/* eslint-disable react-hooks/exhaustive-deps */

import {
    Button,
    Card,
    Col,
    Image,
    List,
    Modal,
    Row,
    Select,
    Spin,
    Form,
    TimePicker,
    Upload,
    Input
} from 'antd'
import { useState, useEffect, useRef } from 'react'
import locale from "antd/es/date-picker/locale/de_DE"
import { Icon } from '@iconify/react'
import {
    useNavigate,
    useLocation
} from 'react-router-dom'
import * as moment from 'moment'
import { Content } from 'antd/lib/layout/layout'
import {
    LoadingOutlined,
    UploadOutlined
} from '@ant-design/icons'
import { BiTrashAlt } from "react-icons/bi"
// import firebase from 'firebase/compat/app'
import firebase from 'firebase/app'

import Script from "react-load-script"
import { isEmptyObject } from 'jquery'
import '../payment/css/index.css'
import { useSelector } from 'react-redux'
import { apiStorageUrl, bucketName, imgDefaultUrl, storageUrl, urlDomain } from '../../constants'
import { useDimensions } from '../../common/mamager/ScreenManager'
import { Checkout } from './components/checkout'
import { calculateOrderPaymentAmount } from './manager/PaymentManager'
import CourseVideoPreview from '../course/components/video/CourseVideoPreview'
import Header from '../../common/components/header'
import LoadingPage from '../../common/components/loading/page'
import Footer from '../../common/components/footer'
import { getCourseByIdFetch, getCourseOwnerFetch } from '../course/API'
import { checkoutCreditCardFetch, checkoutPromptPayFetch, getPaymentOrderDetectReBuyByUserId, getPaymentPromptPayChargeFetch } from './API'
import { getDetectCourseHasBeenPurchasedFetch } from '../coursShop/API'
import { calculateDetectCourseExpire, calculateDifferentTimeByToday, calculateDiscountTimeLeft } from '../../common/mamager/TimeManager'
import { detectSignIn } from '../../common/mamager/DetectManager'
import { Notification } from '../../common/components/notification'
import { removeFileByIdFetch } from '../erp/API'
import { getInspectCouponByIdFetch } from '../../common/API/coupon'
import { display } from '@mui/system'

let Omise
const { Option } = Select

export default function Payment(props) {
    const language = "th"

    const [form] = Form.useForm()
    const [formCoupon] = Form.useForm()

    const navigate = useNavigate()
    const { resultOrderPaymentChapterAndVideoCourse } = useSelector(({ orderPaymentChapterAndVideoCourseReducer }) => orderPaymentChapterAndVideoCourseReducer);

    const [loading, setLoading] = useState(true)
    const [loadingCoupon, setLoadingCoupon] = useState(false)

    const [freeLoading, setFreeLoading] = useState(false)
    const [creditCardLoading, setCreditCardLoading] = useState(false)
    const [promptPayLoading, setPromptPayLoading] = useState(false)

    const [paymentmethodLoading, setPaymentmethodLoading] = useState(false)
    const [transferSlipImage, setTransferSlipImage] = useState({
        data: null,
        loading: false
    })
    const transferSlipImageRef = useRef(null)

    const [charge, setCharge] = useState({
        download_uri: null,
        status: null,
        id: null
    })
    const [isModalPromptPay, setIsModalPromptPay] = useState(false)

    const location = useLocation()
    const params = new URLSearchParams(location.search)
    const courseId = params.get("course-id")

    const [cardPamentModal, setCardPamentModal] = useState({
        isShow: false,
        title: null,
    })

    const [course, setCourse] = useState(null)
    const [courseOwner, setCourseOwner] = useState(null)

    const [day, setDay] = useState([])
    const [month, setMonth] = useState([])
    const [year, setYear] = useState([])

    const [uid, setUid] = useState(null)

    const [netPrice, setNetPrice] = useState({
        isDiscount: false,
        price: "0",
        priceForOmise: "0",
    })

    const [timeLeft, setTimeLeft] = useState(null)
    const [timer, setTimer] = useState({
        time: null,
        display: null,
        isTimeOut: false
    })

    const [isExpire, setIsExpire] = useState(false)

    const [orderPaymentAmount, setOrderPaymentAmount] = useState({
        totalAmount: 0,
        totalPrice: 0
    })

    const option = {
        name: 'file',
        action: `${apiStorageUrl}/upload/file`,
        data: {
            // uploaded/img/payment/tranfer-money
            bucket: bucketName,
            folder: "image/payment/tranfer-money"
        },
        headers: {
            authorization: 'authorization-text',
        },
        onChange(info) {
            if (info.file.status === 'done') {
                let result = info.file.response;
                console.log("info : ", info.file.response)
                if (result?.isSuccess) {

                    if (transferSlipImageRef.current) {
                        removeFileByIdFetch(transferSlipImageRef.current, 0, 5) // (path, sliceMin, sliceMax)
                    }

                    if (info.fileList.length > 0) {
                        setTransferSlipImage({
                            data: result.url,
                            loading: false,
                        })
                        transferSlipImageRef.current = result.url
                    }
                }
            } else {
                setTransferSlipImage({ loading: true })
            }

            if (info.file.status === 'done') {
                Notification("success", "ระบบ", "อัพโหลดหลักฐานการชำระเงินสำเร็จ")
            } else if (info.file.status === 'error') {
                Notification("error", "ระบบ", "ไม่สามารถอัพโหลดหลักฐานการชำระเงินได้ กรุณาตรวจสอบข้อมูล")
            }
        },
        progress: {
            strokeColor: {
                '0%': '#108ee9',
                '100%': '#87d068',
            },
            strokeWidth: 3,
            format: percent => `${parseFloat(percent.toFixed(2))}%`,
        },
    }

    const promptPayTokenRef = useRef(null)
    const timerActiveRef = useRef(false)

    const { width } = useDimensions()

    const [coupon, setCoupon] = useState(null)
    const [couponSearch, setCouponSearch] = useState(null)
    const [couponMassageKey, setCouponMassageKey] = useState(null)

    const [isUseCoupon, setIsUseCoupon] = useState({
        code: null,
        status: false
    })

    const [couponModal, setCouponModal] = useState({
        isShow: false,
        title: null,
    })

    const [couponAlertModal, setCouponAlertModal] = useState({
        isShow: false,
        title: null,
        description: null,
        eventKey: null,
    })

    const onCouponFinish = async (value) => {
        setLoadingCoupon(true)

        // inspect coupon
        let obj = {
            code: value.couponCode,
            courseId: courseId ? Number(courseId) : null
        }
        // console.log("obj : ", obj)
        const result = await getInspectCouponByIdFetch(obj)
        setCouponSearch(result)
        setCouponMassageKey(result.massageKey)
        // console.log("result : ", result)

        handleCouponOpenModal("คูปอง")
        if (result.massageKey === "COUPON_USER_NOT_PRIVILEGE") {
            // setIsUseCoupon({ code: null, status: false })
        } else if (result.massageKey === "COUPON_COURSE_NOT_PRIVILEGE") {
            // setIsUseCoupon({ code: null, status: false })
        } else if (result.massageKey === "COUPON_PERMISSION_FULL") {
            // setIsUseCoupon({ code: null, status: false })
        } else if (result.massageKey === "COUPON_OVERDULE") {
            // setIsUseCoupon({ code: null, status: false })
        } else if (result.massageKey === "COUPON_NOT_PREMATURELY") {
            // setIsUseCoupon({ code: null, status: false })
        } else if (result.massageKey === "COUPON_ACTIVE") {
            if (!isUseCoupon.code) {
                setCoupon(result)
            }
        } else if (result.massageKey === "COUPON_NOT_FOUND") {
            // setIsUseCoupon({ code: null, status: false })
        }

        setLoadingCoupon(false)
    }

    const handleCouponOpenModal = (title) => {
        setCouponModal({ isShow: true, title })
    }

    const handleCouponAlertOpenModal = (title, description, eventKey) => {
        setCouponAlertModal({ isShow: true, title, description, eventKey })
    }

    const handleCouponCloseModal = () => {
        setCouponModal({ isShow: false, title: null })
    }

    const handleCouponAlertCloseModal = () => {
        setCouponAlertModal({ isShow: false, title: null, description: null, eventKey: null })
    }

    const genFormatPrice = (price) => {
        return Intl.NumberFormat("en", { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(Number(price))
    }

    const handlePromptPay = () => {
        setIsModalPromptPay(!isModalPromptPay)
    }

    const getCourseById = async () => {
        const resultCourse = await getCourseByIdFetch(courseId, language); // params is courseId, language
        setCourse(resultCourse)

        if (resultCourse) {
            // calculateNetPrice(resultCourse)

            const resultCourseOwner = await getCourseOwnerFetch(resultCourse.uid)
            setCourseOwner(resultCourseOwner)

            setTimeLeft(calculateDiscountTimeLeft(resultCourse.discountDateEnd))
        }
    }

    // const calculateNetPrice = (value) => {
    //     if (orderPaymentAmount.totalAmount > 0) {
    //         let calPrice = Intl.NumberFormat("en", { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(Number(orderPaymentAmount.totalPrice));
    //         let calPriceNoNumericalUnits = String(orderPaymentAmount.totalPrice);
    //         setNetPrice({
    //             isDiscount: false,
    //             price: calPrice,
    //             priceForOmise: calPriceNoNumericalUnits + "00"
    //         })
    //     } else {
    //         if (value.discountStatus) {
    //             let calPrice = Intl.NumberFormat("en", { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(Number(value.price) - Number(value.discount))
    //             let calPriceNoNumericalUnits = String(Number(value.price) - Number(value.discount))
    //             setNetPrice({
    //                 isDiscount: true,
    //                 price: calPrice,
    //                 priceForOmise: calPriceNoNumericalUnits + "00"
    //             })
    //         } else {
    //             let calPrice = Intl.NumberFormat("en", { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(Number(value.price));
    //             let calPriceNoNumericalUnits = String(value.price);
    //             setNetPrice({
    //                 isDiscount: false,
    //                 price: calPrice,
    //                 priceForOmise: calPriceNoNumericalUnits + "00"
    //             })
    //         }
    //     }
    // }

    const detectCourseHasBeenPurchased = async (uid) => {
        let obj = {
            learnerId: uid,
            courseId: courseId,
            isActive: 1,
        }
        const resultDetectCourseHasBeenPurchased = await getDetectCourseHasBeenPurchasedFetch(obj)

        const resultPaymentOrder = await getPaymentOrderDetectReBuyByUserId(uid, courseId)

        if (resultDetectCourseHasBeenPurchased && resultPaymentOrder?.isPaid) {
            let calculateIsExpire = null
            if (resultDetectCourseHasBeenPurchased.endLearnDate) {
                calculateIsExpire = calculateDetectCourseExpire(resultDetectCourseHasBeenPurchased.endLearnDate)
                if (calculateIsExpire) {
                    if (calculateIsExpire.isExpire) {
                        setIsExpire(true)
                    } else {
                        redirectPageClassroomCourseStudy()
                    }
                } else {
                    redirectPageClassroomCourseStudy()
                }
            }

            if (!calculateIsExpire) {
                redirectPageClassroomCourseStudy()
            }
        }
    }

    const redirectPageClassroomCourseStudy = () => {
        navigate("/classroom-course-study?course-id=" + courseId)
    }

    const getDay = () => {
        let tmpDay = [];
        for (let i = 1; i <= 31; i++) {
            tmpDay.push({
                label: i,
                value: i
            })
        }
        setDay(tmpDay)
    }

    const getMonth = () => {
        let tmpMonth = [
            { label: "มกราคม", value: 1 },
            { label: "กุมภาพันธ์", value: 2 },
            { label: "มีนาคม", value: 3 },
            { label: "เมษายน", value: 4 },
            { label: "พฤษภาคม", value: 5 },
            { label: "มิถุนายน", value: 6 },
            { label: "กรกฎาคม", value: 7 },
            { label: "สิงหาคม", value: 8 },
            { label: "กันยายน", value: 9 },
            { label: "ตุลาคม", value: 10 },
            { label: "พฤศจิกายน", value: 11 },
            { label: "ธันวาคม", value: 12 },
        ]
        setMonth(tmpMonth)
    }

    const getYear = () => {
        let tmpYear = []
        let a = moment().format('YYYY')
        let yearCurrent = (Number(a) + 543) - 1
        for (let i = yearCurrent; i > (yearCurrent - 100); i--) {
            tmpYear.push({
                label: i + 1,
                value: i + 1
            })
        }
        setYear(tmpYear)
    }

    const onFinish = async (values) => {
        if (transferSlipImage.data !== null) {
            let day = values.day
            let month = values.month
            let year = values.year
            let date = (Number(year) - 543) + "/" + month + "/" + day
            let time = moment(values.time).format('HH:mm')
            let dateTimeTransfer = moment(date + " " + time).format('YYYY-MM-DD HH:mm:ss')

            await Checkout(
                coupon?.couponId ?? null,                                   // ไอดีของคูปอง                
                1,                                                          // (1: คอร์สเรียน, ...)
                1,                                                          // (วิธีการชำระเงิน -> [1: โอนเงิน, 2: บัตรเดบิต/เครดิต, 3: Paypal, 4: พร้อมเพย์])
                "pending",                                                  // (สถานะการชำระเงิน -> [1,successful: ชำระเงินแล้ว, 2,pending: รอการชำระเงิน, 3,failed: ยกเลิกการชำระเงิน, 4,free: ฟรี (ไม่ชำระเงิน)])
                false,                                                      // (true คือ การชำระเงินสำเร็จเเละเสร็จสิ้นเเล้ว พร้อมเพิ่มคอร์สเรียนลงในรายการคอร์สของผู้เรียน เเละพร้อมเรียนได้ทันที)
                {
                    courseId,
                    uid: course.uid,
                    courseFormatId: course.courseFormatId,
                    courseTitleName: course.courseTitleName
                },                                                          // ข้อมูลของผู้สอน
                {
                    uid,
                    amount: orderPaymentAmount.totalAmount > 0 ?
                        orderPaymentAmount.totalPrice
                        :
                        (course.discountStatus ?
                            (Number(course.price) - Number(course.discount))
                            :
                            Number(course.price)
                        ),
                },                                                          // ข้อมูลของผู้เรียน
                null,                                                       // charge token
                null,                                                       // charge status
                dateTimeTransfer,                                           // วันที่โอนดูจากสลิปธนาคาร หรือวันที่ชำระเงิน (today -> moment())
                true,                                                       // เปิดหน้า ธุรกรรมการชำระเงิน
                navigate,                                                   // redirect page
                transferSlipImage.data,                                     // ภาพหลักฐานการโอนเงิน เฉพาะการโอนเงิน
                isExpire ? 2 : null,                                        // การต่อสัญญาจากหมดอายุเรียนคอร์ส -> [1: ต่อสัญญาเเล้ว, 2: รอต่อสัญญา, null: ซื้อคอร์สครั้งเเรก หรือเป็นคอร์สที่ไม่มีหมดอายุ]
                resultOrderPaymentChapterAndVideoCourse?.video,             // วิดีโอที่ซื้อ
                resultOrderPaymentChapterAndVideoCourse?.videoMain,         // วิดีโอทั้งหมดของคอร์ส
                2,                                                          // Order payment version ของการชำระเงิน
            )
        } else {
            Notification("warning", "ระบบ", 'กรุณาอัพโหลด หลักฐานการโอนเงิน')
        }
    }

    const setFormDefault = (type) => {
        form.resetFields()

        // ser default image
        setCardPamentModal({
            isShow: false,
            title: null,
        })
    }

    const getBaseApi = async () => {
        setLoading(true)

        const isLogin = await detectSignIn()
        if (isLogin) {
            let uid = firebase.auth().currentUser.uid

            getDay()
            getMonth()
            getYear()
            setUid(uid)

            await detectCourseHasBeenPurchased(uid)

            if (resultOrderPaymentChapterAndVideoCourse.video?.length > 0) {
                setOrderPaymentAmount(calculateOrderPaymentAmount(resultOrderPaymentChapterAndVideoCourse.video))
            }

            await getCourseById()
        } else {
            navigate("/login")
        }

        setLoading(false)
    }

    const handleLoadScript = () => {
        Omise = window?.OmiseCard
        if (Omise) {
            Omise.configure({
                publicKey: 'pkey_5rr9zx91nmu2l68ib3m',
                currency: 'THB',
                frameLabel: 'Toflyn',
                frameDescription: 'คอร์ส ' + course.courseTitleName,
                submitLabel: 'ชำระเงิน',
                locale: 'th',
                amount: netPrice.priceForOmise,
                image: `${urlDomain}/assets/images/logo/toflynUnBG.png`,
            })
        }
    }

    const omiseCardHandler = async () => {
        setLoading(true)

        const priceDisplay = String(genCourseNet(2)) // mode 2 for payment api

        if (Omise) {
            await Omise.open({
                amount: priceDisplay,
                onCreateTokenSuccess: async (token) => {
                    setCreditCardLoading(true);

                    let obj = {
                        email: null,
                        name: 'toflyn',
                        amount: priceDisplay,
                        token,
                        uid,
                        courseId: course.courseId,
                        headers: { "Content-Type": "application/json" },
                        couponId: coupon.couponId ?? null
                    }
                    const result = await checkoutCreditCardFetch(obj)

                    if (result.status === "pending") {
                        window.location.replace(result.authorizeUri)
                    } else {
                        Notification("error", "ระบบ", 'เกิดข้อผิดพลาดในการชำระเงิน โปรดลองใหม่อีกครั้ง')
                    }

                    setCreditCardLoading(false)
                },
                onFormClosed: () => { },
            })
        }


        setLoading(false)
    }

    const omisePromptPayHandler = async () => {
        setLoading(true)

        const priceDisplay = String(genCourseNet(2)) // mode 2 for payment api

        if (Omise) {
            Omise.configure({
                defaultPaymentMethod: 'promptpay'
            })
            await Omise.open({
                amount: priceDisplay,
                onCreateTokenSuccess: async (token) => {
                    setPromptPayLoading(true)
                    let obj = {
                        amount: priceDisplay,
                        token,
                        uid,
                        courseId: course.courseId,
                        headers: { "Content-Type": "application/json" },
                        couponId: coupon?.couponId ?? null
                    }

                    const result = await checkoutPromptPayFetch(obj)

                    timerActiveRef.current = true
                    setTimer({
                        time: moment().add(15, "minute"),
                        display: "15:00",
                        isTimeOut: false
                    }) // set 15 นาที

                    promptPayTokenRef.current = token
                    setCharge(result)
                    setIsModalPromptPay(true)

                    setPromptPayLoading(false)
                },
                onFormClosed: () => { },
            })
        }

        setLoading(false)
    }

    const creditCardConfigure = () => {
        if (Omise) {
            Omise.configure({
                defaultPaymentMethod: 'credit_card',
                otherPaymentMethods: []
            });
            Omise.configureButton("#credit-card")
            Omise.attach()
        }
    }

    const handleCreditCardClick = async (e) => {
        const isLogin = await detectSignIn()
        if (isLogin) {
            if (!creditCardLoading) {
                let result = await getPaymentOrderDetectReBuyByUserId(uid, courseId)
                if (result?.isPaid && !isExpire) {
                    Notification("warning", "ระบบ", 'ไม่สามารถซื้อคอร์สนี้ได้ เนื่องจากท่านได้ซื้อคอร์สนี้แล้ว หรืออยู่ในช่วงตรวจสอบชำระเงินอยู่')
                } else {
                    e.preventDefault()
                    creditCardConfigure()
                    omiseCardHandler()
                }
            } else {
                Notification("warning", "ระบบ", 'ขณะนี้ อยู่ระหว่างการดำเนินการชำระเงิน')
            }
        } else {
            Notification("warning", "ระบบ", 'กรุณาเข้าสู่ระบบก่อนทำการซื้อคอร์ส !')
        }
    }

    const handlePromptPayClick = async (e) => {
        const isLogin = await detectSignIn()
        if (isLogin) {
            if (!creditCardLoading) {
                let result = await getPaymentOrderDetectReBuyByUserId(uid, courseId)
                if (result?.isPaid && !isExpire) {
                    Notification("warning", "ระบบ", 'ไม่สามารถซื้อคอร์สนี้ได้ เนื่องจากท่านได้ซื้อคอร์สนี้แล้ว หรืออยู่ในช่วงตรวจสอบชำระเงินอยู่')
                } else {
                    e.preventDefault()
                    omisePromptPayHandler()
                }
            } else {
                Notification("warning", "ระบบ", 'ขณะนี้ อยู่ระหว่างการดำเนินการชำระเงิน')
            }
        } else {
            Notification("warning", "ระบบ", 'กรุณาเข้าสู่ระบบก่อนทำการซื้อคอร์ส !')
        }
    }

    useEffect(() => {
        if (!timeLeft) {
            getBaseApi()
        }
    }, [])

    useEffect(() => {
        setTimeout(() => {
            if (course) {
                let discountTimeLeft = calculateDiscountTimeLeft(course.discountDateEnd)
                // console.log("discountTimeLeft : ", discountTimeLeft, course.discountDateEnd)
                setTimeLeft(discountTimeLeft)

                if (orderPaymentAmount.totalAmount > 0) {
                    let calPrice = Intl.NumberFormat("en", { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(Number(orderPaymentAmount.totalPrice));
                    let calPriceNoNumericalUnits = String(orderPaymentAmount.totalPrice);
                    setNetPrice({
                        isDiscount: false,
                        price: calPrice,
                        priceForOmise: calPriceNoNumericalUnits + "00"
                    })
                } else {
                    if (course.discountStatus && !isEmptyObject(discountTimeLeft)) {
                        let calPrice = Intl.NumberFormat("en", { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(Number(course.price) - Number(course.discount));
                        let calPriceNoNumericalUnits = String(Number(course.price) - Number(course.discount));
                        setNetPrice({
                            isDiscount: true,
                            price: calPrice,
                            priceForOmise: calPriceNoNumericalUnits + "00"
                        });
                    } else {
                        let calPrice = Intl.NumberFormat("en", { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(Number(course.price))
                        let calPriceNoNumericalUnits = String(course.price);
                        setNetPrice({
                            isDiscount: false,
                            price: calPrice,
                            priceForOmise: calPriceNoNumericalUnits + "00"
                        })
                    }
                }
            }
        }, 1000)
    }, [timeLeft])

    useEffect(() => { // พร้อมเพย์
        if (timerActiveRef.current && !timer.isTimeOut && timer.time) {
            setTimeout(async () => {
                let resultTimer = calculateDifferentTimeByToday(timer.time)
                // console.log("resultTimer : ", resultTimer, timer.display)
                if (resultTimer) {
                    setTimer({
                        ...timer,
                        display: (resultTimer.minutes + ":" + resultTimer.seconds),
                        isTimeOut: resultTimer.isTimeOut
                    })
                }

                if (charge) {
                    const result = await getPaymentPromptPayChargeFetch({
                        id: charge.id,
                        courseId,
                        uid
                    })
                    if (result) {
                        // console.log("getPaymentPromptPayChargeFetch : ", result, timerActiveRef.current);
                        if (result.status === "successful" && timerActiveRef.current) {     // จุดนี้ timerActiveRef ของ พร้อมเพย์ ห้ามมี เงื่อนไขเช็ค timerActiveRef ว่าต้อง true ด้วย
                            Checkout(                                                       // จุดนี้ Checkout ของ พร้อมเพย์ ห้ามมี await อยู่ข้างหน้า
                                coupon?.couponId ?? null,                                   // ไอดีของคูปอง   
                                1,                                                          // (1: คอร์สเรียน, ...)
                                4,                                                          // (วิธีการชำระเงิน -> [1: โอนเงิน, 2: บัตรเดบิต/เครดิต, 3: Paypal, 4: พร้อมเพย์])
                                "successful",                                               // (สถานะการชำระเงิน -> [1,successful: ชำระเงินแล้ว, 2,pending: รอการชำระเงิน, 3,failed: ยกเลิกการชำระเงิน, 4,free: ฟรี (ไม่ชำระเงิน)])
                                true,                                                       // (true คือ การชำระเงินสำเร็จเเละเสร็จสิ้นเเล้ว พร้อมเพิ่มคอร์สเรียนลงในรายการคอร์สของผู้เรียน เเละพร้อมเรียนได้ทันที)
                                {
                                    courseId,
                                    uid: course.uid,
                                    courseFormatId: course.courseFormatId,
                                    courseTitleName: course.courseTitleName
                                },                                                          // ข้อมูลของผู้สอน
                                {
                                    uid,
                                    amount: orderPaymentAmount.totalAmount !== 0 ?
                                        orderPaymentAmount.totalPrice
                                        :
                                        (course.discountStatus ?
                                            (Number(course.price) - Number(course.discount))
                                            :
                                            Number(course.price)),
                                },                                                          // ข้อมูลของผู้เรียน
                                promptPayTokenRef.current,                                  // charge token
                                "successful", //result.status,                              // charge status
                                moment(),                                                   // วันที่โอนดูจากสลิปธนาคาร หรือวันที่ชำระเงิน (today -> moment())
                                false,                                                      // เปิดหน้า ธุรกรรมการชำระเงิน
                                navigate,                                                   // redirect page
                                null,                                                       // ภาพหลักฐานการโอนเงิน เฉพาะการโอนเงิน
                                null,                                                       // การต่อสัญญาจากหมดอายุเรียนคอร์ส -> [1: ต่อสัญญาเเล้ว, 2: รอต่อสัญญา, null: ซื้อคอร์สครั้งเเรก หรือเป็นคอร์สที่ไม่มีหมดอายุ]
                                resultOrderPaymentChapterAndVideoCourse?.video,             // วิดีโอที่ซื้อ
                                resultOrderPaymentChapterAndVideoCourse?.videoMain,         // วิดีโอทั้งหมดของคอร์ส
                                2,                                                          // Order payment version ของการชำระเงิน
                            )
                            timerActiveRef.current = false
                            setTimer({
                                time: null,
                                display: null,
                                isTimeOut: false
                            })
                            handlePromptPay()
                        }
                    }
                }
            }, 1000)
        }
    }, [timer])

    useEffect(() => {

    }, [transferSlipImage])


    const genOrderedList = () => {
        return (
            <Card
                bordered
                title={
                    <label style={{ paddingBottom: 0, marginBottom: 0, fontWeight: 100 }}>รายการสั่งซื้อ {isExpire ? "(ต่อสัญญา)" : []}</label>
                }
                style={{
                    width: '100%',
                    borderRadius: 10,
                    boxShadow: "1px 1px 12px 5px rgba(208, 216, 243, 0.4)",
                    height: '100%'
                }}
            >
                <Row>
                    <Col
                        xs={24} xl={6}
                        style={{ display: 'flex', alignItems: 'center', justifyContent: width > 1400 ? 'left' : 'center', paddingBottom: 24 }}
                    >
                        <Image
                            height={width < 1200 ? 280 : 115}
                            preview={false}
                            src="error"
                            fallback={`${course.courseImageUrl}`}
                        />
                    </Col>

                    <Col xs={24} xl={18} style={{ paddingLeft: width < 1200 ? 0 : 24, paddingBottom: 24 }}>
                        <Row>
                            <Col xs={width < 1200 ? 12 : 8} xl={(width > 1200 && width < 1593) ? 10 : 6}>
                                <label style={{ float: "right", paddingRight: 10 }}>ชื่อคอร์ส</label>
                            </Col>
                            <Col xs={width < 1200 ? 12 : 16} xl={(width > 1200 && width < 1593) ? 14 : 18}>
                                <label style={{}}>{course.courseTitleName}</label>
                            </Col>

                            <Col xs={width < 1200 ? 12 : 8} xl={(width > 1200 && width < 1593) ? 10 : 6}>
                                <label style={{ float: "right", paddingRight: 10 }}>ผู้สอน</label>
                            </Col>
                            <Col xs={width < 1200 ? 12 : 16} xl={(width > 1200 && width < 1593) ? 14 : 18}>
                                <label style={{}}>{courseOwner.userName}</label>
                            </Col>

                            <Col xs={width < 1200 ? 12 : 8} xl={(width > 1200 && width < 1593) ? 10 : 6}>
                                <label style={{ float: "right", paddingRight: 10 }}>ใบรับรองหลักสูตร</label>
                            </Col>
                            <Col xs={width < 1200 ? 12 : 16} xl={(width > 1200 && width < 1593) ? 14 : 18}>
                                <label style={{}}>{course.courseCertificateName}</label>
                            </Col>

                            <Col xs={width < 1200 ? 12 : 8} xl={(width > 1200 && width < 1593) ? 10 : 6}>
                                <label style={{ float: "right", paddingRight: 10 }}>รูปเเบบคอร์ส</label>
                            </Col>
                            <Col xs={width < 1200 ? 12 : 16} xl={(width > 1200 && width < 1593) ? 14 : 18}>
                                <label style={{}}>{course.courseFormatName}</label>
                            </Col>
                        </Row>
                    </Col>

                    {resultOrderPaymentChapterAndVideoCourse.video?.length > 0 ?
                        <>
                            <Col span={24} style={{ paddingBottom: 22, borderTop: "2px solid #23B14D" }}></Col>
                            <Col span={24}>
                                <CourseVideoPreview
                                    resultChapter={resultOrderPaymentChapterAndVideoCourse.chapter}
                                    resultVideo={resultOrderPaymentChapterAndVideoCourse.video}
                                    isPaymentByVideo={true}
                                    bgChapterTitle="#F9F9F9"
                                    bgNonChapterTitle="#F9F9F9"
                                    bgChapterBody="#EEEEEE"
                                    bgVideo="white"
                                    borderLine="#B2B2B2"
                                    fontColor="black"
                                />
                            </Col>
                        </>
                        : []
                    }

                    {Number(course.price) === 0 ?
                        <Col span={24} style={{ borderTop: "2px solid #23B14D" }}>
                            <center style={{ paddingTop: 15 }}>
                                <label
                                    style={{
                                        paddingBottom: 0,
                                        marginBottom: 0,
                                        fontSize: 24,
                                        paddingLeft: 8,
                                        paddingRight: 8,
                                        fontWeight: "bold",
                                        color: "#23B14D"
                                    }}
                                >ฟรี</label>
                            </center>
                        </Col>
                        :
                        <>
                            {orderPaymentAmount.totalAmount > 0 ?
                                <>
                                    <Col span={24} style={{ paddingTop: 22 }}></Col>
                                    <Col span={12} style={{ borderTop: "2px solid #23B14D" }}>
                                        <div style={{ paddingTop: 15 }}>
                                            <label style={{ paddingBottom: 0, marginBottom: 0, paddingRight: 10, float: "right" }}>วิดีโอที่เลือก</label>
                                        </div>
                                    </Col>
                                    <Col span={12} style={{ borderTop: "2px solid #23B14D" }}>
                                        <div style={{ paddingTop: 15 }}>
                                            <label style={{ paddingBottom: 0, marginBottom: 0, fontWeight: "bold" }}>
                                                {orderPaymentAmount.totalAmount}
                                            </label>
                                            <label style={{ paddingBottom: 0, marginBottom: 0, paddingLeft: 5 }}>วิดีโอ</label>
                                        </div>
                                    </Col>
                                </>
                                : []
                            }

                            <Col span={24} >
                                <div style={{ display: "flex", alignItems: "flex-start", justifyContent: "center", width: "100%" }}>
                                    <Form form={formCoupon} layout="vertical" onFinish={onCouponFinish} >
                                        <Form.Item
                                            name="couponCode"
                                            // label="รหัสคูปอง"
                                            style={{ width: '100%' }}
                                            rules={[{ required: true, message: 'กรุณากรอกรหัสคูปอง' }]}
                                        >
                                            <Input disabled={loadingCoupon} placeholder="รหัสคูปอง" style={{ width: '100%', borderTopRightRadius: 0, borderBottomRightRadius: 0 }} />
                                        </Form.Item>
                                    </Form>
                                    <Button
                                        type="primary"
                                        style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                                        loading={loadingCoupon}
                                        onClick={() => {
                                            formCoupon.submit()
                                        }}
                                    >
                                        {!loadingCoupon ? <label style={{ cursor: "pointer" }}>ค้นหา</label> : []}
                                    </Button>
                                </div>
                            </Col>

                            <Col span={12} style={{ borderTop: orderPaymentAmount.totalAmount === 0 ? "2px solid #23B14D" : "" }}>
                                <div style={{ paddingTop: orderPaymentAmount.totalAmount === 0 ? 15 : 0 }}>
                                    <label style={{ paddingBottom: 0, marginBottom: 0, paddingRight: 10, fontSize: 18, float: "right" }}>
                                        {orderPaymentAmount.totalAmount === 0 ? "ราคาคอร์ส" : "ราคาคอร์สที่จ่าย"}
                                    </label>
                                </div>
                            </Col>
                            <Col span={12} style={{ borderTop: orderPaymentAmount.totalAmount === 0 ? "2px solid #23B14D" : "" }}>
                                <div style={{ paddingTop: orderPaymentAmount.totalAmount === 0 ? 15 : 0 }}>
                                    <label style={{ paddingBottom: 0, marginBottom: 0, fontSize: 18, fontWeight: "bold" }}>
                                        {Intl.NumberFormat("en", { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(Number(
                                            (orderPaymentAmount.totalAmount === 0 ? course.price : orderPaymentAmount.totalPrice)
                                        ))}
                                    </label>
                                    <label style={{ paddingBottom: 0, marginBottom: 0, paddingLeft: 5, fontSize: 18 }}>บาท</label>
                                </div>
                            </Col>

                            {course.discountStatus && !isEmptyObject(timeLeft) ?
                                <>
                                    <Col span={12} style={{}}>
                                        <div style={{}}>
                                            <label
                                                style={{
                                                    paddingBottom: 0,
                                                    marginBottom: 0,
                                                    paddingRight: 10,
                                                    fontSize: 18,
                                                    float: "right"
                                                }}
                                            >ลดราคาเหลือ</label>
                                        </div>
                                    </Col>
                                    <Col span={12} style={{}}>
                                        <div style={{}}>
                                            <label
                                                style={{
                                                    paddingBottom: 0,
                                                    marginBottom: 0,
                                                    fontSize: 18,
                                                    fontWeight: "bold"
                                                }}
                                            >
                                                {Intl.NumberFormat("en", { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(Number(course.price) - Number(course.discount))}
                                            </label>
                                            <label
                                                style={{
                                                    paddingBottom: 0,
                                                    marginBottom: 0,
                                                    paddingLeft: 5,
                                                    fontSize: 18
                                                }}
                                            >บาท</label>
                                        </div>
                                    </Col>
                                </>
                                : []
                            }

                            {isUseCoupon.status ?
                                <>
                                    <Col span={12}>
                                        <div>
                                            <label style={{ paddingBottom: 0, marginBottom: 0, paddingRight: 10, fontSize: 18, color: "#23B14D", float: "right" }}>คูปอง {coupon?.code ?? null}</label>
                                        </div>
                                    </Col>
                                    <Col span={12}>
                                        <div style={{ display: "flex", alignContent: "center" }}>
                                            <label style={{ paddingBottom: 0, marginBottom: 0, fontSize: 18, fontWeight: "bold", color: "#23B14D", paddingRight: 10 }}>
                                                ส่วนลด {coupon.couponPrivilegeTypeId === 1 ? coupon.privilege + "% (" + Intl.NumberFormat("en", { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(Number(coupon.discount)) + " บาท)"
                                                    :
                                                    Intl.NumberFormat("en", { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(Number(coupon.privilege)) + " บาท"}
                                            </label>

                                            <div
                                                style={{
                                                    backgroundColor: '#ff5959',
                                                    padding: 4,
                                                    borderRadius: 20,
                                                    cursor: 'pointer',
                                                    width: 23,
                                                    height: 23,
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center"
                                                }}
                                                onClick={() => {
                                                    handleCouponAlertOpenModal(
                                                        'ยกเลิกการใช้คูปอง!',
                                                        <label>คุณยืนยันที่จะไม่ใช้คูปองนี้ <span style={{ fontWeight: 600, color: "#23B14D" }}>{formCoupon.getFieldValue("couponCode")}</span> โปรดคลิกที่ยืนยัน !</label>,
                                                        "REMOVE_COUPON"
                                                    )
                                                }}
                                            >
                                                <BiTrashAlt size={16} style={{ color: 'white', cursor: 'pointer' }} />
                                            </div>
                                        </div>
                                    </Col>
                                </>
                                : []
                            }

                            <Col span={12}>
                                <div>
                                    <label style={{ paddingBottom: 0, marginBottom: 0, paddingRight: 10, fontSize: 18, color: "#23B14D", float: "right" }}>ราคาสุทธิ </label>
                                </div>
                            </Col>
                            <Col span={12}>
                                <div>
                                    <label style={{ paddingBottom: 0, marginBottom: 0, fontSize: 18, fontWeight: "bold", color: "#23B14D" }}>
                                        {genCourseNet(1)}
                                    </label>
                                    <label style={{ paddingBottom: 0, marginBottom: 0, paddingLeft: 5, fontSize: 18, color: "#23B14D" }}>บาท</label>
                                </div>
                            </Col>
                        </>
                    }
                </Row>
            </Card>

        )
    }

    const genPaymentMethod = () => {
        return (
            <Card
                bordered
                title={<label style={{ paddingBottom: 0, marginBottom: 0, fontWeight: 100 }}>ช่องทางชำระเงิน</label>}
                style={{
                    width: '100%',
                    borderRadius: 10,
                    boxShadow: "1px 1px 16px 3px rgba(208, 216, 243, 0.6)",
                    height: '100%' //width <= 578 ? 445 : 400
                }}
            >
                <center>
                    <List style={{ width: "80%" }}>
                        {course?.price === 0 ?
                            <List.Item
                                className={"payment-method"}
                                style={
                                    !freeLoading ? {
                                        height: width <= 578 ? 90 : 63,
                                        borderRadius: 10,
                                        borderLeft: '2px solid #23B14D',
                                        borderRight: '2px solid #23B14D',
                                        borderTop: '2px solid #23B14D',
                                        borderBottom: '2px solid #23B14D',
                                        boxShadow: "1px 1px 16px 3px rgba(208, 216, 243, 0.8)",
                                        marginBottom: 12
                                    } : {
                                        height: width <= 578 ? 90 : 63,
                                        borderRadius: 10,
                                        borderLeft: '2px solid #23B14D',
                                        borderRight: '2px solid #23B14D',
                                        borderTop: '2px solid #23B14D',
                                        borderBottom: '2px solid #23B14D',
                                        boxShadow: "1px 1px 16px 3px rgba(208, 216, 243, 0.8)",
                                        marginBottom: 12,
                                        background: "#EEEEEE"
                                    }
                                }
                                onClick={async () => {
                                    const isLogin = await detectSignIn()
                                    if (isLogin) {
                                        let result = await getPaymentOrderDetectReBuyByUserId(uid, courseId)
                                        // console.log("getPaymentOrderDetectReBuyByUserId : ", result)
                                        if (result?.isPaid) {
                                            Notification("warning", "ระบบ", 'ไม่สามารถซื้อคอร์สนี้ได้ เนื่องจากท่านได้ซื้อคอร์สนี้แล้ว หรืออยู่ในช่วงตรวจสอบชำระเงินอยู่')
                                        } else {
                                            setFreeLoading(true)

                                            await Checkout(
                                                coupon?.couponId ?? null,                                   // ไอดีของคูปอง   
                                                1,                                                          // (1: คอร์สเรียน, ...)
                                                null,                                                       // (วิธีการชำระเงิน -> [1: โอนเงิน, 2: บัตรเดบิต/เครดิต, 3: Paypal, 4: พร้อมเพย์])
                                                "successful",                                               // (สถานะการชำระเงิน -> [1,successful: ชำระเงินแล้ว, 2,pending: รอการชำระเงิน, 3,failed: ยกเลิกการชำระเงิน, 4,free: ฟรี (ไม่ชำระเงิน)])
                                                true,                                                       // (true คือ การชำระเงินสำเร็จเเละเสร็จสิ้นเเล้ว พร้อมเพิ่มคอร์สเรียนลงในรายการคอร์สของผู้เรียน เเละพร้อมเรียนได้ทันที)
                                                {
                                                    courseId,
                                                    uid: course.uid,
                                                    courseFormatId: course.courseFormatId,
                                                    courseTitleName: course.courseTitleName
                                                },                                                          // ข้อมูลของผู้สอน
                                                {
                                                    uid,
                                                    amount: orderPaymentAmount.totalAmount !== 0 ?
                                                        orderPaymentAmount.totalPrice
                                                        :
                                                        (course.discountStatus ?
                                                            (Number(course.price) - Number(course.discount))
                                                            :
                                                            Number(course.price)),
                                                },                                                          // ข้อมูลของผู้เรียน
                                                null,                                                       // charge token
                                                null,                                                       // charge status
                                                moment(),                                                   // วันที่โอนดูจากสลิปธนาคาร หรือวันที่ชำระเงิน (today -> moment())
                                                false,                                                      // เปิดหน้า ธุรกรรมการชำระเงิน
                                                navigate,                                                   // redirect page
                                                null,                                                       // ภาพหลักฐานการโอนเงิน เฉพาะการโอนเงิน
                                                null,                                                       // การต่อสัญญาจากหมดอายุเรียนคอร์ส -> [1: ต่อสัญญาเเล้ว, 2: รอต่อสัญญา, null: ซื้อคอร์สครั้งเเรก หรือเป็นคอร์สที่ไม่มีหมดอายุ]
                                                resultOrderPaymentChapterAndVideoCourse?.video,             // วิดีโอที่ซื้อ
                                                resultOrderPaymentChapterAndVideoCourse?.videoMain,         // วิดีโอทั้งหมดของคอร์ส
                                                2,                                                          // Order payment version ของการชำระเงิน
                                            )

                                            // set default value
                                            setCardPamentModal({
                                                isShow: false,
                                                title: null,
                                            })

                                            form.resetFields()

                                            setTransferSlipImage({
                                                data: null,
                                                loading: false,
                                            })

                                            setFreeLoading(false)
                                        }
                                    } else {
                                        Notification("warning", "ระบบ", 'กรุณาเข้าสู่ระบบก่อนทำการซื้อคอร์ส !')
                                    }
                                }}
                            >
                                {!freeLoading ?
                                    <>เพิ่มคอร์สเรียนเข้าไปที่ "คอร์สของฉัน" เเบบไม่ชำระเงิน</>
                                    :
                                    <div style={{ width: "100%" }}>
                                        <center>
                                            <Spin size="default" />
                                            <span style={{ paddingLeft: 15 }}>กำลังดำเนินการ ...</span>
                                        </center>
                                    </div>
                                }
                            </List.Item>
                            :
                            <>
                                {/* paypal */}
                                {/* <div
                                    style={{
                                        marginBottom: 8,
                                    }}
                                >
                                    <PayPalScriptProvider
                                        options={{
                                            "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID,
                                        }}
                                    >
                                        <PayPalButtons
                                            style={{
                                                color: "white",
                                                shape: "rect",
                                                label: "pay",
                                                tagline: false,
                                                layout: "horizontal",
                                            }}
                                            createOrder={(data, actions) => {
                                                return actions.order.create({
                                                    purchase_units: [
                                                        {
                                                            description: "xxx", // course?.title,
                                                            amount: {
                                                                value: '10', // course?.price,
                                                                currency_code: 'USD',
                                                            }
                                                        }
                                                    ]
                                                });
                                            }}
                                            onApprove={async (data, actions) => {
                                                const isLogin = await detectSignIn()
                                                if (isLogin) {
                                                    const order = await actions.order.capture();
                                                    // console.log("order : ", order);
                                                } else {
                                                    Notification("warning", "ระบบ", 'กรุณาเข้าสู่ระบบก่อนทำการซื้อคอร์ส !');
                                                }
                                            }}
                                            onError={(err) => {
                                                // console.log("err : ", err);
                                            }}
                                        />
                                    </PayPalScriptProvider>
                                </div> */}

                                {/* บัตรเครดิต/เดบิต */}
                                <div className="own-form">
                                    <Script
                                        url="https://cdn.omise.co/omise.js"
                                        onLoad={handleLoadScript}
                                    />
                                    <form>
                                        <div
                                            id="credit-card"
                                            type="button"
                                            onClick={handleCreditCardClick}
                                        >
                                            <List.Item
                                                className={"payment-method"}
                                                style={
                                                    !creditCardLoading ? {
                                                        height: width <= 578 ? 90 : 63,
                                                        borderRadius: 10,
                                                        borderLeft: '2px solid #23B14D',
                                                        borderRight: '2px solid #23B14D',
                                                        borderTop: '2px solid #23B14D',
                                                        borderBottom: '2px solid #23B14D',
                                                        boxShadow: "1px 1px 16px 3px rgba(208, 216, 243, 0.8)",
                                                        marginBottom: 12
                                                    } : {
                                                        height: width <= 578 ? 90 : 63,
                                                        borderRadius: 10,
                                                        borderLeft: '2px solid #23B14D',
                                                        borderRight: '2px solid #23B14D',
                                                        borderTop: '2px solid #23B14D',
                                                        borderBottom: '2px solid #23B14D',
                                                        boxShadow: "1px 1px 16px 3px rgba(208, 216, 243, 0.8)",
                                                        marginBottom: 12,
                                                        background: "#EEEEEE"
                                                    }
                                                }
                                            >
                                                {!creditCardLoading ?
                                                    <Row style={{ width: "100%", paddingLeft: 18, paddingRight: 18 }}>
                                                        <Col
                                                            span={width < 1400 ? 24 : 12}
                                                            style={{ display: 'flex', alignItems: 'center', justifyContent: width > 1400 ? 'left' : 'center' }}
                                                        >
                                                            <label style={{ paddingBottom: 0, marginBottom: 0, cursor: "pointer" }}>บัตรเครดิต/เดบิต</label>
                                                        </Col>
                                                        <Col
                                                            span={width < 1400 ? 24 : 12}
                                                            style={{ display: 'flex', alignItems: 'center', justifyContent: width > 1400 ? 'right' : 'center' }}
                                                        >
                                                            <Image
                                                                height={25}
                                                                width={"auto"}
                                                                style={{ borderRadius: 10, paddingLeft: 5 }}
                                                                preview={false}
                                                                src="error"
                                                                fallback={`./assets/images/payment/card/jcb-logo.png`}
                                                            />
                                                            <Image
                                                                height={23}
                                                                width={"auto"}
                                                                style={{ borderRadius: 10, paddingLeft: 5 }}
                                                                preview={false}
                                                                src="error"
                                                                fallback={`./assets/images/payment/card/visa-logo.png`}
                                                            />
                                                            <Image
                                                                height={25}
                                                                width={"auto"}
                                                                style={{ borderRadius: 10, paddingLeft: 5 }}
                                                                preview={false}
                                                                src="error"
                                                                fallback={`./assets/images/payment/card/mastercard-logo.png`}
                                                            />
                                                        </Col>
                                                    </Row>
                                                    :
                                                    <div style={{ width: "100%" }}>
                                                        <center>
                                                            <Spin size="default" />
                                                            <span style={{ paddingLeft: 15 }}>กำลังดำเนินการ ...</span>
                                                        </center>
                                                    </div>
                                                }
                                            </List.Item>
                                        </div>
                                    </form>
                                </div>

                                {/* ===================================================================================================== */}
                                {/* พร้อมเพย์ */}
                                <div className="own-form">
                                    <Script
                                        url="https://cdn.omise.co/omise.js"
                                        onLoad={handleLoadScript}
                                    />
                                    <form>
                                        <div
                                            id="prompt-pay"
                                            type="button"
                                            onClick={handlePromptPayClick}
                                        >
                                            <List.Item
                                                className={"payment-method"}
                                                style={
                                                    !promptPayLoading ? {
                                                        height: width <= 578 ? 90 : 63,
                                                        borderRadius: 10,
                                                        borderLeft: '2px solid #23B14D',
                                                        borderRight: '2px solid #23B14D',
                                                        borderTop: '2px solid #23B14D',
                                                        borderBottom: '2px solid #23B14D',
                                                        boxShadow: "1px 1px 16px 3px rgba(208, 216, 243, 0.8)",
                                                        marginBottom: 12
                                                    } : {
                                                        height: width <= 578 ? 90 : 63,
                                                        borderRadius: 10,
                                                        borderLeft: '2px solid #23B14D',
                                                        borderRight: '2px solid #23B14D',
                                                        borderTop: '2px solid #23B14D',
                                                        borderBottom: '2px solid #23B14D',
                                                        boxShadow: "1px 1px 16px 3px rgba(208, 216, 243, 0.8)",
                                                        marginBottom: 12,
                                                        background: "#EEEEEE"
                                                    }
                                                }
                                            >
                                                {!promptPayLoading ?
                                                    <Row style={{ width: "100%", paddingLeft: 18, paddingRight: 18 }}>
                                                        <Col
                                                            span={width < 1400 ? 24 : 12}
                                                            style={{ display: 'flex', alignItems: 'center', justifyContent: width > 1400 ? 'left' : 'center' }}
                                                        >
                                                            <label style={{ paddingBottom: 0, marginBottom: 0, cursor: "pointer" }}>พร้อมเพย์</label>
                                                        </Col>
                                                        <Col
                                                            span={width < 1400 ? 24 : 12}
                                                            style={{ display: 'flex', alignItems: 'center', justifyContent: width > 1400 ? 'right' : 'center' }}
                                                        >
                                                            <Image
                                                                height={30}
                                                                width={"auto"}
                                                                preview={false}
                                                                src="error"
                                                                fallback={`./assets/images/payment/prompt-pay.png`}
                                                            />
                                                        </Col>
                                                    </Row>
                                                    :
                                                    <div style={{ width: "100%" }}>
                                                        <center>
                                                            <Spin size="default" />
                                                            <span style={{ paddingLeft: 15 }}>กำลังดำเนินการ ...</span>
                                                        </center>
                                                    </div>
                                                }
                                            </List.Item>
                                        </div>
                                    </form>
                                </div>
                                {/* ===================================================================================================== */}

                                {/* โอนเงิน */}
                                <List.Item
                                    className={"payment-method"}
                                    style={{
                                        height: width <= 577 ? 'auto' : 63,
                                        borderRadius: 10,
                                        borderLeft: '2px solid #23B14D',
                                        borderRight: '2px solid #23B14D',
                                        borderBottom: '2px solid #23B14D',
                                        borderTop: '2px solid #23B14D',
                                        boxShadow: "1px 1px 16px 3px rgba(208, 216, 243, 0.8)",
                                    }}
                                    onClick={async () => {
                                        const isLogin = await detectSignIn()
                                        if (isLogin) {
                                            let result = await getPaymentOrderDetectReBuyByUserId(uid, courseId)
                                            // console.log("getPaymentOrderDetectReBuyByUserId : ", result)
                                            if (result?.isPaid && !isExpire) {
                                                Notification("warning", "ระบบ", 'ไม่สามารถซื้อคอร์สนี้ได้ เนื่องจากท่านได้ซื้อคอร์สนี้แล้ว หรืออยู่ในช่วงตรวจสอบชำระเงินอยู่');
                                            } else {
                                                if (!creditCardLoading) {
                                                    setPaymentmethodLoading(true)

                                                    setCardPamentModal({
                                                        isShow: true,
                                                        title: "โอนเงินผ่านบัญชีธนาคาร",
                                                    })

                                                    form.resetFields();
                                                    setTransferSlipImage({
                                                        data: null,
                                                        loading: false,
                                                    })

                                                    setPaymentmethodLoading(false)
                                                } else {
                                                    Notification("warning", "ระบบ", 'ขณะนี้ อยู่ระหว่างการดำเนินการชำระเงิน')
                                                }
                                            }
                                        } else {
                                            Notification("warning", "ระบบ", 'กรุณาเข้าสู่ระบบก่อนทำการซื้อคอร์ส !')
                                        }
                                    }}
                                >
                                    <Row style={{ width: "100%", paddingLeft: 18, paddingRight: 18 }}>
                                        <Col
                                            span={width < 1400 ? 24 : 12}
                                            style={{ display: 'flex', alignItems: 'center', justifyContent: width > 1400 ? 'left' : 'center' }}
                                        >
                                            <label style={{ paddingBottom: 0, marginBottom: 0, cursor: "pointer" }}>โอนเงิน</label>
                                        </Col>
                                        <Col
                                            span={width < 1400 ? 24 : 12}
                                            style={{ display: 'flex', alignItems: 'center', justifyContent: width > 1400 ? 'right' : 'center' }}
                                        >
                                            <Image
                                                height={30}
                                                width={"auto"}
                                                style={{ borderRadius: 10 }}
                                                preview={false}
                                                src="error"
                                                fallback={`./assets/images/bank_logo/SCB.jpg`}
                                            />
                                        </Col>
                                    </Row>
                                </List.Item>

                                <List.Item
                                    style={{ float: "right" }}
                                    onClick={async () => {
                                        navigate("/refund-policy")
                                    }}
                                >
                                    <label style={{ textDecoration: "underline", color: "#23B14D", cursor: "pointer" }}>นโยบายการคืนเงิน</label>
                                </List.Item>
                            </>
                        }
                    </List>
                </center >
            </Card >
        )
    }

    const convertPrice = (price) => {
        // คูณราคาด้วย 100 เพื่อเลื่อนทศนิยมไปที่ตำแหน่งที่ต้องการ
        const multipliedPrice = price * 100

        // // ให้เก็บทศนิยมที่ได้ไว้ในตัวแปร decimalPart
        // const decimalPart = multipliedPrice % 100

        // // หาจำนวนเต็มที่ได้
        // const integerPart = (multipliedPrice - decimalPart) / 100

        // // ให้เติม 0 ถ้าทศนิยมมีค่าน้อยกว่า 10
        // const formattedDecimal = decimalPart < 10 ? `0${decimalPart}` : `${decimalPart}`

        // // รวมเลขจำนวนเต็มและทศนิยมในรูปแบบที่ต้องการ
        // const result = `${integerPart}.${formattedDecimal}`

        // console.log("multipliedPrice : ", multipliedPrice)

        return multipliedPrice
    }

    const genCourseNet = (mode) => { // mode : (1 is normal, 2 for payment api)
        if (isUseCoupon.status) {
            if (mode === 1) {
                return genFormatPrice(Number(coupon.netPrice))
            }

            if (mode === 2) {
                return convertPrice(Number(coupon.netPrice))
            }
        } else {
            if (orderPaymentAmount.totalAmount > 0) {
                if (mode === 1) {
                    return genFormatPrice(Number(orderPaymentAmount.totalPrice))
                }

                if (mode === 2) {
                    return convertPrice(Number(orderPaymentAmount.totalPrice))
                }
            } else {
                if (course) {
                    if (course.discountStatus && !isEmptyObject(timeLeft)) {
                        if (mode === 1) {
                            return genFormatPrice(Number(course.price) - Number(course.discount))
                        }

                        if (mode === 2) {
                            return convertPrice(Number(course.price) - Number(course.discount))
                        }
                    } else {
                        if (mode === 1) {
                            return genFormatPrice(Number(course.price))
                        }

                        if (mode === 2) {
                            return convertPrice(Number(course.price))
                        }
                    }
                }
            }
        }
    }

    return (
        <>
            <Header
                firebase={props.firebase}
                user={props.user}
            />

            <article>
                <Content style={{ backgroundColor: "#F5F5F5" }}>
                    <article>
                        {!loading ?
                            <div style={width >= 1500 ? { paddingLeft: '5%', paddingRight: '5%' } : ((width > 912 && width < 1500) ? { paddingLeft: '5%', paddingRight: '5%' } : { paddingLeft: '0%', paddingRight: '0%' })}>
                                <Row style={{ backgroundColor: "white", paddingTop: 30, paddingLeft: 15, paddingRight: 15 }}>
                                    <Col xs={24} xl={12} style={{ paddingLeft: 15, paddingRight: 15, paddingBottom: 30 }}>
                                        {genOrderedList()}
                                    </Col>

                                    <Col xs={24} lg={24} xl={12} style={{ paddingLeft: 15, paddingRight: 15, paddingBottom: 30 }}>
                                        {genPaymentMethod()}
                                    </Col>
                                </Row>

                                <Modal
                                    maskClosable={false}
                                    title={<label style={{ fontWeight: 100 }}>{cardPamentModal.title}</label>}
                                    visible={cardPamentModal.isShow}
                                    width={550}
                                    loading={paymentmethodLoading}
                                    onCancel={() => {
                                        // close modal
                                        setFormDefault();
                                    }}
                                    footer={[
                                        <Button
                                            type="primary"
                                            loading={paymentmethodLoading}
                                            onClick={() => {
                                                form.submit()
                                            }}
                                        >
                                            {!paymentmethodLoading ?
                                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                    <Icon icon="bi:send" style={{ color: "white" }} />
                                                    <label style={{ paddingLeft: 10, cursor: "pointer" }}>ส่งหลักฐานการชำระเงิน</label>
                                                </div>
                                                : []
                                            }
                                        </Button>
                                    ]}
                                >
                                    <Row gutter={[12, 12]}>
                                        <Col span={6}><label style={{ float: "right" }}>ธนาคาร</label></Col>
                                        <Col span={18}><label>ไทยพาณิชย์ (SCB) 0382 สาขาบิ๊กซี ติวานนท์</label></Col>

                                        <Col span={6}><label style={{ float: "right" }}>ชื่อบัญชี</label></Col>
                                        <Col span={18}><label>บริษัท กู๊ดเนส โกบอล กรุ้ป จำกัด</label></Col>

                                        <Col span={6}><label style={{ float: "right" }}>เลขที่บัญชี</label></Col>
                                        <Col span={18}><label>382-258213-9</label></Col>

                                        <Col span={6}><label style={{ float: "right" }}>ส่งหลักฐาน</label></Col>
                                        <Col span={18}>
                                            <Row gutter={[12, 0]}>
                                                <Col span={24}><label>วันเเละเวลาที่โอน (ดูในสลิปธนาคาร)</label></Col>

                                                <Col xs={24} sm={12} md={8} lg={5}>
                                                    <Form form={form} layout="vertical" onFinish={onFinish} >
                                                        <Form.Item
                                                            name="day"
                                                            label="วัน"
                                                            style={{ width: '100%' }}
                                                            rules={[{ required: true, message: 'กรุณาเลือกวัน' }]}
                                                        >
                                                            <Select
                                                                showSearch
                                                                style={{ width: '100%' }}
                                                                placeholder="วัน"
                                                                optionFilterProp="children"
                                                                allowClear
                                                            >
                                                                {day.map((val, index) =>
                                                                    <Option key={index} value={val.value}>{val.label}</Option>
                                                                )}
                                                            </Select>
                                                        </Form.Item>
                                                    </Form>
                                                </Col>

                                                <Col xs={24} sm={12} md={8} lg={7}>
                                                    <Form form={form} layout="vertical" onFinish={onFinish} >
                                                        <Form.Item
                                                            name="month"
                                                            label="เดือน"
                                                            style={{ width: '100%' }}
                                                            rules={[{ required: true, message: 'กรุณาเลือกเดือน' }]}
                                                        >
                                                            <Select
                                                                showSearch
                                                                style={{ width: '100%' }}
                                                                placeholder="เดือน"
                                                                optionFilterProp="children"
                                                                allowClear
                                                            >
                                                                {month.map((val, index) =>
                                                                    <Option key={index} value={val.value}>{val.label}</Option>
                                                                )}
                                                            </Select>
                                                        </Form.Item>
                                                    </Form>
                                                </Col>

                                                <Col xs={24} sm={12} md={8} lg={6}>
                                                    <Form form={form} layout="vertical" onFinish={onFinish} >
                                                        <Form.Item
                                                            name="year"
                                                            label="ปี"
                                                            style={{ width: '100%' }}
                                                            rules={[{ required: true, message: 'กรุณาเลือกปี' }]}
                                                        >
                                                            <Select
                                                                showSearch
                                                                style={{ width: '100%' }}
                                                                placeholder="ปี"
                                                                optionFilterProp="children"
                                                                allowClear
                                                            >
                                                                {year.map((val, index) =>
                                                                    <Option key={index} value={val.value}>{val.label}</Option>
                                                                )}
                                                            </Select>
                                                        </Form.Item>
                                                    </Form>
                                                </Col>

                                                <Col xs={24} sm={12} md={8} lg={6}>
                                                    <Form form={form} layout="vertical" onFinish={onFinish} >
                                                        <Form.Item
                                                            name="time"
                                                            label="เวลา"
                                                            style={{ width: '100%' }}
                                                            rules={[{ required: true, message: 'กรุณาเลือกเวลา' }]}
                                                        >
                                                            <TimePicker
                                                                showNow={false}
                                                                placeholder="เวลา"
                                                                style={{ width: '100%' }}
                                                                locale={{
                                                                    ...locale,
                                                                    lang: {
                                                                        ...locale.lang,
                                                                        // now: "เวลาปัจจุบัน",
                                                                        ok: "ตกลง",
                                                                    }
                                                                }}
                                                                format={"HH:mm"}
                                                            />
                                                        </Form.Item>
                                                    </Form>
                                                </Col>

                                                <Col span={24} style={{ paddingBottom: 6 }}>
                                                    <Form form={form} layout="vertical" onFinish={onFinish} >
                                                        <Form.Item
                                                            name="transferSlipImage"
                                                            label={
                                                                <>
                                                                    <label style={{ color: "red", margin: 0, padding: 0 }}>*</label>
                                                                    <label style={{ margin: 0, paddingBottom: 0, paddingLeft: 5 }}>หลักฐานการโอน (สลิป)</label>
                                                                </>
                                                            }
                                                        >
                                                            <div style={{ paddingBottom: 12 }}>
                                                                {transferSlipImage.data ?
                                                                    <Image
                                                                        style={{ width: 250, border: "1px solid #e8e8e8" }}
                                                                        alt="avatar"
                                                                        src={`${transferSlipImage.data}`}
                                                                        preview={{
                                                                            src: `${transferSlipImage.data}`,
                                                                        }}
                                                                    />
                                                                    :
                                                                    <Image
                                                                        style={{ width: 250, border: "1px solid #e8e8e8" }}
                                                                        src="error"
                                                                        fallback={`${imgDefaultUrl}`}
                                                                    />
                                                                }
                                                            </div>

                                                            <Upload
                                                                {...option}
                                                                listType="picture"
                                                                accept='image/jpeg, image/png, image/jfif'
                                                                maxCount={1}
                                                                showUploadList={false}
                                                            >
                                                                <Button
                                                                    type="primary"
                                                                    disabled={props.isDisabled}
                                                                    style={{ width: 245 }}
                                                                    icon={transferSlipImage.loading ? <LoadingOutlined /> : <UploadOutlined />}
                                                                >อัพโหลด</Button>
                                                            </Upload>
                                                        </Form.Item>
                                                    </Form>
                                                </Col>
                                            </Row>
                                        </Col>

                                        {width >= 590 ? <Col xs={24} sm={24} md={4} lg={4}></Col> : []}
                                        <Col xs={24} sm={24} md={20} lg={20}>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <Icon icon="carbon:information" style={{ color: "green", width: 20, height: 20 }} />
                                                <label style={{ paddingLeft: 5 }}>กรุณาเก็บหลักฐานการโอนเงินไว้</label>
                                            </div>
                                        </Col>

                                        {width >= 590 ? <Col xs={24} sm={24} md={4} lg={4}></Col> : []}
                                        <Col xs={24} sm={24} md={20} lg={20}>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <Icon icon="carbon:information" style={{ color: "green", width: 20, height: 20 }} />
                                                <label style={{ paddingLeft: 5 }}>การตรวจสอบจะใช้เวลาไม่เกิน 24 ชม. เราจะดำเนินการให้เร็วที่สุด</label>
                                            </div>
                                        </Col>
                                    </Row>
                                </Modal>
                            </div>
                            :
                            <LoadingPage loading={loading} />
                        }
                    </article>
                </Content>
            </article>

            <Footer />

            <Modal
                maskClosable={false}
                title={"ชำระเงินผ่านพร้อมเพย์"}
                visible={isModalPromptPay}
                onCancel={() => {
                    timerActiveRef.current = false;
                    setTimer({
                        time: null,
                        display: null,
                        isTimeOut: false
                    });
                    handlePromptPay();
                }}
                width={350}
                cancelButtonProps={{ hidden: true }}
                okButtonProps={{ hidden: true }}
                footer={false}
            >
                {timer?.isTimeOut === false ?
                    <Row>
                        <Col span={24}>
                            <center>
                                <Image
                                    height={425}
                                    width={"auto"}
                                    src="error"
                                    fallback={charge?.download_uri}
                                />
                            </center>
                        </Col>
                        <Col span={24}>
                            <center>
                                <label style={{ fontSize: 20 }}>ราคาคอร์ส {genCourseNet(1)} บาท</label>
                                <br />
                                <label>ชำระเงินภายใน <span style={{ fontWeight: "bold" }}>{!timer.isTimeOut > 0 ? timer.display : "00:00"}</span> นาที</label>
                            </center>
                        </Col>
                    </Row>
                    :
                    <center>
                        <Row>
                            <Col span={24}>
                                <Icon icon="oi:timer" style={{ color: "#23b14d", width: 60, height: 60 }} />
                            </Col>
                            <Col span={24}>
                                <label>หมดเวลาชำระเงิน</label>
                            </Col>
                            <Col span={24}>
                                <label>โปรดกดชำระเงินผ่าน QR Code (พร้อมเพย์) ใหม่อีกครั้ง</label>
                            </Col>
                        </Row>
                    </center>
                }
            </Modal>

            <Modal
                maskClosable={false}
                title={
                    <label>
                        {couponModal.title}
                        {couponMassageKey !== "COUPON_NOT_FOUND" ? <span style={{ fontWeight: 600, color: "#23B14D" }}> {couponSearch?.couponTitle}</span> : " ?"}
                    </label>
                }
                visible={couponModal.isShow}
                width={390}
                loading={loading}
                onCancel={() => {
                    handleCouponCloseModal()
                }}
                footer={[

                    <Button
                        type={(isUseCoupon.status && isUseCoupon.code === formCoupon.getFieldValue("couponCode")) ? "default" : "primary"}
                        loading={loading}
                        onClick={() => {
                            if (couponMassageKey === "COUPON_ACTIVE") {
                                if (isUseCoupon.status && isUseCoupon.code === formCoupon.getFieldValue("couponCode")) {
                                    setIsUseCoupon({
                                        code: null,
                                        status: false
                                    })
                                    handleCouponCloseModal()
                                } else {
                                    if (isUseCoupon.status && isUseCoupon.code !== formCoupon.getFieldValue("couponCode")) {
                                        // console.log("ใช้คูปองนี้ " + formCoupon.getFieldValue("couponCode") + " เเท่นที่คูปองนี้ " + isUseCoupon.code)
                                        handleCouponAlertOpenModal(
                                            'มีคูปองที่ใช้งานอยู่ก่อนหน้า!',
                                            <label>ใช้คูปองนี้ <span style={{ fontWeight: 600, color: "#23B14D" }}>{formCoupon.getFieldValue("couponCode")}</span> เเท่นที่คูปอง <span style={{ fontWeight: 600, color: "#23B14D" }}>{isUseCoupon.code}</span></label>,
                                            "REPLACE_COUPON"
                                        )
                                    } else {
                                        setIsUseCoupon({
                                            code: couponSearch?.code ?? null,
                                            status: true
                                        })
                                        formCoupon.resetFields()
                                        handleCouponCloseModal()
                                    }
                                }
                            } else {
                                setIsUseCoupon({
                                    code: null,
                                    status: false
                                })
                                handleCouponCloseModal()
                            }
                        }}
                    >
                        {!loading ?
                            <>
                                {couponMassageKey === "COUPON_ACTIVE" ?
                                    (isUseCoupon.status && isUseCoupon.code === formCoupon.getFieldValue("couponCode") ?
                                        <label style={{ cursor: "pointer" }}>ยกเลิกใช้งาน</label>
                                        :
                                        <label style={{ cursor: "pointer" }}>ใช้คูปองนี้</label>
                                    )
                                    :
                                    <label style={{ cursor: "pointer" }}>ค้นหาใหม่</label>
                                }
                            </>
                            : []
                        }
                    </Button>

                ]}
            >
                <div style={{ display: "flex", alignItems: "flex-start" }}>
                    <div>
                        <Icon icon="bxs:coupon" style={{ width: 80, height: 80, marginTop: -10, color: "#23B14D", marginLeft: -6 }} />
                    </div>
                    <div style={{ paddingLeft: 10 }}>
                        {couponMassageKey === "COUPON_USER_NOT_PRIVILEGE" ?
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <label>ผู้ใช้งานไม่มีสิทธิ์ใช้คูปองนี้</label>
                            </div>
                            : []
                        }

                        {couponMassageKey === "COUPON_COURSE_NOT_PRIVILEGE" ?
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <label>คอร์สนี้ไม่มีสิทธิ์ใช้คูปองนี้</label>
                            </div>
                            : []
                        }

                        {couponMassageKey === "COUPON_PERMISSION_FULL" ?
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <label>คูปองนี้มีผู้ใช้งานเต็มเเล้ว</label>
                            </div>
                            : []
                        }

                        {couponMassageKey === "COUPON_OVERDULE" ?
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <label>คูปองนี้เกินกำหนดการใช้งานเเล้ว</label>
                            </div>
                            : []
                        }

                        {couponMassageKey === "COUPON_NOT_PREMATURELY" ?
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <label>คูปองนี้ยังไม่เปิดให้ใช้ได้</label>
                            </div>
                            : []
                        }

                        {couponMassageKey === "COUPON_ACTIVE" ?
                            <div style={{ display: "grid", alignItems: "center", justifyContent: "center" }}>
                                <label>สิทธิพิเศษของคูปองนี้ ส่วนลด <span style={{ fontWeight: 600, color: "#23B14D" }}>{couponSearch.couponPrivilegeTypeId === 1 ? couponSearch.privilege + "%" : genFormatPrice(Number(couponSearch.privilege)) + " บาท"}</span></label>
                                <label>รหัสคูปองนี้ <span style={{ fontWeight: 600, color: "#23B14D" }}>{formCoupon.getFieldValue("couponCode")}</span> ซื้อคอร์สได้ในราคา <span style={{ fontWeight: 600, color: "#23B14D" }}>{genFormatPrice(Number(couponSearch.netPrice))}</span> บาท</label>
                                <label>หมดอายุวันที่ {moment(couponSearch.endDate).format("DD/MM/YYYY HH:mm")}</label>
                            </div>
                            : []
                        }

                        {couponMassageKey === "COUPON_NOT_FOUND" ?
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <label>ไม่พบคูปองนี้ <span style={{ fontWeight: 600, color: "#23B14D" }}>{formCoupon.getFieldValue("couponCode")}</span></label>
                            </div>
                            : []
                        }
                    </div>
                </div>
            </Modal>


            <Modal
                maskClosable={false}
                title={couponAlertModal.title}
                visible={couponAlertModal.isShow}
                width={390}
                loading={loading}
                onCancel={() => {
                    handleCouponAlertCloseModal()
                }}
                footer={[
                    <Button
                        type="primary"
                        onClick={() => {
                            if (couponAlertModal.eventKey === "REPLACE_COUPON") {
                                setIsUseCoupon({
                                    code: couponSearch?.code ?? null,
                                    status: true
                                })
                                setCoupon(couponSearch)
                                handleCouponAlertCloseModal()
                                handleCouponCloseModal()
                                formCoupon.resetFields()
                            }

                            if (couponAlertModal.eventKey === "REMOVE_COUPON") {
                                setIsUseCoupon({
                                    code: null,
                                    status: false
                                })
                                handleCouponAlertCloseModal()
                            }
                        }}
                    >
                        ยืนยัน
                    </Button>
                ]}
            >
                {couponAlertModal.description}
            </Modal>
        </>
    )
}