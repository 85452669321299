/* eslint-disable react-hooks/exhaustive-deps */

import { useState, useEffect, useRef } from 'react'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import {
    Row,
    Col,
    Button,
    Form,
    Popconfirm,
    Spin,
    Divider,
} from 'antd'
import "../../css/manageDescription.css"
import { Icon } from '@iconify/react'
import { useDispatch, useSelector } from "react-redux"
import { setCourseManageDescriptionStateToSuccess } from '../../../../actions/courseManageDescription.action'
import { setCourseManageAddCurrentStepStateToSuccess } from '../../../../actions/courseManageAddCurrentStep.action'
import { Notification } from '../../../../common/components/notification'
import { useDimensions } from '../../../../common/mamager/ScreenManager'
import { getCourseManageFetch, updateCourseFetch, updateCourseIsUpdateFetch, updateRequestOpenCourseDescriptionAdjustFetch } from '../../API'

export default function ManageDescript(props) {
    const [form] = Form.useForm()

    const dispatch = useDispatch()

    const { width } = useDimensions()

    const [course, setCourse] = useState(null)
    const [user, setUser] = useState({ uid: null })
    const [isStepActive, setIsStepActive] = useState(null)
    const [courseInspect, setcourseInspect] = useState(null)
    const [switchType, setSwitchType] = useState(null)

    const [loading, setLoading] = useState(true)

    const { resultCourseManageAddCurrentStep } = useSelector(({ courseManageAddCurrentStepReducer }) => courseManageAddCurrentStepReducer)
    const { resultDescription } = useSelector(({ courseManageDescriptionReducer }) => courseManageDescriptionReducer)

    const submitTypeRef = useRef(null)

    const [text, setText] = useState('')
    const textRef = useRef('')

    const setReducer = (description) => {
        // reducer
        dispatch(setCourseManageDescriptionStateToSuccess({ description }))
    }

    const onFinish = async (value) => {
        if (submitTypeRef.current === 'next') {
            // set session
            localStorage.setItem("stepsDescriptionClickable", 1)

            // reducer
            dispatch(setCourseManageAddCurrentStepStateToSuccess(Number(resultCourseManageAddCurrentStep) + 1))
        } else {
            // get data latest
            const resultCourseLatest = await getCourseManageFetch(user.uid, course.courseId)

            let obj = {
                // new
                courseDescription: switchType === "adjust-course" ? textRef.current : (resultDescription ? resultDescription.description : textRef.current),

                // old
                courseId: resultCourseLatest.courseId,
                approvalStatusId: resultCourseLatest.approvalStatusId,
                ownerCheckCourseId: resultCourseLatest.ownerCheckCourseId,
                courseCategoryId: resultCourseLatest.courseCategoryId,
                courseCertificateCategoryId: resultCourseLatest.courseCertificateCategoryId,
                courseFormatId: resultCourseLatest.courseFormatId,
                courseSubDescriptionName: resultCourseLatest.courseSubDescriptionName,
                courseTitleName: resultCourseLatest.courseTitleName,
                courseImageLocation: resultCourseLatest.courseImageLocation,
                courseView: resultCourseLatest.courseView,
                coursePrice: resultCourseLatest.coursePrice,
                courseDiscount: resultCourseLatest.courseDiscount,
                coursePriceDiscount: resultCourseLatest.coursePriceDiscount,
                discountDateEnd: resultCourseLatest.discountDateEnd,
                created_at: new Date(resultCourseLatest.created_at),
                updated_at: new Date(resultCourseLatest.updated_at),
            }

            if (switchType === "adjust-course") { // course adjust
                const resultUpdate = await updateRequestOpenCourseDescriptionAdjustFetch(
                    user.uid,               // uid
                    obj,                    // course
                    1,                      // inspectionCourseAdjustCategoryId
                    1,                      // isActive
                    course.courseId,        // courseId
                )
                if (resultUpdate.data.isSuccess) {
                    Notification("success", "ระบบ", "บันทึกการปรับเเก้สำเร็จ")
                } else {
                    Notification("error", "ระบบ", "บันทึกการปรับเเก้ไม่สำเร็จ")
                }
            } else if (switchType === "edit-course") { // course edit
                const resultUpdateCourse = await updateCourseFetch(
                    user.uid,               // uid
                    obj,                    // course
                    1,                      // isActive
                    course.courseId,        // courseId
                    "courseDescription",    // courseComponent
                )

                // update isUpdateCourseDescription true
                const resultIsUpdate = await updateCourseIsUpdateFetch(
                    user.uid,               // uid
                    1,                      // isActive
                    course.courseId,        // courseId
                    'courseDescription',    // use update field isUpdateCourseDescription
                )

                if (resultUpdateCourse.data.isSuccess && resultIsUpdate.data.isSuccess) {
                    Notification("success", "ระบบ", "บันทึกการปรับเเต่งสำเร็จ")
                } else {
                    Notification("error", "ระบบ", "บันทึกการปรับเเต่งไม่สำเร็จ")
                }
            }

            if (!isStepActive) {
                // reload
                props.handleCourseReload()
            }
        }
    }

    const detectDescription = () => {
        if (textRef.current !== '') {
            return Promise.resolve()
        } else {
            return Promise.reject(new Error('กรุณากรอกรายละเอียดคอร์ส'))
        }
    }

    const genDescription = () => {
        return (
            <>
                <Form
                    layout="vertical"
                    form={form}
                    onFinish={onFinish}
                >
                    <Form.Item
                        name="description"
                        rules={[{ required: true, validator: detectDescription }]}
                    >
                        <CKEditor
                            editor={ClassicEditor}
                            disabled={
                                switchType === "adjust-course" ?
                                    (props.courseInspect ?
                                        (props.courseInspect.course.inspectionCourseDescriptionApprovalId === 1 || props.courseInspect.inspectionCourseId === 2 ? true : false) : false)
                                    :
                                    (switchType === "edit-course" ?
                                        (courseInspect ?
                                            (courseInspect.inspectionCourseId !== 2 && courseInspect.inspectionCourseApprovalId !== 2 ?
                                                false
                                                :
                                                ((course.approvalStatusId === 1 || course.approvalStatusId === 2) && courseInspect.inspectionCourseId === 3 ?
                                                    false
                                                    :
                                                    (!props.inspectCourseApprovalId && !courseInspect.course.inspectionCourseDescriptionAdjustId && courseInspect.inspectionCourseId === 2 ?
                                                        false
                                                        :
                                                        true
                                                    )
                                                )
                                            )
                                            :
                                            false
                                        )

                                        :
                                        false
                                    )
                            }
                            config={{
                                toolbar: {
                                    items: [
                                        'heading', '|',
                                        'fontfamily', 'fontsize', '|',
                                        'bold', 'italic', 'underline', '|',
                                        'alignment', '|',
                                        'fontColor', 'fontBackgroundColor', '|',
                                        'bulletedList', 'numberedList', 'todoList', '|',
                                        'code', 'codeBlock', '|',
                                        'undo', 'redo'
                                    ],
                                    removeButtons: 'Subscript,Superscript'
                                }
                            }}
                            data={text}
                            onChange={async (event, editor) => {
                                const data = editor.getData()
                                setReducer(data)
                                textRef.current = data
                            }}
                        />
                    </Form.Item>
                </Form>

                <Divider style={{ marginTop: 1, marginBottom: 15 }} />
                <div style={{ fontSize: 14 }}>
                    <label>หมายเหตุ !</label><br />
                    <ul>
                        <li>ส่วนของ "รายละเอียดคอร์ส" ใช้บรรยายถึงสิ่งที่จะสอนว่าหัวข้อหลัก ๆ ในเรื่องที่สอนมีอะไรบ้าง หรือใช้บอกผู้เรียน เกี่ยวกกับสิ่งที่จะได้รับจากการเรียนคอร์สนี้ เเบบละเอียด</li>
                        <li>เมื่อ "รายละเอียดคอร์ส" ผ่านการตรวจสอบเเล้ว จะเผยแพร่สู่สาธารณะให้ผู้คนทั่วไปเห็นได้ทันที เเม้ว่าผู้เรียนจะยังไม่ได้ซื้อคอร์สนี้ก็ตาม</li>
                    </ul>
                </div>
            </>
        )
    }

    useEffect(() => {
        setLoading(true);

        setTimeout(function () {
            setCourse(props.course);
            setcourseInspect(props.courseInspect);
            setUser(props.user);
            setIsStepActive(props.isStepActive);
            setSwitchType(props.switchType);

            if (props.isStepActive) {
                if (resultDescription) {
                    textRef.current = resultDescription.description;
                    setText(resultDescription.description);
                }
            } else {
                if (props.course) {
                    textRef.current = props.course.courseDescription;
                    setText(props.course.courseDescription);
                }
            }

            setLoading(false);
        }, 500);
    }, [text]);

    return (
        <>
            {!loading ?
                <>
                    {isStepActive ?
                        <Row gutter={24} style={{ textAlign: 'left' }}>
                            <Col span={24}>
                                {genDescription()}
                            </Col>
                            <Divider style={{ marginTop: 1, marginBottom: 20 }} />
                            <Col span={24}>
                                <Row gutter={[24, 24]}>
                                    <Col xs={24} sm={12} md={12} lg={12}>
                                        <Button
                                            style={width > 992 ? { float: 'right', width: 130 } : ((width < 992 && width > 576) ? { float: 'right', width: 130 } : { width: '100%' })}
                                            type="default"
                                            onClick={() => dispatch(setCourseManageAddCurrentStepStateToSuccess(Number(resultCourseManageAddCurrentStep) - 1))}
                                        >
                                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                <Icon icon="fluent:arrow-step-back-20-filled" />
                                                <label style={{ paddingLeft: 10, cursor: "pointer" }}>ย้อนกลับ</label>
                                            </div>
                                        </Button>
                                    </Col>
                                    <Col xs={24} sm={12} md={12} lg={12}>
                                        <Button
                                            style={width > 992 ? { float: 'left', width: 130 } : ((width < 992 && width > 576) ? { float: 'left', width: 130 } : { width: '100%' })}
                                            type="primary"
                                            onClick={() => {
                                                submitTypeRef.current = 'next'
                                                form.submit()
                                            }}
                                        >
                                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                <Icon icon="fluent:arrow-next-20-filled" style={{ color: "white" }} />
                                                <label style={{ paddingLeft: 10, cursor: "pointer" }}>ถัดไป</label>
                                            </div>
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>

                        </Row>
                        :
                        <>
                            <Row
                                style={{
                                    paddingTop: 10,
                                    paddingBottom: 0,
                                    paddingLeft: 10,
                                    paddingRight: 10
                                }}
                            >
                                <Col span={24} style={{ padding: 10 }}>
                                    {genDescription()}
                                </Col>
                                <Divider style={{ marginTop: 1, marginBottom: 1 }} />
                                <Col span={24} style={{ padding: 20 }}>
                                    <center>
                                        {(courseInspect ? courseInspect.course.inspectionCourseDescriptionApprovalId === 2 : false) ?
                                            <Popconfirm
                                                title="คุณยืนยันบันทึกการปรับเเก้หรือไม่ ?"
                                                okText={<span style={{ width: 50 }}>ใช่</span>}
                                                onConfirm={() => {
                                                    submitTypeRef.current = 'update';
                                                    form.submit();
                                                }}
                                                cancelText={<span style={{ width: 50 }}>ไม่ใช่</span>}
                                                disabled={courseInspect.inspectionCourseId === 3 ? false : true}
                                            >
                                                <Row>
                                                    <Col span={24}>
                                                        <Button
                                                            type="primary"
                                                            disabled={courseInspect.inspectionCourseId === 3 ? false : true}
                                                        >
                                                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                                <Icon icon="bx:save" style={{ color: "white" }} />
                                                                <label style={{ paddingLeft: 10, cursor: "pointer" }}>บันทึกการปรับเเก้</label>
                                                            </div>
                                                        </Button>
                                                    </Col>
                                                    <Col span={24}>
                                                        {switchType === "edit-course" && courseInspect.inspectionCourseId === 2 && courseInspect.inspectionCourseDescriptionId === 1 ?
                                                            <label style={{ paddingTop: 10 }}>กำลังอยู่ระหว่างการตรวจสอบ ทำให้ไม่สามารถกดปุ่ม "บันทึกการเเก้ไข" ได้ในขณะนี้! จนกว่าจะตรวจสอบเสร็จ เเละเเจ้งผลให้ทราบ</label>
                                                            : []
                                                        }
                                                    </Col>
                                                </Row>
                                            </Popconfirm>
                                            :
                                            <>
                                                {switchType === "edit-course" ?
                                                    <Popconfirm
                                                        title="คุณยืนยันการบันทึกการปรับเเต่งหรือไม่ ?"
                                                        okText={<span style={{ width: 50 }}>ใช่</span>}
                                                        onConfirm={() => {
                                                            submitTypeRef.current = 'update';
                                                            form.submit();
                                                        }}
                                                        cancelText={<span style={{ width: 50 }}>ไม่ใช่</span>}
                                                    >
                                                        <Button type="primary">
                                                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                                <Icon icon="bx:save" style={{ color: "white" }} />
                                                                <label style={{ paddingLeft: 10, cursor: "pointer" }}>บันทึกการปรับเเต่ง</label>
                                                            </div>
                                                        </Button>
                                                    </Popconfirm>
                                                    : []}
                                            </>
                                        }
                                    </center>
                                </Col>
                            </Row>

                            {(switchType === "adjust-course" ?
                                (courseInspect ? courseInspect.course.inspectionCourseDescriptionApprovalId === 2 : false)
                                :
                                (switchType === "edit-course" ?
                                    (
                                        (courseInspect ? (
                                            (courseInspect.course.inspectionCourseDescriptionApprovalId === 2 && courseInspect.inspectionCourseDescriptionId === 1 && courseInspect.inspectionCourseId === 3) ||
                                            (courseInspect.amountEdit > 0 && courseInspect.course.inspectionCourseDescriptionApprovalId === 2)
                                        ) : false)
                                    ) : false
                                )
                            ) ?
                                <div style={{ paddingLeft: 20, paddingRight: 20, paddingBottom: 20, backgroundColor: 'white' }}>
                                    <Row
                                        style={{
                                            padding: 10,
                                            border: '1px solid #EEEEEE',
                                            fontSize: 14
                                        }}
                                    >
                                        <Col span={24} style={{ paddingLeft: 10 }}>
                                            เหตุผลไม่ผ่าน
                                        </Col>
                                        <Col span={24} style={{ paddingLeft: 10, paddingRight: 10, paddingTop: 5 }}>
                                            <p style={{ color: "red", backgroundColor: "#EEEEEE", padding: 8 }}>
                                                {courseInspect.course.reasonCourseDescriptionNotApproval}
                                            </p>
                                        </Col>
                                        <Col span={24} style={{ padding: 10, borderTop: "1px solid #EEEEEE" }}>
                                            ตัวอย่าง เกร็ดความรู้ เเละสิ่งที่ควรหลีกเลี่ยง
                                        </Col>
                                        <Col span={24}>
                                            <label style={{ paddingLeft: 20 }}>ควรใส่รายละเอียดให้สอดคล้องเหมาะสมกับเนื้อหาไม่ใช้คำคุมเครือเข้าใจยากเน้นถ้อยคำดึงดูดชัดเจน และเน้นเนื้อหาหลักที่ต้องการสอน ไม่ควรใช้คำหยาบคาย</label>
                                        </Col>
                                    </Row>
                                </div>
                                : []}
                        </>
                    }
                </>
                :
                <center><Spin size="large" style={{ marginTop: 200, marginBottom: 200 }} /></center>
            }
        </>
    )
}

