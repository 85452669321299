/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from 'react'
import {
    Col,
    Divider,
    Radio,
    Row,
    Input,
    Button,
    Form,
    Popconfirm,
    Spin,
} from 'antd'
import moment from 'moment'
import { Notification } from '../../../../../common/components/notification'
import { updateInspectRequestEditCoursePriceByIdFetch, updateInspectRequestOpenCoursePriceByIdFetch } from '../../../API'
import LoadingPage from '../../../../../common/components/loading/page'

const { TextArea } = Input
const formatDateTime = "DD/MM/YYYY HH:mm"

export default function InspectPrice(props) {

    const [listOriginal, setListOriginal] = useState(null)
    const [listReqtEdit, setListReqtEdit] = useState(null)
    const [inspect, setInspect] = useState(null)

    const [courseId, setCourseId] = useState(null)
    const [uid, setUid] = useState(null)
    const [isCloseInspect, setIsCloseInspect] = useState({
        status: null,
        message: null,
    })
    const [userType, setUserType] = useState(null)

    const [loading, setLoading] = useState(true)

    const [inspectCourseRadio, setInspectCourseRadio] = useState(1)

    const [formAdminInspectOpenCourse] = Form.useForm()
    const [formAdminInspectEditCourse] = Form.useForm()

    const [isSendResults, setIsSendResults] = useState(true)

    const detectReasonNotApploval = (rules, value) => {
        if (inspectCourseRadio === 2) {
            if (value) {
                return Promise.resolve()
            } else {
                return Promise.reject(new Error('กรุณากรอก เหตุผลที่ไม่ผ่าน'))
            }

        } else {
            return Promise.resolve()
        }
    }

    const onInspectCourseChange = e => {
        if (e.target.value === 1) {
            formAdminInspectOpenCourse.setFieldsValue({
                inspectCourseRadio: e.target.value,
                reasonNotApploval: null,
            })
        }
        setInspectCourseRadio(e.target.value)
    }

    const onAdminInspectCourseFinish = async (values) => {
        if (!inspect.inspectionCoursePriceAdjustId && !inspect.inspectionCoursePriceApprovalId) { // inspect first time 
            // console.log("inspect first time")
            updateInspectCoursePrice(values.inspectCourseRadio, values.reasonNotApploval)
        } else { // inspect second time or more 
            // console.log("inspect second time or more")
            updateInspectCoursePrice(values.inspectCourseRadio, values.reasonNotApploval)
        }
    }

    const updateInspectCoursePrice = async (inspectCourseRadio, reasonNotApploval) => {
        let inspectCourseApploval = inspectCourseRadio ? inspectCourseRadio : null
        let obj = {
            courseId,
            uid,
            inspectionCoursePriceApprovalId: inspectCourseApploval,
            inspectionCoursePriceAdjustId: inspectCourseApploval === 2 ? 2 : (inspectCourseApploval === 1 ? null : inspectCourseApploval),
            inspectionCoursePriceId: 1,
            reasonCoursePriceNotApproval: reasonNotApploval ? reasonNotApploval : null,
        }
        // console.log("obj : ", obj)

        let result = { isSuccess: false }
        if (userType === "admin-inspect-open-course") {
            result = await updateInspectRequestOpenCoursePriceByIdFetch(obj)
        } else if (userType === "admin-inspect-edit-course") {
            // console.log("course price : ", obj)
            result = await updateInspectRequestEditCoursePriceByIdFetch(obj)
        }

        if (result.isSuccess) {
            props.reloadInspectCourse()
            Notification("success", "ระบบ", "บันทึกผลตรวจสอบสำเร็จ")
        } else {
            Notification("error", "ระบบ", "บันทึกผลตรวจสอบไม่สำเร็จ กรุณาลองใหม่อีกครั้ง")
        }
    }

    useEffect(() => {
        setLoading(true)

        setUserType(props.userType)
        setListOriginal(props.courseOriginal)

        setInspectCourseRadio(props.inspect.course.inspectionCoursePriceApprovalId)
        setInspect(props.inspect.course)

        setCourseId(props.courseId)
        setUid(props.uid)

        if ( // open button send result to teacher
            props.inspect.inspectionCourseCardId !== 2 &&               // ต้องไม่รอตรวจสอบ
            props.inspect.inspectionCourseDescriptionId !== 2 &&        // ต้องไม่รอตรวจสอบ
            props.inspect.inspectionCoursePriceId !== 2 &&              // ต้องไม่รอตรวจสอบ

            props.inspect.inspectionCourseMediaIntroId !== 2 &&         // ต้องไม่รอตรวจสอบ
            props.inspect.inspectionCourseDocumentId !== 2 &&           // ต้องไม่รอตรวจสอบ
            props.inspect.inspectionCourseVideoId !== 2 &&              // ต้องไม่รอตรวจสอบ

            props.inspect.inspectionCourseId === 3                      // สถานะโดยรวม คือ รอตรวจสอบ [2]
        ) {
            setIsSendResults(true)
        } else {
            setIsSendResults(false)
        }

        setTimeout(function () {
            if (props.userType === "admin-inspect-open-course") {
                setIsCloseInspect(props.isCloseInspect)

                formAdminInspectOpenCourse.setFieldsValue({
                    inspectCourseRadio: props.inspect.course.inspectionCoursePriceApprovalId ? props.inspect.course.inspectionCoursePriceApprovalId : undefined,
                    reasonNotApploval: props.inspect.course.reasonCoursePriceNotApproval ? props.inspect.course.reasonCoursePriceNotApproval : undefined
                })
            } else if (props.userType === "admin-inspect-edit-course") {
                setListReqtEdit(props.courseReqtEdit)

                formAdminInspectEditCourse.setFieldsValue({
                    inspectCourseRadio: props.inspect.course.inspectionCoursePriceApprovalId ? props.inspect.course.inspectionCoursePriceApprovalId : undefined,
                    reasonNotApploval: props.inspect.course.reasonCoursePriceNotApproval ? props.inspect.course.reasonCoursePriceNotApproval : undefined
                })
            }

            setLoading(false)
        }, 500)
    }, [])

    const genAdminInspectOpenCourse = (isSaveInspect) => {
        return (
            <Form
                layout="vertical"
                form={formAdminInspectOpenCourse}
                onFinish={onAdminInspectCourseFinish}
            >
                <Row>
                    <Col span={12} style={{ paddingBottom: 0 }}>
                        <Row gutter={[12, 12]}>
                            <Col span={6}>ราคาปกติ</Col>
                            <Col span={18}>{listOriginal?.coursePrice ? Intl.NumberFormat("en", { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(listOriginal.coursePrice) : "0.00"} บาท</Col>

                            <Col span={6}>ราคาที่ลด</Col>
                            <Col span={18}>{listOriginal?.coursePriceDiscount ? Intl.NumberFormat("en", { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(listOriginal.coursePriceDiscount) : "0.00"} บาท</Col>

                            <Col span={6}>ลดถึงวันที่</Col>
                            <Col span={18}>{listOriginal?.discountDateEnd ? moment(listOriginal.discountDateEnd).format(formatDateTime) : "-"}</Col>

                            <Col span={6}>ลดเหลือ</Col>
                            <Col span={18}>{listOriginal?.coursePrice && listOriginal?.coursePriceDiscount ? Intl.NumberFormat("en", { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format((listOriginal.coursePrice - listOriginal.coursePriceDiscount)) : "0"} บาท, {listOriginal ? ((listOriginal.coursePriceDiscount * 100) / listOriginal.coursePrice).toFixed(2) : "0"} %</Col>
                        </Row>
                    </Col>

                    {isSaveInspect ?
                        <>
                            <Divider style={{ marginBottom: 10, marginTop: 10 }} />
                            {!isCloseInspect.status ?
                                <>
                                    <Col span={24} style={{ textAlign: "center" }}>
                                        <Form.Item
                                            name="inspectCourseRadio"
                                            rules={[{ required: true, message: 'กรุณาเลือก ผลการตรวจ' }]}
                                        >
                                            <Radio.Group
                                                disabled={isSendResults}
                                                onChange={onInspectCourseChange}
                                                value={inspectCourseRadio}
                                            >
                                                <Radio value={1}>ผ่าน</Radio>
                                                <Radio value={2}>ไม่ผ่าน</Radio>
                                            </Radio.Group>
                                        </Form.Item>
                                    </Col>
                                    {inspectCourseRadio === 2 ?
                                        <Col span={24}>
                                            <Form.Item
                                                name="reasonNotApploval"
                                                label="เหตุผลที่ไม่ผ่าน"
                                                rules={[{ required: true, validator: detectReasonNotApploval }]}
                                            >
                                                <TextArea
                                                    disabled={isSendResults}
                                                    placeholder="เหตุผลที่ไม่ผ่าน"
                                                    autoSize={{ minRows: 3, maxRows: 5 }}
                                                />
                                            </Form.Item>
                                        </Col>
                                        : []}
                                    <Col span={24} style={{ textAlign: "center" }}>
                                        <Popconfirm
                                            disabled={isSendResults}
                                            title="คุณยืนยันบันทึกผลตรวจสอบหรือไม่ ?"
                                            okText={<span style={{ width: 50 }}>ใช่</span>}
                                            onConfirm={() => formAdminInspectOpenCourse.submit()}
                                            cancelText={<span style={{ width: 50 }}>ไม่ใช่</span>}
                                        >
                                            <Button
                                                type="primary"
                                                style={{ width: 150 }}
                                                disabled={isSendResults}
                                            >บันทึกผลตรวจสอบ</Button>
                                        </Popconfirm>
                                    </Col>
                                </>
                                :
                                <Col span={24} style={{ textAlign: "center" }}>
                                    <center><label style={{ color: "red" }}>{isCloseInspect.message}</label></center>
                                </Col>
                            }
                        </>
                        : []}
                </Row>
            </Form>
        )
    }

    const genAdminInspectEditCourse = () => {
        return (
            <Row>
                <Col
                    span={12}
                    style={{
                        height: 30,
                        borderRight: "1px solid #DEDEDE",
                        borderBottom: "1px solid #DEDEDE"
                    }}
                ><center>เเบบเก่า</center></Col>
                <Col
                    span={12}
                    style={{
                        height: 30,
                        borderBottom: "1px solid #DEDEDE"
                    }}
                ><center>เเบบที่ขอเเก้ไข</center></Col>

                <Col
                    span={12}
                    style={{
                        paddingTop: 15,
                        borderRight: "1px solid #DEDEDE"
                    }}
                >
                    {genAdminInspectOpenCourse(false)}
                </Col>
                <Col
                    span={12}
                    style={{
                        paddingTop: 15,
                        paddingLeft: 15
                    }}
                >
                    <Row>
                        <Col span={12} style={{ paddingBottom: 0, fontSize: 14 }}>
                            <Row gutter={[12, 12]}>
                                <Col span={6}>ราคาปกติ</Col>
                                <Col span={18}>
                                    {listReqtEdit?.coursePrice ?
                                        Intl.NumberFormat("en", { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(listReqtEdit.coursePrice)
                                        : "0"
                                    } บาท
                                </Col>

                                <Col span={6}>ราคาที่ลด</Col>
                                <Col span={18}>
                                    {listReqtEdit?.coursePriceDiscount ?
                                        Intl.NumberFormat("en", { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(listReqtEdit.coursePriceDiscount)
                                        : "0"
                                    } บาท
                                </Col>

                                <Col span={6}>ลดถึงวันที่</Col>
                                <Col span={18}>
                                    {listReqtEdit?.discountDateEnd ?
                                        moment(listReqtEdit.discountDateEnd).format(formatDateTime)
                                        : "-"
                                    }
                                </Col>

                                <Col span={6}>ลดเหลือ</Col>
                                <Col span={18}>
                                    {listReqtEdit?.coursePrice && listReqtEdit?.coursePriceDiscount ?  
                                    <>
                                        {Number(listReqtEdit.coursePrice) !== 0 && Number(listReqtEdit.coursePriceDiscount) !== 0 ?
                                            Intl.NumberFormat("en", { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format((listReqtEdit.coursePrice - listReqtEdit.coursePriceDiscount)) : "0"} บาท, {listReqtEdit ? ((listReqtEdit.coursePriceDiscount * 100) / listReqtEdit.coursePrice).toFixed(2)
                                                : "0"
                                        } %
                                    </>
                                    : "-"}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>

                <Divider style={{ marginBottom: 10, marginTop: 10 }} />
                {props.inspect.inspectionCoursePriceId ?
                    <Col span={24}>
                        <Form
                            layout="vertical"
                            form={formAdminInspectEditCourse}
                            onFinish={onAdminInspectCourseFinish}
                        >
                            <Col span={24} style={{ textAlign: "center" }}>
                                <Form.Item
                                    name="inspectCourseRadio"
                                    rules={[{ required: true, message: 'กรุณาเลือก ผลการตรวจ' }]}
                                >
                                    <Radio.Group
                                        disabled={isSendResults}
                                        onChange={onInspectCourseChange}
                                        value={inspectCourseRadio}
                                    >
                                        <Radio value={1}>ผ่าน</Radio>
                                        <Radio value={2}>ไม่ผ่าน</Radio>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>

                            {inspectCourseRadio === 2 ?
                                <Col span={24}>
                                    <Form.Item
                                        name="reasonNotApploval"
                                        label="เหตุผลที่ไม่ผ่าน"
                                        rules={[{ required: true, validator: detectReasonNotApploval }]}
                                    >
                                        <TextArea
                                            disabled={isSendResults}
                                            defaultValue={isSendResults}
                                            placeholder="เหตุผลที่ไม่ผ่าน"
                                            autoSize={{ minRows: 3, maxRows: 5 }}
                                        />
                                    </Form.Item>
                                </Col>
                                : []}

                            <Col span={24} style={{ textAlign: "center" }}>
                                <Popconfirm
                                    disabled={isSendResults}
                                    title="คุณยืนยันบันทึกผลตรวจสอบหรือไม่ ?"
                                    okText={<span style={{ width: 50 }}>ใช่</span>}
                                    onConfirm={() => formAdminInspectEditCourse.submit()}
                                    cancelText={<span style={{ width: 50 }}>ไม่ใช่</span>}
                                >
                                    <Button
                                        type="primary"
                                        style={{ width: 150 }}
                                        disabled={isSendResults}
                                    >
                                        บันทึกผลตรวจสอบ
                                    </Button>
                                </Popconfirm>
                            </Col>
                            :
                            <Col span={24} style={{ textAlign: "center" }}>
                                <center><label style={{ color: "red" }}>{isCloseInspect.message}</label></center>
                            </Col>
                        </Form>
                    </Col>
                    : []}
            </Row>
        )
    }

    return (
        <>
            {!loading ?
                <>
                    {userType === "admin-inspect-open-course" ? genAdminInspectOpenCourse(true) : []}
                    {userType === "admin-inspect-edit-course" ? genAdminInspectEditCourse() : []}
                </>
                :
                <LoadingPage loading={loading} isTitleActive={true} />
            }
        </>
    )
}
