import { 
    HTTP_COURSE_MANAGE_CARD_FETCHING, 
    HTTP_COURSE_MANAGE_CARD_SUCCESS, 
    HTTP_COURSE_MANAGE_CARD_FAILED,
} from "../constants"

export const setCourseManageCardStateToFetching = () => ({
    type: HTTP_COURSE_MANAGE_CARD_FETCHING,
})

export const setCourseManageCardStateToSuccess = (payload) => ({
    type: HTTP_COURSE_MANAGE_CARD_SUCCESS,
    payload
})

export const setCourseManageCardStateToFailed = () => ({
    type: HTTP_COURSE_MANAGE_CARD_FAILED,
})