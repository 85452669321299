import {
    HTTP_REGISTER_COURSE_CATEGORY_INTEREST_FETCHING,
    HTTP_REGISTER_COURSE_CATEGORY_INTEREST_SUCCESS,
    HTTP_REGISTER_COURSE_CATEGORY_INTEREST_FAILED
} from "../constants"

const initialState = {
    resultCourseCategoryInterest: {
        courseCategoryInterestedRank1: undefined,
        courseCategoryInterestedRank2: undefined,
        courseCategoryInterestedRank3: undefined,
    },
    isFetching: false,
    isError: false
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, { type, payload }) => {
    switch (type) {

        case HTTP_REGISTER_COURSE_CATEGORY_INTEREST_FETCHING:
            return { ...state, resultCourseCategoryInterest: null, isFetching: true, isError: false }

        case HTTP_REGISTER_COURSE_CATEGORY_INTEREST_SUCCESS:
            return { ...state, resultCourseCategoryInterest: payload, isFetching: false, isError: false }

        case HTTP_REGISTER_COURSE_CATEGORY_INTEREST_FAILED:
            return { ...state, resultCourseCategoryInterest: null, isFetching: false, isError: true }

        default:
            return state
    }
}
