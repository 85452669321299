import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import InputMask from "react-input-mask"
import {
    Card,
    Row,
    Col,
    Form,
    Input,
    Button
} from 'antd'
import { getAdminByIdFetch, getTokenByIdFetch, sendLineNotifyToGroup } from '../../API'
import { adminLoginToken } from '../../../../common/mamager/IdManager'

export default function LoginERP() {
    const [form] = Form.useForm()

    const navigate = useNavigate()

    const [tokenActive, setTokenActive] = useState(true)
    const [loading, setLoading] = useState(false)
    const [loginCorrect, setIsLoginCorrect] = useState(null)

    // token =======================================
    const [tokenLoading, setTokenLoading] = useState(false)
    const [tokenClickFirst, setTokenClickFirst] = useState(true)
    // token =======================================

    const [usernameForToken, setUsernameForToken] = useState(null)

    const onFinish = async (values) => {
        setLoading(true)

        let username = values.username ? values.username : null
        let password = values.password
        let token = values.token

        const resultAdmin = await getAdminByIdFetch(username, password)
        // console.log("resultAdmin : ", resultAdmin)

        if (resultAdmin) {
            if (resultAdmin.isAdmin === "correct") {

                const resultToken = await getTokenByIdFetch(username, token)
                // console.log("resultToken : ", resultToken)

                if (resultToken === "correct") {
                    openAdminManage(
                        resultAdmin.adminListId,
                        resultAdmin.adminTypeId,
                        username,
                        password,
                        token
                    )
                    setIsLoginCorrect(true)
                } else {
                    setIsLoginCorrect(false)
                }
            } else {
                setIsLoginCorrect(false)
            }
        } else {
            setIsLoginCorrect(false)
        }

        setLoading(false)
    }

    const openAdminManage = (adminListId, adminTypeId, username, password, token) => {
        navigate(
            "/erp-manage",
            {
                state: {
                    adminListId,
                    adminTypeId,
                    username,
                    password,
                    token
                }
            }
        )
    }

    const handleUserPass = () => {
        // on / off tokenActive
        let value = form.getFieldValue();
        if (value.username !== '' && value.username !== undefined && value.password !== '' && value.password !== undefined) {
            setTokenActive(false)
        } else {
            setTokenActive(true)
        }
    }

    const InputToken = props => (
        <InputMask
            maskChar={null}
            mask={"****-****-****-****-****"}
            {...props}
        // formatChars
        // '9': '[0-9]',
        // 'a': '[A-Za-z]',
        // '*': '[A-Za-z0-9]'
        />
    )

    return (
        <div style={{ backgroundColor: '#222222' }}>
            <article>
                <Row
                    style={{
                        width: 500,
                        top: "50%",
                        left: "50%",
                        position: "absolute",
                        msTransform: "translate(-50%, -50%)",
                        transform: "translate(-50%, -50%)",
                    }}
                >
                    <Col span={24}>
                        <Card>
                            <Row
                                gutter={[12, 0]}
                                style={{
                                    paddingTop: '10%',
                                    paddingBottom: '10%',
                                    paddingLeft: '10%',
                                    paddingRight: '10%'
                                }}
                            >
                                <Col span={24} style={{ paddingBottom: 15 }}><h4 style={{ float: "left" }}>เข้าสู่ระบบ Admin</h4></Col>

                                <Col span={24} style={{}}>
                                    <Form layout="vertical" form={form} onFinish={onFinish}>
                                        <Form.Item
                                            label="ชื่อผู้ใช้"
                                            name="username"
                                            rules={[{ required: true, message: <label style={{ float: "left" }}>กรุณากรอก username!</label> }]}
                                            style={{}}
                                            onChange={(e) => setUsernameForToken(e.target.value)}
                                        >
                                            <Input onChange={() => handleUserPass()} />
                                        </Form.Item>
                                    </Form>
                                </Col>

                                <Col span={24} style={{}}>
                                    <Form layout="vertical" form={form} onFinish={onFinish}>
                                        <Form.Item
                                            label="รหัสผ่าน"
                                            name="password"
                                            rules={[{ required: true, message: <label style={{ float: "left" }}>กรุณากรอก password!</label> }]}
                                            style={{}}
                                        >
                                            <Input.Password onChange={() => handleUserPass()} />
                                        </Form.Item>
                                    </Form>
                                </Col>

                                <Col span={15} style={{}}>
                                    <Form layout="vertical" form={form} onFinish={onFinish}>
                                        <Form.Item
                                            label="Token"
                                            name="token"
                                            rules={[{ required: true, message: <label style={{ float: "left" }}>กรุณากรอก token!</label> }]}
                                            style={{}}
                                        >
                                            <InputToken
                                                disabled={tokenActive}
                                                inputMode="text"
                                                placeholder="XXXX-XXXX-XXXX-XXXX-XXXX"
                                                style={{ width: '100%', paddingTop: 4, paddingBottom: 4, border: '1px solid #D5D5D5' }}
                                                type="text"
                                            />
                                        </Form.Item>
                                    </Form>
                                </Col>
                                <Col span={9} style={{}}>
                                    <Button
                                        disabled={tokenActive}
                                        loading={tokenLoading}
                                        style={{ marginTop: 31, width: '100%' }}
                                        type="primary"
                                        onClick={async () => {
                                            setTokenLoading(true)

                                            setTokenClickFirst(false)
                                            const token = await adminLoginToken(usernameForToken)
                                            await sendLineNotifyToGroup(usernameForToken, token)
                                            // console.log("TOKEN : ", token)

                                            setTokenLoading(false)
                                        }}
                                    >
                                        {tokenClickFirst ? <>ขอ Token</> : <>ขอ Token ใหม่</>}
                                    </Button>
                                </Col>

                                <Col span={24}>
                                    {loginCorrect !== null ? (loginCorrect ? [] : <label style={{ color: "red" }}>กรอกรหัสผ่านไม่ถูกต้อง!</label>) : []}
                                </Col>

                                <Col span={24}>
                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                        <Button
                                            type="primary"
                                            size="large"
                                            onClick={() => form.submit()}
                                            loading={loading}
                                        >
                                            {!loading ? "เข้าสู่ระบบ" : []}
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
            </article>
        </div>
    )
}
