/* eslint-disable react-hooks/exhaustive-deps */

import {
    Button,
    Col,
    Form,
    Input,
    Modal,
    Row,
    Select,
    Upload
} from 'antd'
import { useEffect, useState } from 'react'
import { LoadingOutlined, UploadOutlined } from '@ant-design/icons'
import { apiStorageUrl, bucketName } from '../../../constants'
import { Icon } from '@iconify/react'
import { Notification } from '../../../common/components/notification'
import { useDimensions } from '../../../common/mamager/ScreenManager'
import { getUserTypeFetch, insertComplaintFetch } from './API'

const { TextArea } = Input
const { Option } = Select

export default function Complaint(props) {

    const language = "th"
    const [form] = Form.useForm()
    const { width } = useDimensions()

    const [isModalComplaintActive, setIsModalComplaintActive] = useState(false)

    const [userTypes, setUserTypes] = useState([])
    const [attachAdditionalComplaintDocument, setAttachAdditionalComplaintDocument] = useState({
        data: null,
        loading: false
    })

    const optionAttachAdditionalComplaintDocument = {
        name: 'file',
        action: `${apiStorageUrl}/upload/file`,
        data: {
            // uploaded/file/complaint
            bucket: bucketName,
            folder: "file/complaint"
        },
        headers: {
            authorization: 'authorization-text',
        },
        onChange(info) {
            if (info.file.status === 'done') {
                let result = info.file.response;
                console.log("info : ", info.file.response)
                if (result?.isSuccess) {
                    setAttachAdditionalComplaintDocument({
                        data: result.url,
                        loading: false,
                    })
                }
            } else {
                setAttachAdditionalComplaintDocument({ loading: true })
            }

            if (info.file.status === 'done') {
                Notification("success", "ระบบ", "อัพโหลดเอกสารเพิ่มเติมสำเร็จ")
            } else if (info.file.status === 'error') {
                Notification("error", "ระบบ", "ไม่สามารถอัพโหลดเอกสารเพิ่มได้ กรุณาลองใหม่อีกครั้ง")
            }
        },
        progress: {
            strokeColor: {
                '0%': '#108ee9',
                '100%': '#87d068',
            },
            strokeWidth: 3,
            format: percent => `${parseFloat(percent.toFixed(2))}%`,
        },
    }

    const onFinish = async (value) => {
        let complaintTopic = value.complaintTopic ? value.complaintTopic : null
        let complaintDetails = value.complaintDetails ? value.complaintDetails : null
        let userTypeId = value.userTypeId ? value.userTypeId : null
        let attachAdditionalComplaintDocumentUrl = attachAdditionalComplaintDocument.data

        let obj = {
            uid: props.uid,
            complaintTopic,
            complaintDetails,
            userTypeId,
            complaintActionStatusId: 2,
            attachAdditionalComplaintDocumentUrl
        }
        // console.log("obj : ", obj)

        const result = await insertComplaintFetch(obj)
        if (result.isSuccess) {
            setIsModalComplaintActive(false)
            Modal.success({
                content: 'ส่งเรื่องร้องเรียนสำเร็จ',
                okText: "ตกลง"
            })
            // Notification("success", "ระบบ", "ส่งเรื่องร้องเรียนสำเร็จ")
        } else {
            Notification("success", "ระบบ", "ส่งเรื่องร้องเรียนไม่สำเร็จ กรุณาลองใหม่อีกครั้ง")
        }
    }

    // const detectAttachAdditionalComplaintDocument = (rule, value) => {
    //     if (attachAdditionalComplaintDocument.data) {
    //         return Promise.resolve();
    //     } else {
    //         return Promise.reject(new Error('กรุณาอัพโหลดรายละเอียดร้องเรียน'))
    //     }
    // }

    const getUserTypes = async () => {
        const result = await getUserTypeFetch(language)
        // console.log("getUserTypeFetch : ", result)
        if (result) {
            setUserTypes(result)
        }
    }

    const getBaseApi = async () => {
        await getUserTypes()
    }

    useEffect(() => {
        getBaseApi()
    }, [])

    useEffect(() => {
        form.resetFields()
        setAttachAdditionalComplaintDocument({
            data: null,
            loading: false
        })
        setIsModalComplaintActive(props.isModalComplaintActive)
    }, [props.isModalComplaintActive])

    return (
        <>
            <Modal
                maskClosable={false}
                title={
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <Icon icon="ic:outline-feedback" style={{ color: "#23B14D", width: 20, height: 20 }} />
                        <span style={{ paddingLeft: 10 }}>เรื่องร้องเรียน</span>
                    </div>
                }
                visible={isModalComplaintActive}
                onCancel={() => {
                    props.handleModalComplaintActive()
                }}
                width={width > 800 ? 600 : "90%"}
                cancelButtonProps={{ hidden: true }}
                okButtonProps={{ hidden: true }}
                footer={[
                    <Button
                        type="primary"
                        onClick={() => form.submit()}
                    >
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <Icon icon="bi:send" style={{ color: "white" }} />
                            <label style={{ paddingLeft: 10, cursor: "pointer" }}>ส่งเรื่องร้องเรียน</label>
                        </div>
                    </Button>
                ]}
            >
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={onFinish}
                >
                    <Row>
                        <Col span={24}>
                            <Form.Item
                                label="หัวข้อร้องเรียน"
                                name="complaintTopic"
                                rules={[{ required: true, message: 'กรุณากรอกหัวข้อร้องเรียน' }]}
                            >
                                <Input style={{ width: "100%" }} />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                label="ประเภทผู้ร้องเรียน"
                                name="userTypeId"
                                rules={[{ required: true, message: 'กรุณาเลือกประเภทผู้ร้องเรียน' }]}
                            >
                                <Select
                                    showSearch
                                    style={{ width: '100%' }}
                                    optionFilterProp="children"
                                    allowClear
                                >
                                    {userTypes.map((val, index) =>
                                        <Option key={index} value={val.value}>{val.label}</Option>
                                    )}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                label="รายละเอียดร้องเรียน"
                                name="complaintDetails"
                                rules={[{ required: true, message: 'กรุณากรอกรายละเอียดร้องเรียน' }]}
                            >
                                <TextArea
                                    showCount
                                    maxLength={500}
                                    style={{ width: "100%" }}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label="เเนบเอกสารเพิ่มเติม"
                                name="attachAdditionalComplaintDocument"
                            // rules={[{ required: true, validator: detectAttachAdditionalComplaintDocument }]}
                            >
                                <Upload
                                    {...optionAttachAdditionalComplaintDocument}
                                    // listType="picture"
                                    accept='application/pdf, image/jpeg, image/png, image/jfif'
                                    maxCount={1}
                                // showUploadList={}
                                >
                                    <Button
                                        type="primary"
                                        style={{ width: 130 }}
                                        icon={attachAdditionalComplaintDocument.loading ? <LoadingOutlined /> : <UploadOutlined />}
                                    >อัพโหลด</Button>
                                </Upload>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </>
    )
}
