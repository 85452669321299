/* eslint-disable react-hooks/exhaustive-deps */

import { Button, Col, Form, Input, Modal, Popconfirm, Row, Table } from 'antd'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { FaCog } from "react-icons/fa"
import { Icon } from '@iconify/react'
import { FiEdit } from "react-icons/fi"
import { RiDeleteBin6Line } from "react-icons/ri"
import { Notification } from '../../../../common/components/notification'
import { deleteManageReservedWordByIdFetch, getManageReservedWordAllFetch, insertManageReservedWordFetch, updateManageReservedWordByIdFetch } from '../../API'

export default function ReservedWordManage() {
    const [form] = Form.useForm()

    const columns = [
        {
            title: 'No',
            dataIndex: 'index',
            width: "10%",
        },
        {
            title: 'คำสงวน',
            dataIndex: 'word',
            width: "40%",
        },
        {
            title: 'วันที่สร้าง',
            dataIndex: 'createdAt',
            width: "20%",
            render: (text, record) => {
                return moment(text).format('DD/MM/YYYY');
            }
        },
        {
            title: <FaCog />,
            align: "center",
            width: "30%",
            render: (text, record) => {
                return (
                    <>
                        <Button
                            type="primary"
                            style={{
                                width: 35,
                                backgroundColor: "orange",
                                border: "1px solid orange",
                                color: 'white',
                                borderRadius: 50,
                                marginRight: 10
                            }}
                            icon={<FiEdit style={{ marginTop: -3, color: "white" }} />}
                            onClick={() => {
                                // set data
                                form.setFieldsValue({
                                    word: record.word
                                })

                                // open modal
                                setReservedWordModal({
                                    visible: true,
                                    id: record.reservedWordId,
                                    type: "edit"
                                })
                            }}
                        ></Button>

                        <Popconfirm
                            title="คุณยืนยันลบคำสงวนหรือไม่ ?"
                            okText={<span style={{ width: 50 }}>ใช่</span>}
                            onConfirm={async () => {
                                const result = await deleteManageReservedWordByIdFetch(record.reservedWordId)
                                if (result.isSuccess) {
                                    Notification("success", "ระบบ", "ลบคำสงวนสำเร็จ")

                                    // reload data
                                    getBaseApi()
                                } else {
                                    Notification("error", "ระบบ", "ลบคำสงวนไม่สำเร็จ โปรดลองใหม่อีกครั้ง")
                                }
                            }}
                            cancelText={<span style={{ width: 50 }}>ไม่ใช่</span>}
                        >
                            <Button
                                danger
                                type="primary"
                                style={{
                                    width: 35,
                                    borderRadius: 50,
                                    marginRight: 10,
                                }}
                                icon={<RiDeleteBin6Line style={{ marginTop: -3, color: "white" }} />}
                            ></Button>
                        </Popconfirm>
                    </>
                )
            }
        },
    ]

    const [loading, setLoading] = useState(true)
    const [list, setList] = useState([])
    const [pageCurrent, setPageCurrent] = useState(1)

    const onFinish = async (values) => {

        if (reservedWordModal.type === "edit") {
            let obj = {
                reservedWordId: reservedWordModal.id,
                word: values.word,
            }
            const result = await updateManageReservedWordByIdFetch(obj)
            // console.log("updateManageReservedWordByIdFetch : ", result, obj)
            if (result.isSuccess) {
                Notification("success", "ระบบ", "เเก้ไขคำสงวนสำเร็จ")

                // reload data
                getBaseApi()

                // close modal
                setReservedWordModal({
                    visible: false,
                    id: null,
                    type: null,
                })

                // set default form
                form.resetFields()
            } else {
                Notification("error", "ระบบ", "เเก้ไขคำสงวนไม่สำเร็จ โปรดลองใหม่อีกครั้ง")
            }
        } else if (reservedWordModal.type === "add") {
            let obj = {
                word: values.word,
            }
            const result = await insertManageReservedWordFetch(obj)
            // console.log("insertManageReservedWordFetch : ", result, obj)
            if (result.isSuccess) {
                Notification("success", "ระบบ", "เพิ่มคำสงวนสำเร็จ")

                // reload data
                getBaseApi()

                // close modal
                setReservedWordModal({
                    visible: false,
                    id: null,
                    type: null,
                })

                // set default form
                form.resetFields()
            } else {
                Notification("error", "ระบบ", "เพิ่มคำสงวนไม่สำเร็จ โปรดลองใหม่อีกครั้ง")
            }
        }
    }

    const [search, setSearch] = useState(null)

    const [reservedWordModal, setReservedWordModal] = useState({
        visible: false,
        id: null,
        type: null,
    })

    const onPagine = (n) => {
        setPageCurrent(n.current)
    }

    const getManageReservedWordAll = async (text) => {
        setLoading(true)

        let obj = {
            text
        }
        // console.log("obj : ", obj)
        const result = await getManageReservedWordAllFetch(obj)
        // console.log("getManageReservedWordAllFetch", result)
        setList(result)

        setLoading(false)
    }

    const getBaseApi = async () => {
        getManageReservedWordAll(null)
    }

    useEffect(() => {
        getBaseApi()
    }, [])

    return (
        <>
            {!loading ?
                <Row gutter={[0,16]}>
                    <Col span={12} style={{ paddingBottom: 5 }}>
                        <label>จัดการคำสงวน</label>
                    </Col>

                    <Col span={12}>
                        <Button
                            type="primary"
                            style={{ width: 35, float: "right" }}
                            onClick={async () => {
                                getManageReservedWordAll(search)
                            }}
                            icon={<Icon icon="eva:search-fill" style={{ color: "white", width: 18, height: 18 }} />}
                        ></Button>
                        <Input
                            value={search}
                            placeholder="ค้นหาคำสงวน"
                            style={{ width: 200, float: "right", marginRight: 10 }}
                            onChange={(e) => {
                                setSearch(e.target.value)
                            }}
                        />
                    </Col>

                    <Col span={24}>
                        <Button
                            type="primary"
                            style={{ width: 120, float: "right" }}
                            onClick={async () => {
                                // open modal
                                setReservedWordModal({
                                    visible: true,
                                    id: null,
                                    type: "add"
                                })
                            }}
                        >+ เพิ่มคำสงวน</Button>
                    </Col>

                    <Col span={24}>
                        <Table
                            loading={loading}
                            columns={columns}
                            dataSource={list}
                            pagination={{
                                current: pageCurrent,
                                pageSize: 10,
                                total: list.length
                            }}
                            onChange={(n) => onPagine(n)}
                        >
                        </Table>
                    </Col>
                </Row>
                : []}


            <Modal
                maskClosable={false}
                title={reservedWordModal.type === "add" ? "เพิ่มคำสงวน" : "แก้ไขคำสงวน"}
                visible={reservedWordModal.visible}
                width={500}
                // loading={tranferLoading}
                onCancel={() => {
                    // close modal
                    setReservedWordModal({
                        visible: false,
                        id: null,
                        type: null
                    })

                    form.resetFields()
                }}
                footer={[

                    <Button
                        type='primary'
                        onClick={() => {
                            form.submit()
                        }}
                    >บันทึก</Button>
                ]}
            >
                <Form form={form} onFinish={onFinish}>
                    <Form.Item
                        name="word"
                        rules={[{ required: true, message: 'กรุณากรอกคำสงวน' }]}
                    >
                        <Input placeholder="คำสงวน" />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}
