/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */

import { useState, useEffect } from 'react'
import {
    Table,
    Row,
    Col,
    Button,
    Form,
    Input,
    Select,
    Radio,
    DatePicker,
} from 'antd'
import { useNavigate } from 'react-router-dom'
import { AiFillCheckCircle } from "react-icons/ai"
import { IoMdCloseCircle } from "react-icons/io"
import moment from 'moment'
import { getInspectionCourseApprovalCategoryFetch, getInspectionCourseCategoryFetch, getInspectionRequestOpenCourseFetch } from '../../API'

const { Option } = Select
const { RangePicker } = DatePicker

const columns = [
    {
        title: 'No',
        dataIndex: 'index',
        width: "7%",
    },
    {
        title: 'ชื่อคอร์ส',
        dataIndex: 'courseTitleName',
        width: "10%",
    },
    {
        title: 'จำนวนตีกลับ',
        dataIndex: 'amountAdjust',
        width: "10%",
    },
    {
        title: 'วันที่สร้าง',
        dataIndex: 'createdAt',
        width: "10%",
    },
    {
        title: 'วันที่เเก้ไข',
        dataIndex: 'updatedAt',
        width: "10%",
    },
    {
        title: 'ผลตรวจสอบ',
        dataIndex: 'inspectionCourseApproval',
        width: "10%",
    },
    {
        title: 'สถานะตรวจสอบ',
        dataIndex: 'inspectionCourse',
        align: "center",
        width: "15%",
    },
]

const formatTime = "HH:mm"
const formatDateTime = "YYYY-MM-DD HH:mm"

export default function InspectRequestUoloadCourse(props) {
    const language = "th"
    const navigate = useNavigate()

    const [form] = Form.useForm()

    const [list, setList] = useState([])
    const [loading, setLoading] = useState(false) // true

    const [inspectionCourseCategory, setInspectionCourseCategory] = useState([])
    const [inspectionCourseApprovalCategory, setInspectionCourseApprovalCategory] = useState([])
    const [pageCurrent, setPageCurrent] = useState(1)

    const onPagine = (n) => {
        setPageCurrent(n.current)
    };

    const inspectionCourseApprovalButton = (type) => {
        switch (type) {
            case 1:
                return <center><AiFillCheckCircle style={{ width: 25, height: 25, color: "green" }} /></center>
            case 2:
                return <center><IoMdCloseCircle style={{ width: 25, height: 25, color: "red" }} /></center>
            default:
                return null
        }
    }

    const inspectionCourseRedirect = (value) => {
        navigate(
            "/erp-inspect-course",
            { state: value }
        )
    }

    const inspectionCourseButton = (value) => {
        switch (value.inspectionCourseId) {
            case 1:
                return <Button
                    type="primary"
                    style={{ width: 130 }}
                    onClick={() => inspectionCourseRedirect(value)}
                >{value.inspectionCourseName}</Button>
            case 2:
                return <Button
                    type="primary"
                    style={{ width: 130 }}
                    onClick={() => inspectionCourseRedirect(value)}
                >{value.inspectionCourseName}</Button>
            case 3:
                return <Button
                    type="primary"
                    style={{ width: 130 }}
                    onClick={() => inspectionCourseRedirect(value)}
                >{value.inspectionCourseName}</Button>
            case 4:
                return <Button
                    type="primary"
                    style={{ width: 130 }}
                    onClick={() => inspectionCourseRedirect(value)}
                >เกินกำหนดปรับเเก้</Button>
            default:
                return null
        }
    }

    const onFinish = async (values) => {
        let courseName = values.courseName ? values.courseName : null
        let startedDate = values.dateRange ? moment(values.dateRange[0]).format(formatDateTime) : null
        let endDate = values.dateRange ? moment(values.dateRange[1]).format(formatDateTime) : null
        let inspectionCourse = values.inspectionCourse ? values.inspectionCourse : null
        let inspectionCourseApproval = values.inspectionCourseApproval ? values.inspectionCourseApproval : null
        let dateType = values.dateType ? values.dateType : null

        let request = { courseName, startedDate, endDate, inspectionCourse, inspectionCourseApproval, dateType }

        if (!courseName && !startedDate && !endDate && !inspectionCourse && !inspectionCourseApproval) {
            await getInspectRequestOpenCourseAllApi("allData", {
                courseName: null,
                startedDate: null,
                endDate: null,
                inspectionCourse: null,
                inspectionCourseApproval: null,
                dateType
            })
        } else {
            await getInspectRequestOpenCourseAllApi("bySearch", request)
        }
    }

    const getInspectRequestOpenCourseAllApi = async (type, values) => {
        setLoading(true)

        const result = await getInspectionRequestOpenCourseFetch(values, language)
        let adjustResult = []
        result.map((val, index) => {

            adjustResult.push({
                index: (index + 1),
                courseTitleName: val.course.courseTitleName,
                teacherName: val.user.userName,
                amountAdjust: val.amountAdjust,
                createdAt: moment(val.createdAt).format(formatDateTime),
                updatedAt: moment(val.updatedAt).format(formatDateTime),
                inspectionCourseApproval: inspectionCourseApprovalButton(val.inspectionCourseApprovalId),
                inspectionCourse: inspectionCourseButton(
                    {
                        inspectionCourseId: val.inspectionCourseId,
                        inspectionCourseName: val.inspectionCourseName,
                        courseId: val.courseId,
                        uid: val.uid,
                        createdAt: moment(val.createdAt).format(formatDateTime),
                        updatedAt: moment(val.updatedAt).format(formatDateTime),
                        dateEndAdjust: val.dateEndAdjust,
                        amountAdjust: val.amountAdjust,
                        isOverdue: val.isOverdue,
                        courseTitleName: val.course.courseTitleName,
                        user: val.user,           // teacher
                        userType: "admin-inspect-open-course",          // teacher-adjust-course, admin-inspect-edit-course

                        adminLogin: props.adminLogin
                    }
                ),
            })
        })
        setList(adjustResult)

        setLoading(false)
    }

    const getInspectionCourseApprovalCategoryApi = async () => {
        const result = await getInspectionCourseApprovalCategoryFetch(language)
        setInspectionCourseApprovalCategory(result)
    }

    const getInspectionCourseCategoryApi = async () => {
        const result = await getInspectionCourseCategoryFetch(language)
        setInspectionCourseCategory(result)
    }

    useEffect(() => {
        getInspectRequestOpenCourseAllApi("allData", {
            courseName: null,
            startedDate: null,
            endDate: null,
            inspectionCourse: null,
            inspectionCourseApproval: null,
            dateType: 2
        })
    }, [])

    return (
        <Row>
            <Col span={4}>
                <label>ตรวจสอบคำขอเปิดคอร์ส</label>
            </Col>
            <Col span={20} style={{ paddingBottom: 20 }}>
                <Form
                    layout="vertical"
                    form={form}
                    onFinish={onFinish}
                >
                    <Row>
                        <Col xs={12} lg={5}>
                            <Form.Item name="inspectionCourseApproval" >
                                <Select
                                    showSearch
                                    style={{ width: '90%' }}
                                    placeholder="ผลตรวจสอบ"
                                    optionFilterProp="children"
                                    allowClear
                                    onClick={() => getInspectionCourseApprovalCategoryApi()}
                                >
                                    {inspectionCourseApprovalCategory.map((val, index) =>
                                        <Option key={index} value={val.value}>{val.label}</Option>
                                    )}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={12} lg={5}>
                            <Form.Item name="inspectionCourse" >
                                <Select
                                    showSearch
                                    style={{ width: '90%' }}
                                    placeholder="สถานะตรวจสอบ"
                                    optionFilterProp="children"
                                    allowClear
                                    onClick={() => getInspectionCourseCategoryApi()}
                                >
                                    {inspectionCourseCategory.map((val, index) =>
                                        <Option key={index} value={val.value}>{val.label}</Option>
                                    )}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={12} lg={7}>
                            <Form.Item name="dateRange" >
                                <RangePicker
                                    style={{ width: '90%' }}
                                    showTime={{ format: formatTime }}
                                    format={formatDateTime}
                                />
                            </Form.Item>

                            <Form.Item label="ค้นหาจาก" name="dateType" style={{ marginTop: -15, marginBottom: 5 }}>
                                <Radio.Group>
                                    <Radio value={1}>วันที่สร้าง</Radio>
                                    <Radio value={2}>วันที่เเก้ไข</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        <Col xs={12} lg={5}>
                            <Form.Item name="courseName" >
                                <Input
                                    style={{ width: '100%' }}
                                    placeholder="ชื่อคอร์ส"
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={12} lg={2}>
                            <Button
                                type="primary"
                                onClick={() => form.submit()}
                            >
                                ค้นหา
                            </Button>
                        </Col>
                    </Row>
                </Form>

            </Col>
            <Col span={24}>
                <Table
                    loading={loading}
                    columns={columns}
                    dataSource={list}
                    pagination={{
                        current: pageCurrent,
                        pageSize: 10,
                        total: list.length
                    }}
                    onChange={(n) => onPagine(n)}
                >
                </Table>
            </Col>
        </Row>
    )
}
