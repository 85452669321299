import { 
    HTTP_COURSE_MANAGE_ADD_CURRENT_PAGE_FETCHING, 
    HTTP_COURSE_MANAGE_ADD_CURRENT_PAGE_SUCCESS, 
    HTTP_COURSE_MANAGE_ADD_CURRENT_PAGE_FAILED, 
} from "../constants"

export const setCourseManageAddCurrentStepStateToFetching = () => ({
    type: HTTP_COURSE_MANAGE_ADD_CURRENT_PAGE_FETCHING,
})

export const setCourseManageAddCurrentStepStateToSuccess = (payload) => ({
    type: HTTP_COURSE_MANAGE_ADD_CURRENT_PAGE_SUCCESS,
    payload
})

export const setCourseManageAddCurrentStepStateToFailed = () => ({
    type: HTTP_COURSE_MANAGE_ADD_CURRENT_PAGE_FAILED,
})
