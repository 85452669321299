import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet"
import "../map/css/index.css"

export default function Map(props) {
    return (
        <div>
            <MapContainer center={[13.7837263,100.5603957]} zoom={13} scrollWheelZoom={true}>
                <TileLayer
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <Marker position={[13.7837263,100.5603957]}>
                    <Popup>บริษัท กู๊ดเน็ท โกบอล กรุ๊ป จำกัด</Popup>
                </Marker>
            </MapContainer>
        </div>
    );
}