/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import parse from 'html-react-parser'
import {
    Layout,
    Row,
    Col,
    Image
} from 'antd'
import { useLocation } from "react-router-dom"
import Footer from '../footer'
import Header from '../header'
import { getAnnounceByIdFetch } from './API'
import { useDimensions } from '../../mamager/ScreenManager'

const { Content } = Layout

export default function Announce(props) {

    const location = useLocation()
    const params = new URLSearchParams(location.search)
    const announceId = params.get("announcement-id")

    const { width } = useDimensions()

    const [list, setList] = useState(null)
    const [loading, setLoading] = useState(true)

    const getAnnounceById = async () => {
        setLoading(true)

        const result = await getAnnounceByIdFetch(Number(announceId))
        // console.log("list : ", list)
        setList(result)

        setLoading(false)
    }

    useEffect(() => {
        getAnnounceById()
    }, [])

    return (
        <>
            {!loading ?
                <div style={{ backgroundColor: "#EEEEEE" }}>
                    <Header
                        firebase={props.firebase}
                        authorized={props.authorized}
                        user={props.user}
                    />

                    <article>
                        <Content
                            style={
                                width > 1500 ?
                                    { backgroundColor: "white", marginLeft: '5%', marginRight: '5%' }
                                    :
                                    (
                                        (width > 912 && width <= 1500) ?
                                            { backgroundColor: "white", marginLeft: '5%', marginRight: '5%' }
                                            :
                                            { backgroundColor: "white", marginLeft: '0%', marginRight: '0%' }
                                    )
                            }
                        >
                            <div style={{ width: '100%', backgroundColor: 'white', padding: 15, borderRadius: 15 }}>
                                {list ?
                                    <Row>
                                        <Col span={24} style={{ paddingBottom: 15 }}>
                                            <label style={{ fontSize: 18 }}>{list ? list.announcementTitleName : null}</label>
                                        </Col>

                                        {list.announcementImage ?
                                            <Col span={24} style={{ paddingBottom: 15 }}>
                                                <Image
                                                    style={{ height: props.aspectImageHeight, width: props.aspectImageWidth, border: "1px solid #e8e8e8" }}
                                                    src="error"
                                                    fallback={`${list.announcementImage}`}
                                                    preview={false}
                                                />
                                            </Col>
                                            : []}

                                        <Col span={24}>
                                            <p>{list ? parse(list.announcementDescription) : null}</p>
                                        </Col>
                                    </Row>
                                    : []}
                            </div>
                        </Content>
                    </article>

                    <Footer />
                </div>
                : []
            }
        </>
    )
}
