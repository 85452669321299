import "./../../css/search.css"
import { useRef, useState } from "react"
import { Form, Row, Col, Select, AutoComplete, Button } from 'antd'
import { Icon } from '@iconify/react'
import { useDimensions } from "../../../../common/mamager/ScreenManager"
import { getCourseCategoryFetch, getCourseRatingFetch } from "../../../course/API"
import { getClassroomCourseSearchSuggestByLearnerIdFetch } from "../../API"

const { Option } = Select

export default function ClassroomSearch(props) {
    const { width } = useDimensions()
    const [form] = Form.useForm()
    const searchRef = useRef('')
    const [options, setOptions] = useState([])

    const [courseCategory, setCourseCategory] = useState([])
    const [courseRating, setCourseRating] = useState([])

    const onFinish = (values) => {

        let category = values.category ? values.category : null
        let rating = values.rating ? values.rating : null
        let text = searchRef.current ? searchRef.current : null

        props.handleClassroomSearch(category, rating, text)
    }

    const getCourseRating = async () => {
        const result = await getCourseRatingFetch(props.authorized, 'th')
        if (result) {
            setCourseRating(result.filter(val => val.value !== 2))
        }
    }

    const getCourseCategory = async () => {
        const result = await getCourseCategoryFetch('th')
        setCourseCategory(result)
    }

    const handleSearch = async (value) => {
        const result = await getClassroomCourseSearchSuggestByLearnerIdFetch(value, props.user.uid, 10)
        let data = []
        result.map((val) => data.push(val.course.courseTitleName))
        let searchResult = await data
            .map((data) => {
                return {
                    value: data,
                    label: (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between'
                            }}
                        >
                            <span>{data}</span>
                        </div>
                    )
                }
            })
        setOptions(value ? searchResult : [])
    }

    const onSelect = (value) => {
        // console.log('onSelect', value)
    }

    const onFinishFailed = (errorInfo) => {
        // console.log('Failed:', errorInfo)
    }

    return (
        <div>
            <Form
                onFinish={onFinish}
                form={form}
                onFinishFailed={onFinishFailed}
            >
                <Row
                    style={width >= 1500 ?
                        { paddingLeft: '5%', paddingRight: '5%', paddingTop: 15, backgroundColor: '#E5E5E5', paddingBottom: 0 }
                        : (
                            (width > 912 && width < 1500) ?
                                { paddingLeft: '5%', paddingRight: '5%', paddingTop: 15, backgroundColor: '#E5E5E5', paddingBottom: 0 }
                                : { paddingLeft: '0%', paddingRight: '0%', paddingTop: 15, backgroundColor: '#E5E5E5', paddingBottom: 0 }
                        )}
                >
                    <Col xs={24} sm={24} md={24} lg={6} xl={6} xxl={6} style={{ paddingLeft: 15 }}>
                        <label style={{ fontSize: 18, paddingTop: 4, paddingLeft: 4 }}>คอร์สของฉัน</label>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={6} xl={6} xxl={6} style={{ paddingLeft: 8, paddingRight: 8 }}>
                        <Form.Item
                            name="rating"
                        >
                            <Select
                                showSearch
                                style={{ width: '100%', marginBottom: -10 }}
                                placeholder="เลือกลำดับ"
                                optionFilterProp="children"
                                allowClear
                                size="large"
                                onClick={() => getCourseRating()}
                            >
                                {courseRating.map((val, index) =>
                                    <Option key={index} value={String(val.value)}>{val.label}</Option>
                                )}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={6} xl={6} xxl={6} style={{ paddingLeft: 8, paddingRight: 8 }}>
                        <Form.Item
                            name="category"
                        >
                            <Select
                                showSearch
                                style={{ width: '100%', marginBottom: -10 }}
                                placeholder="เลือกหมวดหมู่"
                                optionFilterProp="children"
                                allowClear
                                size="large"
                                onClick={() => getCourseCategory()}
                            >
                                {courseCategory.map((val, index) =>
                                    <Option key={index} value={String(val.value)}>{val.label}</Option>
                                )}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={6} xl={6} xxl={6} style={{ paddingLeft: 8, paddingRight: 15, paddingBottom: 15 }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div className="search-auto-complete">
                                <AutoComplete
                                    onChange={(e) => searchRef.current = e}
                                    // ref={searchRef}
                                    dropdownMatchSelectWidth={252}
                                    style={{
                                        width: '100%'
                                    }}
                                    options={options}
                                    onSelect={onSelect}
                                    size="large"
                                    placeholder="ค้นหาชื่อคอร์ส"
                                    onSearch={handleSearch}
                                />
                            </div>

                            <Button
                                type="primary"
                                style={{ 
                                    width: '20%', 
                                    // border: '1px solid #dedede' 
                                    borderRadius: "0px 10px 10px 0px"
                                }}
                                size="large"
                                onClick={(e) => {
                                    e.preventDefault()
                                    form.submit()
                                }}
                            >
                                <Icon icon="bi:search" style={{ color: "white", width: 15, height: 15, marginTop: 5.5 }} />
                            </Button>
                        </div>
                    </Col>
                </Row>
            </Form>
        </div>
    )
}
