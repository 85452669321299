/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useRef } from "react"

// import firebase from 'firebase/compat/app'
import firebase from 'firebase/app'

import {
    Form,
    Avatar,
    Row,
    Col,
    Input,
    Button
} from "antd"
import { Comment } from '@ant-design/compatible'
import { formatRelative } from "date-fns"
import { useNavigate } from "react-router-dom"
import "../chat/css/chatItem.css"
import "../chat/css/message.css"
import uuid from 'react-uuid'
import { getCourseOwnerFetch } from "../../../modules/course/API"
import { getReservedWordAllFetch } from "./API"

const { TextArea } = Input


export default function ChatItem({
    db = null,
    toggleTab = null,
    learnerId = null,
    teacherId = null,
    courseId = null,
    chatType = null,
    roomId = null,
}) {
    const [form] = Form.useForm()
    const [chat, setChat] = useState([])

    const navigate = useNavigate()
    const dataRoomRef = useRef(null)
    const [isNotReaded, setIsNotReaded] = useState(false)
    const toMemberRef = useRef(null)

    const toggleTabRef = useRef(Number(localStorage.getItem('toggleTabChat')))

    function updateScrollingChat() {
        if (chat?.length > 0) {
            document.getElementById("scroll").scrollTop = document.getElementById("scroll").scrollHeight
        }
    }

    const genChat = async () => {
        let queryRoom = null
        let queryMessage = null

        if (db) {
            // get chat_room ---------------------------------------------------------------------------------
            queryRoom = db.collection("chat_room")
            if (chatType === "learner") {
                queryRoom = queryRoom.where("chatCourseId", "==", String(courseId))
                queryRoom = queryRoom.where("chatRoomBuilderId", "==", learnerId)
            } else if (chatType === "teacher" || chatType === "learner-manage") {
                queryRoom = queryRoom.where("chatRoomId", "==", roomId)
            }
            await queryRoom.onSnapshot(async (val1) => {
                let dataRoom = val1.docs.map((doc) => ({
                    ...doc.data(),
                    id: doc.id,
                }))

                // set member target send message (learner or teacher)
                if (chatType === "learner" || chatType === "learner-manage") {
                    toMemberRef.current = dataRoom?.length > 0 ? dataRoom[0].chatRoomHeaderId : null
                } else if (chatType === "teacher") {
                    toMemberRef.current = dataRoom?.length > 0 ? dataRoom[0].chatRoomBuilderId : null
                }

                dataRoomRef.current = dataRoom?.length > 0 ? dataRoom[0] : null
                if (dataRoom?.length > 0) {
                    // get chat_message --------------------------------------------------------------------------
                    queryMessage = db.collection("chat_message").orderBy("createAt")
                    queryMessage = queryMessage.where("chatRoomId", "==", dataRoom[0].chatRoomId)
                    await queryMessage.onSnapshot(async (val2) => {
                        toggleTabRef.current = Number(localStorage.getItem('toggleTabChat'))
                        if (toggleTabRef.current === 3) {
                            let dataMessage = val2.docs.map((doc) => ({
                                ...doc.data(),
                                id: doc.id
                            }))

                            if (chatType === "learner" ? true : (roomId === localStorage.getItem('lsRoomId') ? true : false)) {
                                // ชั่วคราว
                                let findIsNotReaded = dataMessage.filter(val => val.chatFromMemberId === (chatType === "learner" || chatType === "learner-manage" ? dataRoom[0].chatRoomBuilderId : teacherId) && val.chatMessageIsReadStatus === false)
                                setIsNotReaded(findIsNotReaded?.length > 0 ? true : false)

                                let findUpdateReaded = dataMessage.filter(val => val.chatFromMemberId === (chatType === "learner" || chatType === "learner-manage" ? teacherId : dataRoom[0].chatRoomBuilderId) && val.chatMessageIsReadStatus === false)

                                // update message read
                                if (findUpdateReaded?.length > 0) {
                                    findUpdateReaded.map(val => updateMessageRead(val.id))
                                }

                                // get username and image profile --------------------------------------------------------
                                let tempDataMessage = []
                                for (let i = 0; i < dataMessage.length; i++) {
                                    let result = await getCourseOwnerFetch(chatType === "learner" || chatType === "learner-manage" ? teacherId : dataMessage[i].chatFromMemberId)
                                    tempDataMessage.push({
                                        chatMessageId: dataMessage[i].chatMessageId,
                                        chatFromMemberId: dataMessage[i].chatFromMemberId,
                                        chatRoomId: dataMessage[i].chatRoomId,
                                        chatMessageName: dataMessage[i].chatMessageName,
                                        chatMessageIsActive: dataMessage[i].chatMessageIsActive,
                                        updateAt: dataMessage[i].updateAt,
                                        createAt: dataMessage[i].createAt,

                                        // of member
                                        chatMemberName: result ? result.name : "",
                                        chatMemberImageProfile: result ? result.imageProfile : "",
                                        chatMessageIsReadStatus: dataMessage[i].chatMessageIsReadStatus
                                    })
                                }
                                dataMessage = tempDataMessage

                                setChat(dataMessage)
                            }
                        }
                    })
                }
            })
        }
    }

    const updateMessageRead = async (id) => {
        db.collection("chat_message").doc(id).update({ // chat_message_read
            chatMessageIsReadStatus: true
        })
    }

    const insertMember = async (type, roomId, memberId) => {
        let obj = {
            chatFromMemberId: memberId,
            chatRoomId: roomId,
            chatMemberRightId: null,                // next phase 
            chatMemberName: null,                   // from user_infomation db
            chatMemberImageProfile: null,           // from user_infomation db
            chatMemberIsActive: true,               // default true
            updateAt: null,
            createAt: firebase.firestore.FieldValue.serverTimestamp()
        }
        await db.collection("chat_member").add(obj)
    }

    const onFinish = async (values) => {
        let message = values.newMessage ? values.newMessage : null
        let genId = null
        if (db) {
            if (!dataRoomRef.current) {
                // CREATE CHAT ROOM --------------------------------------------
                genId = uuid()
                let objRoom = {
                    chatRoomId: genId,
                    chatCourseId: String(courseId),
                    chatRoomName: null,
                    isActive: true,
                    updateAt: null,
                    chatRoomHeaderId: teacherId,            // for teacher
                    chatRoomBuilderId: learnerId,           // for learner
                    chatRoomBuilderName: null,              // for learner
                    chatRoomBuilderImageProfile: null,      // for learner

                    createAt: firebase.firestore.FieldValue.serverTimestamp()
                }
                await db.collection("chat_room").add(objRoom)

                // CREATE CHAT MEMBER (LEARNER) --------------------------------
                await insertMember("LEARNER", genId, learnerId)

                // CREATE CHAT MEMBER (TEACHER) --------------------------------
                await insertMember("TEACHER", genId, teacherId)

            }

            let roomId;
            if (genId !== null) {
                roomId = genId
                dataRoomRef.current = { chatRoomId: roomId }
            } else {
                roomId = dataRoomRef.current ? dataRoomRef.current.chatRoomId : null
            }

            await insertMessage(roomId, message)
            form.setFieldsValue({ newMessage: '' })
        }
    }

    const detectWord = async (message) => {
        let word = await getReservedWordAllFetch()
        let scanWord = message

        for (let i = 0; i < word.length; i++) {
            let loc = scanWord.search(word[i]?.toLowerCase())
            if (loc !== -1) {
                let substr = scanWord.substring(loc, loc + word[i].length)
                scanWord = scanWord.substring(0, loc) + "***" + scanWord.substring(loc + substr.length, scanWord.length)
            }
        }

        return scanWord
    }

    const insertMessage = async (roomId, message) => {
        let messageId = uuid()

        // detect word
        let messageScan = await detectWord(message)

        // Create message on chat_message --------------------------------
        let obj = {
            chatMessageId: messageId,
            chatMessageHeaderId: teacherId,           // for teacher   
            chatFromMemberId: chatType === "learner" || chatType === "learner-manage" ? learnerId : teacherId,
            chatToMemberId: toMemberRef.current,
            chatCourseId: String(courseId),
            chatRoomId: roomId,
            chatMessageName: messageScan,
            chatMessageIsActive: true,
            updateAt: null,
            createAt: firebase.firestore.FieldValue.serverTimestamp(),

            // ชั่วคราว
            chatMessageIsReadStatus: false, // default false  
        }
        await db.collection("chat_message").add(obj)
    }

    useEffect(() => {
        genChat()

        // Detach listener
        return navigate((location) => {
            localStorage.setItem('toggleTabChat', 1)
            localStorage.setItem('lsRoomId', null)
        })

    }, [db, roomId, toggleTab])

    return (
        <>
            <div id="scroll" style={{ backgroundColor: '#DDDDDD', listStyleType: 'none', overflow: 'scroll', overflowX: 'hidden', height: 477 }}>
                <Row style={{ paddingLeft: 15, paddingTop: 15, paddingRight: 15 }}>
                    <>
                        {chat?.map((val, index) =>
                            <Col key={index} span={24}>
                                <Row>
                                    <Col span={24} style={{ paddingBottom: 15 }}>
                                        {val.chatFromMemberId === (chatType === "learner" || chatType === "learner-manage" ? learnerId : teacherId) ?
                                            <Comment
                                                style={{ backgroundColor: '#c2ffce', maxWidth: "70%", paddingLeft: 15, paddingRight: 15, borderRadius: 10, float: 'right' }}
                                                author={<label style={{ color: 'black', fontSize: 16 }}>คุณ</label>}
                                                content={<label style={{ color: 'black' }}>{val.chatMessageName}</label>}
                                                datetime={
                                                    <span style={{ color: 'black' }}>
                                                        {val.createAt ?
                                                            formatRelative(
                                                                new Date(val.createAt.seconds * 1000),
                                                                new Date()
                                                            ) : null}
                                                    </span>
                                                }
                                            />
                                            :
                                            <Comment
                                                style={{ backgroundColor: 'white', maxWidth: "70%", paddingLeft: 15, paddingRight: 15, borderRadius: 10, float: 'left' }}
                                                author={val.chatMemberName ? <label style={{ color: 'black', fontSize: 16 }}>{val.chatMemberName}</label> : null}
                                                avatar={
                                                    <Avatar
                                                        src={val.chatMemberImageProfile}
                                                        alt={val.chatMemberName ? val.chatMemberName : null}
                                                    />
                                                }
                                                content={<p style={{ color: 'black' }}>{val.chatMessageName}</p>}
                                                datetime={
                                                    <span style={{ color: 'black' }}>
                                                        {val.createAt ? formatRelative(
                                                            new Date(val.createAt.seconds * 1000),
                                                            new Date()
                                                        ) : null}
                                                    </span>
                                                }
                                            />
                                        }
                                    </Col>

                                    {updateScrollingChat()}
                                </Row>
                            </Col>
                        )}

                        {chat?.length > 0 ?
                            <>
                                {!isNotReaded && chat.length > 0 && (chat[chat.length - 1].chatFromMemberId === learnerId) ? <Col span={24}><div style={{ float: 'right' }}><p>อ่านเเล้ว</p></div></Col> : null}
                            </>
                            : []
                        }
                    </>
                </Row>
            </div>

            <Form form={form} name="control-hooks" onFinish={onFinish}>
                <div style={{ display: "flex", alignItems: "flex-start", justifyContent: "space-between", width: "100%" }}>
                    <Form.Item
                        name="newMessage"
                        rules={[{ required: true, message: '' }]}
                        style={{ width: '100%' }}
                    >
                        <TextArea
                            style={{ width: '100%' }}
                            placeholder="กรอกข้อความ"
                            autoSize={{ minRows: 1, maxRows: 3 }}
                        />
                    </Form.Item>

                    <Button
                        type="primary"
                        style={{ width: 70 }}
                        onClick={() => form.submit()}
                    >ส่ง</Button>
                </div>
            </Form>
        </>
    )
}
