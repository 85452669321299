

import { Content } from 'antd/lib/layout/layout'
import { Icon } from '@iconify/react'
import { useNavigate } from 'react-router-dom'
import { Col, Row } from 'antd'
import Footer from '../../common/components/footer'
import Header from '../../common/components/header'
import { useDimensions } from '../../common/mamager/ScreenManager'

export default function ToflynPolicy(props) {

    const navigate = useNavigate()
    const { width } = useDimensions()

    return (
        <>
            <Header firebase={props.firebase} user={props.user} />

            <Content style={{ backgroundColor: "#F5F5F5" }}>
                <div style={width >= 1500 ? { paddingLeft: '20%', paddingRight: '20%' } : ((width > 1200 && width < 1500) ? { paddingLeft: '10%', paddingRight: '10%' } : { paddingLeft: '0%', paddingRight: '0%' })}>
                    <div style={{ backgroundColor: "white", paddingTop: 80, paddingLeft: (width > 1000 ? 80 : 24), paddingRight: (width > 1000 ? 80 : 24), paddingBottom: 80 }}>
                        <Row>
                            <Col span={24} style={{ textAlign: "center" }}>
                                <label style={{ fontSize: 18, fontWeight: "bold" }}>นโยบายของ Toflyn</label>
                            </Col>
                            <Col span={24}>
                                <div onClick={() => {
                                    navigate("/privacy-policy")
                                }}>
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <Icon icon="radix-icons:dot" style={{ color: "#23b14d", width: 25, height: 25 }} />
                                        <label style={{ paddingLeft: 10, cursor: "pointer" }}>นโยบายความเป็นส่วนตัว</label>
                                    </div>
                                </div>
                            </Col>
                            <Col span={24}>
                                <div onClick={() => {
                                    navigate("/refund-policy")
                                }}>
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <Icon icon="radix-icons:dot" style={{ color: "#23b14d", width: 25, height: 25 }} />
                                        <label style={{ paddingLeft: 10, cursor: "pointer" }}>นโยบายการคืนเงิน</label>
                                    </div>
                                </div>
                            </Col>
                            <Col span={24}>
                                <div onClick={() => {
                                    navigate("/service-cancel-policy")
                                }}>
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <Icon icon="radix-icons:dot" style={{ color: "#23b14d", width: 25, height: 25 }} />
                                        <label style={{ paddingLeft: 10, cursor: "pointer" }}>นโยบายการยกเลิกการใช้บริการ</label>
                                    </div>
                                </div>
                            </Col>
                            <Col span={24}>
                                <div onClick={() => {
                                    navigate("/terms-of-use")
                                }}>
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <Icon icon="radix-icons:dot" style={{ color: "#23b14d", width: 25, height: 25 }} />
                                        <label style={{ paddingLeft: 10, cursor: "pointer" }}>เงื่อนไขการให้บริการ</label>
                                    </div>
                                </div>
                            </Col>
                            <Col span={24}>
                                <div onClick={() => {
                                    navigate("/terms-being-instructor")
                                }}>
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <Icon icon="radix-icons:dot" style={{ color: "#23b14d", width: 25, height: 25 }} />
                                        <label style={{ paddingLeft: 10, cursor: "pointer", paddingBottom: 0, marginBottom: 0 }}>เงื่อนไขการลงคอร์สเรียนเป็นผู้สอน</label>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Content>

            <Footer />
        </>
    )
}
