import { server } from "../../../../constants"
import { httpClient } from "../../../../utils/HttpClient"

const getCourseCertificateByIdFetch = async (value) => { // Done
    try {
        const result = await httpClient.post(server.GET_COURSE_CERTIFICATE_BY_ID_URL, { value })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getThemeCertificateAllFetch = async () => { // Done
    try {
        const result = await httpClient.get(server.GET_THEME_CERTIFICATE_ALL_URL)
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getThemeCertificateByIdFetch = async (id) => { // Done
    try {
        const result = await httpClient.get(server.GET_THEME_CERTIFICATE_BY_ID_URL + `/${id}`)
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getInspectCourseCertificateByIdFetch = async (value) => { // Done
    try {
        const result = await httpClient.post(server.GET_INSPECT_COURSE_CERTIFICATE_BY_ID_URL, { value })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const detectCertificateCodeByIdFetch = async (code) => { // Done
    try {
        const result = await httpClient.get(server.GET_CERTIFICATE_CODE_BY_ID_URL + `/${code}`)
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const insertCourseCertificateFetch = async (value) => { // Done 
    try {
        const result = await httpClient.post(server.INSERT_COURSE_CERTIFICATE_URL, { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const updateCourseCertificateDownloadFirstTimeFetch = async (value) => { // Done 
    try {
        const result = await httpClient.post(server.UPDATE_COURSE_CERTIFICATE_DOWNLOAD_FIRST_TIME_URL, { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const updateThemeCourseCertificateByIdFetch = async (value) => { // Done 
    try {
        const result = await httpClient.post(server.UPDATE_THEME_COURSE_CERTIFICATE_BY_ID_URL, { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const updateCertificateApprovalCategoryByIdFetch = async (value) => { // Done 
    try {
        const result = await httpClient.post(server.UPDATE_CERTIFICATE_APPROVAL_CATEGORY_BY_ID_URL, { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

export {
    // get
    getCourseCertificateByIdFetch,
    getThemeCertificateAllFetch,
    getThemeCertificateByIdFetch,
    getInspectCourseCertificateByIdFetch,

    // delete
    detectCertificateCodeByIdFetch,

    // insert
    insertCourseCertificateFetch,

    // update
    updateCourseCertificateDownloadFirstTimeFetch,
    updateThemeCourseCertificateByIdFetch,
    updateCertificateApprovalCategoryByIdFetch
}