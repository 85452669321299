/* eslint-disable react-hooks/exhaustive-deps */

import { useState, useEffect } from "react"
import { Icon } from '@iconify/react'
import "../requestOpenCourse/css/requestOpenCourse.css"
import { BsInfoCircle } from "react-icons/bs"
import { AiOutlineQuestionCircle } from "react-icons/ai"
import {
    Layout,
    Col,
    Row,
    Input,
    Button,
    Modal,
    Form,
    Select,
    Card,
    Checkbox,
    Popconfirm,
    Popover,
    Divider,
    Image
} from "antd"
import { useLocation, useNavigate } from "react-router-dom"
import { BiTrashAlt } from "react-icons/bi"
import { AiFillCheckCircle, AiFillCloseCircle } from "react-icons/ai"
import { useDimensions } from "../../common/mamager/ScreenManager"
import Header from "../../common/components/header"
import firebase from "../../config/firebase-config"
import Footer from "../../common/components/footer"
import TermsBeingInstructorItem from "../policy/components/termsBeingInstructor/TermsBeingInstructorItem"
import { insertRequestOpenCourseFetch, insertRequestOpenCourseTopicFetch, updateRequestOpenCourseFetch } from "./API"
import { getCourseCategoryFetch } from "../course/API"
import { getInspectFormRequestOpenCourseByIdFetch } from "../erp/API"
import { detectSignIn } from "../../common/mamager/DetectManager"
import { Notification } from "../../common/components/notification"

export default function RequestOpenCourse(props) {
    const navigate = useNavigate()
    const location = useLocation()
    const params = new URLSearchParams(location.search)
    const type = params.get("type")
    const inspectId = params.get("inspectId")

    const { width } = useDimensions()

    const language = "th"

    const [agree, setAgree] = useState(false)

    const [loading, setLoading] = useState(true)
    const [loadingSendInspect, setLoadingSendInspect] = useState(false)

    const [modalExampleFormRequestOpenCourse, setModalExampleFormRequestOpenCourse] = useState(false)

    const [form] = Form.useForm()

    const [courseCategory, setCourseCategory] = useState([])

    const [list, setList] = useState(null)

    const [initial, setInitial] = useState(null)

    const { Content } = Layout
    const { Option } = Select

    const [modalActive, setModalActive] = useState(false)

    const onFinish = async (values) => {
        setLoadingSendInspect(true)

        if (values.topics?.length >= 3) {
            if (type === "inspect") {

                let objUpdateTopic = []
                let objInsertTopic = []
                let topics = values.topics
                for (let i = 0; i < topics.length; i++) {
                    if (topics[i].formRegOpenCourseTopicId) {
                        objUpdateTopic.push({
                            formRegOpenCourseTopicId: topics[i].formRegOpenCourseTopicId,
                            inspectFormRequestOpenCourseTopicAdjustId: topics[i].inspectFormRequestOpenCourseTopicAdjustId === 2 ? 1 : topics[i].inspectFormRequestOpenCourseTopicAdjustId,
                            topic: topics[i].topic,
                            topicDescription: topics[i].topicDescription,
                        })
                    } else {
                        objInsertTopic.push({
                            topic: topics[i].topic,
                            topicDescription: topics[i].topicDescription,
                        })
                    }
                }
                // console.log("objUpdateTopic:", objUpdateTopic)
                // console.log("objInsertTopic:", objInsertTopic)

                let objUpdate = {
                    inspectionCourseCategoryId: 2,

                    inspectionFormRegOpenCourseId: list.inspectionFormRegOpenCourseId,
                    formRegOpenCourseId: list.formRequestOpenCourse.formRegOpenCourseId,
                    uid: list.uid,

                    inspectionCourseNameAdjustId: list.formRequestOpenCourse.inspectionCourseNameAdjustId === 2 ? 1 : list.formRequestOpenCourse.inspectionCourseNameAdjustId,
                    formRegOpenCourseName: values.formRegOpenCourseName ? values.formRegOpenCourseName : null,

                    inspectionCourseCategoryAdjustId: list.formRequestOpenCourse.inspectionCourseCategoryAdjustId === 2 ? 1 : list.formRequestOpenCourse.inspectionCourseCategoryAdjustId,
                    formCourseCategory: values.formCourseCategory ? values.formCourseCategory : null,

                    inspectionCourseTargetAdjustId: list.formRequestOpenCourse.inspectionCourseTargetAdjustId === 2 ? 1 : list.formRequestOpenCourse.inspectionCourseTargetAdjustId,
                    formRegOpenCourseTarget: values.formRegOpenCourseTarget ? values.formRegOpenCourseTarget : null,

                    inspectionCourseReceiveAdjustId: list.formRequestOpenCourse.inspectionCourseReceiveAdjustId === 2 ? 1 : list.formRequestOpenCourse.inspectionCourseReceiveAdjustId,
                    formRegOpenCourseReceive: values.formRegOpenCourseReceive ? values.formRegOpenCourseReceive : null,

                    inspectionCourseAboutOwnerAdjustId: list.formRequestOpenCourse.inspectionCourseAboutOwnerAdjustId === 2 ? 1 : list.formRequestOpenCourse.inspectionCourseAboutOwnerAdjustId,
                    formRegOpenCourseAboutOwner: values.formRegOpenCourseAboutOwner ? values.formRegOpenCourseAboutOwner : null,

                    topics: objUpdateTopic // values.topics
                }

                const resultUpdate = await updateRequestOpenCourseFetch(objUpdate)

                let resultInsert = { data: { isSuccess: true } }
                if (objInsertTopic.length > 0) {
                    resultInsert = await insertRequestOpenCourseTopicFetch(
                        props.user.uid,                                 // uid
                        objInsertTopic,                                 // value
                        1,                                              // isActive
                        list.formRequestOpenCourse.formRegOpenCourseId  // formRegOpenCourseId
                    )
                }

                if (resultUpdate.data.isSuccess && resultInsert.data.isSuccess) {
                    Notification("success", "ระบบ", "ส่งเเบบฟอร์มที่เเก้ไขเเล้วสำเร็จ")

                    navigate("/course-manage")
                } else {
                    Notification("error", "ระบบ", "ส่งเเบบฟอร์มที่เเก้ไขเเล้วไม่สำเร็จ กรุณาลองใหม่อีกครั้ง")
                }
            } else {
                let objInsert = {
                    formRegOpenCourseName: values.formRegOpenCourseName ? values.formRegOpenCourseName : null,
                    formCourseCategory: values.formCourseCategory ? values.formCourseCategory : null,
                    formRegOpenCourseTarget: values.formRegOpenCourseTarget ? values.formRegOpenCourseTarget : null,
                    formRegOpenCourseReceive: values.formRegOpenCourseReceive ? values.formRegOpenCourseReceive : null,
                    formRegOpenCourseAboutOwner: values.formRegOpenCourseAboutOwner ? values.formRegOpenCourseAboutOwner : null,
                    topics: values.topics
                }

                let result = await insertRequestOpenCourseFetch(props.user.uid, objInsert, 1)
                if (result.data.isSuccess) {
                    // open page success
                    navigate(
                        "/result",
                        {
                            state: {
                                authorized: props.authorized,

                                type: "success",
                                title: "ส่งเเบบฟอร์มคำขอเปิดคอร์สเรียนสำเร็จ!",
                                subTitle: "โปรดรอการตรวจสอบประมาณ 1-3 วัน โดยเราจะเเจ้งผลททางอีเมล์ เเละเบอร์โทรศัพท์ให้ท่านทราบ หรือเข้ามาดูความคืบหน้าของการตรวจสอบได้ที่หน้าจัดการคอร์ส",
                                btnWidth: 170,

                                btnName1: "กลับไปหน้าจัดการคอร์ส",
                                btnPathName1: "course-manage",
                                btnIsActive1: true,

                                btnName2: "เพิ่มคอร์สใหม่",
                                btnPathName2: "registration-open-course",
                                btnIsActive2: true
                            }
                        }
                    )
                } else {
                    Notification("error", "ระบบ", "ไม่สามารถส่งเเบบฟอร์มคำขอเปิดคอร์สเรียนได้ กรุณาลองใหม่อีกครั้ง")
                }
            }
        } else {
            Notification("error", "ระบบ", "กรุณาเพิ่มหัวข้อที่สอนอย่างน้อย 3 หัวข้อ")
        }

        setLoadingSendInspect(false)
    }

    const getCourseCategory = async () => {
        const result = await getCourseCategoryFetch(language)
        setCourseCategory(result)
    }

    const onFinishFailed = errorInfo => {
        Notification("error", "ระบบ", "กรุณากรอกข้อมูลให้ครบถ้วน")
    }

    const detectIsInspectRequestOpenCourse = async () => {
        if (type === "inspect") {
            setModalActive(false)

            let obj = {
                inspectionFormRegOpenCourseId: inspectId,
            }
            const result = await getInspectFormRequestOpenCourseByIdFetch(obj, language)

            setList(result)

            if (result) {
                setInitial({
                    formRegOpenCourseName: result.formRequestOpenCourse.formRegOpenCourseName,
                    formCourseCategory: result.formRequestOpenCourse.courseCategoryId,
                    topics: result.formRequestOpenCourseTopic,
                    formRegOpenCourseTarget: result.formRequestOpenCourse.formRegOpenCourseTarget,
                    formRegOpenCourseReceive: result.formRequestOpenCourse.formRegOpenCourseReceive,
                    formRegOpenCourseAboutOwner: result.formRequestOpenCourse.formRegOpenCourseAboutOwner
                })
            }
        } else {
            setModalActive(true)

            setInitial({
                openCourseName: undefined,
                openCourseCategories: undefined,
                topics: [
                    { topic: undefined, topicDescription: undefined },
                    { topic: undefined, topicDescription: undefined },
                    { topic: undefined, topicDescription: undefined }
                ],
                openCourseTarget: undefined,
                openCourseReceive: undefined,
                openCourseAboutOwner: undefined
            })
        }
    }

    const detectLogin = async () => {
        // detect login
        const isLogin = await detectSignIn()
        if (!isLogin) {
            navigate("/login")
        }
    }

    useEffect(() => {
        setLoading(true)

        detectLogin()

        if (!agree) {
            detectIsInspectRequestOpenCourse()
        }

        if (courseCategory.length === 0) {
            getCourseCategory()
        }

        setLoading(false)
    }, [])

    return (
        <>
            <Layout>
                <Header
                    firebase={firebase}
                    authorized={props.authorized}
                    user={props.user}
                />

                <article>
                    <Content>
                        {!loading && initial ?
                            <div style={width >= 1500 ? { paddingLeft: '25%', paddingRight: '25%' } : ((width > 912 && width < 1500) ? { paddingLeft: '5%', paddingRight: '5%' } : { paddingLeft: '0%', paddingRight: '0%' })}>
                                <div style={{ backgroundColor: 'white', padding: 24 }}>
                                    <Row>
                                        <Col span={24} style={{ textAlign: "center" }}>
                                            <label>เเบบฟอร์มคำขอเปิดคอร์ส</label>
                                        </Col>
                                        <Col span={24} style={{ textAlign: "center" }}>
                                            <Button
                                                type="link"
                                                style={{ color: "#23B14D" }}
                                                onClick={() => {
                                                    setModalExampleFormRequestOpenCourse(true)
                                                }}
                                            >ดูตัวอย่างการกรอก</Button>
                                        </Col>
                                    </Row>

                                    <Form
                                        form={form}
                                        layout="vertical"
                                        onFinish={onFinish}
                                        onFinishFailed={onFinishFailed}
                                        initialValues={initial}
                                    >
                                        <Row gutter={[24, 0]}>
                                            <Col xs={24} lg={12} style={{ paddingBlock: 5 }}>
                                                {list?.inspectionCourseNameApprovalId === 1 ?
                                                    <div style={{ display: "flex", alignItems: "center" }}>
                                                        <AiFillCheckCircle style={{ height: 23, width: 23, color: 'green' }} />
                                                        <label style={{ paddingLeft: 10, color: 'green' }}>ผ่านการตรวจสอบ</label>
                                                        <br />
                                                    </div>
                                                    : []}
                                                {list?.inspectionCourseNameApprovalId === 2 ?
                                                    <div style={{ display: "flex", alignItems: "center" }}>
                                                        <AiFillCloseCircle style={{ height: 23, width: 23, color: 'red' }} />
                                                        <label style={{ paddingLeft: 10, color: 'red' }}>ไม่ผ่านการตรวจสอบ</label>
                                                        <br />
                                                    </div>
                                                    : []}
                                                <Form.Item
                                                    label={"ชื่อคอร์สที่จะเปิดสอน"}
                                                    name="formRegOpenCourseName"
                                                    rules={[{ required: true, message: "กรุณากรอกชื่อคอร์ส" }]}
                                                >
                                                    <Input
                                                        style={{ width: '100%' }}
                                                        maxLength={100}
                                                        onBlur={(e) => {
                                                            form.setFieldsValue({ formRegOpenCourseName: e.target.value })
                                                        }}
                                                        disabled={list?.formRequestOpenCourse.inspectionCourseNameApprovalId === 1 ? true : false}
                                                    />
                                                </Form.Item>
                                                {list?.formRequestOpenCourse.inspectionCourseNameApprovalId === 2 ?
                                                    <Col span={24} style={{ padding: 0 }}>
                                                        <Row style={{ padding: 10, border: '1px solid #EEEEEE' }}>
                                                            <Col span={24} style={{ paddingLeft: 10 }}>
                                                                เหตุผลไม่ผ่าน
                                                                <Popover content={<div style={{ width: 250 }}>ผลตรวจ ที่ทาง Toflyn เเจ้งให้ผู้สอนปรับเเก้ เเละส่งกลับมาใหม่อีกครั้งในเวลาที่กำหนด</div>}>
                                                                    <AiOutlineQuestionCircle style={{ marginLeft: 5, cursor: 'pointer' }} />
                                                                </Popover>
                                                            </Col>
                                                            <Col span={24} style={{ paddingLeft: 10, paddingRight: 10, paddingTop: 5 }}>
                                                                <p style={{ color: "red", backgroundColor: "#EEEEEE", padding: 8 }}>
                                                                    {list?.formRequestOpenCourse.reasonCourseNameNotApproval}
                                                                </p>
                                                            </Col>
                                                        </Row>

                                                    </Col>
                                                    : []}
                                            </Col>

                                            <Col xs={24} lg={12} style={{ paddingBlock: 5 }}>
                                                {list?.inspectionCourseCategoryApprovalId === 1 ?
                                                    <div style={{ display: "flex", alignItems: "center" }}>
                                                        <AiFillCheckCircle style={{ height: 23, width: 23, color: 'green' }} />
                                                        <label style={{ paddingLeft: 10, color: 'green' }}>ผ่านการตรวจสอบ</label>
                                                        <br />
                                                    </div>
                                                    : []}
                                                {list?.inspectionCourseCategoryApprovalId === 2 ?
                                                    <div style={{ display: "flex", alignItems: "center" }}>
                                                        <AiFillCloseCircle style={{ height: 23, width: 23, color: 'red' }} />
                                                        <label style={{ paddingLeft: 10, color: 'red' }}>ไม่ผ่านการตรวจสอบ</label>
                                                        <br />
                                                    </div>
                                                    : []}
                                                <Form.Item
                                                    label="ประเภทคอร์สที่จะเปิดสอน"
                                                    name="formCourseCategory"
                                                    rules={[{ required: true, message: "กรุณาเลือกประเภทคอร์ส" }]}
                                                >
                                                    <Select
                                                        showSearch
                                                        allowClear
                                                        optionFilterProp="children"
                                                        style={{ width: "100%" }}
                                                        disabled={list?.formRequestOpenCourse.inspectionCourseCategoryApprovalId === 1 ? true : false}
                                                    >
                                                        {courseCategory.map((val, index) => <Option key={index} value={val.value}>{val.label}</Option>)}
                                                    </Select>
                                                </Form.Item>
                                                {list?.formRequestOpenCourse.inspectionCourseCategoryApprovalId === 2 ?
                                                    <Col span={24} style={{ padding: 0 }}>
                                                        <Row style={{ padding: 10, border: '1px solid #EEEEEE' }}>
                                                            <Col span={24} style={{ paddingLeft: 10 }}>
                                                                เหตุผลไม่ผ่าน
                                                                <Popover content={<div style={{ width: 250 }}>ผลตรวจ ที่ทาง Toflyn เเจ้งให้ผู้สอนปรับเเก้ เเละส่งกลับมาใหม่อีกครั้งในเวลาที่กำหนด</div>}>
                                                                    <AiOutlineQuestionCircle style={{ marginLeft: 5, cursor: 'pointer' }} />
                                                                </Popover>
                                                            </Col>
                                                            <Col span={24} style={{ paddingLeft: 10, paddingRight: 10, paddingTop: 5 }}>
                                                                <p style={{ color: "red", backgroundColor: "#EEEEEE", padding: 8 }}>
                                                                    {list?.formRequestOpenCourse.reasonCourseCategoryNotApproval}
                                                                </p>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    : []}
                                            </Col>

                                            <Divider />

                                            <Col span={24} style={{ paddingBlock: 5 }}>
                                                <Form.List name="topics">
                                                    {(fields, { add, remove }) => (
                                                        <>
                                                            <div style={{ paddingBottom: 5, display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                                                <div>
                                                                    {list?.inspectionCourseTopicApprovalId === 1 ?
                                                                        <div style={{ display: "flex", alignItems: "center" }}>
                                                                            <AiFillCheckCircle style={{ height: 23, width: 23, color: 'green' }} />
                                                                            <label style={{ paddingLeft: 10, color: 'green' }}>ผ่านการตรวจสอบ</label>
                                                                            <br />
                                                                        </div>
                                                                        : []
                                                                    }

                                                                    {list?.inspectionCourseTopicApprovalId === 2 ?
                                                                        <div style={{ display: "flex", alignItems: "center" }}>
                                                                            <AiFillCloseCircle style={{ height: 23, width: 23, color: 'red' }} />
                                                                            <label style={{ paddingLeft: 10, color: 'red' }}>ไม่ผ่านการตรวจสอบ</label>
                                                                            <br />
                                                                        </div>
                                                                        : []
                                                                    }

                                                                    <label style={{ color: 'red' }}>*</label>
                                                                    <label style={{ paddingLeft: 6 }}>หัวข้อทั้งหมดที่สอน (ต้องมีอย่างน้อย 3 หัวข้อ)</label>
                                                                </div>

                                                                <Button
                                                                    type="primary"
                                                                    onClick={() => add()}
                                                                >
                                                                    <div style={{ display: "flex", alignItems: "center" }}>
                                                                        <Icon icon="ant-design:plus-outlined" style={{ cursor: "pointer", color: "white" }} />
                                                                        <label style={{ paddingLeft: 10, cursor: "pointer" }}>เพิ่มหัวข้อ</label>
                                                                    </div>
                                                                </Button>
                                                            </div>

                                                            <div style={{ overflow: 'auto', height: 578, padding: 15, borderRadius: '10px 0px 0px 10px', border: '1px solid #D9D9D9' }}>
                                                                {fields.map((field, index) => (
                                                                    <Card hoverable style={{ marginBottom: 15 }}>
                                                                        <Row key={field.key}>
                                                                            <Col xs={20} lg={12}>
                                                                                {list?.formRequestOpenCourseTopic?.find(val => index === val.key)?.inspectFormRequestOpenCourseTopicApprovalId === 1 ?
                                                                                    <div style={{ display: "flex", alignItems: "center" }}>
                                                                                        <AiFillCheckCircle style={{ height: 23, width: 23, color: 'green' }} />
                                                                                        <label style={{ paddingLeft: 10, color: 'green' }}>ผ่านการตรวจสอบ</label>
                                                                                    </div>
                                                                                    : []}
                                                                                {list?.formRequestOpenCourseTopic?.find(val => index === val.key)?.inspectFormRequestOpenCourseTopicApprovalId === 2 ?
                                                                                    <div style={{ display: "flex", alignItems: "center" }}>
                                                                                        <AiFillCloseCircle style={{ height: 23, width: 23, color: 'red' }} />
                                                                                        <label style={{ paddingLeft: 10, color: 'red' }}>ไม่ผ่านการตรวจสอบ</label>
                                                                                    </div>
                                                                                    : []}
                                                                                <Form.Item
                                                                                    {...field}
                                                                                    label={"หัวข้อที่ " + (index + 1)}
                                                                                    name={[field.name, "topic"]}
                                                                                    fieldKey={[field.fieldKey, "topic"]}
                                                                                    rules={[{ required: true, message: "กรุณากรอกหัวข้อ" }]}
                                                                                >
                                                                                    <Input
                                                                                        maxLength={100}
                                                                                        disabled={list?.formRequestOpenCourseTopic?.find(val => index === val.key)?.inspectFormRequestOpenCourseTopicApprovalId === 1 ? true : false}
                                                                                    />
                                                                                </Form.Item>
                                                                            </Col>
                                                                            <Col xs={4} lg={12} style={{ textAlign: "right" }}>
                                                                                {list?.formRequestOpenCourseTopic?.find(val => index === val.key)?.inspectFormRequestOpenCourseTopicApprovalId === 1 ?
                                                                                    <div
                                                                                        style={{
                                                                                            backgroundColor: '#DEDEDE',
                                                                                            padding: 4,
                                                                                            borderRadius: 20,
                                                                                            cursor: 'pointer',
                                                                                            width: 30,
                                                                                            height: 30,
                                                                                            display: "flex",
                                                                                            alignItems: "center",
                                                                                            justifyContent: "center",
                                                                                            float: 'right'
                                                                                        }}
                                                                                    >
                                                                                        <BiTrashAlt size={16} style={{ color: 'white', cursor: 'pointer' }} />
                                                                                    </div>
                                                                                    :
                                                                                    <Popconfirm
                                                                                        title="คุณต้องการลบหรือไม่ ?"
                                                                                        okText={<span style={{ width: 50 }}>ใช่</span>}
                                                                                        onConfirm={() => {
                                                                                            let countTopic = form.getFieldValue()?.topics?.length
                                                                                            if (countTopic > 3) {
                                                                                                remove(field.name)
                                                                                                Notification("success", "เเจ้งเตือน", `ลบหัวข้อที่ ${index + 1} สำเร็จ`)
                                                                                            } else {
                                                                                                Notification("warning", "เเจ้งเตือน", "ไม่สามารถลบหัวข้อที่สอนนี้ได้ ต้องมีหัวข้อที่สอนอย่างน้อย 3 หัวข้อ")
                                                                                            }
                                                                                        }}
                                                                                        cancelText={<span style={{ width: 50 }}>ไม่ใช่</span>}
                                                                                    >
                                                                                        <div
                                                                                            style={{
                                                                                                backgroundColor: 'red',
                                                                                                padding: 4,
                                                                                                borderRadius: 20,
                                                                                                cursor: 'pointer',
                                                                                                width: 30,
                                                                                                height: 30,
                                                                                                display: "flex",
                                                                                                alignItems: "center",
                                                                                                justifyContent: "center",
                                                                                                float: 'right'
                                                                                            }}
                                                                                        >
                                                                                            <BiTrashAlt size={16} style={{ color: 'white', cursor: 'pointer' }} />
                                                                                        </div>
                                                                                    </Popconfirm>
                                                                                }
                                                                            </Col>
                                                                            <Col span={24}>
                                                                                <Form.Item
                                                                                    {...field}
                                                                                    label={"รายละเอียดในหัวข้อที่ " + (index + 1)}
                                                                                    name={[field.name, "topicDescription"]}
                                                                                    fieldKey={[field.fieldKey, "topicDescription"]}
                                                                                    rules={[{ required: true, message: "กรุณากรอกรายละเอียด" }]}
                                                                                >
                                                                                    <Input.TextArea
                                                                                        showCount
                                                                                        maxLength={200}
                                                                                        autoSize={{ minRows: 3, maxRows: 5 }}
                                                                                        disabled={list?.formRequestOpenCourseTopic?.find(val => index === val.key)?.inspectFormRequestOpenCourseTopicApprovalId === 1 ? true : false}
                                                                                    />
                                                                                </Form.Item>
                                                                            </Col>
                                                                            {list?.formRequestOpenCourseTopic?.find(val => index === val.key)?.inspectFormRequestOpenCourseTopicApprovalId === 2 ?
                                                                                <Col span={24} style={{ padding: 0 }}>
                                                                                    <Row style={{ padding: 10, border: '1px solid #EEEEEE' }}>
                                                                                        <Col span={24} style={{ paddingLeft: 10 }}>
                                                                                            เหตุผลไม่ผ่าน
                                                                                            <Popover content={<div style={{ width: 250 }}>ผลตรวจ ที่ทาง Toflyn เเจ้งให้ผู้สอนปรับเเก้ เเละส่งกลับมาใหม่อีกครั้งในเวลาที่กำหนด</div>}>
                                                                                                <AiOutlineQuestionCircle style={{ marginLeft: 5, cursor: 'pointer' }} />
                                                                                            </Popover>
                                                                                        </Col>
                                                                                        <Col span={24} style={{ paddingLeft: 10, paddingRight: 10, paddingTop: 5 }}>
                                                                                            <p style={{ color: "red", backgroundColor: "#EEEEEE", padding: 8 }}>
                                                                                                {list?.formRequestOpenCourseTopic?.find(val => index === val.key)?.reasonFormRequestOpenCourseTopicNotApproval}
                                                                                            </p>
                                                                                        </Col>
                                                                                    </Row>
                                                                                </Col>
                                                                                : []}
                                                                        </Row>
                                                                    </Card>
                                                                ))}
                                                            </div>
                                                        </>
                                                    )}
                                                </Form.List>
                                            </Col>
                                            <Divider />

                                            <Col span={24}>
                                                {list?.inspectionCourseTargetApprovalId === 1 ?
                                                    <div style={{ display: "flex", alignItems: "center" }}>
                                                        <AiFillCheckCircle style={{ height: 23, width: 23, color: 'green' }} />
                                                        <label style={{ paddingLeft: 10, color: 'green' }}>ผ่านการตรวจสอบ</label>
                                                        <br />
                                                    </div>
                                                    : []}
                                                {list?.inspectionCourseTargetApprovalId === 2 ?
                                                    <div style={{ display: "flex", alignItems: "center" }}>
                                                        <AiFillCloseCircle style={{ height: 23, width: 23, color: 'red' }} />
                                                        <label style={{ paddingLeft: 10, color: 'red' }}>ไม่ผ่านการตรวจสอบ</label>
                                                        <br />
                                                    </div>
                                                    : []}
                                                <Form.Item
                                                    label="กลุ่มเป้าหมายของผู้เรียนที่ต้องการสอน ?"
                                                    name="formRegOpenCourseTarget"
                                                    rules={[{ required: true, message: "กรุณากรอกกลุ่มเป้าหมายของผู้เรียนที่ต้องการสอน" }]}
                                                >
                                                    <Input.TextArea
                                                        showCount
                                                        maxLength={200}
                                                        autoSize={{ minRows: 3, maxRows: 5 }}
                                                        onBlur={(e) => {
                                                            form.setFieldsValue({ formRegOpenCourseTarget: e.target.value })
                                                        }}
                                                        disabled={list?.formRequestOpenCourse.inspectionCourseTargetApprovalId === 1 ? true : false}
                                                    />
                                                </Form.Item>
                                                {list?.formRequestOpenCourse.inspectionCourseTargetApprovalId === 2 ?
                                                    <Col span={24} style={{ padding: 0 }}>
                                                        <Row style={{ padding: 10, border: '1px solid #EEEEEE' }}>
                                                            <Col span={24} style={{ paddingLeft: 10 }}>
                                                                เหตุผลไม่ผ่าน
                                                                <Popover content={<div style={{ width: 250 }}>ผลตรวจ ที่ทาง Toflyn เเจ้งให้ผู้สอนปรับเเก้ เเละส่งกลับมาใหม่อีกครั้งในเวลาที่กำหนด</div>}>
                                                                    <AiOutlineQuestionCircle style={{ marginLeft: 5, cursor: 'pointer' }} />
                                                                </Popover>
                                                            </Col>
                                                            <Col span={24} style={{ paddingLeft: 10, paddingRight: 10, paddingTop: 5 }}>
                                                                <p style={{ color: "red", backgroundColor: "#EEEEEE", padding: 8 }}>
                                                                    {list?.formRequestOpenCourse.reasonCourseTargetNotApproval}
                                                                </p>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    : []}
                                            </Col>
                                            <Divider />

                                            <Col span={24}>
                                                {list?.inspectionCourseReceiveApprovalId === 1 ?
                                                    <div style={{ display: "flex", alignItems: "center" }}>
                                                        <AiFillCheckCircle style={{ height: 23, width: 23, color: 'green' }} />
                                                        <label style={{ paddingLeft: 10, color: 'green' }}>ผ่านการตรวจสอบ</label>
                                                        <br />
                                                    </div>
                                                    : []}
                                                {list?.inspectionCourseReceiveApprovalId === 2 ?
                                                    <div style={{ display: "flex", alignItems: "center" }}>
                                                        <AiFillCloseCircle style={{ height: 23, width: 23, color: 'red' }} />
                                                        <label style={{ paddingLeft: 10, color: 'red' }}>ไม่ผ่านการตรวจสอบ</label>
                                                        <br />
                                                    </div>
                                                    : []}
                                                <Form.Item
                                                    label="ผู้ที่เรียนจะได้อะไรจากคอร์สนี้ ?"
                                                    name="formRegOpenCourseReceive"
                                                    rules={[{ required: true, message: "กรุณากรอกสิ่งที่ผู้เรียนจะได้รับจากคอร์สนี้" }]}
                                                >
                                                    <Input.TextArea
                                                        showCount
                                                        maxLength={200}
                                                        autoSize={{ minRows: 3, maxRows: 5 }}
                                                        onBlur={(e) => {
                                                            form.setFieldsValue({ formRegOpenCourseReceive: e.target.value })
                                                        }}
                                                        disabled={list?.formRequestOpenCourse.inspectionCourseReceiveApprovalId === 1 ? true : false}
                                                    />
                                                </Form.Item>
                                                {list?.formRequestOpenCourse.inspectionCourseReceiveApprovalId === 2 ?
                                                    <Col span={24} style={{ padding: 0 }}>
                                                        <Row style={{ padding: 10, border: '1px solid #EEEEEE' }}>
                                                            <Col span={24} style={{ paddingLeft: 10 }}>
                                                                เหตุผลไม่ผ่าน
                                                                <Popover content={<div style={{ width: 250 }}>ผลตรวจ ที่ทาง Toflyn เเจ้งให้ผู้สอนปรับเเก้ เเละส่งกลับมาใหม่อีกครั้งในเวลาที่กำหนด</div>}>
                                                                    <AiOutlineQuestionCircle style={{ marginLeft: 5, cursor: 'pointer' }} />
                                                                </Popover>
                                                            </Col>
                                                            <Col span={24} style={{ paddingLeft: 10, paddingRight: 10, paddingTop: 5 }}>
                                                                <p style={{ color: "red", backgroundColor: "#EEEEEE", padding: 8 }}>
                                                                    {list?.formRequestOpenCourse.reasonCourseReceiveNotApproval}
                                                                </p>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    : []}
                                            </Col>
                                            <Divider />

                                            <Col span={24}>
                                                {list?.inspectionCourseAboutOwnerApprovalId === 1 ?
                                                    <div style={{ display: "flex", alignItems: "center" }}>
                                                        <AiFillCheckCircle style={{ height: 23, width: 23, color: 'green' }} />
                                                        <label style={{ paddingLeft: 10, color: 'green' }}>ผ่านการตรวจสอบ</label>
                                                        <br />
                                                    </div>
                                                    : []}
                                                {list?.inspectionCourseAboutOwnerApprovalId === 2 ?
                                                    <div style={{ display: "flex", alignItems: "center" }}>
                                                        <AiFillCloseCircle style={{ height: 23, width: 23, color: 'red' }} />
                                                        <label style={{ paddingLeft: 10, color: 'red' }}>ไม่ผ่านการตรวจสอบ</label>
                                                        <br />
                                                    </div>
                                                    : []}
                                                <Form.Item
                                                    label="คุณมีความรู้ในเรื่องที่สอนได้อย่างไร ใช้เวลากี่ปีถึงเข้าใจได้ ?"
                                                    name="formRegOpenCourseAboutOwner"
                                                    rules={[{ required: true, message: "กรุณากรอกรายละเอียด" }]}
                                                >
                                                    <Input.TextArea
                                                        showCount
                                                        maxLength={200}
                                                        autoSize={{ minRows: 3, maxRows: 5 }}
                                                        onBlur={(e) => {
                                                            form.setFieldsValue({ formRegOpenCourseAboutOwner: e.target.value })
                                                        }}
                                                        disabled={list?.formRequestOpenCourse.inspectionCourseAboutOwnerApprovalId === 1 ? true : false}
                                                    />
                                                </Form.Item>
                                                {list?.formRequestOpenCourse.inspectionCourseAboutOwnerApprovalId === 2 ?
                                                    <Col span={24} style={{ padding: 0 }}>
                                                        <Row style={{ padding: 10, border: '1px solid #EEEEEE' }}>
                                                            <Col span={24} style={{ paddingLeft: 10 }}>
                                                                เหตุผลไม่ผ่าน
                                                                <Popover content={<div style={{ width: 250 }}>ผลตรวจ ที่ทาง Toflyn เเจ้งให้ผู้สอนปรับเเก้ เเละส่งกลับมาใหม่อีกครั้งในเวลาที่กำหนด</div>}>
                                                                    <AiOutlineQuestionCircle style={{ marginLeft: 5, cursor: 'pointer' }} />
                                                                </Popover>
                                                            </Col>
                                                            <Col span={24} style={{ paddingLeft: 10, paddingRight: 10, paddingTop: 5 }}>
                                                                <p style={{ color: "red", backgroundColor: "#EEEEEE", padding: 8 }}>
                                                                    {list?.formRequestOpenCourse.reasonCourseAboutOwnerNotApproval}
                                                                </p>
                                                            </Col>
                                                        </Row>

                                                    </Col>
                                                    : []}
                                            </Col>
                                            <Divider />
                                        </Row>

                                        <center>

                                            <Popconfirm
                                                title="คุณยืนยันที่จะส่งคำขอเปิดคอร์สหรือไม่ ?"
                                                okText={<span style={{ width: 50 }}>ใช่</span>}
                                                onConfirm={() => {
                                                    form.submit()
                                                }}
                                                cancelText={<span style={{ width: 50 }}>ไม่ใช่</span>}
                                                disabled={list?.inspectionCourseCategoryId === 2 || list?.inspectionCourseCategoryId === 1 ? true : false}
                                                loading={loadingSendInspect}
                                            >
                                                <Button
                                                    loading={loadingSendInspect}
                                                    disabled={list?.inspectionCourseCategoryId === 2 || list?.inspectionCourseCategoryId === 1 ? true : false}
                                                    type="primary"
                                                >
                                                    {!loadingSendInspect ?
                                                        <div style={{ display: "flex", alignItems: "center" }}>
                                                            <Icon icon="bi:send" style={{ color: "white" }} />
                                                            <label style={{ paddingLeft: 10, cursor: "pointer" }}>ส่งคำขอเปิดคอร์ส</label>
                                                        </div>
                                                        : []
                                                    }
                                                </Button>
                                            </Popconfirm>
                                        </center>
                                    </Form>
                                </div>
                            </div>
                            : []}
                    </Content>
                </article>

                <Footer />
            </Layout >

            <Modal
                maskClosable={false}
                closable={false}
                title={
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <BsInfoCircle style={{ width: 20, height: 20, color: "#23B14D" }} />
                        <label style={{ marginLeft: 10, fontWeight: 100 }}>โปรดอ่านข้อตกลงเเละเงื่อนไขบริการ</label>
                    </div>
                }
                visible={modalActive}
                onOk={() => {
                    setModalActive(false)
                }}
                onCancel={() => {
                    // firebase.auth().signOut()
                    navigate("/")
                }}
                okText={
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <Icon icon="line-md:confirm" style={{ color: (agree ? "white" : "#B1B1B1") }} />
                        <label style={{ cursor: 'pointer', paddingLeft: 10 }}>ตกลง</label>
                    </div>
                }
                cancelText={
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <Icon icon="fluent:arrow-step-back-20-filled" />
                        <label style={{ cursor: 'pointer', paddingLeft: 10 }}>กลับไปหน้าเเรก</label>
                    </div>
                }
                width={800}
                okButtonProps={{ disabled: !agree }}
            >
                <Row>
                    <Col span={24} style={{ paddingBottom: 15 }}>
                        ข้อตกลงเเละเงื่อนไขการเปิดคอร์สเรียน กำกับดูเเลภายใต้ชื่อ Toflyn ซึ่งมีข้อตกลงเเละเงื่อนไขดังต่อไปนี้
                    </Col>
                    <Col span={24} style={{ paddingBottom: 15 }}>
                        <div style={{ overflow: 'auto', height: 400, width: 'auto', overflowX: 'hidden', border: "1px solid #eeeeee", padding: 15 }}>
                            <TermsBeingInstructorItem headerSize={14} />
                        </div>
                    </Col>
                    <Col span={24}>
                        <Checkbox
                            className='checkbox-custom'
                            onChange={() => setAgree(!agree)}
                        >ข้าพเจ้ายอมรับข้อกำหนด เเละเงื่อนไขการให้บริการข้างต้น</Checkbox>
                    </Col>
                </Row>
            </Modal>

            <Modal
                maskClosable={false}
                title={<label style={{ fontWeight: 100 }}>ตัวอย่าง เเบบฟอร์มคำขอเปิดคอร์ส</label>}
                visible={modalExampleFormRequestOpenCourse}
                width={900}
                // height={700}
                onCancel={() => {
                    // close modal
                    setModalExampleFormRequestOpenCourse(false)
                }}
                footer={false}
            >
                <Image
                    preview={false}
                    src="error"
                    fallback={`./assets/images/form-request-open-course/examp-form-request-open-course.jpg`}
                />
            </Modal>
        </ >
    )
}
