import { server } from "../../../../../constants"
import { httpClient } from "../../../../../utils/HttpClient"

const getUserManageAllFetch = async (value) => {
    try {
        const result = await httpClient.post(server.GET_USER_MANAGE_ALL_URL, { value })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getCourseOwnerByIdFetch = async (value) => {
    try {
        const result = await httpClient.post(server.GET_COURSE_OWNER_BY_ID_URL, value)
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const updateUserManageByIdFetch = async (value) => {
    try {
        const result = await httpClient.put(server.UPDATE_USER_MANAGE_MANAGE_BY_ID_URL, { value })
        if (result.data.isSuccess) {
            return result.data
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const deleteUserManageByIdFetch = async (id) => {
    try {
        const result = await httpClient.delete(server.DELETE_USER_MANAGE_BY_ID_URL + `/${id}`)
        if (result.data.isSuccess) {
            return result.data
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const lineNotifyUserManageResult = async (value) => {
    try {
        const result = await httpClient.post(server.LINE_NOTIFY_USER_MANAGE_RESULT_URL, { value })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

export {
    // get
    getUserManageAllFetch,
    getCourseOwnerByIdFetch,

    // update
    updateUserManageByIdFetch,

    // delete
    deleteUserManageByIdFetch,

    // line
    lineNotifyUserManageResult
}
