/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */

import { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import {
    AiFillCheckCircle,
    AiFillCloseCircle
} from "react-icons/ai"
import { BiTrashAlt } from "react-icons/bi"
import {
    Row,
    Col,
    Pagination,
    Spin,
    Button,
    Empty
} from 'antd'
import "../../modules/courseMamage/css/manageBoard.css"
import moment from "moment"
import { useDispatch } from "react-redux"
import { Icon } from '@iconify/react'
import { httpClient } from "../../utils/HttpClient"
import { useDimensions } from "../../common/mamager/ScreenManager"
import { server } from "../../constants"
import { setCourseManageCardStateToSuccess } from "../../actions/courseManageCard.action"
import { setCourseManageIntroStateToSuccess } from "../../actions/courseManageIntro.action"
import { setCourseManagePriceStateToSuccess } from "../../actions/courseManagePrice.action"
import { setCourseManageDescriptionStateToSuccess } from "../../actions/courseManageDescription.action"
import { setCourseManageVideoListStateToSuccess } from "../../actions/courseManageVideoList.action"
import { setCourseManageDocumentStateToSuccess } from "../../actions/courseManageDocument.action"
import { setCourseManageAddCurrentStepStateToSuccess } from "../../actions/courseManageAddCurrentStep.action"
import ManageItem from "./ManageItem"
import MonitorOverview from "../monitor/components/overview"
import { deleteRequestOpenCourseFetch, getRequestOpenCourseAllFetch } from "../requestOpenCourse/API"
import { getOpenCourseTokenByIdFetch } from "../erp/API"
import { modifyDate } from "../../common/mamager/DateMamager"
import { MdChangeCircle } from "../../common/mamager/IconMamager"
import { Notification } from "../../common/components/notification"

const formatDate = "DD/MM/YYYY"

export default function ManageBoard(props) {

    let language = "th"
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { width } = useDimensions()

    const [courseAmount, setcourseAmount] = useState(0)
    const [courseManageGroup, setCourseManageGroup] = useState({
        courseManageItem: [],
        pageNumber: 0,
    })
    const [loading, setLoading] = useState(true)

    // course
    let pagingCourseMax = 4
    const [pageCourse, setPageCourse] = useState({
        minValue: 0,
        maxValue: pagingCourseMax
    })
    const [pageCourseLoading, setPageCourseLoading] = useState(false)

    // form request open course
    let pagingFormRequestOpenCourseMax = 2
    const [pageFormRequestOpenCourse, setFormPageRequestOpenCourse] = useState({
        minValue: 0,
        maxValue: pagingFormRequestOpenCourseMax
    })
    const [pageFormRequestOpenCourseLoading, setPageFormRequestOpenCourseLoading] = useState(false)

    const [formRequestOpenCourse, setFormRequestOpenCourse] = useState(null)

    const [isBodyCourseDisplay, setIsBodyCourseDisplay] = useState(true)
    const [isBodyFormRequestOpenCourseDisplay, setIsBodyFormRequestOpenCourseDisplay] = useState(true)

    const handleCourseChange = (value) => {
        setPageCourseLoading(true)

        if (value <= 1) {
            setPageCourse({
                minValue: 0,
                maxValue: pagingCourseMax
            })
        } else {
            setPageCourse({
                minValue: (value - 1) * pagingCourseMax,
                maxValue: value * pagingCourseMax
            })
        }
        window.scrollTo(0, 0);

        setTimeout(function () {
            setPageCourseLoading(false)
        }, 300)
    }

    const handleFormOpenCourseChange = (value) => {
        setPageFormRequestOpenCourseLoading(true)

        if (value <= 1) {
            setFormPageRequestOpenCourse({
                minValue: 0,
                maxValue: pagingFormRequestOpenCourseMax
            })
        } else {
            setFormPageRequestOpenCourse({
                minValue: (value - 1) * pagingFormRequestOpenCourseMax,
                maxValue: value * pagingFormRequestOpenCourseMax
            })
        }
        window.scrollTo(0, 0)

        setTimeout(function () {
            setPageFormRequestOpenCourseLoading(false)
        }, 300)
    }

    const getCourseFound = (mgTop, pdBottom) => {
        return (
            <div style={{ paddingBottom: pdBottom }}>
                <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    imageStyle={{
                        height: 45,
                        marginTop: mgTop
                    }}
                    style={{ width: '100%' }}
                    description={
                        <span style={{ color: 'gray' }}>ไม่พบรายการ</span>
                    }
                >
                </Empty>
            </div>
        )
    }

    const getCourseManage = async (url, value) => {
        try {
            const result = await httpClient.post(url, value)

            if (result.data.isSuccess) {
                setcourseAmount(result.data.formData?.length)

                setCourseManageGroup({
                    courseManageItem: result.data.formData,
                    pageNumber: 0,
                })
            } else {
                Notification("warning", "ระบบ", "การดึงข้อมูลคอร์สมาเเสดงล้มเหลว! โปรดลองใหม่อีกครั้ง")
            }
        } catch (err) {
            Notification("warning", "ระบบ", "การดึงข้อมูลคอร์สมาเเสดงล้มเหลว! โปรดลองใหม่อีกครั้ง")
        }
    }

    const reloadManageCourse = async () => {
        setLoading(true)

        await getCourseManageSearch(
            props.user ? props.user.uid : null,                 // user
            props.textSearch,                                   // text
            "all"                                               // approval
        )

        setLoading(false)
    }

    const getCourseManageSearch = async (user, text, approval) => {
        await getCourseManage(server.COURSE_MANAGE_BY_UID_AND_TEXT_URL, {
            user,
            text,
            approval
        }) // Done
    }

    const getFormRequestOpenCourse = async () => {
        let obj = {
            uid: props.user ? props.user.uid : null,
            isActive: true,
            language
        }
        const resultFormRequestOpenCourseAll = await getRequestOpenCourseAllFetch(obj)
        setFormRequestOpenCourse(resultFormRequestOpenCourseAll)
    }

    const getBaseApi = async () => {
        setLoading(true)

        // console.log("props.user", props.user.uid)
        // console.log("props.textSearch", props.textSearch)
        await getCourseManageSearch(
            props.user ? props.user.uid : null,                 // user
            props.textSearch,                                   // text
            "all"                                               // approval
        )
        await getFormRequestOpenCourse()

        setLoading(false)
    }

    const genBoard = () => {
        return (
            <Row style={{ paddingTop: 15 }}>
                <Col span={24}
                    style={{
                        borderRadius: 15,
                        marginBottom: 15
                    }}
                >
                    <Spin spinning={pageFormRequestOpenCourseLoading} size="large" style={{ marginTop: 50 }}>
                        <Row>
                            <Col
                                span={24}
                                style={{
                                    padding: 12,
                                    backgroundColor: 'white',
                                    borderRadius: isBodyFormRequestOpenCourseDisplay ? "15px 15px 0px 0px" : "15px 15px 15px 15px",
                                }}
                            >
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                    <div
                                        onClick={() => setIsBodyFormRequestOpenCourseDisplay(!isBodyFormRequestOpenCourseDisplay)}
                                        style={{ display: "flex", alignItems: "center" }}
                                    >
                                        {isBodyFormRequestOpenCourseDisplay ?
                                            <Icon icon="bi:chevron-left" rotate={2} style={{ cursor: "pointer", color: "black", width: 17, height: 16 }} />
                                            :
                                            <Icon icon="bi:chevron-left" rotate={3} style={{ cursor: "pointer", color: "black", width: 17, height: 16 }} />
                                        }
                                        <label style={{ paddingLeft: 10, cursor: "pointer" }}>เเบบฟอร์มคำขอเปิดคอร์ส</label>
                                    </div>

                                    <Button
                                        style={{
                                            width: 150,
                                            float: 'right'
                                        }}

                                        type="primary"
                                        onClick={() => {
                                            navigate("/registration-open-course")
                                        }}
                                    >
                                        + เพิ่มคอร์สเรียน
                                    </Button>
                                </div>
                            </Col>

                            {isBodyFormRequestOpenCourseDisplay ?
                                <Col
                                    span={24}
                                    style={{
                                        backgroundColor: "none",
                                        border: "2px solid white",
                                        borderRadius: "0px 0px 15px 15px",
                                    }}
                                >
                                    <div style={{ paddingTop: 15, paddingLeft: 15, paddingRight: 15 }}>
                                        {formRequestOpenCourse
                                            ?.slice(pageFormRequestOpenCourse.minValue, pageFormRequestOpenCourse.maxValue)
                                            ?.map(val => {
                                                return (
                                                    <>
                                                        <div style={{ paddingBottom: 10, backgroundColor: 'white', borderRadius: 10 }}>
                                                            <Row>
                                                                <Col span={24} style={{ padding: 12, borderBottom: '1px solid #EEEEEE' }}>
                                                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                                                        <div style={{ display: "flex", alignItems: "center" }}>
                                                                            {val.inspectFormRequestOpenCourseApprovalId === 1 ? // pass
                                                                                <AiFillCheckCircle style={{ height: 23, width: 23, color: 'green' }} />
                                                                                :
                                                                                (val.inspectFormRequestOpenCourseApprovalId === 2 ? // not pass
                                                                                    <AiFillCloseCircle style={{ height: 23, width: 23, color: 'red' }} />
                                                                                    :
                                                                                    <MdChangeCircle type={2} />
                                                                                )
                                                                            }
                                                                            <label style={{ paddingLeft: 10 }}>{val.inspectFormRequestOpenCourseApprovalName ? val.inspectFormRequestOpenCourseApprovalName : 'รอการตรวจสอบ'}</label>
                                                                        </div>

                                                                        <Row gutter={[12, 12]}>
                                                                            <Col span={24}>

                                                                                {val.inspectFormRequestOpenCourseApprovalId === 1 ?
                                                                                    <Button
                                                                                        style={{ float: 'right', width: 140 }}
                                                                                        type="primary"
                                                                                        onClick={async (e) => {
                                                                                            e.preventDefault()

                                                                                            dispatch(setCourseManageCardStateToSuccess(null))
                                                                                            dispatch(setCourseManageIntroStateToSuccess(null))
                                                                                            dispatch(setCourseManagePriceStateToSuccess(null))
                                                                                            dispatch(setCourseManageDescriptionStateToSuccess(null))
                                                                                            dispatch(setCourseManageVideoListStateToSuccess(null))
                                                                                            dispatch(setCourseManageDocumentStateToSuccess([]))
                                                                                            dispatch(setCourseManageAddCurrentStepStateToSuccess(0))

                                                                                            const resultFormOpenCourseToken = await getOpenCourseTokenByIdFetch(val.formRegOpenCourseId)

                                                                                            navigate("/course-manage-add" +
                                                                                                '?token=' + resultFormOpenCourseToken.token
                                                                                                + '&formROCId=' + val.formRegOpenCourseId
                                                                                                + '&inspectId=' + val.inspectionFormRegOpenCourseId
                                                                                            )
                                                                                        }}
                                                                                    >
                                                                                        <div style={{ display: "flex", alignItems: "center" }}>
                                                                                            <Icon icon="ant-design:upload-outlined" style={{ color: "white", width: 18, height: 18 }} />
                                                                                            <label style={{ paddingLeft: 8, cursor: "pointer" }}>อัพโหลดคอร์ส</label>
                                                                                        </div>
                                                                                    </Button>
                                                                                    :
                                                                                    <>
                                                                                        {val.inspectFormRequestOpenCourseId === 4 ?
                                                                                            <Button
                                                                                                danger
                                                                                                type="primary"
                                                                                                style={{ width: 140, float: 'right' }}
                                                                                                onClick={async () => {

                                                                                                    let obj = {
                                                                                                        formROCId: val.formRegOpenCourseId,
                                                                                                        inspectId: val.inspectionFormRegOpenCourseId,
                                                                                                    }
                                                                                                    const result = await deleteRequestOpenCourseFetch(obj)
                                                                                                    if (result.data.isSuccess) {
                                                                                                        getBaseApi()
                                                                                                        Notification("success", "ระบบ", "ลบเเบบฟอร์มคำขอเปิดคอร์สสำเร็จ")
                                                                                                    } else {
                                                                                                        Notification("error", "ระบบ", "ลบเเบบฟอร์มคำขอเปิดคอร์สไม่สำเร็จ")
                                                                                                    }
                                                                                                }}
                                                                                            >
                                                                                                <BiTrashAlt size={16} style={{ color: 'white', cursor: "pointer" }} />
                                                                                                <label style={{ paddingLeft: 8, cursor: "pointer" }}>ลบ</label>
                                                                                            </Button>
                                                                                            :
                                                                                            <Button
                                                                                                disabled={val.inspectFormRequestOpenCourseId === 2 ? true : false}
                                                                                                type="primary"
                                                                                                style={{ width: 140, float: 'right' }}
                                                                                                onClick={() => {
                                                                                                    navigate("/registration-open-course" +
                                                                                                        '?type=inspect'
                                                                                                        + '&inspectId=' + val.inspectionFormRegOpenCourseId
                                                                                                    )
                                                                                                }}
                                                                                            >
                                                                                                <Icon icon="dashicons:admin-customizer" style={{ color: (val.inspectFormRequestOpenCourseId === 2 ? "#C9C9C9" : "white") }} />
                                                                                                <label style={{ paddingLeft: 10, cursor: "pointer" }}>ปรับเเก้</label>
                                                                                            </Button>
                                                                                        }
                                                                                    </>

                                                                                }
                                                                            </Col>
                                                                        </Row>
                                                                    </div>
                                                                </Col>

                                                                <Col span={24} style={{ paddingTop: 10, fontSize: 14 }}>
                                                                    <Row gutter={[0, 8]}>
                                                                        <Col xs={10} sm={6} md={6} lg={4}><label style={{ float: 'right' }}>คอร์ส : </label></Col>
                                                                        <Col xs={14} sm={18} md={18} lg={20}><label style={{ paddingLeft: 10 }}>{val.openCourseName ? val.openCourseName : '-'}</label></Col>

                                                                        <Col xs={10} sm={6} md={6} lg={4}><label style={{ float: 'right' }}>วันที่ส่งคำขอ : </label></Col>
                                                                        <Col xs={14} sm={18} md={18} lg={20}><label style={{ paddingLeft: 10 }}>{val.createdAt ? modifyDate(moment(val.createdAt).format(formatDate)) : '-'}</label></Col>

                                                                        <Col xs={10} sm={6} md={6} lg={4}><label style={{ float: 'right' }}>เเก้ไขได้ถึงวันที่ : </label></Col>
                                                                        <Col xs={14} sm={18} md={18} lg={20}><label style={{ paddingLeft: 10 }}>{val.dateEndAdjust ? modifyDate(moment(val.dateEndAdjust).format(formatDate)) : '-'}</label></Col>
                                                                    </Row>
                                                                </Col>
                                                            </Row >
                                                        </div>
                                                        <div style={{ paddingTop: 15 }}></div>
                                                    </>
                                                )
                                            })}
                                        {formRequestOpenCourse?.length === 0 ? getCourseFound(20, 20) : []}
                                    </div>

                                    {formRequestOpenCourse?.length > pagingFormRequestOpenCourseMax ?
                                        <Col span={24} style={{ paddingBottom: 15 }}>
                                            <center>
                                                <Pagination
                                                    defaultCurrent={1}
                                                    defaultPageSize={pagingFormRequestOpenCourseMax}
                                                    onChange={handleFormOpenCourseChange}
                                                    total={formRequestOpenCourse?.length}
                                                />
                                            </center>
                                        </Col>
                                        : []
                                    }
                                </Col>
                                : []
                            }
                        </Row>
                    </Spin >
                </Col >

                <Col span={24}>
                    <Spin spinning={pageCourseLoading} size="large" style={{ marginTop: 125 }}>
                        <Row style={{ paddingBottom: 15 }}>
                            <Col
                                span={24}
                                style={{
                                    paddingTop: 16,
                                    paddingBottom: 16,
                                    paddingLeft: 12,
                                    paddingRight: 12,
                                    marginBottom: 0,
                                    backgroundColor: 'white',
                                    borderRadius: isBodyCourseDisplay ? "15px 15px 0px 0px" : "15px 15px 15px 15px",
                                }}
                            >
                                <div
                                    onClick={() => setIsBodyCourseDisplay(!isBodyCourseDisplay)}
                                    style={{ display: "flex", alignItems: "center" }}
                                >
                                    {isBodyCourseDisplay ?
                                        <Icon icon="bi:chevron-left" rotate={2} style={{ cursor: "pointer", color: "black", width: 17, height: 16 }} />
                                        :
                                        <Icon icon="bi:chevron-left" rotate={3} style={{ cursor: "pointer", color: "black", width: 17, height: 16 }} />
                                    }
                                    <label style={{ paddingLeft: 10, cursor: "pointer" }}>คอร์สเรียน</label>

                                </div>
                            </Col>

                            {isBodyCourseDisplay ?
                                <Col
                                    span={24}
                                    style={{
                                        backgroundColor: "none",
                                        border: "2px solid white",
                                        borderRadius: "0px 0px 15px 15px",
                                    }}
                                >
                                    <div style={{ paddingTop: 15, paddingLeft: 15, paddingRight: 15 }}>
                                        {courseManageGroup.courseManageItem?.length > 0 ?
                                            <>
                                                <Row>
                                                    {courseManageGroup.courseManageItem && courseManageGroup.courseManageItem?.length > 0 && courseManageGroup.courseManageItem
                                                        ?.slice(pageCourse.minValue, pageCourse.maxValue)
                                                        ?.map((val, index) => (
                                                            <ManageItem
                                                                key={index}
                                                                course={val}
                                                                index={(index + 1)}
                                                                user={props.user}
                                                                reloadManageCourse={reloadManageCourse}
                                                            />
                                                        ))
                                                    }
                                                </Row>

                                                {courseManageGroup.courseManageItem?.length > pagingCourseMax ?
                                                    <Col span={24} style={{ paddingBottom: 15 }}>
                                                        <center>
                                                            <Pagination
                                                                defaultCurrent={1}
                                                                defaultPageSize={pagingCourseMax}
                                                                onChange={handleCourseChange}
                                                                total={courseManageGroup.courseManageItem?.length}
                                                            />
                                                        </center>
                                                    </Col>
                                                    : []
                                                }
                                            </>
                                            :
                                            <Col span={24}>
                                                {getCourseFound(0, 15)}
                                            </Col>
                                        }
                                    </div>
                                </Col>
                                : []
                            }
                        </Row>
                    </Spin>
                </Col>
            </Row >
        )
    }

    useEffect(() => {
        getBaseApi()
    }, [props.textSearch])

    return (
        <>
            <div style={{ backgroundColor: 'white' }}>
                <div style={width >= 1500 ? { paddingLeft: '5%', paddingRight: '5%' } : ((width > 912 && width < 1500) ? { paddingLeft: '5%', paddingRight: '5%' } : { paddingLeft: '0%', paddingRight: '0%' })}>
                    <div style={{ display: (width > 912 ? "flex" : "flow"), alignItems: "center", justifyContent: "space-between" }}>
                        <div style={{ display: (width > 912 ? "flex" : "flow"), alignItems: "center" }}>
                            <div style={{ display: "flex", alignItems: "center", paddingRight: 12, paddingTop: 12, paddingBottom: (width > 912 ? 12 : 6) }}>
                                <AiFillCheckCircle style={{ height: 23, width: 23, color: 'green' }} />
                                <label style={{ paddingLeft: 10 }}> ตรวจสอบเเล้ว</label>
                            </div>

                            <div style={{ display: "flex", alignItems: "center", paddingRight: 12, paddingTop: (width > 912 ? 12 : 6), paddingBottom: (width > 912 ? 12 : 6) }}>
                                <MdChangeCircle type={2} />
                                <label style={{ paddingLeft: 10 }}> รอตรวจสอบ</label>
                            </div>

                            <div style={{ display: "flex", alignItems: "center", paddingRight: 12, paddingTop: (width > 912 ? 12 : 6), paddingBottom: 12 }}>
                                <AiFillCloseCircle style={{ height: 23, width: 23, color: 'red' }} />
                                <label style={{ paddingLeft: 10 }}> ไม่ผ่านตรวจสอบ</label>
                            </div>
                        </div>

                        <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", paddingTop: (width > 912 ? 12 : 6), paddingBottom: 12 }}>
                            <label className="course-manage-course-amount-all">
                                จำนวนคอร์สที่พบ ({courseAmount})
                            </label>
                        </div>
                    </div>
                </div>
            </div>

            <div style={width >= 1500 ? { paddingLeft: '5%', paddingRight: '5%' } : ((width > 912 && width < 1500) ? { paddingLeft: '5%', paddingRight: '5%' } : { paddingLeft: '0%', paddingRight: '0%' })}>
                <article>
                    <Row>
                        <Col
                            xs={24} md={24} lg={24} xl={6}
                            style={{
                                paddingLeft: width >= 1500 ? 0 : ((width > 912 && width < 1500) ? 0 : 15),
                                paddingRight: 15,
                                paddingTop: 15,
                                paddingBottom: 15
                            }}
                        >
                            <MonitorOverview teacherId={props.user ? props.user.uid : null} type={"basic"} />
                        </Col>

                        <Col xs={24} md={24} lg={24} xl={18}
                            style={width >= 1500 ? { paddingLeft: 0, paddingRight: 0 } : ((width > 912 && width < 1500) ? { paddingLeft: 0, paddingRight: 0 } : { paddingLeft: 15, paddingRight: 15 })}
                        >
                            {!loading ?
                                genBoard()
                                :
                                <div style={{ height: 375, display: "flex", alignItems: "center", justifyContent: "center" }}>
                                    <Spin size="large" />
                                </div>
                            }
                        </Col>
                    </Row>
                </article>
            </div>
        </>
    );
}
