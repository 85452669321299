import { Notification } from "../../../common/components/notification";
import { server } from "../../../constants"
import { httpClient } from "../../../utils/HttpClient"

const getClassroomCourseSearchSuggestByLearnerIdFetch = async (text, learnerId, paginate) => {
    try {
        const result = await httpClient.post(server.CLASSROOM_COURSE_SEARCH_SUGGEST_BY_LEARNER_ID_URL, { text, paginate, learnerId })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) {}
};

const getClassroomCourseFetch = async (value) => {
    try {
        const result = await httpClient.post(server.CLASSROOM_COURSE_BY_LEARNER_ID_URL, value)
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) {}
}

const getCoursePurchaseDetectedFetch = async (value) => { // courseId, uid
    try {
        const result = await httpClient.post(server.GET_COURSE_PURCHASE_DETECTED_URL, { value })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) {}
}

const getClassroomGetBookmarkVideoCourseFetch = async (courseVideoId, courseId, teacherId, uid) => { // bookmark select
    try {
        const result = await httpClient.post(server.CLASSROOM_GET_BOOKMARK_VIDEO_COURSE_URL, { courseVideoId, courseId, teacherId, uid })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) {}
}

const getClassroomUpdateViewVideoCourseFetch = async (courseVideoId) => {
    try {
        const result = await httpClient.post(server.CLASSROOM_UPDATE_VIEW_VIDEO_COURSE_URL, { courseVideoId })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) {}
}

const getTimerWatchCourseByIdFetch = async (value) => { // courseId, uid
    try {
        const result = await httpClient.post(server.GET_TIMER_WATCH_COURSE_BY_ID, { value })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) {}
}

const getPercentWatchVideoCourseFetch = async (value) => { // courseId, uid
    try {
        const result = await httpClient.post(server.GET_PERCENT_WATCH_VIDEO_COURSE_URL, { value })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) {}
}

const updateTimerWatchCourseByIdFetch = async (value) => { // learnerCourseId, courseId, uid, timer 
    try {
        const result = await httpClient.put(server.UPDATE_TIMER_WATCH_COURSE_BY_ID, { value })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) {}
}

const updatePercentWatchVideoCourseFetch = async (value) => { // courseId, uid, 1
    try {
        const result = await httpClient.put(server.UPDATE_PERCENT_WATCH_VIDEO_COURSE_BY_ID_URL, { value })
        return result.data
    } catch (err) {}
}

const updateLearnerCourseByUserIdFetch = async (value) => {
    try {
        const result = await httpClient.put(server.UPDATE_LEARNER_COURSE_BY_USER_ID_URL, { value })
        return result.data
    } catch (err) {}
}

const insertClassroomBookmarkVideoCourseFetch = async (value) => { // bookmark insert
    try {
        const result = await httpClient.put(server.CLASSROOM_INSERT_BOOKMARK_VIDEO_COURSE_URL, value)
        if (result.data.isSuccess) {
            Notification("success", "ระบบ", "บันทึก Bookmark สำเร็จ")
            return result
        } else {
            Notification("error", "ระบบ", "ไม่สามารถบันทึก Bookmark ได้ กรุณาตรวจสอบข้อมูล")
            return null
        }
    } catch (err) {}
}

const deleteClassroomBookmarkVideoCourseFetch = async (id) => { // bookmark delete
    try {
        const result = await httpClient.delete(`${server.CLASSROOM_DELETE_BOOKMARK_VIDEO_COURSE_URL}/${id}`)
        if (result.data.isSuccess) {
            Notification("success", "ระบบ", "ลบ Bookmark สำเร็จ")
            return result
        } else {
            Notification("error", "ระบบ", "ไม่สามารถลบ Bookmark ได้ กรุณาตรวจสอบข้อมูล")
            return null
        }
    } catch (err) {}
}

export {
    // get
    getClassroomCourseSearchSuggestByLearnerIdFetch,
    getClassroomCourseFetch,
    getCoursePurchaseDetectedFetch,
    getClassroomGetBookmarkVideoCourseFetch,
    getClassroomUpdateViewVideoCourseFetch,
    getTimerWatchCourseByIdFetch,
    getPercentWatchVideoCourseFetch,

    // update
    updateTimerWatchCourseByIdFetch,
    updatePercentWatchVideoCourseFetch,
    updateLearnerCourseByUserIdFetch,

    // insert
    insertClassroomBookmarkVideoCourseFetch,

    // delete
    deleteClassroomBookmarkVideoCourseFetch
}