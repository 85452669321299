import { 
    HTTP_REGISTER_COURSE_CATEGORY_INTEREST_FETCHING, 
    HTTP_REGISTER_COURSE_CATEGORY_INTEREST_SUCCESS, 
    HTTP_REGISTER_COURSE_CATEGORY_INTEREST_FAILED, 
} from "../constants"

export const setRegisterCourseCategoryInterestFetching = () => ({
    type: HTTP_REGISTER_COURSE_CATEGORY_INTEREST_FETCHING,
})

export const setRegisterCourseCategoryInterestSuccess = (payload) => ({
    type: HTTP_REGISTER_COURSE_CATEGORY_INTEREST_SUCCESS,
    payload
})

export const setRegisterCourseCategoryInterestFailed = () => ({
    type: HTTP_REGISTER_COURSE_CATEGORY_INTEREST_FAILED,
})
