import { 
    HTTP_COURSE_MANAGE_DOCUMENT_FETCHING, 
    HTTP_COURSE_MANAGE_DOCUMENT_SUCCESS, 
    HTTP_COURSE_MANAGE_DOCUMENT_FAILED, 
} from "../constants"

export const setCourseManageDocumentStateToFetching = () => ({
    type: HTTP_COURSE_MANAGE_DOCUMENT_FETCHING,
})

export const setCourseManageDocumentStateToSuccess = (payload) => ({
    type: HTTP_COURSE_MANAGE_DOCUMENT_SUCCESS,
    payload
})

export const setCourseManageDocumentStateToFailed = () => ({
    type: HTTP_COURSE_MANAGE_DOCUMENT_FAILED,
})