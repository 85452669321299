import { 
    HTTP_REGISTER_ADDRESS_FETCHING, 
    HTTP_REGISTER_ADDRESS_SUCCESS, 
    HTTP_REGISTER_ADDRESS_FAILED, 
} from "../constants"

export const setRegisterAddressFetching = () => ({
    type: HTTP_REGISTER_ADDRESS_FETCHING,
})

export const setRegisterAddressSuccess = (payload) => ({
    type: HTTP_REGISTER_ADDRESS_SUCCESS,
    payload
})

export const setRegisterAddressFailed = () => ({
    type: HTTP_REGISTER_ADDRESS_FAILED,
})
