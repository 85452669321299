import { server } from "../../../constants"
import { httpClient } from "../../../utils/HttpClient"

const getCourseCategoryFetch = async (language) => { // language -> th, eng //move
    try {
        const result = await httpClient.get(server.COURSE_CATEGORY_ALL_URL + `/${language}`)
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getCourseByIdFetch = async (courseId, language) => { // language -> th, eng
    try {
        const result = await httpClient.get(server.COURSE_BY_ID_URL + `/${courseId}/${language}`) // params is courseId, language
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getCourseAllFetch = async (value) =>  { // { isActive }
    try {
        const result = await httpClient.post(server.GET_COURSE_ALL_URL, { value })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getCourseByUserIdFetch = async (value) => {
    try {
        const result = await httpClient.post(server.COURSE_BY_USER_ID_URL, { value })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getCourseRatingFetch = async (authorized, language) => { // language -> th, eng
    try {
        const result = await httpClient.get(server.COURSE_RATING_ALL_URL + `/${authorized}&${language}`)
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getChapterCategoryFetch = async (language) => { // Done
    try {
        const result = await httpClient.get(server.GET_CHAPTER_CATEGORY_URL + `/${language}`)
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getCourseUpdateInterestedFetch = async (courseId, uid) => {
    try {
        const result = await httpClient.post(server.COURSE_UPDATE_INTERESTED_URL, { courseId, uid })
        return result.data;
    } catch (err) { // status 404
        return null
    }
}

const getCourseInsertInterestedFetch = async (courseId, uid) => {
    try {
        const result = await httpClient.post(server.COURSE_INSERT_INTERESTED_URL, { courseId, uid })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const getCourseMediaIntroFetch = async (courseId, language) => {
    try {
        const result = await httpClient.get(server.COURSE_MEDIA_INTRO_BY_ID_URL + `/${courseId}/${language}`)
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getVideoCourseListFetch = async (courseId) => { // courseId
    try {
        const result = await httpClient.get(server.COURSE_VIDEO_LIST_BY_ID_URL + `/${courseId}`)
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getVideoGroupFetch = async (value) => { // uid, courseId, language
    try {
        const result = await httpClient.post(server.GET_COURSE_CHAPTER_AND_VIDEO_BY_ID_URL, { value })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getVideoCourseListByLearnerIdFetch = async (value) => { // courseId, learnerId
    try {
        const result = await httpClient.post(server.GET_COURSE_VIDEO_LIST_BY_LEARNER_ID_URL, { value })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getCourseOwnerFetch = async (ownerId) => {
    try {
        const result = await httpClient.post(server.GET_USER_BY_UID_URL, { uid: ownerId ? ownerId : null })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getCourseDocumentationFetch = async (courseId) => {
    try {
        const result = await httpClient.get(server.COURSE_DOCUMENTATION_BY_ID_URL + `/${courseId}`)
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getCourseUpdateViewCourseFetch = async (value) => {
    try {
        const result = await httpClient.post(server.COURSE_UPDATE_VIEW_COURSE_URL, { value })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getCourseFormatFetch = async (language) => {
    try {
        const result = await httpClient.get(server.COURSE_FORMAT_ALL_URL + `/${language}`)
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getCourseCertificateFetch = async (language) => {
    try {
        const result = await httpClient.get(server.COURSE_CERTIFICATE_ALL_URL + `/${language}`)
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getSearchSuggestFetch = async (value, paginate) => {
    try {
        const result = await httpClient.get(server.COURSE_GROUP_SEARCH_SUGGEST_URL + `/${value}&${paginate}`)
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getCourseSearchByIdFetch = async (value) => {
    try {
        const result = await httpClient.post(server.COURSE_SEARCH_BY_ID_URL, { value })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getAttendLatestCourseByUserIdFetch = async (value) => {
    try {
        const result = await httpClient.post(server.GET_ATTENT_LATEST_COURSE_BY_USER_ID_URL, { value })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getCountLearnerByCourseIdFetch = async (value) => {
    try {
        const result = await httpClient.post(server.GET_COUNT_LEARNER_BY_COURSE_ID_URL, { value })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

export {
    // get
    getCourseCategoryFetch,
    getCourseAllFetch,
    getCourseByIdFetch,
    getCourseByUserIdFetch,
    getCourseRatingFetch,
    getChapterCategoryFetch,
    getCourseUpdateInterestedFetch,
    getCourseInsertInterestedFetch,
    getCourseMediaIntroFetch,
    getVideoCourseListFetch,
    getVideoGroupFetch,
    getVideoCourseListByLearnerIdFetch,
    getCourseOwnerFetch,
    getCourseDocumentationFetch,
    getCourseUpdateViewCourseFetch,
    getCourseFormatFetch,
    getCourseCertificateFetch,
    getSearchSuggestFetch,
    getCourseSearchByIdFetch,
    getAttendLatestCourseByUserIdFetch,
    getCountLearnerByCourseIdFetch
}