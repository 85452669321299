/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */

import { useEffect, useState } from 'react'
import {
    Row,
    Layout,
    Col,
    Input,
    Button,
    Popover,
    Image,
    Empty
} from 'antd'
import { Icon } from '@iconify/react'
import { AiOutlineQuestionCircle } from "react-icons/ai"
import { useLocation, useNavigate } from "react-router-dom"
import moment from 'moment'
import Footer from '../footer'
import LoadingPage from '../loading/page'
import { getCourseOwnerFetch } from '../../../modules/course/API'
import { getInspectCourseCertificateByIdFetch } from './API'
import { secondsToHms } from '../../mamager/TimeManager'
import { modifyDate } from '../../mamager/DateMamager'
import { useDimensions } from '../../mamager/ScreenManager'

const { Content } = Layout
const formatDate = "DD/MM/YYYY"

export default function Certificate(props) {
    const language = "th"

    const [loading, setLoading] = useState(true)

    const navigate = useNavigate()
    const location = useLocation()
    const params = new URLSearchParams(location.search + location.hash)
    const certificateId = params.get("id")

    const { width } = useDimensions()

    const [learnerDetail, setLearnerDetail] = useState(null)
    const [teacherDetail, setTeacherDetail] = useState(null)
    const [certificateCodeSearch, setCertificateCodeSearch] = useState(certificateId ? certificateId : null)
    const [isFoundCertificate, setIsFoundCertificate] = useState("first-time")

    const onSearch = async (render) => {
        setLoading(true)

        let objLearnerDetail = {
            courseCertificateCode: certificateCodeSearch ? certificateCodeSearch : null,
            language
        }
        // console.log("objLearnerDetail : ", objLearnerDetail)
        const resultLearnerDetail = await getInspectCourseCertificateByIdFetch(objLearnerDetail)
        // console.log("resultLearnerDetail : ", resultLearnerDetail)
        setLearnerDetail(resultLearnerDetail)

        if (resultLearnerDetail) {
            const resultTeacherDetail = await getCourseOwnerFetch(resultLearnerDetail.uid) // uid of teacher
            // console.log("resultTeacherDetail : ", resultTeacherDetail)
            setTeacherDetail(resultTeacherDetail)
            setIsFoundCertificate("found")
        } else {
            if (render !== "first-time") {
                setIsFoundCertificate("not-found")
            }
        }

        setLoading(false)
    }

    useEffect(() => {
        onSearch("first-time")
    }, [certificateId])

    return (
        <>
            <header>
                <div style={{ height: 42 }}>
                    <div
                        style={{
                            paddingLeft: 0,
                            paddingRight: 20,
                            paddingTop: 0,
                            paddingBottom: 0,
                            position: 'fixed',
                            zIndex: 2,
                            width: '100%',
                            backgroundColor: '#fff',
                            overflow: 'hidden',
                            boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.2)',
                            height: 50,
                            marginTop: -8
                        }}
                    >
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <img
                                src={`./assets/images/logo/toflynAddName.png`}
                                style={{ width: 147, padding: 3, cursor: 'pointer' }}
                                onClick={() => {
                                    if (props.authorized) {
                                        navigate("/home")
                                    } else {
                                        navigate("/")
                                    }
                                }}
                            />

                            <label style={{ fontSize: 16, paddingRight: 20 }}>ตรวจสอบ Certificate</label>
                        </div>
                    </div>
                </div>
            </header>

            <article>
                <Content style={{ height: '100%' }}>
                    <div
                        style={
                            width > 1500 ?
                                { backgroundColor: "white", marginLeft: '5%', marginRight: '5%', paddingTop: '1%', paddingBottom: '1%' }
                                :
                                (
                                    (width > 912 && width <= 1500) ?
                                        { backgroundColor: "white", marginLeft: '5%', marginRight: '5%', paddingTop: '1%', paddingBottom: '1%' }
                                        :
                                        { backgroundColor: "white", marginLeft: '0%', marginRight: '0%', paddingTop: '0%', paddingBottom: '0%' }
                                )
                        }
                    >
                        <Row style={{ paddingTop: 12 }}>
                            <Col span={24} style={{ textAlign: "center" }}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Input
                                        onChange={(e) => setCertificateCodeSearch(e.target.value)}
                                        size="large"
                                        style={{
                                            width: (width >= 768 ? '40%' : '100%'),
                                            borderRadius: "12px 0px 0px 12px"
                                        }}
                                        placeholder="กรอกรหัสใบประกาศ"
                                    />
                                    <Button
                                        type="primary"
                                        style={{
                                            width: 80,
                                            backgroundColor: "#23B14D",
                                            borderRadius: "00px 10px 10px 0px"
                                        }}
                                        size="large"
                                        onClick={() => {
                                            // navigate("/inspect-certificate?id=" + certificateCodeSearch)
                                            onSearch("not-found")
                                        }}
                                    >
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: "center" }}>
                                            <Icon icon="bi:search" style={{ color: "white" }} />
                                        </div>
                                    </Button>
                                </div>
                            </Col>
                            <Col
                                span={24}
                                style={{
                                    marginTop: (width >= 768 ? 30 : 8),
                                    border: "1px solid #D9DAD9",
                                    paddingLeft: (width >= 768 ? 30 : 8),
                                    paddingRight: (width >= 768 ? 30 : 8),
                                    paddingBottom: 30,
                                    borderRadius: 20
                                }}
                            >
                                {!loading ?
                                    <>
                                        {isFoundCertificate === "first-time" ?
                                            <Row style={{ marginTop: 30 }}>
                                                <Col span={24}>
                                                    <center>
                                                        <Image
                                                            style={{ maxHeight: 300 }}
                                                            src="error"
                                                            fallback={`./assets/images/course-certificate/certificate-icon.png`}
                                                            preview={false}
                                                        />
                                                    </center>
                                                </Col>
                                            </Row>
                                            : []
                                        }

                                        {isFoundCertificate === "not-found" ?
                                            <Row style={{ paddingTop: 75, paddingBottom: 35 }}>
                                                <Col span={24}>
                                                    <center>
                                                        <label>ไม่พบใบประกาศ (Certificate)</label>
                                                    </center>
                                                </Col>
                                            </Row>
                                            : []
                                        }
                                    </>
                                    :
                                    <LoadingPage height={40} loading={loading} />
                                }

                                {!loading && isFoundCertificate === "found" ?
                                    <>
                                        {learnerDetail ?
                                            <Row>
                                                <Col xs={24} lg={learnerDetail.certificateApprovalId === 1 ? 14 : 24}>
                                                    <Row style={{ marginTop: 30 }} gutter={[10, 10]}>
                                                        <Col span={10}><label style={{ float: "right" }}>สถานะ</label></Col>
                                                        <Col span={14}> {learnerDetail.certificateApprovalName}</Col>

                                                        <Col span={10}><label style={{ float: "right" }}>{learnerDetail.businessOperationId ? "คุณ" : "บริษัท"}</label></Col>
                                                        <Col span={14}> {learnerDetail.learnerName}</Col>

                                                        <Col span={10}><label style={{ float: "right" }}>Certificate ID</label></Col>
                                                        <Col span={14}> {learnerDetail.courseCertificateCode}</Col>

                                                        <Col span={10}><label style={{ float: "right" }}>ระดับ Certificate</label></Col>
                                                        <Col span={14}> {learnerDetail.certificateLevel.name}
                                                            <Popover
                                                                content={
                                                                    <div style={{ width: 500 }}>
                                                                        <Row>
                                                                            <Col span={24}>
                                                                                <label>ระดับ Certificate เป็นการเเบ่งกลุ่มของราคาที่ผู้เรียนชำระเงินค่าคอร์ส</label>
                                                                            </Col>

                                                                            <Col span={8}>
                                                                                <label>Legend (L)</label>
                                                                            </Col>
                                                                            <Col span={16}>
                                                                                <label>1 ล้านบาทขึ้นไป</label>
                                                                            </Col>

                                                                            <Col span={8}>
                                                                                <label>Super rare (SR)</label>
                                                                            </Col>
                                                                            <Col span={16}>
                                                                                <label>ตั้งเเต่ 1 เเสนบาทขึ้นไป เเต่น้อยกว่า 1 ล้านบาท</label>
                                                                            </Col>

                                                                            <Col span={8}>
                                                                                <label>Rare (R)</label>
                                                                            </Col>
                                                                            <Col span={16}>
                                                                                <label>ตั้งเเต่ 1 หมื่นบาทขึ้นไป เเต่น้อยกว่า 1 เเสนบาท</label>
                                                                            </Col>

                                                                            <Col span={8}>
                                                                                <label>Common (CM)</label>
                                                                            </Col>
                                                                            <Col span={16}>
                                                                                <label>น้อยกว่า 1 หมื่นบาท</label>
                                                                            </Col>
                                                                        </Row>
                                                                    </div>
                                                                }
                                                            >
                                                                <AiOutlineQuestionCircle style={{ marginLeft: 5, cursor: 'pointer' }} />
                                                            </Popover>
                                                        </Col>

                                                        <Col span={10}><label style={{ float: "right" }}>ราคาหลักสูตร</label></Col>
                                                        <Col span={14}> {Intl.NumberFormat('en').format(learnerDetail.courseNetPrice)} บาท (ณ วันที่ซื้อ)</Col>

                                                        <Col span={10}><label style={{ float: "right" }}>ชื่อหลักสูตร</label></Col>
                                                        <Col span={14}> {learnerDetail.courseTitleName}</Col>

                                                        <Col span={10}><label style={{ float: "right" }}>ชื่อผู้สอน</label></Col>
                                                        <Col span={14}> {teacherDetail.userName}</Col>

                                                        <Col span={10}><label style={{ float: "right" }}>รหัสหลักสูตร</label></Col>
                                                        <Col span={14}> {learnerDetail.courseId}</Col>

                                                        <Col span={10}><label style={{ float: "right" }}>เวลาที่เข้ามาเรียน</label></Col>
                                                        <Col span={14}>
                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                <label style={{ fontWeight: "bold" }}>
                                                                    {secondsToHms(learnerDetail.learnerCourseTimer)} / </label>
                                                                <label>{secondsToHms(learnerDetail.courseVideoTimeAll)}</label>
                                                                <label style={{ fontWeight: "bold", paddingLeft: 5 }}>({parseFloat(((learnerDetail.learnerCourseTimer * 100) / learnerDetail.courseVideoTimeAll).toFixed(2))} %) </label>
                                                                <Popover content={<div style={{ width: 400 }}>ระยะเวลาที่ผู้เรียนเปิดดูวิดีโอในคอร์สจริงๆ เทียบกับเวลาของวิดีโอในหลักสูตร (เวลาที่ใช้ดูวิดีโอจริง X 100) / วิดีโอในหลักสูตร</div>}>
                                                                    <AiOutlineQuestionCircle style={{ marginLeft: 5, cursor: 'pointer' }} />
                                                                </Popover>
                                                            </div>
                                                        </Col>

                                                        <Col span={10}><label style={{ float: "right" }}>สำเร็จหลักสูตร ณ วันที่</label></Col>
                                                        <Col span={14}> {learnerDetail.courseCertificateDownloadFirstTime ? modifyDate(moment(learnerDetail.courseCertificateDownloadFirstTime).format(formatDate)) : "-"}</Col>
                                                    </Row>
                                                </Col>

                                                {learnerDetail.certificateApprovalId === 1 ?
                                                    <Col xs={24} lg={10}>
                                                        <Row
                                                            gutter={[0, 16]}
                                                            style={{
                                                                paddingTop: (width >= 768 ? 30 : 16)
                                                            }}
                                                        >
                                                            <Col span={24}>
                                                                <Row
                                                                    style={{
                                                                        backgroundImage: `url(./assets/images/course-certificate/theme/img_theme_certificate_form${learnerDetail.themeCertificateId}.jpeg)`,
                                                                        backgroundSize: '500px 300px',
                                                                        backgroundRepeat: 'no-repeat',
                                                                        height: 240,
                                                                        // maxWidth: 500,
                                                                        // minWidth: 405
                                                                    }}
                                                                >
                                                                    <Col span={5}>
                                                                        <center>
                                                                            <Row style={{ marginLeft: 20 }}>
                                                                                <Col span={24} style={{ paddingBottom: 5 }}>
                                                                                    <img
                                                                                        src={`./assets/images/logo/toflynUnBG.png`}
                                                                                        style={{ width: 60, height: 60, marginTop: 70 }}
                                                                                    />
                                                                                </Col>
                                                                                <Col span={24}>
                                                                                    <label style={{}}>Toflyn Verified</label>
                                                                                </Col>
                                                                            </Row>
                                                                        </center>
                                                                    </Col>
                                                                    <Col span={14}>
                                                                        <Row style={{ marginTop: 65, fontSize: 18 }} gutter={[10, 10]}>
                                                                            <Col span={24} style={{ textAlign: "center" }}>
                                                                                <label style={{ fontWeight: "bold" }}>CERTIFICATE OF ACHIEVEMENT</label>
                                                                            </Col>
                                                                            <Col span={24} style={{ textAlign: "center" }}>
                                                                                <label style={{}}>{learnerDetail.learnerName}</label>
                                                                            </Col>
                                                                            <Col span={24} style={{ textAlign: "center" }}>
                                                                                <label style={{}}>ได้สำเร็จหลักสูตร</label>
                                                                            </Col>
                                                                            <Col span={24} style={{ textAlign: "center" }}>
                                                                                <label style={{ fontSize: 16 }}>{learnerDetail.courseTitleName}</label>
                                                                            </Col>
                                                                        </Row>
                                                                    </Col>
                                                                    <Col span={5}>
                                                                        <center>
                                                                            <img
                                                                                src={`./assets/images/course-certificate/level/${learnerDetail.certificateLevel.id}.png`}
                                                                                style={{ width: 60, height: 60, marginTop: 71, marginRight: 20 }}
                                                                            />
                                                                        </center>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                            <Col span={24}>
                                                                <label style={{ paddingTop: 10 }}>
                                                                    หมายเหตุ : ภาพใบ Certificate นี้เป็นเพียงภาพประกอบของการตรวจสอบ ซึ่งไม่ใช่ใบจริงเเละไม่สามารถนำไปใช้งานได้ โดยต้องดาวน์โหลดใบ Certificate ตัวจริงจากในห้องเรียนคอร์สของผู้เรียนที่สำเร็จหลักสูตร
                                                                </label>
                                                            </Col>
                                                        </Row>

                                                    </Col>
                                                    : []}
                                            </Row>
                                            :
                                            <div style={{ paddingTop: 100, paddingBottom: 100 }}>
                                                <center>
                                                    <Empty
                                                        style={{ width: '100%' }}
                                                        description={
                                                            <span style={{ color: 'gray' }}>ไม่พบ Certificate โปรดตรวจสอบความถูกต้องของไอดี เเละลองใหม่อีกครั้ง!</span>
                                                        }
                                                    >
                                                    </Empty>
                                                </center>
                                            </div>
                                        }
                                    </>
                                    : []}
                            </Col>
                        </Row>
                    </div>
                </Content>
            </article>

            <Footer />
        </>
    )
}
