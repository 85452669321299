import React from 'react'
import GuidePlatformBreadcrumb from './GuidePlatformBreadcrumb'
import { Icon } from '@iconify/react'
import { fontSize } from '../../../common/mamager/FontManager'

export default function GuidePayment(props) {
    return (
        <>
            <GuidePlatformBreadcrumb pathName={"การซื้อคอร์สเรียน"} />
            <div style={{ fontSize: fontSize.medium }}>
                <p style={{ textAlign: "center" }}>การชำระเงินค่าคอร์สเรียน</p>

                <p style={{ fontWeight: "bold" }}>สำหรับการชำระเงินค่าคอร์สเรียนมี 2 รูปเเบบ ได้เเก่</p>
                
                <Icon icon="radix-icons:dot" style={{ marginTop: -2, color: "#23b14d", width: 25, height: 25 }}/>
                <label style={{ paddingBottom: 0, marginBottom: 0 }}><span>รูปเเบบที่ 1 ชำระเงินค่าคอร์สเรียนทั้งคอร์ส</span></label><br/>
                <p style={{ paddingBottom: 0, marginBottom: 0 }}><span> สำหรับการชำระเงินรูปเเบบนี้ เมื่อชำระเงินเสร็จสิ้น จะสามารถดูเนื้อหาคอร์สเรียนได้ทั้งหมด โดยมีอายุที่สามารถเข้าไปเรียนได้ตามที่ผู้สอนกำหนด ซึ่งมี 4 ประเภทได้เเก่</span></p>
                <label style={{ paddingBottom: 0, marginBottom: 0, paddingLeft: 25 }}><span>ประเภทที่ 1 มีอายุเรียนได้ตลอดชีพ (ไม่มีหมดอายุ)</span></label><br/>
                <label style={{ paddingBottom: 0, marginBottom: 0, paddingLeft: 25 }}><span>ประเภทที่ 2 มีอายุเรียนได้ 3 เดือน</span></label><br/>
                <label style={{ paddingBottom: 0, marginBottom: 0, paddingLeft: 25 }}><span>ประเภทที่ 3 มีอายุเรียนได้ 4 เดือน</span></label><br/>
                <label style={{ paddingBottom: 0, marginBottom: 0, paddingLeft: 25 }}><span>ประเภทที่ 4 มีอายุเรียนได้ 1 ปี</span></label><br/>
                <p><span style={{ color: "red" }}>* เมื่อเวลาหมดอายุในการเรียนเเล้ว ผู้เรียนต้องต่ออายุโดยการชำระเงินอีกครั้งเพื่อให้เข้าเรียนได้ปกติ</span></p>

                <Icon icon="radix-icons:dot" style={{ marginTop: -2, color: "#23b14d", width: 25, height: 25 }}/>
                <label style={{ paddingBottom: 0, marginBottom: 0 }}><span>รูปเเบบที่ 2 ชำระเงินค่าคอร์สเรียนรายวิดีโอ</span></label><br/>
                <p style={{ paddingBottom: 0, marginBottom: 0 }}><span> สำหรับการชำระเงินรูปเเบบนี้ จะเป็นการซื้อวิดีโอคอร์สเรียนตามความต้องการที่ไม่อยากซื้อเนื้อหาคอร์สเรียนทั้งหมดในครั้งเดียว เหมาะกับผู้ที่กำลังซื้อไม่สูง หรือต้องการทยอยซื้อเนื้องหาคอร์สเรียน โดยการซื้อเนื้อหาคอร์สเรียนในรูปเเบบนี้ได้ สอนจะต้องยินดีที่ให้ชำระเงินเป็นรายวิดีโอด้วย </span></p>

                <p><span style={{}}></span></p>
            </div>
        </>
    )
}
