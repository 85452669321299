import { server } from "../../../constants"
import { httpClient } from "../../../utils/HttpClient"

const getCourseCategoryByCourseIdFetch = async (courseId) => {
    try {
        const result = await httpClient.get(server.COURSE_CATEGORY_BY_ID_URL + `/${courseId}`)
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getCourseAlreadyTakenPlaceFetch = async () => {
    try {
        const result = await httpClient.get(server.COURSE_CATEGORY_URL)
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getBannerFetch = async () => {
    try {
        const result = await httpClient.get(server.GET_BANNER_URL)
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getPointViewFetch = async () => {
    try {
        const result = await httpClient.get(server.POINTVIEW_ALL_URL)
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getCheckUserIdOnDBFetch = async (uid) => {
    try {
        const result = await httpClient.post(server.CHECK_USER_ID_ON_DATABASE_URL, { uid })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

export {
    // get
    getCourseCategoryByCourseIdFetch,
    getCourseAlreadyTakenPlaceFetch,
    getBannerFetch,
    getPointViewFetch,
    getCheckUserIdOnDBFetch
}