/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-no-target-blank */

import '../../css/document.css'
import { useState, useCallback, useEffect } from 'react'
import {
    List,
    Button,
    Collapse,
    Empty,
    Spin,
} from 'antd'
import Gallery from "react-photo-gallery"
import Carousel, {
    Modal,
    ModalGateway,
} from "react-images"
import { Icon } from '@iconify/react'
import { img404notfound, storageUrl } from '../../../../constants'
import LoadingPage from '../../../../common/components/loading/page'

export default function CourseDocument(props) {
    const [loading, setLoading] = useState(true)
    const [imageList, setImageList] = useState([])

    const [switchImg, setSwitchImg] = useState('spread') // [หุบ,gorge] [กาง,spread]
    const [imgSize, setImgSize] = useState('short') // short, high

    const [switchPdf, setSwitchPdf] = useState('spread') // [หุบ,gorge] [กาง,spread]
    const [pdfSize, setPdfSize] = useState('short') // short, high

    const [currentImage, setCurrentImage] = useState(0)
    const [viewerIsOpen, setViewerIsOpen] = useState(false)

    const [isImgEmpty, setIsImgEmpty] = useState(true)
    const [isPdfEmpty, setIsPdfEmpty] = useState(true)

    const onSwitchDocument = (type) => {
        if (type === "img") {
            if (switchImg === "gorge") {
                setSwitchImg("spread")
                setPdfSize("short")
            } else {
                setSwitchImg("gorge")
                setPdfSize("high")
            }

            if (switchPdf === "gorge") {
                setImgSize("high")
                setPdfSize("short")
            }
        } else if (type === "pdf") {
            if (switchPdf === "gorge") {
                setSwitchPdf("spread")
                setImgSize("short")
            } else {
                setSwitchPdf("gorge")
                setImgSize("high")
            }

            if (switchImg === "gorge") {
                setPdfSize("high")
                setImgSize("short")
            }
        }
    };

    const openLightbox = useCallback((event, { photo, index }) => {
        setCurrentImage(index)
        setViewerIsOpen(true)
    }, []);

    const closeLightbox = () => {
        setCurrentImage(0)
        setViewerIsOpen(false)
    };

    const imageExists = (image_url) => {
        var http = new XMLHttpRequest()
        http.open('HEAD', image_url, false)
        // http?.send()
        return http.status !== 404
    }

    const getMeta = async (url) => {
        if (imageExists(url)) {
            return url
        } else {
            return img404notfound
        }
    }

    async function getMetas(url, callback) {
        var img = new Image()
        img.src = url
        img.onload = async function () { await callback(this.width, this.height) }
    }

    const init = async () => {
        setLoading(true)

        let findImg = props.coureDocumentation.find(val => val.fileCategoryId === 2)
        if (findImg) {
            setIsImgEmpty(false)
        } else {
            setIsImgEmpty(true)
        }

        let findPdf = props.coureDocumentation.find(val => val.fileCategoryId === 1);
        if (findPdf) {
            setIsPdfEmpty(false)
        } else {
            setIsPdfEmpty(true)
        }

        let filterImage = props.coureDocumentation.filter(val => val.fileCategoryId === 2);
        for (let i = 0; i < filterImage.length; i++) {
            let imgDocURL = `${filterImage[i].coureDocumentationLocation}`
            let img = await getMeta(imgDocURL)

            await getMetas(
                img,
                async function (width, height) {
                    setImageList(imageList => [...imageList, {
                        src: imageExists(imgDocURL) ? imgDocURL : img404notfound,
                        width,
                        height
                    }])
                }
            )
        }

        setLoading(false)
    }

    useEffect(() => {
        init()
    }, [])

    return (
        <div
            style={{ paddingLeft: 15, paddingRight: 15, paddingBottom: 15, height: '100%' }}
        >
            {!loading ?
                <Collapse
                    defaultActiveKey={['1', '2']}
                    ghost
                >
                    <div
                        id="galleryHeader"
                        style={{
                            padding: 15,
                            border: "1px solid #EEEEEE",
                            cursor: "pointer"
                        }}
                        onClick={() => onSwitchDocument("img")}
                    >
                        <label style={{ marginBottom: 0 }}>ไฟล์รูปภาพ</label>
                        {switchImg === "spread" ?
                            <Icon icon="charm:chevron-down" style={{ float: "right", width: 20, height: 20 }} />
                            :
                            <Icon icon="charm:chevron-right" style={{ float: "right", width: 20, height: 20 }} />
                        }
                    </div>
                    {switchImg === "spread" ?
                        <div
                            id="galleryBody"
                            style={{
                                // width: "100%",
                                borderBottom: "1px solid #EEEEEE",
                                borderLeft: "1px solid #EEEEEE",
                                borderRight: "1px solid #EEEEEE",
                                cursor: "pointer"
                            }}
                        >
                            {!isImgEmpty ?
                                <div style={{ overflow: "auto", height: (imgSize === "short" ? 310 : 510), overflowX: "hidden" }}>
                                    <Gallery photos={imageList} onClick={openLightbox} />
                                    <ModalGateway>
                                        {viewerIsOpen ? (
                                            <Modal onClose={closeLightbox}>
                                                <Carousel
                                                    currentIndex={currentImage}
                                                    views={imageList.map(x => ({
                                                        ...x,
                                                        srcset: x.srcSet,
                                                        caption: x.title
                                                    }))}
                                                />
                                            </Modal>
                                        ) : null}
                                    </ModalGateway>
                                </div>
                                :
                                <Empty
                                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                                    imageStyle={{
                                        height: 60,
                                    }}
                                    style={{ width: '100%' }}
                                    description={<span>ไม่มีไฟล์ภาพ</span>}
                                >
                                </Empty>
                            }
                        </div>
                        : []}

                    <div style={{ height: 15 }}></div>

                    <div
                        id="pdfHeader"
                        style={{
                            padding: 15,
                            border: "1px solid #EEEEEE",
                            cursor: "pointer"
                        }}
                        onClick={() => onSwitchDocument("pdf")}
                    >
                        <label style={{ marginBottom: 0 }}>ไฟล์ PDF</label>
                        {switchPdf === "spread" ?
                            <Icon icon="charm:chevron-down" style={{ float: "right", width: 20, height: 20 }} />
                            :
                            <Icon icon="charm:chevron-right" style={{ float: "right", width: 20, height: 20 }} />
                        }
                    </div>
                    {switchPdf === "spread" ?
                        <div
                            id="pdfBody"
                            style={{
                                // width: "100%",
                                borderBottom: "1px solid #EEEEEE",
                                borderLeft: "1px solid #EEEEEE",
                                borderRight: "1px solid #EEEEEE",
                                cursor: "pointer"
                            }}
                        >
                            {!isPdfEmpty ?
                                <div style={{ overflow: "auto", height: (pdfSize === "short" ? 195 : 510), overflowX: "hidden" }}>
                                    <List
                                        itemLayout="horizontal"
                                        dataSource={props.coureDocumentation}
                                        renderItem={item =>
                                            <>
                                                {item.fileCategoryId === 1 ?
                                                    <List.Item style={{ paddingLeft: 15, paddingRight: 15 }}>
                                                        <List.Item.Meta title={<label style={{ fontWeight: 'normal' }}>{item.coureDocumentationName}</label>} />
                                                        <Button
                                                            type="primary"
                                                            onClick={() => {
                                                                window.open(`${item.coureDocumentationLocation}`, '_blank')
                                                            }}
                                                        >
                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                <Icon icon="ant-design:cloud-download-outlined" style={{ color: "white", width: 20, height: 20 }} />
                                                                <label style={{ paddingLeft: 5 }}>ดาวน์โหลด</label>
                                                            </div>
                                                        </Button>
                                                    </List.Item>
                                                    : []}
                                            </>
                                        }
                                    />
                                </div>
                                :
                                <Empty
                                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                                    imageStyle={{
                                        height: 60,
                                    }}
                                    style={{ width: '100%' }}
                                    description={<span>ไม่มีไฟล์ PDF</span>}
                                >
                                </Empty>
                            }
                        </div>
                        : []}
                </Collapse>
                :
                <LoadingPage loading={loading} isTitleActive={true} />
            }
        </div>
    )
}
