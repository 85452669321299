/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */

import { useState } from "react"
import "../footer/css/index.css"
import { BackTop, Col, Image, Row, Tooltip } from "antd"
import { useLocation } from 'react-router-dom'
import { Icon } from '@iconify/react'

import firebase from 'firebase/app'

import Complaint from "../../../modules/helpAndResources/complaint"
import ContractUs from "../contractUs"
import { detectSignIn } from "../../mamager/DetectManager"
import { Notification } from "../notification"

function Footer() {
    let uid = firebase?.auth()?.currentUser?.uid

    const location = useLocation()
    const [isModalContractUsVisible, setIsModalContractUsVisible] = useState(false)

    const [isModalComplaintActive, setIsModalComplaintActive] = useState(false)

    const showContractUsModal = () => {
        setIsModalContractUsVisible(true)
    }

    const handleContractUsCancel = () => {
        setIsModalContractUsVisible(false)
    }

    const handleModalComplaintActive = () => {
        setIsModalComplaintActive(!isModalComplaintActive)
    }

    return (
        <footer>
            <Tooltip
                placement="right"
                style={{ padding: 0, margin: 0 }}
                title={
                    <div
                        style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                        onClick={async () => {
                            const isLogin = await detectSignIn()
                            if (isLogin) {
                                handleModalComplaintActive()
                            } else {
                                Notification("warning", "ระบบ", 'กรุณาเข้าสู่ระบบก่อนร้องเรียน !')
                            }
                        }}
                    >
                        <Icon icon="ic:baseline-feedback" style={{ color: "white", width: 18, height: 18 }} />
                        <span style={{ paddingLeft: 8 }}>ร้องเรียน</span>
                    </div>
                }
            >
                <div
                    style={{
                        padding: 0,
                        backgroundColor: "#23B14D",
                        color: "white",
                        fontVariant: "tabular-nums",
                        lineHeight: '40px',
                        listStyle: "none",
                        WebkitFontFeatureSettings: "tnum",
                        fontFeatureSettings: "tnum",
                        position: "fixed",
                        textAlign: "center",
                        borderRadius: 40,
                        left: 15,
                        bottom: 15,
                        zIndex: 10,
                        width: 40,
                        height: 40,
                        cursor: "pointer"
                    }}
                >
                    <Icon icon="bi:question-lg" style={{ color: "white", width: 25, height: 25, marginTop: 7 }} />
                </div>
            </Tooltip>

            <Complaint
                isModalComplaintActive={isModalComplaintActive}
                handleModalComplaintActive={handleModalComplaintActive}
                uid={uid}
            />

            {/* <nav className="navbar navbar-expand-lg" style={{ backgroundColor: "white", color: "white" }}></nav> */}

            <nav style={{ backgroundColor: "#2C2C2C", color: "white", paddingLeft: '9%', paddingRight: '9%', paddingTop: 50, paddingBottom: 50 }}>
                <Row>
                    <Col xs={24} xl={9}>
                        <Row gutter={15} style={{ paddingRight: 15 }}>
                            <Col span={24}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Image
                                        fallback="./assets/images/logo/toflynUnBG.png"
                                        src="error"
                                        preview={false}
                                        width={50}
                                        height={50}
                                    />
                                    <label style={{ paddingLeft: 10, fontSize: 40 }}>Toflyn</label>
                                </div>
                            </Col>
                            <Col span={24} style={{ paddingTop: 7.5 }}>
                                <div style={{ display: 'flex' }}>
                                    <Icon icon="pixelarticons:teach" style={{ color: "white", width: 26, height: 20, marginLeft: -4 }} />
                                    <label style={{ paddingLeft: 8 }}>ทักษะ ความรู้ เเละความสามารถที่คุณมี อาจเป็นประโยชน์ที่เปลี่ยนชีวิตให้กับใครบางคน</label>
                                </div>
                            </Col>
                            <Col span={24} style={{ paddingBottom: 20 }}>
                                <div style={{ display: 'flex' }}>
                                    <Icon icon="dashicons:welcome-learn-more" style={{ color: "white", width: 20, height: 20 }} />
                                    <label style={{ paddingLeft: 10 }}>มองหาความรู้ มองหาทอฟลิน ความรู้ที่คุณพึ่งได้</label>
                                </div>
                            </Col>

                            <Col span={24}>
                                <a
                                    href="#"
                                    style={{ color: "white", fontSize: 22 }}
                                    onClick={() => showContractUsModal()}
                                >ติดต่อเรา</a>
                            </Col>
                            <Col span={24} style={{ paddingTop: 5, paddingBottom: 15 }}>
                                <a className="nav-link " style={{ color: "white", fontSize: 16, padding: 0 }} href="mailto:info@toflyn.com">
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <Icon icon="entypo:email" style={{ color: "white", width: 20, height: 20 }} />
                                        <span style={{ paddingLeft: 10 }}>info@toflyn.com</span>
                                    </div>
                                </a>
                            </Col>

                            <Col span={24} style={{ paddingBottom: 20 }}>
                                <a className="nav-link" style={{ paddingLeft: 0, paddingRight: 10, float: "left" }} onClick={() => alert("Line official กำลังเปิดใช้งานเร็ว ๆ นี้")}>
                                    <Icon icon="cib:line" style={{ color: "white", width: 30, height: 30 }} />
                                </a>

                                <a className="nav-link" style={{ paddingLeft: 0, paddingRight: 10, float: "left" }} href="https://www.facebook.com/Toflyn/?ti=as" target="_blank">
                                    <Icon icon="akar-icons:facebook-fill" style={{ color: "white", width: 30, height: 30 }} />
                                </a>

                                <a className="nav-link" style={{ paddingLeft: 0, paddingRight: 10, float: "left" }} onClick={() => alert("Youtube official กำลังเปิดใช้งานเร็ว ๆ นี้")}>
                                    <Icon icon="entypo-social:youtube-with-circle" style={{ color: "white", width: 30, height: 30 }} />
                                </a>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={24} xl={15}>
                        <Row>
                            <Col xs={24} xl={8} style={{ paddingBottom: 30 }}>
                                <label style={{ fontWeight: "bold", color: "gray" }}>ผู้เรียน</label>
                                <br />
                                <a href="/inspect-certificate" style={{ color: "white" }}>ตรวจสอบ Certificate</a>
                                <br />
                                <a href="/classroom-course" style={{ color: "white" }}>คอร์สของฉัน</a>
                            </Col>

                            <Col xs={24} xl={8} style={{ paddingBottom: 30 }}>
                                <label style={{ fontWeight: "bold", color: "gray" }}>ผู้สอน</label>
                                <br />
                                <a href="/registration-open-course" style={{ color: "white" }}>เปิดคอร์สเรียน</a>
                                <br />
                                <a href="/course-manage" style={{ color: "white" }}>จัดการคอร์ส</a>
                            </Col>

                            <Col xs={24} xl={8} style={{ paddingBottom: 30 }}>
                                <a href="/toflyn-policy" style={{ color: "white" }}>นโยบายของ Toflyn</a>
                            </Col>

                            <Col span={24} style={{}}>
                                <label style={{ paddingBottom: 0, marginBottom: 0 }}>แพลตฟอร์ม Toflyn ดำเนินการโดย บริษัท กู๊ดเนส โกบอล กรุ๊ป จำกัด</label>
                                <br />
                                <label style={{ color: "white" }}>ที่อยู่ เลขที่ 110 ซอยวิภาวดีรังสิต 4 ถนนวิภาวดีรังสิต เเขวงรัชดาภิเษก เขตดินเเดง เขตดินแดง กรุงเทพมหานคร 10400</label>
                            </Col>
                            <Col span={24}>
                                <Image
                                    fallback="./assets/images/dbd/dbd-logo.png"
                                    src="error"
                                    width={"auto"}
                                    height={50}
                                />
                            </Col>

                            {location.pathname === "/course-shop" || location.pathname === "/payment" || location.pathname === "/course-list" ?
                                <Col span={24} style={{ paddingTop: 30 }}>
                                    <div style={{}}>
                                        <label style={{ color: "white", paddingRight: 15 }}>วิธีการชำระเงิน</label>
                                        <br />

                                        <Image
                                            height={35}
                                            width={"auto"}
                                            style={{ borderRadius: 10, marginTop: 5, paddingRight: 15 }}
                                            src="error"
                                            fallback={`./assets/images/payment/card/jcb-logo.png`}
                                        />

                                        <Image
                                            height={35}
                                            width={"auto"}
                                            style={{ borderRadius: 10, marginTop: 5, paddingRight: 15 }}
                                            src="error"
                                            fallback={`./assets/images/payment/card/visa-logo.png`}
                                        />

                                        <Image
                                            height={35}
                                            width={"auto"}
                                            style={{ borderRadius: 10, marginTop: 5, paddingRight: 15 }}
                                            src="error"
                                            fallback={`./assets/images/payment/card/mastercard-logo.png`}
                                        />

                                        <span style={{ paddingRight: 15 }}>
                                            <Image
                                                height={35}
                                                width={"auto"}
                                                style={{ borderRadius: 10, marginTop: 5, backgroundColor: "white" }}
                                                src="error"
                                                fallback={`./assets/images/payment/prompt-pay.png`}
                                            />
                                        </span>

                                        <Image
                                            height={35}
                                            width={"auto"}
                                            style={{ borderRadius: 10, marginTop: 5 }}
                                            src="error"
                                            fallback={`./assets/images/payment/tranfer-money.jpg`}
                                        />
                                    </div>
                                </Col>
                                : []
                            }
                        </Row>
                    </Col>
                </Row>
            </nav>

            <nav style={{ backgroundColor: "#23B14D", color: "white", paddingLeft: '5%', paddingRight: '5%', paddingTop: 15, paddingBottom: 15, fontWeight: 600 }}>
                <label>สงวนลิขสิทธิ์ © 2022 - Toflyn.com</label>
                <label style={{ float: "right" }}>Version 2.0.1</label>
            </nav>

            {/* <BackTop>
                <div
                    style={{
                        height: 40,
                        width: 40,
                        lineHeight: '40px',
                        borderRadius: 5,
                        backgroundColor: '#23B14D',
                        color: '#fff',
                        textAlign: 'center',
                        fontSize: 14,
                        marginTop: -12,
                        // marginLeft: -24
                    }}
                >
                    <Icon
                        icon="akar-icons:chevron-up"
                        width="25"
                        height="25"
                        style={{ marginTop: -1, color: "white" }}
                    />
                </div>
            </BackTop> */}

            <ContractUs
                isModalContractUsVisible={isModalContractUsVisible}
                handleContractUsCancel={handleContractUsCancel}
            />
        </footer>
    )
}

export default Footer
