import { server } from "../../../constants"
import { httpClient } from "../../../utils/HttpClient"

const getPaymentOrderByUserIdFetch = async (language, uid) => {
    try {
        const result = await httpClient.post(server.GET_PAYMENT_ORDER_BY_USER_ID_URL, { language, uid })
        return result.data.formData
    } catch (err) { // status 404
        return null
    }
}

const getPaymentOrderDetectReBuyByUserId = async (uid, courseId) => {
    try {
        const result = await httpClient.post(server.GET_PAYMENT_ORDER_DETECT_RE_BUY_BY_USER_ID_URL, { uid, courseId })
        return result.data.formData
    } catch (err) { // status 404
        return null
    }
}

const getPaymentCreditDebitTransactionFetch = async (value) => { // Done
    try {
        const result = await httpClient.post(server.GET_PAYMENT_CREDIT_DEBIT_TRANSACTION, { value })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getPaymentPromptPayChargeFetch = async (value) => { // Done
    try {
        const result = await httpClient.post(server.GET_PAYMENT_PROMPT_PAY_CHARGE_URL, { value })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const checkoutCreditCardFetch = async (value) => { // Done
    try {
        const result = await httpClient.post(server.CHECKOUT_CREDIT_CARD_URL, { value })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const checkoutPromptPayFetch = async (value) => { // Done
    try {
        const result = await httpClient.post(server.CHECKOUT_PROMPT_PAY_URL, { value })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const insertPaymentOrderFetch = async (value) => { // Done
    try {
        const result = await httpClient.post(server.INSERT_PAYMENT_ORDER_URL, { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const insertPaymentCourseVideoFetch = async (value) => { // Done
    try {
        const result = await httpClient.post(server.INSERT_PAYMENT_COURSE_VIDEO_URL, { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const insertEarnedSalesRevenueFetch = async (value) => { // Done
    try {
        const result = await httpClient.post(server.INSERT_EARNED_SALES_REVENUE_URL, { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const insertLearnerCourseFetch = async (value) => { // value[ courseId, teacherId, learnerId, isActive, courseMonthId, courseFormatId, is25PercentWatchVideoCourse, is50PercentWatchVideoCourse, is80PercentWatchVideoCourse, startLearnDate, endLearnDate ]
    try {
        const result = await httpClient.post(server.INSERT_LEARNER_COURSE_URL, { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const updatePaymentCreditDebitTransactionFetch = async (value) => { // Done
    try {
        const result = await httpClient.put(server.UPDATE_PAYMENT_CREDIT_DEBIT_TRANSACTION, { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const updatePaymentPromptPayTransactionFetch = async (value) => { // Done
    try {
        const result = await httpClient.put(server.UPDATE_PAYMENT_PROMPT_PAY_TRANSACTION, { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const updatePaymentOrderExpireByIdFetch = async (value) => { // Done
    try {
        const result = await httpClient.put(server.UPDATE_PAYMENT_ORDER_EXPIRE_BY_ID_URL, { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const updateCourseLearnerExpireByIdFetch = async (value) => { // Done
    try {
        const result = await httpClient.put(server.UPDATE_COURSE_LEARNER_EXPIRE_BY_ID_URL, { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const lineNotifyPaymentOrder = async (value) => {
    try {
        const result = await httpClient.post(server.LINE_NOTIFY_PAYMENT_ORDER_URL, { value })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const lineNotifyCoupon = async (value) => {
    try {
        const result = await httpClient.post(server.LINE_NOTIFY_COUPON_URL, { value })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

export {
    // get
    getPaymentOrderByUserIdFetch,
    getPaymentOrderDetectReBuyByUserId,
    getPaymentCreditDebitTransactionFetch,
    getPaymentPromptPayChargeFetch,
    checkoutCreditCardFetch,
    checkoutPromptPayFetch,

    // insert
    insertPaymentOrderFetch,
    insertPaymentCourseVideoFetch,
    insertEarnedSalesRevenueFetch,
    insertLearnerCourseFetch,

    // update
    updatePaymentCreditDebitTransactionFetch,
    updatePaymentPromptPayTransactionFetch,
    updatePaymentOrderExpireByIdFetch,
    updateCourseLearnerExpireByIdFetch,
    
    // delete

    // notify
    lineNotifyPaymentOrder,
    lineNotifyCoupon
}