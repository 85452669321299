import moment from 'moment'
import { Notification } from '../../../../common/components/notification'
import { calculateEndDateLearnCourse } from '../../../../common/mamager/DateMamager'
import { insertEarnedSalesRevenueFetch, insertPaymentCourseVideoFetch, insertPaymentOrderFetch, lineNotifyCoupon, lineNotifyPaymentOrder, updateCourseLearnerExpireByIdFetch, updatePaymentCreditDebitTransactionFetch, updatePaymentPromptPayTransactionFetch } from '../../API'
import { CourseCreateLearner } from '../courseCreateLearner'
import { insertCouponLogFetch, updatePermissionUsedCouponByIdFetch } from '../../../../common/API/coupon'

export async function Checkout(     // insertPaymentOrder
    couponId,                       // ไอดีของคูปอง
    orderType,                      // (1: คอร์สเรียน, ...)
    paymentMethodType,              // (วิธีการชำระเงิน -> [1: โอนเงิน, 2: บัตรเดบิต/เครดิต, 3: Paypal, 4: พร้อมเพย์])
    paymentStatus,                  // (สถานะการชำระเงิน -> [1,successful: ชำระเงินแล้ว, 2,pending: รอการชำระเงิน, 3,failed: ยกเลิกการชำระเงิน, 4,free: ฟรี (ไม่ชำระเงิน)])
    isPaymentCompleted,             // (true คือ การชำระเงินสำเร็จเเละเสร็จสิ้นเเล้ว พร้อมเพิ่มคอร์สเรียนลงในรายการคอร์สของผู้เรียน เเละพร้อมเรียนได้ทันที)
    teacherInfo,                    // ข้อมูลของผู้สอน
    learnerInfo,                    // ข้อมูลของผู้เรียน
    chargeToken,                    // charge token
    chargeStatus,                   // charge status
    dateTimeTransfer,               // วันที่โอนดูจากสลิปธนาคาร หรือวันที่ชำระเงิน (today -> moment())
    isOpenTransactionPaymentOrder,  // เปิดหน้า ธุรกรรมการชำระเงิน
    navigate,                        // redirect page 
    transferSlipImage,              // ภาพหลักฐานการโอนเงิน เฉพาะการโอนเงิน
    expireStatusId,                 // การต่อสัญญาจากหมดอายุเรียนคอร์ส -> [1: ต่อสัญญาเเล้ว, 2: รอต่อสัญญา, null: ซื้อคอร์สนี้ครั้งเเรก หรือเป็นคอร์สที่ไม่มีหมดอายุ]
    courseVideo,                    // จำนวนวิดีโอที่ซื้อ
    videoMain,                      // วิดีโอทั้งหมดของคอร์ส
    paymentVersion                  // Order payment version ของการชำระเงิน
) {
    // console.log({orderType,paymentMethodType,paymentStatus,isPaymentCompleted,teacherInfo,learnerInfo,chargeToken,chargeStatus,dateTimeTransfer,isOpenTransactionPaymentOrder,navigate,transferSlipImage,expireStatusId});

    let paymentStatusId = null
    let paymentStatusName = "-"
    switch (paymentStatus) {
        case "successful":
            paymentStatusId = 1
            paymentStatusName = "ชำระเงินแล้ว"
            break
        case "pending":
            paymentStatusId = 2
            paymentStatusName = "รอการตรวจสอบชำระเงิน"
            break
        case "failed":
            paymentStatusId = 3
            paymentStatusName = "ชำระเงินไม่สำเร็จ"
            break
        case "free":
            paymentStatusId = 4
            paymentStatusName = "ฟรี (ไม่ชำระเงิน)"
            break
        default:
            paymentStatusName = "-"
            break
    }

    if (
        paymentStatusId === 1 ||
        paymentStatusId === 2 ||
        paymentStatusId === 4
    ) {
        // (START) Insert payment order ------------------------------------------------------------------
        let objPaymentOrder = {
            dateTimeTransfer,
            transferSlipImage,
            courseId: teacherInfo.courseId,
            courseOwnerId: teacherInfo.uid,
            uid: learnerInfo.uid,
            netPrice: Number(learnerInfo.amount),
            paymentStatusId,
            paymentMethodType,
            paymentType: 1,
            courseFormat: teacherInfo.courseFormatId,
            last3DigitsBankAccountTransferor: null,
            courseVideoAmount: (courseVideo?.length === videoMain?.length ? null : courseVideo?.length),
            paymentVersion,
            expireStatusId,
            couponId
        }
        const resultPaymentOrder = await insertPaymentOrderFetch(objPaymentOrder)

        // (START) Insert payment course video  ----------------------------------------------------------
        let resultPaymentCourseVideo = { isSuccess: true }
        if (courseVideo?.length > 0) {
            let objPaymentCourseVideo = {
                videoList: courseVideo,
                courseId: teacherInfo.courseId,
                uid: learnerInfo.uid,
                paymentOderId: resultPaymentOrder?.formData?.paymentOderId,
            }
            resultPaymentCourseVideo = await insertPaymentCourseVideoFetch(objPaymentCourseVideo)
        }
        // (END) Insert payment order --------------------------------------------------------------------

        // (START) Send line notify ----------------------------------------------------------------------
        if (resultPaymentOrder.isSuccess && resultPaymentCourseVideo?.isSuccess) {
            let paymentMethodTypeName = ""
            switch (paymentMethodType) {
                case 1: paymentMethodTypeName = "โอนเงิน"; break;
                case 2: paymentMethodTypeName = "บัตรเดบิต/เครดิต"; break;
                case 3: paymentMethodTypeName = "Paypal"; break;
                case 4: paymentMethodTypeName = "พร้อมเพย์"; break;
                default: paymentMethodTypeName = "-"; break;
            }

            let orderTypeName = "";
            switch (orderType) {
                case 1: orderTypeName = "คอร์สเรียน"; break;
                default: orderTypeName = "-"; break;
            }

            let objLineNotify = {
                orderType: orderTypeName,
                orderName: teacherInfo.courseTitleName,
                orderNetPrice: learnerInfo.amount + " บาท",
                orderStatus: paymentStatusName,
                orderDateTime: moment().format('DD/MM/YYYY HH:mm'),
                orderPaymentMethodTypeName: paymentMethodTypeName,
            }
            // console.log("objLineNotify : ", objLineNotify)
            await lineNotifyPaymentOrder(objLineNotify)
            // (END) Send line notify --------------------------------------------------------------------

            if (isOpenTransactionPaymentOrder) {
                Notification("success", "ระบบ", 'ส่งหลักฐานการชำระเงินสำเร็จ')

                navigate("/account?tab=paymentTransaction")
            }
            // 

            // (START) Insert earned sales revenue ----------------------------------------------------------
            let resultUpdateCourseLearnerExpire = { isSuccess: true };
            if (isPaymentCompleted) {
                // เพิ่มคอร์สเรียนในรายการคอร์สของผู้เรียน เเละพร้อมเรียนได้ทันที (สำหรับ ฟรี (ไม่ชำระเงิน), บัตรเดบิต/เครดิต, หรือตรวจสอบการโอนผ่านเเล้ว)
                if (!expireStatusId) { // null is ซื้อคอร์สนี้ครั้งเเรก หรือเป็นคอร์สที่ไม่มีหมดอายุ
                    await CourseCreateLearner(teacherInfo, learnerInfo, navigate, Number(learnerInfo.amount))
                } else {
                    // update course learner expire by id
                    resultUpdateCourseLearnerExpire = await updateCourseLearnerExpire(
                        teacherInfo,
                        learnerInfo,
                        expireStatusId === 2 ? 1 : expireStatusId,
                        dateTimeTransfer
                    )
                }

                // create earned sales revenue (จะเกิด record ได้ก็ต่อเมื่อการชำระเงินต้องสำเร็จ เเละเสร็จสิ้น)
                let objEarnedSalesRevenue = {
                    paymentOderId: resultPaymentOrder.formData ? resultPaymentOrder.formData?.paymentOderId : null,     // paymentOderId latest
                    uid: teacherInfo.uid,                                                                               // TeacherId : เจ้าของเงินที่ Toflyn ต้องส่งให้  
                    receivedDate: moment().add(7, 'd'),                                                                 // วันที่ได้รับเงิน หลังผ่านไป 7 วัน
                    paymentDate: moment(),                                                                              // วันที่เกิดชำระเงิน นับจากรายการชำระเงินผ่านตรวจสอบเเล้ว
                    isReceived: false,                                                                                  // สถานะการรับเงินของผู้สอน
                }
                // console.log("objEarnedSalesRevenue : ", objEarnedSalesRevenue)
                const resultInsertEarnedSalesRevenue = await insertEarnedSalesRevenueFetch(objEarnedSalesRevenue)
                // console.log("insertEarnedSalesRevenueFetch : ", resultInsertEarnedSalesRevenue)

                let resultUpdatePaymentTransaction = { isSuccess: false }
                // ---------------------------------------------------------------------------------------------------------------------
                if (paymentMethodType === 2) { // การชำระเงินเเบบ บัตรเดบิต/เครดิต
                    // update payment credit debit transaction
                    let objPaymentCreditDebitTransaction = {
                        token: chargeToken,
                        status: chargeStatus,
                        uid: learnerInfo.uid,
                    }
                    // console.log("objPaymentCreditDebitTransaction (successful) : ", objPaymentCreditDebitTransaction)
                    resultUpdatePaymentTransaction = await updatePaymentCreditDebitTransactionFetch(objPaymentCreditDebitTransaction)
                } else if (paymentMethodType === 4) { // การชำระเงินเเบบ พร้อมเพย์
                    // update payment credit debit transaction
                    let objPaymentPromptPayTransaction = {
                        token: chargeToken,
                        status: chargeStatus,
                        uid: learnerInfo.uid,
                    }
                    // console.log("objPaymentPromptPayTransaction (successful) : ", objPaymentPromptPayTransaction)
                    resultUpdatePaymentTransaction = await updatePaymentPromptPayTransactionFetch(objPaymentPromptPayTransaction)
                } else if (paymentMethodType === null) { // ฟรี
                    resultUpdatePaymentTransaction = { isSuccess: true }
                }
                // ---------------------------------------------------------------------------------------------------------------------
                // update coupon information
                let resultCouponLog = { isSuccess: true }
                let resultUpdatePermissionUsedCoupon = { isSuccess: true }
                if (couponId) {
                    let objCouponLog = {
                        couponId,
                        courseId: teacherInfo.courseId,
                        uid: learnerInfo.uid,
                    }
                    resultCouponLog = await insertCouponLogFetch(objCouponLog)

                    let objPermissionUsedCoupon = { couponId }
                    resultUpdatePermissionUsedCoupon = await updatePermissionUsedCouponByIdFetch(objPermissionUsedCoupon)

                    await lineNotifyCoupon({
                        title: "มีการใช้คูปอง",
                        couponId: couponId,
                        courseId: teacherInfo.courseId,
                        uid: learnerInfo.uid,
                        dateNotify: moment().format("DD/MM/YYYY HH:mm")
                    })
                }
                // ---------------------------------------------------------------------------------------------------------------------

                if (
                    !resultInsertEarnedSalesRevenue.isSuccess ||
                    !resultUpdatePaymentTransaction.isSuccess ||
                    !resultUpdateCourseLearnerExpire.isSuccess ||
                    !resultCouponLog.isSuccess || 
                    !resultUpdatePermissionUsedCoupon.isSuccess
                ) {
                    Notification("error", "ระบบ", "การบันทึกข้อมูลไม่สมบูรณ์ พบข้อผิดพลาดเกิดขึ้น กรุณาลองใหม่อีกครั้ง หรือติดต่อเราเพื่อช่วยเหลือ")
                }
            }
            // (END) Insert earned sales revenue --------------------------------------------------------------
        } else {
            Notification("error", "ระบบ", 'ชำระเงินไม่สำเร็จ')
        }
    } else if (paymentStatusId === 3) {
        let resultUpdatePaymentTransaction = { isSuccess: false }
        if (paymentMethodType === 2) {
            let objPaymentCreditDebitTransaction = {
                token: chargeToken,
                status: paymentStatus,
                uid: learnerInfo.uid,
            }
            // console.log("objPaymentCreditDebitTransaction (failed) : ", objPaymentCreditDebitTransaction)
            resultUpdatePaymentTransaction = await updatePaymentCreditDebitTransactionFetch(objPaymentCreditDebitTransaction)
        }

        if (resultUpdatePaymentTransaction.isSuccess) {
            navigate(
                "/result",
                {
                    state: {
                        authorized: null,// props.authorized,

                        type: "error",
                        title: "ชำระเงินไม่สำเร็จ (ยกเลิกการชำระเงิน)",
                        subTitle: "หากพบปัญหาในการชำระเงิน โปรดติดต่อทีมงาน Toflyn เพื่อช่วยเเก้ไขปัญหา",
                        btnWidth: 170,

                        btnName1: "ไปหน้าหลัก",
                        btnPathName1: "home",
                        btnIsActive1: true
                    }
                }
            )
        } else {
            Notification("error", "ระบบ", 'การดำเนินการชำระเงินเกิดข้อผิดพลาด')
        }
    } else {
        navigate(
            "/result",
            {
                state: {
                    authorized: null,// props.authorized,

                    type: "error",
                    title: "เกิดข้อผิดพลาดในการชำระเงิน",
                    subTitle: "โปรดติดต่อทีมงาน Toflyn เพื่อช่วยเเก้ไขปัญหา",
                    btnWidth: 170,

                    btnName1: "ไปหน้าหลัก",
                    btnPathName1: "home",
                    btnIsActive1: true
                }
            }
        )
    }
}

export async function updateCourseLearnerExpire(teacherInfo, learnerInfo, expireStatusId, startDate) {
    let endLearnDate = calculateEndDateLearnCourse(teacherInfo?.courseFormatId, startDate)

    let objCourseLearnerExpire = {
        uid: learnerInfo.uid,                       // uid of learner
        courseId: teacherInfo.courseId,             // courseId
        courseOwnerId: teacherInfo.uid,             // uid of teacher
        courseFormat: teacherInfo.courseFormatId,   // course format
        expireStatusId,                             // expire status
        startLearnDate: startDate,                  // start date
        endLearnDate                                // end eate
    }
    // console.log("objCourseLearnerExpire : ", objCourseLearnerExpire);
    const resultUpdateCourseLearnerExpire = await updateCourseLearnerExpireByIdFetch(objCourseLearnerExpire)
    // console.log("updateCourseLearnerExpireByIdFetch : ", resultUpdateCourseLearnerExpire)
    return resultUpdateCourseLearnerExpire
}
