import React, { useState } from 'react'
import {
    Menu,
    Modal,
    Row,
    Col,
} from 'antd'
import { PageHeader } from '@ant-design/pro-layout'
import { BsInfoCircle } from "react-icons/bs"
import Header from '../../../common/components/header'
import { useDimensions } from '../../../common/mamager/ScreenManager'
import Footer from '../../../common/components/footer'
import SalePageDetail from './components/tab/SalePageDetail'
import SalePageImage from './components/tab/SalePageImage'
import SalePageVideo from './components/tab/SalePageVideo'
// import LoadingPage from '../../../common/components/loading/page'

export default function ManageSalepage({ firebase, authorized, user }) {

    const { width } = useDimensions()
    // const [loading, setLoading] = useState(false) // default true

    const [selectedMenuItem, setSelectedMenuItem] = useState("menu1")

    const toggleMenuToDefault = (key) => {
        // update location menu
        setSelectedMenuItem(key)
    }

    const componentsSwitch = (type) => {
        switch (type) {
            case "menu1":
                return <SalePageDetail />
            case "menu2":
                return <SalePageImage />
            case "menu3":
                return <SalePageVideo />
            default:
                return null
        }
    }

    return (
        <div style={{ backgroundColor: '#ECECEC' }}>
            <Header firebase={firebase} user={user} />

            <article>
                <div style={width >= 1500 ? { paddingLeft: '5%', paddingRight: '5%' } : ((width > 912 && width < 1500) ? { paddingLeft: '5%', paddingRight: '5%' } : { paddingLeft: '0%', paddingRight: '0%' })}>
                    <PageHeader title={<span style={{ fontSize: 18 }}>ปรับเเก้ Salepage</span>} style={{ backgroundColor: 'white', borderBottom: '1px solid #ECECEC' }} />
                    <Row style={{ backgroundColor: 'white', width: '100%', height: '100%' }}>
                        <Col xs={8} sm={7} md={6} lg={5}>
                            <Menu
                                defaultSelectedKeys={['1']}
                                defaultOpenKeys={['menu1']}
                                mode="inline"
                                selectedKeys={selectedMenuItem}
                                style={{ height: '100%' }}
                                onClick={async (e) => {
                                    toggleMenuToDefault(e.key);
                                }}
                            >
                                <Menu.Item key="menu1" style={{ height: (width <= 768 ? 90 : 60), borderBottom: "1px solid #DDDDDD", padding: 0, margin: 0 }}>
                                    AAA
                                </Menu.Item>

                                <Menu.Item key="menu2" style={{ height: (width <= 768 ? 90 : 60), borderBottom: "1px solid #DDDDDD", padding: 0, margin: 0 }}>
                                    BBB
                                </Menu.Item>

                                <Menu.Item key="menu3" style={{ height: (width <= 768 ? 90 : 60), borderBottom: "1px solid #DDDDDD", padding: 0, margin: 0 }}>
                                    CCC
                                </Menu.Item>
                            </Menu>
                        </Col>

                        <Col xs={16} sm={17} md={18} lg={19}>
                            <article>
                                {componentsSwitch(selectedMenuItem)}
                            </article>
                        </Col>
                    </Row>
                </div>
            </article>

            <Footer />

            <Modal
                maskClosable={false}
                closable={false}
                title={<label style={{ marginBottom: 0 }}><BsInfoCircle style={{ marginRight: 10, width: 22, height: 22, marginTop: -3, color: '#006FFF' }} />ส่งข้อมูลปรับเเก้เเล้ว คอร์สอยู่ระหว่างการตรวจสอบ</label>}
                // visible={modalActive}
                onOk={() => {
                    // setModalActive(false)
                }}
                onCancel={() => {
                    // setModalActive(false)
                }}
                okButtonProps={{ style: { display: 'none' } }}
                cancelText={<label style={{ width: 50, cursor: 'pointer' }}>ปิด</label>}
            >
                <Row>
                    <Col span={24}>

                    </Col>
                </Row>
            </Modal>
        </div>
    )
}
