/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */

import { Col, Image, Row } from 'antd'
import { useEffect, useState } from 'react'
import { Icon } from '@iconify/react'
import { GiHamburgerMenu } from "react-icons/gi"
import { storageUrl } from '../../../../constants'

export default function CourseVideoPreview(props) {

    const [chapter, setChapter] = useState([])
    const [noneChapter, setNoneChapter] = useState([])
    const [loading, setLoading] = useState(true)

    const generateObj = () => {
        setLoading(true)
        let tmpChapter = []

        if (props.resultChapter?.length > 0) {
            props.resultChapter?.map((valCh, indexCh) => {
                let video = props.resultVideo?.filter((valVo) => valVo.courseChapterId === valCh.courseChapterId);
                tmpChapter.push({
                    courseChapterId: valCh.courseChapterId,
                    courseChapterTitleName: valCh.courseChapterTitleName,
                    chapterCategoryName: valCh.chapterCategoryName,
                    courseChapterNo: valCh.courseChapterNo, //(indexCh + 1),
                    approvalStatusId: valCh.approvalStatusId,
                    inspectCourseApprovalId: valCh.inspectCourseApprovalId,
                    video,
                    isOpen: true
                })
            })
            setChapter(tmpChapter)

            if (props.resultVideo?.length > 0) {
                let tmpNoneChapter = props.resultVideo.filter(val => !val.courseChapterId)
                // console.log('tmpNoneChapter : ', tmpNoneChapter)
                setNoneChapter(tmpNoneChapter)
            } else {
                setNoneChapter([])
            }
        } else {
            setNoneChapter(props.resultVideo?.length > 0 ? props.resultVideo : [])
        }

        setLoading(false)
    }

    const onHideVideo = (id) => {
        setLoading(true);
        let tempChapter = chapter.map(val => {
            return (val.courseChapterId === id ? {
                courseChapterId: val.courseChapterId,
                approvalStatusId: val.approvalStatusId,
                chapterCategoryName: val.chapterCategoryName,
                courseChapterNo: val.courseChapterNo,
                courseChapterTitleName: val.courseChapterTitleName,
                inspectCourseApprovalId: val.inspectCourseApprovalId,
                video: val.video,
                isOpen: !val.isOpen
            } : val)
        })
        setChapter(tempChapter);
        // console.log("itempChapterd : ", tempChapter[0].isOpen, id)

        setLoading(false)
    }

    useEffect(() => {
        generateObj()
    }, [props.resultChapter, props.resultVideo])

    const genVideo = (video) => {
        return (
            <div
                style={{
                    padding: (props.resultChapter?.length > 0 ? 6 : 0),
                    backgroundColor: props.bgChapterBody,
                    color: props.fontColor,
                    borderRight: (props.resultChapter?.length > 0 ? ('6px solid ' + props.bgChapterTitle) : ''),
                    borderLeft: (props.resultChapter?.length > 0 ? ('6px solid ' + props.bgChapterTitle) : ''),
                    borderBottom: (props.resultChapter?.length > 0 ? ('6px solid ' + props.bgChapterTitle) : '')
                }}
            >
                {video?.length > 0 ?
                    <div style={{ backgroundColor: props.bgVideo }}>
                        {video?.map((val, index) => {
                            return (
                                <div
                                    style={{
                                        paddingLeft: 0,
                                        paddingRight: 0,
                                        paddingBottom: (video.length === (index - 1) ? 0 : 2),
                                        paddingTop: (index === 0 ? 2 : 0)
                                    }}
                                >
                                    <div style={{ borderBottom: (video.length === (index + 1) ? '' : ('1px solid ' + props.borderLine)) }}>
                                        <Row style={{ minHeight: 52 }}>
                                            <Col span={2} style={{ textAlign: "center" }}>
                                                <GiHamburgerMenu />
                                                <div style={{ paddingLeft: 3 }}>{index + 1}</div>
                                            </Col>
                                            <Col span={6}>
                                                <Image
                                                    style={{ height: 40, marginTop: 5, border: '1px solid #E8E8E8' }}
                                                    src="error"
                                                    preview={false}
                                                    fallback={`${val.courseVideoImgLocation}`}
                                                />
                                            </Col>
                                            <Col span={16} style={{ paddingRight: 4, paddingLeft: 4 }}>
                                                <label style={{ padding: 0, margin: 0 }}>{val.courseVideoTitleName}</label>
                                                {props.isPaymentByVideo ? 
                                                    <>
                                                        <br />
                                                        <label>{Intl.NumberFormat("en", { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(Number(val.courseVideoPrice))} บาท</label>
                                                    </>
                                                : []}
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    :
                    <div style={{ padding: 8, backgroundColor: props.bgVideo }}>
                        <center>
                            <label style={{ padding: 0, margin: 0 }}>ไม่มีรายการวิดีโอ</label>
                        </center>
                    </div>
                }
            </div>
        )
    }

    return (
        <div style={{ fontSize: 14 }}>
            {!loading ?
                <>
                    {chapter?.length > 0 ?
                        <>
                            {chapter.map((val, index) => {
                                return (
                                    <>
                                        <div
                                            style={{
                                                width: '100%',
                                                paddingTop: 10,
                                                paddingBottom: 10,
                                                paddingLeft: 16,
                                                paddingRight: 16,
                                                borderTop: index === 0 ? '' : '6px dashed #23B14D',
                                                // borderBottom: (chapter.length === (index + 1) ? '' : (val.isOpen ? '' : '1px solid #292929')),
                                                backgroundColor: props.bgChapterTitle
                                            }}
                                        >
                                            <div
                                                style={{ float: "left", marginTop: -1, paddingRight: 8, cursor: "pointer", color: props.fontColor }}
                                                onClick={() => onHideVideo(val.courseChapterId)}
                                            >
                                                {val.isOpen ?
                                                    <Icon icon="charm:chevron-down" style={{ width: 20, height: 20 }} />
                                                    :
                                                    <Icon icon="charm:chevron-right" style={{ width: 20, height: 20 }} />
                                                }
                                            </div>
                                            <span style={{ color: props.fontColor }}>
                                                {/* {console.log("courseChapterNo : ", val)} */}
                                                {`${val.chapterCategoryName} ${val.courseChapterNo} ${val.courseChapterTitleName}`}
                                            </span>
                                        </div>
                                        {val.isOpen ? genVideo(val.video) : []}
                                    </>
                                )
                            })}
                        </>
                        : []
                    }
                    <div style={{ borderTop: chapter?.length === 0 ? '' : '6px dashed #23B14D', backgroundColor: props.bgNonChapterTitle }}>
                        {noneChapter?.length > 0 ? genVideo(noneChapter) : []}
                    </div>
                </>
                : []
            }
        </div>
    )
}
