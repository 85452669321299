/* eslint-disable react-hooks/exhaustive-deps */

import { Button, Col, DatePicker, Divider, Empty, Form, Modal, Row, Select, Spin } from 'antd'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom"
import { Icon } from '@iconify/react'
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    ResponsiveContainer,
    BarChart,
    Bar,
    Tooltip,
    Area,
    AreaChart,
    Legend
} from "recharts"
import { getCourseMonitorFetch } from '../../API'
import CourseRank from '../graph/pieChart/courseRank'
import { useDimensions } from '../../../../common/mamager/ScreenManager'

const { RangePicker } = DatePicker
const { Option } = Select
const formatDateDB = "YYYY-MM-DD"

export default function MonitorOverview(props) {
    const { width } = useDimensions()
    const navigate = useNavigate()

    const [loading, setLoading] = useState(true)
    const [loadingMonitor, setLoadingMonitor] = useState(true)

    const [formInDepthFilter] = Form.useForm()

    const [monitor, setMonitor] = useState(null)
    const [viewChart, setViewChart] = useState([])

    const [switchChartData, setSwitchChartData] = useState("1")
    const [switchChartType, setSwitchChartType] = useState("1")

    const [rangDate, setRangDate] = useState("4")

    const [modalInDepthFilter, setModalInDepthFilter] = useState(false)

    const getCourseMonitor = async (uid, dateRange, isInDepthFilter) => {
        setLoadingMonitor(true)

        let startedDate, endDate = null
        if (isInDepthFilter) {
            startedDate = dateRange.startedDate
            endDate = dateRange.endDate
        } else {
            if (dateRange) {
                if (dateRange === "1") {
                    startedDate = moment().format(formatDateDB) + " 00:00:00"
                    endDate = moment().format(formatDateDB) + " 23:59:59"
                } else if (dateRange === "2") {
                    startedDate = moment().add(-7, 'd').format(formatDateDB) + " 00:00:00"
                    endDate = moment().format(formatDateDB) + " 23:59:59"
                } else if (dateRange === "3") {
                    startedDate = moment().add(-30, 'd').format(formatDateDB) + " 00:00:00"
                    endDate = moment().format(formatDateDB) + " 23:59:59"
                }
                else if (dateRange === "4") {
                    startedDate = moment("2022-01-01").format(formatDateDB) + " 00:00:00"
                    endDate = moment().format(formatDateDB) + " 23:59:59"
                }
            }
        }

        let obj = {
            uid,                // teacherId
            startedDate,
            endDate
        }
        const result = await getCourseMonitorFetch(obj)
        // console.log("getCourseMonitorFetch : ", result)
        setMonitor(result)

        // chart ------------------------------------------
        let dayDistance = function (o) {
            var d = new Date(o.date),
                day = 1000 * 60 * 60 * 24,
                offset = 1 * day
            d.setTime(Math.floor((d.valueOf() - offset) / day) * day + offset)
            return d.toISOString().slice(0, 10)
        }
        // console.log(groupBy(result.visitDetail, dayDistance))

        // visit ------------------------------------------
        let tempCourseVisit = groupByCourseId(result?.visitDetail, "view course")
        // console.log("Course Visit : ", tempCourseVisit)

        // buy --------------------------------------------
        let tempCourseBuy = groupByCourseId(result?.buyDetail, "buy course")
        // console.log("Course Buy : ", tempCourseBuy)

        // all --------------------------------------------
        let tempPushAll = result?.visitDetail
        for (let i = 0; i < result?.buyDetail.length; i++) {
            tempPushAll.push(result?.buyDetail[i])
        }
        let tempDateALL = groupByDate(tempPushAll, dayDistance)
        // console.log("tempDateALL : ", tempDateALL)

        setViewChart({
            // dataVisit: tempSortVisit ? tempSortVisit : [],
            date: tempDateALL ? tempDateALL : [],
            courseVisit: tempCourseVisit ? tempCourseVisit : [],
            courseBuy: tempCourseBuy ? tempCourseBuy : [],
        })

        setLoadingMonitor(false)
    }

    const groupByDate = (array, groupFn) => {
        var hash = Object.create(null), result = []

        array?.forEach(function (o) {
            var key = groupFn(o);
            if (!hash[key]) {
                hash[key] = {
                    date: key,
                    buy: 0,
                    visit: 0
                }
                result.push(hash[key])
            }
            if (o.type === "buy course") {
                hash[key].buy += o.count
            } else if (o.type === "view course") {
                hash[key].visit += o.count
            }
        })
        return result
    }

    const groupByCourseId = (array, type) => {
        var hash = Object.create(null), result = []
        array?.forEach(function (o) {
            var key = o.courseId;
            if (!hash[key]) {
                hash[key] = {
                    name: o.courseTitleName,
                    value: 0,
                    type: o.type
                }
                result.push(hash[key])
            }
            hash[key].value += o.count
        })
        return result
    }

    const onInDepthFilterFinish = async (values) => {
        let startedDate = moment(values.dateRange[0]).format(formatDateDB)
        let endDate = moment(values.dateRange[1]).format(formatDateDB)

        await getCourseMonitor(
            props.teacherId,
            { startedDate, endDate },
            null,
            true
        )
    }

    const getBaseApi = async () => {
        setLoading(true)

        if (props.type === "basic") {
            await getCourseMonitor(props.teacherId, rangDate, false)
        } else {
            await getCourseMonitor(
                props.teacherId,
                props.filter.isInDepth ? props.filter.rangAdvance : props.filter.rangBasic,
                props.filter.isInDepth
            )
        }

        setLoading(false)
    }

    const getGraphFound = (message, pdTop) => {
        return (
            <div style={{ paddingBottom: 50, width: '100%', paddingTop: pdTop }}>
                <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    imageStyle={{
                        height: 80
                    }}
                    style={{ width: '100%' }}
                    description={
                        <span style={{ color: 'gray' }}>{message}</span>
                    }
                >
                </Empty>
            </div>
        )
    }

    const aspectDivisor = (val) => {
        if (val >= 992) {
            return 1
        } else if (val >= 768 && val < 992) {
            return 1.25
        } else if (val >= 576 && val < 768) {
            return 1.5
        } else if (val < 576) {
            return 2
        } else {
            return 1
        }
    }

    useEffect(() => {
        getBaseApi()
    }, [props.filter])

    useEffect(() => {
        setLoadingMonitor(true)
        setTimeout(() => {
            setLoadingMonitor(false)
        }, 250)
    }, [switchChartData])

    const genMonitorOverview = () => {
        return (
            <div
                style={{
                    backgroundColor: 'white',
                    borderRadius: 15,
                    marginBottom: 15,
                    padding: 15
                }}
            >
                {!loading && !loadingMonitor ?
                    <Row>
                        {props.type === "basic" ?
                            <Col span={24}>
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                    <label>Monitor</label>

                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <Button
                                            style={{
                                                width: 35,
                                                padding: 0,
                                                alignItems: "center",
                                                display: "flex",
                                                justifyContent: "center"
                                            }}
                                            onClick={() => setModalInDepthFilter(true)}
                                        >
                                            <Icon icon="akar-icons:calendar" style={{ color: "gray", width: 23, height: 23 }} />
                                        </Button>

                                        <Select
                                            value={rangDate}
                                            style={{ width: 120, paddingLeft: 10 }}
                                            onChange={async (e) => {
                                                await getCourseMonitor(props.teacherId, e);
                                                setRangDate(e);
                                            }}
                                        >
                                            <Option value="1">วันนี้</Option>
                                            <Option value="2">7 วันล่าสุด</Option>
                                            <Option value="3">30 วันล่าสุด</Option>
                                            <Option value="4">ทั้งหมด</Option> {/* ตลอดอายุใช้งาน */}
                                        </Select>
                                    </div>
                                </div>
                                <Divider style={{ marginTop: 12, marginBottom: 5 }} />
                            </Col>
                            :
                            <Col span={24} style={{ paddingBottom: 12 }}>
                                <label style={{ marginTop: 5 }}>ภาพรวม</label>
                                <Divider style={{ marginTop: 12, marginBottom: 5 }} />
                            </Col>
                        }

                        <Col span={24}>
                            <Row gutter={[0, 8]} style={{ paddingTop: 8 }}>
                                <Col span={12}>
                                    <label style={{ marginTop: 14, fontSize: 14 }}>จำนวนคนเข้าชมคอร์ส</label>
                                </Col>
                                <Col span={12}>
                                    <div style={{ float: "right" }}>
                                        <label style={{ fontWeight: "bold", fontSize: 16, marginTop: 0, color: "green" }}>{monitor?.countVisitor}</label>
                                        <label style={{ paddingLeft: 6, marginTop: 8 }}>คน</label>
                                    </div>
                                </Col>

                                <Col span={12}>
                                    <label style={{ marginTop: 14, fontSize: 14 }}>จำนวนคนซื้อคอร์ส</label>
                                </Col>
                                <Col span={12}>
                                    <div style={{ float: "right" }}>
                                        <label style={{ fontWeight: "bold", fontSize: 16, marginTop: 0, color: "green" }}>{monitor?.countBuyCourse}</label>
                                        <label style={{ paddingLeft: 6, marginTop: 8 }}>คน</label>
                                    </div>
                                </Col>

                                <Col span={12}>
                                    <label style={{ marginTop: 14, fontSize: 14 }}>รายได้ที่เกิดขึ้น</label>
                                </Col>
                                <Col span={12}>
                                    <div style={{ float: "right" }}>
                                        <label style={{ fontWeight: "bold", fontSize: 16, marginTop: 0, color: "green" }}>{monitor ? Intl.NumberFormat('en', { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(Number(monitor.sumNetPrice)) : 0}</label>
                                        <label style={{ paddingLeft: 6, marginTop: 8 }}>บาท</label>
                                    </div>
                                </Col>

                                <Col span={12}>
                                    <label style={{ marginTop: 14, fontSize: 14 }}>รายได้หลังหัก Fees</label>
                                </Col>
                                <Col span={12}>
                                    <div style={{ float: "right" }}>
                                        <label style={{ fontWeight: "bold", fontSize: 16, marginTop: 0, color: "green" }}>{monitor ? Intl.NumberFormat('en', { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(Number(monitor.sumBalanceAfterPayFee)) : 0}</label>
                                        <label style={{ paddingLeft: 6, marginTop: 8 }}>บาท</label>
                                    </div>
                                </Col>

                                {props.type === "basic" ?
                                    <Col span={24}>
                                        <Divider style={{ marginTop: 12, marginBottom: 0 }} />
                                        <label
                                            style={{
                                                float: "right",
                                                textDecoration: "underline",
                                                color: "green",
                                                marginTop: 10,
                                                cursor: "pointer",
                                                fontSize: 14,
                                                paddingBottom: 0,
                                                marginBottom: 0
                                            }}
                                            onClick={() => {
                                                navigate("/course-monitor")
                                            }}
                                        >ข้อมูลเพิ่มเติม</label>
                                    </Col>
                                    : []
                                }
                            </Row>
                        </Col>
                    </Row>
                    :
                    <div style={{ height: 140, display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <Spin size="large" />
                    </div>
                }
            </div >
        )
    }

    const customizedLabel = (props) => {
        const { x, y, stroke, value } = props;
        return (
            <text x={x} y={y} dy={-4} fill={stroke} fontSize={14} position="top" textAnchor="middle">
                {value}
            </text>
        );
    }

    const genMonitorChartMoment = () => { // ช่วงเวลา
        return (
            <>
                {!loading ?
                    <div
                        style={{
                            backgroundColor: 'white',
                            borderRadius: 15,
                            marginBottom: 15,
                            marginLeft: (width >= 992 ? 15 : 0),
                            padding: 15,
                            fontSize: 14
                        }}
                    >
                        <Row>
                            <Col span={24}>
                                <div
                                    style={{
                                        display: width < 576 ? "flow" : "flex",
                                        alignItems: 'center',
                                        justifyContent: width < 576 ? "flex-start" : "space-between"
                                    }}
                                >
                                    <label
                                        style={{
                                            fontSize: 16
                                        }}
                                    >
                                        {switchChartData === "1" ? "ทั้งหมด/วัน" : (switchChartData === "2" ? "เข้าชมคอร์ส/วัน" : (switchChartData === "3" ? "ซื้อคอร์ส/วัน" : "-"))}
                                    </label>

                                    <div
                                        style={{
                                            display: width < 576 ? "flow" : "flex",
                                            alignItems: 'center'
                                        }}
                                    >
                                        <Select
                                            value={switchChartType}
                                            style={{ width: (width < 576 ? "100%" : 150) }}
                                            onChange={(e) => setSwitchChartType(e)}
                                        >
                                            <Option value="1">กราฟพื้นที่</Option>
                                            <Option value="2">กราฟเส้น</Option>
                                            <Option value="3">กราฟเเท่ง</Option>
                                        </Select>

                                        <Select
                                            value={switchChartData}
                                            style={{ width: (width < 576 ? "100%" : 150), paddingLeft: 6 }}
                                            onChange={(e) => setSwitchChartData(e)}
                                        >
                                            <Option value="1">ทั้งหมด</Option>
                                            <Option value="2">คนเข้าชมคอร์ส</Option>
                                            <Option value="3">คนซื้อคอร์ส</Option>
                                        </Select>
                                    </div>
                                </div>
                            </Col>

                            <Col span={24}>
                                <Divider style={{ marginTop: 15, marginBottom: 5 }} />
                                {!loadingMonitor ?
                                    <>
                                        {viewChart.date?.length > 0 ?
                                            <>
                                                {switchChartType === "1" ?
                                                    <ResponsiveContainer
                                                        width='99%'
                                                        aspect={4.0 / aspectDivisor(width)}
                                                    >
                                                        <AreaChart
                                                            data={viewChart.date}
                                                            margin={{
                                                                top: 10,
                                                                right: 30,
                                                                left: 0,
                                                                bottom: 0,
                                                            }}
                                                        >
                                                            <CartesianGrid strokeDasharray="3 3" />
                                                            <XAxis dataKey="date" />
                                                            <YAxis />
                                                            <Tooltip />
                                                            {switchChartData === "1" ?
                                                                <>
                                                                    <Area type="monotone" name='เข้าชมคอร์ส' dataKey="visit" label={customizedLabel} stroke="#23B14D" fill="#23B14D" />
                                                                    <Area type="monotone" name='ซื้อคอร์ส' dataKey="buy" label={customizedLabel} stroke="#8884d8" fill="#8884d8" />
                                                                    <Legend />
                                                                </>
                                                                : []
                                                            }

                                                            {switchChartData === "2" ?
                                                                <Area type="monotone" name='ซื้อคอร์ส' dataKey="visit" label={customizedLabel} stroke="#23B14D" fill="#23B14D" />
                                                                : []
                                                            }

                                                            {switchChartData === "3" ?
                                                                <Area type="monotone" name='เข้าชมคอร์ส' dataKey="buy" label={customizedLabel} stroke="#8884d8" fill="#8884d8" />
                                                                : []
                                                            }
                                                        </AreaChart>
                                                    </ResponsiveContainer>
                                                    : []
                                                }

                                                {switchChartType === "2" ?
                                                    <ResponsiveContainer
                                                        width='100%'
                                                        aspect={4.0 / 1}
                                                    >
                                                        <LineChart data={viewChart.date}>
                                                            <XAxis dataKey="date" />
                                                            <YAxis />
                                                            <Tooltip />
                                                            <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
                                                            {switchChartData === "1" ?
                                                                <>
                                                                    <Line type="monotone" name='เข้าชมคอร์ส' dataKey="visit" label={customizedLabel} stroke="#23B14D" />
                                                                    <Line type="monotone" name='ซื้อคอร์ส' dataKey="buy" label={customizedLabel} stroke="#8884d8" />
                                                                    <Legend />
                                                                </>
                                                                : []
                                                            }

                                                            {switchChartData === "2" ?
                                                                <Line type="monotone" name='ซื้อคอร์ส' dataKey="visit" label={customizedLabel} stroke="#23B14D" />
                                                                : []
                                                            }

                                                            {switchChartData === "3" ?
                                                                <Line type="monotone" name='เข้าชมคอร์ส' dataKey="buy" label={customizedLabel} stroke="#8884d8" />
                                                                : []
                                                            }
                                                        </LineChart>
                                                    </ResponsiveContainer>
                                                    : []
                                                }

                                                {switchChartType === "3" ?
                                                    <ResponsiveContainer
                                                        width="100%"
                                                        aspect={4.0 / 1}
                                                    >
                                                        <BarChart data={viewChart.date}>
                                                            <XAxis dataKey="date" />
                                                            <YAxis />
                                                            <Tooltip />
                                                            {switchChartData === "1" ?
                                                                <>
                                                                    <Bar dataKey="visit" name='เข้าชมคอร์ส' label={{ position: 'top' }} fill="#23B14D" />
                                                                    <Bar dataKey="buy" name='ซื้อคอร์ส' label={{ position: 'top' }} fill="#8884d8" />
                                                                    <Legend />
                                                                </>
                                                                : []
                                                            }

                                                            {switchChartData === "2" ?
                                                                <Bar dataKey="visit" name='ซื้อคอร์ส' label={{ position: 'top' }} fill="#23B14D" />
                                                                : []
                                                            }

                                                            {switchChartData === "3" ?
                                                                <Bar dataKey="buy" name='เข้าชมคอร์ส' label={{ position: 'top' }} fill="#8884d8" />
                                                                : []
                                                            }
                                                        </BarChart>
                                                    </ResponsiveContainer>
                                                    : []
                                                }
                                            </>
                                            :
                                            getGraphFound("ไม่พบข้อมูล ความเคลื่อนไหวของคอร์ส", 40)
                                        }
                                    </>
                                    :
                                    <div style={{ height: 262 }}>
                                        <center><Spin size="large" style={{ marginTop: 110 }} /></center>
                                    </div>
                                }
                            </Col>
                        </Row>
                    </div>
                    :
                    <div style={{ height: 262, display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <Spin size="large" />
                    </div>
                }
            </>
        )
    }

    const genMonitorChartCompare = () => { // เปรียบเทียบ
        return (
            <>
                {!loading ?
                    <div
                        style={{
                            backgroundColor: 'white',
                            borderRadius: 15,
                            marginBottom: 15,
                            padding: 15,
                            fontSize: 14
                        }}
                    >
                        <Row>
                            <Col span={24}>
                                {!loadingMonitor ?
                                    <CourseRank
                                        switchChartData={switchChartData}
                                        data={viewChart}
                                        countTotal={switchChartData === "1" ? monitor.countVisitor : monitor.countBuyCourse}
                                    />
                                    :
                                    <div style={{ height: 262 }}>
                                        <center><Spin size="large" style={{ marginTop: 110 }} /></center>
                                    </div>
                                }
                            </Col>
                        </Row>
                    </div>
                    :
                    <div style={{ height: 262, display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <Spin size="large" />
                    </div>
                }
            </>
        )
    }

    return (
        <>
            {props.type === "basic" ?
                genMonitorOverview()
                :
                <Row>
                    <Col xs={24} lg={8}>{genMonitorOverview()}</Col>
                    <Col xs={24} lg={16}>{genMonitorChartMoment()}</Col>
                    <Col span={24}>{genMonitorChartCompare()}</Col>
                </Row>
            }

            <Modal
                title="ตัวกรองเชิงลึก"
                visible={modalInDepthFilter}
                width={400}
                maskClosable={false}
                onCancel={() => {
                    setModalInDepthFilter(false);
                }}

                footer={[
                    <Button
                        type="primary"
                        onClick={() => {
                            formInDepthFilter.submit()
                            setModalInDepthFilter(false)
                        }}
                        loading={loadingMonitor}
                    >
                        {!loadingMonitor ?
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <Icon icon="charm:search" style={{ color: "white", width: 18, height: 18 }} />
                                <label style={{ paddingLeft: 10, cursor: "pointer" }}>ค้นหา</label>
                            </div>
                            : []
                        }
                    </Button>
                ]}
            >
                <Form
                    layout="vertical"
                    form={formInDepthFilter}
                    onFinish={onInDepthFilterFinish}
                >
                    <Form.Item
                        name="dateRange"
                        label="ช่วงวันที่"
                        rules={[{ required: true, message: 'กรุณากรอกช่วงวันที่' }]}
                    >
                        <RangePicker
                            ranges={{
                                "วันนี้": [moment(), moment()],
                                'เดือนนี้': [moment().startOf('month'), moment().endOf('month')],
                            }}
                        />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}
