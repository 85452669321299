import { 
    HTTP_COURSE_MANAGE_VIDEO_LIST_FETCHING, 
    HTTP_COURSE_MANAGE_VIDEO_LIST_SUCCESS, 
    HTTP_COURSE_MANAGE_VIDEO_LIST_FAILED, 
} from "../constants"

export const setCourseManageVideoListStateToFetching = () => ({
    type: HTTP_COURSE_MANAGE_VIDEO_LIST_FETCHING,
})

export const setCourseManageVideoListStateToSuccess = (payload) => ({
    type: HTTP_COURSE_MANAGE_VIDEO_LIST_SUCCESS,
    payload
})

export const setCourseManageVideoListStateToFailed = () => ({
    type: HTTP_COURSE_MANAGE_VIDEO_LIST_FAILED,
})