/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable jsx-a11y/anchor-is-valid */

import { useState, useEffect, useMemo, useRef } from 'react'
import {
    AiFillCheckCircle,
    AiFillCloseCircle,
} from "react-icons/ai"
import { Icon } from '@iconify/react'
import { useLocation } from "react-router-dom"
import {
    Menu,
    Popconfirm,
    Modal,
    Row,
    Col,
    Button,
    Divider
} from 'antd'
import { PageHeader } from '@ant-design/pro-layout'
import { ExclamationCircleOutlined } from "@ant-design/icons"
import { useDispatch, useSelector } from "react-redux"
import { setCourseManageCardStateToSuccess } from '../../../actions/courseManageCard.action'
import { setCourseManageIntroStateToSuccess } from '../../../actions/courseManageIntro.action'
import { setCourseManagePriceStateToSuccess } from '../../../actions/courseManagePrice.action'
import { setCourseManageDescriptionStateToSuccess } from '../../../actions/courseManageDescription.action'
import Footer from '../../../common/components/footer'
import Header from '../../../common/components/header'
import LoadingPage from '../../../common/components/loading/page'
import { Notification } from '../../../common/components/notification'
import { useDimensions } from '../../../common/mamager/ScreenManager'
import ManageDescript from '../components/descript'
import ManageDocument from '../components/document'
import ManageMediaIntro from '../components/mediaIntro'
import ManagePrice from '../components/price'
import ManageCard from '../components/card'
import ManageVideo from '../components/video'
import "../css/edit.css"
import { getCourseManageChapterFetch, getCourseManageDocumentApiFetch, getCourseManageFetch, getCourseManageMediaIntroFetch, getCourseManageVideoListFetch, getWaitApprovalFetch, updateRequestEditCourseInspectFetch } from '../API'
import { getInspectRequestEditCourseByIdFetch, getStatusInspectRequestEditCourseByIdFetch } from '../../erp/API'
import { MdChangeCircle } from '../../../common/mamager/IconMamager'

const { confirm } = Modal

export default function CourseEdit({ firebase, authorized, user }) {
    const language = "th"
    const { width } = useDimensions()

    const dispatch = useDispatch()

    const location = useLocation()
    const params = new URLSearchParams(location.search)
    const courseId = Number(params.get("course-id"))

    const [inspect, setInspect] = useState([])
    const [loading, setLoading] = useState(true)
    const [isNotOpenSendAdjust, setNotIsOpenSendAdjust] = useState(true) // disable or enable btn send adjust

    const isSavedRef = useRef(false)

    const { resultCard } = useSelector(({ courseManageCardReducer }) => courseManageCardReducer)
    const { resultIntro } = useSelector(({ courseManageIntroReducer }) => courseManageIntroReducer)
    const { resultPrice } = useSelector(({ courseManagePriceReducer }) => courseManagePriceReducer)
    const { resultDescription } = useSelector(({ courseManageDescriptionReducer }) => courseManageDescriptionReducer)
    const { resultDocument } = useSelector(({ courseManageDocumentReducer }) => courseManageDocumentReducer)
    const { resultVideoList } = useSelector(({ courseManageVideoListReducer }) => courseManageVideoListReducer)
    const { resultChapter } = useSelector(({ courseManageChapterReducer }) => courseManageChapterReducer)

    const [course, setCourse] = useState([])
    const [courseMediaIntro, setCourseMediaIntro] = useState([])
    const [courseVideoList, setCourseVideoList] = useState([])
    const [courseDocument, setCourseDocument] = useState([])
    const [courseChapter, setCourseChapter] = useState([])

    const [constantCourse, setConstantCourse] = useState(null)
    const [constantMediaIntro, setConstantMediaIntro] = useState(null)
    const [constantCourseDocument, setConstantCourseDocument] = useState([])
    const [constantCourseVideoList, setConstantCourseVideoList] = useState([])
    const [constantCourseChapter, setConstantCourseChapter] = useState([])

    const [waitApproval, setWaitApproval] = useState()

    const [selectedMenuItem, setSelectedMenuItem] = useState("menu1")

    const getCourse = async (isConstant) => {
        const result = await getCourseManageFetch(user.uid, courseId)
        if (!isConstant) {
            setCourse(result)
        }
        return result
    }

    const getCourseMediaIntro = async (isConstant) => {
        const result = await getCourseManageMediaIntroFetch(user.uid, courseId)
        if (!isConstant) {
            setCourseMediaIntro(result)
        }
        return result
    }

    const getCourseManageDocumentApi = async (isConstant) => {
        let obj = {
            courseId: courseId,
            uid: user.uid,
            isActive: 1,
            language
        }
        const result = await getCourseManageDocumentApiFetch(obj)
        if (!isConstant) {
            setCourseDocument(result)
        }
        return result
    }

    const getCourseCourseVideoList = async (isConstant) => {
        let obj = {
            courseId: courseId,
            uid: user.uid,
            isActive: 1,
            language
        }
        console.log("obj : ", obj)

        const result = await getCourseManageVideoListFetch(obj)
        console.log("getCourseManageVideoListFetch : ", result)
        if (!isConstant) {
            setCourseVideoList(result)
        }
        return result
    }

    const getCourseCourseChapter = async (isConstant) => {
        let obj = {
            courseId: courseId,
            // uid: user.uid,
            isActive: 1,
            language
        }
        const result = await getCourseManageChapterFetch(obj)
        if (!isConstant) {
            setCourseChapter(result)
        }
        return result
    }

    const getWaitApproval = async () => {
        const result = await getWaitApprovalFetch(user.uid, courseId)
        setWaitApproval(result)
    }

    const handleCourseReload = async () => {
        setLoading(true)

        isSavedRef.current = true
        await getInspectApproval()
        await getWaitApproval()
        await getCourse(false)

        setLoading(false)
    }

    const handleCourseMediaIntroReload = async () => {
        setLoading(true)

        isSavedRef.current = true
        await getCourseMediaIntro(false)

        // reload icon apploval
        await handleCourseReload()

        setLoading(false)
    }

    const handleDocumentReload = async () => {
        setLoading(true)

        isSavedRef.current = true
        await getConstantData(false)
        // await getCourseManageDocumentApi(false) // isConstant

        // reload icon apploval
        await handleCourseReload()

        setLoading(false)
    }

    const handleCourseVideoListReload = async () => {
        setLoading(true)

        isSavedRef.current = true
        await getConstantData(false)
        // await getCourseCourseVideoList(false) // isConstant

        // reload icon apploval
        await handleCourseReload()

        setLoading(false)
    }

    const handleCourseChapterReload = async () => {
        setLoading(true)

        isSavedRef.current = true
        await getConstantData(false)
        // await getCourseCourseChapter(false) // isConstant

        // reload icon apploval
        await handleCourseReload()

        setLoading(false)
    }

    const handleSendResult = async () => {
        let obj = {
            inspectCourseId: 2,
            inspectCourseCardId: !inspect.inspectionCourseCardId ? null : 2,
            inspectCourseMediaIntroId: !inspect.inspectionCourseMediaIntroId ? null : 2,
            inspectCoursePriceId: !inspect.inspectionCoursePriceId ? null : 2,
            inspectCourseDescriptionId: !inspect.inspectionCourseDescriptionId ? null : 2,
            inspectCourseDocumentId: !inspect.inspectionCourseDocumentId ? null : 2,
            inspectCourseVideoId: !inspect.inspectionCourseVideoId ? null : 2,
        }
        const result = await updateRequestEditCourseInspectFetch(
            user.uid,           // uid
            obj,                // value
            courseId,           // courseId
            1                   // isActive
        )
        if (result.data.isSuccess) {
            Notification("success", "ระบบ", "ส่งการปรับเเก้สำเร็จ")
        } else {
            Notification("error", "ระบบ", "บันทึกการปรับเเต่งไม่สำเร็จ")
        }

        getRequestApi()
    }

    const componentsSwitch = (type) => {
        switch (type) {
            case "menu1":
                return <ManageCard
                    isStepActive={false}
                    isCourseCategoryActive={true}
                    isCourseFormatActive={true}
                    isCourseCertificateActive={true}
                    user={user}
                    course={course}
                    courseInspect={inspect}
                    handleCourseReload={handleCourseReload}
                    switchType="edit-course"
                />
            case "menu2":
                return <ManageMediaIntro
                    isStepActive={false}
                    user={user}
                    courseMediaIntro={courseMediaIntro}
                    courseInspect={inspect}
                    handleCourseMediaIntroReload={handleCourseMediaIntroReload}
                    switchType="edit-course"
                />
            case "menu3":
                return <ManagePrice
                    isStepActive={false}
                    user={user}
                    course={course}
                    courseInspect={inspect}
                    handleCourseReload={handleCourseReload}
                    switchType="edit-course"
                />
            case "menu4":
                return <ManageDescript
                    isStepActive={false}
                    user={user}
                    course={course}
                    courseInspect={inspect}
                    handleCourseReload={handleCourseReload}
                    switchType="edit-course"
                />
            case "menu5":
                return <ManageDocument
                    isStepActive={false}
                    course={course}
                    courseDocument={courseDocument}
                    user={user}
                    courseId={courseId}
                    courseInspect={inspect}
                    handleDocumentReload={handleDocumentReload}
                    switchType="edit-course"
                />
            case "menu6":
                return <ManageVideo
                    authorized={authorized}
                    isStepActive={false}
                    user={user}
                    courseVideoList={courseVideoList}
                    courseChapter={courseChapter}

                    courseId={courseId}
                    coursePrice={course?.coursePrice}
                    isPaymentByVideo={course?.isPaymentByVideo}

                    courseInspect={inspect}

                    handleCourseVideoListReload={handleCourseVideoListReload}
                    handleCourseChapterReload={handleCourseChapterReload}
                    switchType="edit-course"
                />
            default:
                return null
        }
    };

    const reloadComponent = async (key) => {
        // reload
        if (
            key === 'menu1' ||
            key === 'menu3' ||
            key === 'menu4'
        ) {
            await getCourse(false)
        } else if (key === 'menu2') {
            await getCourseMediaIntro(false)
        } else if (key === 'menu5') {
            await getCourseManageDocumentApi(false) // isConstant
        } else if (key === 'menu6') {
            await getCourseCourseVideoList(false) // isConstant
        }
    }

    const getConstantData = async (isConstant) => {
        const resultCourse = await getCourse(isConstant) // isConstant
        setConstantCourse(resultCourse)

        const resultMediaIntro = await getCourseMediaIntro(isConstant) // isConstant
        setConstantMediaIntro(resultMediaIntro)

        const resultDocument = await getCourseManageDocumentApi(isConstant) // isConstant
        setConstantCourseDocument(resultDocument)

        const resultVideo = await getCourseCourseVideoList(isConstant) // isConstant
        setConstantCourseVideoList(resultVideo)

        const resultChapter = await getCourseCourseChapter(isConstant) // isConstant
        setConstantCourseChapter(resultChapter)
    }

    const detectCardChange = () => {
        let isCardDiff = false

        if (
            constantCourse.courseTitleName === resultCard.title &&
            constantCourse.courseSubDescriptionName === resultCard.subDescription &&
            constantCourse.courseImageLocation === resultCard.courseCoverImage &&
            constantCourse.monthlyPayment === resultCard.monthlyPayment &&
            constantCourse.isPaymentByVideo === resultCard.isPaymentByVideo &&
            constantCourse.courseCategoryId === resultCard.courseCategory &&
            constantCourse.courseFormatId === resultCard.courseFormat &&
            constantCourse.courseCertificateCategoryId === resultCard.courseCertificateCategory
        ) {
            isCardDiff = false // not diff
        } else {
            isCardDiff = true // diff
        }

        return isCardDiff
    }

    const detectMediaIntroChange = () => {
        let isMediaIntroDiff = false

        if (
            constantMediaIntro.courseMediaIntroTitleName === resultIntro.title &&
            constantMediaIntro.courseMediaIntroLocation === resultIntro.location
        ) {
            isMediaIntroDiff = false // not diff
        } else {
            isMediaIntroDiff = true // diff
        }

        return isMediaIntroDiff
    }

    const detectPriceChange = () => {
        let isPriceDiff = false

        if (constantCourse.courseDiscount || resultPrice.isDiscount) {
            if (
                constantCourse.coursePrice === resultPrice.price &&
                constantCourse.coursePriceDiscount === resultPrice.priceDiscount &&
                constantCourse.courseDiscount === resultPrice.isDiscount &&
                constantCourse.discountDateEnd === resultPrice.dateTimeEndDiscount
            ) {
                isPriceDiff = false // not diff
            } else {
                isPriceDiff = true // diff
            }
        } else {
            if (constantCourse.coursePrice === resultPrice.price) {
                isPriceDiff = false // not diff
            } else {
                isPriceDiff = true // diff
            }
        }

        return isPriceDiff
    }

    const detectDescriptionChange = () => {
        let isDescriptioDiff = false

        if (constantCourse.courseDescription === resultDescription.description) {
            isDescriptioDiff = false // not diff
        } else {
            isDescriptioDiff = true // diff
        }

        return isDescriptioDiff
    }

    const detectVideoAndChapterChange = () => {
        let isVideoDiff = false
        let isChapterDiff = false

        // detect video ---------------------------------------------------------------------------------------------------
        if (resultVideoList?.length > 0) {
            if (constantCourseVideoList.length !== resultVideoList.length) { // row not equal
                isVideoDiff = true // diff
            } else {
                for (let i = 0; i < constantCourseVideoList.length; i++) { // row equal , next step check diff data
                    if (
                        constantCourseVideoList[i].approvalStatusName === resultVideoList[i].approvalStatusName &&
                        constantCourseVideoList[i].approvalStatusId === resultVideoList[i].approvalStatusId &&
                        constantCourseVideoList[i].courseId === resultVideoList[i].courseId &&
                        constantCourseVideoList[i].courseVideoDescriptionName === resultVideoList[i].courseVideoDescriptionName &&
                        constantCourseVideoList[i].courseVideoDuration === resultVideoList[i].courseVideoDuration &&
                        constantCourseVideoList[i].courseVideoId === resultVideoList[i].courseVideoId &&
                        constantCourseVideoList[i].courseVideoImgLocation === resultVideoList[i].courseVideoImgLocation &&
                        constantCourseVideoList[i].courseVideoLocation === resultVideoList[i].courseVideoLocation &&
                        constantCourseVideoList[i].courseVideoRank === resultVideoList[i].courseVideoRank &&
                        constantCourseVideoList[i].courseVideoTitleName === resultVideoList[i].courseVideoTitleName &&
                        constantCourseVideoList[i].created_at === resultVideoList[i].created_at &&
                        constantCourseVideoList[i].isUpdate === resultVideoList[i].isUpdate &&
                        constantCourseVideoList[i].updated_at === resultVideoList[i].updated_at
                    ) {
                        isVideoDiff = false // not diff
                    } else {
                        isVideoDiff = true // diff
                        break
                    }
                }
            }
        }

        // detect chapter -------------------------------------------------------------------------------------------------
        if (resultChapter?.length > 0) {
            if (constantCourseChapter.length !== resultChapter.length) { // row not equal
                isChapterDiff = true // diff
            } else {
                for (let i = 0; i < constantCourseChapter.length; i++) { // row equal , next step check diff data
                    if (
                        constantCourseChapter[i].approvalStatusName === resultChapter[i].approvalStatusName &&
                        constantCourseChapter[i].approvalStatusId === resultChapter[i].approvalStatusId &&
                        constantCourseChapter[i].chapterCategoryId === resultChapter[i].chapterCategoryId &&
                        constantCourseChapter[i].chapterCategoryName === resultChapter[i].chapterCategoryName &&
                        constantCourseChapter[i].courseChapterId === resultChapter[i].courseChapterId &&
                        constantCourseChapter[i].courseChapterTitleName === resultChapter[i].courseChapterTitleName &&
                        constantCourseChapter[i].courseChapterDescriptionName === resultChapter[i].courseChapterDescriptionName &&
                        constantCourseChapter[i].courseId === resultChapter[i].courseId &&
                        constantCourseChapter[i].isUpdate === resultChapter[i].isUpdate &&
                        constantCourseChapter[i].createdAt === resultChapter[i].createdAt &&
                        constantCourseChapter[i].updatedAt === resultChapter[i].updatedAt
                    ) {
                        isChapterDiff = false // not diff
                    } else {
                        isChapterDiff = true // diff
                        break
                    }
                }
            }
        }
        return (isVideoDiff || isChapterDiff) ? true : false
    }

    const detectDocumentChange = () => {
        if (constantCourseDocument.length !== resultDocument.length) { // row not equal
            return true // diff
        } else {
            let isDiff = false
            for (let i = 0; i < constantCourseDocument.length; i++) { // row equal , next step check diff data
                if (resultDocument?.length > 0) {
                    if (
                        constantCourseDocument[i].approvalStatusName === resultDocument[i].approvalStatusName &&
                        constantCourseDocument[i].approvalStatusId === resultDocument[i].approvalStatusId &&
                        constantCourseDocument[i].coureDocumentationId === resultDocument[i].coureDocumentationId &&
                        constantCourseDocument[i].coureDocumentationLocation === resultDocument[i].coureDocumentationLocation &&
                        constantCourseDocument[i].coureDocumentationName === resultDocument[i].coureDocumentationName &&
                        constantCourseDocument[i].courseId === resultDocument[i].courseId &&
                        constantCourseDocument[i].isUpdate === resultDocument[i].isUpdate
                    ) {
                        isDiff = false // not diff
                    } else {
                        isDiff = true // diff
                        break
                    }
                }
            }
            return isDiff
        }
    }

    const toggleMenuToDefault = (key) => {
        // reload
        reloadComponent(key)

        // update location menu
        setSelectedMenuItem(key)

        setDefaultReducer()

        // set default
        isSavedRef.current = false // not click update edit
    }

    const setDefaultReducer = () => {
        // default
        dispatch(setCourseManageCardStateToSuccess(null))
        dispatch(setCourseManageIntroStateToSuccess(null))
        dispatch(setCourseManagePriceStateToSuccess(null))
        dispatch(setCourseManageDescriptionStateToSuccess(null))
        // dispatch(setCourseManageDocumentStateToSuccess([]))
        // dispatch(setCourseManageVideoListStateToSuccess([]))
    }

    const getInspectApproval = async () => {
        let objStatusInspect = {
            courseId,
            uid: user.uid,
            isActive: 1,
        }
        const resultStatusInspect = await getStatusInspectRequestEditCourseByIdFetch(objStatusInspect)
        // console.log("getStatusInspectRequestEditCourseByIdFetch : ", resultStatusInspect)

        let objInspect = {
            courseId,
            uid: user.uid,
            inspectStatus: resultStatusInspect
        }
        const result = await getInspectRequestEditCourseByIdFetch(objInspect, language)
        // console.log("getInspectRequestEditCourseByIdFetch : ", result)
        setInspect(result)

        // switch button send result adjust
        if (result) {
            if (
                (result.course.inspectionCourseCardAdjustId === 1 || !result.course.inspectionCourseCardAdjustId || !result.inspectionCourseCardId) &&
                (result.course.inspectionCoursePriceAdjustId === 1 || !result.course.inspectionCoursePriceAdjustId || !result.inspectionCoursePriceId) &&
                (result.course.inspectionCourseDescriptionAdjustId === 1 || !result.course.inspectionCourseDescriptionAdjustId || !result.inspectionCourseDescriptionId) &&
                (result.courseMediaIntro.inspectionCourseAdjustIdSummary === 1 || !result.courseMediaIntro.inspectionCourseAdjustIdSummary || !result.inspectionCourseMediaIntroId) &&
                (result.courseDocument.inspectionCourseAdjustIdSummary === 1 || !result.courseDocument.inspectionCourseAdjustIdSummary || !result.inspectionCourseDocumentId) &&
                (result.courseVideo.inspectionCourseAdjustIdSummary === 1 || !result.courseVideo.inspectionCourseAdjustIdSummary || !result.inspectionCourseVideoId) &&
                (result.courseChapter.inspectionCourseAdjustIdSummary === 1 || !result.courseChapter.inspectionCourseAdjustIdSummary || !result.inspectionCourseChapterId)
            ) {
                setNotIsOpenSendAdjust(false)
            } else {
                setNotIsOpenSendAdjust(true)
            }
        }
    }

    const getRequestApi = async () => {
        setLoading(true)

        await getInspectApproval()
        // await getCourse(false)
        // await getCourseMediaIntro(false)
        await getConstantData(false)
        await getWaitApproval()

        setLoading(false)
    }

    useMemo(() => {
        // reducer default
        setDefaultReducer()
    }, [])

    useEffect(() => {
        getRequestApi()
    }, [])

    useEffect(() => {

    }, [waitApproval])

    return (
        <div style={{ backgroundColor: '#ECECEC' }}>
            <Header firebase={firebase} user={user} />

            <article>
                {!loading ?
                    <div style={width >= 1500 ? { paddingLeft: '5%', paddingRight: '5%' } : ((width > 912 && width < 1500) ? { paddingLeft: '5%', paddingRight: '5%' } : { paddingLeft: '0%', paddingRight: '0%' })}>
                        <PageHeader title={<span style={{ fontSize: 18 }}>เเก้ไขคอร์ส</span>} style={{ backgroundColor: 'white', borderBottom: '1px solid #ECECEC', paddingTop: 6, paddingBottom: 6 }} />
                        <div style={{ backgroundColor: 'white', width: '100%', height: '100%' }}>
                            <Row>
                                <Col xs={8} sm={7} md={6} lg={5}>
                                    <Menu
                                        defaultSelectedKeys={['1']}
                                        defaultOpenKeys={['menu1']}
                                        mode="inline"
                                        selectedKeys={selectedMenuItem}
                                        onClick={async (e) => {

                                            let isOpenReminder = false;
                                            if (selectedMenuItem === 'menu1') {
                                                isOpenReminder = resultCard ? detectCardChange() : false;
                                            } else if (selectedMenuItem === 'menu2') {
                                                isOpenReminder = resultIntro ? detectMediaIntroChange() : false;
                                            } else if (selectedMenuItem === 'menu3') {
                                                isOpenReminder = resultPrice ? detectPriceChange() : false;
                                            } else if (selectedMenuItem === 'menu4') {
                                                isOpenReminder = resultDescription ? detectDescriptionChange() : false;
                                            } else if (selectedMenuItem === 'menu5') {
                                                isOpenReminder = resultDocument ? detectDocumentChange() : true;
                                            } else if (selectedMenuItem === 'menu6') {
                                                isOpenReminder = (resultVideoList || resultChapter) ? detectVideoAndChapterChange() : true;
                                            }

                                            // console.log("isOpenReminder : ", isOpenReminder, selectedMenuItem, resultPrice);

                                            // if have change and saved not show reminder
                                            if (isSavedRef.current) {
                                                isOpenReminder = false;
                                            }

                                            if (isOpenReminder) {
                                                if (selectedMenuItem !== e.key) {
                                                    confirm({
                                                        icon: <ExclamationCircleOutlined />,
                                                        content: "ออกจากเมนูนี้ โดยไม่บันทึกการเปลี่ยนเเปลงหรือไม่?",
                                                        okText: <span style={{ width: 50 }}>ใช่</span>,
                                                        cancelText: <span style={{ width: 50 }}>ไม่ใช่</span>,
                                                        async onOk() {
                                                            // set default menu or reload data when toggle menu
                                                            toggleMenuToDefault(e.key);
                                                        },
                                                        onCancel() { }
                                                    });
                                                }
                                            } else {
                                                // set default menu or reload data when toggle menu
                                                toggleMenuToDefault(e.key);
                                            }
                                        }}
                                        style={{ height: '100%' }}
                                    >
                                        <Menu.Item key="menu1" style={{ height: 50, borderBottom: '1px solid #EEEEEE', paddingBottom: 0, paddingTop: 0, marginBottom: 0, marginTop: 0, paddingLeft: '6%' }}>
                                            <div style={{ display: "flex", alignContent: "center" }}>
                                                <div style={{ display: "grid", alignContent: "center", width: 50 }}>
                                                    {!inspect ?
                                                        <>
                                                            {waitApproval.resultCourseComponent[0].isUpdateCourseCard ?
                                                                <MdChangeCircle type={1} />
                                                                :
                                                                <AiFillCheckCircle style={{ height: 23, width: 23, color: 'green' }} />
                                                            }
                                                        </>
                                                        :
                                                        <>
                                                            {inspect.inspectionCourseId === 2 ?
                                                                <>
                                                                    {waitApproval.resultCourseComponent[0].isUpdateCourseCard ?
                                                                        <MdChangeCircle type={1} />
                                                                        :
                                                                        <AiFillCheckCircle style={{ height: 23, width: 23, color: 'green' }} />
                                                                    }
                                                                </>
                                                                :
                                                                <> {/* inspectionCourseId is 1, 3 */}
                                                                    {!inspect.course.inspectionCourseCardApprovalId ?
                                                                        <>
                                                                            {waitApproval.resultCourseComponent[0].isUpdateCourseCard ?
                                                                                <MdChangeCircle type={1} />
                                                                                :
                                                                                <AiFillCheckCircle style={{ height: 23, width: 23, color: 'green' }} />
                                                                            }
                                                                        </>
                                                                        :
                                                                        <AiFillCloseCircle style={{ height: 23, width: 23, color: 'red' }} />
                                                                    }
                                                                </>
                                                            }
                                                        </>
                                                    }
                                                </div>

                                                <div style={{ display: "grid", alignItems: "center" }}>
                                                    <label style={{ cursor: 'pointer', fontWeight: "bold", fontSize: (width > 576 ? 14 : 12), height: (inspect ? 20 : 'auto') }}>หน้าปก</label>
                                                    {inspect ? <label style={{ cursor: 'pointer', fontSize: (width > 576 ? 14 : 12) }}>{inspect.inspectionCourseCardName && inspect.inspectionCourseId === 3 ? inspect.course.inspectionCourseCardAdjustName : []}</label> : []}
                                                </div>
                                            </div>
                                        </Menu.Item>

                                        <Menu.Item key="menu2" style={{ height: 50, borderBottom: '1px solid #EEEEEE', paddingBottom: 0, paddingTop: 0, marginBottom: 0, marginTop: 0, paddingLeft: '6%' }}>
                                            <div style={{ display: "flex", alignContent: "center" }}>
                                                <div style={{ display: "grid", alignContent: "center", width: 50 }}>
                                                    {!inspect ?
                                                        <>
                                                            {waitApproval.resultCourseMediaIntro.length > 0 ?
                                                                <MdChangeCircle type={1} />
                                                                :
                                                                <AiFillCheckCircle style={{ height: 23, width: 23, color: 'green' }} />
                                                            }
                                                        </>
                                                        :
                                                        <>
                                                            {inspect.inspectionCourseId === 2 ?
                                                                <>
                                                                    {waitApproval.resultCourseMediaIntro.length > 0 ?
                                                                        <MdChangeCircle type={1} />
                                                                        :
                                                                        <AiFillCheckCircle style={{ height: 23, width: 23, color: 'green' }} />
                                                                    }
                                                                </>
                                                                :
                                                                <> {/* inspectionCourseId is 1, 3 */}
                                                                    {!inspect.courseMediaIntro.inspectionCourseApprovalIdSummary ?
                                                                        <>

                                                                            {waitApproval.resultCourseMediaIntro.length > 0 ?
                                                                                <MdChangeCircle type={1} />
                                                                                :
                                                                                <AiFillCheckCircle style={{ height: 23, width: 23, color: 'green' }} />
                                                                            }
                                                                        </>
                                                                        :
                                                                        <AiFillCloseCircle style={{ height: 23, width: 23, color: 'red' }} />
                                                                    }
                                                                </>
                                                            }
                                                        </>
                                                    }
                                                </div>

                                                <div style={{ display: "grid", alignItems: "center" }}>
                                                    <label style={{ cursor: 'pointer', fontWeight: "bold", fontSize: (width > 576 ? 14 : 12), height: (inspect ? 20 : 'auto') }}>สื่อนำเสนอ</label>
                                                    {inspect ? <label style={{ cursor: 'pointer', fontSize: (width > 576 ? 14 : 12) }}>{inspect.inspectionCourseMediaIntroName && inspect.inspectionCourseId === 3 ? inspect.courseMediaIntro.inspectionCourseAdjustNameSummary : []}  </label> : []}
                                                </div>
                                            </div>
                                        </Menu.Item>

                                        <Menu.Item key="menu3" style={{ height: 50, borderBottom: '1px solid #EEEEEE', paddingBottom: 0, paddingTop: 0, marginBottom: 0, marginTop: 0, paddingLeft: '6%' }}>
                                            <div style={{ display: "flex", alignContent: "center" }}>
                                                <div style={{ display: "grid", alignContent: "center", width: 50 }}>
                                                    {!inspect ?
                                                        <>
                                                            {waitApproval.resultCourseComponent[0].isUpdateCoursePrice ?
                                                                <MdChangeCircle type={1} />
                                                                :
                                                                <AiFillCheckCircle style={{ height: 23, width: 23, color: 'green' }} />
                                                            }
                                                        </>
                                                        :
                                                        <>
                                                            {inspect.inspectionCourseId === 2 ?
                                                                <>
                                                                    {waitApproval.resultCourseComponent[0].isUpdateCoursePrice ?
                                                                        <MdChangeCircle type={1} />
                                                                        :
                                                                        <AiFillCheckCircle style={{ height: 23, width: 23, color: 'green' }} />
                                                                    }
                                                                </>
                                                                :
                                                                <> {/* inspectionCourseId is 1, 3 */}
                                                                    {!inspect.course.inspectionCoursePriceApprovalId ?
                                                                        <>
                                                                            {waitApproval.resultCourseComponent[0].isUpdateCoursePrice ?
                                                                                <MdChangeCircle type={1} />
                                                                                :
                                                                                <AiFillCheckCircle style={{ height: 23, width: 23, color: 'green' }} />
                                                                            }
                                                                        </>
                                                                        :
                                                                        <AiFillCloseCircle style={{ height: 23, width: 23, color: 'red' }} />
                                                                    }
                                                                </>
                                                            }
                                                        </>
                                                    }
                                                </div>

                                                <div style={{ display: "grid", alignItems: "center" }}>
                                                    <label style={{ cursor: 'pointer', fontWeight: "bold", fontSize: (width > 576 ? 14 : 12), height: (inspect ? 20 : 'auto') }}>ราคา</label>
                                                    {inspect ? <label style={{ cursor: 'pointer', fontSize: (width > 576 ? 14 : 12) }}>{inspect.inspectionCoursePriceName && inspect.inspectionCourseId === 3 ? inspect.course.inspectionCoursePriceAdjustName : []}</label> : []}
                                                </div>
                                            </div>
                                        </Menu.Item>

                                        <Menu.Item key="menu4" style={{ height: 50, borderBottom: '1px solid #EEEEEE', paddingBottom: 0, paddingTop: 0, marginBottom: 0, marginTop: 0, paddingLeft: '6%' }}>
                                            <div style={{ display: "flex", alignContent: "center" }}>
                                                <div style={{ display: "grid", alignContent: "center", width: 50 }}>
                                                    {!inspect ?
                                                        <>
                                                            {waitApproval.resultCourseComponent[0].isUpdateCourseDescription ?
                                                                <MdChangeCircle type={1} />
                                                                :
                                                                <AiFillCheckCircle style={{ height: 23, width: 23, color: 'green' }} />
                                                            }
                                                        </>
                                                        :
                                                        <>
                                                            {inspect.inspectionCourseId === 2 ?
                                                                <>
                                                                    {waitApproval.resultCourseComponent[0].isUpdateCourseDescription ?
                                                                        <MdChangeCircle type={1} />
                                                                        :
                                                                        <AiFillCheckCircle style={{ height: 23, width: 23, color: 'green' }} />
                                                                    }
                                                                </>
                                                                :
                                                                <> {/* inspectionCourseId is 1, 3 */}
                                                                    {!inspect.course.inspectionCourseDescriptionApprovalId ?
                                                                        <>
                                                                            {waitApproval.resultCourseComponent[0].isUpdateCourseDescription ?
                                                                                <MdChangeCircle type={1} />
                                                                                :
                                                                                <AiFillCheckCircle style={{ height: 23, width: 23, color: 'green' }} />
                                                                            }
                                                                        </>
                                                                        :
                                                                        <AiFillCloseCircle style={{ height: 23, width: 23, color: 'red' }} />
                                                                    }
                                                                </>
                                                            }
                                                        </>
                                                    }
                                                </div>

                                                <div style={{ display: "grid", alignItems: "center" }}>
                                                    <label style={{ cursor: 'pointer', fontWeight: "bold", fontSize: (width > 576 ? 14 : 12), height: (inspect ? 20 : 'auto') }}>รายละเอียด</label>
                                                    {inspect ? <span style={{ cursor: 'pointer', fontSize: (width > 576 ? 14 : 12) }}>{inspect.inspectionCourseDescriptionName && inspect.inspectionCourseId === 3 ? inspect.course.inspectionCourseDescriptionAdjustName : []}</span> : []}
                                                </div>
                                            </div>
                                        </Menu.Item>

                                        <Menu.Item key="menu5" style={{ height: 50, borderBottom: '1px solid #EEEEEE', paddingBottom: 0, paddingTop: 0, marginBottom: 0, marginTop: 0, paddingLeft: '6%' }}>
                                            <div style={{ display: "flex", alignContent: "center" }}>
                                                <div style={{ display: "grid", alignContent: "center", width: 50 }}>
                                                    {!inspect ?
                                                        <>
                                                            {waitApproval.resultCourseDocument.length > 0 ?
                                                                <MdChangeCircle type={1} />
                                                                :
                                                                <AiFillCheckCircle style={{ height: 23, width: 23, color: 'green' }} />
                                                            }
                                                        </>
                                                        :
                                                        <>
                                                            {inspect.inspectionCourseId === 2 ?
                                                                <>
                                                                    {waitApproval.resultCourseDocument.length > 0 ?
                                                                        <MdChangeCircle type={1} />
                                                                        :
                                                                        <AiFillCheckCircle style={{ height: 23, width: 23, color: 'green' }} />
                                                                    }
                                                                </>
                                                                :
                                                                <> {/* inspectionCourseId is 1, 3 */}
                                                                    {!inspect.courseDocument.inspectionCourseApprovalIdSummary ?
                                                                        <>
                                                                            {waitApproval.resultCourseDocument.length > 0 ?
                                                                                <MdChangeCircle type={1} />
                                                                                :
                                                                                <AiFillCheckCircle style={{ height: 23, width: 23, color: 'green' }} />
                                                                            }
                                                                        </>
                                                                        :
                                                                        <AiFillCloseCircle style={{ height: 23, width: 23, color: 'red' }} />
                                                                    }
                                                                </>
                                                            }
                                                        </>
                                                    }
                                                </div>

                                                <div style={{ display: "grid", alignItems: "center" }}>
                                                    <label style={{ cursor: 'pointer', fontWeight: "bold", fontSize: (width > 576 ? 14 : 12), height: (inspect ? 20 : 'auto') }}>เอกสารเพิ่มเติม</label>
                                                    {inspect ? <label style={{ cursor: 'pointer', fontSize: (width > 576 ? 14 : 12) }}>{inspect.inspectionCourseDocumentName && inspect.inspectionCourseId === 3 ? inspect.courseDocument.inspectionCourseAdjustNameSummary : []}</label> : []}
                                                </div>
                                            </div>
                                        </Menu.Item>

                                        <Menu.Item key="menu6" style={{ height: 50, borderBottom: '1px solid #EEEEEE', paddingBottom: 0, paddingTop: 0, marginBottom: 0, marginTop: 0, paddingLeft: '6%' }}>
                                            <div style={{ display: "flex", alignContent: "center" }}>
                                                <div style={{ display: "grid", alignContent: "center", width: 50 }}>
                                                    {!inspect ?
                                                        <>
                                                            {(waitApproval.resultCourseChapter.length > 0 || waitApproval.resultCourseVideo.length > 0) ?
                                                                <MdChangeCircle type={1} />
                                                                :
                                                                <AiFillCheckCircle style={{ height: 23, width: 23, color: 'green' }} />
                                                            }
                                                        </>
                                                        :
                                                        <>
                                                            {inspect.inspectionCourseId === 2 ?
                                                                <>
                                                                    {(waitApproval.resultCourseChapter.length > 0 || waitApproval.resultCourseVideo.length > 0) ?
                                                                        <MdChangeCircle type={1} />
                                                                        :
                                                                        <AiFillCheckCircle style={{ height: 23, width: 23, color: 'green' }} />
                                                                    }
                                                                </>
                                                                :
                                                                <> {/* inspectionCourseId is 1, 3 */}
                                                                    {(!inspect.courseChapter.inspectionCourseApprovalIdSummary || !inspect.courseVideo.inspectionCourseApprovalIdSummary) ?
                                                                        <>
                                                                            {(waitApproval.resultCourseChapter.length > 0 || waitApproval.resultCourseVideo.length > 0) ?
                                                                                <MdChangeCircle type={1} />
                                                                                :
                                                                                <AiFillCheckCircle style={{ height: 23, width: 23, color: 'green' }} />
                                                                            }
                                                                        </>
                                                                        :
                                                                        <AiFillCloseCircle style={{ height: 23, width: 23, color: 'red' }} />
                                                                    }
                                                                </>
                                                            }
                                                        </>
                                                    }
                                                </div>

                                                <div style={{ display: "grid", alignItems: "center" }}>
                                                    <label style={{ cursor: 'pointer', fontWeight: "bold", fontSize: (width > 576 ? 14 : 12), height: (inspect ? 20 : 'auto') }}>วิดีโอ</label>
                                                    {inspect ? <label style={{ cursor: 'pointer', fontSize: (width > 576 ? 14 : 12) }}>{inspect.inspectionCourseVideoName && inspect.inspectionCourseId === 3 ? inspect.courseVideo.inspectionCourseAdjustNameSummary : []}</label> : []}
                                                </div>
                                            </div>
                                        </Menu.Item>
                                    </Menu>
                                </Col>

                                <Col xs={16} sm={17} md={18} lg={19}>
                                    <Row>
                                        {inspect && (inspect.inspectionCourseId === 1 || inspect.inspectionCourseId === 3) ?
                                            <>
                                                <Col xs={24} sm={24} md={14} lg={19} style={{ padding: (width > 576 ? 15 : 10), marginTop: 2 }}>
                                                    <Row>
                                                        <Col xs={11} sm={9} md={8} lg={6}>
                                                            <span style={{ float: "right" }}>จำนวนถูกตีกลับ : </span>
                                                        </Col>
                                                        <Col xs={13} sm={15} md={16} lg={18}>
                                                            <span style={{ paddingLeft: 3 }}>{inspect ? (inspect.amountEdit + "  ครั้ง") : "-"}</span>
                                                        </Col>
                                                    </Row>
                                                </Col>

                                                <Col xs={24} sm={24} md={10} lg={5} style={width > 768 ? { padding: 20 } : { paddingLeft: 20, paddingTop: 5, paddingRight: 20, paddingBottom: 14 }}>
                                                    <Row gutter={[10, 10]}>
                                                        <Col span={24}>
                                                            <Popconfirm
                                                                title="คุณยืนยันส่งผลการปรับเเก้หรือไม่ ?"
                                                                okText={<span style={{ width: 50 }}>ใช่</span>}
                                                                onConfirm={() => handleSendResult()}
                                                                cancelText={<span style={{ width: 50 }}>ไม่ใช่</span>}
                                                                disabled={isNotOpenSendAdjust ? true : (inspect.inspectionCourseId === 3 ? false : true)}
                                                            >
                                                                <Button
                                                                    loading={loading}
                                                                    disabled={isNotOpenSendAdjust ? true : (inspect.inspectionCourseId === 3 ? false : true)}
                                                                    type="primary"
                                                                    style={{ width: 130, float: "right" }}
                                                                >
                                                                    {!loading ?
                                                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                                            <Icon icon="bi:send" style={{ color: isNotOpenSendAdjust ? "#C3C3C3" : (inspect.inspectionCourseId === 3 ? "white" : "#C3C3C3") }} />
                                                                            <label style={{ paddingLeft: 10, cursor: "pointer" }}>ส่งการปรับเเก้</label>
                                                                        </div>
                                                                        : []
                                                                    }
                                                                </Button>
                                                            </Popconfirm>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Divider style={{ marginBottom: 0, marginTop: 0 }} />
                                            </>
                                            : []}

                                        <Col span={24}>
                                            {componentsSwitch(selectedMenuItem)}
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                    </div>
                    :
                    <LoadingPage loading={loading} />
                }
            </article>

            <Footer />
        </div>
    )
}