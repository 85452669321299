import { server } from "../../../constants"
import { httpClient } from "../../../utils/HttpClient"

const getRankCourseVideoListFetch = async (uid, isActive, courseId) => { // Done
    try {
        const result = await httpClient.post(server.COURSE_MANAGE_GET_RANK_COURSE_VIDEO_BY_ID_URL, { uid, isActive, courseId })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getWaitApprovalFetch = async (uid, courseId) => { // Done
    try {
        const result = await httpClient.post(server.COURSE_MANAGE_GET_WAIT_APPROVAL_BY_ID_URL, { uid, courseId, isActive: 1 })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getApprovalStatusFetch = async (language) => { // Done
    try {
        const result = await httpClient.post(server.GET_APPROVAL_STATUS_URL, { language })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getCourseManageFetch = async (uid, courseId) => { // Done
    try {
        const result = await httpClient.post(server.COURSE_MANAGE_GET_COURSE_BY_ID_URL, { uid, courseId, isActive: 1, })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getCourseManageMediaIntroFetch = async (uid, courseId) => { // Done
    try {
        const result = await httpClient.post(server.COURSE_MANAGE_GET_COURSE_MEDIA_INTRO_BY_ID_URL, { uid, courseId, isActive: 1 })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getCourseManageDocumentApiFetch = async (value) => { // Done
    try {
        const result = await httpClient.post(server.COURSE_MANAGE_GET_COURSE_DOCUMENT_BY_ID_URL, { value })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getCourseManageVideoListFetch = async (value) => { // Done
    try {
        const result = await httpClient.post(server.COURSE_MANAGE_GET_COURSE_VIDEO_BY_ID_URL, { value })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getCourseManageSearchFetch = async (uid, text, paginate) => { // Done 
    try {
        const result = await httpClient.post(server.COURSE_MANAGE_SEARCH_SUGGEST_BY_UID_URL, { text, paginate, uid })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getCourseManageChapterFetch = async (value) => { // Done
    try {
        const result = await httpClient.post(server.COURSE_MANAGE_GET_COURSE_CHAPTER_BY_ID_URL, { value })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getRankCourseChapterListFetch = async (uid, isActive, courseId) => { // Done
    try {
        const result = await httpClient.post(server.COURSE_MANAGE_GET_RANK_COURSE_CHAPTER_BY_ID_URL, { uid, isActive, courseId })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const updateCourseFetch = async (uid, value, isActive, courseId, courseComponent) => { // Done
    try {
        const result = await httpClient.post(server.COURSE_MANAGE_UPDATE_COURSE_BY_ID_URL, { uid, value, isActive, courseId, courseComponent })
        return result
    } catch (err) { // status 404
        return null
    }
}

const updateCourseMediaIntroFetch = async (uid, value, isActive, courseId, courseComponent) => { // Done
    try {
        const result = await httpClient.post(server.COURSE_MANAGE_UPDATE_COURSE_MEDIA_INTRO_BY_ID_URL, { uid, value, isActive, courseId, courseComponent })
        return result
    } catch (err) { // status 404
        return null
    }
}

const updateCourseDocumentListFetch = async (uid, value, isActive, courseId, courseComponent) => { // Done
    try {
        const result = await httpClient.post(server.COURSE_MANAGE_UPDATE_COURSE_DOCUMENT_BY_ID_URL, { uid, value, isActive, courseId, courseComponent })
        return result
    } catch (err) { // status 404
        return null
    }
}

const updateCourseVideoListFetch = async (uid, value, isActive, courseId, courseComponent) => { // Done
    try {
        const result = await httpClient.post(server.COURSE_MANAGE_UPDATE_COURSE_VIDEO_BY_ID_URL, { uid, value, isActive, courseId, courseComponent })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const updateCourseIsUpdateFetch = async (uid, isActive, courseId, component) => { // Done
    try {
        const result = await httpClient.post(server.COURSE_MANAGE_UPDATE_COURSE_IS_UPDATE_BY_ID_URL, { uid, isActive, courseId, component })
        return result
    } catch (err) { // status 404
        return null
    }
}

const updateRankCourseVideoListFetch = async (uid, value, isActive, courseId) => { // Done
    try {
        const result = await httpClient.post(server.COURSE_MANAGE_UPDATE_RANK_COURSE_VIDEO_BY_ID_URL, { uid, value, isActive, courseId })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const updateRequestOpenCourseCardAdjustFetch = async (uid, value, inspectCourseAdjustId, isActive, courseId) => { // [/] ,  
    try {
        const result = await httpClient.post(server.UPDATE_REUEST_OPEN_COURSE_CARD_ADJUST_BY_ID_URL, { uid, value, inspectCourseAdjustId, isActive, courseId })
        return result
    } catch (err) { // status 404
        return null
    }
}

const updateRequestOpenCourseMediaIntroAdjustFetch = async (uid, value, inspectCourseAdjustId, isActive, courseId) => { // [/] ,  
    try {
        const result = await httpClient.post(server.UPDATE_REUEST_OPEN_COURSE_MEDIA_INTRO_ADJUST_BY_ID_URL, { uid, value, inspectCourseAdjustId, isActive, courseId })
        return result
    } catch (err) { // status 404
        return null
    }
}

const updateRequestOpenCoursePriceAdjustFetch = async (uid, value, inspectCourseAdjustId, isActive, courseId) => { // [/] ,  
    try {
        const result = await httpClient.post(server.UPDATE_REUEST_OPEN_COURSE_PRICE_ADJUST_BY_ID_URL, { uid, value, inspectCourseAdjustId, isActive, courseId })
        return result
    } catch (err) { // status 404
        return null
    }
}

const updateRequestOpenCourseDescriptionAdjustFetch = async (uid, value, inspectCourseAdjustId, isActive, courseId) => { // [/] ,  
    try {
        const result = await httpClient.post(server.UPDATE_REUEST_OPEN_COURSE_DESCRIPTION_ADJUST_BY_ID_URL, { uid, value, inspectCourseAdjustId, isActive, courseId })
        return result
    } catch (err) { // status 404
        return null
    }
}

const updateRequestOpenCourseDocumentAdjustFetch = async (uid, value, inspectCourseAdjustId, isActive, courseId) => { // [/] ,  
    try {
        const result = await httpClient.post(server.UPDATE_REUEST_OPEN_COURSE_DOCUMENT_ADJUST_BY_ID_URL, { uid, value, inspectCourseAdjustId, isActive, courseId })
        return result
    } catch (err) { // status 404
        return null
    }
}

const updateRequestOpenCourseVideoAdjustFetch = async (uid, value, inspectCourseAdjustId, isActive, courseId) => { // [/] ,  
    try {
        const result = await httpClient.post(server.UPDATE_REUEST_OPEN_COURSE_VIDEO_ADJUST_BY_ID_URL, { uid, value, inspectCourseAdjustId, isActive, courseId })
        return result
    } catch (err) { // status 404
        return null
    }
}

const updateRequestOpenCourseInspectFetch = async (uid, value, courseId, isActive) => { // [/] ,  
    try {
        const result = await httpClient.post(server.UPDATE_REUEST_OPEN_COURSE_INSPECT_BY_ID_URL, { uid, value, courseId, isActive })
        return result
    } catch (err) { // status 404
        return null
    }
}

const updateRequestEditCourseInspectFetch = async (uid, value, courseId, isActive) => { // [/] ,  
    try {
        const result = await httpClient.post(server.UPDATE_REUEST_EDIT_COURSE_INSPECT_BY_ID_URL, { uid, value, courseId, isActive })
        return result
    } catch (err) { // status 404
        return null
    }
}

const updateCourseChapterListFetch = async (uid, value, isActive, courseId, courseComponent) => { // Done
    try {
        const result = await httpClient.post(server.COURSE_MANAGE_UPDATE_COURSE_CHAPTER_BY_ID_URL, { uid, value, isActive, courseId, courseComponent })
        return result
    } catch (err) { // status 404
        return null
    }
}

const updateRankCourseChapterListFetch = async (uid, value, isActive, courseId) => { // Done
    try {
        const result = await httpClient.post(server.COURSE_MANAGE_UPDATE_RANK_COURSE_CHAPTER_BY_ID_URL, { uid, value, isActive, courseId })
        return result
    } catch (err) { // status 404
        return null
    }
}

const insertCourseDocumentFetch = async (uid, value, isActive, courseId, courseComponent) => { // Done
    try {
        const result = await httpClient.post(server.COURSE_MANAGE_ADD_COURSE_MANAGE_URL, { uid, value, isActive, courseId, courseComponent })
        return result
    } catch (err) { // status 404
        return null
    }
}

const insertCourseVideoListFetch = async (uid, value, isActive, courseId, courseComponent) => { // Done
    try {
        const result = await httpClient.post(server.COURSE_MANAGE_ADD_COURSE_VIDEO_URL, { uid, value, isActive, courseId, courseComponent })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const insertNewCourseFetch = async (value) => { // Done 
    try {
        const result = await httpClient.post(server.INSERT_NEW_COURSE_MANAGE_COURSE_URL, value)
        return result
    } catch (err) { // status 404
        return null
    }
}

const insertNewCourseMediaIntroFetch = async (uid, value) => { // Done 
    try {
        const result = await httpClient.post(server.INSERT_NEW_COURSE_MANAGE_MEDIA_INTRO_URL, { uid, value })
        return result
    } catch (err) { // status 404
        return null
    }
}

const insertNewCourseDocumentFetch = async (uid, value, courseId) => { // Done 
    try {
        const result = await httpClient.post(server.INSERT_NEW_COURSE_MANAGE_DOCUMENT_URL, { uid, value, courseId })
        return result
    } catch (err) { // status 404
        return null
    }
}

const insertNewCourseVideoFetch = async (uid, value, courseId) => { // Done 
    try {
        const result = await httpClient.post(server.INSERT_NEW_COURSE_MANAGE_VIDEO_LIST_URL, { uid, value, courseId })
        return result
    } catch (err) { // status 404
        return null
    }
}

const insertNewCourseChapterFetch = async (value, courseId) => { // Done 
    try {
        const result = await httpClient.post(server.INSERT_NEW_COURSE_MANAGE_CHAPTER_URL, { value, courseId })
        return result
    } catch (err) { // status 404
        return null
    }
}

const insertInspectRequestOpenCourseFetch = async (value) => { // Done 
    try {
        const result = await httpClient.post(server.INSERT_INSPECT_REQUEST_OPEN_COURSE_URL, { value })
        return result
    } catch (err) { // status 404
        return null
    }
}

const insertCourseChapterListFetch = async (uid, value, isActive, courseId, courseComponent) => { // Done
    try {
        const result = await httpClient.post(server.COURSE_MANAGE_ADD_COURSE_CHAPTER_URL, { uid, value, isActive, courseId, courseComponent })
        return result
    } catch (err) { // status 404
        return null
    }
}

const deleteCourseDocumentFetch = async (id) => { // Done
    try {
        const result = await httpClient.delete(`${server.COURSE_MANAGE_DELETE_COURSE_DOCUMENT_URL}/${id}`)
        return result
    } catch (err) { // status 404
        return null
    }
}

const deleteCourseDocumentThroughSpecialFetch = async (value) => {
    try {
        const result = await httpClient.post(`${server.DELETE_COURSE_DOCUMENT_THROUGH_SPECIAL_URL}`, { value })
        return result
    } catch (err) { // status 404
        return null
    }
}

const deleteCourseOverdueByIdFetch = async (courseId) => { // [courseId] , Done 
    try {
        const result = await httpClient.delete(`${server.DELETE_COURSE_OVERDUE_BY_ID_URL}/${courseId}`)
        return result
    } catch (err) { // status 404
        return null
    }
}

export {
    // get
    getRankCourseVideoListFetch,
    getWaitApprovalFetch,
    getApprovalStatusFetch,
    getCourseManageFetch,
    getCourseManageMediaIntroFetch,
    getCourseManageDocumentApiFetch,
    getCourseManageVideoListFetch,
    getCourseManageSearchFetch,
    getCourseManageChapterFetch,
    getRankCourseChapterListFetch,
    
    // delete
    deleteCourseDocumentFetch,
    deleteCourseDocumentThroughSpecialFetch,
    deleteCourseOverdueByIdFetch,

    // insert
    insertCourseDocumentFetch,
    insertCourseVideoListFetch,
    insertNewCourseFetch,
    insertNewCourseMediaIntroFetch,
    insertNewCourseDocumentFetch,
    insertNewCourseVideoFetch,
    insertNewCourseChapterFetch,
    insertInspectRequestOpenCourseFetch,
    insertCourseChapterListFetch,

    // update
    updateCourseFetch,
    updateCourseMediaIntroFetch,
    updateCourseDocumentListFetch,
    updateCourseVideoListFetch,
    updateCourseIsUpdateFetch,
    updateRankCourseVideoListFetch,
    updateRequestOpenCourseCardAdjustFetch,
    updateRequestOpenCourseMediaIntroAdjustFetch,
    updateRequestOpenCoursePriceAdjustFetch,
    updateRequestOpenCourseDescriptionAdjustFetch,
    updateRequestOpenCourseDocumentAdjustFetch,
    updateRequestOpenCourseVideoAdjustFetch,
    updateRequestOpenCourseInspectFetch,
    updateRequestEditCourseInspectFetch,
    updateCourseChapterListFetch,
    updateRankCourseChapterListFetch
}