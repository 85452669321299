import { server } from "../../../../../constants"
import { httpClient } from "../../../../../utils/HttpClient"

const getEarnedSalesRevenueManageAllFetch = async (value) => {
    try {
        const result = await httpClient.post(server.GET_EARNED_SALES_REVENUE_MANAGE_ALL_URL, { value })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const updateEarnedSalesRevenueManageByIdFetch = async (value) => {
    try {
        const result = await httpClient.put(server.UPDATE_EARNED_SALES_REVENUE_MANAGE_BY_ID_URL, { value })
        if (result.data.isSuccess) {
            return result.data
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

export {
    // get
    getEarnedSalesRevenueManageAllFetch,

    // update
    updateEarnedSalesRevenueManageByIdFetch,
}
