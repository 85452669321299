import {
    HTTP_REGISTER_INFORMATION_FETCHING,
    HTTP_REGISTER_INFORMATION_SUCCESS,
    HTTP_REGISTER_INFORMATION_FAILED
} from "../constants"

const initialState = {
    resultRegisterInformation: {
        birthday: undefined,
        firstname: undefined,
        gender: undefined,
        lastname: undefined,
        
        username: undefined,
        email: undefined,
        phone: undefined,
        businessOperation: undefined,
        companyNameTh: undefined,
        companyNameEng: undefined
    },
    isFetching: false,
    isError: false
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, { type, payload }) => {
    switch (type) {

        case HTTP_REGISTER_INFORMATION_FETCHING:
            return { ...state, resultRegisterInformation: null, isFetching: true, isError: false }

        case HTTP_REGISTER_INFORMATION_SUCCESS:
            return { ...state, resultRegisterInformation: payload, isFetching: false, isError: false }

        case HTTP_REGISTER_INFORMATION_FAILED:
            return { ...state, resultRegisterInformation: null, isFetching: false, isError: true }

        default:
            return state
    }
}
