/* eslint-disable react-hooks/exhaustive-deps */

import { useState, useEffect, useRef } from "react"
import {
    Select,
    Form,
    Modal,
    Button,
    Card,
    Row,
    Col,
    Radio,
    Input
} from "antd"
import { Icon } from '@iconify/react'
import moment from "moment"
import { getCourseCategoryFetch } from "../../../course/API"
import { updateJuristicPerson, updateNaturalPerson } from "../../API"
import { getBusinessCategoryFetch, getCareersFetch, getEducationFetch } from "../../../../common/components/userInfo/API"
import { getCityCategoryFetch, getCountryCategoryFetch, getStateCategoryFetch } from "../../../register/API"
import { detectFirstName, detectLastName } from "../../../../common/mamager/DetectManager"
import { genDay, genMonth, genYear } from "../../../../common/mamager/DateMamager"
import { Notification } from "../../../../common/components/notification"
import { useDimensions } from "../../../../common/mamager/ScreenManager"

const { Option } = Select

export default function UserInfo(props) {
    const language = "th"
    const { width } = useDimensions()
    const isEditCourseInterestRef = useRef(false)

    const [isModalVisible, setIsModalVisible] = useState(false)
    const [careers, setCareers] = useState([])
    const [education, setEducation] = useState([])
    const [courseCategory, setCourseCategory] = useState([])

    const [day, setDay] = useState([])
    const [month, setMonth] = useState([])
    const [year, setYear] = useState([])
    const [age, setAge] = useState([])

    const [courseCategory1, setCourseCategory1] = useState([])
    const [courseCategory2, setCourseCategory2] = useState([])
    const [courseCategory3, setCourseCategory3] = useState([])

    const [form] = Form.useForm()

    const [cityCategory, setCityCategory] = useState([])
    const [stateCategory, setStateCategory] = useState([])
    const [businessCategory, setBusinessCategory] = useState([])
    const [countryCategory, setCountryCategory] = useState([])

    const handleCancel = () => {
        setIsModalVisible(false)
    }

    const onFinish = async (values) => {

        let userInfoIsSuccess = true
        if (props.user.businessOperationId === 1) {
            let objNaturalPerson = {
                uid: props.user ? props.user.uid : null,
                careerId: values.career ? values.career : null,
                genderId: values.gender ? values.gender : null,
                educationId: values.education ? values.education : null,
                courseCategoryLike1: isEditCourseInterestRef.current ?
                    (values.courseCategoryInterestedRank1 ? values.courseCategoryInterestedRank1 : null)
                    :
                    (props.user ? props.user.courseCategoryLike1Id : null),
                courseCategoryLike2: isEditCourseInterestRef.current ?
                    (values.courseCategoryInterestedRank2 ? values.courseCategoryInterestedRank2 : null)
                    :
                    (props.user ? props.user.courseCategoryLike2Id : null),
                courseCategoryLike3: isEditCourseInterestRef.current ?
                    (values.courseCategoryInterestedRank3 ? values.courseCategoryInterestedRank3 : null)
                    :
                    (props.user ? props.user.courseCategoryLike3Id : null),
                firstName: values.firstName ? values.firstName : null,
                lastName: values.lastName ? values.lastName : null,
                birthday: values.day ? String(values.day) : null,
                birthMonth: values.month ? String(values.month) : null,
                birthYear: values.year ? String(values.year - 543) : null
            }
            // console.log("objNaturalPerson : ", objNaturalPerson)
            const result = await updateNaturalPerson(objNaturalPerson)
            if (result.isSuccess) {
                userInfoIsSuccess = true
            } else {
                userInfoIsSuccess = false
            }
        }

        if (props.user.businessOperationId === 2) {
            let objJuristicPerson = {
                uid: props.user.uid,

                businessCategory: values.businessCategory,
                companyNameTH: values.companyNameTH,
                companyNameENG: values.companyNameENG,
                companyAddress: values.companyAddress,
                taxIdNumber: props.user.taxIdNumber,
                countryId: values.countryId,
                stateId: values.stateId,
                cityId: values.cityId,
                zipCode: values.zipCode
            }
            // console.log("objJuristicPerson : ", objJuristicPerson)
            const result = await updateJuristicPerson(objJuristicPerson)
            if (result.isSuccess) {
                userInfoIsSuccess = true
            } else {
                userInfoIsSuccess = false
            }
        }

        if (userInfoIsSuccess) {
            Notification("success", "ระบบ", "บันทึกสำเร็จ")
            props.handleReload()
        } else {
            Notification('error', 'ไม่สามารถบันทึกข้อมูลได้ กรุณาลองใหม่อีกครั้ง')
        }

        isEditCourseInterestRef.current = false
        handleCancel()
    }

    const getCareers = async () => {
        const result = await getCareersFetch(language)
        setCareers(result)
    }

    const getEducation = async () => {
        const result = await getEducationFetch(language)
        setEducation(result)
    }

    const getCourseCategory = async () => {
        const result = await getCourseCategoryFetch(language)
        setCourseCategory(result)

        setCourseCategory1(result)
        setCourseCategory2(result)
        setCourseCategory3(result)
    }

    const detectCourseCategory = (value, rank) => {
        // console.log("detectCourseCategory : ", value, rank)
        let tempCourseCategory = courseCategory

        if (rank === 2 || rank === 3) {
            let rank2 = form.getFieldValue('courseCategoryInterestedRank2')
            let rank3 = form.getFieldValue('courseCategoryInterestedRank3')

            if (rank2) {
                tempCourseCategory = tempCourseCategory.filter(item => item.value !== rank2)
            }

            if (rank3) {
                tempCourseCategory = tempCourseCategory.filter(item => item.value !== rank3)
            }

            setCourseCategory1(tempCourseCategory)
            // console.log("A : ", rank2, rank3, tempCourseCategory)
        }

        if (rank === 1 || rank === 3) {
            let rank1 = form.getFieldValue('courseCategoryInterestedRank1')
            let rank3 = form.getFieldValue('courseCategoryInterestedRank3')

            if (rank1) {
                tempCourseCategory = tempCourseCategory.filter(item => item.value !== rank1)
            }

            if (rank3) {
                tempCourseCategory = tempCourseCategory.filter(item => item.value !== rank3)
            }

            setCourseCategory2(tempCourseCategory)
            // console.log("B : ", rank1, rank3, tempCourseCategory)
        }

        if (rank === 1 || rank === 2) {
            let rank1 = form.getFieldValue('courseCategoryInterestedRank1')
            let rank2 = form.getFieldValue('courseCategoryInterestedRank2')

            if (rank1) {
                tempCourseCategory = tempCourseCategory.filter(item => item.value !== rank1)
            }

            if (rank2) {
                tempCourseCategory = tempCourseCategory.filter(item => item.value !== rank2)
            }

            setCourseCategory3(tempCourseCategory)
            // console.log("C : ", rank1, rank2, tempCourseCategory)
        }
    }

    const calculateAge = () => {
        let day = form.getFieldValue('day')
        let month = form.getFieldValue('month')
        let year = form.getFieldValue('year')

        if (day && month && year) {
            let birthday = (Number(year) - 543) + "-" + month + "-" + day
            setAge("อายุ " + moment().diff(birthday, 'years') + " ปี")
        } else {
            setAge("")
        }
    }

    const getCityCategory = async (stateId, countryId) => {
        let obj = {
            language,
            stateId,
            countryId
        }
        const result = await getCityCategoryFetch(obj)
        // console.log("getCityCategoryFetch : ", result)
        setCityCategory(result)
    }

    const getStateCategory = async (countryId) => {
        let obj = {
            language,
            countryId
        }
        const result = await getStateCategoryFetch(obj)
        // console.log("getStateCategoryFetch : ", result)
        setStateCategory(result)
    }

    const getBusinessCategory = async () => {
        const result = await getBusinessCategoryFetch(language)
        // console.log("getBusinessCategory", result)
        setBusinessCategory(result)
    }

    const getCountryCategory = async () => {
        let obj = {
            language,
        }
        const result = await getCountryCategoryFetch(obj)
        // console.log("getCountryCategoryFetch : ", result)
        setCountryCategory(result)
    }

    const detectZipCode = (rules, value) => {
        if (value === "" || value === undefined) {
            // return Promise.reject('กรุณากรอกรหัสไปรษณีย์')
            return Promise.resolve()
        } else {
            if (isNumber(String(value))) {
                return Promise.resolve()
            } else {
                return Promise.reject('รหัสไปรษณีย์ต้องเป็นตัวเลข')
            }
        }
    }

    const isNumber = (str) => {
        if (str.trim() === '') {
            return false
        }

        return !isNaN(str)
    }

    const getBaseApi = async () => {
        if (careers.length === 0) {
            await getCareers()
        }

        if (education.length === 0) {
            await getEducation()
        }

        if (courseCategory.length === 0) {
            await getCourseCategory()
        }

        if (props.user.businessOperationId === 1) { // บุคคลธรรมดา
            setDay(genDay())
            setMonth(genMonth())
            setYear(genYear("regress"))
            calculateAge()
        }

        if (props.user.businessOperationId === 2) { // บริษัท
            getCityCategory()
            getStateCategory()
            getBusinessCategory()
            getCountryCategory()
        }
    }

    useEffect(() => {
        getBaseApi()
    }, [])

    return (
        <>
            <Form
                form={form}
                layout="vertical"
                maskClosable={false}
                initialValues={{
                    career: props.user.careerId,
                    education: props.user.educationId,
                    gender: props.user.genderId,
                    day: props.user.birthday_day,
                    month: props.user.birthday_month,
                    year: props.user.birthday_year,
                    firstName: props.user.firstName,
                    lastName: props.user.lastName,

                    companyNameTH: props.user.companyNameTH,
                    companyNameENG: props.user.companyNameENG,
                    companyAddress: props.user.companyAddress,
                    businessCategory: props.user.businessCategoryId,
                    countryId: props.user.countryId,
                    stateId: props.user.stateId,
                    cityId: props.user.cityId,
                    zipCode: props.user.zipCode,

                    courseCategoryInterestedRank1: props.user.courseCategoryLike1Id,
                    courseCategoryInterestedRank2: props.user.courseCategoryLike2Id,
                    courseCategoryInterestedRank3: props.user.courseCategoryLike3Id
                }}
                onFinish={onFinish}
            >
                <div style={width > 576 ? {} : { borderTopWidth: 0, borderRadius: 0 }}>
                    <Row>
                        <Col span={24} style={{ paddingBottom: 8 }}>
                            {props.user.businessOperationId === 1 ? <label>ข้อมูลส่วนตัวผู้ใช้งาน</label> : []}
                            {props.user.businessOperationId === 2 ? <label>ข้อมูลบริษัท</label> : []}
                        </Col>

                        <Col xs={24} xl={12}>
                            <Row gutter={[16, 0]}>
                                {props.user.businessOperationId === 1 ?
                                    <>
                                        <Col xs={24} xl={12}>
                                            <Form.Item
                                                label="ชื่อจริง"
                                                name="firstName"
                                                rules={[{ required: false, validator: detectFirstName }]}
                                            >
                                                <Input placeholder="กรอกชื่อจริง" />
                                            </Form.Item>
                                        </Col>

                                        <Col xs={24} xl={12}>
                                            <Form.Item
                                                label="นามสกุล"
                                                name="lastName"
                                                rules={[{ required: false, validator: detectLastName }]}
                                            >
                                                <Input placeholder="กรอกนามสกุล" />
                                            </Form.Item>
                                        </Col>

                                        <Col span={24}>
                                            <Form.Item
                                                label="อาชีพ"
                                                name="career"
                                                rules={[{ required: false, message: "กรุณาเลือกอาชีพ" }]}
                                            >
                                                <Select
                                                    showSearch
                                                    placeholder="เลือกอาชีพ"
                                                    optionFilterProp="children"
                                                    allowClear
                                                >
                                                    {careers.map((val, index) => <Option key={index} value={val.value}>{val.label}</Option>)}
                                                </Select>
                                            </Form.Item>
                                        </Col>

                                        <Col span={24}>
                                            <Form.Item
                                                label="การศึกษา"
                                                name="education"
                                                rules={[{ required: false, message: "กรุณาเลือกการศึกษา" }]}
                                            >
                                                <Select
                                                    showSearch
                                                    placeholder="เลือกการศึกษา"
                                                    optionFilterProp="children"
                                                    allowClear
                                                >
                                                    {education.map((val, index) => <Option key={index} value={val.value}>{val.label}</Option>)}
                                                </Select>
                                            </Form.Item>
                                        </Col>

                                        <Col span={24}>
                                            <Form.Item
                                                label="เพศ"
                                                name="gender"
                                                rules={[{ required: false, message: "กรุณาเลือกเพศ" }]}
                                            >
                                                <Radio.Group>
                                                    <Radio value={1}>ชาย</Radio>
                                                    <Radio value={2}>หญิง</Radio>
                                                </Radio.Group>
                                            </Form.Item>
                                        </Col>

                                        <Col span={24} style={{ paddingBottom: 8 }}>
                                            <label>วันเกิด</label>
                                        </Col>
                                        <Col span={24}>
                                            <Row gutter={[16, 0]}>
                                                <Col xs={24} sm={7} md={7} lg={7} xl={7}>
                                                    <Form.Item
                                                        name="day"
                                                        label="วัน"
                                                        style={{ width: '100%' }}
                                                        rules={[{ required: false, message: 'กรุณาเลือกวัน' }]}
                                                    >
                                                        <Select
                                                            showSearch
                                                            style={{ width: '100%' }}
                                                            placeholder="เลือกวัน"
                                                            optionFilterProp="children"
                                                            allowClear
                                                            onChange={() => calculateAge()}
                                                        >
                                                            {day.map((val, index) =>
                                                                <Option key={index} value={val.value}>{val.label}</Option>
                                                            )}
                                                        </Select>
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={24} sm={10} md={10} lg={10} xl={10}>
                                                    <Form.Item
                                                        name="month"
                                                        label="เดือน"
                                                        style={{ width: '100%' }}
                                                        rules={[{ required: false, message: 'กรุณาเลือกเดือน' }]}
                                                    >
                                                        <Select
                                                            showSearch
                                                            style={{ width: '100%' }}
                                                            placeholder="เลือกเดือน"
                                                            optionFilterProp="children"
                                                            allowClear
                                                            onChange={() => calculateAge()}
                                                        >
                                                            {month.map((val, index) =>
                                                                <Option key={index} value={val.value}>{val.label}</Option>
                                                            )}
                                                        </Select>
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={24} sm={7} md={7} lg={7} xl={7}>
                                                    <Form.Item
                                                        name="year"
                                                        label="ปี"
                                                        style={{ width: '100%' }}
                                                        rules={[{ required: false, message: 'กรุณาเลือกปี' }]}
                                                    >
                                                        <Select
                                                            showSearch
                                                            style={{ width: '100%' }}
                                                            placeholder="เลือกปี"
                                                            optionFilterProp="children"
                                                            allowClear
                                                            onChange={() => calculateAge()}
                                                        >
                                                            {year.map((val, index) =>
                                                                <Option key={index} value={val.value}>{val.label}</Option>
                                                            )}
                                                        </Select>
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </>
                                    :
                                    <>
                                        <Col span={24}>
                                            <Form.Item
                                                label="ชื่อบริษัทภาษาไทย"
                                                name="companyNameTH"
                                                rules={[{ required: false, message: "กรุณากรอกชื่อบริษัทภาษาไทย" }]}
                                            >
                                                <Input placeholder="กรอกชื่อบริษัทภาษาไทย" />
                                            </Form.Item>
                                        </Col>

                                        <Col span={24}>
                                            <Form.Item
                                                label="ชื่อบริษัทภาษาอังกฤษ"
                                                name="companyNameENG"
                                                rules={[{ required: false, message: "กรุณากรอกชื่อบริษัทภาษาอังกฤษ" }]}
                                            >
                                                <Input placeholder="กรอกชื่อบริษัทภาษาอังกฤษ" />
                                            </Form.Item>
                                        </Col>

                                        <Col span={24}>
                                            <Form.Item
                                                label="ประกอบธุรกิจ"
                                                name="businessCategory"
                                                rules={[{ required: false, message: "กรุณากรอกการประกอบธุรกิจ" }]}
                                            >
                                                <Select
                                                    showSearch
                                                    placeholder="เลือกประกอบธุรกิจ"
                                                    dropdownMatchSelectWidth={false}
                                                    optionFilterProp="children"
                                                    allowClear
                                                >
                                                    {businessCategory.map((val, index) =>
                                                        <Option key={index} value={val.value}>{val.label}</Option>
                                                    )}
                                                </Select>
                                            </Form.Item>
                                        </Col>

                                        <Col span={24}>
                                            <Form.Item
                                                label="ที่อยู่บริษัท"
                                                name="companyAddress"
                                                rules={[{ required: false, message: "กรุณากรอกที่อยู่บริษัท" }]}
                                            >
                                                <Input placeholder="กรอกที่อยู่บริษัท" />
                                            </Form.Item>
                                        </Col>

                                        <Col span={24}>
                                            <Form.Item
                                                label="ประเทศ"
                                                name="countryId"
                                                rules={[{ required: false, message: "กรุณาเลือกประเทศ" }]}
                                            >
                                                <Select
                                                    showSearch
                                                    optionFilterProp="children"
                                                    placeholder="เลือกประเทศ"
                                                    allowClear
                                                    onChange={(val) => {
                                                        form.setFieldsValue({ cityId: undefined });
                                                        form.setFieldsValue({ stateId: undefined });
                                                        getStateCategory(val);
                                                    }}
                                                >
                                                    {countryCategory.map((val, index) => <Option key={index} value={val.value}>{val.label}</Option>)}
                                                </Select>
                                            </Form.Item>
                                        </Col>

                                        <Col span={24}>
                                            <Form.Item
                                                label="จังหวัด"
                                                name="stateId"
                                                rules={[{ required: false, message: "กรุณาเลือกจังหวัด" }]}
                                            >
                                                <Select
                                                    showSearch
                                                    optionFilterProp="children"
                                                    placeholder="เลือกจังหวัด"
                                                    allowClear
                                                    onChange={(val) => {
                                                        form.setFieldsValue({ cityId: undefined });
                                                        getCityCategory(val, form.getFieldsValue().countryId)
                                                    }}
                                                >
                                                    {stateCategory.map((val, index) =>
                                                        <Option key={index} value={val.value}>{val.label}</Option>
                                                    )}
                                                </Select>
                                            </Form.Item>
                                        </Col>

                                        <Col span={24}>
                                            <Form.Item
                                                label="เขต/อำเภอ"
                                                name="cityId"
                                                rules={[{ required: false, message: "กรุณาเลือกเขต/อำเภอ" }]}
                                            >
                                                <Select
                                                    showSearch
                                                    optionFilterProp="children"
                                                    placeholder="เลือกเขต/อำเภอ"
                                                    allowClear
                                                >
                                                    {cityCategory.map((val, index) =>
                                                        <Option key={index} value={val.value}>{val.label}</Option>
                                                    )}
                                                </Select>
                                            </Form.Item>
                                        </Col>

                                        <Col span={24}>
                                            <Form.Item
                                                label="รหัสไปรษณีย์"
                                                name="zipCode"
                                                rules={[{ required: false, validator: detectZipCode }]}
                                            >
                                                <Input
                                                    maxLength={5}
                                                    placeholder="กรอกรหัสไปรษณีย์"
                                                />
                                            </Form.Item>
                                        </Col>
                                    </>
                                }
                            </Row>
                        </Col>
                        <Col xs={24} xl={12}></Col>

                        <Col span={24} style={{ textAlign: "center", borderTop: "1px solid #eeeeee" }}
                        >
                            <div style={{ paddingTop: 15 }}>
                                <Button type="primary" onClick={() => form.submit()} >
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <Icon icon="bx:save" style={{ width: 19, height: 19, color: "white" }} />
                                        <label style={{ paddingLeft: 10, cursor: "pointer" }}>บันทึกข้อมูล</label>
                                    </div>
                                </Button>
                            </div>
                        </Col>
                    </Row>

                </div>

                <div style={{ height: 24 }}></div>

                {props.user.businessOperationId === 1 ?
                    <div>
                        <Row gutter={[16, 8]} style={{ paddingBottom: 10 }}>
                            <Col span={24} style={{ textAlign: "center" }}>
                                <label>สิ่งที่สนใจ</label>
                            </Col>

                            <Col xs={24} lg={8} style={{ textAlign: "center" }}>
                                <Icon icon={props.user.courseCategoryLike1Img} style={{ color: "#23b14d", width: 50, height: 50 }} />
                                <br />
                                <label style={{ paddingTop: 15 }}>{props.user.courseCategoryLike1Name}</label>
                            </Col>

                            <Col xs={24} lg={8} style={{ textAlign: "center" }}>
                                <Icon icon={props.user.courseCategoryLike2Img} style={{ color: "#23b14d", width: 50, height: 50 }} />
                                <br />
                                <label style={{ paddingTop: 15 }}>{props.user.courseCategoryLike2Name}</label>
                            </Col>

                            <Col xs={24} lg={8} style={{ textAlign: "center" }}>
                                <Icon icon={props.user.courseCategoryLike3Img} style={{ color: "#23b14d", width: 50, height: 50 }} />
                                <br />
                                <label style={{ paddingTop: 15 }}>{props.user.courseCategoryLike3Name}</label>
                            </Col>
                        </Row>

                        <Row>
                            <Col span={24} style={{ textAlign: "center", borderTop: "1px solid #eeeeee" }}>
                                <div style={{ paddingTop: 15 }}>
                                    <Button
                                        type="primary"
                                        onClick={() => {
                                            let tempCourseCategory2 = courseCategory.filter(item => item.value !== props.user.courseCategoryLike1Id);
                                            setCourseCategory2(tempCourseCategory2);

                                            let tempCourseCategory3 = tempCourseCategory2.filter(item => item.value !== props.user.courseCategoryLike2Id);
                                            setCourseCategory3(tempCourseCategory3);

                                            setIsModalVisible(true);
                                        }}
                                    >
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <Icon icon="dashicons:admin-customizer" style={{ color: "white" }} />
                                            <label style={{ paddingLeft: 10, cursor: "pointer" }}>เปลี่ยนสิ่งที่สนใจ</label>
                                        </div>
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    : []
                }

            </Form>

            {props.user.businessOperationId === 1 ?
                <Modal
                    title={<label style={{ fontSize: 14, fontWeight: 100 }}>เปลี่ยนสิ่งที่สนใจ</label>}
                    visible={isModalVisible}
                    onCancel={handleCancel}
                    footer={[
                        <Button
                            type="primary"
                            onClick={() => {
                                isEditCourseInterestRef.current = true
                                form.submit()
                            }}
                        >
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Icon icon="bx:save" style={{ width: 19, height: 19, color: "white" }} />
                                <label style={{ paddingLeft: 10, cursor: "pointer" }}>บันทึกข้อมูล</label>
                            </div>
                        </Button>
                    ]}
                    cancelButtonProps={{ hidden: true }}
                    okButtonProps={{ hidden: true }}
                    width={400}
                >
                    <Form
                        form={form}
                        layout="vertical"
                        maskClosable={false}
                        onFinish={onFinish}
                    >
                        <Row>
                            <Col span={24}>
                                <Form.Item
                                    label="หมวดหมู่คอร์สที่ชื่นชอบ อันดับ 1"
                                    name="courseCategoryInterestedRank1"
                                    rules={[{ required: true, message: 'กรุณาเลือก หมวดหมู่คอร์สที่ชื่นชอบ อันดับ 1' }]}
                                >
                                    <Select
                                        showSearch
                                        placeholder="อันดับ 1 ใหม่"
                                        optionFilterProp="children"
                                        allowClear
                                        onChange={(e) => {
                                            form.setFieldsValue({ courseCategoryInterestedRank2: undefined });
                                            form.setFieldsValue({ courseCategoryInterestedRank3: undefined });
                                            detectCourseCategory(e, 1);
                                        }}
                                    >
                                        {courseCategory1.map((val, index) => <Option key={index} value={val.value}>{val.label}</Option>)}
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col span={24}>
                                <Form.Item
                                    label="หมวดหมู่คอร์สที่ชื่นชอบ อันดับ 2"
                                    name="courseCategoryInterestedRank2"
                                    rules={[{ required: true, message: 'กรุณาเลือก หมวดหมู่คอร์สที่ชื่นชอบ อันดับ 2' }]}
                                >
                                    <Select
                                        showSearch
                                        placeholder="อันดับ 2 ใหม่"
                                        optionFilterProp="children"
                                        allowClear
                                        onChange={(e) => {
                                            detectCourseCategory(e, 2);
                                            form.setFieldsValue({ courseCategoryInterestedRank3: undefined });
                                        }}
                                        disabled={form.getFieldValue('courseCategoryInterestedRank1') === undefined ? true : false}
                                    >
                                        {courseCategory2.map((val, index) => <Option key={index} value={val.value}>{val.label}</Option>)}
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col span={24}>
                                <Form.Item
                                    label="หมวดหมู่คอร์สที่ชื่นชอบ อันดับ 3"
                                    name="courseCategoryInterestedRank3"
                                    rules={[{ required: true, message: 'กรุณาเลือก หมวดหมู่คอร์สที่ชื่นชอบ อันดับ 3' }]}
                                >
                                    <Select
                                        showSearch
                                        placeholder="อันดับ 3 ใหม่"
                                        optionFilterProp="children"
                                        allowClear
                                        onChange={(e) => {
                                            detectCourseCategory(e, 3);
                                        }}
                                        disabled={form.getFieldValue('courseCategoryInterestedRank1') === undefined || form.getFieldValue('courseCategoryInterestedRank2') === undefined ? true : false}
                                    >
                                        {courseCategory3.map((val, index) => <Option key={index} value={val.value}>{val.label}</Option>)}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Modal>
                : []
            }
        </>
    )
}
