/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */

import './../../css/index.css'
import { useEffect, useRef, useState } from 'react'
import moment from 'moment'
import { 
    Row, 
    Col, 
    Spin, 
    Modal, 
    Form, 
    Input, 
    Button, 
    Popconfirm, 
    Tooltip, 
    Drawer,
    // Collapse
} from 'antd'
import { Icon } from '@iconify/react'
import { RiDeleteBinLine } from "react-icons/ri"
import BookmarkIcon from "@material-ui/icons/Bookmark"
import $ from 'jquery'
import { useDimensions } from './../../../../common/mamager/ScreenManager'
import Video from './../../../../common/components/video'
import { PathVideo } from './../../../../common/components/video/components/convert/pathVideo'
import CourseVideo from './../../../course/components/video'
import {
    deleteClassroomBookmarkVideoCourseFetch,
    getClassroomGetBookmarkVideoCourseFetch,
    getClassroomUpdateViewVideoCourseFetch,
    insertClassroomBookmarkVideoCourseFetch
} from './../../../classroom/API'

// import { styled } from '@mui/system'
// const { Panel } = Collapse

const format = (seconds) => {
    if (isNaN(seconds)) {
        return `00:00`
    }
    const date = new Date(seconds * 1000)
    const hh = date.getUTCHours()
    const mm = date.getUTCMinutes()
    const ss = date.getUTCSeconds().toString().padStart(2, "0")
    if (hh) {
        return `${hh}:${mm.toString().padStart(2, "0")}:${ss}`
    }
    return `${mm}:${ss}`
}

// const useStyles = styled((theme) => ({
//     menuRight: {
//         height: 50,
//         width: 55,
//         borderRadius: 0,
//         marginTop: 1,
//         backgroundColor: "#0c0c0c",
//         borderBottom: "1px solid rgb(80, 80, 80)",
//         "&:hover": {
//             backgroundColor: "black",
//             borderBottom: "1px solid #c8c8c8"
//         }
//     },
//     menuIconRight: {
//         fontSize: '2.5em',
//         marginTop: -2,
//         marginLeft: -6,
//         color: "white",
//         "&:hover": {
//             color: " #818181"
//         }
//     }
// }));

// CourseStudyVideo

export default function VideoStudy(props) {

    // const classes = useStyles()
    
    const { width } = useDimensions()
    const [formBookmark] = Form.useForm()

    const courseId = props?.course ? (props.course?.courseId ? props.course.courseId : null) : null
    const learnerId = props?.user ? (props.user?.uid ? props.user.uid : null) : null
    const teacherId = props?.course ? (props.course?.uid ? props.course.uid : null) : null
    const videoId = props.videoCourse ? (props.videoCourse?.courseVideoId ? props.videoCourse?.courseVideoId : null) : null

    const [bookmarks, setBookmarks] = useState([])
    const [modalBookmark, setModalBookmark] = useState(false)

    const playerRef = useRef(null)
    const controlsRef = useRef(null)
    const canvasRef = useRef(null)

    const [loading, setLoading] = useState(false)

    const [openVideoList, setOpenVideoList] = useState(false)

    const [bookmarkIsOpen, setBookmarkIsOpen] = useState(true)

    const onSwitchBookmark = () => {
        setBookmarkIsOpen(!bookmarkIsOpen)
    }

    const showVideoListDrawer = () => {
        setOpenVideoList(true)
    }

    const onVideoListClose = () => {
        setOpenVideoList(false)
    }

    const showModalBookmark = () => {
        formBookmark.resetFields()
        setModalBookmark(true)
    }

    const sizeLayout_L_R = (type) => {
        let w = width;
        let L = w - 55;
        let LP = (L * 100) / w;
        let RP = 100 - LP;
        // console.log("w : ", w, ", LP : ", LP, ", RP : ", RP);
        if (type === "LP") {
            return LP + "%";
        } else if (type === "RP") {
            return RP + "%";
        }
    }

    const handleBookmarkCancel = () => {
        setModalBookmark(false)
    }

    const handlePlayer = (player) => {
        playerRef.current = player.current
    }

    const handleControls = (controls) => {
        controlsRef.current = controls.current
    }

    const onFinishBookmark = async (values) => {
        // Add bookmark
        const canvas = canvasRef.current
        canvas.width = 160
        canvas.height = 90
        const ctx = canvas.getContext("2d")

        ctx.drawImage(playerRef.current.getInternalPlayer(), 0, 0, canvas.width, canvas.height)
        const dataUri = canvas.toDataURL()
        canvas.width = 0
        canvas.height = 0

        // add bookmark
        await insertClassroomBookmarkVideoCourseFetch({
            courseVideoId: videoId,
            courseId,
            learnerId,
            teacherId,
            timeVideo: playerRef.current.getCurrentTime(),
            displayTimeVideo: format(playerRef.current.getCurrentTime()),
            captureVideo: dataUri,
            titleBookmark: values.titleBookmark,
        });

        // state push
        const bookmarksCopy = [...bookmarks]
        bookmarksCopy.push({
            timeVideo: playerRef.current.getCurrentTime(),
            displayTimeVideo: format(playerRef.current.getCurrentTime()),
            captureVideo: dataUri,
            titleBookmark: values.titleBookmark,
        })
        setBookmarks(bookmarksCopy)

        // close modal
        handleBookmarkCancel()

        // reload
        handleBookmarkReload()
    }

    const getBookmarkVideoCourse = async () => {
        setLoading(true)

        const result = await getClassroomGetBookmarkVideoCourseFetch(
            videoId,
            courseId,
            teacherId,
            learnerId
        )
        setBookmarks(result)

        setLoading(false)
    }

    const handleBookmarkReload = () => {
        getBookmarkVideoCourse()
    }

    const handleTimeVideo = (timeVideo, displayTimeVideo) => { }
    const handlePercentWatchVideoCourse = (timeVideoCourse, timerWatchVideoCourse, percent, isFirstLoadApi, VideoCourseList, timerWatchVideoCourseList) => {
        props.handlePercentWatchVideoCourse(timeVideoCourse, timerWatchVideoCourse, percent, isFirstLoadApi, VideoCourseList, timerWatchVideoCourseList)
    }

    const getBaseApi = async () => {
        if (props.videoCourse) {
            // plus one view of video
            await getClassroomUpdateViewVideoCourseFetch(props.videoCourse.courseVideoId)
            await getBookmarkVideoCourse()
        }
    }

    const reloadOderPayment = (data) => { }

    useEffect(() => {
        getBaseApi()
    }, [props.videoCourse ? props.videoCourse.courseVideoId : null]); // props.course.courseId, props.course.uid, props.videoCourse.courseVideoId

    useEffect(() => {
    }, [playerRef])

    useEffect(() => {
    }, [controlsRef])

    return (

        <>
            <Spin spinning={loading}>
                <Row className="course-introVideo-container">
                    <Col span={24}>
                        <div style={{ float: "left", width: (width < 992 ? sizeLayout_L_R("LP") : "100%") }}>
                            <Video
                                videoId={videoId}
                                videoTitle={props.videoCourse ? props.videoCourse.courseVideoTitleName : null}
                                videoLocation={props.videoCourse ? PathVideo(props.videoCourse.courseVideoLocation) : null}

                                courseId={courseId}
                                learnerId={learnerId}

                                component={'course-intro-video-board'}
                                height={454}
                                isPlaying={true}
                                timerRunning={true}

                                handleTimeVideo={handleTimeVideo}
                                handlePercentWatchVideoCourse={handlePercentWatchVideoCourse}
                                handlePlayer={handlePlayer}
                                handleControls={handleControls}
                            />
                        </div>

                        {width < 992 ?
                            <div
                                style={{
                                    float: "left",
                                    width: sizeLayout_L_R("RP"),
                                    backgroundColor: "rgb(25, 25, 25)",
                                    borderBottom: "1px solid #c8c8c8"
                                    // height: "100%"
                                }}
                            >
                                <Row>
                                    <Col span={24}>
                                        <Tooltip placement="left" title={"เนื้อหาคอร์ส"}>
                                            <Button
                                                type="text"
                                                onClick={() => showVideoListDrawer()}
                                            >
                                                <Icon
                                                    icon="iconoir:media-video-list"
                                                    style={{ color: "white", width: 20, height: 20 }}
                                                    onClick={() => showVideoListDrawer()}
                                                />
                                            </Button>
                                        </Tooltip>
                                    </Col>
                                </Row>
                            </div>
                            : []
                        }
                    </Col>

                    <Col
                        span={24}
                        style={{
                            paddingTop: 12,
                            paddingBottom: 12,
                            paddingLeft: 20,
                            paddingRight: 20,
                            borderBottom: '1px solid #696969'
                        }}
                    >
                        <label style={{ paddingRight: 20, height: 0 }}>เข้าชม {Intl.NumberFormat('en').format(Number(props.videoCourse ? props.videoCourse?.view : 0))} ครั้ง </label>
                        <label style={{ height: 0 }}> เเก้ไขล่าสุด {moment(props.videoUpdateAt).add(543, "year").format('DD/MM/YYYY')}</label>
                    </Col>

                    <Col span={24}>
                        <div>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    padding: 8
                                }}
                            >
                                <div
                                    style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                                    onClick={() => onSwitchBookmark()}
                                >
                                    {bookmarkIsOpen ? (
                                        <Icon icon="mingcute:up-line" style={{ color: 'white', height: 18, width: 18 }} />
                                    ) : (
                                        <Icon icon="mingcute:down-line" style={{ color: 'white', height: 18, width: 18 }} />
                                    )}
                                    <label style={{ color: 'white', paddingLeft: 10, cursor: 'pointer' }}>รายการ Bookmark</label>
                                </div>

                                <Button type="primary" onClick={() => showModalBookmark()}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <BookmarkIcon style={{ height: 18, width: 18, cursor: 'pointer' }} />
                                        <label style={{ paddingLeft: 5, cursor: 'pointer' }}>เพิ่มบุ๊กมาร์ก</label>
                                    </div>
                                </Button>
                            </div>
                            <div>
                                {bookmarkIsOpen ?
                                    <>
                                        <Row
                                            style={{
                                                overflow: 'auto',
                                                maxHeight: 389,
                                                width: 'auto',
                                                overflowX: 'hidden',
                                                paddingLeft: 4,
                                                paddingRight: 4
                                            }}
                                        >
                                            {bookmarks.length > 0 ?
                                                <>
                                                    {bookmarks.map((bookmark, index) => (
                                                        <Col Col xs={12} lg={6} style={{ cursor: 'pointer', padding: 5 }}>
                                                            <div
                                                                style={{ padding: 10, backgroundColor: '#242424' }}
                                                                className={"bookmark-video-course-" + index}
                                                                onClick={() => {

                                                                    playerRef.current.seekTo(bookmark.timeVideo)
                                                                    controlsRef.current.style.visibility = "visible";

                                                                    setTimeout(() => {
                                                                        controlsRef.current.style.visibility = "hidden";
                                                                    }, 1000);

                                                                    for (let i = 0; i < bookmarks.length; i++) {
                                                                        // focus 
                                                                        if (i === index) {
                                                                            $(".bookmark-video-course-" + i).css({ "backgroundColor": "#3D3D3D" })
                                                                        } else {
                                                                            $(".bookmark-video-course-" + i).css({ "backgroundColor": "#242424" })
                                                                        }
                                                                    }
                                                                }}
                                                                elevation={3}
                                                            >
                                                                <Popconfirm
                                                                    title="คุณยืนยันลบ Bookmark นี้หรือไม่ ?"
                                                                    okText={<span style={{ width: 50 }}>ใช่</span>}
                                                                    onConfirm={async () => {
                                                                        await deleteClassroomBookmarkVideoCourseFetch(bookmark.bookmarkVideoCourseId)
                                                                        handleBookmarkReload()
                                                                    }}
                                                                    cancelText={<span style={{ width: 50 }}>ไม่ใช่</span>}
                                                                >
                                                                    <RiDeleteBinLine

                                                                        className="bookmark-video-course-delete"
                                                                        style={{ marginTop: -2, marginRight: 13 }}
                                                                    />
                                                                </Popconfirm>
                                                                <img
                                                                    crossOrigin="anonymous"
                                                                    src={bookmark.captureVideo}
                                                                    style={{ width: '100%' }}
                                                                />
                                                                <p
                                                                    style={{
                                                                        width: '100%',
                                                                        margin: 0,
                                                                        color: 'white'
                                                                    }}
                                                                >{bookmark.titleBookmark} {bookmark.displayTimeVideo}</p>
                                                            </div>
                                                        </Col>
                                                    ))}
                                                </>
                                                :
                                                <Col
                                                    span={24}
                                                    style={{
                                                        paddingTop: 15,
                                                        paddingBottom: 15,
                                                        borderRadius: 10
                                                    }}
                                                >
                                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                        <Icon icon="fontisto:preview" style={{ color: "#6f6f6f", width: 30, height: 30 }} />
                                                        <span style={{ color: '#6f6f6f', paddingLeft: 8 }}>ไม่พบบุ๊กมาร์ก</span>
                                                    </div>
                                                </Col>
                                            }
                                        </Row>
                                        <div style={{ paddingTop: 4 }}></div>
                                    </>
                                    : []
                                }
                            </div>
                        </div>
                    </Col>

                    <Col span={24} style={{ height: 0, display: 'none' }}>
                        <canvas ref={canvasRef} />
                    </Col>
                </Row>
            </Spin>

            <Modal
                title={<label style={{ fontWeight: 100 }}>เพิ่มบุ๊กมาร์ก</label>}
                style={{ zIndex: 1001 }}
                visible={modalBookmark}
                onOk={() => {
                    formBookmark.submit()
                }}
                onCancel={handleBookmarkCancel}
                okText={
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Icon icon="bx:save" style={{ color: "white" }} />
                        <label style={{ paddingLeft: 10, cursor: "pointer" }}>บันทึก</label>
                    </div>
                }
                cancelText={
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Icon icon="mdi:cancel" />
                        <label style={{ paddingLeft: 10, cursor: "pointer" }}>ยกเลิก</label>
                    </div>
                }
                width={400}
            >
                <Form
                    layout="vertical"
                    form={formBookmark}
                    onFinish={onFinishBookmark}
                >
                    <Form.Item
                        name="titleBookmark"
                        label="ชื่อบุ๊กมาร์ก"
                        rules={[{ required: true, message: 'กรุณากรอกชื่อบุ๊กมาร์ก !' }]}
                    >
                        <Input style={{ width: '100%' }} />
                    </Form.Item>
                </Form>
            </Modal>

            <Drawer
                title={<label style={{ fontWeight: 100 }}>เนื้อหาคอร์ส</label>}
                placement="right"
                onClose={onVideoListClose}
                visible={openVideoList}
                bodyStyle={{ padding: '0px' }}
                width={width > 768 ? "60%" : ((width >= 576 && width < 768) ? "70%" : "85%")}
            >
                <div style={{ backgroundColor: "rgb(25, 25, 25)", height: "100%" }}>
                    {!props.timeLeft.isExpire || props.coursePurchase?.courseFormatId === 1 ? // [courseFormat 1 is ตลอดชีวิต, isExpire false is ยังไม่หมดอายุ]
                        <CourseVideo
                            reloadOderPayment={reloadOderPayment}
                            coureVideoGroup={props.coureVideoGroup}
                            course={props.course}
                            handleVideoCourseCurrent={props.handleVideoCourseCurrent}
                            pageName="classroom-course-study"
                        />
                        : []
                    }
                </div>
                {/* <div style={{ height: 100, border: "1px solid black", color: "black", fontSize: 20 }}>1</div>
                <div style={{ height: 100, border: "1px solid black", color: "black", fontSize: 20 }}>2</div>
                <div style={{ height: 100, border: "1px solid black", color: "black", fontSize: 20 }}>3</div>
                <div style={{ height: 100, border: "1px solid black", color: "black", fontSize: 20 }}>4</div>
                <div style={{ height: 100, border: "1px solid black", color: "black", fontSize: 20 }}>5</div>
                <div style={{ height: 100, border: "1px solid black", color: "black", fontSize: 20 }}>6</div>
                <div style={{ height: 100, border: "1px solid black", color: "black", fontSize: 20 }}>7</div>
                <div style={{ height: 100, border: "1px solid black", color: "black", fontSize: 20 }}>8</div>
                <div style={{ height: 100, border: "1px solid black", color: "black", fontSize: 20 }}>9</div>
                <div style={{ height: 100, border: "1px solid black", color: "black", fontSize: 20 }}>10</div>
                <div style={{ height: 100, border: "1px solid black", color: "black", fontSize: 20 }}>10</div>
                <div style={{ height: 100, border: "1px solid black", color: "black", fontSize: 20 }}>10</div> */}
            </Drawer>
        </>

    )
}
