import { 
    HTTP_COURSE_MANAGE_PRICE_FETCHING, 
    HTTP_COURSE_MANAGE_PRICE_SUCCESS, 
    HTTP_COURSE_MANAGE_PRICE_FAILED, 
} from "../constants"

export const setCourseManagePriceStateToFetching = () => ({
    type: HTTP_COURSE_MANAGE_PRICE_FETCHING,
})

export const setCourseManagePriceStateToSuccess = (payload) => ({
    type: HTTP_COURSE_MANAGE_PRICE_SUCCESS,
    payload
})

export const setCourseManagePriceStateToFailed = () => ({
    type: HTTP_COURSE_MANAGE_PRICE_FAILED,
})