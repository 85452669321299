/* eslint-disable react-hooks/exhaustive-deps */

import { Button, Col, Form, Input, Modal, Popconfirm, Row } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import { useEffect, useState } from 'react'
import { FiEdit } from "react-icons/fi"
import { Icon } from '@iconify/react'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { GiHamburgerMenu } from "react-icons/gi"
import { setCourseManageVideoListStateToSuccess } from '../../../../actions/courseManageVideoList.action'
import { setCourseManageChapterStateToSuccess } from '../../../../actions/courseManageChapter.action'
import { Notification } from '../../../../common/components/notification'
import { useDimensions } from '../../../../common/mamager/ScreenManager'

export default function ManageChapter(props) {

    const { width } = useDimensions()

    const dispatch = useDispatch()
    const { resultChapter } = useSelector(({ courseManageChapterReducer }) => courseManageChapterReducer)
    const { resultVideoList } = useSelector(({ courseManageVideoListReducer }) => courseManageVideoListReducer)

    const [inspectChapter, setInspectChapter] = useState([])

    const [formEditChapter] = Form.useForm()

    const [modalEditChapter, setModalEditChapter] = useState(false)

    const setDefaultChapter = () => {
        formEditChapter.resetFields()

        // close modal chapter
        setModalEditChapter(false)
    }

    const onFinishEditChapter = async (values) => {
        let tempResultChapter = resultChapter.map(val => {
            return (val.courseChapterId === props.courseChapterId ? {
                approvalStatusName: "เเก้ไขกลุ่มวิดีโอ ยังไม่ถูกบันทึก",
                approvalStatusId: 14,
                courseChapterId: props.courseChapterId,
                chapterCategoryId: props.chapterCategoryUsed?.id,
                chapterCategoryName: props.chapterCategoryUsed?.name,
                courseChapterTitleName: values.courseChapterTitleName,
                courseChapterDescriptionName: values.courseChapterDescriptionName,

                isUpdate: true,
                createdAt: props.createdAt,
                updatedAt: moment(),

                // inspect
                inspectCourseApprovalId: props.inspectCourseApprovalId,
                inspectCourseApprovalName: props.inspectCourseApprovalName,
                reasonCourseNotApproval: props.reasonCourseNotApproval
            } : val)
        })

        // reducer
        setReducer(tempResultChapter, "video")

        // notify
        Notification("success", "เเจ้งเตือน", "เเก้ไขกลุ่มวิดีโอสำเร็จ")

        // set default
        setDefaultChapter()
    }

    const onRemoveChapter = () => {
        let tempResultChapter = []

        let approvalStatusName = props.approvalStatusName
        let approvalStatusId = props.approvalStatusId

        let tempChapterId = String(props.courseChapterId).split("-=|=-") // split a string using "-=|=-" form add video

        if (tempChapterId[0] !== "temporary") { // delete pending check
            approvalStatusName = "ลบกลุ่มวิดีโอ ยังไม่ถูกบันทึก"
            approvalStatusId = 15

            tempResultChapter = resultChapter.map(val => {
                return (val.courseChapterId === props.courseChapterId ? {
                    approvalStatusName,
                    approvalStatusId,
                    courseChapterId: props.courseChapterId,
                    chapterCategoryId: props.chapterCategoryUsed?.id,
                    chapterCategoryName: props.chapterCategoryUsed?.name,
                    courseChapterTitleName: props.courseChapterTitleName,
                    courseChapterDescriptionName: props.courseChapterDescriptionName,
                    isUpdate: true,
                    createdAt: props.createdAt,
                    updatedAt: moment()
                } : val)
            })

            // notify
            Notification("success", "ระบบ", "ส่งคำขอลบกลุ่มวิดีโอคอร์สสำเร็จ")

            // in future open log to admin approve 
            // ...
        } else { // delete manually
            tempResultChapter = resultChapter.filter(val => props.courseChapterId !== val.courseChapterId)

            // notify
            Notification("success", "ระบบ", "ลบกลุ่มวิดีโอคอร์สสำเร็จ")
        }

        // reducer
        setReducer(tempResultChapter, "video")
        // console.log("tempResultChapter : ", tempResultChapter)

        let tmpResultVideo = resultVideoList.map((val, index) => val.courseChapterId === props.courseChapterId ? {
            approvalStatusId: val.approvalStatusId,
            approvalStatusName: val.approvalStatusName,
            courseVideoId: val.courseVideoId,
            courseVideoImgLocation: val.courseVideoImgLocation,
            courseVideoLocation: val.courseVideoLocation,
            courseVideoTitleName: val.courseVideoTitleName,
            courseVideoDescriptionName: val.courseVideoDescriptionName,
            courseVideoDuration: val.courseVideoDuration,
            courseChapterId: null,
        } : val)

        // reducer
        setReducer(tmpResultVideo, "chapter")

        props.reload()

        // set default
        setDefaultChapter()
    }

    const setReducer = (data, type) => {
        // reducer
        if (type === "video") {
            dispatch(setCourseManageVideoListStateToSuccess(data))
        } else if (type === "chapter") {
            dispatch(setCourseManageChapterStateToSuccess(data))
        }
    }

    const onCancelRemoveChapter = async () => {
        let approvalStatusName = "ยกเลิกการลบกลุ่มวิดีโอ กลับมาใช้งานเหมือนเดิม"
        let approvalStatusId = 1

        let tempResultChapter = resultChapter.map(val => {
            return (val.courseChapterId === props.courseChapterId ? {
                approvalStatusName,
                approvalStatusId,
                courseChapterId: props.courseChapterId,
                chapterCategoryId: props.chapterCategoryUsed?.id,
                chapterCategoryName: props.chapterCategoryUsed?.name,
                courseChapterTitleName: props.courseChapterTitleName,
                courseChapterDescriptionName: props.courseChapterDescriptionName,
                isUpdate: true,
                createdAt: props.createdAt,
                updatedAt: moment()
            } : val)
        })
        setReducer(tempResultChapter, "video")
        Notification("success", "ระบบ", "ยกเลิกการลบกลุ่มวิดีโอสำเร็จ")
    }

    useEffect(() => {
        if (props.courseInspect?.inspectionCourseChapterId) {
            let courseChapter = props.courseInspect.courseChapter ? (props.courseInspect.courseChapter.formData.find(val => val.id === props.courseChapterId)) : null; // inspectionCourseApprovalId
            setInspectChapter({
                inspectionCourseId: props.courseInspect.inspectionCourseId,
                inspectionCourseApprovalId: courseChapter ? courseChapter.inspectionCourseApprovalId : null
            })
        }
    }, [])

    const genBtnEditChapter = () => {
        return (
            <div
                style={{
                    backgroundColor: '#23B14D',
                    marginTop: 7,
                    padding: 4,
                    borderRadius: 20,
                    cursor: 'pointer',
                    width: 30,
                    marginLeft: 5,
                    float: 'right'
                }}
                onClick={() => {
                    formEditChapter.setFieldsValue({
                        courseChapterTitleName: props.courseChapterTitleName,
                        courseChapterDescriptionName: props.courseChapterDescriptionName
                    })

                    setModalEditChapter(true)
                }}
            >
                <center>
                    <FiEdit
                        size={16}
                        style={{ color: 'white', cursor: 'pointer', marginTop: -2 }}
                        disabled={
                            props.switchType === "adjust-course" ?
                                (props.isFormDisable ? true : (props.approvalStatusId === 15 ? true : false))
                                :
                                (props.switchType === "edit-course" ?
                                    (props.courseInspect ?
                                        (props.courseInspect.inspectionCourseId === 2 && props.courseInspect.inspectionCourseApprovalId === 2 ? true : false) : false) : false)
                        }
                    />
                </center>
            </div>
        )
    }

    const btnEdit = () => {
        return (
            <>
                {   /* cancel delete video */
                    props.switchType === "edit-course" &&
                        inspectChapter.inspectionCourseId === 3 &&
                        inspectChapter.inspectionCourseApprovalId === 2 &&
                        props.approvalStatusId === 15 ?
                        <Button
                            type="primary"
                            onClick={() => {
                                // alert("ยกเลิกลบวิดีโอ")
                                onCancelRemoveChapter()
                            }}
                        >
                            ยกเลิกการลบ
                        </Button>
                        :
                        <>
                            {props.switchType === "adjust-course" ?
                                <>
                                    {props.courseInspect.inspectionCourseChapterId === 2 ?
                                        <div style={{ backgroundColor: 'gray', padding: 4, borderRadius: 20, cursor: 'pointer', width: 30, marginLeft: 5, marginTop: 7, float: 'right' }}>
                                            <center><FiEdit size={16} style={{ color: 'white' }} /></center>
                                        </div>
                                        :
                                        (props.courseInspect ?
                                            (props.courseInspect.courseChapter.inspectionCourseApprovalIdSummary === 1 ?
                                                <div style={{ backgroundColor: 'gray', padding: 4, borderRadius: 20, cursor: 'pointer', width: 30, marginLeft: 5, marginTop: 7, float: 'right' }}>
                                                    <center><FiEdit size={16} style={{ color: 'white' }} /></center>
                                                </div>
                                                :
                                                <>
                                                    {props.inspectCourseApprovalId === 1 ?
                                                        <div style={{ backgroundColor: 'gray', padding: 4, borderRadius: 20, cursor: 'pointer', width: 30, marginLeft: 5, marginTop: 7, float: 'right' }}>
                                                            <center><FiEdit size={16} style={{ color: 'white' }} /></center>
                                                        </div>
                                                        :
                                                        <>{genBtnEditChapter()}</>
                                                    }
                                                </>
                                            )
                                            :
                                            false
                                        )
                                    }
                                </>
                                :
                                (props.switchType === "edit-course" ?
                                    (props.courseInspect ?
                                        (props.courseInspect.inspectionCourseId !== 2 && props.courseInspect.inspectionCourseApprovalId !== 2 ?
                                            <>{genBtnEditChapter()}</>
                                            :
                                            ((props.approvalStatusId === 1 || props.approvalStatusId === 4) && props.courseInspect.inspectionCourseId === 3 ?
                                                <>{genBtnEditChapter()}</>
                                                :
                                                (!props.courseInspect.inspectCourseApprovalId && !props.courseInspect.courseChapter.inspectionCourseAdjustIdSummary && props.courseInspect.inspectionCourseId === 2 ?
                                                    <>{genBtnEditChapter()}</>
                                                    :
                                                    (props.courseInspect.inspectionCourseId === 3 ?
                                                        <>{genBtnEditChapter()}</>
                                                        :
                                                        <div style={{ backgroundColor: 'gray', padding: 4, borderRadius: 20, cursor: 'pointer', width: 30, marginLeft: 5, float: 'right' }}>
                                                            <center>
                                                                <FiEdit size={16} style={{ color: 'white' }} />
                                                            </center>
                                                        </div>
                                                    )
                                                )
                                            )
                                        )
                                        :
                                        <>{genBtnEditChapter()}</>
                                    )
                                    :
                                    <>{genBtnEditChapter()}</>
                                )
                            }
                        </>
                }
            </>
        )
    }

    const genChapterApprovalDisplay = (approvalId, approvalName) => {
        return (
            <p
                style={
                    approvalId === 1 ? { color: "green" }
                        : (approvalId === 13 || approvalId === 14 ? { color: "orange" } : { color: "red" })
                }
            >
                {approvalName}
            </p>
        )
    }

    return (
        <div
            style={{
                paddingLeft: 5,
                maxHeight: 44
            }}
        >
            <Row>
                <Col span={4}>
                    <GiHamburgerMenu />
                    <div>{props.chapterCategoryName} {props.index + 1}</div>
                </Col>

                <Col span={16}>
                    <p>{props.courseChapterTitleName}</p>
                    {(props.switchType === "adjust-course" || props.switchType === "edit-course") ?
                        <>
                            {props.inspectCourseApprovalId ?
                                genChapterApprovalDisplay(props.inspectCourseApprovalId, props.inspectCourseApprovalName)
                                :
                                genChapterApprovalDisplay(props.approvalStatusId, props.approvalStatusName)
                            }
                        </>
                        :
                        genChapterApprovalDisplay(props.approvalStatusId, props.approvalStatusName)
                    }
                </Col>

                <Col span={4}>
                    {btnEdit()}
                </Col>
            </Row>

            {/* Start modal edit chapter */}
            <Modal
                title={"เเก้ไขกลุ่มวิดีโอ"}
                visible={modalEditChapter}
                width={width > 992 ? 650 : ((width <= 992 && width >= 576) ? "80%" : "100%")}
                maskClosable={false}
                onCancel={() => {
                    // set default
                    setDefaultChapter();
                }}
                footer={[
                    <Button
                        key="cancel"
                        style={width > 480 ? { width: 140 } : { width: "100%" }}
                        onClick={() => {
                            // set default
                            setDefaultChapter();
                        }}
                        disabled={props.approvalStatusId === 15 ? true : false}
                    >
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <Icon icon="mdi:cancel" />
                            <label style={{ paddingLeft: 10, cursor: "pointer" }}>ยกเลิก</label>
                        </div>
                    </Button>,

                    <Button
                        key="save"
                        style={width > 480 ? { width: 140 } : { width: "100%", margin: 0, marginTop: 8 }}
                        type="primary"
                        onClick={() => formEditChapter.submit()}
                        disabled={props.approvalStatusId === 15 ? true : false}
                    >
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <Icon icon="bx:edit-alt" />
                            <label style={{ paddingLeft: 10, cursor: "pointer" }}>บันทึก</label>
                        </div>
                    </Button>,

                    <Popconfirm
                        title="คุณต้องการลบกลุ่มวิดีโอหรือไม่ ?"
                        okText={<span style={{ width: 50 }}>ใช่</span>}
                        onConfirm={() => onRemoveChapter()}
                        cancelText={<span style={{ width: 50 }}>ไม่ใช่</span>}
                        disabled={props.approvalStatusId === 15 ? true : false}
                    >
                        <Button
                            key="delete"
                            danger
                            type="primary"
                            style={props.approvalStatusId === 15 ?
                                {
                                    marginLeft: width > 480 ? 8 : 0,
                                    width: width > 480 ? 140 : "100%",
                                    marginTop: width > 480 ? 0 : 8
                                }
                                :
                                {
                                    marginLeft: width > 480 ? 8 : 0,
                                    width: width > 480 ? 140 : "100%",
                                    marginTop: width > 480 ? 0 : 8,
                                }
                            }
                            disabled={props.approvalStatusId === 15 ? true : false}
                        >
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <Icon icon="fluent:delete-24-regular" style={{ color: "white" }} />
                                <label style={{ paddingLeft: 10, cursor: "pointer" }}>ลบกลุ่มวิดีโอ</label>
                            </div>
                        </Button>
                    </Popconfirm>
                ]}
            >
                <Form layout="vertical" form={formEditChapter} onFinish={onFinishEditChapter}>
                    <Row gutter={[24, 0]}>
                        <Col xs={24} lg={12}>
                            <Form.Item
                                name="courseChapterTitleName"
                                label={
                                    <>
                                        <span>ชื่อกลุ่มวิดีโอ</span>
                                        <span style={{ paddingLeft: 8 }}> (จำกัดตัวอักษร 200 ตัว)</span>
                                    </>
                                }
                                rules={[{ required: true, message: 'กรุณากรอกกลุ่มวิดีโอในคอร์ส' }]}
                            >
                                <Input maxLength={200} />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                name="courseChapterDescriptionName"
                                label={
                                    <>
                                        <span>รายละเอียดกลุ่มวิดีโอ</span>
                                        <span style={{ paddingLeft: 8 }}> (จำกัดตัวอักษร 500 ตัว)</span>
                                    </>
                                }
                                rules={[{ required: true, message: 'กรุณากรอกรายละเอียดกลุ่มวิดีโอในคอร์ส' }]}
                            >
                                <TextArea
                                    showCount
                                    maxLength={500}
                                    autoSize={{ minRows: 5, maxRows: 5 }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
            {/* End modal add chapter */}
        </div>
    )
}
