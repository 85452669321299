/* eslint-disable array-callback-return */

import { useRef, useState } from "react"
import parse from 'html-react-parser'
import { useNavigate } from 'react-router-dom'
import { Tabs, Button } from 'antd'
import { Icon } from '@iconify/react'
import { useDimensions } from "../../../../common/mamager/ScreenManager"
import CourseDescript from "../descript"
import CourseDocument from "../document"
import ChatItem from "../../../../common/components/chat/ChatItem"
import { detectSignIn } from "../../../../common/mamager/DetectManager"

const { TabPane } = Tabs

export default function CourseProperty(props) {
    let queryRoom
    const navigate = useNavigate()

    const { width } = useDimensions()
    const userRef = useRef(props.user ? props.user.uid : null)

    const [toggleTab, setToggleTab] = useState(null)

    const [roomId, setRoomId] = useState(null)

    const handleChatReminder = async (clickTab, db) => {
        if (userRef.current) {
            queryRoom = db.collection("chat_room")
            queryRoom = queryRoom.where("chatRoomHeaderId", "==", props.courseOwner)
            queryRoom = queryRoom.where("chatRoomBuilderId", "==", props.user.uid)
            queryRoom = queryRoom.where("chatCourseId", "==", String(props.courseId))
            queryRoom = queryRoom.onSnapshot((querySnapshot) => {
                const dataRoom = querySnapshot.docs.map((doc) => ({
                    ...doc.data(),
                    id: doc.id,
                }))

                setRoomId(dataRoom[0].chatRoomId)
            })
        }
    }

    async function callback(key) {

        // console.log('toggleTab : ', Number(key))
        localStorage.setItem('toggleTabChat', Number(key))

        setToggleTab(Number(key))

        const isLogin = await detectSignIn()
        if (!isLogin) {
            handleChatReminder(true, props.firebase.firestore())
        }
    }

    return (
        <div>
            <Tabs
                onChange={callback}
                // type="line"
                tabPosition={"top"}
                style={{ backgroundColor: 'white', width: '100%' }}
            >
                {props.pageName === "classroom-course-study" ?
                    <TabPane
                        tab={
                            (width > 480 ?
                                <div style={{ textAlign: 'center' }}>รายละเอียดวิดีโอ</div>
                                :
                                <div style={{ textAlign: 'center', width: 70 }}>
                                    <Icon icon="fluent:video-chat-16-regular" style={{ color: "#23b14d", width: 30, height: 30 }} />
                                </div>
                            )
                        }
                        key="0"
                    >
                        <div style={{ height: 600 }}>
                            <p style={{ paddingLeft: 25 }}>{props.videoCourse ? parse(props.videoCourse.courseVideoDescriptionName) : null}</p>
                        </div>
                    </TabPane>
                    : []
                }

                <TabPane
                    tab={
                        (width > 480 ?
                            <div style={{ textAlign: 'center' }}>รายละเอียดคอร์ส</div>
                            :
                            <div style={{ textAlign: 'center', width: (props.pageName === "course-shop" ? 100 : 70) }}>
                                <Icon icon="fluent:text-description-24-filled" style={{ color: "#23b14d", width: 30, height: 30 }} />
                            </div>
                        )
                    }
                    key="1"
                >
                    <CourseDescript course={props.course} />
                </TabPane>

                <TabPane
                    tab={
                        (width > 480 ?
                            <div style={{
                                textAlign: 'center',
                                // paddingLeft: 5, 
                                // paddingRight: 5 
                            }}>เอกสารเพิ่มเติม</div>
                            :
                            <div style={{ textAlign: 'center', width: (props.pageName === "course-shop" ? 100 : 70) }}>
                                <Icon icon="fluent:document-arrow-down-20-regular" style={{ color: "#23b14d", width: 30, height: 30 }} />
                            </div>
                        )
                    }
                    key="2"
                >
                    <CourseDocument coureDocumentation={props.coureDocumentation} />
                </TabPane>

                <TabPane
                    tab={
                        (width > 480 ?
                            <div style={{
                                textAlign: 'center',
                                // paddingLeft: 5, 
                                // paddingRight: 5 
                            }}>เเชท</div>
                            :
                            <div style={{ textAlign: 'center', width: (props.pageName === "course-shop" ? 100 : 70) }}>
                                <Icon icon="ic:outline-mark-unread-chat-alt" style={{ color: "#23b14d", width: 30, height: 30 }} />
                            </div>
                        )
                    }
                    key="3"
                >
                    <div style={{ height: 600 }}>
                        {userRef.current !== null ?
                            <>
                                {userRef.current === props.courseOwner ?
                                    <center style={{ paddingTop: 25 }}>
                                        <Button
                                            type="primary"
                                            onClick={() => {
                                                localStorage.setItem('toggleTabTeacher', 1) // off snap

                                                // ให้รับค่าไว้ที่ตัวเเปร เเล้วค่อยส่งไปเเบบนี้ ห้ามเปลี่ยน code นะครับ เพราะลองส่งไปเเบบลดรูปเเล้วมันเป็น null
                                                let courseId = props.courseId ? props.courseId : ""
                                                let courseOwner = props.courseOwner ? props.courseOwner : ""
                                                let toMember = props.user ? props.user.uid : ""

                                                navigate("/chat-teacher" +
                                                    '?course-id=' + courseId +
                                                    '&course-owner=' + courseOwner +
                                                    '&to-member=' + toMember
                                                )
                                            }}
                                        >
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <Icon icon="bi:chat-left-dots" style={{ color: "white", width: 17, height: 17 }} />
                                                <label style={{ paddingLeft: 10, cursor: "pointer" }}>ไปหน้าเเชท</label>
                                            </div>
                                        </Button>
                                    </center>
                                    :
                                    <div style={{ paddingRight: 15 }}>
                                        <ChatItem
                                            db={props.firebase.firestore()}
                                            learnerId={props.user.uid}
                                            teacherId={props.courseOwner}
                                            courseId={props.courseId}
                                            chatType="learner"
                                            roomId={roomId}
                                            toggleTab={toggleTab}
                                        />
                                    </div>
                                }
                            </>
                            :
                            <div style={{ padding: 50 }}>
                                <center>
                                    <span>กรุณาเข้าสู่ระบบ !!! </span>
                                </center>
                            </div>
                        }
                    </div>
                </TabPane>
            </Tabs >
        </div>
    )
}
