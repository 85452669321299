/* eslint-disable react-hooks/exhaustive-deps */

import { useState, useEffect } from 'react'
import {
    Table,
    Row,
    Col,
    Button,
    Form,
    Input,
    Select,
    Radio,
    DatePicker,
} from 'antd'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import { getInspectionCourseCategoryFetch, getInspectRequestEditCourseAllFetch } from '../../API'

const { Option } = Select
const { RangePicker } = DatePicker

const columns = [
    {
        title: 'No.',
        dataIndex: 'index',
        width: "7%",
    },
    {
        title: 'ชื่อคอร์ส',
        dataIndex: 'courseTitleName',
        width: "20%",
    },
    {
        title: 'ชื่อผู้สอน',
        dataIndex: 'teacherName',
        width: "20%",
    },
    {
        title: 'จำนวนตีกลับ',
        dataIndex: 'amountEdit',
        width: "15%",
    },
    {
        title: 'วันที่สร้าง',
        dataIndex: 'createdAt',
        width: "10%",
    },
    {
        title: 'วันที่เเก้ไข',
        dataIndex: 'updatedAt',
        width: "10%",
    },
    {
        title: 'สถานะตรวจสอบ',
        dataIndex: 'inspectionCourse',
        align: "center",
        width: "15%",
    },
]

const formatTime = "HH:mm"
const formatDateTime = "YYYY-MM-DD HH:mm"

export default function InspectRequestEditCourse(props) {
    const language = "th"
    const [form] = Form.useForm()

    const [list, setList] = useState([])
    const [loading, setLoading] = useState(false) // true

    const navigate = useNavigate()

    const [inspectionCourseCategory, setInspectionCourseCategory] = useState([])
    const [pageCurrent, setPageCurrent] = useState(1)

    const onFinish = async (value) => {
        let courseName = value.courseName ? value.courseName : null
        let startedDate = value.dateRange ? moment(value.dateRange[0]).format(formatDateTime) : null
        let endDate = value.dateRange ? moment(value.dateRange[1]).format(formatDateTime) : null
        let inspectionCourse = value.inspectionCourse ? value.inspectionCourse : null
        let dateType = value.dateType ? value.dateType : null

        let request = { courseName, startedDate, endDate, inspectionCourse, dateType }

        if (!courseName && !startedDate && !endDate && !inspectionCourse) {
            getInspectRequestEditCourseAllApi("allData", {
                courseName: null,
                startedDate: null,
                endDate: null,
                inspectionCourse: null,
                dateType,
                language
            })
        } else {
            getInspectRequestEditCourseAllApi("bySearch", request)
        }
    }

    const onPagine = (n) => {
        setPageCurrent(n.current)
    }

    const getInspectRequestEditCourseAllApi = async (type, value) => {
        const result = await getInspectRequestEditCourseAllFetch(type, value) // (values, language);
        // console.log("getInspectRequestEditCourseAllFetch : ", result)
        let genData = []
        for (let i = 0; i < result.length; i++) {
            genData.push({
                index: (i + 1),
                courseTitleName: result[i].courseTitleName,
                teacherName: result[i].user?.userName,
                amountEdit: result[i].inspectionRequestEditCourse.amountEdit,
                createdAt: moment(result[i].createdAt).format(formatDateTime),
                updatedAt: moment(result[i].updatedAt).format(formatDateTime),
                inspectionCourse: genButtonInspectionCourse(result[i]),
            })
        }
        setList(genData)
    }

    const genButtonInspectionCourse = (value) => {
        return (
            <Button
                type="primary"
                onClick={() => {
                    navigate(
                        "/erp-inspect-course",
                        {
                            state: {
                                inspectionCourseId: value.inspectionRequestEditCourse.inspectionCourseCategoryId,
                                inspectionCourseName: value.inspectionRequestEditCourse.inspectionCourseCategoryName,
                                courseId: value.courseId,
                                uid: value.uid,
                                createdAt: moment(value.createdAt).format(formatDateTime),
                                updatedAt: moment(value.updatedAt).format(formatDateTime),
                                amountEdit: value.inspectionRequestEditCourse.amountEdit,
                                courseTitleName: value.courseTitleName,
                                user: value.user,           // teacher
                                userType: "admin-inspect-edit-course",                  // teacher-adjust-course, admin-inspect-edit-course
                                adminLogin: props.adminLogin
                            }
                        }
                    )
                }}
            >{value.inspectionRequestEditCourse.inspectionCourseCategoryName}</Button>
        )
    }

    const getInspectionCourseCategory = async () => {
        const result = await getInspectionCourseCategoryFetch(language)
        setInspectionCourseCategory(result)
    }

    useEffect(() => {
        setLoading(true)

        getInspectRequestEditCourseAllApi("allData", {
            courseName: null,
            startedDate: null,
            endDate: null,
            inspectionCourse: null,
            dateType: 2,
            language
        })

        setLoading(false)
    }, [])

    return (
        <Row>
            <Col span={4}>
                <label>ตรวจสอบคำเเก้ไขคอร์ส</label>
            </Col>
            <Col span={20} style={{ paddingBottom: 20 }}>
                <Form
                    layout="vertical"
                    form={form}
                    onFinish={onFinish}
                >
                    <Row>
                        <Col xs={12} lg={5}>
                            <Form.Item name="inspectionCourse" >
                                <Select
                                    showSearch
                                    style={{ width: '90%' }}
                                    placeholder="สถานะตรวจสอบ"
                                    optionFilterProp="children"
                                    allowClear
                                    onClick={() => getInspectionCourseCategory()}
                                >
                                    {inspectionCourseCategory.map((val, index) =>
                                        <>
                                            {val.value !== 1 ?
                                                <Option key={index} value={val.value}>{val.label}</Option>
                                                : []}
                                        </>
                                    )}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={12} lg={7}>
                            <Form.Item name="dateRange" >
                                <RangePicker
                                    style={{ width: '90%' }}
                                    showTime={{ format: formatTime }}
                                    format={formatDateTime}
                                />
                            </Form.Item>

                            <Form.Item label="ค้นหาจาก" name="dateType" style={{ marginTop: -15, marginBottom: 5 }}>
                                <Radio.Group>
                                    <Radio value={1}>วันที่สร้าง</Radio>
                                    <Radio value={2}>วันที่เเก้ไข</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        <Col xs={12} lg={10}>
                            <Form.Item name="courseName" >
                                <Input
                                    style={{ width: '100%' }}
                                    placeholder="ชื่อคอร์ส"
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={12} lg={2}>
                            <Button
                                type="primary"
                                onClick={() => form.submit()}
                            >
                                ค้นหา
                            </Button>
                        </Col>
                    </Row>
                </Form>

            </Col>
            <Col span={24}>
                <Table
                    loading={loading}
                    columns={columns}
                    dataSource={list}
                    pagination={{
                        current: pageCurrent,
                        pageSize: 10,
                        total: list.length
                    }}
                    onChange={(n) => onPagine(n)}
                >
                </Table>
            </Col>
        </Row>
    )
}
