/* eslint-disable no-const-assign */
/* eslint-disable react-hooks/exhaustive-deps */

import { useState, useEffect, useRef } from "react"
import {
    Layout,
    Row,
    Col,
    Button,
    Progress,
    Popover,
    Modal,
    Form,
    Radio,
    Image,
    Spin,
} from 'antd'
import moment from 'moment'

import firebase from 'firebase/app'

import { useNavigate } from 'react-router-dom'

import { PDFDownloadLink } from "@react-pdf/renderer"
// import ReactPDF from '@react-pdf/renderer'
import { pdf } from "@react-pdf/renderer"
import Template from "./../../common/components/certificate/components/Template"
import { saveAs } from "file-saver"

import { useLocation } from "react-router-dom"
import { AiOutlineQuestionCircle } from "react-icons/ai"
import { Icon } from '@iconify/react'
import { BsInfoCircle } from "react-icons/bs"
import { useDimensions } from "./../../common/mamager/ScreenManager"
import { Timer } from "./../../common/components/video/components/timer"
import CourseOverview from "./../../modules/course/components/overview"
import CourseCRL from "./../../common/components/crl"
import CourseVideo from "./../../modules/course/components/video"
import Header from "./../../common/components/header"
import VideoStudy from "./components/video"
import CourseProperty from "./../../modules/course/components/property"
import Footer from "./../../common/components/footer"
import LoadingPage from "./../../common/components/loading/page"
import { getAccountVerifyByIdFetch } from "./../../modules/account/API"
import {
    getCourseDocumentationFetch,
    getCourseByIdFetch,
    getCourseOwnerFetch,
    getVideoCourseListByLearnerIdFetch
} from "./../../modules/course/API"
import {
    getCourseCertificateByIdFetch,
    getThemeCertificateAllFetch,
    updateCertificateApprovalCategoryByIdFetch,
    updateCourseCertificateDownloadFirstTimeFetch,
    updateThemeCourseCertificateByIdFetch
} from "./../../common/components/certificate/API"
import {
    getCoursePurchaseDetectedFetch,
    getPercentWatchVideoCourseFetch,
    updateLearnerCourseByUserIdFetch,
    updatePercentWatchVideoCourseFetch
} from "./../../modules/classroom/API"
import { calculateRemainingPeriodStudyCourse } from "./../../common/mamager/TimeManager"
import { Notification } from "./../../common/components/notification"

const { Content } = Layout

const formatDateTime = "DD/MM/YYYY HH:mm"

export default function ClassroomStudy(props) {

    const navigate = useNavigate()
    const language = "th"

    const [form] = Form.useForm()

    const location = useLocation()
    const params = new URLSearchParams(location.search)
    const courseId = params.get("course-id")

    const { width } = useDimensions()

    const [course, setCourse] = useState([])
    const [courseOwner, setCourseOwner] = useState([])
    const [coureVideoGroup, setCourseVideoGroup] = useState([])
    const [coureDocumentation, setCourseDocumentation] = useState([])
    const [videoCourseCurrent, setVideoCourseCurrent] = useState([])

    const [loading, setLoading] = useState(true)
    const [loadingSaveThemeCertificate, setLoadingSaveThemeCertificate] = useState(false)
    const [loadingPDFDownload, setLoadingPDFDownload] = useState(false)

    const isPercent25DoneOnceRef = useRef(false)
    const isPercent50DoneOnceRef = useRef(false)
    const isPercent80DoneOnceRef = useRef(false)

    const [visitPercent, setVisitPercent] = useState(null)

    const [isModalThemeCertificate, setIsModalThemeCertificate] = useState(false)
    const [isModalCertificateDownload, setIsModalCertificateDownload] = useState(false)
    const [isModalExpiredCourse, setIsModalExpiredCourse] = useState(false)

    const [selectThemeCertificate, setSelectThemeCertificate] = useState(undefined)
    const [themeCertificate, setThemeCertificate] = useState([])
    const [courseCertificate, setCourseCertificate] = useState([])

    const [accountVerify, setAccountVerify] = useState(null)

    const [timeLeft, setTimeLeft] = useState({
        isExpire: false
    })

    const [coursePurchase, setCoursePurchase] = useState(null)

    const [modal, setModal] = useState({
        isShow: false,
        title: null
    })

    const handleThemeCertificate = () => setIsModalThemeCertificate(!isModalThemeCertificate)
    const handleCertificateDownload = () => setIsModalCertificateDownload(!isModalCertificateDownload)
    const handleExpiredCourse = () => setIsModalExpiredCourse(!isModalExpiredCourse)

    const detectVerify = async (uid) => {
        const result = await getAccountVerifyByIdFetch(uid)
        setAccountVerify(result)
        if (!result.isAccountVerifiedIdentify) {
            setModal({
                isShow: true,
                title: "ยังไม่ได้ยืนยันตัวตนด้วย" + (result.businessOperationId === 1 ? "บัตรประชาชน" : "หนังสือรับรอง หรือใบสําคัญแสดงการจดทะเบียนห้างหุ้นส่วนบริษัท")
            })
        }
        return result
    }

    const getCourseApi = async () => {
        let result = await getCourseByIdFetch(courseId, 'th')
        if (result) {
            setCourse(result)
            return result.uid
        } else {
            return null
        }
    }

    // is default percent visit 
    const getRequestApi = async (isDefaultPercentWatch) => {
        setLoading(true)

        // Course
        const teacherId = await getCourseApi()

        // Owner of course
        let ownerCourse = await getCourseOwnerFetch(teacherId)
        setCourseOwner(ownerCourse)

        // Video course
        await getVideoCourseListByLearnerId(
            true, // isCurrent
            isDefaultPercentWatch
        )

        // course document
        let resultCourseDocument = await getCourseDocumentationFetch(courseId)
        setCourseDocumentation(resultCourseDocument)

        // theme certificate
        const resultThemeCertificate = await getThemeCertificateAllFetch()
        setThemeCertificate(resultThemeCertificate)

        // certificate
        let objCourseCertificate = {
            uid: props.user?.uid,
            courseId: courseId,
            isActive: 1
        }
        const resultCourseCertificate = await getCourseCertificateByIdFetch(objCourseCertificate)
        setSelectThemeCertificate(resultCourseCertificate.themeCertificateId)
        setCourseCertificate(resultCourseCertificate)

        // update visit course classroom
        await updateLearnerCourseByUserIdFetch({
            uid: props.user?.uid,
            courseId: Number(courseId)
        })

        setLoading(false)
    }

    const getVideoCourseListByLearnerId = async (isCurrent, isDefaultPercentWatch) => {
        // Video course
        let obj = {
            courseId,
            learnerId: props.user?.uid,
            language
        }
        let result = await getVideoCourseListByLearnerIdFetch(obj)

        if (result) {
            setCourseVideoGroup(result)

            let tmpVideoCurrent = null
            if (result?.chapter?.length > 0) {
                tmpVideoCurrent = result?.chapter[0]?.videoList[0] ?? 0
            } else {
                tmpVideoCurrent = result?.video[0] ?? 0
            }

            if (isCurrent) { // open set video current
                setVideoCourseCurrent(tmpVideoCurrent)
            }

            // load percent watching video
            if (isDefaultPercentWatch) {
                let timer = await Timer(
                    0,                                              // เวลาของการเข้าชมวิดีโอ ที่จะเพิ่ม
                    false,                                          // 
                    Number(courseId),                               // id ของคอร์สที่เข้าเรียน
                    props.user?.uid,                                // uid ของผู้เข้าชมวิดีโอ
                    tmpVideoCurrent?.courseVideoId,                 // id ของวิดีโอที่เข้าชม
                    false,                                          // เรียกใช้ฟังก์ชัน isHandlePercentWatchVideoCourse หรือไม่ (true: ใช่, false: ไม่ใช่)
                    null                                            // props
                )
                setVisitPercent(timer ? timer.percentWatch : null)
            }
        }
    }

    const handleVideoCourseCurrent = (value) => {
        setVideoCourseCurrent(value)
    }

    const handlePercentWatchVideoCourse = async (timeVideoCourse, timerWatchVideoCourse, percent, isFirstLoadApi, VideoCourseList, timerWatchVideoCourseList) => {
        if (!isFirstLoadApi) { // not rirst load api
            if (parseFloat(percent.toFixed(1)) >= 25 && parseFloat(percent.toFixed(1)) <= 30) {
                if (!isPercent25DoneOnceRef.current) {
                    let percentTimerVideoCourse = await getPercentWatchVideoCourse()
                    if (!percentTimerVideoCourse.is25PercentWatchVideoCourse) {
                        await updatePercentWatchVideoCourse(percentTimerVideoCourse.learnerCourseId, 25)
                        Notification("info", "ว้าว 25% เเล้ว", "ตอนนี้คุณได้ดูวิดีโอคอร์สเเล้ว 25% ของเวลาทั้งหมดในคอร์ส ใช้ได้เลยทีเดียว เรียนกันต่อเลย")
                    }
                    isPercent25DoneOnceRef.current = true
                }
            } else if (parseFloat(percent.toFixed(1)) >= 50 && parseFloat(percent.toFixed(1)) <= 55) {

                if (!isPercent50DoneOnceRef.current) {
                    let percentTimerVideoCourse = await getPercentWatchVideoCourse()
                    if (!percentTimerVideoCourse.is50PercentWatchVideoCourse) {
                        await updatePercentWatchVideoCourse(percentTimerVideoCourse.learnerCourseId, 50)
                        Notification("info", "ว้าว 50% เเล้ว", "ตอนนี้คุณได้ดูวิดีโอคอร์สเเล้ว 50% ของเวลาทั้งหมดในคอร์ส ยอดเยี่ยมมาก เรียนกันต่อเลย")
                    }
                    isPercent50DoneOnceRef.current = true
                }
            } else if (parseFloat(percent.toFixed(1)) >= 80 && parseFloat(percent.toFixed(1)) <= 85) {

                if (!isPercent80DoneOnceRef.current) {
                    let percentTimerVideoCourse = await getPercentWatchVideoCourse()
                    if (!percentTimerVideoCourse.is80PercentWatchVideoCourse) {
                        await updatePercentWatchVideoCourse(percentTimerVideoCourse.learnerCourseId, 80)
                        Notification("info", "ว้าว 80% เเล้ว", "ตอนนี้คุณได้ดูวิดีโอคอร์สเเล้ว 80% ของเวลาทั้งหมดในคอร์ส ยอดเยี่ยมที่สุด เรียนกันต่อเลย")

                        // update 
                        let obj = {
                            uid: props.user?.uid,
                            courseId: courseId,
                            isActive: 1,
                            certificateApprovalCategoryId: 1 // pass
                        }
                        await updateCertificateApprovalCategoryByIdFetch(obj)
                    }
                    isPercent80DoneOnceRef.current = true
                }
            }
        }

        detectExpiredCourse(null, false)

        await getVideoCourseListByLearnerId(false, false)

        setVisitPercent(percent)
    }

    const getPercentWatchVideoCourse = async () => {
        let requestIsPercentTimerVideoCourse = {
            courseId: Number(courseId),
            uid: props.user?.uid,
        }
        const resultIsPercentTimerVideoCourse = await getPercentWatchVideoCourseFetch(requestIsPercentTimerVideoCourse)
        return resultIsPercentTimerVideoCourse
    }

    const updatePercentWatchVideoCourse = async (learnerCourseId, typePercent) => {
        let requestIsPercentTimerVideoCourse = {
            isPercentWatchVideoCourse: 1,
            learnerCourseId,
            typePercent
        }
        await updatePercentWatchVideoCourseFetch(requestIsPercentTimerVideoCourse)
    }

    const saveThemeCertificate = async () => {
        setLoadingSaveThemeCertificate(true)

        let obj = {
            uid: props.user?.uid,
            courseId: courseId,
            isActive: 1,
            themeCertificateId: selectThemeCertificate
        }
        const resultThemeCourseCertificate = await updateThemeCourseCertificateByIdFetch(obj)

        let objCourseCertificateDownloadFirstTime = {
            courseCertificateDownloadFirstTime: moment(),
            uid: props.user?.uid,           // uid of learner
            courseId: courseId,             // courseId of teacher
            isActive: 1,
        }
        const resultCourseCertificateDownloadFirstTime = await updateCourseCertificateDownloadFirstTimeFetch(objCourseCertificateDownloadFirstTime)

        if (
            resultThemeCourseCertificate.isSuccess &&
            resultCourseCertificateDownloadFirstTime.isSuccess
        ) {
            Notification("success", "ระบบ", "บันทึกธีมใบประกาศ (Certificate) สำเร็จ")
        } else {
            Notification("error", "ระบบ", "บันทึกธีมใบประกาศ (Certificate) ไม่สำเร็จ")
        }

        await getRequestApi(
            false // is default percent visit 
        )
        handleThemeCertificate() // false
        handleCertificateDownload() // true

        setLoadingSaveThemeCertificate(false)
    }

    const genBtnDownloadCertificate = () => {
        return (
            <PDFDownloadLink
                document={
                    <Template
                        uid={props.user?.uid}
                        courseId={course.courseId}
                        course={course}
                    />
                }
                fileName={"Certificate-" + (courseOwner ? courseOwner.userName : "you") + "-(toflyn.com).pdf"}
            >
                {({ loading }) => (loading && visitPercent === null ?
                    <Button type="primary" disabled={true} style={{ width: 180 }} >กำลังตรวจสอบ ...</Button>
                    :
                    visitPercent === null ?
                        <Button type="primary" disabled={true} style={{ width: 180 }} >กำลังตรวจสอบ ...</Button>
                        :
                        <Button
                            type="primary"
                            disabled={parseFloat(visitPercent?.toFixed(1)) >= 80 ? false : true}
                            loading={loadingPDFDownload}
                            onClick={async () => {
                                setLoadingPDFDownload(true)

                                const doc = <Template uid={props.user?.uid} courseId={course.courseId} course={course} />
                                const asPdf = pdf([])
                                asPdf.updateContainer(doc)

                                await new Promise(resolve => setTimeout(resolve, 3000))

                                const blob = await asPdf.toBlob()
                                saveAs(blob, "Certificate-" + (courseOwner ? courseOwner.userName : "you") + "-(toflyn.com).pdf")

                                setLoadingPDFDownload(false)
                            }}
                            style={{ width: 180 }}
                        >
                            {!loadingPDFDownload ?
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Icon
                                        icon="teenyicons:certificate-outline"
                                        style={{ color: (parseFloat(visitPercent?.toFixed(1)) >= 80 ? "white" : "#CBCBCB"), width: 19, height: 19 }}
                                    />
                                    <label style={{ paddingLeft: 10, cursor: "pointer" }}>ดาวน์โหลดใบประกาศ</label>
                                </div>
                                : []
                            }
                        </Button>
                )}
            </PDFDownloadLink>
        )
    }

    const getCoursePurchaseDetected = async () => {
        let requestCoursePurchaseDetected = {
            courseId: courseId,
            learnerId: props.user?.uid,
            language
        }
        const result = await getCoursePurchaseDetectedFetch(requestCoursePurchaseDetected)
        if (!result) {
            navigate("/classroom-course")
        } else {
            setCoursePurchase(result)
            detectExpiredCourse(result, true)
        }
    }

    const detectExpiredCourse = (res, isParam) => {
        let endDate = isParam ? res.endLearnDate : coursePurchase?.endLearnDate
        let courseFormat = isParam ? res.courseFormatId : coursePurchase?.courseFormatId

        endDate = endDate ? endDate : null
        courseFormat = courseFormat ? courseFormat : null

        let data = calculateRemainingPeriodStudyCourse(new Date(), endDate)
        setTimeLeft(data)
        if (data) {
            if (data.isExpire && courseFormat !== 1) { // [courseFormat 1 is ตลอดชีวิต, isExpire true is หมดอายุเเล้ว]
                setIsModalExpiredCourse(true)
            }
        }
    }

    const reloadOderPayment = (data) => { }

    const genExpiredCourse = () => {
        return (
            <Row>
                <Col span={24}>
                    <label>คอร์สหมดอายุเเล้ว โปรดต่อสัญญาคอร์สเพื่อเข้าเรียน</label>
                </Col>

                <Col span={8}>
                    <label style={{ float: "right", paddingRight: 8 }}>รูปเเบบคอร์สเรียน</label>
                </Col>
                <Col span={16}>
                    :<label style={{ paddingLeft: 8 }}>{coursePurchase ? coursePurchase.courseFormatName : "-"}</label>
                </Col>

                <Col span={8}>
                    <label style={{ float: "right", paddingRight: 8 }}>วันเวลาเริ่มเรียน</label>
                </Col>
                <Col span={16}>
                    :<label style={{ paddingLeft: 8 }}>{coursePurchase?.startLearnDate ? moment(coursePurchase.startLearnDate).format(formatDateTime) : "-"}</label>
                </Col>

                <Col span={8}>
                    <label style={{ float: "right", paddingRight: 8 }}>วันเวลาหมดอายุ</label>
                </Col>
                <Col span={16}>
                    :<label style={{ paddingLeft: 8 }}>{coursePurchase?.endLearnDate ? moment(coursePurchase.endLearnDate).format(formatDateTime) : "-"}</label>
                </Col>

                <Col span={24} style={{ borderTop: "2px solid #23B14D" }}>
                    <center style={{ paddingTop: 16 }}>
                        <Button
                            type="primary"
                            style={{ width: 150 }}
                            onClick={() => {
                                navigate("/payment?course-id=" + courseId)
                            }}
                        >
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <Icon icon="clarity:contract-line" style={{ cursor: "pointer", color: "white", width: 20, height: 20 }} />
                                <label style={{ paddingLeft: 8, cursor: "pointer" }}>ต่อสัญญาคอร์ส</label>
                            </div>
                        </Button>
                    </center>
                </Col>
            </Row>
        )
    }

    const genCourseOverview = () => {
        return (
            <CourseOverview
                course={course}
                video={[]}
                courseOwner={courseOwner}
                user={props.user}
                pageName="classroom-course-study"
            />
        )
    }

    const genCourseCRL = (color) => {
        return (
            <>
                {course?.learnerCount > 0 ?
                    <CourseCRL
                        learnerCount={course?.learnerCount}
                        pageName="classroom-course-study"
                        color={color}
                    />
                    : []
                }
            </>
        )
    }

    const genCourseVideoList = () => {
        return (
            <>
                {!timeLeft.isExpire || coursePurchase?.courseFormatId === 1 ? // [courseFormat 1 is ตลอดชีวิต, isExpire false is ยังไม่หมดอายุ]
                    <CourseVideo
                        coureVideoGroup={coureVideoGroup}
                        // visitPercent={visitPercent}
                        course={course}
                        reloadOderPayment={reloadOderPayment}
                        handleVideoCourseCurrent={handleVideoCourseCurrent}
                        pageName="classroom-course-study"
                    />
                    : []
                }
            </>
        )
    }

    const percentLearing = () => {
        return (
            <div style={{ backgroundColor: "#191919" }}>
                <Row style={{ paddingLeft: 25, paddingRight: 25, paddingTop: 13, paddingBottom: 13, borderTop: '1px solid #696969' }}>
                    <Col span={24} style={{ paddingTop: 13 }}>
                        <center>
                            <Progress
                                type="dashboard"
                                percent={visitPercent ? visitPercent : 0}
                                color={"green"}
                                format={percent =>
                                    <div style={{ color: 'white' }}>{visitPercent === null ?
                                        <Spin size="large" />
                                        :
                                        <>{parseFloat(percent.toFixed(1))} %</>}
                                    </div>
                                }
                                strokeColor={"#23B14D"}
                                trailColor={'#EEEEEE'}
                            />
                        </center>
                    </Col>

                    <Col span={24} style={{ color: 'white', paddingBottom: 13 }}>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <label>เปอร์เซนต์การเข้าชมวิดีโอ</label>
                            <Popover content={<label style={{ width: 270 }}>การได้ certificate ต้องดูอย่างน้อย 80% ขึ้นไป</label>}>
                                <AiOutlineQuestionCircle style={{ marginLeft: 5, cursor: 'pointer' }} />
                            </Popover>
                        </div>
                    </Col>

                    {course?.courseCertificateCategoryId === 1 ?
                        <Col span={24} style={{ paddingBottom: 13 }}>
                            {courseCertificate.courseCertificateDownloadFirstTime ?
                                <center>{genBtnDownloadCertificate()}</center>
                                :
                                <center>
                                    {visitPercent === null ?
                                        <Button type="primary" disabled={true} style={{ width: 180 }} >กำลังตรวจสอบ ...</Button>
                                        :
                                        <Button
                                            loading={loading}
                                            type="primary"
                                            disabled={parseFloat(visitPercent?.toFixed(1)) >= 80 ? false : true}
                                            style={{ backgroundColor: (visitPercent?.toFixed(1)) >= 80 ? '' : '#E4E4E4' }}
                                            onClick={async () => {
                                                let uid = firebase.auth().currentUser.uid
                                                const accountVerify = await detectVerify(uid)
                                                if (accountVerify.isAccountVerifiedIdentify) {
                                                    form.setFieldsValue({ "themeCourseCertificate": selectThemeCertificate })

                                                    handleThemeCertificate() // true
                                                } else {

                                                }
                                            }}
                                        >
                                            {!loading ?
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <Icon icon="teenyicons:certificate-outline" style={{ color: (parseFloat(visitPercent?.toFixed(1)) >= 80 ? "white" : "#BEBEBE"), width: 19, height: 19 }} />
                                                    <label style={{ paddingLeft: 10, cursor: "pointer" }}>ดาวน์โหลดใบประกาศ</label>
                                                </div>
                                                : []
                                            }
                                        </Button>
                                    }
                                </center>
                            }
                        </Col>
                        : []
                    }
                </Row>
            </div>
        )
    }

    const genMenuRight = () => {
        return (
            <div>
                {genCourseOverview()}

                {genCourseCRL("#191919")}

                {percentLearing()}

                {genCourseVideoList()}

                {course?.courseCertificateCategoryId === 2 ? <div style={{ height: 46, backgroundColor: "#191919" }}></div> : []}
            </div>
        )
    }

    useEffect(() => {
        getCoursePurchaseDetected()
        getRequestApi(
            true // is default percent watching video 
        )
    }, [])

    useEffect(() => {

    }, [coureVideoGroup])

    return (
        <>
            <Header
                firebase={props.firebase}
                authorized={props.authorized}
                user={props.user}
            />

            <article>
                {!loading ?
                    <Content style={{ backgroundColor: "#F5F5F5" }}>
                        <div style={width >= 1500 ? { paddingLeft: '5%', paddingRight: '5%' } : { paddingLeft: '0%', paddingRight: '0%' }}>
                            <Row>
                                <Col xs={24} lg={16} style={{ backgroundColor: "#0c0c0c", borderBottom: "1px solid #EEEEEE" }}>

                                    {width < 992 ?
                                        <div style={{ float: "none", borderBottom: "1px solid rgb(80, 80, 80)" }}>
                                            {genCourseOverview()}
                                        </div>
                                        : []
                                    }

                                    {!timeLeft.isExpire || coursePurchase?.courseFormatId === 1 ? // [courseFormat 1 is ตลอดชีวิต, isExpire false is ยังไม่หมดอายุ]
                                        <>
                                            <Row>
                                                <Col span={24}>
                                                    <VideoStudy
                                                        user={props.user}
                                                        course={course}

                                                        videoCourse={videoCourseCurrent}
                                                        handlePercentWatchVideoCourse={handlePercentWatchVideoCourse}
                                                        timeLeft={timeLeft}
                                                        coursePurchase={coursePurchase}
                                                        coureVideoGroup={coureVideoGroup}
                                                        handleVideoCourseCurrent={handleVideoCourseCurrent}
                                                    />
                                                </Col>

                                                <Col span={24}>
                                                    {width < 992 ?
                                                        <>
                                                            {genCourseCRL("black")}
                                                            <div style={{ float: "none", borderBottom: "1px solid rgb(80, 80, 80)" }}>
                                                                {percentLearing()}
                                                            </div>
                                                        </>
                                                        : []
                                                    }
                                                </Col>
                                            </Row>

                                            <CourseProperty
                                                course={course}
                                                coureDocumentation={coureDocumentation}
                                                authorized={props.authorized}
                                                firebase={props.firebase}
                                                user={props.user}
                                                courseOwner={course.uid}
                                                courseId={course.courseId}
                                                videoCourse={videoCourseCurrent}
                                                pageName="classroom-course-study"
                                            />

                                            <div
                                                style={{
                                                    padding: 8,
                                                    borderTop: "1px solid #EEEEEE",
                                                    // borderBottom: "1px solid #EEEEEE",
                                                    backgroundColor: "white",
                                                    fontSize: 16
                                                }}
                                            >
                                                <label>ระยะเวลาเรียนคงเหลือ : {coursePurchase?.courseFormatId === 1 ? "ตลอดชีวิต" : timeLeft.display}</label>
                                            </div>
                                        </>
                                        :
                                        !isModalExpiredCourse ? <div style={{ padding: 24 }}>{genExpiredCourse()}</div> : []}
                                </Col>

                                {width >= 992 ?
                                    <Col xs={24} lg={8}>
                                        {genMenuRight()}
                                    </Col>
                                    : []
                                }
                            </Row>
                        </div>
                    </Content>
                    :
                    <LoadingPage loading={loading} />
                }
            </article>

            <Footer />

            <Modal
                maskClosable={false}
                title={
                    <strong>
                        <BsInfoCircle style={{ marginRight: 10, width: 22, height: 22, marginTop: -3, color: '#23B14D' }} />
                        <span className="topic-color-bold">{modal.title}</span>
                    </strong>
                }
                visible={modal.isShow}
                width={590}
                onCancel={() => {
                    // close modal
                    setModal({
                        isShow: false,
                        title: null,
                    })
                }}
                footer={[
                    <Button
                        type="primary"
                        onClick={() => {
                            navigate("/account?tab=verifyIdentity")
                        }}
                    >
                        ไปที่หน้ายืนยันตัวตน
                    </Button>
                ]}
            >
                {accountVerify?.businessOperationId === 1 ?
                    <>
                        <strong><p>เหตุใดต้องมีการยืนยันตัวตนด้วย บัตรประชาชน ?</p></strong>
                        <p>1. เพื่อใช้เป็นข้อมูลประกอบในการสร้างใบประกาศ (Certificate) ให้เเก่ผู้เรียน ในกรณีที่คอร์สนั้น ๆ มีใบประกาศให้ผู้เรียน หลังจากเรียนจบหลักสูตร</p>
                        <p>2. การยืนยันตัวตนนี้ จะขึ้นเเจ้งให้ยืนยันเมื่อมีการดาวน์โหลดใบประกาศครั้งเเรก เเละเมื่อยืนยันตัวตนผ่านเเล้ว ครั้งต่อไปที่มีการดาวน์โหลดใบประกาศอื่น ๆ จะใช้ข้อมูลของการยืนยันตัวตนด้วยบัตรประชาชนนี้ เพื่อออกใบประกาศ (Certificate)</p>
                    </>
                    : []}

                {accountVerify?.businessOperationId === 2 ?
                    <>
                        <strong><p>เหตุใดต้องมีการยืนยันตัวตนด้วยหนังสือรับรอง หรือใบสําคัญแสดงการจดทะเบียนห้างหุ้นส่วนบริษัท ?</p></strong>
                        <p>เพื่อใช้ตรวจสอบการมีอยู่จริงของห้างหุ้นส่วนบริษัท</p>
                    </>
                    : []}

                <strong><p>ท่านสามารถยืนยันตัวตนได้โดยไปที่</p></strong>
                <p>1. คลิกเเทบเมนูมุมขวาบน "บัญชีของฉัน"</p>
                <p>2. เลือกเมนู "ยืนยันตัวตน"</p>
                <p>3. เลือกขั้นตอนที่ 2 ยืนยันตัวตนด้วย ข้อมูลส่วนตัว</p>
                <p>4. กรอกข้อมูลในส่วนของบัตรประชาชน</p>
                <p>หรือคลิกที่ปุ่มด้านล่างนี้</p>
            </Modal>

            <Modal
                title={<label style={{ fontWeight: 100 }}>ธีมใบประกาศ (Certificate)</label>}
                loading={loading}
                visible={isModalThemeCertificate}
                onCancel={handleThemeCertificate}
                width={700}
                cancelButtonProps={{ hidden: true }}
                okButtonProps={{ hidden: true }}
                footer={[
                    <Button
                        loading={loadingSaveThemeCertificate}
                        type="primary"
                        onClick={() => {
                            saveThemeCertificate()
                        }}
                    >
                        {!loadingSaveThemeCertificate ?
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Icon icon="bx:save" style={{ color: "white", width: 18, height: 18 }} />
                                <label style={{ paddingLeft: 10, cursor: "pointer" }}>บันทึก</label>
                            </div>
                            : []
                        }
                    </Button>
                ]}
            >
                <Row gutter={[0, 8]}>
                    <Col span={24}>
                        <label>เลือกธีม Certificate</label>
                    </Col>
                    <Col span={24}>
                        <div
                            style={{
                                backgroundColor: "#f5f5f5",
                                height: 320,
                                width: '100%',
                                overflow: 'auto',
                                overflowX: 'hidden'
                            }}
                        >
                            <Radio.Group
                                style={{ width: '100%' }}
                                onChange={(e) => setSelectThemeCertificate(e.target.value)}
                                value={selectThemeCertificate}
                            >
                                <Row>
                                    {themeCertificate.map(val => {
                                        return (
                                            <Col span={8} style={{ height: 160, padding: 6 }}>
                                                <Radio value={val.themeCertificateId} style={{ paddingBottom: 8, alignItems: 'flex-start', display: 'flow' }} >
                                                    <label>{val.themeCertificateName}</label>
                                                    <Image
                                                        width={"100%"}
                                                        style={{ paddingTop: 5 }}
                                                        src="error"
                                                        preview={false}
                                                        fallback={`./assets/images/course-certificate/theme/${val.themeCertificateUrl}`}
                                                    />

                                                </Radio>
                                            </Col>
                                        )
                                    })}
                                </Row>
                            </Radio.Group>
                        </div>
                    </Col>
                    <Col span={24}>
                        <label style={{ paddingTop: 10 }}>หมายเหตุ : ผู้เรียนสามารถเลือกธีม Certificate ได้เพียงหนึ่งครั้ง โดยนับหลังจากกดดาวน์โหลด Certificate ไปเเล้ว ซึ่งจะยึดธีมที่ผู้เรียนเลือกนำไปใช้กับคอร์สนี้ตลอด</label>
                    </Col>
                </Row>
            </Modal>

            <Modal
                title={<label style={{ fontWeight: 100 }}>ดาวน์โหลดใบประกาศ (Certificate)</label>}
                visible={isModalCertificateDownload}
                onCancel={handleCertificateDownload}
                width={350}
                cancelButtonProps={{ hidden: true }}
                okButtonProps={{ hidden: true }}
            >
                <Row>
                    <Col span={24} style={{ display: 'flex', justifyContent: 'center', paddingBottom: 16 }}>
                        {genBtnDownloadCertificate()}
                    </Col>
                </Row>
            </Modal>

            <Modal
                title={"คอร์สหมดอายุเเล้ว"}
                maskClosable={false}
                visible={isModalExpiredCourse}
                onCancel={handleExpiredCourse}
                width={400}
                cancelButtonProps={{ hidden: true }}
                okButtonProps={{ hidden: true }}
                footer={false}
            >
                {genExpiredCourse()}
            </Modal>
        </>
    )
}