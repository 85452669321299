import firebase from '../config/firebase-config'

const socialMediaAuth = async (provider) => {
    return firebase.auth().signInWithPopup(provider).then((res) => {
        return res.user
    }).catch( async (err) => {
        // console.log("err   : ", err);
        return err
    }) 
}

export default socialMediaAuth