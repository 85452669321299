import { Avatar, Col, Rate, Row } from 'antd'

export default function ReviewItem(props) {

    return (
        <div
            style={{
                padding: 12,
                backgroundColor: props.type === "basic" ? "#3A3B3D" : "white",
                color: props.type === "basic" ? "white" : "black",
                borderRadius: 10,
                marginBottom: 12,
            }}
        >
            <Row>
                <Col xs={24} lg={16}>
                    <Avatar
                        src={props.review?.imageProfile ? props.review.imageProfile : `./assets/images/profile/personDefault.jpg`}
                        style={{
                            height: 35,
                            width: 35,
                            padding: 0,
                            margin: 0
                        }}
                    />
                    <label style={{ paddingLeft: 8 }}>{props.review.userName}</label>
                </Col>

                <Col xs={24} lg={8}>
                    <Rate
                        disabled
                        allowHalf
                        value={props.review.reviewScore}
                        style={{
                            float: 'right',
                            fontSize: 12,
                            paddingLeft: 8,
                            marginTop: -5,
                            paddingBottom: 0,
                            marginBottom: 0,
                            color: '#23B14D'
                        }}
                    /><br />
                    <label
                        style={{
                            paddingLeft: 8,
                            paddingBottom: 0,
                            marginBottom: 0,
                            float: 'right',
                            fontSize: 10,
                            marginTop: -6,
                        }}
                    >{props.review.createdAt}</label>
                </Col>

                <Col span={24}>
                    <label
                        style={{
                            paddingTop: 4,
                            paddingBottom: 0,
                            marginBottom: 0,
                        }}
                    >{props.review.reviewMessage}</label>
                </Col>
            </Row>
        </div>
    )
}
