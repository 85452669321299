/* eslint-disable react-hooks/exhaustive-deps */
import moment from 'moment'
import { useEffect, useState } from 'react'

import {
    Col,
    Divider,
    Row,
    Table
} from 'antd'
import { getEarnedSalesRevenueMonitorByIdFetch } from '../../API'

const formatDateDB = "YYYY-MM-DD"
const formatDate = "DD/MM/YYYY"

export default function EarnedSalesRevenue(props) {

    let language = "th"

    const columns = [
        {
            title: 'No',
            dataIndex: 'index',
            width: 10,
        },
        {
            title: 'รายการที่ขายได้',
            dataIndex: 'courseName',
            width: "13%",
        },
        {
            title: 'ซื้อผ่านทาง',
            dataIndex: 'paymentMethodTypeNameTH',
            width: "8%",
        },
        // {
        //     title: 'ประเภทซื้อ',
        //     dataIndex: 'paymentTypeNameTH',
        //     width: "6%",
        // },
        {
            title: 'ราคา',
            dataIndex: 'netPrice',
            width: 20,
            render: (text, record) => {
                return (
                    <div>{Intl.NumberFormat('en', { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(Number(record.netPrice))} ฿</div>
                )
            }
        },
        {
            title: 'คูปอง',
            dataIndex: 'couponCode',
            width: "6%",
        },
        {
            title: 'หลังหักค่าธรรมเนียม',
            dataIndex: 'balanceAfterPayFee',
            width: 20,
            render: (text, record) => {
                return (
                    <div>{Intl.NumberFormat('en', { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(Number(record.balanceAfterPayFee))} ฿</div>
                )
            }
        },
        {
            title: 'ค่าธรรมเนียม',
            dataIndex: 'fee',
            width: 20,
            render: (text, record) => {
                return (
                    <div>{Intl.NumberFormat('en', { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(Number(record.fee))} %</div>
                )
            }
        },
        {
            title: 'การต่อสัญญา',
            dataIndex: 'expireStatusName',
            width: 19,
            render: (text, record) => {
                return (
                    <div>{record.expireStatusId ? record.expireStatusName : "-"}</div>
                )
            }
        },
        {
            title: 'สถานะการซื้อ',
            dataIndex: 'paymentStatusNameTH',
            width: 19,
            render: (text, record) => {
                if (record.paymentStatusId === 1) {
                    return <label style={{ color: "green" }}>{record.paymentStatusNameTH}</label>
                } else if (record.paymentStatusId === 2) {
                    return <label style={{ color: "orange" }}>{record.paymentStatusNameTH}</label>
                } else if (record.paymentStatusId === 3) {
                    return <label style={{ color: "red" }}>{record.paymentStatusNameTH}</label>
                }
            }
        },
        {
            title: 'ผู้ซื้อ',
            dataIndex: 'orderOwnerName',
            width: "8%",
        },
        {
            title: 'วันที่ชำระเงิน (จากตรวจผ่าน)',
            dataIndex: 'paymentDate',
            width: 16,
            render: (text, record) => {
                return (
                    <div>{moment(record.paymentDate).format(formatDate)}</div>
                )
            }
        },
        {
            title: 'สถานะการรับเงิน',
            dataIndex: 'isReceivedName',
            width: 19,
            render: (text, record) => {
                return (
                    <div style={{ color: record.isReceived ? "green" : "black" }}>{record.isReceivedName}</div>
                )
            }
        },
        {
            title: 'วันที่จะได้รับเงิน',
            dataIndex: 'receivedDate',
            width: 25,
            render: (text, record) => {
                return (
                    <div>
                        {moment(record.receivedDate).format(formatDate)} ถึง {moment(record.receivedDate).add(3, 'day').format(formatDate)}
                    </div>
                )
            }
        },
    ]

    const [loading, setLoading] = useState(true)

    const [monitor, setMonitor] = useState(null)

    const [pageOrderCurrent, setPageOrderCurrent] = useState(1)

    const onOrderPagine = (n) => {
        setPageOrderCurrent(n.current)
    };

    const getCourseMonitor = async (uid, dateRange, isInDepthFilter) => {
        let startedDate, endDate = null
        if (isInDepthFilter) {
            startedDate = dateRange.startedDate
            endDate = dateRange.endDate
        } else {
            if (dateRange) {
                if (dateRange === "1") {
                    startedDate = moment().format(formatDateDB) + " 00:00:00"
                    endDate = moment().format(formatDateDB) + " 23:59:59"
                } else if (dateRange === "2") {
                    startedDate = moment().add(-7, 'd').format(formatDateDB) + " 00:00:00"
                    endDate = moment().format(formatDateDB) + " 23:59:59"
                } else if (dateRange === "3") {
                    startedDate = moment().add(-30, 'd').format(formatDateDB) + " 00:00:00"
                    endDate = moment().format(formatDateDB) + " 23:59:59"
                }
                else if (dateRange === "4") {
                    startedDate = moment("2022-01-01").format(formatDateDB) + " 00:00:00"
                    endDate = moment().format(formatDateDB) + " 23:59:59"
                }
            }
        }

        let obj = {
            uid,                // teacherId
            startedDate,
            endDate,
            language
        }
        const result = await getEarnedSalesRevenueMonitorByIdFetch(obj)
        setMonitor(result)
    }

    const getBaseApi = async () => {
        setLoading(true)

        await getCourseMonitor(
            props.teacherId,
            props.filter.isInDepth ? props.filter.rangAdvance : props.filter.rangBasic,
            props.filter.isInDepth
        );

        setLoading(false)
    }

    useEffect(() => {
        getBaseApi()
    }, [props.filter])

    return (
        <div
            style={{
                backgroundColor: 'white',
                borderRadius: 15,
                padding: 15
            }}
        >
            <Row>
                <Col span={24}>
                    <label style={{ marginTop: 5, fontSize: 16 }}>การขาย</label>
                    <Divider style={{ marginTop: 12, marginBottom: 12 }} />
                </Col>

                <Col span={24}>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                        <label>ได้รับเงินเเล้ว</label>

                        <div style={{ display: "flex", alignItems: "center" }}>
                            <label style={{ fontWeight: "bold", fontSize: 20, color: "green" }}>{monitor ? Intl.NumberFormat('en', { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(Number(monitor.sumReceived)) : 0}</label>
                            <label style={{ paddingLeft: 10 }}>บาท</label>
                        </div>
                    </div>
                </Col>

                <Col span={24}>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                        <label style={{ marginTop: 8 }}>ยังไม่ได้รับเงิน</label>

                        <div style={{ display: "flex", alignItems: "center" }}>
                            <label style={{ fontWeight: "bold", fontSize: 20, color: "green" }}>{monitor ? Intl.NumberFormat('en', { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(Number(monitor.sumNotReceived)) : 0}</label>
                            <label style={{ paddingLeft: 10 }}>บาท</label>
                        </div>
                    </div>
                </Col>

                <Col span={24} style={{ paddingTop: 12 }}>
                    <Table
                        scroll={{ x: 2000 }}
                        loading={loading}
                        columns={columns}
                        dataSource={monitor?.detail}
                        pagination={{
                            current: pageOrderCurrent,
                            pageSize: 10,
                            total: monitor?.detail.length
                        }}
                        onChange={(n) => onOrderPagine(n)}
                    >
                    </Table>
                </Col>
            </Row>
        </div>
    )
}
