import { server } from "../../../constants"
import { httpClient } from "../../../utils/HttpClient"

const getFetchUid = async (firebase) => { // language -> th, eng //move
    try {
        // const result = await httpClient.get(server.COURSE_CATEGORY_ALL_URL + `/${language}`);
        const result = await httpClient.get(server.COURSE_DETECT_URL + `/${firebase}`);
        if (result.data.isSuccess) {
            return result.data.formData;
        } else {
            return null;
        }
    } catch (err) { // status 404
        return null;
    }
}

const getEmailByUsernameFetch = async (value) => { // value[ email ] : 
    try {
        const result = await httpClient.post(server.GET_EMAIL_BY_USERNAME_URL, { value });
        if (result.data.isSuccess) {
            return result.data.formData;
        } else {
            return [];
        }
    } catch (err) { // status 404
        return null;
    }
}

const getEmailPasswordIsOnDbFetch = async (value) => { // value[ email ] : 
    try {
        const result = await httpClient.post(server.GET_EMAIL_PASSWORD_IS_ON_DB_URL, { value });
        if (result.data.isSuccess) {
            return result.data.formData;
        } else {
            return [];
        }
    } catch (err) { // status 404
        return null;
    }
}

export {
    // get
    getFetchUid,
    getEmailByUsernameFetch,
    getEmailPasswordIsOnDbFetch
    
    // update

    // insert

    // delete

}