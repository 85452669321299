/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */

import { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from "react-redux"
import { AiOutlineUpload } from 'react-icons/ai'
import { Icon } from '@iconify/react'
import { AiOutlineQuestionCircle } from "react-icons/ai"
import { LoadingOutlined, UploadOutlined } from '@ant-design/icons'
import ImgCrop from 'antd-img-crop'
import "../../css/manageCard.css"
import {
    Form,
    Input,
    Row,
    Col,
    Button,
    Upload,
    Select,
    Radio,
    Popover,
    Popconfirm,
    Spin,
    Divider
} from 'antd'
import { setCourseManageAddCurrentStepStateToSuccess } from '../../../../actions/courseManageAddCurrentStep.action'
import { setCourseManageCardStateToSuccess } from '../../../../actions/courseManageCard.action'
import { Notification } from '../../../../common/components/notification'
import { useDimensions } from '../../../../common/mamager/ScreenManager'
import { apiStorageUrl, bucketName, imgDefaltCardCourse, storageUrl } from '../../../../constants'
import { getCourseManageFetch, updateCourseFetch, updateCourseIsUpdateFetch, updateRequestOpenCourseCardAdjustFetch } from '../../API'
import { getCourseCategoryFetch, getCourseCertificateFetch, getCourseFormatFetch } from '../../../course/API'

const { TextArea } = Input

const { Option } = Select

export default function ManageCard(props) {
    const language = "th"

    const dispatch = useDispatch()

    const [course, setCourse] = useState(null)
    const [user, setUser] = useState({ uid: null })
    const [isStepActive, setIsStepActive] = useState(null)
    const [isCourseCategoryActive, setIsCourseCategoryActive] = useState(null)
    const [isCourseFormatActive, setIsCourseFormatActive] = useState(null)
    const [isCourseCertificateActive, setIsCourseCertificateActive] = useState(null)
    const [courseInspect, setcourseInspect] = useState(null)
    const [switchType, setSwitchType] = useState(null)

    const { width } = useDimensions()

    const [loading, setLoading] = useState(true)

    const [form] = Form.useForm()

    const { resultCourseManageAddCurrentStep } = useSelector(({ courseManageAddCurrentStepReducer }) => courseManageAddCurrentStepReducer)
    const { resultCard } = useSelector(({ courseManageCardReducer }) => courseManageCardReducer)

    const [courseCategory, setCourseCategory] = useState([])
    const [courseFormat, setCourseFormat] = useState([])
    const [courseCertificateCategory, setCourseCertificateCategory] = useState([])

    const initialAdd = {
        title: resultCard ? resultCard.title : undefined,
        subDescription: resultCard ? resultCard.subDescription : undefined,
        courseCategory: resultCard ? Number(resultCard.courseCategory) : undefined,
        courseFormat: resultCard ? Number(resultCard.courseFormat) : undefined,
        courseCertificateCategory: resultCard ? Number(resultCard.courseCertificateCategory) : undefined,
        isPaymentByVideo: resultCard ? resultCard.isPaymentByVideo : undefined
    }

    const initialEdit = {
        title: course ? course.courseTitleName : undefined,
        subDescription: course ? course.courseSubDescriptionName : undefined,
        courseCategory: course ? Number(course.courseCategoryId) : undefined,
        courseFormat: course ? Number(course.courseFormatId) : undefined,
        courseCertificateCategory: course ? Number(course.courseCertificateCategoryId) : undefined,
        isPaymentByVideo: course ? (course.isPaymentByVideo === 1 ? true : false) : undefined
    }

    const submitTypeRef = useRef(null);

    // const [cardTitle, setCardTitle] = useState(course ? course.courseTitleName : undefined)
    // const [cardSubDescriptionName, setCardSubDescriptionName] = useState(course ? course.courseSubDescriptionName : undefined)
    // const [cardPaymentByVideoStatus, setCardPaymentByVideoStatus] = useState(course ? course.isPaymentByVideo : undefined)

    const [courseCoverImage, setCourseCoverImage] = useState({
        loading: false,
    })

    const [checkedMonthlyPayment, setCheckedMonthlyPayment] = useState(false)

    const optionImage = {
        name: 'file',
        action: `${apiStorageUrl}/upload/file`,
        data: {
            // uploaded/img/course-card
            bucket: bucketName,
            folder: "image/course/cover"
        },
        headers: {
            authorization: 'authorization-text',
        },
        onChange(info) {
            if (info.file.status === 'done') {
                let result = info.file.response;
                console.log("info : ", info.file.response)
                if (result?.isSuccess) {
                    let formValue = form.getFieldValue();
                    setReducer(
                        formValue.title,
                        formValue.subDescription,
                        result.url,
                        checkedMonthlyPayment, // ###
                        formValue.isPaymentByVideo,
                        formValue.courseCategory,
                        formValue.courseFormat,
                        formValue.courseCertificateCategory
                    );

                    setCourseCoverImage({
                        imageUrl: result.url,
                        loading: false,
                    });
                }
            } else {
                setCourseCoverImage({ loading: true });
            }

            if (info.file.status === 'done') {
                Notification("success", "ระบบ", "อัพโหลดรูปภาพสำเร็จ");
            } else if (info.file.status === 'error') {
                Notification("error", "ระบบ", "อัพโหลดรูปภาพไม่สำเร็จ กรุณาลองใหม่อีกครั้ง");
            }
        },
        progress: {
            strokeColor: {
                '0%': '#108ee9',
                '100%': '#87d068',
            },
            strokeWidth: 3,
            format: percent => `${parseFloat(percent.toFixed(2))}%`,
        },
    }

    // const onMonthlyPayment = () => {
    //     setCheckedMonthlyPayment(!checkedMonthlyPayment)
    // }

    const onFormChange = () => {
        let formValue = form.getFieldValue()
        setReducer(
            formValue.title,
            formValue.subDescription,
            courseCoverImage.imageUrl,
            checkedMonthlyPayment,
            formValue.isPaymentByVideo,
            formValue.courseCategory,
            formValue.courseFormat,
            formValue.courseCertificateCategory
        )
    }

    const onFinish = async (values) => {
        if (submitTypeRef.current === 'next') {

            setReducer(
                values.title,
                values.subDescription,
                courseCoverImage.imageUrl,
                checkedMonthlyPayment, // ###
                values.isPaymentByVideo,
                values.courseCategory,
                values.courseFormat,
                values.courseCertificateCategory
            )

            // set session
            localStorage.setItem("stepsCardClickable", 1)

            // reducer
            dispatch(setCourseManageAddCurrentStepStateToSuccess(Number(resultCourseManageAddCurrentStep) + 1))
        } else {
            // get data latest
            const resultCourseLatest = await getCourseManageFetch(user.uid, course.courseId)

            // console.log("values : ", values)

            let obj = {
                // new
                courseCategoryId: values.courseCategory,
                courseCertificateCategoryId: values.courseCertificateCategory,
                courseFormatId: values.courseFormat,
                courseSubDescriptionName: values.subDescription,
                courseTitleName: values.title,
                courseImageLocation: courseCoverImage.imageUrl,
                monthlyPayment: checkedMonthlyPayment, // ###
                isPaymentByVideo: values.isPaymentByVideo, // ? 1 : 0,

                // old
                courseId: resultCourseLatest.courseId,
                courseDescription: resultCourseLatest.courseDescription,
                approvalStatusId: resultCourseLatest.approvalStatusId,
                ownerCheckCourseId: resultCourseLatest.ownerCheckCourseId,
                coursePrice: resultCourseLatest.coursePrice,
                courseDiscount: resultCourseLatest.courseDiscount,
                coursePriceDiscount: resultCourseLatest.coursePriceDiscount,
                courseView: resultCourseLatest.courseView,
                discountDateEnd: resultCourseLatest.discountDateEnd,
                created_at: new Date(resultCourseLatest.created_at),
                updated_at: new Date(resultCourseLatest.updated_at),
            }

            if (switchType === "adjust-course") { // course adjust
                const resultUpdate = await updateRequestOpenCourseCardAdjustFetch(
                    user.uid,               // uid
                    obj,                    // course
                    1,                      // inspectionCourseAdjustCategoryId
                    1,                      // isActive
                    course.courseId,        // courseId
                )

                if (resultUpdate.data.isSuccess) {
                    Notification("success", "ระบบ", "บันทึกการปรับเเก้สำเร็จ")
                } else {
                    Notification("error", "ระบบ", "บันทึกการปรับเเก้ไม่สำเร็จ")
                }
            } else if (switchType === "edit-course") { // course edit
                // =======================================================================
                // future add check diff between resultCard whit courseLatest
                // =======================================================================

                const resultUpdate = await updateCourseFetch(
                    user.uid,                                   // uid              
                    obj,                                        // data                 
                    1,                                          // isActive             
                    course.courseId,                            // courseId             
                    "courseCard",                               // courseComponent
                )

                // update isUpdateCourseCard true
                let resultIsUpdate = { data: { isSuccess: true } }
                resultIsUpdate = await updateCourseIsUpdateFetch(
                    user.uid,                                   // uid                                      
                    1,                                          // isActive                                     
                    course.courseId,                            // data                                                  
                    'courseCard',                               // use update field isUpdateCourseCard
                )

                if (resultUpdate.data.isSuccess && resultIsUpdate.data.isSuccess) {
                    props.handleCourseReload();
                    Notification("success", "ระบบ", "บันทึกการปรับเเต่งสำเร็จ")
                } else {
                    Notification("error", "ระบบ", "บันทึกการปรับเเต่งไม่สำเร็จ")
                }
            }

            if (!isStepActive) {
                // reload
                props.handleCourseReload()
            }
        }
    }

    const setReducer = (
        title,
        subDescription,
        courseCoverImage,
        monthlyPayment,
        isPaymentByVideo,
        courseCategory,
        courseFormat,
        courseCertificateCategory
    ) => {
        // reducer
        dispatch(setCourseManageCardStateToSuccess({
            title,
            subDescription,
            courseCoverImage,
            monthlyPayment,
            isPaymentByVideo,
            courseCategory,
            courseFormat,
            courseCertificateCategory
        }))
    }

    const detectCourseCoverImage = () => {
        if (courseCoverImage.imageUrl) {
            return Promise.resolve()
        } else {
            return Promise.reject(new Error('กรุณาอัพโหลดภาพปกวิดีโอ'))
        }
    }

    const getRequestBaseApi = async () => {
        const category = await getCourseCategoryFetch(language)
        setCourseCategory(category)

        const format = await getCourseFormatFetch(language)
        setCourseFormat(format)

        const certificate = await getCourseCertificateFetch(language)
        setCourseCertificateCategory(certificate)
    }

    useEffect(() => {
        setLoading(true)

        setTimeout(function () {
            setUser(props.user)
            setIsStepActive(props.isStepActive)
            setIsCourseCategoryActive(props.isCourseCategoryActive)
            setIsCourseFormatActive(props.isCourseFormatActive)
            setIsCourseCertificateActive(props.isCourseCertificateActive)
            setSwitchType(props.switchType)

            getRequestBaseApi()

            if (props.isStepActive) {
                if (resultCard) {
                    // set img course cover
                    setCourseCoverImage(resultCard ? {
                        imageUrl: resultCard.courseCoverImage,
                        loading: false,
                    } : [])

                    // checkbox monthly payment
                    setCheckedMonthlyPayment(resultCard.monthlyPayment)
                }
            } else {
                setCourse(props.course);
                // console.log("props.course : ", props.course)

                setcourseInspect(props.courseInspect)

                setCourseCoverImage(props.course ? {
                    imageUrl: props.course.courseImageLocation,
                    loading: false,
                } : [])

                setCheckedMonthlyPayment(props.course?.monthlyPayment)
            }

            setLoading(false)
        }, 500)
    }, [])

    const genBtnUpload = () => {
        return (
            <ImgCrop
                modalTitle={<label style={{ fontWeight: 'bold', padding: 0, margin: 0 }}>ปรับเเต่งรูปภาพ</label>}
                modalOk={<label style={{ width: 90, cursor: "pointer" }}><AiOutlineUpload style={{ width: 20, height: 20, paddingRight: 5 }} /> อัพโหลด</label>}
                modalCancel={<label style={{ width: 90, cursor: "pointer" }}><Icon icon="mdi:cancel" style={{ width: 20, height: 20, paddingRight: 5 }} />ยกเลิก</label>}
                aspect={300 / 300}
            >
                <Upload
                    {...optionImage}
                    accept='image/jpeg, image/png, image/jfif'
                    maxCount={1}
                    showUploadList={{ showRemoveIcon: false }}
                >
                    <Button
                        type="primary"
                        style={{ width: "100%" }}
                        icon={courseCoverImage.loading ? <LoadingOutlined /> : <UploadOutlined />}
                        disabled={
                            switchType === "adjust-course" ?
                                (props.courseInspect ?
                                    (props.courseInspect.course.inspectionCourseCardApprovalId === 1 ? true : false) : false)
                                :
                                (switchType === "edit-course" ?
                                    (props.courseInspect ?
                                        (props.courseInspect.inspectionCourseId === 2 && props.courseInspect.inspectionCourseApprovalId === 2 ? true : false) : false) : false)
                        }
                    >
                        อัพโหลด
                    </Button>
                </Upload>
            </ImgCrop>
        )
    }

    const genCard = () => {
        return (
            <>
                <Form
                    layout="vertical"
                    form={form}
                    initialValues={isStepActive ? initialAdd : initialEdit}
                    onFinish={onFinish}
                >
                    <Row style={{ textAlign: 'left' }}>
                        <Col xs={24} md={12} lg={6}>
                            {courseCoverImage.imageUrl ?
                                <img
                                    style={{ border: "1px solid #eeeeee", width: '100%' }}
                                    src={`${courseCoverImage.imageUrl}`}
                                />
                                :
                                <img
                                    style={{ width: '100%' }}
                                    src={imgDefaltCardCourse}
                                />
                            }
                            <div style={{ paddingTop: 12 }}>
                                <Form.Item
                                    name="uploadCourseCoverImage"
                                    rules={[{ required: true, validator: detectCourseCoverImage }]}
                                >
                                    {courseInspect ?
                                        (courseInspect.inspectionCourseId !== 2 && courseInspect.inspectionCourseApprovalId !== 2 ?
                                            genBtnUpload()
                                            :
                                            ((course.approvalStatusId === 1 || course.approvalStatusId === 2) && courseInspect.inspectionCourseId === 3 ?
                                                genBtnUpload()
                                                :
                                                (!props.inspectCourseApprovalId && !courseInspect.course.inspectionCourseCardAdjustId && courseInspect.inspectionCourseId === 2 ?
                                                    genBtnUpload()
                                                    :
                                                    <Button style={{ width: 200 }} icon={<UploadOutlined />} type="primary" disabled={true}>อัพโหลด</Button>
                                                )
                                            )
                                        )
                                        :
                                        genBtnUpload()
                                    }
                                </Form.Item>
                            </div>
                        </Col>

                        <Col xs={24} md={12} lg={18} style={width > 767 ? { paddingLeft: 25 } : {}}>
                            <Form.Item
                                name="title"
                                label={
                                    <>
                                        <div>ชื่อคอร์ส </div>
                                        <div style={{ paddingLeft: 10 }}> (จำกัดตัวอักษร 100 ตัว)</div>
                                    </>
                                }
                                rules={[{ required: true, message: 'กรุณากรอกชื่อคอร์ส' }]}
                            >
                                <Input
                                    showCount
                                    maxLength={100}
                                    style={width > 1200 ? { width: '50%' } : { width: '100%' }}
                                    placeholder="กรอกชื่อปกคอร์ส"
                                    onBlur={(e) => {
                                        onFormChange(e);
                                    }} // use detect title change
                                    disabled={
                                        switchType === "adjust-course" ?
                                            (props.courseInspect ?
                                                (
                                                    (props.courseInspect.course.inspectionCourseCardApprovalId === 1 || props.courseInspect.inspectionCourseId === 2) ? true : false) : false)
                                            :
                                            (switchType === "edit-course" ?
                                                courseInspect ?
                                                    (courseInspect.inspectionCourseId !== 2 && courseInspect.inspectionCourseApprovalId !== 2 ?
                                                        false
                                                        :
                                                        ((course.approvalStatusId === 1 || course.approvalStatusId === 2) && courseInspect.inspectionCourseId === 3 ?
                                                            false
                                                            :
                                                            (!props.inspectCourseApprovalId && !courseInspect.course.inspectionCourseCardAdjustId && courseInspect.inspectionCourseId === 2 ?
                                                                false
                                                                :
                                                                true
                                                            )
                                                        )
                                                    )
                                                    :
                                                    false
                                                : false
                                            )
                                    }
                                />
                            </Form.Item>

                            <Form.Item
                                name="subDescription"
                                label={
                                    <span>
                                        เเนะนำคอร์สเบื้องต้น
                                        <span style={{ paddingLeft: 10 }}> (จำกัดตัวอักษร 150 ตัว)</span>
                                    </span>
                                }
                                // rules={[{ required: true, validator: detectSubDescription }]}
                                rules={[{ required: true, message: 'กรุณากรอกรายละเอียดเบื้องต้น' }]}
                            >
                                <TextArea
                                    showCount
                                    maxLength={150}
                                    autoSize={{ minRows: 3, maxRows: 5 }}
                                    placeholder="กรอกรายละเอียดเบื้องต้น"
                                    onBlur={(e) => {
                                        onFormChange(e.target.value);
                                    }} // use detect title subDescription
                                    disabled={
                                        switchType === "adjust-course" ?
                                            (props.courseInspect ?
                                                (props.courseInspect.course.inspectionCourseCardApprovalId === 1 || props.courseInspect.inspectionCourseId === 2 ? true : false) : false)
                                            :
                                            (switchType === "edit-course" ?
                                                courseInspect ?
                                                    (courseInspect.inspectionCourseId !== 2 && courseInspect.inspectionCourseApprovalId !== 2 ?
                                                        false
                                                        :
                                                        ((course.approvalStatusId === 1 || course.approvalStatusId === 2) && courseInspect.inspectionCourseId === 3 ?
                                                            false
                                                            :
                                                            (!props.inspectCourseApprovalId && !courseInspect.course.inspectionCourseCardAdjustId && courseInspect.inspectionCourseId === 2 ?
                                                                false
                                                                :
                                                                true
                                                            )
                                                        )
                                                    )
                                                    :
                                                    false
                                                : false
                                            )
                                    }
                                />
                            </Form.Item>

                            <Form.Item
                                name="courseCategory"
                                label={
                                    <span>
                                        ประเภทคอร์ส
                                        {!isCourseCategoryActive ?
                                            <span style={{ marginLeft: 10 }}>(ไม่สามารถเปลี่ยนเเปลงภายหลังได้)</span>
                                            : []
                                        }
                                    </span>
                                }
                                rules={!isCourseCategoryActive ? [{ required: true, message: 'กรุณาเลือกประเภทคอร์ส' }] : null}
                            >
                                <Select
                                    disabled={props.isStepActive ? false : true}
                                    showSearch
                                    style={width > 1200 ? { width: '50%' } : { width: '100%' }}
                                    placeholder="เลือกประเภท"
                                    optionFilterProp="children"
                                    allowClear
                                >
                                    {courseCategory.map((val, index) =>
                                        <Option key={index} value={val.value}>{val.label}</Option>
                                    )}
                                </Select>
                            </Form.Item>

                            <Form.Item
                                name="courseFormat"
                                label={
                                    <span>
                                        รูปเเบบคอร์ส
                                        {!isCourseFormatActive ?
                                            <>
                                                <Popover content={<div style={{ width: 250 }}>อายุของคอร์สเรียนที่ผู้เรียนสามารถใช้ได้ เมื่อผู้เรียนชำระเงินเเล้ว</div>}>
                                                    <AiOutlineQuestionCircle style={{ marginLeft: 5, cursor: 'pointer' }} />
                                                </Popover>
                                                <span style={{ marginLeft: 10 }}>(ไม่สามารถเปลี่ยนเเปลงภายหลังได้)</span>
                                            </>
                                            : []}
                                    </span>
                                }
                                rules={!isCourseFormatActive ? [{ required: true, message: 'กรุณาเลือกรูปเเบบคอร์ส' }] : null}
                            >
                                <Select
                                    disabled={props.isStepActive ? false : true}
                                    showSearch
                                    style={width > 1200 ? { width: '50%' } : { width: '100%' }}
                                    placeholder="เลือกระยะเวลา"
                                    optionFilterProp="children"
                                    allowClear
                                >
                                    {courseFormat.map((val, index) =>
                                        <Option key={index} value={val.value}>{val.label}</Option>
                                    )}
                                </Select>
                            </Form.Item>

                            {/* <Form.Item>
                                <Checkbox
                                    checked={checkedMonthlyPayment}
                                    onChange={onMonthlyPayment}
                                    disabled={
                                        switchType === "adjust-course" ?
                                            (props.courseInspect ?
                                                (props.courseInspect.course.inspectionCourseCardApprovalId === 1 || props.courseInspect.inspectionCourseId === 2 ? true : false) : false)
                                            :
                                            (switchType === "edit-course" ?
                                                (courseInspect ?
                                                    (courseInspect.inspectionCourseId !== 2 && courseInspect.inspectionCourseApprovalId !== 2 ?
                                                        false
                                                        :
                                                        ((course.approvalStatusId === 1 || course.approvalStatusId === 2) && courseInspect.inspectionCourseId === 3 ?
                                                            false
                                                            :
                                                            (!props.inspectCourseApprovalId && !courseInspect.course.inspectionCourseCardAdjustId && courseInspect.inspectionCourseId === 2 ?
                                                                false
                                                                :
                                                                true
                                                            )
                                                        )
                                                    )
                                                    :
                                                    false
                                                )
                                                :
                                                false
                                            )
                                    }
                                >
                                    โหมดเช่าคอร์สรายเดือน
                                    <Popover content={<div style={{ width: 250 }}>เป็นการเพิ่มทางเลือกในการชำระเงินที่นอกเหนือจากรูปเเบบปกติที่เป็บตลอดชีวิต เเละรายปี โดยเพิ่มการเช่าคอร์สอีกหนึ่งช่องทางให้เลือกชำระเงินเข้ามา ราคาคอร์สที่ผู้เรียนจ่ายเเบบเช่าจะน้อยกว่าราคาปกติถึง 6 เท่า เเละต้องชำระเงินทุก ๆ เดือน เพื่อต่ออายุการใช้งาน โหมดนี้จะช่วยให้ผู้ที่มีรายได้น้อยสามารถเข้าถึงได้</div>}>
                                        <AiOutlineQuestionCircle style={{ marginLeft: 5, cursor: 'pointer' }} />
                                    </Popover>
                                </Checkbox>
                            </Form.Item> */}

                            <Form.Item
                                name="courseCertificateCategory"
                                label={
                                    <span>
                                        ใบรับรอง (Certificate)
                                        {!isCourseCertificateActive ?
                                            <>
                                                <Popover content={<span style={{ width: 250 }}>ใบประกาศสำหรับผู้เรียน เมื่อเรียนจบหลักสูตรคอร์สตามที่กำหนดเเล้ว</span>}>
                                                    <AiOutlineQuestionCircle style={{ marginLeft: 5, cursor: 'pointer' }} />
                                                </Popover>
                                                <span style={{ marginLeft: 10 }}>(ไม่สามารถเปลี่ยนเเปลงภายหลังได้)</span>
                                            </>
                                            : []}
                                    </span>
                                }
                                rules={!isCourseCertificateActive ? [{ required: true, message: 'กรุณาเลือกรูปเเบบคอร์ส' }] : null}
                            >
                                <Radio.Group disabled={props.isStepActive ? false : true}>
                                    {courseCertificateCategory.map((val) => {
                                        return <Radio value={val.value}>{val.label}</Radio>
                                    })}
                                </Radio.Group>
                            </Form.Item>

                            <Form.Item
                                name="isPaymentByVideo"
                                label={
                                    <span>ยินยอมชำระเงินเเบบรายวิดีโอ</span>
                                }
                                rules={!isCourseCertificateActive ? [{ required: true, message: 'กรุณาเลือกการยินยอมชำระเงินเเบบรายวิดีโอ' }] : null}
                            >
                                <Radio.Group
                                    // disabled={props.isStepActive ? false : true}
                                    onChange={(e) => {
                                        onFormChange(e.target.value);
                                    }} // use detect select payment by video status
                                    disabled={
                                        switchType === "adjust-course" ?
                                            (props.courseInspect ?
                                                (props.courseInspect.course.inspectionCourseCardApprovalId === 1 || props.courseInspect.inspectionCourseId === 2 ? true : false) : false)
                                            :
                                            (switchType === "edit-course" ?
                                                courseInspect ?
                                                    (courseInspect.inspectionCourseId !== 2 && courseInspect.inspectionCourseApprovalId !== 2 ?
                                                        false
                                                        :
                                                        ((course.approvalStatusId === 1 || course.approvalStatusId === 2) && courseInspect.inspectionCourseId === 3 ?
                                                            false
                                                            :
                                                            (!props.inspectCourseApprovalId && !courseInspect.course.inspectionCourseCardAdjustId && courseInspect.inspectionCourseId === 2 ?
                                                                false
                                                                :
                                                                true
                                                            )
                                                        )
                                                    )
                                                    :
                                                    false
                                                : false
                                            )
                                    }
                                >
                                    <Radio value={true}>ยินยอม</Radio>
                                    <Radio value={false}>ไม่ยินยอม</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>

                <Divider style={{ marginTop: 1, marginBottom: 15 }} />
                <div span={24} style={{ paddingLeft: 20, paddingRight: 20, float: "left", fontSize: 14 }}>
                    <label>หมายเหตุ !</label><br />
                    <ul>
                        <li>ส่วนของ "หน้าปกคอร์ส" ใช้เเสดงข้อมูลคร่าว ๆ เกี่ยวกับคอร์ส เช่นภาพโฆษณาคอร์ส เเนะนำคอร์สเบื้องต้นที่เป็นเเนวการตลาด เเละชื่อคอร์ส เป็นต้น เพื่อให้ผู้เรียนเห็นเเล้วสะดุดตา อยู่ในรูปเเบบของการ์ดที่จะปรากฏให้เห็นในหน้าหลัก หน้ารายการคอร์สที่ค้นหา เป็นต้น ซึ่งเปรียบเสมือนด่านหน้าที่ผู้คนจะพบเห็นคอร์สเป็นส่วนใหญ่</li>
                        <li>เมื่อ "หน้าปกคอร์ส" ผ่านการตรวจสอบเเล้ว จะเผยแพร่สู่สาธารณะให้ผู้คนทั่วไปเห็นได้ทันที เเม้ว่าผู้เรียนจะยังไม่ได้ซื้อคอร์สนี้ก็ตาม</li>
                    </ul>
                </div>
            </>
        )
    }

    return (
        <>
            {!loading ?
                <>
                    {isStepActive ? // is open
                        <Row>
                            <Col span={24}>
                                {genCard()}
                            </Col>
                            <Divider style={{ marginTop: 1, marginBottom: 1 }} />
                            <Col span={24} style={{ paddingTop: 20 }}>
                                <center>
                                    <Button
                                        type="primary"
                                        onClick={() => {
                                            submitTypeRef.current = 'next';
                                            form.submit();
                                        }}
                                        style={width > 992 ? { width: 130 } : ((width < 992 && width > 576) ? { width: 130 } : { width: '100%' })}
                                    >
                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                            <Icon icon="fluent:arrow-next-20-filled" style={{ color: "white" }} />
                                            <label style={{ paddingLeft: 10, cursor: "pointer" }}>ถัดไป</label>
                                        </div>
                                    </Button>
                                </center>
                            </Col>
                        </Row>
                        :
                        <>
                            <Row
                                style={{
                                    paddingTop: 10,
                                    paddingBottom: 0,
                                    paddingLeft: 10,
                                    paddingRight: 10
                                }}
                            >
                                <Col span={24} style={{ padding: 10 }}>
                                    {genCard()}
                                </Col>
                                <Divider style={{ marginTop: 1, marginBottom: 1 }} />
                                <Col span={24} style={{ padding: 20 }}>
                                    <center>
                                        {(courseInspect ? courseInspect.course.inspectionCourseCardApprovalId === 2 : null) ?
                                            <Popconfirm
                                                title="คุณยืนยันบันทึกการปรับเเก้หรือไม่ ?"
                                                okText={<span style={{ width: 50 }}>ใช่</span>}
                                                onConfirm={() => {
                                                    submitTypeRef.current = 'update'
                                                    form.submit()
                                                }}
                                                cancelText={<span style={{ width: 50 }}>ไม่ใช่</span>}
                                                disabled={courseInspect.inspectionCourseId === 3 ? false : true}
                                            >
                                                <Row>
                                                    <Col span={24}>
                                                        <Button
                                                            type="primary"
                                                            disabled={courseInspect.inspectionCourseId === 3 ? false : true}
                                                        >
                                                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                                <Icon icon="bx:save" style={{ color: "white" }} />
                                                                <label style={{ paddingLeft: 10, cursor: "pointer" }}>บันทึกการปรับเเก้</label>
                                                            </div>
                                                        </Button>
                                                    </Col>
                                                    <Col span={24}>
                                                        {switchType === "edit-course" && courseInspect.inspectionCourseId === 2 && courseInspect.inspectionCourseCardId === 1 ?
                                                            <label style={{ paddingTop: 10 }}>กำลังอยู่ระหว่างการตรวจสอบ ทำให้ไม่สามารถกดปุ่ม "บันทึกการเเก้ไข" ได้ในขณะนี้! จนกว่าจะตรวจสอบเสร็จ เเละเเจ้งผลให้ทราบ</label>
                                                            : []
                                                        }
                                                    </Col>
                                                </Row>
                                            </Popconfirm>
                                            :
                                            <>
                                                {switchType === "edit-course" ?
                                                    <Popconfirm
                                                        title="คุณยืนยันการบันทึกการปรับเเต่งหรือไม่ ?"
                                                        okText={<span style={{ width: 50 }}>ใช่</span>}
                                                        onConfirm={() => {
                                                            submitTypeRef.current = 'update'
                                                            form.submit()
                                                        }}
                                                        cancelText={<span style={{ width: 50 }}>ไม่ใช่</span>}
                                                    >
                                                        <Button type="primary" >
                                                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                                <Icon icon="bx:save" style={{ color: "white" }} />
                                                                <label style={{ paddingLeft: 10, cursor: "pointer" }}>บันทึกการปรับเเต่ง</label>
                                                            </div>
                                                        </Button>
                                                    </Popconfirm>
                                                    : []
                                                }
                                            </>
                                        }
                                    </center>
                                </Col>
                            </Row>

                            {(switchType === "adjust-course" ?
                                (courseInspect ? courseInspect.course.inspectionCourseCardApprovalId === 2 : false)
                                :
                                (switchType === "edit-course" ?
                                    (
                                        (courseInspect ? (
                                            (courseInspect.course.inspectionCourseCardApprovalId === 2 && courseInspect.inspectionCourseCardId === 1 && courseInspect.inspectionCourseId === 3) ||
                                            (courseInspect.amountEdit > 0 && courseInspect.course.inspectionCourseCardApprovalId === 2)
                                        ) : false)
                                    ) : false
                                )
                            ) ?
                                <div style={{ paddingLeft: 20, paddingRight: 20, paddingBottom: 20, backgroundColor: 'white', height: "auto" }}>
                                    <Row
                                        style={{
                                            padding: 10,
                                            border: '1px solid #EEEEEE',
                                            fontSize: 14
                                        }}
                                    >
                                        <Col span={24} style={{ paddingLeft: 10 }}>
                                            เหตุผลไม่ผ่าน
                                            <Popover content={<div style={{ width: 250 }}>ผลตรวจ ที่ทาง Toflyn เเจ้งให้ผู้สอนปรับเเก้ เเละส่งกลับมาใหม่อีกครั้งในเวลาที่กำหนด</div>}>
                                                <AiOutlineQuestionCircle style={{ marginLeft: 5, cursor: 'pointer' }} />
                                            </Popover>
                                        </Col>
                                        <Col span={24} style={{ paddingLeft: 10, paddingRight: 10, paddingTop: 5 }}>
                                            <p style={{ color: "red", backgroundColor: "#EEEEEE", padding: 8 }}>
                                                {courseInspect.course.reasonCourseCardNotApproval}
                                            </p>
                                        </Col>
                                        <Col span={24} style={{ padding: 10, borderTop: "1px solid #EEEEEE" }}>
                                            ตัวอย่าง เกร็ดความรู้ เเละสิ่งที่ควรหลีกเลี่ยง
                                        </Col>
                                        <Col span={24}>
                                            <label style={{ paddingLeft: 20 }}>1. ภาพหน้าปกคอร์ส ควรอยู่ในองค์ประกอบที่เหมาะสม</label>
                                        </Col>
                                        <Col span={24}>
                                            <label style={{ paddingLeft: 20 }}>2. ชื่อคอร์ส ควรสื่อได้อย่างชัดเจนเข้าใจง่าย ไม่คลุมเครือ สื่อความหมายชัดเจน</label>
                                        </Col>
                                        <Col span={24}>
                                            <label style={{ paddingLeft: 20 }}>3. คำแนะนำคอร์สเบื่องต้น ควรเน้นเนื้อหาที่ต้องการสอนใจความหลัก</label>
                                        </Col>
                                        <Col span={24}>
                                            <label style={{ paddingLeft: 20 }}>4. ประเภทคอร์ส ควรเหมาะสมกับเนื้อหาที่สอน</label>
                                        </Col>
                                    </Row>
                                </div>
                                : []}
                        </>
                    }
                </>
                :
                <center><Spin size="large" style={{ marginTop: 200, marginBottom: 200 }} /></center>
            }
        </>
    )
}
