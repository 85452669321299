import { Col, Image, Popover, Row } from 'antd';
import { AiOutlineQuestionCircle } from "react-icons/ai";

const genIcon = (crlInitials, crlFullName, amountLearner, code, widthBG, heightBG, openDetail) => {
    return (
        <div style={{ height: openDetail ? 40 : "auto", display: 'flex', alignItems: 'center' }}>
            <div
                style={{
                    borderRadius: 10,
                    backgroundColor: code,
                    width: widthBG,
                    height: heightBG
                }}
            >
                <Row>
                    <Col span={24}>
                        <Image
                            style={{
                                paddingLeft: '8%',
                                paddingRight: '8%',
                                paddingBottom: '8%',
                                paddingTop: '0%',
                            }}
                            src="error"
                            fallback={`./assets/images/crl/CRL_${crlInitials}.png`} 
                            preview={false}
                        />
                    </Col>

                    <Col span={12} style={{ marginTop: '-45%' }}>
                        <center>
                            <label
                                style={{
                                    paddingBottom: 0,
                                    marginBottom: 0,
                                    fontSize: 7,
                                    fontWeight: 'bold',
                                    color: 'white'
                                }}
                            >{crlInitials}</label>
                        </center>
                    </Col>

                    <Col span={12} style={{ marginTop: '-45%' }}>
                        <center>
                            <label
                                style={{
                                    paddingBottom: 0,
                                    marginBottom: 0,
                                    fontSize: 5,
                                    fontWeight: 'bold',
                                    color: 'white'
                                }}
                            >TFY</label>
                        </center>
                    </Col>
                </Row>
            </div>

            {openDetail ?
                <div style={{ paddingLeft: 8 }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <span>{crlFullName}</span>
                        <Popover content={
                            <div style={{ width: 250 }}>
                                <Row gutter={[0, 12]}>
                                    <Col span={6}>
                                        {iconCRL(1, 40, 40, false)}
                                    </Col>
                                    <Col span={18}>
                                        <div style={{ marginTop: -2 }}>
                                            <span style={{ padding: 0 }}>CRL Coral</span>
                                            <br />
                                            <span style={{ padding: 0 }}>มีผู้เรียน 1 ถึง 49 คน</span>
                                        </div>
                                    </Col>

                                    <Col span={6}>
                                        {iconCRL(50, 40, 40, false)}
                                    </Col>
                                    <Col span={18}>
                                        <div style={{ marginTop: -2 }}>
                                            <span style={{ padding: 0 }}>CRL Lime</span>
                                            <br />
                                            <span style={{ padding: 0 }}>มีผู้เรียน 50 ถึง 99 คน</span>
                                        </div>
                                    </Col>

                                    <Col span={6}>
                                        {iconCRL(100, 40, 40, false)}
                                    </Col>
                                    <Col span={18}>
                                        <div style={{ marginTop: -2 }}>
                                            <span style={{ padding: 0 }}>CRL Olive</span>
                                            <br />
                                            <span style={{ padding: 0 }}>มีผู้เรียน 100 ถึง 999 คน</span>
                                        </div>
                                    </Col>

                                    <Col span={6}>
                                        {iconCRL(1000, 40, 40, false)}
                                    </Col>
                                    <Col span={18}>
                                        <div style={{ marginTop: -2 }}>
                                            <span style={{ padding: 0 }}>CRL Navy</span>
                                            <br />
                                            <span style={{ padding: 0 }}>มีผู้เรียน 1,000 ถึง 9,999 คน</span>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        }>
                            <AiOutlineQuestionCircle style={{ marginLeft: 5, cursor: 'pointer' }}/>
                        </Popover>
                    </div>
                    <div>
                        <span>จำนวนผู้เรียน {amountLearner} คน</span>
                    </div>
                </div>
                : []
            }
        </div>
    )
}

const findCRL = (amountLearner, widthBG, heightBG, openDetail) => {
    if (amountLearner >= 1 && amountLearner <= 49) {
        return genIcon("C", "CRL Coral", amountLearner, "#FF7F50", widthBG, heightBG, openDetail);
    } else if (amountLearner >= 50 && amountLearner <= 99) {
        return genIcon("L", "CRL Lime", amountLearner, "#23B14D", widthBG, heightBG, openDetail);
    } else if (amountLearner >= 100 && amountLearner <= 999) {
        return genIcon("O", "CRL Olive", amountLearner, "#808000", widthBG, heightBG, openDetail);
    } else if (amountLearner >= 1000 && amountLearner <= 9999) {
        return genIcon("N", "CRL Navy", amountLearner, "#000080", widthBG, heightBG, openDetail);
    } else if (amountLearner >= 10000 && amountLearner <= 99999) {
        return genIcon("F", "CRL Fuchsia", amountLearner, "#FF00FF", widthBG, heightBG, openDetail);
    } else if (amountLearner >= 100000 && amountLearner <= 999999) {
        return genIcon("G", "CRL Gold", amountLearner, "#FFD700", widthBG, heightBG, openDetail);
    } else if (amountLearner >= 1000000 && amountLearner <= 9999999) {
        return genIcon("S", "CRL Salmon", amountLearner, "#FA8072", widthBG, heightBG, openDetail);
    } else if (amountLearner >= 10000000) {
        return genIcon("T", "CRL Turquoise", amountLearner, "#40E0D", widthBG, heightBG, openDetail);
    }
}

export function iconCRL(amountLearner, widthBG, heightBG, openDetail) { // ตัวย่อของสี
    return (
        <>{findCRL(amountLearner, widthBG, heightBG, openDetail)}</>
    )
}