/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */

import { useState, useEffect } from 'react'
import {
    Table,
    Row,
    Col,
    Button,
    Form,
    Input,
    Select,
    Radio,
    DatePicker,
    Modal,
    Divider
} from 'antd'
import Column from 'antd/lib/table/Column'
import { AiFillCheckCircle } from "react-icons/ai"
import { IoMdCloseCircle } from "react-icons/io"
import moment from 'moment'
import TextArea from 'antd/lib/input/TextArea'
import { Notification } from '../../../../common/components/notification'
import { openCourseVerify } from '../../../../common/mamager/IdManager'
import { emailSubject, emailSupport } from '../../../../constants'
import { getCourseOwnerFetch } from '../../../course/API'
import { getInspectFormRequestOpenCourseAllFetch, getInspectFormRequestOpenCourseByIdFetch, getInspectionCourseApprovalCategoryFetch, getInspectionCourseCategoryFetch, getOpenCourseTokenByIdFetch, updateInspectFormRequestOpenCourseFetch, updateInspectFormRequestOpenCourseIsOverdueFetch } from '../../API'
import { inspectFormRequestOpenCourseNotPass, inspectFormRequestOpenCourseOverdue, inspectFormRequestOpenCoursePass } from '../../../../common/mamager/EmailMamager'

const { Option } = Select

const { RangePicker } = DatePicker

const columns = [
    {
        title: 'No',
        dataIndex: 'index',
        width: "7%",
    },
    {
        title: 'ชื่อคอร์ส',
        dataIndex: 'openCourseName',
        width: "10%",
    },
    {
        title: 'จำนวนตีกลับ',
        dataIndex: 'amountAdjust',
        width: "10%",
    },
    {
        title: 'วันที่สร้าง',
        dataIndex: 'createdAt',
        width: "10%",
    },
    {
        title: 'วันที่เเก้ไข',
        dataIndex: 'updatedAt',
        width: "10%",
    },
    {
        title: 'ผลตรวจสอบ',
        dataIndex: 'inspectionCourseApproval',
        width: "10%",
    },
    {
        title: 'สถานะตรวจสอบ',
        dataIndex: 'inspectionCourse',
        align: "center",
        width: "15%",
    },
]

const formatDate = "YYYY-MM-DD"
const formatTime = "HH:mm"
const formatDateTime = "YYYY-MM-DD HH:mm"
const formatDateTimeDB = "MM/DD/YYYY HH:mm"

export default function InspectRequestOpenCourse(props) {
    const language = "th"

    const [form] = Form.useForm()
    const [formDetail] = Form.useForm()

    const [list, setList] = useState([])
    const [formRequestOpenCourseDetail, setFormRequestOpenCourseDetail] = useState(null)

    const [inspectFormRequestOpenCourseTopic, setInspectFormRequestOpenCourseTopic] = useState([])

    const [inspectFormRequestOpenCourseName, setInspectFormRequestOpenCourseName] = useState({
        isApploval: null,
        isAdjust: null,
        reason: "",
    })

    const [inspectFormRequestOpenCourseCategory, setInspectFormRequestOpenCourseCategory] = useState({
        isApploval: null,
        isAdjust: null,
        reason: "",
    })

    const [inspectFormRequestOpenCourseTarget, setInspectFormRequestOpenCourseTarget] = useState({
        isApploval: null,
        isAdjust: null,
        reason: "",
    })

    const [inspectFormRequestOpenCourseReceive, setInspectFormRequestOpenCourseReceive] = useState({
        isApploval: null,
        isAdjust: null,
        reason: "",
    })

    const [inspectFormRequestOpenCourseAboutOwner, setInspectFormRequestOpenCourseAboutOwner] = useState({
        isApploval: null,
        isAdjust: null,
        reason: "",
    })

    const [loading, setLoading] = useState(false) // true

    const [inspectionCourseCategory, setInspectionCourseCategory] = useState([])
    const [inspectionCourseApprovalCategory, setInspectionCourseApprovalCategory] = useState([])

    const [teacherInfo, setTeacherInfo] = useState(null)

    const [modalInspectFormRequestOpenCourse, setModalInspectFormRequestOpenCourse] = useState({
        isOpen: false,
        inspectionCourseId: null
    })
    const [modalFormRequestOpenCourseIsOverdue, setModalFormRequestOpenCourseIsOverdue] = useState({
        isOpen: false,
        inspectionFormRegOpenCourseId: null,
        uid: null,
    })

    const [pageCurrent, setPageCurrent] = useState(1)

    const onPagine = (n) => {
        setPageCurrent(n.current)
    }

    const inspectionCourseApprovalButton = (type) => {
        switch (type) {
            case 1:
                return <center><AiFillCheckCircle style={{ width: 25, height: 25, color: "green" }} /></center>
            case 2:
                return <center><IoMdCloseCircle style={{ width: 25, height: 25, color: "red" }} /></center>
            default:
                return null
        }
    }

    const inspectionCourseButton = (value) => {
        if (
            value.inspectionCourseId === 1 ||
            value.inspectionCourseId === 2 ||
            value.inspectionCourseId === 3
        ) {
            return <Button
                type="primary"
                style={{ width: 130 }}
                onClick={() => getFormRequestOpenCourseDetail(value, 'inspect')}
            >{value.inspectionCourseName}</Button>
        } else if (value.inspectionCourseId === 4) {
            return <>
                {value.isOverdue ?
                    <label>เกินกำหนดปรับเเก้เเล้ว</label>
                    :
                    <Button
                        type="primary"
                        style={{ width: 130 }}
                        onClick={() => getFormRequestOpenCourseDetail(value, 'overdue')}
                    >เกินกำหนดปรับเเก้</Button>
                }
            </>
        } else {
            return null
        }
    }

    const onFinish = async (values) => {
        let courseName = values.courseName ? values.courseName : null
        let startedDate = values.dateRange ? moment(values.dateRange[0]).format(formatDateTime) : null
        let endDate = values.dateRange ? moment(values.dateRange[1]).format(formatDateTime) : null
        let inspectionCourse = values.inspectionCourse ? values.inspectionCourse : null
        let inspectionCourseApproval = values.inspectionCourseApproval ? values.inspectionCourseApproval : null
        let dateType = values.dateType ? values.dateType : null

        let request = {
            courseName, startedDate, endDate, inspectionCourse, inspectionCourseApproval, dateType
        }

        if (!courseName && !startedDate && !endDate && !inspectionCourse && !inspectionCourseApproval) {
            await getInspectFormRequestOpenCourseAll("allData", {
                courseName: null,
                startedDate: null,
                endDate: null,
                inspectionCourse: null,
                inspectionCourseApproval: null,
                dateType
            })
        } else {
            await getInspectFormRequestOpenCourseAll("bySearch", request)
        }
    }

    const onFormDetailFinish = async (values) => {
        let inspectFormRequestOpenCourseTopicIsApploval = 1
        for (let i = 0; i < inspectFormRequestOpenCourseTopic.length; i++) {
            if (inspectFormRequestOpenCourseTopic[i].inspect.inspectFormRequestOpenCourseTopicApprovalId === 2) {
                inspectFormRequestOpenCourseTopicIsApploval = 2
                break
            }
        }

        let inspectionCourseApprovalCategoryId = null;
        if (
            inspectFormRequestOpenCourseName.isApploval === 1 &&
            inspectFormRequestOpenCourseCategory.isApploval === 1 &&

            inspectFormRequestOpenCourseTarget.isApploval === 1 &&
            inspectFormRequestOpenCourseReceive.isApploval === 1 &&
            inspectFormRequestOpenCourseAboutOwner.isApploval === 1
        ) {
            inspectionCourseApprovalCategoryId = 1
        } else {
            inspectionCourseApprovalCategoryId = 2
        }

        let objTopic = [];
        for (let i = 0; i < inspectFormRequestOpenCourseTopic.length; i++) {
            objTopic.push({
                formRegOpenCourseTopicId: inspectFormRequestOpenCourseTopic[i].formRegOpenCourseTopicId,
                inspectFormRequestOpenCourseTopicApprovalId: inspectFormRequestOpenCourseTopic[i].inspect.inspectFormRequestOpenCourseTopicApprovalId,
                inspectFormRequestOpenCourseTopicAdjustId: inspectFormRequestOpenCourseTopic[i].inspect.inspectFormRequestOpenCourseTopicApprovalId === 1 ? (inspectFormRequestOpenCourseTopic[i].inspect.inspectFormRequestOpenCourseTopicAdjustId ? inspectFormRequestOpenCourseTopic[i].inspect.inspectFormRequestOpenCourseTopicAdjustId : null) : 2,
                reasonFormRequestOpenCourseTopicNotApproval: inspectFormRequestOpenCourseTopic[i].inspect.reasonFormRequestOpenCourseTopicNotApproval,
            })
        }

        let obj = {
            inspectionCourseCategoryId: inspectionCourseApprovalCategoryId === 2 ? 3 : 1,
            inspectionCourseApprovalCategoryId,

            formRegOpenCourseId: formRequestOpenCourseDetail.formRequestOpenCourse.formRegOpenCourseId,
            inspectionFormRegOpenCourseId: formRequestOpenCourseDetail.inspectionFormRegOpenCourseId,
            uid: formRequestOpenCourseDetail.uid,
            dateEndAdjust: inspectionCourseApprovalCategoryId !== 1 ? moment().add(1, 'M').format(formatDateTimeDB) : formRequestOpenCourseDetail.dateEndAdjust,
            amountAdjust: inspectionCourseApprovalCategoryId !== 1 ? (Number(formRequestOpenCourseDetail.amountAdjust) + 1) : formRequestOpenCourseDetail.amountAdjust,

            // form_request_open_course
            inspectionCourseNameApprovalId: inspectFormRequestOpenCourseName.isApploval ? inspectFormRequestOpenCourseName.isApploval : null,
            inspectionCourseNameAdjustId: inspectFormRequestOpenCourseName.isApploval === 1 ? (inspectFormRequestOpenCourseName.isAdjust ? inspectFormRequestOpenCourseName.isAdjust : null) : 2,
            reasonCourseNameNotApproval: inspectFormRequestOpenCourseName.reason ? inspectFormRequestOpenCourseName.reason : null,

            inspectionCourseCategoryApprovalId: inspectFormRequestOpenCourseCategory.isApploval ? inspectFormRequestOpenCourseCategory.isApploval : null,
            inspectionCourseCategoryAdjustId: inspectFormRequestOpenCourseCategory.isApploval === 1 ? (inspectFormRequestOpenCourseCategory.isAdjust ? inspectFormRequestOpenCourseCategory.isAdjust : null) : 2,
            reasonCourseCategoryNotApproval: inspectFormRequestOpenCourseCategory.reason ? inspectFormRequestOpenCourseCategory.reason : null,

            inspectionCourseTopicApprovalId: inspectFormRequestOpenCourseTopicIsApploval,

            inspectionCourseTargetApprovalId: inspectFormRequestOpenCourseTarget.isApploval ? inspectFormRequestOpenCourseTarget.isApploval : null,
            inspectionCourseTargetAdjustId: inspectFormRequestOpenCourseTarget.isApploval === 1 ? (inspectFormRequestOpenCourseTarget.isAdjust ? inspectFormRequestOpenCourseTarget.isAdjust : null) : 2,
            reasonCourseTargetNotApproval: inspectFormRequestOpenCourseTarget.reason ? inspectFormRequestOpenCourseTarget.reason : null,

            inspectionCourseReceiveApprovalId: inspectFormRequestOpenCourseReceive.isApploval ? inspectFormRequestOpenCourseReceive.isApploval : null,
            inspectionCourseReceiveAdjustId: inspectFormRequestOpenCourseReceive.isApploval === 1 ? (inspectFormRequestOpenCourseReceive.isAdjust ? inspectFormRequestOpenCourseReceive.isAdjust : null) : 2,
            reasonCourseReceiveNotApproval: inspectFormRequestOpenCourseReceive.reason ? inspectFormRequestOpenCourseReceive.reason : null,

            inspectionCourseAboutOwnerApprovalId: inspectFormRequestOpenCourseAboutOwner.isApploval ? inspectFormRequestOpenCourseAboutOwner.isApploval : null,
            inspectionCourseAboutOwnerAdjustId: inspectFormRequestOpenCourseAboutOwner.isApploval === 1 ? (inspectFormRequestOpenCourseAboutOwner.isAdjust ? inspectFormRequestOpenCourseAboutOwner.isAdjust : null) : 2,
            reasonCourseAboutOwnerNotApproval: inspectFormRequestOpenCourseAboutOwner.reason ? inspectFormRequestOpenCourseAboutOwner.reason : null,

            // form_request_open_course_topic
            inspectFormRequestOpenCourseTopic: objTopic,
        }
        // console.log("obj : ", obj)

        const result = await updateInspectFormRequestOpenCourseFetch(obj)
        // console.log("result : ", result)

        if (result.isSuccess) {
            getBaseApi()
            Notification("success", "ระบบ", "ส่งผลตรวจสอบสำเร็จ")
            // gen token verify upload course
            if (obj.inspectionCourseCategoryId === 1) {
                await openCourseVerify(
                    obj.uid,
                    obj.formRegOpenCourseId,
                    obj.inspectionFormRegOpenCourseId
                )
            }

            let resultFormOpenCourseToken = null
            // get token verify upload course when pass
            if (obj.inspectionCourseCategoryId === 1) {
                resultFormOpenCourseToken = await getOpenCourseTokenByIdFetch(obj.formRegOpenCourseId)
            }

            let courseName = formRequestOpenCourseDetail ? (formRequestOpenCourseDetail.formRequestOpenCourse ? formRequestOpenCourseDetail.formRequestOpenCourse.formRegOpenCourseName : null) : null
            let objEmail = {
                ownerName: teacherInfo ? teacherInfo.name : null,
                courseName,
                courseUrl: inspectionCourseApprovalCategoryId === 1 ?
                    ("/course-manage-add?token=" + resultFormOpenCourseToken.token + "&formROCId=" + obj.formRegOpenCourseId + "&inspectId=" + obj.inspectionFormRegOpenCourseId)
                    :
                    ("/registration-open-course?type=inspect&inspectId=" + obj.inspectionFormRegOpenCourseId)
            }
            if (inspectionCourseApprovalCategoryId === 1) {
                inspectFormRequestOpenCoursePass(
                    emailSupport,                               // mailgun.dreampoint.com
                    teacherInfo ? teacherInfo.email : null,     // teacher email
                    emailSubject + "เเจ้งผล คำขอเปิดคอร์ส " + courseName + " ผ่านการตรวจสอบ",
                    objEmail,
                )
            } else {
                inspectFormRequestOpenCourseNotPass(
                    emailSupport,                               // mailgun.dreampoint.com
                    teacherInfo ? teacherInfo.email : null,     // teacher email
                    emailSubject + "เเจ้งผล คำขอเปิดคอร์ส " + courseName + " ไม่ผ่านการตรวจสอบ",
                    objEmail,
                )
            }

            // close modal 
            setModalInspectFormRequestOpenCourse({
                isOpen: false,
                inspectionCourseId: null
            })
        } else {
            Notification("error", "ระบบ", "ส่งผลตรวจสอบไม่สำเร็จ กรุณาลองใหม่อีกครั้ง")
        }
    }

    const getInspectFormRequestOpenCourseAll = async (type, values) => {
        setLoading(true);

        const result = await getInspectFormRequestOpenCourseAllFetch(values, language)
        // console.log("getInspectFormRequestOpenCourseAllFetch : ", result)
        let genRecord = []
        result.map((val, index) => {

            let overdue = null
            let inspectionCourseId = val.inspectionCourseCategoryId
            if (
                (inspectionCourseId === 2 || inspectionCourseId === 3) &&
                val.dateEndAdjust &&
                val.amountAdjust > 0
            ) {
                overdue = moment(val.dateEndAdjust, formatDate).diff(new Date(), 'days')
                if (overdue <= 0) {
                    inspectionCourseId = 4
                }
            }

            genRecord.push({
                index: (index + 1),
                openCourseName: val.openCourseName,
                amountAdjust: val.amountAdjust,
                createdAt: moment(val.createdAt).format(formatDateTime),
                updatedAt: moment(val.updatedAt).format(formatDateTime),
                inspectionCourseApproval: inspectionCourseApprovalButton(val.inspectionCourseApprovalCategoryId),
                inspectionCourse: inspectionCourseButton(
                    {
                        inspectionFormRegOpenCourseId: val.inspectionFormRegOpenCourseId,
                        inspectionCourseId, // *** test
                        inspectionCourseName: val.inspectionCourseCategoryName,
                        isOverdue: val.isOverdue,
                    }
                ),
            })
        })
        setList(genRecord)

        setLoading(false)
    }

    const getFormRequestOpenCourseDetail = async (value, type) => {
        let obj = {
            inspectionFormRegOpenCourseId: value.inspectionFormRegOpenCourseId,
        }
        const result = await getInspectFormRequestOpenCourseByIdFetch(obj, language)
        // console.log("getInspectFormRequestOpenCourseByIdFetch : ", result)
        setFormRequestOpenCourseDetail(result)

        const resultCourseTeacher = await getCourseOwnerFetch(result ? result.uid : null)
        // console.log("getCourseOwnerFetch : ", resultCourseTeacher)
        setTeacherInfo(resultCourseTeacher)

        if (result) {
            formDetail.setFieldsValue({
                inspectCourseRadio_formRequestOpenCourseName: result.formRequestOpenCourse ? result.formRequestOpenCourse.inspectionCourseNameApprovalId : undefined,
                reasonNotApploval_formRequestOpenCourseName: result.formRequestOpenCourse ? result.formRequestOpenCourse.reasonCourseNameNotApproval : undefined,

                inspectCourseRadio_formCourseCategory: result.formRequestOpenCourse ? result.formRequestOpenCourse.inspectionCourseCategoryApprovalId : undefined,
                reasonNotApploval_formCourseCategory: result.formRequestOpenCourse ? result.formRequestOpenCourse.reasonCourseCategoryNotApproval : undefined,

                inspectCourseRadio_formRequestOpenCourseTarget: result.formRequestOpenCourse ? result.formRequestOpenCourse.inspectionCourseTargetApprovalId : undefined,
                reasonNotApploval_formRequestOpenCourseTarget: result.formRequestOpenCourse ? result.formRequestOpenCourse.reasonCourseTargetNotApproval : undefined,

                inspectCourseRadio_formRequestOpenCourseReceive: result.formRequestOpenCourse ? result.formRequestOpenCourse.inspectionCourseReceiveApprovalId : undefined,
                reasonNotApploval_formRequestOpenCourseReceive: result.formRequestOpenCourse ? result.formRequestOpenCourse.reasonCourseReceiveNotApproval : undefined,

                inspectCourseRadio_formRequestOpenCourseAboutOwner: result.formRequestOpenCourse ? result.formRequestOpenCourse.inspectionCourseAboutOwnerApprovalId : undefined,
                reasonNotApploval_formRequestOpenCourseAboutOwner: result.formRequestOpenCourse ? result.formRequestOpenCourse.reasonCourseAboutOwnerNotApproval : undefined,
            })

            // ชื่อคอร์สที่จะเปิดสอน
            setInspectFormRequestOpenCourseName({
                isApploval: result.formRequestOpenCourse ? result.formRequestOpenCourse.inspectionCourseNameApprovalId : null,

                reason: result.formRequestOpenCourse ? result.formRequestOpenCourse.reasonCourseNameNotApproval : null,
            })

            // ประเภทคอร์สที่จะเปิดสอน
            setInspectFormRequestOpenCourseCategory({
                isApploval: result.formRequestOpenCourse ? result.formRequestOpenCourse.inspectionCourseCategoryApprovalId : null,

                reason: result.formRequestOpenCourse ? result.formRequestOpenCourse.reasonCourseCategoryNotApproval : null,
            })

            // กลุ่มเป้าหมายของผู้เรียนที่ต้องการสอน ?
            setInspectFormRequestOpenCourseTarget({
                isApploval: result.formRequestOpenCourse ? result.formRequestOpenCourse.inspectionCourseTargetApprovalId : null,

                reason: result.formRequestOpenCourse ? result.formRequestOpenCourse.reasonCourseTargetNotApproval : null,
            })

            // ผู้ที่เรียนจะได้อะไรจากคอร์สนี้ ?
            setInspectFormRequestOpenCourseReceive({
                isApploval: result.formRequestOpenCourse ? result.formRequestOpenCourse.inspectionCourseReceiveApprovalId : null,

                reason: result.formRequestOpenCourse ? result.formRequestOpenCourse.reasonCourseReceiveNotApproval : null,
            })

            // คุณมีความรู้ในเรื่องที่สอนได้อย่างไร ใช้เวลากี่ปีถึงเข้าใจได้ ?
            setInspectFormRequestOpenCourseAboutOwner({
                isApploval: result.formRequestOpenCourse ? result.formRequestOpenCourse.inspectionCourseAboutOwnerApprovalId : null,

                reason: result.formRequestOpenCourse ? result.formRequestOpenCourse.reasonCourseAboutOwnerNotApproval : null,
            })

            // หัวข้อทั้งหมดที่สอน
            let formRequestOpenCourseTopic = result.formRequestOpenCourseTopic ? result.formRequestOpenCourseTopic : []
            let tempListTopic = []
            for (let i = 0; i < formRequestOpenCourseTopic.length; i++) {
                tempListTopic.push({
                    index: (i + 1),
                    topic: formRequestOpenCourseTopic[i].topic,
                    topicDescription: formRequestOpenCourseTopic[i].topicDescription,
                    inspect: {
                        inspectFormRequestOpenCourseTopicAdjustId: formRequestOpenCourseTopic[i].inspectFormRequestOpenCourseTopicAdjustId,
                        inspectFormRequestOpenCourseTopicApprovalId: formRequestOpenCourseTopic[i].inspectFormRequestOpenCourseTopicApprovalId,
                        reasonFormRequestOpenCourseTopicNotApproval: formRequestOpenCourseTopic[i].reasonFormRequestOpenCourseTopicNotApproval,
                    },
                    formRegOpenCourseTopicId: formRequestOpenCourseTopic[i].formRegOpenCourseTopicId,
                })
            }
            setInspectFormRequestOpenCourseTopic(tempListTopic)
            // console.log("listTopic : ", tempListTopic)
        }

        if (type === "inspect") {
            setModalInspectFormRequestOpenCourse({
                isOpen: true,
                inspectionCourseId: value.inspectionCourseId
            })
        } else if (type === "overdue") {
            setModalFormRequestOpenCourseIsOverdue({
                isOpen: true,
                inspectionFormRegOpenCourseId: result.inspectionFormRegOpenCourseId,
                uid: result.uid,
            })
        }
    }

    const getInspectionCourseApprovalCategoryApi = async () => {
        const result = await getInspectionCourseApprovalCategoryFetch(language)
        setInspectionCourseApprovalCategory(result)
    }

    const getInspectionCourseCategoryApi = async () => {
        const result = await getInspectionCourseCategoryFetch(language)
        setInspectionCourseCategory(result)
    }

    const handleInspectFormRequestOpenCourseTopicApprovalUpdate = (event, record, row) => {
        // console.log("apploval : ", row.index, " : ", event, " : ", record)
        let reasonFormRequestOpenCourseTopicNotApproval = record.reasonFormRequestOpenCourseTopicNotApproval
        let inspectFormRequestOpenCourseTopicAdjustId = record.inspectFormRequestOpenCourseTopicAdjustId
        if (event === 1) { // detect reason
            reasonFormRequestOpenCourseTopicNotApproval = null
        }

        if (event === 2) { // detect adjust
            inspectFormRequestOpenCourseTopicAdjustId = 2
        }

        setInspectFormRequestOpenCourseTopic(inspectFormRequestOpenCourseTopic.map(val => val.index !== row.index ? val : {
            index: val.index,
            topic: val.topic,
            topicDescription: val.topicDescription,
            inspect: {
                inspectFormRequestOpenCourseTopicAdjustId,
                inspectFormRequestOpenCourseTopicApprovalId: event,
                reasonFormRequestOpenCourseTopicNotApproval,
            },
            formRegOpenCourseTopicId: val.formRegOpenCourseTopicId,
        }))
    }

    const handleReasonFormRequestOpenCourseTopicUpdate = (event, record, row) => {
        let x = inspectFormRequestOpenCourseTopic.map(val => val.index === row.index ? {
            index: val.index,
            topic: val.topic,
            topicDescription: val.topicDescription,
            inspect: {
                inspectFormRequestOpenCourseTopicAdjustId: record.inspectFormRequestOpenCourseTopicAdjustId,
                inspectFormRequestOpenCourseTopicApprovalId: record.inspectFormRequestOpenCourseTopicApprovalId,
                reasonFormRequestOpenCourseTopicNotApproval: event,
            },
            formRegOpenCourseTopicId: val.formRegOpenCourseTopicId,
        } : val)
        setInspectFormRequestOpenCourseTopic(x)
    }

    const getBaseApi = async () => {
        getInspectFormRequestOpenCourseAll("allData", {
            courseName: null,
            startedDate: null,
            endDate: null,
            inspectionCourse: null,
            inspectionCourseApproval: null,
            dateType: 2
        })
    }

    useEffect(() => {
        getBaseApi()
    }, [])

    return (
        <>
            <Row>
                <Col span={4}>
                    <label>ตรวจสอบคำขอเปิดคอร์ส</label>
                </Col>
                <Col span={20} style={{ paddingBottom: 20 }}>
                    <Form
                        layout="vertical"
                        form={form}
                        onFinish={onFinish}
                    >
                        <Row>
                            <Col xs={12} lg={5}>
                                <Form.Item name="inspectionCourseApproval" >
                                    <Select
                                        showSearch
                                        style={{ width: '90%' }}
                                        placeholder="ผลตรวจสอบ"
                                        optionFilterProp="children"
                                        allowClear
                                        onClick={() => getInspectionCourseApprovalCategoryApi()}
                                    >
                                        {inspectionCourseApprovalCategory.map((val, index) =>
                                            <Option key={index} value={val.value}>{val.label}</Option>
                                        )}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={12} lg={5}>
                                <Form.Item name="inspectionCourse" >
                                    <Select
                                        showSearch
                                        style={{ width: '90%' }}
                                        placeholder="สถานะตรวจสอบ"
                                        optionFilterProp="children"
                                        allowClear
                                        onClick={() => getInspectionCourseCategoryApi()}
                                    >
                                        {inspectionCourseCategory.map((val, index) =>
                                            <Option key={index} value={val.value}>{val.label}</Option>
                                        )}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={12} lg={7}>
                                <Form.Item name="dateRange" >
                                    <RangePicker
                                        style={{ width: '90%' }}
                                        showTime={{ format: formatTime }}
                                        format={formatDateTime}
                                    />
                                </Form.Item>

                                <Form.Item label="ค้นหาจาก" name="dateType" style={{ marginTop: -15, marginBottom: 5 }}>
                                    <Radio.Group>
                                        <Radio value={1}>วันที่สร้าง</Radio>
                                        <Radio value={2}>วันที่เเก้ไข</Radio>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                            <Col xs={12} lg={5}>
                                <Form.Item name="courseName" >
                                    <Input
                                        style={{ width: '100%' }}
                                        placeholder="ชื่อคอร์ส"
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={12} lg={2}>
                                <Button
                                    type="primary"
                                    onClick={() => form.submit()}
                                >
                                    ค้นหา
                                </Button>
                            </Col>
                        </Row>
                    </Form>

                </Col>
                <Col span={24}>
                    <Table
                        loading={loading}
                        columns={columns}
                        dataSource={list}
                        pagination={{
                            current: pageCurrent,
                            pageSize: 10,
                            total: list.length
                        }}
                        onChange={(n) => onPagine(n)}
                    >
                    </Table>
                </Col>
            </Row>

            <Modal
                title="ตรวจสอบเเบบฟอร์มคำขอเปิดคอร์ส"
                visible={modalInspectFormRequestOpenCourse.isOpen}
                width={1000}
                maskClosable={false}
                onCancel={() => {
                    setModalInspectFormRequestOpenCourse({
                        isOpen: false,
                        inspectionCourseId: null
                    });
                    setFormRequestOpenCourseDetail(null);
                }}

                footer={[
                    <Button
                        type="primary"
                        disabled={modalInspectFormRequestOpenCourse.inspectionCourseId === 2 ? false : true}
                        onClick={() => {
                            formDetail.submit();
                        }}
                    >ส่งผลตรวจ</Button>,
                ]}
            >
                {/* {console.log("modalInspectFormRequestOpenCourse.inspectionCourseId : ", modalInspectFormRequestOpenCourse.inspectionCourseId)} */}
                {formRequestOpenCourseDetail ?
                    <Form
                        layout="vertical"
                        form={formDetail}
                        onFinish={onFormDetailFinish}
                    >
                        <Row>
                            {/* {console.log("teacherInfo : ", teacherInfo)} */}
                            <Col span={24} style={{ paddingBottom: 1 }}>
                                <Row style={{ backgroundColor: "#eeeeee", paddingTop: 10, paddingLeft: 10, paddingRight: 10 }}>
                                    <Col span={2}><label style={{ float: 'right' }}>ชื่อผู้ส่งคำขอ</label></Col>
                                    <Col span={10}><label style={{ paddingLeft: 8 }}>{teacherInfo ? teacherInfo.userName : "-"}</label></Col>

                                    <Col span={2}><label style={{ float: 'right' }}>จำนวนตีกลับ</label></Col>
                                    <Col span={10}><label style={{ paddingLeft: 8 }}>{formRequestOpenCourseDetail ? formRequestOpenCourseDetail.amountAdjust : '-'}</label></Col>

                                    <Col span={2}><label style={{ float: 'right' }}>เบอร์โทร</label></Col>
                                    <Col span={10}><label style={{ paddingLeft: 8 }}>{teacherInfo ? teacherInfo.phone : "-"}</label></Col>

                                    <Col span={2}><label style={{ float: 'right' }}>อีเมล</label></Col>
                                    <Col span={10}><label style={{ paddingLeft: 8 }}>{teacherInfo ? teacherInfo.email : "-"}</label></Col>

                                    <Col span={2}><label style={{ float: 'right' }}>วันที่สร้าง</label></Col>
                                    <Col span={10}><label style={{ paddingLeft: 8 }}>{formRequestOpenCourseDetail ? moment(formRequestOpenCourseDetail.createdAt).format(formatDateTime) : '-'}</label></Col>
                                </Row>
                            </Col>

                            <Col span={4} style={{ backgroundColor: "#F5F5F5", paddingTop: 5, paddingLeft: 10, paddingRight: 5 }}>
                                <label style={{ paddingRight: 10 }}>ชื่อคอร์สที่จะเปิดสอน</label>
                            </Col>
                            <Col span={14}>
                                <label style={{ paddingTop: 5, paddingLeft: 10 }}>{formRequestOpenCourseDetail.formRequestOpenCourse ? formRequestOpenCourseDetail.formRequestOpenCourse.formRegOpenCourseName : '-'}</label>
                            </Col>
                            <Col span={6} style={{ paddingTop: 5 }}>
                                <Form.Item
                                    name="inspectCourseRadio_formRequestOpenCourseName"
                                    rules={[{ required: true, message: 'กรุณาเลือกผลการตรวจ' }]}
                                >
                                    <Radio.Group
                                        disabled={modalInspectFormRequestOpenCourse.inspectionCourseId === 2 ? false : true}
                                        onChange={(e) => {
                                            setInspectFormRequestOpenCourseName({
                                                isApploval: e.target.value,
                                                isAdjust: e.target.value === 2 ? 3 : 1,
                                                reason: inspectFormRequestOpenCourseName.reason,
                                            });
                                        }}
                                    >
                                        <Radio value={1}>ผ่าน</Radio>
                                        <Radio value={2}>ไม่ผ่าน</Radio>
                                    </Radio.Group>
                                </Form.Item>
                                {inspectFormRequestOpenCourseName.isApploval === 2 ?
                                    <Form.Item
                                        name="reasonNotApploval_formRequestOpenCourseName"
                                        rules={[{ required: true, message: 'กรุณากรอกเหตุผลที่ไม่ผ่าน' }]}
                                    >
                                        <TextArea
                                            disabled={modalInspectFormRequestOpenCourse.inspectionCourseId === 2 ? false : true}
                                            onChange={(e) => {
                                                setInspectFormRequestOpenCourseName({
                                                    isApploval: inspectFormRequestOpenCourseName.isApploval,
                                                    isAdjust: inspectFormRequestOpenCourseName.isAdjust,
                                                    reason: e.target.value
                                                });
                                            }}
                                            placeholder="เหตุผลที่ไม่ผ่าน"
                                            autoSize={{ minRows: 3, maxRows: 5 }}
                                        />
                                    </Form.Item>
                                    : []}
                            </Col>
                            <Divider style={{ marginTop: 0, marginBottom: 1 }} />

                            <Col span={4} style={{ backgroundColor: "#F5F5F5", paddingTop: 5, paddingLeft: 10, paddingRight: 5 }}><label style={{ paddingRight: 10 }}>ประเภทคอร์สที่จะเปิดสอน</label></Col>
                            <Col span={14}>
                                <label style={{ paddingTop: 5, paddingLeft: 10 }}>
                                    {formRequestOpenCourseDetail.formRequestOpenCourse ?
                                        (formRequestOpenCourseDetail.formRequestOpenCourse.course_category ?
                                            language === 'th' ? formRequestOpenCourseDetail.formRequestOpenCourse.course_category.courseCategoryNameTH : formRequestOpenCourseDetail.formRequestOpenCourse.course_category.courseCategoryNameENG
                                            : '-'
                                        )
                                        : '-'
                                    }
                                </label>
                            </Col>
                            <Col span={6} style={{ paddingTop: 5 }}>
                                <Form.Item
                                    name="inspectCourseRadio_formCourseCategory"
                                    rules={[{ required: true, message: 'กรุณาเลือกผลการตรวจ' }]}
                                >
                                    <Radio.Group
                                        disabled={modalInspectFormRequestOpenCourse.inspectionCourseId === 2 ? false : true}
                                        onChange={(e) => {
                                            setInspectFormRequestOpenCourseCategory({
                                                isApploval: e.target.value,
                                                isAdjust: e.target.value === 2 ? 3 : 1,
                                                reason: inspectFormRequestOpenCourseCategory.reason
                                            });
                                        }}
                                    >
                                        <Radio value={1}>ผ่าน</Radio>
                                        <Radio value={2}>ไม่ผ่าน</Radio>
                                    </Radio.Group>
                                </Form.Item>
                                {inspectFormRequestOpenCourseCategory.isApploval === 2 ?
                                    <Form.Item
                                        name="reasonNotApploval_formCourseCategory"
                                        rules={[{ required: true, message: 'กรุณากรอกเหตุผลที่ไม่ผ่าน' }]}
                                    >
                                        <TextArea
                                            disabled={modalInspectFormRequestOpenCourse.inspectionCourseId === 2 ? false : true}
                                            onChange={(e) => {
                                                setInspectFormRequestOpenCourseCategory({
                                                    isApploval: inspectFormRequestOpenCourseCategory.isApploval,
                                                    isAdjust: inspectFormRequestOpenCourseCategory.isAdjust,
                                                    reason: e.target.value
                                                });
                                            }}
                                            placeholder="เหตุผลที่ไม่ผ่าน"
                                            autoSize={{ minRows: 3, maxRows: 5 }}
                                        />
                                    </Form.Item>
                                    : []}
                            </Col>
                            <Divider style={{ marginTop: 0, marginBottom: 1 }} />

                            <Col span={24} style={{ backgroundColor: "#F5F5F5", paddingTop: 5, paddingLeft: 10, paddingRight: 5 }}>
                                <label style={{ paddingTop: 10 }}>หัวข้อทั้งหมดที่สอน</label>
                            </Col>
                            <Col span={24}>
                                {/* ----------------------------------------------------------------- */}
                                <Table
                                    loading={loading}
                                    dataSource={inspectFormRequestOpenCourseTopic}
                                    pagination={{
                                        current: pageCurrent,
                                        pageSize: 15,
                                        total: inspectFormRequestOpenCourseTopic.length
                                    }}
                                    onChange={(n) => onPagine(n)}
                                >
                                    <Column title="No" dataIndex="index" width="5%" align="center" />
                                    <Column title="ชื่อหัวข้อ" dataIndex="topic" width="15%" />
                                    <Column title="รายละเอียดในหัวข้อ" dataIndex="topicDescription" width="28%" />

                                    {/* {isSaveInspect ? */}
                                    <Column
                                        title="สถานะตรวจสอบ"
                                        dataIndex="inspect"
                                        width="17%"
                                        align="center"
                                        render={(record, row) => {
                                            return (
                                                <Row>
                                                    {/* {console.log("A : ", record)} */}
                                                    <Col span={24} style={{ textAlign: "center", paddingBottom: 6 }}>
                                                        {/* {console.log(inspectFormRequestOpenCourseTopic.find(val => val.index === row.index).inspect.inspectFormRequestOpenCourseTopicApprovalId)} */}
                                                        <Form.Item
                                                            // name={"inspectCourseRadio_formRequestOpenCourseTopic" + row.index}
                                                            rules={[{ required: true, message: 'กรุณาเลือกผลการตรวจ' }]}
                                                        >
                                                            <Radio.Group
                                                                disabled={modalInspectFormRequestOpenCourse.inspectionCourseId === 2 ? false : true}
                                                                onChange={(e) => handleInspectFormRequestOpenCourseTopicApprovalUpdate(e.target.value, record, row)}
                                                                value={record.inspectFormRequestOpenCourseTopicApprovalId}
                                                            >
                                                                <Radio value={1}>ผ่าน</Radio>
                                                                <Radio value={2}>ไม่ผ่าน</Radio>
                                                            </Radio.Group>
                                                        </Form.Item>
                                                    </Col>
                                                    {record.inspectFormRequestOpenCourseTopicApprovalId === 2 ?
                                                        <Col span={24}>
                                                            <Form.Item
                                                                name={"reasonNotApploval_formRequestOpenCourseTopic" + row.index}
                                                                rules={[{ required: true, message: 'กรุณากรอกเหตุผลที่ไม่ผ่าน' }]}
                                                                initialValue={inspectFormRequestOpenCourseTopic.find(val => val.index === row.index).inspect.reasonFormRequestOpenCourseTopicNotApproval}
                                                            >
                                                                <TextArea
                                                                    disabled={modalInspectFormRequestOpenCourse.inspectionCourseId === 2 ? false : true}
                                                                    onChange={(e) => handleReasonFormRequestOpenCourseTopicUpdate(e.target.defaultValue, record, row)}
                                                                    placeholder="เหตุผลที่ไม่ผ่าน"
                                                                    autoSize={{ minRows: 2, maxRows: 2 }}
                                                                />
                                                            </Form.Item>
                                                        </Col>
                                                        : []}
                                                </Row>
                                            )
                                        }}
                                    />
                                    {/* : []} */}
                                </Table>
                            </Col>
                            <Divider style={{ marginTop: 0, marginBottom: 1 }} />

                            <Col span={4} style={{ backgroundColor: "#F5F5F5", paddingTop: 5, paddingLeft: 10, paddingRight: 5 }}>
                                <label style={{ paddingRight: 10 }}>กลุ่มเป้าหมายของผู้เรียนที่ต้องการสอน ?</label>
                            </Col>
                            <Col span={14}>
                                <label style={{ paddingTop: 5, paddingLeft: 10 }}>{formRequestOpenCourseDetail.formRequestOpenCourse ? formRequestOpenCourseDetail.formRequestOpenCourse.formRegOpenCourseTarget : '-'}</label>
                            </Col>
                            <Col span={6} style={{ paddingTop: 5 }}>
                                <Form.Item
                                    name="inspectCourseRadio_formRequestOpenCourseTarget"
                                    rules={[{ required: true, message: 'กรุณาเลือกผลการตรวจ' }]}
                                >
                                    <Radio.Group
                                        disabled={modalInspectFormRequestOpenCourse.inspectionCourseId === 2 ? false : true}
                                        onChange={(e) => {
                                            setInspectFormRequestOpenCourseTarget({
                                                isApploval: e.target.value,
                                                isAdjust: e.target.value === 2 ? 3 : 1,
                                                reason: inspectFormRequestOpenCourseTarget.reason,
                                            });
                                        }}
                                    >
                                        <Radio value={1}>ผ่าน</Radio>
                                        <Radio value={2}>ไม่ผ่าน</Radio>
                                    </Radio.Group>
                                </Form.Item>
                                {inspectFormRequestOpenCourseTarget.isApploval === 2 ?
                                    <Form.Item
                                        name="reasonNotApploval_formRequestOpenCourseTarget"
                                        rules={[{ required: true, message: 'กรุณากรอกเหตุผลที่ไม่ผ่าน' }]}
                                    >
                                        <TextArea
                                            disabled={modalInspectFormRequestOpenCourse.inspectionCourseId === 2 ? false : true}
                                            onChange={(e) => {
                                                setInspectFormRequestOpenCourseTarget({
                                                    isApploval: inspectFormRequestOpenCourseTarget.isApploval,
                                                    isAdjust: inspectFormRequestOpenCourseTarget.isAdjust,
                                                    reason: e.target.value,
                                                });
                                            }}
                                            placeholder="เหตุผลที่ไม่ผ่าน"
                                            autoSize={{ minRows: 3, maxRows: 5 }}
                                        />
                                    </Form.Item>
                                    : []}
                            </Col>
                            <Divider style={{ marginTop: 0, marginBottom: 1 }} />

                            <Col span={4} style={{ backgroundColor: "#F5F5F5", paddingTop: 5, paddingLeft: 10, paddingRight: 5 }}>
                                <label style={{ paddingRight: 10 }}>ผู้ที่เรียนจะได้อะไรจากคอร์สนี้ ?</label>
                            </Col>
                            <Col span={14}>
                                <label style={{ paddingTop: 5, paddingLeft: 10 }}>{formRequestOpenCourseDetail.formRequestOpenCourse ? formRequestOpenCourseDetail.formRequestOpenCourse.formRegOpenCourseReceive : '-'}</label>
                            </Col>
                            <Col span={6} style={{ paddingTop: 5 }}>
                                <Form.Item
                                    name="inspectCourseRadio_formRequestOpenCourseReceive"
                                    rules={[{ required: true, message: 'กรุณาเลือกผลการตรวจ' }]}
                                >
                                    <Radio.Group
                                        disabled={modalInspectFormRequestOpenCourse.inspectionCourseId === 2 ? false : true}
                                        onChange={(e) => {
                                            setInspectFormRequestOpenCourseReceive({
                                                isApploval: e.target.value,
                                                isAdjust: e.target.value === 2 ? 3 : 1,
                                                reason: inspectFormRequestOpenCourseAboutOwner.reason,
                                            });
                                        }}
                                    >
                                        <Radio value={1}>ผ่าน</Radio>
                                        <Radio value={2}>ไม่ผ่าน</Radio>
                                    </Radio.Group>
                                </Form.Item>
                                {inspectFormRequestOpenCourseReceive.isApploval === 2 ?
                                    <Form.Item
                                        name="reasonNotApploval_formRequestOpenCourseReceive"
                                        rules={[{ required: true, message: 'กรุณากรอกเหตุผลที่ไม่ผ่าน' }]}
                                    >
                                        <TextArea
                                            disabled={modalInspectFormRequestOpenCourse.inspectionCourseId === 2 ? false : true}
                                            onChange={(e) => {
                                                setInspectFormRequestOpenCourseReceive({
                                                    isApploval: inspectFormRequestOpenCourseReceive.isApploval,
                                                    isAdjust: inspectFormRequestOpenCourseReceive.isAdjust,
                                                    reason: e.target.value,
                                                });
                                            }}
                                            placeholder="เหตุผลที่ไม่ผ่าน"
                                            autoSize={{ minRows: 3, maxRows: 5 }}
                                        />
                                    </Form.Item>
                                    : []}
                            </Col>
                            <Divider style={{ marginTop: 0, marginBottom: 1 }} />

                            <Col span={4} style={{ backgroundColor: "#F5F5F5", paddingTop: 5, paddingLeft: 10, paddingRight: 5 }}>
                                <label style={{ paddingRight: 10 }}>คุณมีความรู้ในเรื่องที่สอนได้อย่างไร ใช้เวลากี่ปีถึงเข้าใจได้ ?</label>
                            </Col>
                            <Col span={14}>
                                <label style={{ paddingTop: 5, paddingLeft: 10 }}>{formRequestOpenCourseDetail.formRequestOpenCourse ? formRequestOpenCourseDetail.formRequestOpenCourse.formRegOpenCourseAboutOwner : '-'}</label>
                            </Col>
                            <Col span={6} style={{ paddingTop: 5 }}>
                                <Form.Item
                                    name="inspectCourseRadio_formRequestOpenCourseAboutOwner"
                                    rules={[{ required: true, message: 'กรุณาเลือกผลการตรวจ' }]}
                                >
                                    <Radio.Group
                                        disabled={modalInspectFormRequestOpenCourse.inspectionCourseId === 2 ? false : true}
                                        onChange={(e) => {
                                            setInspectFormRequestOpenCourseAboutOwner({
                                                isApploval: e.target.value,
                                                isAdjust: e.target.value === 2 ? 3 : 1,
                                                reason: inspectFormRequestOpenCourseAboutOwner.reason,
                                            });
                                        }}
                                    >
                                        <Radio value={1}>ผ่าน</Radio>
                                        <Radio value={2}>ไม่ผ่าน</Radio>
                                    </Radio.Group>
                                </Form.Item>
                                {inspectFormRequestOpenCourseAboutOwner.isApploval === 2 ?
                                    <Form.Item
                                        name="reasonNotApploval_formRequestOpenCourseAboutOwner"
                                        rules={[{ required: true, message: 'กรุณากรอกเหตุผลที่ไม่ผ่าน' }]}
                                    >
                                        <TextArea
                                            disabled={modalInspectFormRequestOpenCourse.inspectionCourseId === 2 ? false : true}
                                            onChange={(e) => {
                                                setInspectFormRequestOpenCourseAboutOwner({
                                                    isApploval: inspectFormRequestOpenCourseAboutOwner.isApploval,
                                                    isAdjust: inspectFormRequestOpenCourseAboutOwner.isAdjust,
                                                    reason: e.target.value,
                                                });
                                            }}
                                            placeholder="เหตุผลที่ไม่ผ่าน"
                                            autoSize={{ minRows: 3, maxRows: 5 }}
                                        />
                                    </Form.Item>
                                    : []
                                }
                            </Col>
                        </Row>
                    </Form>
                    : []}
            </Modal>

            <Modal
                title="เเบบฟอร์มเกินกำหนดการปรับเเก้เเล้ว"
                visible={modalFormRequestOpenCourseIsOverdue.isOpen}
                width={500}
                maskClosable={false}
                onCancel={() => {
                    setModalFormRequestOpenCourseIsOverdue({
                        isOpen: false,
                        inspectionFormRegOpenCourseId: null,
                        uid: null,
                    });
                }}

                footer={false}
            >
                <Row>
                    <Col span={24}>
                        <label>โปรดเเจ้งผู้สอนให้ทราบ ผ่านช่องทางดังนี้</label>
                    </Col>
                    <Col span={8}>
                        <Button
                            type="primary"
                            onClick={async () => {
                                // send api updaye isOverdue to true
                                let obj = {
                                    inspectionFormRegOpenCourseId: modalFormRequestOpenCourseIsOverdue.inspectionFormRegOpenCourseId,
                                    uid: modalFormRequestOpenCourseIsOverdue.uid,
                                    isOverdue: true,
                                    inspectionCourseCategoryId: 4
                                }

                                const result = await updateInspectFormRequestOpenCourseIsOverdueFetch(obj)

                                if (result.isSuccess) {
                                    getBaseApi()
                                    Notification("success", "ระบบ", "ส่งอีเมลสำเร็จ")

                                    let courseName = formRequestOpenCourseDetail ? (formRequestOpenCourseDetail.formRequestOpenCourse ? formRequestOpenCourseDetail.formRequestOpenCourse.formRegOpenCourseName : null) : null
                                    let objEmail = {
                                        ownerName: teacherInfo ? teacherInfo.name : null,
                                        courseName,
                                    }
                                    inspectFormRequestOpenCourseOverdue(
                                        emailSupport,                               // mailgun.dreampoint.com
                                        teacherInfo ? teacherInfo.email : null,     // teacher email
                                        emailSubject + "เเจ้งผล คำขอเปิดคอร์ส " + courseName + " เกินกำหนดการปรับเเก้เเล้ว",
                                        objEmail,
                                    )

                                    // set default
                                    setModalFormRequestOpenCourseIsOverdue({
                                        isOpen: false,
                                        inspectionFormRegOpenCourseId: null,
                                        uid: null,
                                    })
                                } else {
                                    Notification("error", "ระบบ", "ส่งอีเมลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง")
                                }
                            }}
                        >ส่งอีเมลเเจ้งเตือน</Button>
                    </Col>
                    <Col span={16}>
                        <label>หรือโทรหาผู้สอน {teacherInfo ? teacherInfo.phone : "-"}</label>
                    </Col>
                </Row>

            </Modal>
        </>
    )
}
