import { server } from "../../../../../constants"
import { httpClient } from "../../../../../utils/HttpClient"

const getBannerAllFetch = async () => {
    try {
        const result = await httpClient.get(server.GET_MANAGE_BANNER_all_URL)
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const insertManageBanner = async (value, bannerImage) => {
    try {
        const result = await httpClient.post(server.INSERT_MANAGE_BANNER_URL, { value, bannerImage })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const updateManageBanner = async (value, bannerImage) => {
    try {
        const result = await httpClient.put(server.UPDATE_MANAGE_BANNER_URL, { value, bannerImage })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const updateManageBannerIsActive = async (values) => {
    try {
        const result = await httpClient.put(server.UPDATE_MANAGE_BANNER_IS_ACTIVE_URL, { values })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const deleteBannerByIdFetch = async (id) => {
    try {
        const result = await httpClient.delete(server.DELETE_BANNER_BY_ID_URL + "/" +  id )
        return result.data
    } catch (err) { // status 404
        return null
    }
}

export {
    // get
    getBannerAllFetch,

    // insert
    insertManageBanner,

    // update
    updateManageBanner,
    updateManageBannerIsActive,

    // delete
    deleteBannerByIdFetch
}
