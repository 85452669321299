/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useRef, useState } from 'react'
import {
    facebookProvider,
    googleProvider
} from '../../config/authMethods'
import socialMediaAuth from '../../service/auth'
import '../login/css/loginItem.css'
import { Row, Col, Button, Input, Form, Spin } from 'antd'
import { SiFacebook, SiGoogle } from "react-icons/si"
import firebase, { auth } from "../../config/firebase-config"
import { useNavigate, Navigate } from 'react-router-dom'
import validator from 'validator'
import { Notification } from '../../common/components/notification'
import { getEmailByUsernameFetch, getEmailPasswordIsOnDbFetch } from './API'
import { handleEnter } from '../../common/mamager/InputMamager'

export default function LoginItem() {

    const navigate = useNavigate()
    const [form] = Form.useForm()
    const [authorized, setAuthorized] = useState(null)
    const [loading, setLoading] = useState(true)
    const [loadingSignIn, setLoadingSignIn] = useState(false)
    const [loadingSendEmail, setLoadingSendEmail] = useState(false)

    const [switchMenu, setSwitchMenu] = useState(1) // 1 = login, 2 = send email to reset password, 3 = send email to reset password success
    const [reminderSendEmail, setReminderSendEmail] = useState("")
    const [reminderSignIn, setReminderSignIn] = useState("")

    const emailRef = useRef()

    const getAuthorized = async () => {

        setLoading(true)

        firebase.auth().onAuthStateChanged((user) => {
            if (user != null) {
                setAuthorized(true)
            } else {
                setAuthorized(false)
            }
        })

        setLoading(false)
    }

    const handleOnClick = async (provider) => {
        const result = await socialMediaAuth(provider)
        // console.log("socialMediaAuth : ", result)
        if (result.code === "auth/account-exists-with-different-credential") {
            Notification(
                "warning",
                "บัญชีอีเมลที่ลงชื่อซ้ำกัน",
                "ท่านมีบัญชีอยู่แล้วโดยใช้ที่อยู่อีเมลเดียวกัน แต่มีข้อมูลรับรองการลงชื่อเข้าใช้ต่างกัน ลงชื่อเข้าใช้โดยใช้ผู้ให้บริการที่เชื่อมโยงกับที่อยู่อีเมลนี้"
            )
        }
    }

    const onFinish = async (value) => {
        if (switchMenu === 1) { // login
            setLoadingSignIn(true)
            let username = value.username ? value.username : null
            let password = value.password ? value.password : null

            if (username && password) {
                let isEmail = validator.isEmail(username)
                if (!isEmail) {
                    let obj = {
                        userName: username
                    }
                    const result = await getEmailByUsernameFetch(obj)
                    if (result?.length > 0) {
                        username = result
                    }
                }

                const result = await auth.signInWithEmailAndPassword(
                    username,
                    password
                ).then(user => {
                    return user
                }).catch(error => {
                    return error
                })

                if (result.code === "auth/wrong-password" || result.code === "auth/invalid-email") {
                    setReminderSignIn("ชื่อผู้ใช้งาน หรือรหัสผ่านไม่ถูกต้อง")
                }
            }
            setLoadingSignIn(false)
        } else if (switchMenu === 2) { // Send email to reset password
            setLoadingSendEmail(true)
            let email = value.email ? value.email : null
            if (email) {
                let obj = {
                    email
                }
                let resultEP = await getEmailPasswordIsOnDbFetch(obj)
                // console.log("resultEP : ", resultEP, email)
                if (resultEP) {
                    const result = await auth.sendPasswordResetEmail(email).then(user => {

                        return {
                            code: "auth/send-email-success",
                        }
                    }).catch(error => {
                        return error
                    })
                    if (result?.code === "auth/invalid-email") {
                        setReminderSendEmail("อีเมลไม่ถูกต้อง")
                    } else if (result?.code === "auth/send-email-success") {
                        setSwitchMenu(3)
                    }
                } else {
                    setReminderSendEmail("ไม่พบอีเมลนี้ในระบบ")
                }
            }
            setLoadingSendEmail(false)
        }
    }

    useEffect(() => {
        if (authorized === null) {
            getAuthorized()
        }
    }, [authorized])

    // useEffect(() => {

    // }, [reminderSignIn])

    useEffect(() => {

    }, [reminderSendEmail])

    const login = () => {
        return (
            <>
                {!loadingSignIn ?
                    <Form form={form} layout="vertical" onFinish={onFinish} >
                        <Row gutter={[10, 10]}>

                            <Col span={24} style={{ paddingTop: 10 }}>
                                <Button
                                    size="large"
                                    type="primary"
                                    // onClick={() => alert("การเข้าสู่ระบบด้วย Facebook อยู่ระหว่างการปรับปรุง เเละจะเปิดให้ใช้งานปกติเร็ว ๆ นี้ ขอภัยในความไม่สะดวก")}
                                    onClick={() => handleOnClick(facebookProvider)}
                                    style={{ width: '100%', color: "white", height: 40.15 }}
                                >
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: "pointer" }}>
                                        <SiFacebook style={{ cursor: "pointer" }} />
                                        <label style={{ paddingLeft: 10, cursor: "pointer" }}>Facebook</label>
                                    </div>
                                </Button>
                            </Col>

                            <Col span={24} style={{ paddingTop: 10 }}>
                                <Button
                                    size="large"
                                    type="primary"
                                    onClick={() => handleOnClick(googleProvider)}
                                    danger
                                    style={{ width: '100%', color: "white", height: 40.15 }}
                                >
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: "pointer" }}>
                                        <SiGoogle style={{ cursor: "pointer" }} />
                                        <label style={{ paddingLeft: 10, cursor: "pointer" }}>Google</label>
                                    </div>
                                </Button>
                            </Col>

                            <Col span={24} style={{ textAlign: "center" }}>
                                <label style={{ fontSize: '1rem' }}>หรือ</label>
                            </Col>

                            <Col span={24} style={{ height: 60 }}>
                                <Form.Item
                                    name="username"
                                    style={{ width: '100%' }}
                                    rules={[{ required: true, message: <span style={{ float: "left" }}>กรุณากรอกอีเมล หรือชื่อผู้ใช้งาน</span> }]}
                                >
                                    <Input
                                        placeholder="อีเมล หรือชื่อผู้ใช้"
                                        size="large"
                                        onKeyDown={handleEnter}
                                    />
                                </Form.Item>
                            </Col>

                            <Col span={24} style={{ height: 60 }}>
                                <Form.Item
                                    name="password"
                                    style={{ width: '100%' }}
                                    rules={[{ required: true, message: <span style={{ float: "left" }}>กรุณากรอกรหัสผ่าน</span> }]}
                                >
                                    <Input.Password
                                        placeholder="รหัสผ่าน"
                                        size="large"
                                        onKeyDown={(e) => {
                                            if (e.keyCode === 13) {
                                                form.submit()
                                            }
                                        }}
                                    />
                                </Form.Item>
                            </Col>

                            <Col span={24} style={{}}>
                                <span style={{ color: "red", fontSize: '1.2rem', float: "left" }}>{reminderSignIn}</span>
                            </Col>

                            <Col span={24} style={{}}>
                                <Button
                                    size="large"
                                    type="primary"
                                    style={{ width: "100%" }}
                                    onClick={() => form.submit()}
                                >เข้าสู่ระบบ</Button>
                            </Col>

                            <Col span={24} style={{ paddingTop: 10, textAlign: "center" }}>
                                <Button
                                    type='default'
                                    size="large"
                                    style={{ color: '#23B14D', width: "100%" }}
                                    onClick={() => {
                                        setSwitchMenu(2)
                                        form.setFieldsValue({
                                            email: undefined
                                        })
                                    }}
                                >ลืมรหัสผ่าน</Button>
                            </Col>

                            <Col span={24} style={{ paddingTop: 10, textAlign: "center" }}>
                                <Button
                                    type='default'
                                    size="large"
                                    style={{ color: '#23B14D', width: "100%" }}
                                    onClick={() => {
                                        navigate(
                                            "/register",
                                            {
                                                state: {
                                                    authorized: null,
                                                    displayName: null,
                                                    email: null,
                                                    phoneNumber: null,
                                                    photoURL: null,
                                                    providerId: "email/password",
                                                    socialMediaId: null,
                                                    uid: null,
                                                    access: true
                                                },
                                                replace: true
                                            }
                                        )
                                    }}
                                >สมัครสมาชิก</Button>
                            </Col>
                        </Row >
                    </Form >
                    :
                    <center>
                        <div style={{ paddingTop: 210, paddingBottom: 210 }}><Spin size="large" /></div>
                    </center>
                }
            </>

        )
    }

    const sendEmailResetPassword = () => {
        return (
            <>
                {!loadingSendEmail ?
                    <Form form={form} layout="vertical" onFinish={onFinish} >
                        <Row gutter={[10, 10]}>
                            <Col span={24} style={{}}>
                                <label style={{ fontSize: '1.2rem', float: "left" }}>ใส่อีเมลของคุณ เพื่อรีเซ็ตรหัสผ่าน</label>
                            </Col>
                            <Col span={24} style={{ height: 50, paddingTop: 2 }}>
                                <Form.Item
                                    name="email"
                                    style={{ width: '100%' }}
                                    rules={[{ required: true, message: <span style={{ float: "left" }}>กรุณากรอกอีเมล</span> }]}
                                >
                                    <Input ref={emailRef} placeholder="อีเมล" size="large" />
                                </Form.Item>

                            </Col>
                            <Col span={24} style={{}}>
                                <span style={{ color: "red", float: "left" }}>{reminderSendEmail}</span>
                            </Col>
                            <Col span={24} style={{}}>
                                <Button
                                    size="large"
                                    type="primary"
                                    style={{ width: "100%" }}
                                    loading={loading}
                                    onClick={() => {
                                        form.submit()
                                    }}
                                >
                                    {!loading ? "รีเซ็ตรหัสผ่าน" : []}
                                </Button>
                            </Col>
                            <Col span={24} style={{ textAlign: "center" }}>
                                <label
                                    style={{ color: '#23B14D', fontSize: '1.2rem', cursor: "pointer" }}
                                    onClick={() => setSwitchMenu(1)}
                                >ยกเลิก</label>
                            </Col>
                        </Row>
                    </Form>
                    :
                    <center>
                        <div style={{ paddingTop: 80, paddingBottom: 80 }}><Spin size="large" /></div>
                    </center>
                }
            </>
        )
    }

    const sendEmailResetPasswordSuccess = () => {
        return (
            <Row gutter={[10, 10]}>
                <Col span={24} style={{ float: "left" }}>
                    <label style={{ fontSize: '1.2rem' }}>เราได้ส่งลิงค์ รีเซ็ตรหัสผ่านไปให้เเล้ว โปรดตรวจสอบอีเมลของคุณ</label>
                </Col>
                <Col span={24} style={{ textAlign: "center" }}>
                    <label
                        style={{ color: '#23B14D', fontSize: '1.2rem', cursor: "pointer" }}
                        onClick={() => setSwitchMenu(1)}
                    >กลับไป เข้าสู่ระบบ</label>
                </Col>
            </Row>
        );
    }

    return (
        <>
            {!loading ?
                <>
                    {authorized ?
                        <>
                            <Navigate to={{ pathname: "/login-detect" }} />
                        </>
                        :
                        <>
                            {switchMenu === 1 ? login() : []}
                            {switchMenu === 2 ? sendEmailResetPassword() : []}
                            {switchMenu === 3 ? sendEmailResetPasswordSuccess() : []}
                        </>
                    }
                </>
                :
                <center>
                    <div style={{ paddingTop: 20, paddingBottom: 20 }}>
                        <Spin size="large" />
                    </div>
                </center>
            }
        </>
    )
}
