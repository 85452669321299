import { server } from "../../../constants"
import { httpClient } from "../../../utils/HttpClient"

const getSalepageByIdFetch = async (courseId) => { 
    try {
        const result = await httpClient.get(server.GET_SALEPAGE_BY_ID_URL + `/${courseId}`)
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const insertSalepageImage = async (value) => { 
    try {
        const result = await httpClient.post(server.INSERT_SALEPAGE_IMAGE_URL, value)
        return result
    } catch (err) { // status 404
        return null
    }
}

const insertSalepageImageItem = async (value) => {
    try {
        const result = await httpClient.post(server.INSERT_SALEPAGE_IMAGE_ITEM_URL, value)
        return result
    } catch (err) { // status 404
        return null
    }
}

const insertSalepageVideo = async (value) => {
    try {
        const result = await httpClient.post(server.INSERT_SALEPAGE_VIDEO_URL, value)
        return result
    } catch (err) { // status 404
        return null
    }
}

const insertSalepageImageVideo = async (value) => {
    try {
        const result = await httpClient.post(server.INSERT_SALEPAGE_VIDEO_ITEM_URL, value)
        return result
    } catch (err) { // status 404
        return null
    }
}

export {
    // get
    getSalepageByIdFetch,

    // insert
    insertSalepageImage,
    insertSalepageImageItem,
    insertSalepageVideo,
    insertSalepageImageVideo
}