import { iconCRL } from './IconCRL'

export default function CourseCRL(props) {
    return (
        <div
            style={{ 
                borderTop: "1px solid #505050",
                // borderRight: "1px solid #505050",
                backgroundColor: props.color ? props.color : "black",
                color: "white", 
                padding: "12px",
            }}
        >
            {iconCRL(props.learnerCount, 40, 40, true)}
        </div>
    )
}
