/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable react-hooks/exhaustive-deps */

import "../../css/manageVideoItem.css"
import { useState, useEffect } from 'react'
import { Icon } from '@iconify/react'
import { AiOutlineQuestionCircle } from "react-icons/ai"
import { FiEdit } from "react-icons/fi"
import { GiHamburgerMenu } from "react-icons/gi"
import { LoadingOutlined, UploadOutlined } from '@ant-design/icons'
import ImgCrop from 'antd-img-crop'
import { useDispatch, useSelector } from "react-redux"
import {
    Button,
    Image,
    Row,
    Col,
    Modal,
    Form,
    Input,
    Upload,
    Popconfirm,
    Popover,
    Select,
    InputNumber,
} from 'antd'
import moment from 'moment'
import { AiOutlineUpload } from 'react-icons/ai'
import axios from 'axios'
import { setCourseManageVideoListStateToSuccess } from "../../../../actions/courseManageVideoList.action"
import { Notification } from "../../../../common/components/notification"
import Video from "../../../../common/components/video"
import { PathVideo } from "../../../../common/components/video/components/convert/pathVideo"
import CoverImage from "../../../../common/components/video/components/coverImage"
import { convertBase64ToFile } from "../../../../common/mamager/ImageMamager"
import { useDimensions } from "../../../../common/mamager/ScreenManager"
import { apiStorageUrl, bucketName, storageUrl } from "../../../../constants"

const { Option } = Select
const { TextArea } = Input

export default function ManageVideoItem(props) {

    const { resultPrice } = useSelector(({ courseManagePriceReducer }) => courseManagePriceReducer)
    const { resultVideoList } = useSelector(({ courseManageVideoListReducer }) => courseManageVideoListReducer)
    const { resultChapter } = useSelector(({ courseManageChapterReducer }) => courseManageChapterReducer)

    const [formEdit] = Form.useForm()

    const { width } = useDimensions()

    const [modalEdit, setModalEdit] = useState(false)
    const [modalVideoCoverImage, setModalVideoCoverImage] = useState(false)

    const [courseChapter, setCourseChapter] = useState([])

    const dispatch = useDispatch()

    const [duration, setDuration] = useState({
        timeVideo: null,
        displayTimeVideo: null
    })

    const [inspectVideo, setInspectVideo] = useState([])
    const [loading, setLoading] = useState(true)

    const [videoCourse, setVideoCourse] = useState({
        loading: false,
        videoUrl: null
    })

    const [videoCourseCoverImage, setVideoCourseCoverImage] = useState({
        imageUrl: null,
        loading: false
    })

    const optionVideo = {
        name: 'file',
        action: `${apiStorageUrl}/upload/file`,
        data: {
            // uploaded/video/course-video-list
            bucket: bucketName,
            folder: "video/course/content"
        },
        headers: {
            authorization: 'authorization-text',
        },
        onChange(info) {
            if (info.file.status === 'done') {
                let result = info.file.response;
                console.log("info : ", info.file.response)
                if (result?.isSuccess) {
                    setVideoCourse({
                        videoUrl: result.url,
                        loading: false
                    })
                }
            } else {
                setVideoCourse({
                    loading: true,
                    videoUrl: videoCourse.videoUrl
                })
            }

            if (info.file.status === 'done') {
                Notification("success", "ระบบ", "อัพโหลดวิดีโอคอร์สสำเร็จ")
            } else if (info.file.status === 'error') {
                Notification("error", "ระบบ", "ไม่สามารถอัพโหลดวิดีโอคอร์สได้ กรุณาตรวจสอบข้อมูล")
            }
        },
        progress: {
            strokeColor: {
                '0%': '#108ee9',
                '100%': '#87d068',
                width: 100,
            },
            strokeWidth: 3,
            width: '10%',
            format: percent => `${parseFloat(percent.toFixed(0))}%`,
        },
    }

    const optionCoverVideo = {
        name: 'file',
        action: `${apiStorageUrl}/upload/file`,
        data: {
            // uploaded/img/course-video-list-cover
            bucket: bucketName,
            folder: "image/course/content-cover"
        },
        headers: {
            authorization: 'authorization-text',
        },
        onChange(info) {
            if (info.file.status === 'done') {
                let result = info.file.response;
                console.log("info : ", info.file.response)
                if (result?.isSuccess) {
                    setVideoCourseCoverImage({
                        imageUrl: result.url,
                        loading: false
                    });
                }
            } else {
                setVideoCourseCoverImage({ loading: true });
            }

            if (info.file.status === 'done') {
                Notification("success", "ระบบ", "อัพโหลดภาพปกวิดีโอคอร์สสำเร็จ");
            } else if (info.file.status === 'error') {
                Notification("error", "ระบบ", "ไม่สามารถอัพโหลดภาพปกวิดีโอคอร์สได้ กรุณาตรวจสอบข้อมูล");
            }
        },
        progress: {
            strokeColor: {
                '0%': '#108ee9',
                '100%': '#87d068',
            },
            strokeWidth: 3,
            format: percent => `${parseFloat(percent.toFixed(2))}%`,
        },
    }

    // select image from video duration 
    const handleCaptureCoverImage = (imgBase64) => {
        const fileToUpload = convertBase64ToFile(imgBase64, 'capture-video.png')
        const formData = new FormData()
        formData.append('file', fileToUpload)
        formData.append('name', (props.user ? props.user.uid : "my-user"))
        formData.append('path', "uploaded/img/course-video-list-cover")

        const config = {
            authorization: 'authorization-text'
        }

        axios
            .post(`${apiStorageUrl}/upload/file`, formData, config)
            .then((res) => {
                // console.log("res : ", res);

                setVideoCourseCoverImage({
                    imageUrl: res?.data?.filePath,
                    loading: false
                })

                Notification("success", "ระบบ", "อัพโหลดภาพปกวิดีโอคอร์สสำเร็จ")
            })
            .catch((err) => {
                Notification("error", "ระบบ", "ไม่สามารถอัพโหลดภาพปกวิดีโอคอร์สได้ กรุณาตรวจสอบข้อมูล")
            }
            )

        // close modal
        setModalVideoCoverImage(false)
    }

    // console.log("SS : ", props.courseVideoId, props.courseChapterId)

    const onFinishVideoEdit = (values) => {
        let tempVideoList = resultVideoList.map(val => {
            // let approvalStatusName = props.approvalStatusName
            // let approvalStatusId = props.approvalStatusId
            // if (props.approvalStatusId === 1) {
            //     approvalStatusName = "เเก้ไขวิดีโอ ยังไม่ถูกบันทึก"
            //     approvalStatusId = 4
            // }

            return (val.courseVideoId === props.courseVideoId ? {
                approvalStatusName: "เเก้ไขวิดีโอ ยังไม่ถูกบันทึก",
                approvalStatusId: 4,
                courseVideoId: props.courseVideoId,
                courseVideoImgLocation: videoCourseCoverImage.imageUrl,
                courseVideoLocation: videoCourse.videoUrl,
                courseVideoTitleName: values.videoTitle,
                courseVideoDescriptionName: values.videoDescription,
                courseVideoPrice: values?.videoPrice ? values.videoPrice : null,
                courseVideoDuration: duration.timeVideo, // props.courseVideoDuration,
                courseChapterId: props.courseChapterId,
                isUpdate: true,
                created_at: props.created_at,
                updated_at: moment(),
                view: props.view, // *** 

                // inspect
                inspectCourseApprovalId: props.inspectCourseApprovalId,
                inspectCourseApprovalName: props.inspectCourseApprovalName,
                reasonCourseNotApproval: props.reasonCourseNotApproval,
            } : val)
        })

        // reducer
        dispatch(setCourseManageVideoListStateToSuccess(tempVideoList))

        // notify
        Notification("success", "ระบบ", "เเก้ไขรายการวิดีโอคอร์สสำเร็จ")

        // set default
        setDefaultVideo()

        getSummaryVideoPrice(tempVideoList)
    }

    const getSummaryVideoPrice = (data) => {
        let videoAmount = data ? data?.length : 0

        let coursePrice = 0;
        if (props.switchType === "add-course") {
            coursePrice = (resultPrice ? resultPrice?.price : 0)
        } else if (
            props.switchType === "adjust-course" ||
            props.switchType === "edit-course"
        ) {
            coursePrice = (props.coursePrice ? props.coursePrice : 0)
        }
        // console.log("coursePrice : ", coursePrice)

        let hasHappened = 0
        let remaining = 0

        data?.map(val => {
            hasHappened += (val?.courseVideoPrice ? val.courseVideoPrice : 0)
        });
        remaining = coursePrice - hasHappened

        props.reloadSummaryVideoPrice({
            videoAmount,
            coursePrice,
            hasHappened,
            remaining
        })
    }

    const onChapterEdit = (e) => {
        let tempVideoList = resultVideoList.map(val => {
            return (val.courseVideoId === props.courseVideoId ? {
                approvalStatusName: "เเก้ไขวิดีโอ ยังไม่ถูกบันทึก",
                approvalStatusId: 4,
                courseVideoId: props.courseVideoId,
                courseVideoImgLocation: videoCourseCoverImage.imageUrl,
                courseVideoLocation: videoCourse.videoUrl,
                courseVideoTitleName: props.courseVideoTitleName,
                courseVideoDescriptionName: props.courseVideoDescriptionName,
                courseVideoPrice: props.courseVideoPrice,
                courseVideoDuration: duration.timeVideo,// props.courseVideoDuration,
                courseChapterId: e,
                isUpdate: true,
                created_at: props.created_at,
                updated_at: moment(),
                view: props.view, // *** 

                // inspect
                inspectCourseApprovalId: props.inspectCourseApprovalId,
                inspectCourseApprovalName: props.inspectCourseApprovalName,
                reasonCourseNotApproval: props.reasonCourseNotApproval,
            } : val)
        })
        // console.log("tempVideoList : ", tempVideoList)

        // reducer
        dispatch(setCourseManageVideoListStateToSuccess(tempVideoList))
    }

    const setDefaultVideo = () => {
        // close modal
        setModalEdit(false)
    }

    const onRemoveVideo = () => {
        let tempResultVideoList

        let approvalStatusName = props.approvalStatusName
        let approvalStatusId = props.approvalStatusId

        let tempVideoId = String(props.courseVideoId).split("-=|=-") // split a string using "-=|=-" form add video

        if (tempVideoId[0] !== "temporary") { // delete pending check
            approvalStatusName = "ลบวิดีโอ ยังไม่ถูกบันทึก"
            approvalStatusId = 5

            tempResultVideoList = resultVideoList.map(val => {
                return (val.courseVideoId === props.courseVideoId ? {
                    approvalStatusName,
                    approvalStatusId,
                    courseVideoId: props.courseVideoId,
                    courseVideoImgLocation: props.courseVideoImgLocation,
                    courseVideoLocation: props.courseVideoLocation,
                    courseVideoTitleName: props.courseVideoTitleName,
                    courseVideoDescriptionName: props.courseVideoDescriptionName,
                    courseVideoPrice: props.courseVideoPrice,
                    courseVideoDuration: duration.timeVideo,//props.courseVideoDuration,
                    courseChapterId: props.courseChapterId,
                    isUpdate: true,
                    view: props.view, // *** 
                    created_at: props.created_at,
                    updated_at: new Date(),
                } : val)
            })

            // notify
            Notification("success", "ระบบ", "ส่งคำขอลบวิดีโอคอร์สสำเร็จ")

            // in future open log to admin approve 
            // ...
        } else { // delete manually
            tempResultVideoList = resultVideoList.filter(val => props.courseVideoId !== val.courseVideoId)
        }

        // reducer
        setReducer(tempResultVideoList)

        // set default
        formEdit.resetFields()
        setModalEdit(false)
    }

    const onCancelRemoveVideo = async () => {
        let approvalStatusName = "ยกเลิกการลบวิดีโอ กลับมาใช้งานเหมือนเดิม"
        let approvalStatusId = 1

        let tempResultVideoList = resultVideoList.map(val => {
            return (val.courseVideoId === props.courseVideoId ? {
                approvalStatusName,
                approvalStatusId,
                courseVideoId: props.courseVideoId,
                courseVideoImgLocation: props.courseVideoImgLocation,
                courseVideoLocation: props.courseVideoLocation,
                courseVideoTitleName: props.courseVideoTitleName,
                courseVideoDescriptionName: props.courseVideoDescriptionName,
                courseVideoPrice: props.courseVideoPrice,
                courseVideoDuration: duration.timeVideo,//props.courseVideoDuration,
                courseChapterId: props.courseChapterId,
                isUpdate: true,
                view: props.view, // *** 
                created_at: props.created_at,
                updated_at: new Date(),
            } : val)
        })
        setReducer(tempResultVideoList)
        Notification("success", "ระบบ", "ยกเลิกการลบวิดีโอสำเร็จ")
    }

    const setReducer = (data) => {
        // reducer
        dispatch(setCourseManageVideoListStateToSuccess(data))
    }

    const detectVideoCourseCoverImage = () => {
        if (videoCourseCoverImage.imageUrl) {
            return Promise.resolve()
        } else {
            return Promise.reject(new Error('กรุณาอัพโหลดภาพปกวิดีโอ'))
        }
    };

    const detectVideoCourse = () => {
        if (videoCourse.videoUrl) {
            return Promise.resolve()
        } else {
            return Promise.reject(new Error('กรุณาอัพโหลดวิดีโอ'))
        }
    };

    const handleTimeVideo = (duration) => {
        setDuration(duration)
    }

    const handlePercentWatchVideoCourse = (timeVideoCourse, timerWatchVideoCourse, percent, isFirstLoadApi, VideoCourseList, timerWatchVideoCourseList) => { }
    const handlePlayer = (player) => { }
    const handleControls = (controls) => { }

    useEffect(() => {
        setLoading(true)

        // video
        setVideoCourse({
            videoUrl: props.courseVideoLocation,
            loading: false,
        })

        formEdit.setFieldsValue({
            videoTitle: props.courseVideoTitleName,
            videoDescription: props.courseVideoDescriptionName,
            videoPrice: props.courseVideoPrice
        })

        // image of video
        setVideoCourseCoverImage({
            imageUrl: props.courseVideoImgLocation,
            loading: false
        })

        if (props.courseInspect?.inspectionCourseVideoId) {
            let courseVideo = props.courseInspect.courseVideo ? (props.courseInspect.courseVideo.formData.find(val => val.id === props.courseVideoId)) : null; // inspectionCourseApprovalId
            setInspectVideo({
                inspectionCourseId: props.courseInspect.inspectionCourseId,
                inspectionCourseApprovalId: courseVideo ? courseVideo.inspectionCourseApprovalId : null
            })
        }

        setLoading(false)
    }, [])

    useEffect(() => {
    }, [videoCourse])

    useEffect(() => {
        let tmpCourseChapter = []
        for (let i = 0; i < resultChapter?.length; i++) {
            tmpCourseChapter.push({
                value: resultChapter[i].courseChapterId,
                label: resultChapter[i].courseChapterTitleName
            })
        }
        setCourseChapter(tmpCourseChapter)
    }, [resultChapter, props.courseChapter])

    const genVideoApprovalDisplay = (approvalId, approvalName) => {
        return (
            <p
                style={
                    approvalId === 1 ? { color: "green" }
                        : (approvalId === 3 || approvalId === 4 ? { color: "orange" } : { color: "red" })
                }
            >
                {approvalName}
            </p>
        )
    }

    const genBtnEditVideo = () => {
        return (
            <div
                style={{ backgroundColor: '#23B14D', borderRadius: 20, cursor: 'pointer', width: 30, height: 30, display: "flex", alignItems: "center", justifyContent: "center" }}
                onClick={() => {
                    setModalEdit(true)
                }}
            >
                <FiEdit
                    size={16}
                    style={{ color: 'white', cursor: 'pointer' }}
                    disabled={
                        props.switchType === "adjust-course" ?
                            (props.isFormDisable ? true : (props.approvalStatusId === 5 ? true : false))
                            :
                            (props.switchType === "edit-course" ?
                                (props.courseInspect ?
                                    (props.courseInspect.inspectionCourseId === 2 && props.courseInspect.inspectionCourseApprovalId === 2 ? true : false) : false) : false)
                    }
                />
            </div>
        )
    }

    return (
        <>
            {!loading ?
                <div style={{ fontSize: 14 }}>
                    <Row>

                        <Col xs={2} sm={1} md={1} lg={1} style={{ paddingLeft: 7 }}>
                            <GiHamburgerMenu />
                            <div style={{ paddingLeft: 3 }}>{(props.index + 1)}</div>
                        </Col>

                        <Col xs={8} sm={6} md={5} lg={4} style={{ paddingLeft: 7 }}>
                            <Image
                                style={{ height: 35, marginTop: 5, border: '1px solid #E8E8E8' }}
                                src="error"
                                fallback={`${props.courseVideoImgLocation}`}
                            />
                        </Col>

                        <Col xs={10} sm={17} md={18} lg={19} style={{ textAlign: 'left' }} >
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                <div>
                                    <p>{props.courseVideoTitleName}</p>
                                    {(props.switchType === "adjust-course" || props.switchType === "edit-course") ?
                                        <>
                                            {props.inspectCourseApprovalId ?
                                                genVideoApprovalDisplay(props.inspectCourseApprovalId, props.inspectCourseApprovalName)
                                                :
                                                genVideoApprovalDisplay(props.approvalStatusId, props.approvalStatusName)
                                            }
                                        </>
                                        :
                                        genVideoApprovalDisplay(props.approvalStatusId, props.approvalStatusName)
                                    }
                                </div>

                                <div>
                                    {   /* cancel delete video */
                                        props.switchType === "edit-course" &&
                                            inspectVideo.inspectionCourseId === 3 &&
                                            inspectVideo.inspectionCourseApprovalId === 2 &&
                                            props.approvalStatusId === 5 ?
                                            <Button
                                                type="primary"
                                                onClick={() => {
                                                    // alert("ยกเลิกลบวิดีโอ");
                                                    onCancelRemoveVideo();
                                                }}
                                            >
                                                ยกเลิกการลบ
                                            </Button>
                                            :
                                            <>
                                                {props.switchType === "adjust-course" ?
                                                    <>
                                                        {props.courseInspect.inspectionCourseVideoId === 2 ?
                                                            <div style={{ backgroundColor: 'gray', borderRadius: 20, cursor: 'pointer', width: 30, height: 30, display: "flex", alignItems: "center", justifyContent: "center" }}><FiEdit size={16} style={{ color: 'white' }} /></div>
                                                            :
                                                            (props.courseInspect ?
                                                                (props.courseInspect.courseVideo.inspectionCourseApprovalIdSummary === 1 ?
                                                                    <div style={{ backgroundColor: 'gray', borderRadius: 20, cursor: 'pointer', width: 30, height: 30, display: "flex", alignItems: "center", justifyContent: "center" }}><FiEdit size={16} style={{ color: 'white' }} /></div>
                                                                    :
                                                                    <>
                                                                        {props.inspectCourseApprovalId === 1 ?
                                                                            <div style={{ backgroundColor: 'gray', borderRadius: 20, cursor: 'pointer', width: 30, height: 30, display: "flex", alignItems: "center", justifyContent: "center" }}><FiEdit size={16} style={{ color: 'white' }} /></div>
                                                                            :
                                                                            genBtnEditVideo()
                                                                        }
                                                                    </>
                                                                )
                                                                :
                                                                false
                                                            )
                                                        }
                                                    </>
                                                    :
                                                    (props.switchType === "edit-course" ?
                                                        (props.courseInspect ?
                                                            (props.courseInspect.inspectionCourseId !== 2 && props.courseInspect.inspectionCourseApprovalId !== 2 ?
                                                                genBtnEditVideo()
                                                                :
                                                                ((props.approvalStatusId === 1 || props.approvalStatusId === 4) && props.courseInspect.inspectionCourseId === 3 ?
                                                                    genBtnEditVideo()
                                                                    :
                                                                    (!props.courseInspect.inspectCourseApprovalId && !props.courseInspect.courseVideo.inspectionCourseAdjustIdSummary && props.courseInspect.inspectionCourseId === 2 ?
                                                                        genBtnEditVideo()
                                                                        :
                                                                        (props.courseInspect.inspectionCourseId === 3 ?
                                                                            genBtnEditVideo()
                                                                            :
                                                                            <div style={{ backgroundColor: 'gray', borderRadius: 20, cursor: 'pointer', width: 30, height: 30, display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                                                <FiEdit size={16} style={{ color: 'white' }} />
                                                                            </div>
                                                                        )
                                                                    )
                                                                )
                                                            )
                                                            :
                                                            genBtnEditVideo()
                                                        )
                                                        :
                                                        genBtnEditVideo()
                                                    )
                                                }
                                            </>
                                    }
                                </div>
                            </div>
                        </Col>

                        {props.isPaymentByVideo ?
                            <Col span={24} style={{ paddingTop: 5 }}>
                                <div style={{ borderTop: '1px solid #EEEEEE', paddingTop: 7 }}>
                                    <label style={{ paddingLeft: 7 }}>ราคาวิดีโอ {Intl.NumberFormat('en', { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(Number(props.courseVideoPrice))} บาท</label>
                                </div>
                            </Col>
                            : []}

                        <Col span={24} style={{ paddingTop: 5 }}>
                            <div style={{ borderTop: '1px solid #EEEEEE', paddingTop: 7 }}>
                                <Select
                                    defaultValue={props.courseChapterId ? props.courseChapterId : undefined}
                                    style={{ width: 300, float: "right" }}
                                    placeholder={"ไม่มีบท"}
                                    allowClear
                                    showSearch
                                    onChange={(e) => {
                                        onChapterEdit(e);
                                    }}
                                >
                                    {courseChapter.map((val, index) => <Option key={index} value={val.value}>{val.label}</Option>)}
                                </Select>
                            </div>
                        </Col>
                    </Row>

                    {
                        (props.courseInspect ? ((props.inspectCourseApprovalId === 2 && props.courseInspect.inspectionCourseVideoId === 1 && props.courseInspect.inspectionCourseId === 3) ||
                            (props.courseInspect.amountEdit > 0 && props.inspectCourseApprovalId === 2)) : false) ?
                            <Row
                                style={{
                                    paddingTop: 12,
                                    paddingBottom: 9,
                                    fontSize: 14
                                }}
                            >
                                <Col
                                    span={24}
                                    style={{ borderTop: "1px solid #EEEEEE", paddingTop: 5, paddingLeft: 5, paddingRight: 5, paddingBottom: 1 }}
                                >
                                    เหตุผลไม่ผ่าน
                                    <Popover content={<div style={{ width: 250 }}>ผลตรวจ ที่ทาง Toflyn เเจ้งให้ผู้สอนปรับเเก้ เเละส่งกลับมาใหม่อีกครั้งในเวลาที่กำหนด</div>}>
                                        <AiOutlineQuestionCircle style={{ marginLeft: 5, cursor: 'pointer' }} />
                                    </Popover>
                                </Col>
                                <Col
                                    span={24}
                                    style={{ paddingBottom: 5, paddingLeft: 5, paddingRight: 5 }}
                                >
                                    <div style={{ backgroundColor: "#EEEEEE", padding: 5 }}>
                                        <p style={{ margin: 0, color: "red" }}>{props.reasonCourseNotApproval}</p>
                                    </div>
                                </Col>
                            </Row>
                            : []
                    }
                </div>
                : []
            }

            {/* Start modal edit video */}
            <Modal
                title="เเก้ไขวิดีโอ"
                visible={modalEdit}
                width={width > 992 ? "60%" : "90%"}
                maskClosable={false}
                onCancel={() => {
                    // set default
                    setDefaultVideo()
                }}

                footer={[
                    <Button
                        key="cancel"
                        style={width > 480 ? { width: 130 } : { width: "100%" }}
                        onClick={() => {
                            // set default
                            setDefaultVideo()
                        }}
                        disabled={props.approvalStatusId === 5 ? true : false}
                    >
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <Icon icon="mdi:cancel" />
                            <label style={{ paddingLeft: 10, cursor: "pointer" }}>ยกเลิก</label>
                        </div>
                    </Button>,
                    <Button
                        key="save"
                        style={width > 480 ? { width: 130 } : { width: "100%", margin: 0, marginTop: 8 }}
                        type="primary"
                        onClick={() => formEdit.submit()}
                        disabled={props.approvalStatusId === 5 ? true : false}
                    >
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <Icon icon="bx:edit-alt" />
                            <label style={{ paddingLeft: 10, cursor: "pointer" }}>บันทึก</label>
                        </div>
                    </Button>,

                    <Popconfirm
                        title="คุณต้องการลบวิดีโอหรือไม่ ?"
                        okText={<span style={{ width: 50 }}>ใช่</span>}
                        onConfirm={() => onRemoveVideo()}
                        cancelText={<span style={{ width: 50 }}>ไม่ใช่</span>}
                        disabled={props.approvalStatusId === 5 ? true : false}
                    >
                        <Button
                            key="delete"
                            danger
                            type="primary"
                            style={props.approvalStatusId === 5 ?
                                {
                                    marginLeft: width > 480 ? 8 : 0,
                                    width: width > 480 ? 110 : "100%",
                                    marginTop: width > 480 ? 0 : 8
                                }
                                :
                                {
                                    marginLeft: width > 480 ? 8 : 0,
                                    width: width > 480 ? 130 : "100%",
                                    marginTop: width > 480 ? 0 : 8
                                }
                            }
                            disabled={props.approvalStatusId === 5 ? true : false}
                        >
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <Icon icon="fluent:delete-24-regular" style={{ color: "white" }} />
                                <label style={{ paddingLeft: 10, cursor: "pointer" }}>ลบวิดีโอ</label>
                            </div>
                        </Button>
                    </Popconfirm>
                ]}
            >
                <Form
                    layout="vertical"
                    form={formEdit}
                    onFinish={onFinishVideoEdit}
                >
                    <Row>
                        <Col xs={24} lg={24} xl={11}>
                            <Col span={24} style={{ paddingLeft: 0 }}>
                                <Row>
                                    <Col span={24}>
                                        {videoCourse.videoUrl ?
                                            <Video
                                                courseId={null}
                                                learnerId={null}

                                                videoTitle=""
                                                videoLocation={PathVideo(videoCourse.videoUrl)}
                                                component={"upload-video"}
                                                handleTimeVideo={handleTimeVideo}
                                                handlePercentWatchVideoCourse={handlePercentWatchVideoCourse}
                                                handlePlayer={handlePlayer}
                                                handleControls={handleControls}
                                            />
                                            :
                                            <img
                                                style={{ width: '100%', border: "1px solid #EEEEEE" }}
                                                src={`./assets/images/default/df-vdo.png`}
                                            />
                                        }
                                    </Col>

                                    <Col span={24}>
                                        <div style={{ paddingTop: 12 }}>
                                            <Form.Item
                                                name="uploadVideoCourse"
                                                rules={[{ required: true, validator: detectVideoCourse }]}
                                            >
                                                <Upload
                                                    {...optionVideo}
                                                    accept='.mp4'
                                                    maxCount={1}
                                                    showUploadList={{ showRemoveIcon: false }}
                                                >
                                                    <Button
                                                        type="primary"
                                                        style={{ width: '100%' }}
                                                        icon={videoCourse.loading ? <LoadingOutlined /> : <UploadOutlined />}
                                                        disabled={props.approvalStatusId === 5 ? true : false}
                                                    >อัพโหลดวิดีโอ</Button>
                                                </Upload>
                                            </Form.Item>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>

                            <Col span={24} style={{ paddingBottom: 25, paddingRight: 0 }}>
                                <Row gutter={[12, 0]}>
                                    <Col span={24}>
                                        {videoCourseCoverImage.imageUrl ?
                                            <img
                                                style={{ width: '100%', border: "1px solid #EEEEEE" }}
                                                src={`${videoCourseCoverImage.imageUrl}`}
                                            />
                                            :
                                            <img
                                                style={{ width: '100%', border: "1px solid #EEEEEE" }}
                                                src={`./assets/images/default/df-img.png`}
                                            />
                                        }
                                    </Col>
                                    <Col xs={24} sm={24} md={12} xl={24} lg={12}>
                                        <div style={{ paddingTop: 12 }}>
                                            <Form.Item
                                                name="uploadImage"
                                                rules={[{ required: true, validator: detectVideoCourseCoverImage }]}
                                            >
                                                <ImgCrop
                                                    modalTitle={<label style={{ fontWeight: 'bold', padding: 0, margin: 0 }}>ปรับเเต่งรูปภาพ</label>}
                                                    modalOk={<label style={{ width: 90, cursor: "pointer" }}><AiOutlineUpload style={{ width: 20, height: 20, paddingRight: 5 }} /> อัพโหลด</label>}
                                                    modalCancel={<label style={{ width: 90, cursor: "pointer" }}><Icon icon="mdi:cancel" style={{ width: 20, height: 20, paddingRight: 5 }} />ยกเลิก</label>}
                                                    aspect={531 / 300}
                                                >
                                                    <Upload
                                                        {...optionCoverVideo}
                                                        accept='image/jpeg, image/png, image/jfif'
                                                        maxCount={1}
                                                        showUploadList={{ showRemoveIcon: false }}
                                                    >
                                                        <Button
                                                            type="primary"
                                                            style={{ width: '100%' }}
                                                            icon={videoCourseCoverImage.loading ? <LoadingOutlined /> : <UploadOutlined />}
                                                            disabled={props.approvalStatusId === 5 ? true : false}
                                                        >อัพโหลดภาพปก</Button>
                                                    </Upload>
                                                </ImgCrop>
                                            </Form.Item>
                                        </div>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} xl={24} lg={12}>
                                        <Button
                                            type="primary"
                                            style={{ width: '100%' }}
                                            onClick={() => { setModalVideoCoverImage(true) }}
                                            disabled={props.approvalStatusId === 5 ? true : false}
                                        >
                                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                <Icon icon="dashicons:cover-image" style={{ color: videoCourse.videoUrl ? "white" : "" }} />
                                                <label style={{ paddingLeft: 10, cursor: "pointer" }}>เลือกภาพจาก Video</label>
                                            </div>
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Col>

                        <Col xs={24} lg={24} xl={13} style={width > 992 ? { paddingLeft: 25 } : {}}>
                            <Form.Item
                                name="videoTitle"
                                label={
                                    <>
                                        <div>ชื่อวิดีโอคอร์ส </div>
                                        <div style={{ paddingLeft: 10 }}> (จำกัดตัวอักษร 100 ตัว)</div>
                                    </>
                                }
                                rules={[{ required: true, message: 'กรุณากรอกชื่อวิดีโอคอร์ส' }]}
                            >
                                <Input
                                    showCount
                                    maxLength={100}
                                />
                            </Form.Item>

                            <Form.Item
                                name="videoDescription"
                                label="รายละเอียดวิดีโอคอร์ส"
                                // rules={[{ required: true, validator: detectVideoDescription }]}
                                rules={[{ required: true, message: 'กรุณากรอกรายละเอียดวิดีโอคอร์ส' }]}
                            >
                                <TextArea
                                    showCount
                                    maxLength={500}
                                    autoSize={{ minRows: 10, maxRows: 10 }}
                                />
                            </Form.Item>

                            {props.isPaymentByVideo ?
                                <Form.Item
                                    name="videoPrice"
                                    label={<span>ราคาวิดีโอคอร์ส</span>}
                                    rules={[{ required: true, message: 'กรุณากรอกราคาวิดีโอคอร์ส' }]}
                                >
                                    <InputNumber
                                        precision={2}
                                        min={0}
                                        max={100000}
                                        step={0.1}
                                        parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                        style={{ width: '50%' }}
                                    />
                                </Form.Item>
                                : []}
                        </Col>
                    </Row>
                </Form>
            </Modal>

            <Modal
                title="ภาพปกวิดีโอ"
                visible={modalVideoCoverImage}
                width={526}
                maskClosable={false}
                onCancel={() => {
                    setModalVideoCoverImage(false)
                }}

                footer={false}
            >
                <CoverImage
                    videoUrl={PathVideo(videoCourse.videoUrl)}
                    duration={duration}
                    handleCaptureCoverImage={handleCaptureCoverImage}
                />
            </Modal>
        </>
    )
}
