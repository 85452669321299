/* eslint-disable react-hooks/exhaustive-deps */

import { useState, useEffect } from "react"
import { Icon } from '@iconify/react'
import { useLocation } from "react-router-dom"
import { Col, Menu, Row } from "antd"
import { PageHeader } from '@ant-design/pro-layout'
import { useDimensions } from "../../common/mamager/ScreenManager"
import UserInfo from "./tabs/UserInfo"
import VerifyIdentity from "./tabs/VerifyIdentity"
import BankAccount from "./tabs/BankAccount"
import Transaction from "../payment/components/transaction"
import Header from "../../common/components/header"
import { Content } from "antd/lib/layout/layout"
import Footer from "../../common/components/footer"
import Profile from "./tabs/Profile"
import { getUserByUidFetch } from "../../common/components/header/API"
import { getAccountVerifyBankByIdFetch } from "./API"

export default function Account(props) {

    const location = useLocation()
    const params = new URLSearchParams(location.search)
    const tab = params.get("tab")

    const { width } = useDimensions()

    const [selectedMenuItem, setSelectedMenuItem] = useState(tab ? tab : "profile")
    const [loading, setLoading] = useState(true)
    const [loadingHeader, setLoadingHeader] = useState(false)

    const [user, setUser] = useState([])

    const [accountVerifiedBank, setAccountVerifiedBank] = useState()

    const handleReload = () => {
        setLoadingHeader(true)

        getUserByUid()

        setLoadingHeader(false)
    }

    const componentsSwitch = (type) => {
        switch (type) {
            case "profile":
                return <Profile
                    handleReload={handleReload}
                    user={user}
                />
            case "userInfo":
                return <UserInfo
                    handleReload={handleReload}
                    user={user}
                />
            case "verifyIdentity":
                return <VerifyIdentity
                    user={props.user}
                    handleReload={handleReload}
                />
            case "bankAccount":
                return <BankAccount
                    user={props.user}
                    handleReload={handleReload}
                />
            case "paymentTransaction":
                return <Transaction
                    user={props.user}
                    handleReload={handleReload}
                />
            default: return null
        }
    }

    const getUserByUid = async () => {
        const result = await getUserByUidFetch(props.user.uid)
        // console.log("getUserByUidFetch : ", result, props.user)
        setUser(result)
    }

    const getAccountVerifyBank = async () => {
        const result = await getAccountVerifyBankByIdFetch(props.user.uid)
        // console.log("getAccountVerifyBankByIdFetch : ", result)
        setAccountVerifiedBank(result)
    }

    const getBaseApi = async () => {
        setLoading(true)

        await getUserByUid()
        await getAccountVerifyBank()

        setLoading(false)
    }

    useEffect(() => {
        getBaseApi()
    }, [])

    return (
        <>
            {!loadingHeader ?
                <Header
                    firebase={props.firebase}
                    authorized={props.authorized}
                    user={props.user}
                />
                : []
            }

            <article>
                <Content style={{ backgroundColor: "#F5F5F5" }}>
                    <div
                        style={
                            width > 1500 ?
                                { backgroundColor: "white", marginLeft: '5%', marginRight: '5%' }
                                :
                                (
                                    (width > 912 && width <= 1500) ?
                                        { backgroundColor: "white", marginLeft: '5%', marginRight: '5%' }
                                        :
                                        { backgroundColor: "white", marginLeft: '0%', marginRight: '0%' }
                                )
                        }
                    >
                        <Row style={{ backgroundColor: "white", height: "100%" }}>
                            <Col span={24}>
                                <PageHeader
                                    title="จัดการบัญชี"
                                    style={{ backgroundColor: 'white', borderBottom: '1px solid #ECECEC' }}
                                >
                                </PageHeader>
                            </Col>

                            <Col xs={5} sm={8} md={6} lg={4}>
                                <Menu
                                    mode="inline"
                                    defaultSelectedKeys={[tab ? tab : "profile"]}
                                    defaultOpenKeys={[tab ? tab : "profile"]}
                                    style={{ height: "100%", borderRight: 0, padding: 0 }}
                                    selectedKeys={selectedMenuItem}
                                    onClick={(e) => setSelectedMenuItem(e.key)}
                                >
                                    <Menu.Item key="profile" title="Profile">
                                        {width > 576 ?
                                            "โปรไฟล์"
                                            :
                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}><Icon icon="charm:person" style={{ color: "#23b14d", width: 24, height: 24 }} /></div>
                                        }
                                    </Menu.Item>

                                    <Menu.Item key="userInfo" title="UserInfo">
                                        {width > 576 ?
                                            "ข้อมูลผู้ใช้"
                                            :
                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}><Icon icon="mdi:information-variant" style={{ color: "#23b14d", width: 24, height: 24 }} /></div>
                                        }
                                    </Menu.Item>

                                    <Menu.Item key="verifyIdentity" title="VerifyIdentity">
                                        {width > 576 ?
                                            "ยืนยันตัวตน"
                                            :
                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}><Icon icon="mdi:shield-check-outline" style={{ color: "#23b14d", width: 24, height: 24 }} /></div>
                                        }
                                    </Menu.Item>

                                    {!loading ?
                                        <>
                                            {accountVerifiedBank.isHasBeenVerified ?
                                                <Menu.Item key="bankAccount" title="BankAccount" >
                                                    {width > 576 ?
                                                        "บัญชีธนาคาร"
                                                        :
                                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}><Icon icon="icon-park-solid:bank-card-one" style={{ color: "#23b14d", width: 24, height: 24 }} /></div>
                                                    }
                                                </Menu.Item>
                                                : []
                                            }
                                        </>
                                        : []
                                    }

                                    <Menu.Item key="paymentTransaction" title="PaymentTransaction">
                                        {width > 576 ?
                                            "ธุรกรรมชำระเงิน"
                                            :
                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}><Icon icon="icon-park-outline:transaction-order" style={{ color: "#23b14d", width: 24, height: 24 }} /></div>
                                        }
                                    </Menu.Item>
                                </Menu>
                            </Col>

                            <Col xs={19} sm={16} md={18} lg={20} style={{ borderLeft: "1px solid #ECECEC" }}>
                                <div style={{ padding: (width > 576 ? 24 : 12), minHeight: 500 }}>
                                    {!loading ? componentsSwitch(selectedMenuItem) : []}
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Content>
            </article>

            <Footer btnAutnActive={false} />
        </>
    )
}
