/* eslint-disable import/no-anonymous-default-export */
import { 
    HTTP_COURSE_MANAGE_DOCUMENT_FETCHING, 
    HTTP_COURSE_MANAGE_DOCUMENT_SUCCESS, 
    HTTP_COURSE_MANAGE_DOCUMENT_FAILED 
} from "../constants"

const initialState = {
    resultDocument : [],
    isFetching : false,
    isError : false
}

export default (state = initialState, { type, payload }) => {
    switch (type) {

        case HTTP_COURSE_MANAGE_DOCUMENT_FETCHING:
            return { ...state, resultDocument : null, isFetching : true, isError : false }
            
        case HTTP_COURSE_MANAGE_DOCUMENT_SUCCESS:
            return { ...state, resultDocument : payload, isFetching : false, isError : false }

        case HTTP_COURSE_MANAGE_DOCUMENT_FAILED:
            return { ...state, resultDocument : null, isFetching : false, isError : true }

        default:
            return state
    }
}
