import { server } from "../../../constants"
import { httpClient } from "../../../utils/HttpClient"

const getCurrencyFetch = async (value) => { // Done
    try {
        const result = await httpClient.get(server.GET_CURRENCY_URL)
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getCouponTypeFetch = async (value) => { // Done
    try {
        const result = await httpClient.get(server.GET_COUPON_TYPE_URL)
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getCouponPrivilegeTypeFetch = async (value) => { // Done
    try {
        const result = await httpClient.get(server.GET_COUPON_PRIVILEGE_TYPE_URL)
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getInspectUsedCouponLogByIdFetch = async (value) => { // Done
    try {
        const result = await httpClient.post(server.GET_INSPECT_USED_COUPON_LOG_BY_ID_URL, { value })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getInspectCouponByIdFetch = async (value) => { // Done
    try {
        const result = await httpClient.post(server.GET_INSPECT_COUPON_BY_ID_URL, { value })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const insertCouponLogFetch = async (value) => { // Done
    try {
        const result = await httpClient.post(server.INSERT_COUPON_LOG_URL, { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const updatePermissionUsedCouponByIdFetch = async (value) => { // Done
    try {
        const result = await httpClient.put(server.UPDATE_PERMISSION_USED_COUPON_BY_ID_URL, { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

export {
    // get
    getCurrencyFetch,
    getCouponTypeFetch,
    getCouponPrivilegeTypeFetch,
    getInspectUsedCouponLogByIdFetch,
    getInspectCouponByIdFetch,

    // insert
    insertCouponLogFetch,

    // update
    updatePermissionUsedCouponByIdFetch,

    // delete

}