/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import Layout, { Content } from "antd/lib/layout/layout"
import "../courseMamage/css/index.css"
import Header from "../../common/components/header"
import ManageSearch from "./components/search"
import ManageBoard from "./ManageBoard"
import Footer from "../../common/components/footer"
import { detectSignIn } from "../../common/mamager/DetectManager"

export default function CourseManage({ firebase, authorized, user }) {
    const navigate = useNavigate()
    const location = useLocation()
    const params = new URLSearchParams(location.search)
    const textSearch = params.get("course-manage-text")

    const detectLogin = async () => {
        // detect login
        const isLogin = await detectSignIn()
        if (!isLogin) {
            navigate("/login")
        }
    }

    useEffect(() => {
        detectLogin()
    }, [textSearch])

    return (
        <Layout className="layout">
            <Header firebase={firebase} authorized={authorized} user={user} />

            <ManageSearch
                firebase={firebase}
                authorized={authorized}
                user={user}
            />
            <ManageBoard user={user} textSearch={textSearch} />

            <Footer />
        </Layout >
    )
}