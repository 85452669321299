import { server } from "../../../constants"
import { httpClient } from "../../../utils/HttpClient"

const getAccountVerifyByIdFetch = async (uid, language) => { // Done
    try {
        const result = await httpClient.post(server.GET_ACCOUNT_VERIFY_BY_ID_URL, { uid, language })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getAccountVerifyIdentifyByIdFetch = async (uid, language) => { // Done
    try {
        const result = await httpClient.post(server.GET_ACCOUNT_VERIFY_IDENTIFY_BY_ID_URL, { uid, language })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getFileRegistrationCertificateByIdFetch = async (uid, language) => { // Done
    try {
        const result = await httpClient.post(server.GET_FILE_REGISTRATION_CERTIFICATE_BY_ID_URL, { uid, language })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getFileAuthorizedSignatoryByIdFetch = async (uid, language) => { // Done
    try {
        const result = await httpClient.post(server.GET_FILE_AUTHORIZED_SIGNATORY_BY_ID, { uid, language })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getAccountVerifyBankByIdFetch = async (uid, language) => { // Done
    try {
        const result = await httpClient.post(server.GET_ACCOUNT_VERIFY_BANK_BY_ID_URL, { uid, language })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getAccountVerifyTokenAllFetch = async (uid) => { // Done
    try {
        const result = await httpClient.post(server.GET_ACCOUNT_VERIFY_TOKEN_ALL_URL, { uid })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getAccountVerifyTokenByIdFetch = async (value) => {  // uid, token : Done
    try {
        const result = await httpClient.post(server.GET_ACCOUNT_VERIFY_TOKEN_BY_ID_URL, { value })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getฺBankCategoryAllFetch = async (value) => {
    try {
        const result = await httpClient.post(server.GET_BANK_CATEGORY_ALL_URL, { value })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const insertAccountVerifyTokenFetch = async (value) => { // Done
    try {
        const result = await httpClient.post(server.INSERT_ACCOUNT_VERIFY_TOKEN_URL, { value })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const insertAccountVerifyFetch = async (value) => { // uid : Done
    try {
        const result = await httpClient.post(server.INSERT_ACCOUNT_VERIFY_URL, { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const insertAccountVerifyIdentifyFetch = async (value) => { // Done
    try {
        const result = await httpClient.post(server.INSERT_ACCOUNT_VERIFY_IDENTIFY_URL, { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const insertAccountVerifyBankFetch = async (value) => { // Done
    try {
        const result = await httpClient.post(server.INSERT_ACCOUNT_VERIFY_BANK_URL, { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const insertFileRegistrationCertificateFetch = async (value) => { // Done
    try {
        const result = await httpClient.post(server.INSERT_FILE_REGISTRATION_CERTIFICATE_URL, { value })
        if (result.data.isSuccess) {
            return result.data
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const insertFileAuthorizedSignatoryFetch = async (value) => { // Done
    try {
        const result = await httpClient.post(server.INSERT_FILE_AUTHORIZED_SIGNATORY_BY_ID, { value })
        if (result.data.isSuccess) {
            return result.data
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const updateAccountVerifyByIdFetch = async (value) => { // 
    try {
        const result = await httpClient.put(server.UPDATE_ACCOUNT_VERIFY_BY_ID_URL, { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const updateAccountVerifyTokenByIdFetch = async (value) => { // uid, token : Done
    try {
        const result = await httpClient.put(server.UPDATE_ACCOUNT_VERIFY_TOKEN_BY_ID_URL, { value })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const updateAccountVerifyEmailByIdFetch = async (value) => { // update status : Done
    try {
        const result = await httpClient.put(server.UPDAT_ACCOUNT_VERIFY_EMAIL_BY_ID_URL, { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const updateAccountVerifyIdentifyByIdFetch = async (value) => { // Done
    try {
        const result = await httpClient.put(server.UPDAT_ACCOUNT_VERIFY_IDENTIFY_BY_ID_URL, { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const uploadAccountVerifyIdentifyByIdFetch = async (value) => { // upload image : Done
    try {
        const result = await httpClient.put(server.UPLOAD_ACCOUNT_VERIFY_IDENTIFY_BY_ID_URL, { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const uploadAccountVerifyBankByIdFetch = async (value) => { // upload image : Done
    try {
        const result = await httpClient.put(server.UPLOAD_ACCOUNT_VERIFY_BANK_BY_ID_URL, { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const updateIsOpenBankAccountVerifyByIdFetch = async (value) => { // upload image : Done
    try {
        const result = await httpClient.put(server.UPDATE_IS_OPEN_BANK_ACCOUNT_VERIFY_BY_ID, { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const updateUser = async (value) => { // Done
    try {
        const result = await httpClient.put(server.UPDATE_USER, { value })
        if (result.data.isSuccess) {
            return result.data
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const updateNaturalPerson = async (value) => { // Done
    try {
        const result = await httpClient.put(server.UPDATE_NATURAL_PERSON, { value })
        if (result.data.isSuccess) {
            return result.data
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const updateJuristicPerson = async (value) => { // Done
    try {
        const result = await httpClient.put(server.UPDATE_JURISTIC_PERSON, { value })
        if (result.data.isSuccess) {
            return result.data
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const detectAccountVerifyRepeatTokenByIdFetch = async (token) => { // Done
    try {
        const result = await httpClient.post(server.DETECT_ACCOUNT_REPEAT_TOKEN_BY_ID_URL, { token })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const deleteFileRegistrationCertificateFetch = async (value) => { // Done
    try {
        const result = await httpClient.delete(server.DELETE_FILE_REGISTRATION_CERTIFICATE_BY_ID_URL + "/" + value)
        if (result.data.isSuccess) {
            return result.data
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const deleteFileAuthorizedSignatoryFetch = async (value) => { // Done
    try {
        const result = await httpClient.delete(server.DELETE_FILE_AUTHORIZED_SIGNATORY_BY_ID_URL + "/" + value)
        if (result.data.isSuccess) {
            return result.data
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

export {
    // get
    getAccountVerifyByIdFetch,
    getAccountVerifyIdentifyByIdFetch,
    getFileRegistrationCertificateByIdFetch,
    getFileAuthorizedSignatoryByIdFetch,
    getAccountVerifyBankByIdFetch,
    getAccountVerifyTokenAllFetch,
    getAccountVerifyTokenByIdFetch,
    getฺBankCategoryAllFetch,

    // update
    updateAccountVerifyByIdFetch,
    updateAccountVerifyTokenByIdFetch,
    updateAccountVerifyEmailByIdFetch,
    updateAccountVerifyIdentifyByIdFetch,
    uploadAccountVerifyIdentifyByIdFetch,
    uploadAccountVerifyBankByIdFetch,
    updateIsOpenBankAccountVerifyByIdFetch,
    updateUser,
    updateNaturalPerson,
    updateJuristicPerson,

    // insert
    insertAccountVerifyTokenFetch,
    insertAccountVerifyFetch,
    insertAccountVerifyIdentifyFetch,
    insertAccountVerifyBankFetch,
    insertFileRegistrationCertificateFetch,
    insertFileAuthorizedSignatoryFetch,

    // delete
    detectAccountVerifyRepeatTokenByIdFetch,
    deleteFileRegistrationCertificateFetch,
    deleteFileAuthorizedSignatoryFetch,
}