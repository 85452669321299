import { server } from "../../../../constants"
import { httpClient } from "../../../../utils/HttpClient"

const getReservedWordAllFetch = async () => {
    try {
        const result = await httpClient.get(server.GET_RESERVED_WORD_ALL_URL)
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

export {
    // get
    getReservedWordAllFetch
}
