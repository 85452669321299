import {
    HTTP_COURSE_MANAGE_ADD_CURRENT_PAGE_FETCHING,
    HTTP_COURSE_MANAGE_ADD_CURRENT_PAGE_SUCCESS,
    HTTP_COURSE_MANAGE_ADD_CURRENT_PAGE_FAILED,

} from "../constants"

const initialState = {
    resultCourseManageAddCurrentStep: 0,
    isFetching: false,
    isError: false
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, { type, payload }) => {
    switch (type) {

        case HTTP_COURSE_MANAGE_ADD_CURRENT_PAGE_FETCHING:
            return { ...state, resultCourseManageAddCurrentStep: null, isFetching: true, isError: false }

        case HTTP_COURSE_MANAGE_ADD_CURRENT_PAGE_SUCCESS:
            return { ...state, resultCourseManageAddCurrentStep: payload, isFetching: false, isError: false }

        case HTTP_COURSE_MANAGE_ADD_CURRENT_PAGE_FAILED:
            return { ...state, resultCourseManageAddCurrentStep: null, isFetching: false, isError: true }

        default:
            return state
    }
}
