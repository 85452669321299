import { 
    HTTP_REGISTER_CAREER_AND_EDUCATION_FETCHING, 
    HTTP_REGISTER_CAREER_AND_EDUCATION_SUCCESS, 
    HTTP_REGISTER_CAREER_AND_EDUCATION_FAILED, 
} from "../constants"

export const setRegisterCareerAndEducationFetching = () => ({
    type: HTTP_REGISTER_CAREER_AND_EDUCATION_FETCHING,
})

export const setRegisterCareerAndEducationSuccess = (payload) => ({
    type: HTTP_REGISTER_CAREER_AND_EDUCATION_SUCCESS,
    payload
})

export const setRegisterCareerAndEducationFailed = () => ({
    type: HTTP_REGISTER_CAREER_AND_EDUCATION_FAILED,
})
