import { 
    HTTP_REGISTER_CURRENT_PAGE_FETCHING, 
    HTTP_REGISTER_CURRENT_PAGE_SUCCESS, 
    HTTP_REGISTER_CURRENT_PAGE_FAILED, 
} from "../constants"

export const setRegisterCurrentStepStateToFetching = () => ({
    type: HTTP_REGISTER_CURRENT_PAGE_FETCHING,
})

export const setRegisterCurrentStepStateToSuccess = (payload) => ({
    type: HTTP_REGISTER_CURRENT_PAGE_SUCCESS,
    payload
})

export const setRegisterCurrentStepStateToFailed = () => ({
    type: HTTP_REGISTER_CURRENT_PAGE_FAILED,
})
