/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */

import { useState, useEffect, useRef } from 'react'
import {
    Layout,
    Menu,
    Button,
    Row,
    Col,
    Divider,
    Spin,
    Popconfirm,
    Select,
    Form
} from 'antd'
import { PageHeader } from '@ant-design/pro-layout'
import { useNavigate, useLocation } from 'react-router-dom'
import moment from 'moment'
import InspectCard from './Card'
import InspectDescript from './Descript'
import InspectDocument from './Document'
import InspectMediaIntro from './MediaIntro'
import InspectPrice from './Price'
import InspectVideo from './Video'
import { Notification } from '../../../../common/components/notification'
import { emailSubject, emailSupport } from '../../../../constants'
import { getCourseOwnerFetch } from '../../../course/API'
import { getAdminInspectOpenCourseByIdFetch, getCourseInspectChapterFetch, getCourseInspectDocumentApiFetch, getCourseInspectFetch, getCourseInspectMediaIntroFetch, getCourseInspectVideoListFetch, getFeePercentageCategoryFetch, getInspectRequestEditCourseByIdFetch, getStatusInspectRequestEditCourseByIdFetch, insertPayFeeFetch, updateCourseInspectFetch, updateCourseInspectRequestEditByIdFetch, updateCourseOverdueFetch } from '../../API'
import { inspectRequestEditCourseNotPass, inspectRequestEditCoursePass, inspectRequestOpenCourseNotPass, inspectRequestOpenCourseOverdue, inspectRequestOpenCoursePass } from '../../../../common/mamager/EmailMamager'

const { Sider } = Layout
const { Option } = Select

const formatDate = "YYYY-MM-DD"
const formatDateTime = "DD/MM/YYYY HH:mm"
const formatDateTimeDB = "MM/DD/YYYY HH:mm"

export default function InspectCourse(props) {
    const language = "th"

    const location = useLocation()
    const navigate = useNavigate()

    const userType = location.state.userType

    const [form] = Form.useForm()

    const [inspect, setInspect] = useState([])
    const [inspectApprovalVideoAndChapter, setInspectApprovalVideoAndChapter] = useState(null)
    const inspectStatusRef = useRef(null)

    const [userInfo, setUserInfo] = useState(null)

    const [courseOriginal, setCourseOriginal] = useState(null)                         // data go lite
    const [courseMediaIntroOriginal, setCourseMediaIntroOriginal] = useState(null)
    const [courseDocumentOriginal, setCourseDocumentOriginal] = useState(null)
    const [courseVideoOriginal, setCourseVideoOriginal] = useState(null)
    const [courseChapterOriginal, setCourseChapterOriginal] = useState(null)

    const [courseReqtEdit, setCourseReqtEdit] = useState(null)
    const [courseMediaIntroReqtEdit, setCourseMediaIntroReqtEdit] = useState(null)
    const [courseDocumentReqtEdit, setCourseDocumentReqtEdit] = useState(null)
    const [courseVideoReqtEdit, setCourseVideoReqtEdit] = useState(null)
    const [courseChapterReqtEdit, setCourseChapterReqtEdit] = useState(null)

    const [isPaymentByVideo, setIsPaymentByVideo] = useState(null)

    const [feePercentage, setFeePercentage] = useState(null)

    const [loading, setLoading] = useState(true)

    const [isSendResults, setIsSendResults] = useState(false)
    const [isOverdue, setIsOverdue] = useState(false)
    const [isCloseInspect, setIsCloseInspect] = useState(false)

    const [selectedMenuItem, setSelectedMenuItem] = useState(null)

    const reloadInspectCourse = () => {
        getBaseApi()
    }

    const onFinish = async (value) => {
        await handleSendResult(value)
    }

    const componentsSwitch = (type) => {
        switch (type) {
            case "menu1":
                return <InspectCard
                    userType={userType}
                    courseId={location.state.courseId}
                    uid={location.state.uid}
                    inspect={inspect}
                    reloadInspectCourse={reloadInspectCourse}
                    isCloseInspect={isCloseInspect}
                    courseOriginal={courseOriginal}
                    courseReqtEdit={courseReqtEdit}
                />
            case "menu2":
                return <InspectMediaIntro
                    userType={userType}
                    courseId={location.state.courseId}
                    uid={location.state.uid}
                    inspect={inspect}
                    reloadInspectCourse={reloadInspectCourse}
                    isCloseInspect={isCloseInspect}
                    courseMediaIntroOriginal={courseMediaIntroOriginal}
                    courseMediaIntroReqtEdit={courseMediaIntroReqtEdit}
                />
            case "menu3":
                return <InspectPrice
                    userType={userType}
                    courseId={location.state.courseId}
                    uid={location.state.uid}
                    inspect={inspect}
                    reloadInspectCourse={reloadInspectCourse}
                    isCloseInspect={isCloseInspect}
                    courseOriginal={courseOriginal}
                    courseReqtEdit={courseReqtEdit}
                />
            case "menu4":
                return <InspectDescript
                    userType={userType}
                    courseId={location.state.courseId}
                    uid={location.state.uid}
                    inspect={inspect}
                    reloadInspectCourse={reloadInspectCourse}
                    isCloseInspect={isCloseInspect}
                    courseOriginal={courseOriginal}
                    courseReqtEdit={courseReqtEdit}
                />
            case "menu5":
                return <InspectDocument
                    userType={userType}
                    courseId={location.state.courseId}
                    uid={location.state.uid}
                    inspect={inspect}
                    reloadInspectCourse={reloadInspectCourse}
                    isCloseInspect={isCloseInspect}
                    courseDocumentOriginal={courseDocumentOriginal}
                    courseDocumentReqtEdit={courseDocumentReqtEdit}
                />
            case "menu6":
                return <InspectVideo
                    userType={userType}
                    courseId={location.state.courseId}
                    uid={location.state.uid}
                    inspect={inspect}
                    reloadInspectCourse={reloadInspectCourse}
                    isCloseInspect={isCloseInspect}
                    isPaymentByVideo={isPaymentByVideo}

                    courseVideoOriginal={courseVideoOriginal}
                    courseVideoReqtEdit={courseVideoReqtEdit}

                    courseChapterOriginal={courseChapterOriginal}
                    courseChapterReqtEdit={courseChapterReqtEdit}
                />
            default:
                return null
        }
    }

    const getCourse = async () => {
        let obj = {
            courseId: location.state.courseId,
            uid: location.state.uid,
            isActive: 1,
            language
        }
        const resultCourse = await getCourseInspectFetch(obj, userType)
        // console.log("getCourseInspectFetch : ", resultCourse )

        if (resultCourse.dataOriginal) {
            setIsPaymentByVideo(resultCourse.dataOriginal.isPaymentByVideo)
        } else {
            setIsPaymentByVideo(resultCourse.dataReqtEdit.isPaymentByVideo)
        }

        setCourseOriginal(resultCourse.dataOriginal)
        setCourseReqtEdit(resultCourse.dataReqtEdit)
    }

    const getCourseMediaIntro = async () => {
        let obj = {
            courseId: location.state.courseId,
            uid: location.state.uid,
            isActive: 1,
            language
        }
        const resultCourseMediaIntro = await getCourseInspectMediaIntroFetch(obj, userType)
        setCourseMediaIntroOriginal(resultCourseMediaIntro.dataOriginal[0])
        if (userType === "admin-inspect-edit-course") {
            setCourseMediaIntroReqtEdit(resultCourseMediaIntro.dataReqtEdit[0])
        }
    }

    const getCourseDocument = async () => {
        let obj = {
            courseId: location.state.courseId,
            uid: location.state.uid,
            isActive: 1,
            language
        }
        const resultCourseDocument = await getCourseInspectDocumentApiFetch(obj, userType)
        setCourseDocumentOriginal(resultCourseDocument.dataOriginal)
        setCourseDocumentReqtEdit(resultCourseDocument.dataReqtEdit)
    }

    const getCourseVideo = async () => {
        let obj = {
            courseId: location.state.courseId,
            uid: location.state.uid,
            isActive: 1,
            language
        }
        const resultCourseVideo = await getCourseInspectVideoListFetch(obj, userType)
        // console.log("getCourseInspectVideoListFetch : ", resultCourseVideo)

        setCourseVideoOriginal(resultCourseVideo.dataOriginal)
        setCourseVideoReqtEdit(resultCourseVideo.dataReqtEdit)
    }

    const getCourseChapter = async () => {
        let obj = {
            courseId: location.state.courseId,
            isActive: 1,
            language
        }
        const resultCourseChapter = await getCourseInspectChapterFetch(obj, userType)
        setCourseChapterOriginal(resultCourseChapter.dataOriginal)
        setCourseChapterReqtEdit(resultCourseChapter.dataReqtEdit)
    }

    const inspectVideoAndChapter = (data) => {
        // inspect video and chapter ---------------------------------------------------------------------------------------------------------------
        if (data.courseVideo?.formData && data.courseChapte?.formData) {
            if (data.courseVideo.inspectionCourseApprovalIdSummary === 1 && data.courseChapter.inspectionCourseApprovalIdSummary === 1) {
                setInspectApprovalVideoAndChapter({
                    inspectionCourseApprovalIdSummary: data.courseVideo.inspectionCourseApprovalIdSummary,
                    inspectionCourseApprovalNameSummary: data.courseVideo.inspectionCourseApprovalNameSummary
                })
            } else if (data.courseVideo.inspectionCourseApprovalIdSummary === 1 && data.courseChapter.inspectionCourseApprovalIdSummary === 2) {
                setInspectApprovalVideoAndChapter({
                    inspectionCourseApprovalIdSummary: data.courseChapter.inspectionCourseApprovalIdSummary,
                    inspectionCourseApprovalNameSummary: data.courseChapter.inspectionCourseApprovalNameSummary
                })
            } else if (data.courseVideo.inspectionCourseApprovalIdSummary === 2 && data.courseChapter.inspectionCourseApprovalIdSummary === 1) {
                setInspectApprovalVideoAndChapter({
                    inspectionCourseApprovalIdSummary: data.courseVideo.inspectionCourseApprovalIdSummary,
                    inspectionCourseApprovalNameSummary: data.courseVideo.inspectionCourseApprovalNameSummary
                })
            } else if (data.courseVideo.inspectionCourseApprovalIdSummary === 2 && data.courseChapter.inspectionCourseApprovalIdSummary === 2) {
                setInspectApprovalVideoAndChapter({
                    inspectionCourseApprovalIdSummary: data.courseVideo.inspectionCourseApprovalIdSummary,
                    inspectionCourseApprovalNameSummary: data.courseVideo.inspectionCourseApprovalNameSummary
                })
            }
        } else if (data.courseVideo?.formData && !data.courseChapter?.formData) {
            setInspectApprovalVideoAndChapter({
                inspectionCourseApprovalIdSummary: data.courseVideo.inspectionCourseApprovalIdSummary,
                inspectionCourseApprovalNameSummary: data.courseVideo.inspectionCourseApprovalNameSummary
            })
        } else {
            setInspectApprovalVideoAndChapter({ inspectionCourseApprovalIdSummary: null, inspectionCourseApprovalNameSummary: null });
        }
    }

    const getCourseInspect = async () => {
        let overdue = null;
        let obj = {
            courseId: location.state.courseId,
            uid: location.state.uid,
            inspectStatus: inspectStatusRef.current
        }
        if (userType === "admin-inspect-open-course") {
            const result = await getAdminInspectOpenCourseByIdFetch(obj, language)

            setIsCloseInspect({
                status: result.dateEndAdjust ? (moment(result.dateEndAdjust, formatDate).diff(new Date(), 'days') <= 0 ? true : false) : false,
                message: "ไม่สามารถตรวจสอบคอร์สได้ เนื่องจากคอร์สเกินกำหนดการปรับเเก้!"
            })

            setInspect(result)

            inspectVideoAndChapter(result)

            // open button send result to teacher ------------------------------------------------------------------------------------------------------
            if (
                result.inspectionCourseCardId !== 2 &&                                                              // ต้องไม่รอตรวจสอบ
                result.inspectionCourseDescriptionId !== 2 &&                                                       // ต้องไม่รอตรวจสอบ
                result.inspectionCourseDocumentId !== 2 &&                                                          // ต้องไม่รอตรวจสอบ
                result.inspectionCourseMediaIntroId !== 2 &&                                                        // ต้องไม่รอตรวจสอบ
                result.inspectionCoursePriceId !== 2 &&                                                             // ต้องไม่รอตรวจสอบ
                result.inspectionCourseVideoId !== 2 &&                                                             // ต้องไม่รอตรวจสอบ
                (inspect.courseChapter.formData.length > 0 ? (result.inspectionCourseChapterId !== 2) : true) &&    // ต้องไม่รอตรวจสอบ
                result.inspectionCourseId === 2                                                                     // สถานะโดยรวม คือ รอตรวจสอบ [2]
            ) {
                setIsSendResults(true)
            } else {
                setIsSendResults(false)
            }

            if (
                (result.inspectionCourseId === 2 || result.inspectionCourseId === 3) &&
                result.dateEndAdjust &&
                result.amountAdjust > 0
            ) {
                overdue = moment(result.dateEndAdjust, formatDate).diff(new Date(), 'days')
                if (overdue <= 0) {
                    setIsOverdue(true)
                }
            } else {
                setIsOverdue(false)
            }
        } else if (userType === "admin-inspect-edit-course") {
            const result = await getInspectRequestEditCourseByIdFetch(obj, language)
            setInspect(result)

            inspectVideoAndChapter(result)

            if ( // open button send result to teacher
                result.inspectionCourseCardId !== 2 &&              // ต้องไม่รอตรวจสอบ
                result.inspectionCourseDescriptionId !== 2 &&       // ต้องไม่รอตรวจสอบ
                result.inspectionCourseDocumentId !== 2 &&          // ต้องไม่รอตรวจสอบ
                result.inspectionCourseMediaIntroId !== 2 &&        // ต้องไม่รอตรวจสอบ
                result.inspectionCoursePriceId !== 2 &&             // ต้องไม่รอตรวจสอบ
                result.inspectionCourseVideoId !== 2 &&             // ต้องไม่รอตรวจสอบ
                result.inspectionCourseChapterId !== 2 &&           // ต้องไม่รอตรวจสอบ
                result.inspectionCourseId === 2                     // สถานะโดยรวม คือ รอตรวจสอบ [2]
            ) {
                setIsSendResults(true)
            } else {
                setIsSendResults(false)
            }
        }
    }

    const getCourseOwner = async () => {
        const result = await getCourseOwnerFetch(location.state.uid) // uid
        setUserInfo(result)
    }

    const getFeePercentageCategory = async () => {
        const result = await getFeePercentageCategoryFetch(language)
        // console.log("getFeePercentageCategoryFetch : ", result)
        setFeePercentage(result)
    }

    const getBaseApi = async () => {
        setLoading(true)

        if (userType === "admin-inspect-open-course") {
            await getCourse()
            await getCourseMediaIntro()
            await getCourseDocument()
            await getCourseVideo()
            await getCourseChapter()
            await getCourseOwner()
            await getCourseInspect()
            await getFeePercentageCategory()
        } else if (userType === "admin-inspect-edit-course") {
            const result = await getStatusInspectRequestEditCourseByIdFetch({
                courseId: location.state.courseId,
                uid: location.state.uid,
                isActive: 1,
            })
            inspectStatusRef.current = result;

            if (result) {
                // course (price, card, description)
                if (
                    result.inspectionCourseCardId ||
                    result.inspectionCoursePriceId ||
                    result.inspectionCourseDescriptionId
                ) {
                    await getCourse()
                }

                // course media intro
                if (result.inspectionCourseMediaIntroId) {
                    await getCourseMediaIntro()
                }

                // course document
                if (result.inspectionCourseDocumentId) {
                    await getCourseDocument()
                }

                // course video
                if (result.inspectionCourseVideoId) {
                    await getCourseVideo()
                }

                await getCourseOwner()
                await getCourseInspect()
            }
        }

        setLoading(false)
    }

    const handleSendResult = async (value) => {
        let approvalStatusId = 0                           // adjust, edit
        let inspectionCourseCategoryId = 2                 // adjust, edit
        let inspectionCourseApprovalCategoryId = null      // adjust, edit
        let amountEdit = 0                                 // edit
        let amountAdjust = 0                               // adjust
        let dateEndAdjust = null                           // adjust
        let result = { isSuccess: false }

        if (userType === "admin-inspect-open-course") {
            // course card --------------------------------------------------------------------------
            let courseCardApploval = inspect.course.inspectionCourseCardApprovalId === 1 ? 1 : 2;

            // course price -------------------------------------------------------------------------
            let coursePriceApploval = inspect.course.inspectionCoursePriceApprovalId === 1 ? 1 : 2;

            // course description -------------------------------------------------------------------
            let courseDescriptionApploval = inspect.course.inspectionCourseDescriptionApprovalId === 1 ? 1 : 2;

            // course media intro -------------------------------------------------------------------
            let courseMediaIntroApploval = 1
            for (let i = 0; i < inspect.courseMediaIntro.formData.length; i++) {
                if (inspect.courseMediaIntro.formData[i].inspectionCourseApprovalId === 2) {
                    courseMediaIntroApploval = 2; break
                }
            }

            // course document ----------------------------------------------------------------------
            let courseDocumentApploval = 1
            for (let i = 0; i < inspect.courseDocument.formData.length; i++) {
                if (inspect.courseDocument.formData[i].inspectionCourseApprovalId === 2) {
                    courseDocumentApploval = 2; break
                }
            }

            // course video -------------------------------------------------------------------------
            let courseVideoApploval = 1
            for (let i = 0; i < inspect.courseVideo.formData.length; i++) {
                if (inspect.courseVideo.formData[i].inspectionCourseApprovalId === 2) {
                    courseVideoApploval = 2; break
                }
            }

            // course chapter -----------------------------------------------------------------------
            let courseChapterApploval = 1
            for (let i = 0; i < inspect.courseChapter.formData.length; i++) {
                if (inspect.courseChapter.formData[i].inspectionCourseApprovalId === 2) {
                    courseChapterApploval = 2; break
                }
            }

            if ( // ผ่าน
                (
                    courseCardApploval === 1 &&                             // ต้องตรวจสอบเเล้ว
                    coursePriceApploval === 1 &&                            // ต้องตรวจสอบเเล้ว
                    courseDescriptionApploval === 1 &&                      // ต้องตรวจสอบเเล้ว
                    courseMediaIntroApploval === 1 &&                       // ต้องตรวจสอบเเล้ว
                    courseDocumentApploval === 1 &&                         // ต้องตรวจสอบเเล้ว
                    courseVideoApploval === 1 &&                            // ต้องตรวจสอบเเล้ว
                    courseChapterApploval === 1                             // ต้องตรวจสอบเเล้ว
                ) && inspect.inspectionCourseId === 2                       // สถานะโดยรวม คือ รอตรวจสอบ [2]
            ) {
                approvalStatusId = 1;                                       // ผ่าน มุมมองผู้สอน
                inspectionCourseCategoryId = 1;                             // ตรวจสอบเเล้ว
                inspectionCourseApprovalCategoryId = 1;                     // ผ่าน มุมมองผู้ตรวจ
                amountEdit = inspect.amountEdit;
                amountAdjust = inspect.amountAdjust;
                dateEndAdjust = null;
            } else if ( // ไม่ผ่าน
                (
                    courseCardApploval === 2 ||                             // ต้องไม่รอตรวจสอบ
                    coursePriceApploval === 2 ||                            // ต้องไม่รอตรวจสอบ
                    courseDescriptionApploval === 2 ||                      // ต้องไม่รอตรวจสอบ
                    courseMediaIntroApploval === 2 ||                       // ต้องไม่รอตรวจสอบ
                    courseDocumentApploval === 2 ||                         // ต้องไม่รอตรวจสอบ
                    courseVideoApploval === 2 ||                            // ต้องไม่รอตรวจสอบ
                    courseChapterApploval === 2                             // ต้องไม่รอตรวจสอบ
                ) || inspect.inspectionCourseId === 2                       // สถานะโดยรวม คือ รอตรวจสอบ [2]
            ) {
                approvalStatusId = 2;                                       // ไม่ผ่าน มุมมองผู้สอน
                inspectionCourseCategoryId = 3;                             // รอตอบกลับ
                inspectionCourseApprovalCategoryId = 2;                     // ไม่ผ่าน มุมมองผู้ตรวจ
                amountEdit = Number(inspect.amountEdit) + 1;
                amountAdjust = Number(inspect.amountAdjust) + 1;               // เพิ่มครั้งการตีกลับ
                dateEndAdjust = !inspect.dateEndAdjust ? moment().add(1, 'M').format(formatDateTimeDB) : inspect.dateEndAdjust;
            }

            let obj = {
                courseId: location.state.courseId,
                uid: location.state.uid,
                approvalStatusId,
                inspectionCourseCategoryId,
                inspectionCourseApprovalCategoryId,
                amountAdjust,
                dateEndAdjust,
                adminInspectId: location.state.adminLogin.adminListId
            }

            // console.log("updateCourseInspectFetch : ", obj)
            result = await updateCourseInspectFetch(obj)
        } else if (userType === "admin-inspect-edit-course") {
            let courseCardApploval = 1;
            let coursePriceApploval = 1;
            let courseDescriptionApploval = 1;
            let courseMediaIntroApploval = 1;
            let courseDocumentApploval = 1;
            let courseVideoApploval = 1;
            let courseChapterApploval = 1;

            // course card
            if (inspectStatusRef.current.inspectionCourseCardId) {
                courseCardApploval = inspect.course.inspectionCourseCardApprovalId === 1 ? 1 : 2;
            }

            // course price
            if (inspectStatusRef.current.inspectionCoursePriceId) {
                coursePriceApploval = inspect.course.inspectionCoursePriceApprovalId === 1 ? 1 : 2;
            }

            // course description
            if (inspectStatusRef.current.inspectionCourseDescriptionId) {
                courseDescriptionApploval = inspect.course.inspectionCourseDescriptionApprovalId === 1 ? 1 : 2;
            }

            // course media intro
            if (inspectStatusRef.current.inspectionCourseMediaIntroId) {
                for (let i = 0; i < inspect.courseMediaIntro.formData.length; i++) {
                    if (inspect.courseMediaIntro.formData[i].inspectionCourseApprovalId === 2) {
                        courseMediaIntroApploval = 2; break;
                    }
                }
            }

            // course document
            if (inspectStatusRef.current.inspectionCourseDocumentId) {
                for (let i = 0; i < inspect.courseDocument.formData.length; i++) {
                    if (inspect.courseDocument.formData[i].inspectionCourseApprovalId === 2) {
                        courseDocumentApploval = 2; break;
                    }
                }
            }

            // course video
            if (inspectStatusRef.current.inspectionCourseVideoId) {
                for (let i = 0; i < inspect.courseVideo.formData.length; i++) {
                    if (inspect.courseVideo.formData[i].inspectionCourseApprovalId === 2) {
                        courseVideoApploval = 2; break;
                    }
                }
            }

            // course chapter
            if (inspectStatusRef.current.inspectionCourseChapterId) {
                for (let i = 0; i < inspect.courseChapter.formData.length; i++) {
                    if (inspect.courseChapter.formData[i].inspectionCourseApprovalId === 2) {
                        courseChapterApploval = 2; break;
                    }
                }
            }

            if ( // ผ่าน
                (
                    courseCardApploval === 1 &&                             // ต้องตรวจสอบเเล้ว
                    coursePriceApploval === 1 &&                            // ต้องตรวจสอบเเล้ว
                    courseDescriptionApploval === 1 &&                      // ต้องตรวจสอบเเล้ว
                    courseMediaIntroApploval === 1 &&                       // ต้องตรวจสอบเเล้ว
                    courseDocumentApploval === 1 &&                         // ต้องตรวจสอบเเล้ว
                    courseVideoApploval === 1 &&                            // ต้องตรวจสอบเเล้ว
                    courseChapterApploval === 1                             // ต้องตรวจสอบเเล้ว
                ) && inspect.inspectionCourseId === 2                       // สถานะโดยรวม คือ รอตรวจสอบ [2]
            ) {
                approvalStatusId = 1;                                       // ผ่าน มุมมองผู้สอน
                inspectionCourseCategoryId = 1;                             // ตรวจสอบเเล้ว
                inspectionCourseApprovalCategoryId = 1;                     // ผ่าน มุมมองผู้ตรวจ
                amountEdit = inspect.amountEdit;
                amountAdjust = inspect.amountAdjust;
                dateEndAdjust = null;
            } else if ( // ไม่ผ่าน
                (
                    courseCardApploval === 2 ||                             // ต้องไม่รอตรวจสอบ
                    coursePriceApploval === 2 ||                            // ต้องไม่รอตรวจสอบ
                    courseDescriptionApploval === 2 ||                      // ต้องไม่รอตรวจสอบ
                    courseMediaIntroApploval === 2 ||                       // ต้องไม่รอตรวจสอบ
                    courseDocumentApploval === 2 ||                         // ต้องไม่รอตรวจสอบ
                    courseVideoApploval === 2 ||                            // ต้องไม่รอตรวจสอบ
                    courseChapterApploval === 2                             // ต้องไม่รอตรวจสอบ
                ) || inspect.inspectionCourseId === 2                       // สถานะโดยรวม คือ รอตรวจสอบ [2]
            ) {
                approvalStatusId = 2;                                       // ไม่ผ่าน มุมมองผู้สอน
                inspectionCourseCategoryId = 3;                             // รอตอบกลับ
                inspectionCourseApprovalCategoryId = 2;                     // ไม่ผ่าน มุมมองผู้ตรวจ
                amountEdit = Number(inspect.amountEdit) + 1;
                amountAdjust = Number(inspect.amountAdjust) + 1;            // เพิ่มครั้งการตีกลับ
                dateEndAdjust = !inspect.dateEndAdjust ? moment().add(1, 'M').format(formatDateTimeDB) : inspect.dateEndAdjust;
            }

            let obj = {
                courseId: location.state.courseId,
                uid: location.state.uid,
                approvalStatusId,
                inspectionCourseCategoryId,
                inspectionCourseApprovalCategoryId,
                amountEdit,
                adminInspectId: location.state.adminLogin.adminListId,

                inspectionCourseCardId: inspectStatusRef.current.inspectionCourseCardId ? courseCardApploval : null,
                inspectionCoursePriceId: inspectStatusRef.current.inspectionCoursePriceId ? coursePriceApploval : null,
                inspectionCourseDescriptionId: inspectStatusRef.current.inspectionCourseDescriptionId ? courseDescriptionApploval : null,
                inspectionCourseMediaIntroId: inspectStatusRef.current.inspectionCourseMediaIntroId ? courseMediaIntroApploval : null,          // (*** ต้องผ่านทั้งหมด ถึงจะได้ update ข้อมูลหลัก)
                inspectionCourseDocumentId: inspectStatusRef.current.inspectionCourseDocumentId ? courseDocumentApploval : null,                // (*** ต้องผ่านทั้งหมด ถึงจะได้ update ข้อมูลหลัก)
                inspectionCourseVideoId: inspectStatusRef.current.inspectionCourseVideoId ? courseVideoApploval : null,                         // (*** ต้องผ่านทั้งหมด ถึงจะได้ update ข้อมูลหลัก)
                inspectionCourseChapterId: inspectStatusRef.current.inspectionCourseChapterId ? courseChapterApploval : null,                   // (*** ต้องผ่านทั้งหมด ถึงจะได้ update ข้อมูลหลัก)
            }
            // console.log("updateCourseInspectRequestEditByIdFetch : ", obj)
            result = await updateCourseInspectRequestEditByIdFetch(obj)
        }

        if (result.isSuccess) {
            getBaseApi()
            Notification("success", "ระบบ", "ส่งผลตรวจสอบสำเร็จ")

            let objEmail = {
                ownerName: userInfo.name,
                courseName: location.state.courseTitleName,
            }

            if (userType === "admin-inspect-open-course") {
                if (approvalStatusId === 1) { // inspect pass
                    inspectRequestOpenCoursePass(
                        emailSupport, // support@toflyn.com
                        location.state.user.email,
                        emailSubject + "เเจ้งผล คอร์ส " + location.state.courseTitleName + " ผ่านการตรวจสอบ",
                        objEmail,
                    )

                    // insert record to table pay_fee
                    let objPayFee = {
                        courseId: location.state.courseId,
                        feePercentageCategoryId: Number(value?.feePercentage), // เจ้าของคอร์สที่ถูกหักค่าธรรมเนียม
                        uid: location.state.uid,
                    }
                    // console.log("insertPayFeeFetch : ", objPayFee)
                    const result = await insertPayFeeFetch(objPayFee)
                    if (result.isSuccess) {
                        Notification("success", "ระบบ", "บันทึกค่าธรรมเนียมสำเร็จ")
                    }
                } else if (approvalStatusId === 2) { // inspect not pass
                    inspectRequestOpenCourseNotPass(
                        emailSupport, // support@toflyn.com
                        location.state.user.email,
                        emailSubject + "เเจ้งผล คอร์ส " + location.state.courseTitleName + " ไม่ผ่านการตรวจสอบ",
                        objEmail,
                    )
                }
            } else if (userType === "admin-inspect-edit-course") {
                if (approvalStatusId === 1) { // inspect pass
                    inspectRequestEditCoursePass(
                        emailSupport, // support@toflyn.com
                        location.state.user.email,
                        emailSubject + "เเจ้งผล คอร์ส " + location.state.courseTitleName + " ผ่านการตรวจสอบ",
                        objEmail,
                    )
                } else if (approvalStatusId === 2) { // inspect not pass
                    inspectRequestEditCourseNotPass(
                        emailSupport, // support@toflyn.com
                        location.state.user.email,
                        emailSubject + "เเจ้งผล คอร์ส " + location.state.courseTitleName + " ไม่ผ่านการตรวจสอบ",
                        objEmail,
                    )
                }
            }

            // redirect
            redirectAdminManage()
        } else {
            Notification("error", "ระบบ", "ส่งผลตรวจสอบไม่สำเร็จ กรุณาลองใหม่อีกครั้ง")
        }
    }

    const handleOverdue = async () => {
        let obj = {
            courseId: location.state.courseId,
            uid: location.state.uid,

            inspectionCourseCategoryId: 4,                          // เกินกำหนดปรับเเก้
            inspectionCourseApprovalCategoryId: 2,                  // ผ่าน มุมมองผู้สอน
            isOverdue: true,                                        // เกินกำหนด
            approvalStatusId: 2,                                    // ผ่าน มุมมองผู้ตรวจ
            adminInspectId: location.state.adminLogin.adminListId,
        }
        const result = await updateCourseOverdueFetch(obj)
        if (result.isSuccess) {
            getBaseApi()
            Notification("success", "ระบบ", "บันทึกการเกินกำหนดสำเร็จ")

            let objEmail = {
                ownerName: userInfo.name,
                courseName: location.state.courseTitleName,
            }
            inspectRequestOpenCourseOverdue(
                emailSupport, // support@toflyn.com
                location.state.user.email,
                emailSubject + "เเจ้งผล คอร์ส " + location.state.courseTitleName + " ผ่านการตรวจสอบ",
                objEmail,
            )

            // redirect
            redirectAdminManage()
        } else {
            Notification("error", "ระบบ", "บันทึกการเกินกำหนดไม่สำเร็จ กรุณาลองใหม่อีกครั้ง")
        }
    }

    const redirectAdminManage = () => {
        navigate(
            "/erp-manage",
            { state: location.state.adminLogin }
        )
    }

    useEffect(() => {
        getBaseApi()
    }, [])

    useEffect(() => {

    }, [isSendResults])

    return (
        <div style={{ backgroundColor: '#ECECEC' }}>
            <div>
                <PageHeader
                    title={
                        <>
                            <label style={{ paddingBottom: 0, marginBottom: 0 }}>
                                {userType === "admin-inspect-open-course" ? "ตรวจสอบคำขอเปิดคอร์ส" : []}
                                {userType === "admin-inspect-edit-course" ? "ตรวจสอบคำขอเเก้ไขคอร์ส" : []}
                            </label>
                            <br />
                            <label style={{ paddingBottom: 0, marginBottom: 0, fontSize: 16, fontWeight: "initial" }}>ผู้ใช้งาน : {location.state ? location.state.adminLogin.username : "-"}</label>
                        </>
                    }
                    extra={[
                        <a
                            onClick={() => {
                                navigate(
                                    "/erp-manage",
                                    { state: null }
                                )

                                navigate("/erp-login")
                            }}
                            style={{ position: 'absolute', marginTop: 5, marginLeft: -85 }}
                        >ออกจากระบบ</a>
                    ]}
                    style={{ backgroundColor: 'white', borderBottom: '1px solid #ECECEC' }}
                >
                </PageHeader>
                <Layout>
                    <Sider
                        width={300}
                        className="site-layout-background"
                    >
                        <Menu
                            defaultSelectedKeys={['1']}
                            defaultOpenKeys={['menu0']}
                            mode="inline"
                            selectedKeys={selectedMenuItem}
                            onClick={async (e) => {
                                setSelectedMenuItem(e.key);
                            }}
                            style={{ height: '100%' }}
                        >
                            {/* course card */}
                            {inspect ?
                                (inspect.inspectionCourseCardId ?
                                    <Menu.Item key="menu1" style={{ height: 60, borderBottom: "1px solid #DDDDDD", padding: 0, margin: 0 }}>
                                        <Row>
                                            <Col span={14}>
                                                <label style={{ paddingLeft: 20, marginBottom: 0, cursor: 'pointer' }}>หน้าปกคอร์ส</label>
                                            </Col>
                                            <Col span={10}>
                                                <label style={{ position: "absolute", marginTop: -13, cursor: "pointer" }}>
                                                    {inspect ?
                                                        (inspect.inspectionCourseCardName ? inspect.inspectionCourseCardName : "-")
                                                        : "-"}
                                                </label>
                                                <label style={{ position: "absolute", marginTop: 13, cursor: "pointer" }}>
                                                    {inspect.course ?
                                                        (inspect.course.inspectionCourseCardApprovalName ? inspect.course.inspectionCourseCardApprovalName : "-")
                                                        : "-"}
                                                </label>
                                            </Col>
                                        </Row>
                                    </Menu.Item>
                                    : [])
                                : []}

                            {/* course media intro */}
                            {inspect ?
                                (inspect.inspectionCourseMediaIntroId ?
                                    <Menu.Item key="menu2" style={{ height: 60, borderBottom: "1px solid #DDDDDD", padding: 0, margin: 0 }}>
                                        <Row>
                                            <Col span={14}>
                                                <label style={{ paddingLeft: 20, marginBottom: 0, cursor: 'pointer' }}>สื่อนำเสนอ</label>
                                            </Col>
                                            <Col span={10}>
                                                <label style={{ position: "absolute", marginTop: -13, cursor: "pointer" }}>
                                                    {inspect ?
                                                        (inspect.inspectionCourseMediaIntroName ? inspect.inspectionCourseMediaIntroName : "-")
                                                        : "-"}
                                                </label>
                                                <label style={{ position: "absolute", marginTop: 13, cursor: "pointer" }}>
                                                    {inspect.courseMediaIntro ?
                                                        (inspect.courseMediaIntro.inspectionCourseApprovalNameSummary ? inspect.courseMediaIntro.inspectionCourseApprovalNameSummary : "-")
                                                        : "-"}
                                                </label>
                                            </Col>
                                        </Row>
                                    </Menu.Item>
                                    : [])
                                : []}

                            {/* course price */}
                            {inspect ?
                                (inspect.inspectionCoursePriceId ?
                                    <Menu.Item key="menu3" style={{ height: 60, borderBottom: "1px solid #DDDDDD", padding: 0, margin: 0 }}>
                                        <Row>
                                            <Col span={14}>
                                                <label style={{ paddingLeft: 20, marginBottom: 0, cursor: 'pointer' }}>ราคาคอร์ส</label>
                                            </Col>
                                            <Col span={10}>
                                                <label style={{ position: "absolute", marginTop: -13, cursor: "pointer" }}>
                                                    {inspect ?
                                                        (inspect.inspectionCoursePriceName ? inspect.inspectionCoursePriceName : "-")
                                                        : "-"}
                                                </label>
                                                <label style={{ position: "absolute", marginTop: 13, cursor: "pointer" }}>
                                                    {inspect.course ?
                                                        (inspect.course.inspectionCoursePriceApprovalName ? inspect.course.inspectionCoursePriceApprovalName : "-")
                                                        : "-"}
                                                </label>
                                            </Col>
                                        </Row>
                                    </Menu.Item>
                                    : [])
                                : []}

                            {/* course description */}
                            {inspect ?
                                (inspect.inspectionCourseDescriptionId ?
                                    <Menu.Item key="menu4" style={{ height: 60, borderBottom: "1px solid #DDDDDD", padding: 0, margin: 0 }}>
                                        <Row>
                                            <Col span={14}>
                                                <label style={{ paddingLeft: 20, marginBottom: 0, cursor: 'pointer' }}>รายละเอียดคอร์ส</label>
                                            </Col>
                                            <Col span={10}>
                                                <label style={{ position: "absolute", marginTop: -13, cursor: "pointer" }}>
                                                    {inspect ?
                                                        (inspect.inspectionCourseDescriptionName ? inspect.inspectionCourseDescriptionName : "-")
                                                        : "-"}
                                                </label>
                                                <label style={{ position: "absolute", marginTop: 13, cursor: "pointer" }}>
                                                    {inspect.course ?
                                                        (inspect.course.inspectionCourseDescriptionApprovalName ? inspect.course.inspectionCourseDescriptionApprovalName : "-")
                                                        : "-"}
                                                </label>
                                            </Col>
                                        </Row>
                                    </Menu.Item>
                                    : [])
                                : []}

                            {/* course document */}
                            {inspect ?
                                (inspect.inspectionCourseDocumentId ?
                                    <Menu.Item key="menu5" style={{ height: 60, borderBottom: "1px solid #DDDDDD", padding: 0, margin: 0 }}>
                                        <Row>
                                            <Col span={14}>
                                                <label style={{ paddingLeft: 20, marginBottom: 0, cursor: 'pointer' }}>เอกสารเพิ่มเติม</label>
                                            </Col>
                                            <Col span={10}>
                                                <label style={{ position: "absolute", marginTop: -13, cursor: "pointer" }}>
                                                    {inspect ?
                                                        (inspect.inspectionCourseDocumentName ? inspect.inspectionCourseDocumentName : "-")
                                                        : "-"}
                                                </label>
                                                <label style={{ position: "absolute", marginTop: 13, cursor: "pointer" }}>
                                                    {inspect.courseDocument ?
                                                        (inspect.courseDocument.inspectionCourseApprovalNameSummary ? inspect.courseDocument.inspectionCourseApprovalNameSummary : "-")
                                                        : "-"}
                                                </label>
                                            </Col>
                                        </Row>
                                    </Menu.Item>
                                    : [])
                                : []}

                            {/* course video */}
                            {inspect ?
                                (inspect.inspectionCourseVideoName ?
                                    <Menu.Item key="menu6" style={{ height: 60, borderBottom: "1px solid #DDDDDD", padding: 0, margin: 0 }}>
                                        <Row>
                                            <Col span={14}>
                                                <label style={{ paddingLeft: 20, marginBottom: 0, cursor: 'pointer' }}>รายการวิดีโอ</label>
                                            </Col>
                                            <Col span={10}>
                                                <label style={{ position: "absolute", marginTop: -13, cursor: "pointer" }}>
                                                    {inspect ?
                                                        (inspect.inspectionCourseVideoName ? inspect.inspectionCourseVideoName : "-")
                                                        : "-"}
                                                </label>
                                                <label style={{ position: "absolute", marginTop: 13, cursor: "pointer" }}>
                                                    {inspectApprovalVideoAndChapter ?
                                                        (inspectApprovalVideoAndChapter.inspectionCourseApprovalNameSummary ? inspectApprovalVideoAndChapter.inspectionCourseApprovalNameSummary : "-")
                                                        : "-"}
                                                </label>
                                            </Col>
                                        </Row>
                                    </Menu.Item>
                                    : [])
                                : []}
                        </Menu>
                    </Sider>
                    <article style={{ backgroundColor: 'white', width: '100%', height: '100%' }}>
                        {!loading ?
                            <Row>
                                <Col span={16} style={{ padding: 15 }}>
                                    <Row gutter={[4, 4]} style={{ fontSize: 14 }}>
                                        <Col span={4}><label style={{ float: "right" }}>ผู้สอน :</label></Col>
                                        <Col span={8}>
                                            {userType === "admin-inspect-open-course" || userType === "admin-inspect-edit-course" ?
                                                <label style={{ paddingLeft: 10 }}>{location.state.user.userName}</label> : []}
                                        </Col>

                                        <Col span={4}><label style={{ float: "right" }}>สร้างเมื่อ :</label></Col>
                                        <Col span={8}>
                                            {userType === "admin-inspect-open-course" || userType === "admin-inspect-edit-course" ?
                                                <label style={{ paddingLeft: 10 }}>{moment(location.state.createdAt).format(formatDateTime)}</label> : []}
                                        </Col>

                                        <Col span={4}><label style={{ float: "right" }}>เบอร์โทร :</label></Col>
                                        <Col span={8}>
                                            {userType === "admin-inspect-open-course" || userType === "admin-inspect-edit-course" ?
                                                <label style={{ paddingLeft: 10 }}>{location.state.user.phone}</label> : []}
                                        </Col>

                                        <Col span={4}><label style={{ float: "right" }}>อีเมล :</label></Col>
                                        <Col span={8}>
                                            {userType === "admin-inspect-open-course" || userType === "admin-inspect-edit-course" ?
                                                <label style={{ paddingLeft: 10 }}>{location.state.user.email}</label> : []}
                                        </Col>

                                        <Col span={4}><label style={{ float: "right" }}>ตีกลับ :</label></Col>
                                        <Col span={8}>
                                            {userType === "admin-inspect-open-course" ? <label style={{ paddingLeft: 10 }}>{inspect.amountAdjust} ครั้ง</label> : []}
                                            {userType === "admin-inspect-edit-course" ? <label style={{ paddingLeft: 10 }}>{location.state.amountEdit}</label> : []}
                                        </Col>

                                        {userType === "admin-inspect-open-course" ?
                                            <>
                                                <Col span={4}><label style={{ float: "right" }}>จำนวนคอร์สในคลัง :</label></Col>
                                                <Col span={8}>
                                                    <label style={{ paddingLeft: 10 }}>{inspect.courseById ? inspect.courseById.length : "0"} คอร์ส</label>
                                                </Col>
                                            </>
                                            : []}

                                        {userType === "admin-inspect-open-course" ?
                                            <>
                                                <Col span={4}><label style={{ float: "right" }}>ระยะเวลาที่ใช้เเก้ไข :</label></Col>
                                                <Col span={8}>

                                                    {inspect.dateEndAdjust ?
                                                        <label style={{ paddingLeft: 10 }}>
                                                            {moment(inspect.dateEndAdjust, formatDate).diff(new Date(), 'days')} วัน, {moment(inspect.dateEndAdjust).format(formatDateTime)}
                                                        </label>
                                                        : " -"}
                                                </Col>
                                            </>
                                            : []}
                                    </Row>
                                </Col>
                                <Col span={5} style={{ padding: 15, marginTop: 11 }}>
                                    {userType === "admin-inspect-open-course" ?
                                        <Form
                                            layout="vertical"
                                            form={form}
                                            onFinish={onFinish}
                                        >
                                            <Form.Item
                                                name="feePercentage"
                                                label="ค่าธรรมเนียมที่ถูกหักจากขายคอร์ส"
                                                rules={[{ required: true, message: 'กรุณาเลือกค่าธรรมเนียม' }]}
                                            >
                                                <Select
                                                    disabled={!isSendResults}
                                                    placeholder="ค่าธรรมเนียม"
                                                    style={{ width: "100%" }}
                                                >
                                                    {feePercentage?.map((fee, index) => (
                                                        <Option key={index} value={fee.value}>{fee.label}</Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        </Form>
                                        : []}
                                </Col>
                                <Col span={3} style={{ padding: 15 }}>
                                    <Row gutter={[10, 10]}>
                                        <Col span={24}>
                                            <Popconfirm
                                                title="คุณยืนยันส่งผลตรวจหรือไม่ ?"
                                                okText={<span style={{ width: 50 }}>ใช่</span>}
                                                onConfirm={() => {
                                                    handleSendResult(null);
                                                }}
                                                cancelText={<span style={{ width: 50 }}>ไม่ใช่</span>}
                                                disabled={!isSendResults}
                                            >
                                                <Button
                                                    loading={loading}
                                                    disabled={!isSendResults}
                                                    type="primary"
                                                    style={{ width: 110, float: "right" }}
                                                >
                                                    {!loading ? "ส่งผลตรวจ" : []}
                                                </Button>
                                            </Popconfirm>
                                        </Col>

                                        {userType === "admin-inspect-open-course" ?
                                            <Col span={24}>
                                                <Popconfirm
                                                    title="คุณยืนยันบันทึกการเกินกำหนดหรือไม่ ?"
                                                    okText={<span style={{ width: 50 }}>ใช่</span>}
                                                    onConfirm={() => handleOverdue()}
                                                    cancelText={<span style={{ width: 50 }}>ไม่ใช่</span>}
                                                    disabled={!isOverdue}
                                                >
                                                    <Button
                                                        loading={loading}
                                                        disabled={!isOverdue}
                                                        type="primary"
                                                        style={{ width: 110, float: "right" }}
                                                    >
                                                        {!loading ? "เกินกำหนด" : []}
                                                    </Button>
                                                </Popconfirm>
                                            </Col>
                                            : []}
                                    </Row>
                                </Col>
                                <Divider style={{ marginBottom: 0, marginTop: 0 }} />
                                <Col span={24}>
                                    <div style={{ paddingLeft: 15, paddingRight: 15, paddingTop: 15, paddingBottom: 10 }}>
                                        {componentsSwitch(selectedMenuItem)}
                                    </div>
                                </Col>
                            </Row>
                            :
                            <center><Spin size="large" style={{ marginTop: "20%" }} /></center>
                        }
                    </article>
                </Layout>
            </div >
        </div >
    )
}
