import { Layout } from 'antd'
import Footer from '../../../common/components/footer'
import Header from '../../../common/components/header'
import PointViewItem from './PointViewItem'
import { useDimensions } from '../../mamager/ScreenManager'

const { Content } = Layout;

export default function PointView(props) {

    const { width } = useDimensions()

    return (
        <>
            <Header
                firebase={props.firebase}
                user={props.user}
            />

            <article>
                <div
                    style={
                        width > 1500 ?
                            { backgroundColor: "white", marginLeft: '5%', marginRight: '5%', paddingTop: '1%', paddingBottom: '1%' }
                            :
                            (
                                (width > 912 && width <= 1500) ?
                                    { backgroundColor: "white", marginLeft: '5%', marginRight: '5%', paddingTop: '1%', paddingBottom: '1%' }
                                    :
                                    { backgroundColor: "white", marginLeft: '0%', marginRight: '0%', paddingTop: '0%', paddingBottom: '0%' }
                            )
                    }
                >
                    <PointViewItem />
                </div>
            </article>

            <Footer />
        </>
    )
}
