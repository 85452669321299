import { combineReducers } from "redux"

import courseManageCardReducer from './courseManageCard.reducer'
import courseManageIntroReducer from './courseManageIntro.reducer'
import courseManagePriceReducer from './courseManagePrice.reducer'
import courseManageDescriptionReducer from './courseManageDescription.reducer'
import courseManageVideoListReducer from './courseManageVideoList.reducer'
import courseManageChapterReducer from './courseManageChapter.reducer'
import courseManageDocumentReducer from './courseManageDocument.reducer'
import courseManageAddCurrentStepReducer from './courseManageAddCurrentStep.reducer'
import registerInformationReducer from "./registerInformation.reducer"
import registerCareerAndEducationReducer from "./registerCareerAndEducation.reducer"
import registerCourseCategoryInterestReducer from "./registerCourseCategoryInterest.reducer"
import registerCurrentStepReducer from "./registerCurrentStep.reducer"
import registerAddressReducer from "./registerAddress.reducer"
import registerBusinessDetailsReducer from "./registerBusinessDetails.reducer"
import orderPaymentChapterAndVideoCourseReducer from "./orderPaymentChapterAndVideoCourse.reducer"

export default combineReducers({
    courseManageCardReducer,
    courseManageIntroReducer,
    courseManagePriceReducer,
    courseManageDescriptionReducer,
    courseManageVideoListReducer,
    courseManageChapterReducer,
    courseManageDocumentReducer,
    courseManageAddCurrentStepReducer,
    registerCurrentStepReducer,
    registerInformationReducer,
    registerCareerAndEducationReducer,
    registerCourseCategoryInterestReducer,
    registerAddressReducer,
    registerBusinessDetailsReducer,
    orderPaymentChapterAndVideoCourseReducer
})