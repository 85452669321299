/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */

import { useState, useEffect, useRef } from 'react'
import {
    Table,
    Row,
    Col,
    Button,
    Form,
    Modal,
    Input,
    Popconfirm
} from 'antd'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import { FaCog } from "react-icons/fa"
import { FiEdit } from "react-icons/fi"
import { RiDeleteBin6Line } from "react-icons/ri"
import moment from 'moment'
import ImageUpload from '../../../../common/components/upload/ImageUpload'
import { deleteAnnouncementFetch, getAnnouncementAllFetch, insertAnnouncementFetch, updateAnnouncementFetch } from '../../API'

const columns = [
    {
        title: 'No',
        dataIndex: 'index',
        width: "10%",
    },
    {
        title: 'เรื่องประกาศ',
        dataIndex: 'announcementTitleName',
        width: "55%",
    },
    {
        title: 'วันที่สร้าง',
        dataIndex: 'createdAt',
        width: "10%",
    },
    {
        title: 'วันที่เเก้ไข',
        dataIndex: 'updatedAt',
        width: "10%",
    },
    {
        title: <FaCog />,
        dataIndex: 'operator',
        align: "center",
        width: "15%",
    },
]

const formatDate = "DD/MM/YYYY"

export default function AnnounceManage() {

    const [searchUser, setSearchUser] = useState('')
    const [list, setList] = useState([])
    const [loading, setLoading] = useState(true)

    const [form] = Form.useForm()

    const [announcementDescription, setAnnouncementDescription] = useState()
    const [announcementImage, setAnnouncementImage] = useState(null)
    const announcementDescriptionRef = useRef()

    const [pageCurrent, setPageCurrent] = useState(1)

    const [modal, setModal] = useState({
        isShow: false,
        title: null
    })

    const getAnnouncementAll = async () => {
        setLoading(true)

        const result = await getAnnouncementAllFetch()
        // console.log("result : ", result)

        let tempList = [];
        result.map((val, index) => {
            tempList.push({
                index: (index + 1),
                announcementTitleName: val.announcementTitleName,
                createdAt: val.createdAt ? moment(val.createdAt).format(formatDate) : "-",
                updatedAt: val.updatedAt ? moment(val.updatedAt).format(formatDate) : "-",
                operator:
                    <>
                        <Button
                            icon={<FiEdit style={{ marginTop: -3, color: "white" }} />}
                            style={{
                                width: 35,
                                backgroundColor: "orange",
                                border: "1px solid orange",
                                color: 'white',
                                borderRadius: 50
                            }}
                            onClick={() => {

                                form.setFieldsValue({
                                    announcementId: val.announcementId,
                                    announcementTitleName: val.announcementTitleName,
                                    updatedAt: val.updatedAt,
                                    createdAt: val.createdAt,
                                })
                                setAnnouncementImage(val.announcementImage)
                                setAnnouncementDescription(val.announcementDescription)
                                setModal({ isShow: true, title: "edit" })
                            }}
                        ></Button>{" "}

                        <Popconfirm
                            title="คุณยืนยันลบหรือไม่ ?"
                            okText={<span style={{ width: 50 }}>ใช่</span>}
                            onConfirm={async () => {
                                await deleteAnnouncementFetch(val.announcementId)

                                // reload
                                await getAnnouncementAll();
                            }}
                            cancelText={<span style={{ width: 50 }}>ไม่ใช่</span>}
                        >
                            <Button
                                icon={<RiDeleteBin6Line style={{ marginTop: -3, color: "white" }} />}
                                danger
                                type="primary"
                                style={{
                                    width: 35,
                                    borderRadius: 50
                                }}
                            ></Button>{" "}
                        </Popconfirm>
                    </>
            });
        });
        setList(tempList)

        setLoading(false)
    }

    const onFinish = async (values) => {
        let request = {
            announcementId: values.announcementId,
            announcementTitleName: values.announcementTitleName,
            announcementImage: announcementImage,
            announcementDescription: announcementDescriptionRef.current
        }
        if (modal.title === "add") {
            await insertAnnouncementFetch(request)
        } else if (modal.title === "edit") {
            await updateAnnouncementFetch(request)
        }

        // reload
        getAnnouncementAll()

        // set default
        setFormDefault()
    }

    const onPagine = (n) => {
        setPageCurrent(n.current)
    };

    const setFormDefault = () => {
        form.setFieldsValue({
            announcementId: undefined,
            announcementTitleName: undefined,
            announcementDescription: undefined,
        })

        setAnnouncementDescription("")

        setModal({
            isShow: false,
            title: null,
        })
    }

    const detectAnnouncementDescription = (rules, value) => {
        if (announcementDescriptionRef.current === "") {
            return Promise.reject(new Error('กรุณากรอกรายละเอียดข่าวรประกาศ'))
        } else {
            return Promise.resolve()
        }
    }

    const handleImageUrl = (img) => {
        // console.log("handleImageUrl : ", img)
        setAnnouncementImage(img)
    }

    useEffect(() => {
        getAnnouncementAll()
    }, [])

    return (
        <Row>
            <Col span={12}>
                <label>จัดการประกาศข่าวสาร</label>
            </Col>
            <Col span={12} style={{ paddingBottom: 20 }}>
                <Button
                    type="primary"
                    style={{ float: 'right' }}
                    onClick={() => {
                        setModal({
                            isShow: true,
                            title: "add"
                        })
                        setAnnouncementImage(null)
                    }}
                >เพิ่มข่าวประกาศ</Button>
                <label style={{ float: 'right', width: 20 }} ></label>
                <Input
                    placeholder="ค้นหา Username"
                    value={searchUser}
                    onChange={e => {
                        const currValue = e.target.value
                        setSearchUser(currValue)
                        if (currValue === "") {
                            getAnnouncementAll()
                        } else {
                            const filteredData = list.filter(entry => entry.announcementTitleName.includes(currValue))
                            setList(filteredData)
                        }
                    }}
                    style={{ float: 'right', width: 250 }}
                />
            </Col>
            <Col span={24}>
                <Table
                    loading={loading}
                    columns={columns}
                    dataSource={list}
                    pagination={{
                        current: pageCurrent,
                        pageSize: 5,
                        total: list.length
                    }}
                    onChange={(n) => onPagine(n)}
                >
                </Table>
            </Col>

            <Modal
                title={<strong><label className="topic-color-bold">{modal.title === "add" ? "เพิ่มข่าวประกาศ" : "เเก้ไขข่าวประกาศ"}</label></strong>}
                visible={modal.isShow}
                onCancel={() => {

                    // default
                    setFormDefault();
                }}
                width={1000}
                onOk={() => {
                    form.submit();
                }}
                okText={<label style={{ width: 50, cursor: 'pointer' }}>บันทึก</label>}
                cancelText={<label style={{ width: 50, cursor: 'pointer' }}>ยกเลิก</label>}
            >
                <Form
                    layout="vertical"
                    form={form}
                    onFinish={onFinish}
                >
                    <Row gutter={[24, 0]}>
                        <Col span={13}>
                            <Form.Item name="announcementId" style={{ display: "none" }}>
                                <Input />
                            </Form.Item>

                            <Form.Item
                                name="announcementTitleName"
                                label="หัวข้อประกาศ"
                                rules={[{ required: true, message: 'กรุณากรอกหัวข้อประกาศ' }]}
                            >
                                <Input.TextArea
                                    style={{ width: "100%" }}
                                // autoSize={{ minRows: 6, maxRows: 6 }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={11}>
                            <label>ภาพข่าวประกาศ</label>
                            <ImageUpload
                                // reset={switchImage}
                                handleImageUrl={handleImageUrl}
                                requestName="imgCardFront"
                                buttonName="อัพโหลดรูปภาพ"
                                messegeSuccess="อัพโหลดภาพบัตรประชาชนสำเร็จ"
                                messegeFail="ไม่สามารถอัพโหลดภาพบัตรประชาชนได้ กรุณาลองใหม่อีกครั้ง"
                                aspectImageHeight={200}
                                aspectImageWidth={420}
                                imageUrl={announcementImage}
                            />
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name="announcementDescription"
                                label="รายละเอียดข่าวรประกาศ"
                                rules={[{ required: true, validator: detectAnnouncementDescription }]}
                            >
                                <CKEditor
                                    editor={ClassicEditor}
                                    config={{
                                        toolbar: {
                                            items: [
                                                'heading', '|',
                                                'fontfamily', 'fontsize', '|',
                                                'bold', 'italic', 'underline', '|',
                                                'alignment', '|',
                                                'fontColor', 'fontBackgroundColor', '|',
                                                'bulletedList', 'numberedList', 'todoList', '|',
                                                'code', 'codeBlock', '|',
                                                'undo', 'redo'
                                            ],
                                            removeButtons: 'Subscript,Superscript',
                                            // height: 350,
                                        },
                                    }}
                                    data={announcementDescription}
                                    onBlur={(event, editor) => {
                                        const data = editor.getData()
                                        announcementDescriptionRef.current = data
                                    }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </Row>
    )
}
