import { server } from "./../../../../../constants"
import { httpClient } from "./../../../../../utils/HttpClient"

const getCouponManageAllFetch = async (value) => { // Done
    const result = await httpClient.post(server.GET_COUPON_MANAGE_ALL_URL, { value })
    if (result.data.isSuccess) {
        return result.data.formData
    } else {
        return null
    }
}

const insertCouponManageFetch = async (value) => {
    const result = await httpClient.post(server.INSERT_COUPON_MANAGE_URL, { value })
    if (result.data.isSuccess) {
        return result
    } else {
        return null
    }
}

const updateCouponManageByIdFetch = async (value) => {
    const result = await httpClient.put(server.UPDATE_COUPON_MANAGE_BY_Id_URL, { value })
    return result.data
}

export {
    // get
    getCouponManageAllFetch,

    // insert
    insertCouponManageFetch,

    // update
    updateCouponManageByIdFetch,

    // delete

    // notify
}