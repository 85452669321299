export default function RefundPolicyItem(props) {
    return (
        <>
            <p style={{ textAlign: "center", fontSize: props.headerSize }}><strong>นโยบายการคืนเงิน</strong></p>

            <span style={{ fontWeight: "bold" }}>การซื้อคอร์สเรียน</span><br/>
            <p><span>ผู้เรียนที่ซื้อคอร์สสำเร็จ เเละได้คอร์สเรียนเเล้ว จะไม่สามารถคืนเงินได้ แม้ว่าผู้เรียนจะยกเลิกการใช้งานเเพลตฟอร์ม Toflyn ก็ตาม</span></p>
            <p><span>กรณีผู้เรียนชำระเงินซื้อคอร์สสำเร็จ เเต่ไม่สามารถเข้าเรียนคอร์สได้ ที่อาจเกิดจากเเพลตฟอร์มทำงานผิดพลาด หรือส่วนอื่นใด ๆ ทางเราจะคืนเงินให้ผู้เรียนเต็มจำนวน ถ้าหากผู้เรียนยังต้องการซื้อคอร์สเดิมอยู่ ให้ดำเนินการซื้อคอร์สใหม่อีกครั้ง</span></p>
            <p><span>กรณีผู้สอนทำผิดเรื่องข้อตกลงการใช้งานเเพลตฟอร์มระหว่างคอร์สถูกเเสดงสู่สาธารณะเเล้ว จนทำให้คอร์สเรียนต้องถูกถอนออกจากหน้าร้าน ผู้เรียนที่ซื้อคอร์สก่อนหน้ายังสามารถเข้าเรียนได้ปกติ (ไม่ถูกคืนเงิน) เนื่องจากก่อนหน้าชำระเงิน คอร์สดังกล่าวได้ผ่านเกณฑ์การตรวจสอบมาเเล้ว เเละกระทำผิดภายหลัง เเต่หากเป็นผู้เรียนรายใหม่จะไม่สามารถซื้อ เเละเห็นคอร์สดังกล่าวได้</span></p>

            <span style={{ fontWeight: "bold" }}>การลงโฆษณา</span><br/>
            <p style={{}}><span>หากมีการทำสัญญาระหว่างเเพลตฟอร์ม Toflyn กับผู้ลงโฆษณา เเละชำระเงินเสร็จสิ้นเเล้ว จะไม่สามารถคืนเงินได้ ผู้ลงโฆษณาสามารถยกเลิกการเผยเเพร่โฆษณาระหว่างทางได้ทุกเมื่อ เเต่จะไม่มีการคืนเงินจากระยะเวลาที่เหลือโฆษณา</span></p>

            <p><span style={{}}>หากมีคำถามเพิ่มเติม สามารถติดต่อเราได้ที่ </span><a href = "mailto: support@toflyn.com">support@toflyn.com</a><span style={{}}> หรือโทร 02-114-3464</span></p>
        
            <p><span style={{}}>ฉบับวันที่ 1 มิถุนายน 2565</span></p>
        </>
    )
}
