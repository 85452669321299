import { server } from "../../../../constants"
import { httpClient } from "../../../../utils/HttpClient"

const getCareersFetch = async (language) => { // language -> th, eng
    try {
        const result = await httpClient.get(server.GET_CAREERS + `/${language}`)
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getEducationFetch = async (language) => { // language -> th, eng
    try {
        const result = await httpClient.get(server.GET_EDUCATION + `/${language}`)
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getBusinessCategoryFetch = async (language) => { // language -> th, eng
    try {
        const result = await httpClient.get(server.GET_BUSINESS_CATEGORY + `/${language}`)
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

export {
    // get
    getCareersFetch,
    getEducationFetch,
    getBusinessCategoryFetch,
}