import { sendEmailServiceFetch } from "../API/mailgun"

const verifyIdentityOTP = async (emailFrom, emailTo, emailSubject, emailValue) => {
    await sendEmailServiceFetch({
        emailFrom, emailTo, emailSubject, emailValue, 
        template: "verify-identity-otp"
    })
}

const inspectRequestOpenCoursePass = async (emailFrom, emailTo, emailSubject, emailValue) => {
    await sendEmailServiceFetch({
        emailFrom, emailTo, emailSubject, emailValue, 
        template: "inspect-request-open-course-pass"
    })
}

const inspectRequestOpenCourseNotPass = (emailFrom, emailTo, emailSubject, emailValue) => {
    sendEmailServiceFetch({
        emailFrom, emailTo, emailSubject, emailValue, 
        template: "inspect-request-open-course-not-pass"
    })
}

const inspectRequestOpenCourseOverdue = (emailFrom, emailTo, emailSubject, emailValue) => {
    sendEmailServiceFetch({
        emailFrom, emailTo, emailSubject, emailValue, 
        template: "inspect-request-open-course-overdue"
    })
}

const inspectRequestEditCoursePass = (emailFrom, emailTo, emailSubject, emailValue) => {
    sendEmailServiceFetch({
        emailFrom, emailTo, emailSubject, emailValue, 
        template: "inspect-request-edit-course-pass"
    })
}

const inspectRequestEditCourseNotPass = (emailFrom, emailTo, emailSubject, emailValue) => {
    sendEmailServiceFetch({
        emailFrom, emailTo, emailSubject, emailValue, 
        template: "inspect-request-edit-course-not-pass"
    })
}

const inspectIdCardAccountPass = (emailFrom, emailTo, emailSubject, emailValue) => {
    sendEmailServiceFetch({
        emailFrom, emailTo, emailSubject, emailValue, 
        template: "inspect-id-card-account-pass"
    })
}

const inspectIdCardAccountNotPass = (emailFrom, emailTo, emailSubject, emailValue) => {
    sendEmailServiceFetch({
        emailFrom, emailTo, emailSubject, emailValue, 
        template: "inspect-id-card-account-not-pass"
    })
}

const inspectBankAccountPass = (emailFrom, emailTo, emailSubject, emailValue) => {
    sendEmailServiceFetch({
        emailFrom, emailTo, emailSubject, emailValue, 
        template: "inspect-bank-account-pass"
    })
}

const inspectBankAccountNotPass = (emailFrom, emailTo, emailSubject, emailValue) => {
    sendEmailServiceFetch({
        emailFrom, emailTo, emailSubject, emailValue, 
        template: "inspect-bank-account-not-pass"
    })
}

const inspectFormRequestOpenCoursePass = (emailFrom, emailTo, emailSubject, emailValue) => {
    sendEmailServiceFetch({
        emailFrom, emailTo, emailSubject, emailValue, 
        template: "inspect-form-request-open-course-pass"
    })
}

const inspectFormRequestOpenCourseNotPass = (emailFrom, emailTo, emailSubject, emailValue) => {
    sendEmailServiceFetch({
        emailFrom, emailTo, emailSubject, emailValue, 
        template: "inspect-form-request-open-course-not-pass"
    })
}

const inspectFormRequestOpenCourseOverdue = (emailFrom, emailTo, emailSubject, emailValue) => {
    sendEmailServiceFetch({
        emailFrom, emailTo, emailSubject, emailValue, 
        template: "inspect-form-request-open-course-overdue"
    })
}

const paymentOrderTransferCancel = (emailFrom, emailTo, emailSubject, emailValue) => {
    sendEmailServiceFetch({
        emailFrom, emailTo, emailSubject, emailValue, 
        template: "payment-order-transfer-cancel"
    })
}

const paymentOrderTransferPass = (emailFrom, emailTo, emailSubject, emailValue) => {
    sendEmailServiceFetch({
        emailFrom, emailTo, emailSubject, emailValue, 
        template: "payment-order-transfer-pass"
    })
}

const paymentOrderTransferNotPass = (emailFrom, emailTo, emailSubject, emailValue) => {
    sendEmailServiceFetch({
        emailFrom, emailTo, emailSubject, emailValue, 
        template: "payment-order-transfer-not-pass"
    })
}

export {
    verifyIdentityOTP,
    inspectRequestOpenCoursePass,
    inspectRequestOpenCourseNotPass,
    inspectRequestOpenCourseOverdue,
    inspectRequestEditCoursePass,
    inspectRequestEditCourseNotPass,
    inspectIdCardAccountPass,
    inspectIdCardAccountNotPass,
    inspectBankAccountPass,
    inspectBankAccountNotPass,
    inspectFormRequestOpenCoursePass,
    inspectFormRequestOpenCourseNotPass,
    inspectFormRequestOpenCourseOverdue,
    paymentOrderTransferCancel,
    paymentOrderTransferPass,
    paymentOrderTransferNotPass
}