/* eslint-disable jsx-a11y/anchor-is-valid */

import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Row, Col, Button, Space, List, Image } from "antd"
import { AiFillStar, AiOutlineStar } from "react-icons/ai"
import moment from 'moment'
import { Icon } from '@iconify/react'
import { useDimensions } from '../../common/mamager/ScreenManager'
import { imgDefaltCardCourse, storageUrl } from '../../constants'
import { getCourseInsertInterestedFetch, getCourseUpdateInterestedFetch } from '../course/API'
import { calculateRemainingPeriodStudyCourse } from '../../common/mamager/TimeManager'
import { detectSignIn } from '../../common/mamager/DetectManager'

const dateTimeFormat = 'DD/MM/YYYY HH:mm'

const starCustomize = {
    color: "#23B14D",
    fontSize: "28px",
    marginTop: 8,
    cursor: 'pointer',
}

export default function ClassroomItem(props) {

    const navigate = useNavigate()
    const data = [""]
    const [checked, setChecked] = useState(props.item.course.course_like ? (props.item.course.course_like.isActive ? true : false) : false)
    const { width } = useDimensions()
    const timeLeft = calculateRemainingPeriodStudyCourse(new Date(), props.item.endLearnDate)

    const onCheckboxClicked = async () => {
        const isLogin = await detectSignIn()
        if (isLogin) {
            setChecked(!checked)
            if (checked) {
                // update
                await getCourseUpdateInterestedFetch(props.item.course.courseId, props.user.uid)
            } else {
                // insert
                await getCourseInsertInterestedFetch(props.item.course.courseId, props.user.uid)
            }
        } else {
            navigate('/login')
        }
    }

    return (
        <Col xs={24} lg={12} style={width > 991 ? (props.index % 2 !== 0 ? { paddingBottom: 14, paddingRight: 7.5 } : { paddingBottom: 14, paddingLeft: 7.5 }) : { paddingBottom: 14 }} >
            <List
                header={
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <div>
                            {checked ?
                                <AiFillStar style={starCustomize} size={28} onClick={() => onCheckboxClicked()} />
                                :
                                <AiOutlineStar style={starCustomize} size={28} onClick={() => onCheckboxClicked()} />
                            }
                        </div>
                        <label style={{ paddingLeft: 10 }}>คอร์ส : {props.item.course.courseTitleName}</label>
                    </div>
                }
                footer={
                    <div style={{ textAlign: "right", paddingTop: 5, paddingBottom: 5, paddingRight: 5 }}>
                        <Space size={20}>
                            <Button
                                type="primary"
                                onClick={() => {
                                    navigate("/classroom-course-study?course-id=" + props.item.course.courseId)
                                }}
                                style={{ width: 130 }}
                            >
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <Icon icon="healthicons:i-training-class" style={{ color: "white", width: 20, height: 20 }} />
                                    <label style={{ paddingLeft: 10, cursor: "pointer" }}>เข้าห้องเรียน</label>
                                </div>
                            </Button>

                            <Button
                                type="primary"
                                onClick={() => {
                                    let courseId = props.item.course.courseId ? props.item.course.courseId : "";
                                    let courseOwner = props.item.course.uid ? props.item.course.uid : "";
                                    let toMember = props.user ? props.user.uid : "";

                                    navigate("/chat-learner" +
                                        "?course-id=" + courseId +
                                        "&course-owner=" + courseOwner +
                                        "&to-member=" + toMember
                                    )
                                }}
                                style={{ width: 130 }}
                            >
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <Icon icon="bi:chat-left-dots" style={{ color: "white", width: 17, height: 17 }} />
                                    <label style={{ paddingLeft: 10, cursor: "pointer" }}>เเชทหาผู้สอน</label>
                                </div>
                            </Button>
                        </Space>
                    </div>
                }
                dataSource={data}
                style={{ backgroundColor: 'white', paddingLeft: 15, paddingRight: 15, borderRadius: 15 }}
                renderItem={(item) => (
                    <List.Item>
                        <List.Item.Meta
                            style={{ height: 170 }}
                            description={
                                <Row>
                                    <Col span={16}>
                                        <Row style={{ paddingLeft: 10 }} gutter={[0, 8]}>
                                            {props.item.course_format.courseFormatId !== 1 && timeLeft?.isExpire ?
                                                <Col span={24}>
                                                    <label style={{ color: 'red', fontWeight: "bold", paddingRight: 8 }}>คอร์สหมดอายุเเล้ว</label>
                                                    <a
                                                        style={{ textDecoration: "underline", color: "#23B14D" }}
                                                        onClick={() => {
                                                            navigate("/payment?course-id=" + props.item.course.courseId)
                                                        }}
                                                    >คลิกที่นี่! เพื่อต่อสัญญา</a>
                                                </Col>
                                                : []}

                                            <Col span={24}>
                                                <label style={{ color: 'black' }}>ประเภทคอร์ส {props.item.course.course_category.courseCategoryNameTH}</label>
                                            </Col>
                                            <Col span={24}>
                                                <label style={{ color: 'black' }}>จำนวนวิดีโอ {props.item.courseVideoPaidAmount}/{props.item.courseVideoFullAmount}</label>
                                            </Col>
                                            <Col span={24}>
                                                <label style={{ color: 'black' }}>วันที่ซื้อคอร์ส {moment(props.item.startLearnDate).format(dateTimeFormat)}</label>
                                            </Col>
                                            <Col span={24}>
                                                <label style={{ color: 'black' }}>วันที่หมดอายุ {props.item.endLearnDate ? moment(props.item.endLearnDate).format(dateTimeFormat) : "-"}</label>
                                            </Col>
                                            <Col span={24}>
                                                <label style={{ color: 'black' }}>รูปเเบบคอร์ส {props.item.course_format.courseFormatNameTH} </label>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col span={8}>
                                        <div style={{ paddingRight: 5, paddingTop: 5, float: 'right' }}>
                                            {props.item.course.courseImageLocation ?
                                                <Image
                                                    style={{ width: '160px' }}
                                                    alt="avatar"
                                                    src={`${props.item.course.courseImageLocation}`}
                                                    preview={false} //{{src: `${props.item.course.courseImageLocation}`}}
                                                />
                                                :
                                                <Image
                                                    style={{ maxHeight: 150 }}
                                                    src="error"
                                                    fallback={`${imgDefaltCardCourse}`}
                                                    preview={false}
                                                />
                                            }
                                        </div>
                                    </Col>
                                </Row>
                            }
                        />
                    </List.Item>
                )}
            />
        </Col>
    )
}