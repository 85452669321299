/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from "react"
import { Row, Col, Skeleton } from 'antd'
import { useLocation } from "react-router-dom"
import { Layout } from 'antd'
import Footer from "../../common/components/footer"
import Header from "../../common/components/header"
import { useDimensions } from "../../common/mamager/ScreenManager"
import CourseSearch from "../../modules/course/components/search"
import Course from "../../modules/course"
import { getCourseCategoryFetch, getCourseRatingFetch, getCourseSearchByIdFetch } from "../course/API"
import { detectConvertLocation } from "../../common/mamager/DetectManager"

const { Content } = Layout

export default function CourseList(props) {

    const language = "th"
    const { width } = useDimensions()

    const [loading, setLoading] = useState(true)

    const [courseCategory, setCourseCategory] = useState()
    const [courseRating, setCourseRating] = useState()
    const [list, setList] = useState([])

    const location = useLocation()
    const params = new URLSearchParams(location.search)
    const category = params.get("course-category")
    const text = params.get("course-text")
    const rating = params.get("course-rating")
    const search = params.get("is-search")

    const getCourseCategory = async () => {
        const resultCourseCategory = await getCourseCategoryFetch(language)
        setCourseCategory(resultCourseCategory)
    }

    const getCourseRating = async () => {
        const CourseRating = await getCourseRatingFetch(props.authorized, language)
        setCourseRating(CourseRating)
    }

    const handleCourseSearch = async (values) => {
        setLoading(true)

        await getSearch(
            values.category,
            values.text,
            values.rating,
            values.search,
            props.user ? props.user.uid : null,
            false,
            200
        )

        setLoading(false)
    }

    const getSearch = async (category, text, rating, search, uid, loadMoreActive, paginate) => {
        let obj = { category, text, rating, search, uid, loadMoreActive, paginate, language }
        // console.log("obj : ", obj)
        const result = await getCourseSearchByIdFetch(obj)
        setList(result)
        // console.log("result : ", result)
        // console.log("-----------------")
    }

    const getRequestBaseApi = async () => {
        setLoading(true)

        await getSearch(
            category ? detectConvertLocation(category) : null,    // category
            text ? text : null,                             // text
            rating ? detectConvertLocation(rating) : null,        // rating
            search ? detectConvertLocation(search) : null,        // search
            props.user ? props.user.uid : null,             // uid
            false,                                          // loadMoreActive
            200                                             // paginate
        )

        await getCourseCategory()
        await getCourseRating()

        setLoading(false)
    }

    useEffect(() => {
        getRequestBaseApi()
    }, [location])

    useEffect(() => {

    }, [list])

    const courseLoading = () => {
        return (
            <Row style={{ paddingTop: 37.5 }}>
                <Col xs={24} sm={12} md={12} lg={6} xl={6} xxl={4} style={{ paddingRight: 5, paddingLeft: 5, paddingBottom: 10 }}>
                    <div style={{ backgroundColor: "#EEEEEE", width: "100%", height: 300, borderRadius: "10px 10px 0px 0px" }}></div>
                    <Skeleton active={true} paragraph={{ rows: 3 }} />
                    <Row>
                        <Col span={12}>
                            <Skeleton.Button active={true} size={'default'} shape={'square'} block={false} />
                        </Col>
                        <Col span={12}>
                            <Skeleton.Button active={true} size={'small'} shape={'square'} block={true} />
                        </Col>
                    </Row>
                </Col>
                <Col xs={24} sm={12} md={12} lg={6} xl={6} xxl={4} style={{ paddingRight: 5, paddingLeft: 5, paddingBottom: 10 }}>
                    <div style={{ backgroundColor: "#EEEEEE", width: "100%", height: 300, borderRadius: "10px 10px 0px 0px" }}></div>
                    <Skeleton active={true} paragraph={{ rows: 3 }} />
                    <Row>
                        <Col span={12}>
                            <Skeleton.Button active={true} size={'default'} shape={'square'} block={false} />
                        </Col>
                        <Col span={12}>
                            <Skeleton.Button active={true} size={'small'} shape={'square'} block={true} />
                        </Col>
                    </Row>
                </Col>
            </Row>
        )
    }

    return (
        <>
            <Header
                firebase={props.firebase}
                authorized={props.authorized}
                user={props.user}
            />
            
            <article>
                <Content>
                    <div style={{ paddingBottom: 12 }}>
                        <CourseSearch
                            uid={props.firebase.auth().currentUser ? props.firebase.auth().currentUser.uid : null}
                            courseRating={courseRating}
                            courseCategory={courseCategory}
                            search={{
                                category: category ? detectConvertLocation(category) : null,
                                text: text ? text : null,
                                rating: rating ? detectConvertLocation(rating) : null,
                            }}
                            isRedirectUrl={false}
                            handleCourseSearch={handleCourseSearch}
                        />
                    </div>
                    <>
                        <div style={width >= 1500 ? { paddingLeft: '5%', paddingRight: '5%' } : ((width > 912 && width < 1500) ? { paddingLeft: '5%', paddingRight: '5%' } : { paddingLeft: '0%', paddingRight: '0%' })}>
                            {!loading ?
                                <Course
                                    courses={list}
                                    uid={props.firebase.auth().currentUser ? props.firebase.auth().currentUser.uid : null}
                                />
                                : courseLoading()
                            }
                        </div>
                    </>
                </Content>
            </article>

            <Footer />
        </>
    )
}