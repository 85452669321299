/* eslint-disable react-hooks/exhaustive-deps */

import { useState, useRef, useEffect } from 'react'
import { useLocation } from "react-router-dom"
import {
    AutoComplete,
    Avatar,
    Button,
    Col,
    Form,
    Layout,
    Row,
    Select,
    Spin
} from 'antd'
import { Icon } from '@iconify/react'
import { imgDefaultUrl } from '../../constants'
import Footer from '../../common/components/footer'
import Header from '../../common/components/header'
import { getCourseCategoryFetch, getCourseOwnerFetch, getCourseRatingFetch, getSearchSuggestFetch } from '../course/API'
import { getCourseChannelSortBySearchFetch } from './API'
import Course from '../course'
import { useDimensions } from '../../common/mamager/ScreenManager'

const { Option } = Select
const { Content } = Layout

export default function School(props) {
    const [form] = Form.useForm()
    const location = useLocation()
    const params = new URLSearchParams(location.search)
    const teacherId = params.get("teacher")

    const paginate = 10
    const language = "th"

    const { width } = useDimensions()

    const searchRef = useRef(null)

    const [options, setOptions] = useState([])

    const [courseCategory, setCourseCategory] = useState([])
    const [courseRating, setCourseRating] = useState([])

    const [course, setCourse] = useState(null)
    const [loading, setLoading] = useState(false)

    const [teacherInfo, setTeacherInfo] = useState(null)

    const getSearch = async (value) => {
        const result = await getSearchSuggestFetch(value, paginate)

        let data = []
        result?.map((val) => data.push(val.courseTitleName))
        let searchResult = data.map((data) => {
            return {
                value: data,
                label: (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between'
                        }}
                    >
                        <span>{data}</span>
                    </div>
                )
            }
        })

        setOptions(value ? searchResult : [])
    }

    const getCourse = async (category, rating, name) => {
        setLoading(true)

        let obj = {
            category,       // 18
            rating,         // 1
            name,           // null
            learnerId: props.user ? props.user.uid : null,
            teacherId: teacherId ? teacherId : null,
        }
        const result = await getCourseChannelSortBySearchFetch(obj)
        setCourse(result)

        await getTeacherInfo()

        setLoading(false)
    }

    const onFinish = (values) => {
        let category = values.category ? Number(values.category) : null
        let rating = values.rating ? Number(values.rating) : 1
        let name = searchRef.current ? searchRef.current : null

        getCourse(category, rating, name)
    }

    const handleSearch = async (value) => {
        await getSearch(value)
    }

    const getCourseCategory = async () => {
        if (courseCategory.length === 0) {
            const result = await getCourseCategoryFetch(language)
            setCourseCategory(result)
        }
    }

    const getCourseRating = async () => {
        if (courseRating.length === 0) {
            const result = await getCourseRatingFetch(props.authorized, language)
            setCourseRating(result)
        }
    }

    const getTeacherInfo = async () => {
        const result = await getCourseOwnerFetch(teacherId ? teacherId : null)
        setTeacherInfo(result)
    }

    const getBaseApi = async () => {
        getCourse(null, null, null)
    }

    const randomCourseGroup = (max) => {
        let loop = true
        while (loop) {
            let random = Math.floor(Math.random() * max)
            if (random !== 0) {
                loop = false
                return random
            }
        }
    }

    useEffect(() => {

        getBaseApi()
    }, [])

    return (
        <>
            <Header
                firebase={props.firebase}
                authorized={props.authorized}
                user={props.user}
            />

            <article>
                <Content>
                    <div
                        style={
                            width > 1500 ?
                                { backgroundColor: "white", marginLeft: '5%', marginRight: '5%' }
                                :
                                (
                                    (width > 912 && width <= 1500) ?
                                        { backgroundColor: "white", marginLeft: '5%', marginRight: '5%' }
                                        :
                                        { backgroundColor: "white", marginLeft: '0%', marginRight: '0%' }
                                )
                        }
                    >
                        {!loading ?
                            <>
                                <div
                                    style={{
                                        backgroundImage: `url("./assets/images/course-channel/cover/img-course-channel-cover-${randomCourseGroup(12)}.jpg")`,
                                        backgroundSize: '100%',
                                        width: '100% auto',
                                        height: '100% auto',
                                    }}
                                >
                                    <Row>
                                        <Col span={24} style={{ padding: 10 }}>
                                            <Avatar
                                                src={
                                                    teacherInfo ?
                                                        (teacherInfo.imageProfile === "-" ?
                                                            // "https://joeschmoe.io/api/v1/random" 
                                                            imgDefaultUrl
                                                            :
                                                            teacherInfo.imageProfile
                                                        )
                                                        :
                                                        imgDefaultUrl
                                                }
                                                style={{ height: 100, width: 100, border: '1px solid green', marginTop: 10, marginLeft: 10 }} alt="Han Solo"
                                            />
                                            <label style={{ marginTop: -40, paddingLeft: 15 }}>
                                                <Row>
                                                    <Col span={24}>
                                                        <label style={{ fontSize: 24, fontWeight: "bold", color: "white" }}>ผู้สอน {teacherInfo ? teacherInfo.userName : "-"}</label>
                                                    </Col>

                                                    <Col span={24}>
                                                        <label style={{ fontSize: 18, fontWeight: "bold", color: "white" }}>มีจำนวน {course?.courseItem ? course.courseItem.length : "0"} คอร์ส</label>
                                                    </Col>
                                                </Row>
                                            </label>
                                        </Col>
                                    </Row>
                                </div>

                                <Form
                                    onFinish={onFinish}
                                    form={form}
                                >
                                    <Row style={{ paddingTop: 15, backgroundColor: '#E5E5E5', paddingBottom: 0 }}>
                                        <Col xs={24} sm={12} md={12} lg={6} xl={6} xxl={6} style={{ paddingLeft: 15 }}>
                                            <Form.Item
                                                name="rating"
                                            >
                                                <Select
                                                    showSearch
                                                    style={{ width: '100%', marginBottom: -10 }}
                                                    placeholder="เลือกลำดับ"
                                                    optionFilterProp="children"
                                                    allowClear
                                                    size="large"
                                                    onClick={() => getCourseRating()}
                                                >
                                                    {courseRating.map((val, index) =>
                                                        <Option key={index} value={String(val.value)}>{val.label}</Option>
                                                    )}
                                                </Select>
                                            </Form.Item>
                                        </Col>

                                        <Col xs={24} sm={12} md={12} lg={6} xl={6} xxl={6} style={{ paddingLeft: 8, paddingRight: 8 }}>
                                            <Form.Item
                                                name="category"
                                            >
                                                <Select
                                                    showSearch
                                                    style={{ width: '100%', marginBottom: -10 }}
                                                    placeholder="เลือกหมวดหมู่"
                                                    optionFilterProp="children"
                                                    allowClear
                                                    size="large"
                                                    onClick={() => getCourseCategory()}
                                                >
                                                    {courseCategory.map((val, index) =>
                                                        <Option key={index} value={String(val.value)}>{val.label}</Option>
                                                    )}
                                                </Select>
                                            </Form.Item>
                                        </Col>

                                        <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12} style={{ paddingLeft: 8, paddingRight: 15, paddingBottom: 15 }}>
                                            <AutoComplete
                                                onChange={(e) => searchRef.current = e}
                                                dropdownMatchSelectWidth={252}
                                                placeholder="ค้นหาคอร์ส"
                                                style={{ width: '90%' }}
                                                options={options}
                                                size="large"
                                                onSearch={handleSearch}
                                            ></AutoComplete>

                                            <Button
                                                type="primary"
                                                style={{ width: '10%', backgroundColor: "#23B14D", borderRadius: "00px 10px 10px 0px" }}
                                                size="large"
                                                onClick={() => {
                                                    form.submit();
                                                }}
                                            >
                                                <Icon icon="bi:search" style={{ color: "white" }} />
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form>

                                <div style={{ paddingTop: 15 }}>
                                    {!loading && teacherInfo && course ?
                                        <Course
                                            courses={course}
                                            uid={props.firebase.auth().currentUser ? props.firebase.auth().currentUser.uid : null}
                                        />
                                        : []}
                                </div>
                            </>
                            :
                            <div style={{ paddingTop: 191, paddingBottom: 191, backgroundColor: 'white' }}>
                                <center>
                                    <Row gutter={[0, 12]}>
                                        <Col span={24}>
                                            <Spin size="large" loading={loading} />
                                        </Col>
                                        <Col span={24}>
                                            <label style={{ textAlign: 'center', marginTop: 30 }}>Loading ...</label>
                                        </Col>
                                    </Row>
                                </center>
                            </div>
                        }
                    </div>
                </Content>
            </article>

            <Footer />
        </>
    )
}
