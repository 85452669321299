import { Card, Col, Modal, Row } from 'antd'
import Map from '../map'

export default function ContractUs(props) {

    return (
        <Modal
            title={<label style={{ fontWeight: 100 }}>ติดต่อเรา</label>}
            visible={props.isModalContractUsVisible}
            onCancel={() => props.handleContractUsCancel()}
            footer={null}
            maskClosable={false}
            cancelButtonProps={{ hidden: true }}
            okButtonProps={{ hidden: true }}
            style={{ top: 50 }}
            width={1000}
        >
            <Row gutter={[12, 12]}>
                <Col span={24}>
                    <Card title={<label style={{ fontWeight: 100 }}>สอบถามข้อมูลทั่วไป</label>}>
                        <Row gutter={[0, 8]}>
                            <Col span={3}><label>โทร</label></Col>
                            <Col span={20}><a href="tel:+021143464" style={{ color: "#23B14D" }}>02-114-3464</a></Col>

                            <Col span={3}><label>อีเมล์</label></Col>
                            <Col span={20}><a href = "mailto: info@toflyn.com" style={{ color: "#23B14D" }}>info@toflyn.com</a></Col>
                            
                            <Col span={3}><label>วันเวลาทำการ</label></Col>
                            <Col span={20}><label>วันจันทร์ - อาทิตย์ เวลา 08:30 - 18:00 น.</label></Col>

                            <Col span={24}>
                                <label style={{ paddingBottom: 0, marginBottom: 0 }}>บริษัท กู๊ดเนส โกบอล กรุ๊ป จำกัด</label>
                                <br />
                                <label>ที่อยู่ เลขที่ 110 ซอยวิภาวดีรังสิต 4 ถนนวิภาวดีรังสิต เเขวงรัชดาภิเษก เขตดินเเดง กรุงเทพมหานคร 10400</label>
                            </Col>
                        </Row>
                    </Card>
                </Col>

                <Col span={24}>
                    <Card title={<label style={{ fontWeight: 100 }}>ที่ตั้ง</label>}>
                        <center>
                            <Map />
                        </center>
                    </Card>
                </Col>
            </Row>
        </Modal>
    )
}
