/* eslint-disable react-hooks/exhaustive-deps */

import { useState, useEffect } from 'react'
import {
    Row,
    Col,
    Button,
    Form,
    Select,
    Image,
    Modal,
    Avatar,
    Input,
    InputNumber,
    Upload
} from 'antd'
import { Icon } from '@iconify/react'
import { LoadingOutlined, UploadOutlined } from '@ant-design/icons'
import { apiStorageUrl, bucketName, imgDefaultUrl, storageUrl } from '../../../../constants'
import { getAccountVerifyBankByIdFetch, getฺBankCategoryAllFetch, updateAccountVerifyByIdFetch, uploadAccountVerifyBankByIdFetch } from '../../API'
import { Notification } from '../../../../common/components/notification'
import { useDimensions } from '../../../../common/mamager/ScreenManager'

const { Option } = Select

export default function BankAccount(props) {
    const [form] = Form.useForm()
    const { width } = useDimensions()

    let language = "th"

    const [fileBankAccount, setFileBankAccount] = useState({
        data: null,
        loading: false
    })

    const [accountVerifiedBank, setAccountVerifiedBank] = useState([])

    const [loading, setLoading] = useState(false)

    const [modal, setModal] = useState({
        isShow: false,
        title: null,
    })

    const [switchImage, setSwitchImage] = useState(true)

    const [bankCategory, setBankCategory] = useState([])

    const optionFileBank = {
        name: 'file',
        action: `${apiStorageUrl}/upload/file`,
        data: {
            // uploaded/img/verify/bank-account
            bucket: bucketName,
            folder: "image/verify/bank-account"
        },
        headers: {
            authorization: 'authorization-text',
        },
        onChange(info) {
            if (info.file.status === 'done') {
                let result = info.file.response;
                console.log("info : ", info.file.response)
                if (result?.isSuccess) {
                    setFileBankAccount({
                        data: result.url,
                        loading: false
                    });
                }
            } else {
                setFileBankAccount({ loading: true })
            }

            if (info.file.status === 'done') {
                Notification("success", "ระบบ", "อัพโหลดบัญชีธนาคารสำเร็จ")
            } else if (info.file.status === 'error') {
                Notification("error", "ระบบ", "ไม่สามารถอัพโหลดบัญชีธนาคารได้ กรุณาตรวจสอบข้อมูล")
            }
        },
        progress: {
            strokeColor: {
                '0%': '#108ee9',
                '100%': '#87d068',
            },
            strokeWidth: 3,
            format: percent => `${parseFloat(percent.toFixed(2))}%`,
        },
    }

    const setDefaultImageUrl = () => {
        setSwitchImage(!switchImage)

        // open modal
        setModal({
            isShow: true,
            title: "bank",
        })
    }

    const detectImgBank = () => {
        if (fileBankAccount) {
            return Promise.resolve()
        } else {
            return Promise.reject(new Error('กรุณาอัพโหลดภาพบัญชีธนาคาร'))
        }
    }

    const onFinish = async (value) => {
        // console.log("value : ", value)
        if (accountVerifiedBank.approvalStatusId === 1 || accountVerifiedBank.approvalStatusId === 2) { // bank is pass
            let requestUpload = {
                uid: props.user.uid,
                approvalStatusId: 12,
                bankFileApprovalId: 12,
                bankInfoApprovalId: 12,

                bankCategoryId: value ? value.bankName : null,
                bankAccountId: value ? value.bankAccountId : null,
                bankAccountName: value ? value.bankAccountName : null,
                fileBankAccount: fileBankAccount.data,

                businessOperationId: accountVerifiedBank ? accountVerifiedBank.businessOperationId : null,
                isActive: 1
            }
            // console.log("requestUpload : ", requestUpload);
            const resultUploadVerifyBank = await uploadAccountVerifyBankByIdFetch(requestUpload)


            let objAccountVerify = {
                uid: props.user.uid,
                isAccountVerifiedBank: 0
            }
            const resultAccountVerify = await updateAccountVerifyByIdFetch(objAccountVerify)

            if (resultUploadVerifyBank.isSuccess && resultAccountVerify.isSuccess) {
                Notification("success", "ระบบ", "ส่งคำขอเเก้ไขเลขบัญชีสำเร็จ")

                // close modal
                setModal({
                    isShow: false,
                    title: "bank"
                })

                // reload
                getBaseApi()
            } else {
                Notification("error", "ระบบ", "ส่งคำขอเเก้ไขเลขบัญชีไม่สำเร็จ")
            }
        }
    }

    const getAccountVerifyBankById = async () => {
        const resultAccountVerifyBank = await getAccountVerifyBankByIdFetch(props.user.uid)
        setAccountVerifiedBank(resultAccountVerifyBank)
        setFileBankAccount({
            data: resultAccountVerifyBank ? resultAccountVerifyBank.fileBankAccountLocation : null,
            loading: false
        })
    }

    const getBankCategoryAll = async () => {
        let obj = {
            language,
        }
        const resultBankCategoryAll = await getฺBankCategoryAllFetch(obj)
        setBankCategory(resultBankCategoryAll)
    }

    const getBaseApi = async () => {
        setLoading(true)

        await getAccountVerifyBankById()
        await getBankCategoryAll()

        setLoading(false)
    }

    const bankAccountIdFormat = (id) => {
        let accountId = "ไม่พบเลขบัญชี"
        if (id) {
            accountId = ""
            for (let i = 0; i < id.length; i++) {
                if (i === 3 || i === 4 || i === 9) {
                    accountId += "-" + id[i]
                } else {
                    accountId += id[i]
                }
            }
        }
        return accountId
    }

    useEffect(() => {
        getBaseApi()
    }, [])

    useEffect(() => {

    }, [accountVerifiedBank])

    return (
        <>
            {!loading ?
                <>
                    <Row>
                        <Col span={24}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                {accountVerifiedBank.approvalStatusId === 1 ?
                                    <Avatar style={{ backgroundColor: "#23B14D", verticalAlign: 'middle', height: 20, width: 20 }} size="large" gap={0}></Avatar>
                                    : []
                                }
                                {accountVerifiedBank.approvalStatusId === 2 ?
                                    <Avatar style={{ backgroundColor: "red", verticalAlign: 'middle', height: 20, width: 20 }} size="large" gap={0}></Avatar>
                                    : []
                                }
                                {accountVerifiedBank.approvalStatusId === 12 ?
                                    <Avatar style={{ backgroundColor: "#F0D602", verticalAlign: 'middle', height: 20, width: 20 }} size="large" gap={0}></Avatar>
                                    : []
                                }
                                <label style={{ paddingLeft: 10 }}>{accountVerifiedBank ? accountVerifiedBank.approvalStatusName : "-"}</label>
                            </div>
                        </Col>
                        <Col span={24} style={{ paddingTop: 15 }}>
                            <div style={{ border: "1px solid #DEDEDE", padding: 20, borderRadius: 15 }}>
                                <Row gutter={[0, 8]}>
                                    <Col xs={24} md={3} xl={3} style={{ textAlign: (width > 808 ? 'left' : 'center') }}>
                                        <Image
                                            width={50}
                                            height={50}
                                            src="error"
                                            style={{ borderRadius: 100, border: "1px solid #DEDEDE" }}
                                            fallback={`./assets/images/${accountVerifiedBank.bankLogoURL}`}
                                            preview={false}
                                        />
                                    </Col>

                                    <Col xs={24} md={16} xl={16}>
                                        <Row>
                                            <Col xs={8} xl={4}>
                                                <label style={{ float: "right" }}>ชื่อบัญชี : </label>
                                            </Col>
                                            <Col xs={16} xl={20}>
                                                <label style={{ paddingLeft: 10 }}>{accountVerifiedBank ? accountVerifiedBank.bankAccountName : "-"}</label>
                                            </Col>

                                            <Col xs={8} xl={4}>
                                                <label style={{ float: "right" }}>เลขที่บัญชี : </label>
                                            </Col>
                                            <Col xs={16} xl={20}>
                                                <label style={{ paddingLeft: 10 }}>{accountVerifiedBank ? bankAccountIdFormat(accountVerifiedBank.bankAccountId) : "-"}</label>
                                            </Col>
                                        </Row>
                                    </Col>

                                    <Col xs={24} md={5} xl={5} style={{ textAlign: (width > 808 ? 'end' : 'center') }}>
                                        <Button
                                            type="primary"
                                            onClick={() => {
                                                form.setFieldsValue({
                                                    bankName: accountVerifiedBank ? accountVerifiedBank.bankCategoryId : null,
                                                    bankAccountId: accountVerifiedBank ? accountVerifiedBank.bankAccountId : null,
                                                    bankAccountName: accountVerifiedBank ? accountVerifiedBank.bankAccountName : null
                                                })

                                                setDefaultImageUrl()
                                            }}
                                        >
                                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                <Icon icon="dashicons:admin-customizer" style={{ color: "white" }} />
                                                <label style={{ paddingLeft: 10, cursor: "pointer" }}>แก้ไขข้อมูล</label>
                                            </div>
                                        </Button>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>

                    <Modal
                        maskClosable={false}
                        title={<label style={{ fontSize: 14, fontWeight: 100 }} className="topic-color-bold">{modal.title === "bank" ? "เเก้ไขบัญชีธนาคาร" : []}</label>}
                        visible={modal.isShow}
                        width={350}
                        onCancel={() => {
                            // close modal
                            setModal({
                                isShow: false,
                                title: modal.title,
                            })
                        }}
                        footer={
                            [
                                <Button
                                    disabled={accountVerifiedBank.approvalStatusId === 12 ? true : false}
                                    type="primary"
                                    onClick={() => {
                                        form.submit()
                                    }}
                                >
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <Icon icon="bi:send" style={{ color: "white" }} />
                                        <label style={{ paddingLeft: 10, cursor: "pointer" }}>ส่งตรวจสอบ</label>
                                    </div>
                                </Button>
                            ]
                        }
                    >
                        <Form
                            layout="vertical"
                            form={form}
                            onFinish={onFinish}
                        >
                            <Row>
                                <Col span={24}>
                                    <Form.Item
                                        label="ธนาคาร"
                                        name="bankName"
                                        rules={[{ required: true, message: "กรุณาเลือกธนาคาร" }]}
                                    >
                                        <Select
                                            showSearch
                                            style={{ width: '100%' }}
                                            placeholder="เลือกธนาคาร"
                                            optionFilterProp="children"
                                            allowClear
                                            disabled={accountVerifiedBank.approvalStatusId === 12 ? true : false}
                                        >
                                            {bankCategory.map((val, index) =>
                                                <Option key={index} value={val.value}>{val.label}</Option>
                                            )}
                                        </Select>
                                    </Form.Item>
                                </Col>

                                <Col span={24}>
                                    <Form.Item
                                        label="บัญชีเลขที่"
                                        name="bankAccountId"
                                        rules={[{ required: true, message: "กรุณากรอกบัญชีเลขที่" }]}
                                    >
                                        <InputNumber
                                            maxLength={10}
                                            disabled={accountVerifiedBank.approvalStatusId === 12 ? true : false}
                                            style={{ width: "100%" }}
                                            placeholder="บัญชีเลขที่"
                                        />
                                    </Form.Item>
                                </Col>

                                <Col span={24}>
                                    <Form.Item
                                        label="ชื่อบัญชี"
                                        name="bankAccountName"
                                        rules={[{ required: true, message: "กรุณากรอกชื่อบัญชี" }]}
                                    >
                                        <Input
                                            disabled={accountVerifiedBank.approvalStatusId === 12 ? true : false}
                                            placeholder="ชื่อบัญชี"
                                        />
                                    </Form.Item>
                                </Col>

                                <Col span={24}>
                                    <Form.Item
                                        label="ภาพถายสมุดบัญชี"
                                        name="fileBankAccount"
                                        rules={[{ required: true, validator: detectImgBank }]}
                                    >
                                        {fileBankAccount.data ?
                                            <Image
                                                style={{ width: '100%', height: 200, border: "1px solid #eeeeee" }}
                                                alt="avatar"
                                                src={`${fileBankAccount.data}`}
                                                preview={{
                                                    src: `${fileBankAccount.data}`,
                                                }}
                                            />
                                            :
                                            <Image
                                                style={{ width: '100%', height: 200, border: "1px solid #eeeeee" }}
                                                src="error"
                                                fallback={`${imgDefaultUrl}`}
                                            />
                                        }
                                        <Upload
                                            {...optionFileBank}
                                            listType="picture"
                                            accept='image/jpeg, image/png, image/jfif'
                                            maxCount={1}
                                            showUploadList={false}
                                        >
                                            <Button
                                                type="primary"
                                                disabled={accountVerifiedBank.approvalStatusId === 12 ? true : false}
                                                style={{ width: "100%" }}
                                                icon={fileBankAccount.loading ? <LoadingOutlined /> : <UploadOutlined />}
                                            >อัพโหลด</Button>
                                        </Upload>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </Modal >
                </>
                : []}
        </>
    )
}
