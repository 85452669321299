/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/img-redundant-alt */

import { useState, useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { BsFillEyeFill } from "react-icons/bs"
import { isEmptyObject } from 'jquery'
import { Icon } from '@iconify/react'
import { Card, Row, Col, Button, Image } from 'antd'
import "../../modules/course/css/courseItem.css"
import { imgDefaultUrl, storageUrl } from '../../constants'
import { iconCRL } from '../../common/components/crl/IconCRL'
import { getCourseInsertInterestedFetch, getCourseUpdateInterestedFetch } from './API'
import { calculateDiscountTimeLeft } from '../../common/mamager/TimeManager'
import { Notification } from '../../common/components/notification'

const { Meta } = Card

export default function CourseItem(props) {

    const [courseData, setCourse] = useState([])

    const navigate = useNavigate()

    const [isInterested, setIsInterested] = useState(props.courses.courseInterested ? true : false)

    const reviewScore = Number(props.courses?.reviewScoreAverage ? props.courses.reviewScoreAverage : 0).toFixed(1)

    const [timeLeft, setTimeLeft] = useState(null)

    const redirectNextPage = () => {
        if (props.isOpenCourseClassroom) {
            navigate("/classroom-course-study?course-id=" + courseData.courseId)
        } else {
            navigate("/course-shop?course-id=" + courseData.courseId)
        }
    }

    const onCheckboxClicked = async () => {
        if (props.uid != null) {
            if (isInterested) {
                const result = await getCourseUpdateInterestedFetch(courseData.courseId, props.uid)
                if (result.isSuccess) {
                    Notification("success", "ระบบ", "ยกเลิกคอร์สที่สนใจสำเร็จ")
                    setIsInterested(false)
                } else {
                    Notification("error", "ระบบ", "ยกเลิกคอร์สที่สนใจไม่สำเร็จ กรุณาลองใหม่อีกครั้ง")
                }
            } else {
                const result = await getCourseInsertInterestedFetch(courseData.courseId, props.uid)
                if (result.isSuccess) {
                    Notification("success", "ระบบ", 'เพิ่มคอร์สที่สนใจสำเร็จ')
                    setIsInterested(true)
                } else {
                    Notification("error", "ระบบ", 'เพิ่มคอร์สที่สนใจไม่สำเร็จ กรุณาลองใหม่อีกครั้ง')
                }
            }
        } else {
            navigate('/login')
        }
    }

    let courseDiscountHTML
    if (courseData.courseIsDiscount && !isEmptyObject(timeLeft)) {

        courseDiscountHTML = (
            <Row style={{ marginTop: -3 }}>
                <Col span={24}>
                    <label style={{ float: 'right' }}>ราคา {Intl.NumberFormat("en", { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(Number(courseData.coursePrice) - Number(courseData.coursePriceDiscount))} ฿</label>
                </Col>
                <Col span={24}>
                    <label style={{ float: 'right', marginTop: -5, color: 'red', textDecoration: "line-through" }}>จากปกติ {Intl.NumberFormat("en", { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(Number(courseData.coursePrice))} ฿</label>
                </Col>
            </Row>
        )
    } else {
        courseDiscountHTML = (
            <div style={{ float: 'right', marginTop: 15 }}>
                {Number(courseData.coursePrice) === 0 ?
                    <label style={{ fontSize: 18 }}>ฟรี</label>
                    :
                    <label >ราคา {Intl.NumberFormat("en", { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(Number(courseData.coursePrice))} ฿</label>
                }
            </div>
        )
    }

    useMemo(() => {
        setTimeLeft(calculateDiscountTimeLeft(props.courses?.discountDateEnd))
    }, [])

    useEffect(() => {
        setCourse(props.courses)

        setTimeout(() => {
            setTimeLeft(calculateDiscountTimeLeft(props.courses?.discountDateEnd))
        }, 1000)

        // console.log(props.courses)
    }, [props.courses])

    return (
        <Col
            xs={24}
            sm={12}
            md={12}
            lg={6}
            xl={6}
            xxl={4}
            style={{
                paddingRight: 5,
                paddingLeft: 5,
                paddingBottom: 10
            }}
        >
            <Card
                hoverable
                bodyStyle={{ padding: "10px" }}
                style={{
                    width: '100%',
                    borderRadius: 10
                }}
                cover={
                    <>
                        <div class="container">
                            {courseData.courseImageCard ?
                                <Image
                                    src={`${courseData.courseImageCard}`}
                                    preview={false}
                                    // alt="Snow"
                                    // style={{ width: '100%' }}
                                    onClick={() => redirectNextPage()}
                                ></Image>
                                :
                                <Image
                                    src={`${imgDefaultUrl}`}
                                    preview={false}
                                    // alt="Snow"
                                    // style={{ width: '100%' }}
                                    onClick={() => redirectNextPage()}
                                ></Image>
                            }

                            <div
                                class="bottom-left"
                                onClick={() => redirectNextPage()}
                            >
                                {iconCRL(courseData.leatnerCount, 40, 40, false)}
                            </div>

                            <div
                                class="top-left"
                                onClick={() => redirectNextPage()}
                            >
                                <BsFillEyeFill
                                    size={20}
                                    style={{
                                        height: "22px",
                                        color: "white",
                                        float: "left",
                                        backgroundColor: "#000000",
                                        borderRadius: "5px 0px 0px 0px",
                                        paddingLeft: 5,
                                        opacity: 0.7,
                                        paddingTop: 2,
                                        paddingBottom: 2
                                    }}
                                />

                                <label
                                    style={{
                                        fontWeight: "bold",
                                        color: "white",
                                        backgroundColor: "#000000",
                                        float: "left",
                                        paddingLeft: 5,
                                        paddingRight: 5,
                                        opacity: 0.7,
                                        cursor: "pointer",
                                        paddingTop: 3,
                                        paddingBottom: 1
                                    }}
                                >{Intl.NumberFormat('en').format(Number(courseData.courseView))} ครั้ง</label>
                            </div>

                            <div class="top-right">
                                <div style={{
                                    backgroundColor: "#000000",
                                    borderRadius: "0px 5px 0px 0px",
                                    width: 28,
                                    opacity: 0.7,
                                    float: "right",
                                    alignItems: 'center',
                                    height: 26

                                }}>
                                    <center>
                                        {isInterested ?
                                            <Icon icon="ant-design:heart-filled"
                                                style={{ marginTop: 3, color: '#23B14D', width: 20, height: 20 }}
                                                onClick={() => onCheckboxClicked()}
                                            />
                                            :
                                            <Icon
                                                icon="akar-icons:heart"
                                                style={{ marginTop: 3, color: '#23B14D', width: 20, height: 20 }}
                                                onClick={() => onCheckboxClicked()}
                                            />
                                        }
                                    </center>
                                </div>
                            </div>

                            {props.isOpenCourseClassroom ?
                                <div
                                    class="centered"
                                    onClick={() => redirectNextPage()}
                                >
                                    <Icon icon="ic:sharp-slow-motion-video" style={{ color: "#cbcbcb", width: "100%", height: "100%" }} />
                                </div>
                                : []
                            }

                            <div
                                class="bottom-right"
                                onClick={() => redirectNextPage()}
                            >
                                <div
                                    style={{
                                        backgroundColor: "#000000",
                                        opacity: 0.7,
                                        float: 'right'
                                    }}
                                >
                                    <label
                                        style={{
                                            paddingLeft: 5,
                                            marginBottom: 0,
                                            fontSize: 15.5,
                                            fontWeight: 'bold',
                                            color: reviewScore > 0 ? '#23B14D' : 'white'
                                        }}
                                    >{reviewScore}</label>

                                    <Icon
                                        icon="game-icons:round-star"
                                        style={{
                                            width: 19,
                                            height: 19,
                                            paddingLeft: 5,
                                            paddingRight: 5,
                                            color: 'white',
                                            marginTop: 2.5,
                                            float: 'right'
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </>
                }
            >
                <Row
                    style={{ height: 120 }}
                    onClick={() => redirectNextPage()}
                >
                    <Col span={24}>
                        <Meta className="card-text course-item-text" title={courseData.courseTitleName} description={courseData.courseSubDescriptionName} />
                    </Col>

                    <Col span={9} style={{ paddingTop: 9 }}>
                        <Button
                            type="primary"
                            onClick={() => redirectNextPage()}
                        >
                            <span style={{ fontWeight: "bold" }}>
                                {props.isOpenCourseClassroom ? "เข้าเรียน" : "คลิกดู"}
                            </span>
                        </Button>
                    </Col>

                    <Col span={15} style={{ paddingTop: 8 }}>
                        {courseDiscountHTML}
                    </Col>
                </Row>
            </Card>
        </Col>
    )
}