import { Icon } from '@iconify/react'

export const genPaymentStatus = (paymentStatusId) => {

    let renderItem = <></>

    if (paymentStatusId === 1 || paymentStatusId === 4) {
        renderItem = (
            <>
                <Icon 
                    icon="material-symbols:check-circle-outline-rounded" 
                    style={{ color: "#23b14d", width: 22, height: 22 }}
                />
                <label style={{ color: "white", paddingLeft: 8, cursor: "pointer" }}>ชำระเงินเเล้ว</label>
            </>
        )
    } else if (paymentStatusId === 2) {
        renderItem = (
            <>
                <Icon 
                    icon="mingcute:time-line" 
                    style={{ color: "orange", width: 22, height: 22 }}
                />
                <label style={{ color: "white", paddingLeft: 8, cursor: "pointer" }}>รอชำระเงิน</label>
            </>
        )
    } else if (paymentStatusId === 3) {
        renderItem = (
            <>
                <Icon 
                    icon="material-symbols:error-circle-rounded-outline" 
                    style={{ color: "red", width: 22, height: 22 }}
                />
                <label style={{ color: "white", paddingLeft: 8, cursor: "pointer" }}>ชำระเงินไม่สำเร็จ</label>
            </>
        ) 
    }

    return (
        <>{renderItem}</>
    )
}

export const calculateOrderPaymentAmount = (data) => {
    // console.log("reload : ", data);
    let tmpOrderPaymentAmount = 0;
    let tmpOrderPaymentPrice = 0;
    data?.map(val => {
        tmpOrderPaymentAmount += 1;
        tmpOrderPaymentPrice += val.courseVideoPrice;
    });

    // console.log("setOrderPaymentAmount : ", {
    //     totalAmount: tmpOrderPaymentAmount,
    //     totalPrice: tmpOrderPaymentPrice
    // });
    
    return {
        totalAmount: tmpOrderPaymentAmount,
        totalPrice: tmpOrderPaymentPrice
    };
}