/* eslint-disable react/style-prop-object */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-distracting-elements */

import { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom"
import Marquee from 'react-fast-marquee'
import { Button } from 'antd'
import { Icon } from '@iconify/react'
import { getAnnounceFetch } from './API'

export default function Marquees() {

    const navigate = useNavigate()

    const [news, setNews] = useState([])

    const getAnnouncement = async () => {
        const result = await getAnnounceFetch()
        setNews(result)
    }

    useEffect(() => {
        getAnnouncement()
    }, [])

    return (
        <div style={{ color: '#FFFFFF', height: 30 }}>
            <Marquee
                speed='100'
                gradient={false}
                pauseOnHover={true}
                pauseOnClick={true}
            >
                {news?.map((val, index) =>
                    <div style={{ paddingRight: 12 }}>
                        <div
                            style={{
                                paddingLeft: 10,
                                paddingRight: 0,
                                color: 'white',
                                fontSize: 18,
                                cursor: "pointer",
                                borderRadius: 10,
                                backgroundColor: '#23B14D',
                                height: 30
                            }}
                            onClick={() => {
                                navigate("/announce?announcement-id=" + val.announcementId)
                            }}
                        >
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <label style={{ paddingRight: 10, height: 19 }}>
                                    <Icon icon="ph:megaphone-fill" style={{ color: "white", width: 20, height: 20 }} />
                                </label>

                                <label style={{ paddingRight: 10, fontSize: 15 }}>
                                    {val.announcementTitleName}
                                </label>

                                <Button
                                    style={{
                                        float: "right",
                                        backgroundColor: "#009610",
                                        border: "none",
                                        color: "white",
                                        height: 30
                                    }}
                                >คลิก</Button>
                            </div>
                        </div>
                    </div>
                )}
            </Marquee>
        </div>
    )
}