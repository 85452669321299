/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */

import { useState, useEffect } from "react"

import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'

import {
    Row,
    Col,
    Button,
    Form,
    Modal,
    Input,
    Popconfirm,
    DatePicker,
    Table,
    Image,
    Checkbox,
    Upload,
} from 'antd'
import { FaCog } from "react-icons/fa"
import { FiEdit } from "react-icons/fi"
import { RiDeleteBin6Line } from "react-icons/ri"
import { AiOutlineEye } from "react-icons/ai"
import { Icon } from '@iconify/react'
import { AiOutlineUpload } from 'react-icons/ai'
import ImgCrop from 'antd-img-crop'
import { LoadingOutlined, UploadOutlined } from '@ant-design/icons'
import moment from 'moment'
import { Notification } from "../../../../common/components/notification"
import { apiStorageUrl, bucketName, imgDefaultUrl, storageUrl } from "../../../../constants"
import {
    deleteBannerByIdFetch,
    getBannerAllFetch,
    insertManageBanner,
    updateManageBanner,
    updateManageBannerIsActive
} from "./API"

const { RangePicker } = DatePicker
const formatDate = "DD/MM/YYYY"

const columns = [
    {
        title: 'No',
        dataIndex: 'index',
        width: "5%",
    },
    {
        title: 'เลือก',
        dataIndex: 'selected',
        width: "5%",
    },
    {
        title: 'ชื่อ Banner',
        dataIndex: 'bannerName',
        width: "20%",
    },
    {
        title: 'ที่อยู่ (URL)',
        dataIndex: 'bannerLocation',
        width: "20%",
    },
    {
        title: 'ระยะเวลา',
        dataIndex: 'bannerDuration',
        width: "20%",
    },
    {
        title: 'เหลือเวลา',
        dataIndex: 'bannerBalance',
        width: "10%",
    },
    {
        title: <FaCog />,
        dataIndex: 'operator',
        align: "center",
        width: "20%",
    },
]

export default function BannerManage(props) {
    const [form] = Form.useForm()

    const [bannerActive, setBannerActive] = useState([])
    const [bannerList, bannerSetList] = useState([])
    const [bannerImage, setBannerImage] = useState({
        loading: false,
        imgURL: null,
    })

    const [modal, setModal] = useState({
        isShow: false,
        title: null,
    })

    const [loading, setLoading] = useState(true)

    const [pageCurrent, setPageCurrent] = useState(1)

    const option = {
        name: 'file',
        action: `${apiStorageUrl}/upload/file`,
        data: {
            // uploaded/img/banner
            bucket: bucketName,
            folder: "image/banner"
        },
        headers: {
            authorization: 'authorization-text',
        },
        onChange(info) {
            if (info.file.status === 'done') {
                let result = info.file.response;
                console.log("info : ", info.file.response)
                if (result?.isSuccess) {
                    setBannerImage({
                        loading: false,
                        imgURL: result.url
                    });
                }
            } else {
                setBannerImage({
                    loading: true,
                    imgURL: bannerImage.imgURL,
                });
            }

            if (info.file.status === 'done') {
                Notification("success", "ระบบ", "อัพโหลดเอกสารสำเร็จ")
            } else if (info.file.status === 'error') {
                Notification("error", "ระบบ", "ไม่สามารถอัพโหลดเอกสารได้ กรุณาตรวจสอบข้อมูล")
            }
        },
        progress: {
            strokeColor: {
                '0%': '#108ee9',
                '100%': '#87d068',
            },
            strokeWidth: 3,
            format: percent => `${parseFloat(percent.toFixed(2))}%`,
        },
    }

    const onPagine = (n) => {
        setPageCurrent(n.current)
    }

    const handleBannerIsActive = async (bannerId, isActive) => {
        let request = {
            bannerId,
            isActive: isActive.target.checked,
        }
        // console.log("handleBannerIsActive : ", request);
        const result = await updateManageBannerIsActive(request)
        if (result.isSuccess) {
            Notification("success", "ระบบ", "บันทึกการเปลี่ยนเเปลงสำเร็จ")
        } else {
            Notification("error", "ระบบ", "บันทึกการเปลี่ยนเเปลงไม่สำเร็จ")
        }
        await getBannerList()
    }

    const getBannerList = async () => {
        setLoading(true)

        const result = await getBannerAllFetch()

        setBannerActive(result.filter(item => item.isActive === 1))

        let tempList = []
        result?.map((val, index) => {

            let balance = moment.duration(moment(val.bannerEndDate).diff(moment())).asDays().toFixed(1);

            tempList.push({
                index: (index + 1),
                selected:
                    <Checkbox
                        checked={val.isActive ? true : false}
                        onClick={(e) => handleBannerIsActive(val.bannerId, e)}
                    ></Checkbox>,
                bannerName: val.bannerName,
                bannerLocation: val.bannerLocation,
                bannerDuration: moment(val.bannerStartDate).format(formatDate) + " - " + moment(val.bannerEndDate).format(formatDate),
                bannerBalance: Math.sign(balance) === -1 ? "หมดอายุ" : balance + " วัน",
                operator:
                    <>
                        <Button
                            icon={<AiOutlineEye style={{ marginTop: -3, color: "white" }} />}
                            style={{
                                width: 35,
                                backgroundColor: "DeepSkyBlue",
                                border: "1px solid DeepSkyBlue",
                                color: 'white',
                                borderRadius: 50,
                                marginRight: 10,
                            }}
                            onClick={() => {
                                setBannerImage({
                                    loading: false,
                                    imgURL: val.bannerImage,
                                });

                                setModal({
                                    isShow: true,
                                    title: "showImageBanner",
                                });
                            }}
                        ></Button>

                        <Button
                            icon={<FiEdit style={{ marginTop: -3, color: "white" }} />}
                            style={{
                                width: 35,
                                backgroundColor: "orange",
                                border: "1px solid orange",
                                color: 'white',
                                borderRadius: 50,
                                marginRight: 10,
                            }}
                            onClick={() => {
                                form.setFieldsValue({
                                    bannerId: val.bannerId,
                                    bannerName: val.bannerName,
                                    bannerLocation: val.bannerLocation,
                                    bannerDuration: [moment(val.bannerStartDate), moment(val.bannerEndDate)],
                                })
                                setBannerImage({
                                    loading: false,
                                    imgURL: val.bannerImage,
                                })

                                setModal({
                                    isShow: true,
                                    title: "editBanner",
                                })
                            }}
                        ></Button>

                        <Popconfirm
                            title="คุณยืนยันลบหรือไม่ ?"
                            okText={<span style={{ width: 50 }}>ใช่</span>}
                            onConfirm={async () => {
                                // reload
                                await deleteBannerById(val.bannerId);
                            }}
                            cancelText={<span style={{ width: 50 }}>ไม่ใช่</span>}
                        >
                            <Button
                                icon={<RiDeleteBin6Line style={{ marginTop: -3, color: "white" }} />}
                                danger
                                type="primary"
                                style={{
                                    width: 35,
                                    borderRadius: 50,
                                    marginRight: 10,
                                }}
                            ></Button>
                        </Popconfirm>
                    </>
            });
        })

        bannerSetList(tempList)

        setLoading(false)
    }

    const onFinish = async (values) => {
        if (bannerImage.imgURL) {
            if (modal.title === "addBanner") {
                const result = await insertManageBanner(values, bannerImage.imgURL)
                if (result.isSuccess) {
                    Notification("success", "ระบบ", "เพิ่ม Banner เรียบร้อย")
                } else {
                    Notification("error", "ระบบ", "ไม่สามารถเพิ่ม Banner ได้ กรุณาลองใหม่อีกครั้ง")
                }
            } else {
                const result = await updateManageBanner(values, bannerImage.imgURL)
                if (result.isSuccess) {
                    Notification("success", "ระบบ", "แก้ไข Banner เรียบร้อย")
                } else {
                    Notification("error", "ระบบ", "ไม่สามารถแก้ไข Banner ได้ กรุณาลองใหม่อีกครั้ง")
                }
            }

            // reload
            await getBannerList()

            // set default
            setFormDefault("save")
        } else {
            Notification("warning", "ระบบ", "กรุณาอัพโหลดภาพ Banner")
        }
    }

    const deleteBannerById = async (bannerId) => {
        const result = await deleteBannerByIdFetch(bannerId)
        if (result.isSuccess) {
            Notification("success", "ระบบ", "ลบ Banner เรียบร้อย")

            await getBannerList()

        } else {
            Notification("error", "ระบบ", "ไม่สามารถลบ Banner ได้ กรุณาลองใหม่อีกครั้ง")
        }
    }

    const setFormDefault = (type) => {
        form.resetFields()

        // ser default image
        setBannerImage({
            loading: false,
            imgURL: null,
        })

        // close modal
        setModal({
            isShow: false,
            title: null,
        })
    }

    useEffect(() => {
        getBannerList()
    }, [])

    return (
        <>
            {!loading ?
                <Row>
                    <Col span={24} style={{ paddingBottom: 5 }}>
                        <label>Banner ที่ทำงานอยู่ ณ ขณะนี้</label>
                    </Col>

                    <Col span={24}>
                        <Row style={{ backgroundColor: "#eeeeee", padding: 10 }}>
                            {bannerActive.map((val, index) =>
                                <Col xs={6} xl={4}>
                                    <Image
                                        style={{ width: 200, paddingRight: 10 }}
                                        src={`${val.bannerImage}`}
                                        preview={{ src: `${val.bannerImage}` }}
                                    />
                                    <br />
                                    ({index + 1}) {val.bannerName}
                                </Col>
                            )}
                        </Row>
                    </Col>

                    <Col span={24} style={{ paddingTop: '15px', textAlign: 'right' }}>
                        <Button
                            type="primary"
                            onClick={() => {
                                setBannerImage({
                                    loading: false,
                                    imgURL: null,
                                })

                                setModal({
                                    isShow: true,
                                    title: "addBanner"
                                })
                            }}
                        >เพิ่ม Banner</Button>
                    </Col>

                    <Col span={24} style={{ paddingTop: '15px' }}>
                        <Table
                            loading={loading}
                            columns={columns}
                            dataSource={bannerList}
                            pagination={{
                                current: pageCurrent,
                                pageSize: 100,
                                total: bannerList.length
                            }}
                            onChange={(n) => onPagine(n)}
                        >
                        </Table>
                    </Col>

                    <Modal
                        title={<label>{modal.title === "showImageBanner" ? "รูปภาพ Banner" : (modal.title === "addBanner" ? "เพิ่ม Banner" : "แก้ไข Banner")}</label>
                        }
                        visible={modal.isShow}
                        onCancel={() => setFormDefault("close-modal")}
                        okButtonProps={{ hidden: modal.title === "showImageBanner" ? true : false }}
                        cancelButtonProps={{ hidden: modal.title === "showImageBanner" ? true : false }}
                        footer={modal.title === "showImageBanner" ? null : [
                            <Button
                                onClick={() => setFormDefault("close-modal")}
                            >ยกเลิก</Button>,

                            <Button type="primary" onClick={() => {
                                form.submit()
                            }}>บันทึก</Button>
                        ]}
                    >
                        {modal.title === "showImageBanner" ?
                            <center>
                                <Image
                                    style={{ width: 472, height: '100%' }}
                                    src={`${bannerImage.imgURL}`}
                                    preview={{ src: `${bannerImage.imgURL}` }}
                                />
                            </center>
                            :
                            <Form form={form}
                                onFinish={onFinish}
                                layout="vertical"
                            >
                                <Form.Item name="bannerId" style={{ display: "none" }}>
                                    <Input />
                                </Form.Item>

                                <Form.Item
                                    label="ชื่อ Banner"
                                    name="bannerName"
                                    rules={[{ required: true, message: 'กรุณากรอกชื่อ Banner' }]}
                                >
                                    <Input />
                                </Form.Item>

                                <Form.Item
                                    label="ที่อยู่ Banner (URL)"
                                    name="bannerLocation"
                                    rules={[{ required: true, message: 'กรุณากรอกที่อยู่ Banner' }]}
                                >
                                    <Input />
                                </Form.Item>

                                <Form.Item
                                    label="ระยะเวลา"
                                    name="bannerDuration"
                                    rules={[{ required: true, message: 'กรุณาเลือกระยะเวลาที่ต้องการ' }]}
                                >
                                    <RangePicker style={{ width: '100%' }} />
                                </Form.Item>

                                <Form.Item
                                    label={
                                        <>
                                            <label style={{ color: "red", fontSize: 22, marginTop: 8 }}>*</label>
                                            <label style={{ paddingLeft: 3 }}>รูปภาพ</label>
                                        </>
                                    }
                                >
                                    <Row>
                                        <Col span={24}>
                                            {bannerImage.imgURL ?
                                                <Image
                                                    style={{ width: '100%', height: props.height, border: "1px solid #e8e8e8" }}
                                                    alt="avatar"
                                                    src={`${bannerImage.imgURL}`}
                                                    preview={{
                                                        src: `${bannerImage.imgURL}`,
                                                    }}
                                                />
                                                :
                                                <Image
                                                    style={{ height: '100%', width: '100%', border: "1px solid #e8e8e8" }}
                                                    src="error"
                                                    fallback={`${imgDefaultUrl}`}
                                                />
                                            }
                                        </Col>
                                        <Col span={24}>
                                            <ImgCrop
                                                modalTitle={<label style={{ fontWeight: 'bold', padding: 0, margin: 0 }}>ปรับเเต่งรูปภาพ</label>}
                                                modalOk={<label style={{ width: 90, cursor: "pointer" }}><AiOutlineUpload style={{ width: 20, height: 20, paddingRight: 5 }} /> อัพโหลด</label>}
                                                modalCancel={<label style={{ width: 90, cursor: "pointer" }}><Icon icon="mdi:cancel" style={{ width: 20, height: 20, paddingRight: 5 }} />ยกเลิก</label>}
                                                aspect={800 / 310}
                                            >
                                                <Upload
                                                    {...option}
                                                    listType="picture"
                                                    maxCount={1}
                                                    showUploadList={false}
                                                    // multiple
                                                    accept='image/jpeg, image/png, image/jfif'
                                                >
                                                    <Button
                                                        type="primary"
                                                        style={{ width: '100%' }}
                                                        icon={bannerImage.loading ? <LoadingOutlined /> : <UploadOutlined />}
                                                    >อัพโหลดไฟล์</Button>
                                                </Upload>
                                            </ImgCrop>
                                        </Col>
                                    </Row>
                                </Form.Item>
                            </Form>
                        }
                    </Modal>
                </Row >
                : []
            }
        </>
    )
}