import '../../css/description.css'
import parse from 'html-react-parser'

export default function CourseDescript(props) {
    const text = props.course.courseDescriptionName ? props.course.courseDescriptionName : ''
    
    return (
        <div className="course-description-container">
            <p>{parse(text)}</p>
        </div>
    )
}