/* eslint-disable array-callback-return */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react-hooks/exhaustive-deps */

import { useState, useEffect, useRef } from 'react'
import {
    Upload,
    Button,
    Row,
    Col,
    List,
    Card,
    Popconfirm,
    Popover,
    Spin,
    Image,
    Divider,
    Empty
} from 'antd'
import { AiOutlineUpload } from "react-icons/ai"
import _uniqueId from 'lodash/uniqueId'
import { Icon } from '@iconify/react'
import { AiOutlineQuestionCircle } from "react-icons/ai"
import { BiTrashAlt } from "react-icons/bi"
import { VscSymbolFile } from "react-icons/vsc"
import { useDispatch, useSelector } from "react-redux"
import { useDimensions } from '../../../../common/mamager/ScreenManager'
import { apiStorageUrl, bucketName, imgDefaultUrl, storageUrl } from '../../../../constants'
import { setCourseManageDocumentStateToSuccess } from '../../../../actions/courseManageDocument.action'
import { httpClient } from '../../../../utils/HttpClient'
import { setCourseManageAddCurrentStepStateToSuccess } from '../../../../actions/courseManageAddCurrentStep.action'
import { deleteCourseDocumentFetch, deleteCourseDocumentThroughSpecialFetch, getApprovalStatusFetch, insertCourseDocumentFetch, updateCourseDocumentListFetch, updateRequestOpenCourseDocumentAdjustFetch } from '../../API'
import { Notification } from '../../../../common/components/notification'

export default function ManageDocument(props) {
    const dispatch = useDispatch()

    let language = "th"

    const { width } = useDimensions()

    const [courseId, setCourseId] = useState(null)
    const [user, setUser] = useState({ uid: null })
    const [isStepActive, setIsStepActive] = useState(null)
    const [courseInspect, setcourseInspect] = useState(null)
    const [switchType, setSwitchType] = useState(null)

    const [approvalStatus, setApprovalStatus] = useState([])

    const [loading, setLoading] = useState(true)
    const [loadingUpload, setLoadingUpload] = useState(false)

    const { resultCourseManageAddCurrentStep } = useSelector(({ courseManageAddCurrentStepReducer }) => courseManageAddCurrentStepReducer)
    const { resultDocument } = useSelector(({ courseManageDocumentReducer }) => courseManageDocumentReducer)

    const [courseDocumentDeleteNow, setCourseDocumentDeleteNow] = useState([])

    const [documentNewUpload, setDocumentNewUpload] = useState([])

    const typeUploadRef = useRef(null)
    const documentIdOverlapRef = useRef(null)

    const option = {
        name: 'file',
        action: `${apiStorageUrl}/upload/file`,
        data: {
            // uploaded/file/course-document
            bucket: bucketName,
            folder: "file/course/document"
        },
        headers: {
            authorization: 'authorization-text',
        },
        onChange(info) {
            setLoadingUpload(true)

            if (info.file.status === 'done') {
                let fileData = info.file.response;
                console.log("info : ", info.file.response)
                if (fileData?.isSuccess) {

                    let fileCategory
                    if (info.file.type === "application/pdf") { // PDF
                        fileCategory = 1
                    } else if (
                        info.file.type === "image/png" ||
                        info.file.type === "image/jpeg"
                    ) { // IMG
                        fileCategory = 2
                    }

                    if (typeUploadRef.current === "upload-overlap") {

                        // state
                        let tempDocumentNewUpload = [];
                        tempDocumentNewUpload = documentNewUpload.map(val => val.coureDocumentationId === documentIdOverlapRef.current ? {
                            // courseDocument
                            coureDocumentationId: val.coureDocumentationId,
                            coureDocumentationName: fileData.name,
                            coureDocumentationLocation: fileData.url,
                            courseId: val.courseId,
                            created_at: val.created_at,
                            fileCategoryId: fileCategory,
                            // fileCategoryName: val.fileCategoryName,
                            isUpdate: val.isUpdate,
                            approvalStatusId: val.approvalStatusId,
                            approvalStatusName: val.approvalStatusName,

                            // courseInspect
                            inspectCourseApprovalId: val.inspectCourseApprovalId,
                            inspectCourseApprovalName: val.inspectCourseApprovalName,
                            reasonCourseNotApproval: val.reasonCourseNotApproval,

                        } : val)

                        setDocumentNewUpload(tempDocumentNewUpload)

                        // reducer
                        let tempResultDocument = [];
                        tempResultDocument = resultDocument.map(val => val.coureDocumentationId === documentIdOverlapRef.current ? {
                            // courseDocument
                            coureDocumentationId: val.coureDocumentationId,
                            coureDocumentationName: fileData.name,
                            coureDocumentationLocation: fileData.url,
                            courseId: val.courseId,
                            created_at: val.created_at,
                            fileCategoryId: fileCategory,
                            // fileCategoryName: val.fileCategoryName,
                            isUpdate: val.isUpdate,
                            approvalStatusId: val.approvalStatusId,
                            approvalStatusName: val.approvalStatusName,

                            // courseInspect
                            inspectCourseApprovalId: val.inspectCourseApprovalId,
                            inspectCourseApprovalName: val.inspectCourseApprovalName,
                            reasonCourseNotApproval: val.reasonCourseNotApproval,
                        } : val)

                        setReducer(tempResultDocument)
                    }

                    if (typeUploadRef.current === "upload-normal") {
                        // state
                        setDocumentNewUpload([...documentNewUpload, { // new file
                            courseId,
                            coureDocumentationId: null,
                            coureDocumentationName: fileData.name,
                            coureDocumentationLocation: fileData.url,
                            approvalStatusId: 9,
                            approvalStatus: 'เพิ่มเอกสาร รอการตรวจสอบ',
                            fileCategoryId: fileCategory,
                            isUpdate: false,
                        }])

                        // reducer
                        setReducer([...resultDocument, {
                            courseId,
                            coureDocumentationId: _uniqueId('temporary-=|=-'), // get id from insert
                            coureDocumentationName: fileData.name,
                            coureDocumentationLocation: fileData.url,
                            approvalStatusId: 9,
                            approvalStatus: 'เพิ่มเอกสาร รอการตรวจสอบ',
                            fileCategoryId: fileCategory,
                            isUpdate: false,
                        }])
                    }
                }
            }

            if (info.file.status === 'done') {
                setLoadingUpload(false)
                Notification("success", "ระบบ", "อัพโหลดเอกสารสำเร็จ")
            } else if (info.file.status === 'error') {
                Notification("error", "ระบบ", "ไม่สามารถอัพโหลดเอกสารได้ กรุณาตรวจสอบข้อมูล")
            }


        },
        progress: {
            strokeColor: {
                '0%': '#108ee9',
                '100%': '#87d068',
            },
            strokeWidth: 3,
            format: percent => `${parseFloat(percent.toFixed(2))}%`,
        },
    }

    const deleteFileImmediately = (location, findFile, remove) => { // [ url api, obj file, status remove file from server now when click submit ]
        // remove
        if (remove) {
            removeDir('/remove/course-document', findFile.coureDocumentationLocation)
        } else {
            courseDocumentDeleteNow.push({
                coureDocumentationId: findFile.coureDocumentationId,
                coureDocumentationLocation: findFile.coureDocumentationLocation,
                location
            })
        }

        // filter
        let tempResultDocument = resultDocument.filter(val => location !== val.coureDocumentationLocation)

        let tempDocumentNewUpload = documentNewUpload.filter(val => location !== val.coureDocumentationLocation)

        // reducer
        setReducer(tempResultDocument)

        // state
        setDocumentNewUpload(tempDocumentNewUpload)
    }

    const onDeleteFile = async (location, from, id, approvalStatusId) => {
        // console.log(location, from, id, approvalStatusId);
        let findFile;
        findFile = resultDocument.find((val) => val.coureDocumentationLocation === location ? val : null);

        if (from === 'fromClient') {

            if (id !== "temporary-=|=-1" && approvalStatusId === 9) { // เอกสารที่ขอเพิ่ม เเต่ไม่ผ่าน เเล้วผู้สอนจะลบออก

                const result = await deleteCourseDocumentThroughSpecialFetch({
                    uid: user.uid,                  // uid
                    courseId,                       // courseId
                    coureDocumentationId: id,       // coureDocumentationId
                    isActive: 1                     // isActive
                })
                if (result.data.isSuccess) {
                    // reload
                    props.handleDocumentReload();

                    Notification("success", "ระบบ", "ลบวิดีโอสำเร็จ")
                } else {
                    Notification("error", "ระบบ", "ลบวิดีโอไม่สำเร็จ")
                }
            } else if (id === "temporary-=|=-1" && approvalStatusId !== 9) { // [/] ผู้สอนที่กดลบเอกกสาร ที่ยังไม่ได้ส่งขอตรวจสอบ สามารถลบออกได้ทันที

                // remove immediately
                deleteFileImmediately(location, findFile, true)
            } else if (id !== "temporary-=|=-1" && approvalStatusId === 11) { // [/] ผู้สอนขอลบเอกสารที่กดขอลบไปก่อนหน้านี้เเล้ว เเล้วดันกดขอลบซ้ำ
                Notification("error", "ระบบ", "คุณได้ขอลบเอกสารนี้ไปเเล้ว!")
            }
        } else {
            let tempDocumentationId = String(findFile.coureDocumentationId)?.split("-=|=-") // split a string using "-=|=-" form add video
            if (tempDocumentationId[0] !== "temporary") {
                if (findFile.approvalStatusId === 9) {
                    // remove immediately
                    deleteFileImmediately(location, findFile, false)
                } else {
                    let approvalStatus = "ลบเอกสาร รอการตรวจสอบ"
                    let approvalStatusId = 11;

                    let tempResultDocument = resultDocument.map(val => {
                        return (val.coureDocumentationId === findFile.coureDocumentationId ? {
                            courseId: val.courseId,
                            coureDocumentationId: val.coureDocumentationId,
                            coureDocumentationLocation: val.coureDocumentationLocation,
                            coureDocumentationName: val.coureDocumentationName,
                            approvalStatusId, //change
                            approvalStatus, //change
                            fileCategoryId: val.fileCategoryId,
                            created_at: val.created_at,
                            isUpdate: val.isUpdate,
                        } : val)
                    })

                    // reducer
                    setReducer(tempResultDocument)
                }
            } else {
                // remove immediately
                deleteFileImmediately(location, findFile, true)
            }
        }
    }

    const setReducer = (docList) => {
        // reducer
        dispatch(setCourseManageDocumentStateToSuccess(docList))
    }

    const removeDir = async (basePath, path) => {
        const tmpFilePath = path
        const pathSplit = tmpFilePath?.split("/")
        const pathMap = pathSplit[0] + '/' + pathSplit[1]

        const formDataREM = new FormData()
        formDataREM.append('pathFolder', pathMap)
        formDataREM.append('pathFile', path)

        await httpClient.post(basePath, formDataREM, {
            headers: { 'Content-Type': 'multipart/form-data' }
        })
    }

    const handleUpdateDocument = async (updateType) => {
        let documentUpdate = []
        let documentInsert = []
        let documentSolveBack = []

        // update api
        for (let i = 0; i < resultDocument.length; i++) {
            let tempDocumentationId = String(resultDocument[i].coureDocumentationId).split("-=|=-"); // split a string using "-=|=-" form add video
            if (tempDocumentationId[0] !== "temporary") {
                if (resultDocument[i].approvalStatusId === 9) {
                    // update to course_document_temp_update ***
                } else if (resultDocument[i].approvalStatusId === 11 && !resultDocument[i].isUpdate) {
                    // delete to course_document_temp_update เเบบส่งคำร้องของลบ ***
                    documentUpdate.push({
                        coureDocumentationId: resultDocument[i] ? resultDocument[i].coureDocumentationId : null,
                        coureDocumentationLocation: resultDocument[i] ? resultDocument[i].coureDocumentationLocation : null,
                        coureDocumentationName: resultDocument[i] ? resultDocument[i].coureDocumentationName : null,
                        approvalStatusId: resultDocument[i] ? resultDocument[i].approvalStatusId : null,
                        fileCategoryId: resultDocument[i] ? resultDocument[i].fileCategoryId : null,
                        created_at: resultDocument[i] ? resultDocument[i].created_at : null,
                    })
                }
            } else {
                // add to course_document เเบบตรวจสอบ ***
                documentInsert.push({
                    coureDocumentationLocation: resultDocument[i] ? resultDocument[i].coureDocumentationLocation : null,
                    coureDocumentationName: resultDocument[i] ? resultDocument[i].coureDocumentationName : null,
                    approvalStatusId: resultDocument[i] ? resultDocument[i].approvalStatusId : null,
                    fileCategoryId: resultDocument[i] ? resultDocument[i].fileCategoryId : null,
                })
            }
        }

        // click "บันทึกการปรับเเก้"
        let resultSolveBack = { data: { isSuccess: true } }
        if (updateType === "solveBack") {
            for (let i = 0; i < resultDocument.length; i++) {
                if (resultDocument[i].isUpdate) {
                    documentSolveBack.push({
                        coureDocumentationId: resultDocument[i] ? resultDocument[i].coureDocumentationId : null,
                        coureDocumentationLocation: resultDocument[i] ? resultDocument[i].coureDocumentationLocation : null,
                        coureDocumentationName: resultDocument[i] ? resultDocument[i].coureDocumentationName : null,
                        approvalStatusId: resultDocument[i] ? resultDocument[i].approvalStatusId : null,
                        fileCategoryId: resultDocument[i] ? resultDocument[i].fileCategoryId : null,
                        created_at: resultDocument[i] ? resultDocument[i].created_at : null,
                        crossStampIsUpdate: true
                    })
                }
            }

            resultSolveBack = await updateCourseDocumentListFetch(
                user.uid,           // uid
                documentSolveBack,  // course document
                1,                  // isActive
                Number(courseId),   // courseId
                "courseDocument",   // courseComponent
            )
        }

        const resultUpdate = await updateCourseDocumentListFetch(
            user.uid,           // uid
            documentUpdate,     // course document
            1,                  // isActive
            Number(courseId),   // courseId
            "courseDocument",   // courseComponent
        )

        const resultInsert = await insertCourseDocumentFetch(
            user.uid,           // uid
            documentInsert,     // course document
            1,                  // isActive
            Number(courseId),   // courseId
            "courseDocument",   // courseComponent
        )

        if (resultUpdate.data.isSuccess && resultInsert.data.isSuccess && resultSolveBack.data.isSuccess) {
            Notification("success", "ระบบ", "บันทึกการปรับเเต่งสำเร็จ")
        } else {
            Notification("error", "ระบบ", "บันทึกการปรับเเต่งไม่สำเร็จ")
        }

        // ลบไฟล์ที่เพิ่มเข้าไปเเล้วยังรอการตรวจสอบอยู่ ให้ลบออกไปได้เลย โดยไม่ต้องรอตรวจสอบ ***
        if (courseDocumentDeleteNow.length > 0) {
            let deleteNowIsSuccess = 0
            for (let i = 0; i < courseDocumentDeleteNow.length; i++) {
                let resultDelete = await deleteCourseDocumentFetch(courseDocumentDeleteNow[i].coureDocumentationId)
                if (resultDelete.data.isSuccess) {
                    // delete file on server
                    await deleteFileImmediately(courseDocumentDeleteNow[i].location, courseDocumentDeleteNow[i], true)
                    deleteNowIsSuccess++
                }
            }

            // check all delete success
            if (deleteNowIsSuccess === courseDocumentDeleteNow.length) {
                setCourseDocumentDeleteNow([]) // clear data
            }
        }

        if (!isStepActive) {
            // reload
            props.handleDocumentReload()
        }
    }

    const handleAdjustDocument = async () => {
        let obj = resultDocument.filter(val => val.approvalStatusId === 2 || val.approvalStatusId === 9);
        const resultUpdate = await updateRequestOpenCourseDocumentAdjustFetch(
            user.uid,           // uid
            obj,                // course document
            1,                  // inspectionCourseAdjustCategoryId
            1,                  // isActive
            courseId,           // courseId
        )
        if (resultUpdate.data.isSuccess) {
            Notification("success", "ระบบ", "บันทึกการปรับเเก้สำเร็จ")
        } else {
            Notification("error", "ระบบ", "บันทึกการปรับเเก้ไม่สำเร็จ")
        }

        if (!isStepActive) {
            // reload
            props.handleDocumentReload()
        }
    }

    const genDocumentApprovalDisplay = (approvalId) => {
        return (
            <div
                style={
                    approvalId === 1 ? { paddingRight: 20, color: 'green' } :
                        (approvalId === 9 ? { paddingRight: 20, color: 'orange' } :
                            { paddingRight: 20, color: 'red' })}
            >
                {approvalStatus.length > 0 ? (approvalStatus.find(val => val.approvalStatusId === approvalId).approvalStatusName) : []}
            </div>
        )
    }

    const genIconRemove = (coureDocumentationLocation, isUpdate, approvalStatusId, coureDocumentationId) => {
        return (
            <Popconfirm
                title="คุณต้องการลบเอกสารหรือไม่ ?"
                okText={<span style={{ width: 50 }}>ใช่</span>}
                onConfirm={() => {
                    onDeleteFile(
                        coureDocumentationLocation,
                        isStepActive ? 'fromClient' : (isUpdate ? 'fromClient' : 'fromServer'),
                        coureDocumentationId,
                        approvalStatusId,
                    );
                }}
                cancelText={<span style={{ width: 50 }}>ไม่ใช่</span>}
                disabled={
                    switchType === "adjust-course" ?
                        (approvalStatusId === 11 ? true : false)
                        :
                        // (switchType === "edit-course" ? false : true)
                        false
                }
            >
                <div style={{ backgroundColor: 'red', borderRadius: 20, cursor: 'pointer', width: 30, height: 30, display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <BiTrashAlt size={16} style={{ color: 'white', cursor: 'pointer' }} />
                </div>
            </Popconfirm>
        )
    }

    const genIconRemoveNow = (coureDocumentationLocation, approvalStatusId, coureDocumentationId) => {
        return (
            <Popconfirm
                title="คุณต้องการลบเอกสารหรือไม่ ?"
                okText={<span style={{ width: 50 }}>ใช่</span>}
                onConfirm={() => {
                    onDeleteFile(
                        coureDocumentationLocation,
                        'fromServer',
                        coureDocumentationId,
                        approvalStatusId,
                    );
                }}
                cancelText={<span style={{ width: 50 }}>ไม่ใช่</span>}
            >
                <div style={{ backgroundColor: 'red', borderRadius: 20, cursor: 'pointer', width: 30, height: 30, display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <BiTrashAlt size={16} style={{ color: 'white', cursor: 'pointer' }} />
                </div>
            </Popconfirm>
        )
    }

    const genIconUpload = (coureDocumentationId) => {
        return (
            <Upload
                {...option}
                listType="picture"
                maxCount={1}
                showUploadList={false}
                // multiple
                accept='application/pdf, image/jpeg, image/png, image/jfif'
            >
                <div style={{ backgroundColor: '#23B14D', borderRadius: 20, cursor: 'pointer', width: 30, height: 30, display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <AiOutlineUpload
                        size={16}
                        style={{ color: 'white', cursor: 'pointer', marginTop: -2 }}
                        onClick={() => {
                            documentIdOverlapRef.current = coureDocumentationId;
                            typeUploadRef.current = "upload-overlap";
                        }}
                        disabled={
                            switchType === "adjust-course" ?
                                (courseInspect.inspectionCourseId === 3 ? false : true)
                                :
                                (switchType === "edit-course" ?
                                    (props.courseInspect ?
                                        (props.courseInspect.inspectionCourseId === 2 && props.courseInspect.inspectionCourseApprovalId === 2 ? true : false) : false) : false)
                        }
                    />
                </div>
            </Upload>
        )
    }

    const genBtnUpload = () => {
        return (
            <Upload
                {...option}
                listType="picture"
                maxCount={1}
                showUploadList={false}
                // multiple
                accept='application/pdf, image/jpeg, image/png, image/jfif'
            >
                <Button
                    loading={loadingUpload}
                    // style={{ float: 'right' }}
                    type="primary"
                    onClick={() => typeUploadRef.current = "upload-normal"}
                    disabled={
                        switchType === "adjust-course" ?
                            // adjust true is disable
                            (props.courseInspect ?
                                (props.courseInspect.courseDocument.inspectionCourseApprovalIdSummary === 1 || props.courseInspect.inspectionCourseCardId === 2 ? true : false) : false)
                            :
                            (switchType === "edit-course" ?
                                (props.courseInspect ?
                                    (props.courseInspect.inspectionCourseId === 2 && props.courseInspect.inspectionCourseApprovalId === 2 ? true : false) : false) : false)
                    }
                >
                    {!loadingUpload ?
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <AiOutlineUpload style={{ width: 20, height: 20 }} />
                            <label style={{ paddingLeft: 10, cursor: "pointer" }}>อัพโหลดไฟล์</label>
                        </div>
                        : []
                    }
                </Button>
            </Upload>
        )
    }

    const genDocumentList = (document) => {
        return (
            <List
                itemLayout="horizontal"
                dataSource={document}
                locale={{
                    emptyText:
                        <Empty
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                            imageStyle={{
                                height: 50
                            }}
                            style={{ width: '100%' }}
                            description={
                                <span style={{ color: 'gray' }}>ไม่พบข้อมูล</span>
                            }
                        >
                        </Empty>
                }}
                renderItem={item => (
                    <div
                        style={{
                            borderColor: '#D0D0D0',
                            backgroundColor: 'white',
                            borderStyle: 'solid',
                            borderWidth: 'thin',
                            marginBottom: 9,
                            paddingLeft: 15,
                            paddingRight: 15,
                            paddingTop: 6,
                            paddingBottom: 6
                        }}
                    >
                        <List.Item style={{ margin: -6 }}>

                            <div style={{ paddingRight: 15, color: '#2BAEFF', marginBottom: -7 }}>
                                {item.fileCategoryId === 1 ?
                                    <VscSymbolFile size={40} />
                                    :
                                    <>
                                        {item.coureDocumentationLocation ?
                                            <Image
                                                style={{ height: 40 }}
                                                alt="avatar"
                                                src={`${item.coureDocumentationLocation}`}
                                                preview={{
                                                    src: `${item.coureDocumentationLocation}`,
                                                }}
                                            />
                                            :
                                            <Image
                                                style={{ height: 40 }}
                                                src="error"
                                                fallback={imgDefaultUrl}
                                            />
                                        }
                                    </>
                                }
                            </div>

                            <List.Item.Meta
                                style={{ textAlign: 'left', height: 'auto' }}
                                title={
                                    <Row>
                                        <Col span={24}>
                                            <div style={{ marginTop: -2.5 }}>
                                                <a style={{ color: 'black' }} href={`${item.coureDocumentationLocation}`} target="_blank">{item.coureDocumentationName}</a>
                                                {(switchType === "adjust-course" || switchType === "edit-course") ?
                                                    <>
                                                        {item.inspectCourseApprovalId ?
                                                            genDocumentApprovalDisplay(item.inspectCourseApprovalId)
                                                            :
                                                            genDocumentApprovalDisplay(item.approvalStatusId)
                                                        }
                                                    </>
                                                    :
                                                    genDocumentApprovalDisplay(item.approvalStatusId)
                                                }
                                            </div>
                                        </Col>
                                    </Row>
                                }
                            />

                            {/* ลบไฟล์ ------------------------------------------------------------------------------------------------------------------------- */}
                        </List.Item>

                        {/* การเปิดเผยข้อมูลสู่สาธารณะ */}
                        <Row>
                            <Col xs={(switchType === "add-course" ? 16 : 24)} sm={18} md={18} lg={18} style={{ paddingTop: 20 }}>
                                {/* <Switch
                                    checkedChildren={<CheckOutlined />}
                                    unCheckedChildren={<CloseOutlined />}
                                    defaultChecked
                                    onChange={(e) => {
                                        // console.log("X : ", e);
                                    }}
                                />
                                <span style={{ paddingLeft: 8 }}>เป็นสาธาระณะ</span> */}
                            </Col>
                            <Col xs={(switchType === "add-course" ? 8 : 24)} sm={6} md={6} lg={6} style={{ paddingTop: 18, paddingBottom: 6, float: "right" }}>
                                <Row >
                                    {/* อัพโหลดใหม่ --------------------------------------------------------------------------------------------------------------------- */}
                                    <Col span={12}>
                                        <center>
                                            {switchType === "adjust-course" ?
                                                <>
                                                    {props.courseInspect.inspectionCourseDocumentId === 2 ?
                                                        <div style={{ backgroundColor: 'gray', borderRadius: 20, cursor: 'pointer', width: 30, height: 30, display: "flex", alignItems: "center", justifyContent: "center" }}><center><AiOutlineUpload size={16} style={{ color: 'white', marginTop: -2 }} /></center></div>
                                                        :
                                                        (props.courseInspect ?
                                                            (props.courseInspect.courseDocument.inspectionCourseApprovalIdSummary === 1 ?
                                                                <div style={{ backgroundColor: 'gray', borderRadius: 20, cursor: 'pointer', width: 30, height: 30, display: "flex", alignItems: "center", justifyContent: "center" }}><center><AiOutlineUpload size={16} style={{ color: 'white', marginTop: -2 }} /></center></div>
                                                                :
                                                                <>
                                                                    {item.inspectCourseApprovalId === 1 ?
                                                                        <div style={{ backgroundColor: 'gray', borderRadius: 20, cursor: 'pointer', width: 30, height: 30, display: "flex", alignItems: "center", justifyContent: "center" }}><center><AiOutlineUpload size={16} style={{ color: 'white', marginTop: -2 }} /></center></div>
                                                                        :
                                                                        genIconUpload(item.coureDocumentationId)
                                                                    }
                                                                </>
                                                            )
                                                            :
                                                            false
                                                        )
                                                    }
                                                </>
                                                :
                                                (switchType === "edit-course" ?
                                                    (item.inspectCourseApprovalId === 2 ?
                                                        <>
                                                            {(props.courseInspect ?
                                                                (props.courseInspect.inspectionCourseId !== 2 && props.courseInspect.inspectionCourseApprovalId !== 2 ?
                                                                    genIconUpload(item.coureDocumentationId)
                                                                    :
                                                                    (props.courseInspect.inspectionCourseId !== 2 && props.courseInspect.inspectionCourseApprovalId !== 2 ?
                                                                        genIconUpload(item.coureDocumentationId)
                                                                        :
                                                                        (item.approvalStatusId === 1 || item.approvalStatusId === 9 || item.approvalStatusId === 11) && props.courseInspect.inspectionCourseId === 3 ?
                                                                            genIconUpload(item.coureDocumentationId)
                                                                            :
                                                                            <div style={{ backgroundColor: 'gray', borderRadius: 20, cursor: 'pointer', width: 30, height: 30, display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                                                <center><AiOutlineUpload size={16} style={{ color: 'white', marginTop: -2 }} /></center>
                                                                            </div>
                                                                    )
                                                                )
                                                                :
                                                                <div style={{ backgroundColor: 'gray', padding: 4, borderRadius: 20, cursor: 'pointer', width: 30, height: 30, display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                                    <center><AiOutlineUpload size={16} style={{ color: 'white', marginTop: -2 }} /></center>
                                                                </div>
                                                            )}
                                                        </>
                                                        : []
                                                    ) : []
                                                )
                                            }
                                        </center>
                                    </Col>

                                    {/* ลบไฟล์ ------------------------------------------------------------------------------------------------------------------------- */}
                                    <Col span={12}>
                                        <center>
                                            {switchType === "adjust-course" ?
                                                <>
                                                    {props.courseInspect.inspectionCourseDocumentId === 2 ?
                                                        <div style={{ backgroundColor: 'gray', borderRadius: 20, cursor: 'pointer', width: 30, height: 30, display: "flex", alignItems: "center", justifyContent: "center" }}><BiTrashAlt size={16} style={{ color: 'white' }} /></div>
                                                        :
                                                        (props.courseInspect ?
                                                            (props.courseInspect.courseDocument.inspectionCourseApprovalIdSummary === 1 ?
                                                                <div style={{ backgroundColor: 'gray', borderRadius: 20, cursor: 'pointer', width: 30, height: 30, display: "flex", alignItems: "center", justifyContent: "center" }}><BiTrashAlt size={16} style={{ color: 'white' }} /></div>
                                                                :
                                                                <>
                                                                    {item.inspectCourseApprovalId === 1 ?
                                                                        <div style={{ backgroundColor: 'gray', borderRadius: 20, cursor: 'pointer', width: 30, height: 30, display: "flex", alignItems: "center", justifyContent: "center" }}><BiTrashAlt size={16} style={{ color: 'white' }} /></div>
                                                                        :
                                                                        genIconRemove(item.coureDocumentationLocation, item.isUpdate, item.approvalStatusId, item.coureDocumentationId)
                                                                    }
                                                                </>
                                                            )
                                                            :
                                                            false
                                                        )
                                                    }
                                                </>
                                                :
                                                (switchType === "edit-course" ?
                                                    (!item.inspectCourseApprovalId && item.approvalStatusId !== 11 ?
                                                        <>
                                                            {props.courseInspect ?
                                                                (props.courseInspect.inspectionCourseId !== 2 && props.courseInspect.inspectionCourseApprovalId !== 2 ?
                                                                    genIconRemove(item.coureDocumentationLocation, item.isUpdate, item.approvalStatusId, item.coureDocumentationId)
                                                                    :
                                                                    ((item.approvalStatusId === 1 || item.approvalStatusId === 4) && props.courseInspect.inspectionCourseId === 3 ?
                                                                        genIconRemove(item.coureDocumentationLocation, item.isUpdate, item.approvalStatusId, item.coureDocumentationId)
                                                                        :
                                                                        (!item.inspectCourseApprovalId && !props.courseInspect.courseDocument.inspectionCourseAdjustIdSummary && props.courseInspect.inspectionCourseId === 2 ?
                                                                            genIconRemove(item.coureDocumentationLocation, item.isUpdate, item.approvalStatusId, item.coureDocumentationId)
                                                                            :
                                                                            <div style={{ backgroundColor: 'gray', borderRadius: 20, cursor: 'pointer', width: 30, height: 30, display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                                                <BiTrashAlt size={16} style={{ color: 'white' }} />
                                                                            </div>
                                                                        )
                                                                    )
                                                                )
                                                                :
                                                                genIconRemove(item.coureDocumentationLocation, item.isUpdate, item.approvalStatusId, item.coureDocumentationId)
                                                            }
                                                        </>
                                                        :
                                                        <>
                                                            {switchType === "adjust-course" ?
                                                                <div style={{ backgroundColor: 'gray', borderRadius: 20, cursor: 'pointer', width: 30, height: 30, display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                                    <BiTrashAlt size={16} style={{ color: 'white' }} />
                                                                </div>
                                                                :
                                                                (switchType === "edit-course" ?
                                                                    (props.courseInspect ?
                                                                        (props.courseInspect.inspectionCourseId !== 2 && props.courseInspect.inspectionCourseApprovalId !== 2 ?
                                                                            <>
                                                                                {item.coureDocumentationId !== "temporary-=|=-1" && item.approvalStatusId === 11 ?
                                                                                    <div style={{ backgroundColor: 'gray', borderRadius: 20, cursor: 'pointer', width: 30, height: 30, display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                                                        <BiTrashAlt size={16} style={{ color: 'white' }} />
                                                                                    </div>
                                                                                    :
                                                                                    genIconRemove(item.coureDocumentationLocation, item.isUpdate, item.approvalStatusId, item.coureDocumentationId)
                                                                                }
                                                                            </>
                                                                            :
                                                                            (props.courseInspect.inspectionCourseId !== 2 && props.courseInspect.inspectionCourseApprovalId !== 2 ?
                                                                                genIconRemove(item.coureDocumentationLocation, item.isUpdate, item.approvalStatusId, item.coureDocumentationId)
                                                                                :
                                                                                (item.approvalStatusId === 1 || item.approvalStatusId === 9 || item.approvalStatusId === 11) && props.courseInspect.inspectionCourseId === 3 ?
                                                                                    genIconRemove(item.coureDocumentationLocation, item.isUpdate, item.approvalStatusId, item.coureDocumentationId)
                                                                                    :
                                                                                    <div style={{ backgroundColor: 'gray', borderRadius: 20, cursor: 'pointer', width: 30, height: 30, display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                                                        <BiTrashAlt size={16} style={{ color: 'white' }} />
                                                                                    </div>
                                                                            )
                                                                        )
                                                                        :
                                                                        genIconRemove(item.coureDocumentationLocation, item.isUpdate, item.approvalStatusId, item.coureDocumentationId)
                                                                    )
                                                                    : []
                                                                )
                                                            }
                                                        </>
                                                    ) :
                                                    genIconRemoveNow(item.coureDocumentationLocation, item.approvalStatusId, item.coureDocumentationId)
                                                )
                                            }
                                        </center>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                        {item.inspectCourseApprovalId === 2 ? // not pass
                            <>
                                <Divider style={{ marginTop: 8, marginBottom: 1 }} />
                                <Row
                                    style={{
                                        paddingTop: 12,
                                        paddingBottom: 9,
                                        fontSize: 14
                                    }}
                                >
                                    <Col span={24} style={{ paddingBottom: 1 }}>
                                        เหตุผลไม่ผ่าน
                                        <Popover content={<div style={{ width: 250 }}>ผลตรวจ ที่ทาง Toflyn เเจ้งให้ผู้สอนปรับเเก้ เเละส่งกลับมาใหม่อีกครั้งในเวลาที่กำหนด</div>}>
                                            <AiOutlineQuestionCircle style={{ marginLeft: 5, cursor: 'pointer' }} />
                                        </Popover>
                                    </Col>
                                    <Col span={24} style={{ backgroundColor: "#EEEEEE", padding: 5 }}>
                                        <p style={{ margin: 0, color: "red" }}>{item.reasonCourseNotApproval}</p>
                                    </Col>
                                </Row>
                            </>

                            : []}
                    </div>
                )}
            />
        )
    }

    const getBaseApi = async () => {
        let result = await getApprovalStatusFetch(language)
        setApprovalStatus(result)
    }

    useEffect(() => {
        setLoading(true)

        setTimeout(function () {
            setcourseInspect(props.courseInspect)
            setCourseId(props.courseId)
            setUser(props.user)
            setIsStepActive(props.isStepActive)
            setSwitchType(props.switchType)

            getBaseApi()

            if (props.isStepActive) { // is open
                setReducer(resultDocument)
            } else { // is close
                setReducer(props.courseDocument)
            }

            setLoading(false)
        }, 500)
    }, [])

    return (
        <>
            {!loading ?
                <>
                    <div style={{ backgroundColor: 'white' }}>
                        <Row
                        // style={{ border: '1px solid #EEEEEE' }}
                        >
                            <Col span={24}>
                                <Row>
                                    <Col className="gutter-row" xs={24} lg={12} style={{ borderRight: '1px solid #ECECEC' }}>
                                        <Card
                                            bordered={false}
                                            title={
                                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                    <div style={{ paddingBottom: 0, float: 'left' }}>ไฟล์ใหม่</div>
                                                    {switchType === "edit-course" ?
                                                        props.courseInspect ?
                                                            props.courseInspect.inspectionCourseId !== 2 && props.courseInspect.inspectionCourseApprovalId !== 2 ?
                                                                genBtnUpload()
                                                                :
                                                                !props.courseInspect.courseDocument.inspectionCourseAdjustIdSummary ?
                                                                    genBtnUpload()
                                                                    :
                                                                    <Button type="primary" icon={<AiOutlineUpload style={{ paddingRight: 5 }} />} disabled={true}>อัพโหลดไฟล์</Button>
                                                            :
                                                            genBtnUpload()
                                                        :
                                                        genBtnUpload()
                                                    }
                                                </div>
                                            }
                                        >
                                            <div style={{ height: 395, overflow: 'auto', overflowX: 'hidden', backgroundColor: '#f5f5f5', padding: 10 }}>
                                                {genDocumentList(documentNewUpload)}
                                            </div>
                                        </Card>
                                    </Col>
                                    <Col className="gutter-row" xs={24} lg={12}>
                                        <Card
                                            bordered={false}
                                            title={<div style={{ paddingBottom: 0, textAlign: 'left' }}>ไฟล์ในคลัง</div>}
                                        >
                                            <div style={{ height: 395, overflow: 'auto', overflowX: 'hidden', backgroundColor: '#f5f5f5', padding: 10 }}>
                                                {genDocumentList(resultDocument)}
                                            </div>
                                        </Card>
                                    </Col>
                                </Row>

                                <Divider style={{ marginTop: 1, marginBottom: 15 }} />
                                <div span={24} style={{ paddingLeft: 20, paddingRight: 20, paddingBottom: 3, float: "left", fontSize: 14 }}>
                                    <label>หมายเหตุ !</label><br />
                                    <ul>
                                        <li>ส่วนของ "เอกสารเพิ่มเติม" ใช้จัดเก็บไฟล์ ซึ่งจะเเสดงให้ผู้เรียนเห็น เช่น ข้อมูลที่เคยอบรม บรรยาย เกียร์ติบัตร หรือสิ่งที่การันตีความสามารถ ความน่าเชื่อถือ เพื่อให้ผู้เรียนใช้ประกอบการตัดสินได้ง่ายขึ่น</li>
                                        {/* <li>หากคอร์สของท่านมีเอกสารประกอบการเรียน เเละต้องการเเสดงให้ผู้เรียนดาวน์โหลดเเบบไม่สาธารณะ เฉพาะผู้เรียนที่ซื้อคอร์สเเล้ว ท่านสามารถปรับโหมดของไฟล์เป็นเเบบ "ไม่สาธารณะ" ได้</li> */}
                                        <li>ข้อมูลในส่วนนี้ ทางเราไม่บังคับให้ใส่ ขึ้นอยู่กับท่าน</li>
                                        <li>ประเภทไฟล์ที่ยินยอมให้ อัพโหลดต้องเป็นไฟลรูปภาพที่เป็นนามสกุล (.png, .jpg, .jpeg) หรือไฟล์ .pdf เท่านั้น</li>
                                        <li>เมื่อ "เอกสารเพิ่มเติม" ผ่านการตรวจสอบเเล้ว จะเผยแพร่สู่สาธารณะให้ผู้คนทั่วไปเห็นได้ทันที</li>
                                        {/* <li>เมื่อ "เอกสารเพิ่มเติม" ผ่านการตรวจสอบเเล้ว จะเผยแพร่สู่สาธารณะให้ผู้คนทั่วไปเห็นได้ทันที เเม้ว่าผู้เรียนจะยังไม่ได้ซื้อคอร์สนี้ก็ตาม ยกเว้นไฟล์ที่เป็น "ไม่สาธารณะ" ที่ผู้คนทั่วไปไม่สามารถมองเห็น โดยจะเห็นเพียงเฉพาะผู้เรียนที่ชำระเงินเเล้วเท่านั้น !</li> */}
                                    </ul>
                                </div>
                            </Col>
                            <Divider style={{ marginTop: 1, marginBottom: 1 }} />
                            <Col span={24} style={{ padding: 20 }}>
                                {isStepActive ? // is open
                                    <Row gutter={[24, 24]}>
                                        <Col xs={24} sm={12} md={12} lg={12}>
                                            <Button
                                                style={width > 992 ? { float: 'right', width: 130 } : ((width < 992 && width > 576) ? { width: 130, float: 'right' } : { width: '100%' })}
                                                type="default"
                                                onClick={() => dispatch(setCourseManageAddCurrentStepStateToSuccess(Number(resultCourseManageAddCurrentStep) - 1))}
                                            >
                                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                    <Icon icon="fluent:arrow-step-back-20-filled" />
                                                    <label style={{ paddingLeft: 10, cursor: "pointer" }}>ย้อนกลับ</label>
                                                </div>
                                            </Button>
                                        </Col>
                                        <Col xs={24} sm={12} md={12} lg={12}>
                                            <Button
                                                style={width > 992 ? { float: 'left', width: 130 } : ((width < 992 && width > 576) ? { width: 130, float: 'left' } : { width: '100%' })}
                                                type="primary"
                                                onClick={() => {
                                                    // set session
                                                    localStorage.setItem("stepsDocumentClickable", 1)

                                                    dispatch(setCourseManageAddCurrentStepStateToSuccess(Number(resultCourseManageAddCurrentStep) + 1))
                                                }}
                                            >
                                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                    <Icon icon="fluent:arrow-next-20-filled" style={{ color: "white" }} />
                                                    <label style={{ paddingLeft: 10, cursor: "pointer" }}>ถัดไป</label>
                                                </div>
                                            </Button>
                                        </Col>
                                    </Row>
                                    :
                                    <Col span={24}>
                                        <center>
                                            {(courseInspect ? courseInspect.courseDocument.inspectionCourseApprovalIdSummary === 2 : false) ?
                                                <Popconfirm
                                                    title="คุณยืนยันบันทึกการปรับเเก้หรือไม่ ?"
                                                    okText={<span style={{ width: 50 }}>ใช่</span>}
                                                    onConfirm={() => {
                                                        if (switchType === "adjust-course") {
                                                            handleAdjustDocument()
                                                        } else if (switchType === "edit-course") {
                                                            handleUpdateDocument("solveBack");
                                                        }
                                                    }}
                                                    cancelText={<span style={{ width: 50 }}>ไม่ใช่</span>}
                                                    disabled={courseInspect.inspectionCourseId === 3 ? false : true}
                                                >
                                                    <Row>
                                                        <Col span={24}>
                                                            <Button
                                                                type="primary"
                                                                disabled={courseInspect.inspectionCourseId === 3 ? false : true}
                                                            >
                                                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                                    <Icon icon="bx:save" style={{ color: "white" }} />
                                                                    <label style={{ paddingLeft: 10, cursor: "pointer" }}>บันทึกการปรับเเก้</label>
                                                                </div>
                                                            </Button>
                                                        </Col>
                                                        <Col span={24}>
                                                            {switchType === "edit-course" && courseInspect.inspectionCourseId === 2 && courseInspect.inspectionCourseDocumentId === 1 ?
                                                                <label style={{ paddingTop: 10 }}>กำลังอยู่ระหว่างการตรวจสอบ ทำให้ไม่สามารถกดปุ่ม "บันทึกการเเก้ไข" ได้ในขณะนี้! จนกว่าจะตรวจสอบเสร็จ เเละเเจ้งผลให้ทราบ</label>
                                                                : []}
                                                        </Col>
                                                    </Row>
                                                </Popconfirm>
                                                :
                                                <>
                                                    {switchType === "edit-course" ?
                                                        <Popconfirm
                                                            title="คุณยืนยันการบันทึกการปรับเเต่งหรือไม่ ?"
                                                            okText={<span style={{ width: 50 }}>ใช่</span>}
                                                            onConfirm={() => {
                                                                handleUpdateDocument(null);
                                                            }}
                                                            cancelText={<span style={{ width: 50 }}>ไม่ใช่</span>}
                                                        >
                                                            <Button type="primary">
                                                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                                    <Icon icon="bx:save" style={{ color: "white" }} />
                                                                    <label style={{ paddingLeft: 10, cursor: "pointer" }}>บันทึกการปรับเเต่ง</label>
                                                                </div>
                                                            </Button>
                                                        </Popconfirm>
                                                        : []}
                                                </>
                                            }
                                        </center>
                                    </Col>
                                }
                            </Col>
                        </Row>
                    </div>

                    {(switchType === "adjust-course" ?
                        (courseInspect ? courseInspect.courseDocument.inspectionCourseApprovalIdSummary === 2 : false)
                        :
                        (switchType === "edit-course" ?
                            (
                                (courseInspect ? (
                                    (courseInspect.courseDocument.inspectionCourseApprovalIdSummary === 2 && courseInspect.inspectionCourseDocumentId === 1 && courseInspect.inspectionCourseId === 3) ||
                                    (courseInspect.amountEdit > 0 && courseInspect.courseDocument.inspectionCourseApprovalIdSummary === 2)
                                ) : false)
                            ) : false
                        )
                    ) ?
                        <div style={{ paddingLeft: 20, paddingRight: 20, paddingBottom: 20, backgroundColor: 'white' }}>
                            <Row style={{ padding: 10, border: '1px solid #EEEEEE', fontSize: 14 }}>
                                <Col span={24} style={{ padding: 10 }}>
                                    ตัวอย่าง เกร็ดความรู้ เเละสิ่งที่ควรหลีกเลี่ยง
                                </Col>
                                <Col span={24}>
                                    <label style={{ paddingLeft: 20 }}>เอกสารเพิ่มเติมไฟล์ภาพหรือ PDF ไม่ควรใส่รหัสผ่าน ภาพควรมีความชัดเจนไม่เบลอ เอกสารเพิ่มเติมจะต้องเกี่ยวข้องกับคอร์สเรียน เเละมีความสอดคล้องกัน ควรหลีกเลี่ยงการนำภาพผู้อื่นมาลง เว้นแต่ได้รับอนุญาตแล้ว </label>
                                </Col>
                            </Row>
                        </div>
                        : []}
                </>
                :
                <center><Spin size="large" style={{ marginTop: 200, marginBottom: 200 }} /></center>
            }
        </>
    )
}
