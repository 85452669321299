import '../../css/mediaIntro.css'
import { useEffect, useState } from 'react'
import moment from 'moment'
import { styled } from '@mui/system'
import { Row, Col, Drawer, Tooltip, Button } from 'antd'
import { Icon } from '@iconify/react'
import { useDimensions } from '../../../../common/mamager/ScreenManager'
import Video from '../../../../common/components/video'
import { PathVideo } from '../../../../common/components/video/components/convert/pathVideo'
import CourseVideo from '../video'

const useStyles = styled((theme) => ({
    menuRight: {
        height: 50,
        width: 55,
        borderRadius: 0,
        marginTop: 1,
        backgroundColor: "#0c0c0c",
        borderBottom: "1px solid rgb(80, 80, 80)",
        "&:hover": {
            backgroundColor: "black",
            borderBottom: "1px solid #c8c8c8"
        }
    },
    menuIconRight: {
        fontSize: '2.5em',
        marginTop: -2,
        marginLeft: -6,
        color: "white",
        "&:hover": {
            color: " #818181"
        }
    }
}))

export default function CourseMediaIntro(props) {

    const classes = useStyles()
    const { width } = useDimensions()
    const [isPauseVideo, setIsPauseVideo] = useState(props.isPauseVideo)
    const [loading, setLoading] = useState(false)

    const [openVideoList, setOpenVideoList] = useState(false)

    const showVideoListDrawer = () => {
        setOpenVideoList(true)
    };

    const onVideoListClose = () => {
        setOpenVideoList(false)
    };

    const sizeLayout_L_R = (type) => {
        let w = width
        let L = w - 55
        let LP = (L * 100) / w
        let RP = 100 - LP
        // console.log("w : ", w, ", LP : ", LP, ", RP : ", RP)
        if (type === "LP") {
            return LP + "%"
        } else if (type === "RP") {
            return RP + "%"
        }
    }

    const handleTimeVideo = (timeVideo, displayTimeVideo) => { }
    const handlePercentWatchVideoCourse = (timeVideoCourse, timerWatchVideoCourse, percent, isFirstLoadApi, VideoCourseList, timerWatchVideoCourseList) => { }
    const handlePlayer = (player) => { }
    const handleControls = (controls) => { }

    const reloadOderPayment = (data) => { }

    useEffect(() => {
        setLoading(true)

        setIsPauseVideo(props.isPauseVideo)

        setLoading(false)
    }, [props.isPauseVideo])

    return (
        <>
            <Row className="course-introVideo-container">
                <Col span={24}>
                    {!loading ?
                        <div>
                            <div style={{ float: "left", width: (width < 992 ? sizeLayout_L_R("LP") : "100%") }}>
                                <Video
                                    videoTitle={props.courseMediaIntro?.courseMediaIntroTitleName}
                                    videoLocation={PathVideo(props.courseMediaIntro?.courseMediaIntroLocation)}

                                    courseId={null}
                                    learnerId={null}

                                    component={'course-intro-video-board'}
                                    height={454}
                                    loop={true}
                                    isPlaying={true}
                                    isBreak={isPauseVideo}
                                    timerRunning={true}

                                    handleTimeVideo={handleTimeVideo}
                                    handlePercentWatchVideoCourse={handlePercentWatchVideoCourse}
                                    handlePlayer={handlePlayer}
                                    handleControls={handleControls}
                                />
                            </div>

                            {width < 992 ?
                                <div
                                    style={{
                                        float: "left",
                                        width: sizeLayout_L_R("RP"),
                                        backgroundColor: "rgb(25, 25, 25)",
                                        height: "100%"
                                    }}
                                >
                                    <Row>
                                        <Col span={24}>
                                            <Tooltip placement="left" title={"เนื้อหาคอร์ส"}>
                                                <Button
                                                    type="text"
                                                    className={classes.menuRight}
                                                    onClick={() => showVideoListDrawer()}
                                                >
                                                    <Icon
                                                        icon="iconoir:media-video-list"
                                                        className={classes.menuIconRight}
                                                        onClick={() => showVideoListDrawer()}
                                                    />
                                                </Button>
                                            </Tooltip>
                                        </Col>
                                    </Row>
                                </div>
                                : []
                            }
                        </div>
                        : []
                    }
                </Col>

                <Col span={24} style={{ paddingBottom: 12, paddingLeft: 12, paddingTop: 12 }}>
                    <label style={{ paddingRight: 20, height: 0 }}>เข้าชม {Intl.NumberFormat('en').format(Number(props.course?.courseVisit))} ครั้ง </label>
                    <label style={{ height: 0 }}> เเก้ไขล่าสุด {moment(props.course?.courseUpdate).add(543, "year").format('DD/MM/YYYY')}</label>
                </Col>
            </Row>
            
            <Drawer
                title="เนื้อหาคอร์ส"
                placement="right"
                onClose={onVideoListClose}
                visible={openVideoList}
                width={width > 768 ? "60%" : ((width >= 576 && width < 768) ? "70%" : "85%")}
            >
                <div style={{ backgroundColor: "rgb(25, 25, 25)", height: "100%" }}>
                    <CourseVideo
                        coureVideoGroup={props.coureVideoGroup}
                        course={props.course}
                        reloadOderPayment={reloadOderPayment}
                        handlePauseVideo={props.handlePauseVideo}
                        isCourseHasBeenPurchased={props.isCourseHasBeenPurchased}
                        pageName="course-shop"
                    />
                </div>
            </Drawer>
        </>
    )
}