import { server } from "../../../constants"
import { httpClient } from "../../../utils/HttpClient"

const insertUserFetch = async (value) => {
    try {
        const result = await httpClient.post(server.INSERT_USER_URL, { value })
        if (result.data.isSuccess) {
            return result.data
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const insertNaturalPersonFetch = async (value) => {
    try {
        const result = await httpClient.post(server.INSERT_NATURAL_PERSON_URL, { value })
        if (result.data.isSuccess) {
            return result.data
        } else {
            return null
        }
    } catch (err) { // status 404
        return err
    }
}

const insertJuristicPersonFetch = async (value) => {
    try {
        const result = await httpClient.post(server.INSERT_JURISTIC_PERSON_URL, { value })
        if (result.data.isSuccess) {
            return result.data
        } else {
            return null
        }
    } catch (err) { // status 404
        return err
    }
}

const getDetectUsernameDuplicateFetch = async (userName) => {
    try {
        const result = await httpClient.post(server.GET_DETECT_USERNAME_DUPLICATE_URL, { userName })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getDetectEmailDuplicateFetch = async (email) => {
    try {
        const result = await httpClient.post(server.GET_DETECT_EMAIL_DUPLICATE_URL, { email })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getDetectPhoneDuplicateFetch = async (phone) => {
    try {
        const result = await httpClient.post(server.GET_DETECT_PHONE_DUPLICATE_URL, { phone })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getCityCategoryFetch = async (value) => {
    try {
        const result = await httpClient.post(server.GET_CITY_CATEGORY_URL, { value })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getStateCategoryFetch = async (value) => {
    try {
        const result = await httpClient.post(server.GET_STATE_CATEGORY_URL, { value })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getCountryCategoryFetch = async (value) => {
    try {
        const result = await httpClient.post(server.GET_COUNTRY_CATEGORY_URL, { value })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

export {
    // get
    getDetectUsernameDuplicateFetch,
    getDetectEmailDuplicateFetch,
    getDetectPhoneDuplicateFetch,
    getCityCategoryFetch,
    getStateCategoryFetch,
    getCountryCategoryFetch,

    // insert
    insertUserFetch,
    insertNaturalPersonFetch,
    insertJuristicPersonFetch,

    // update

    // delete

}