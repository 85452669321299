/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import "../header/css/index.css"

import { useState, useEffect, useRef } from "react"
import { useNavigate, useLocation } from "react-router-dom"
import { scroller } from "react-scroll"
import {
	Drawer,
	Button,
	Row,
	Col,
	Menu,
	Avatar
} from 'antd'
import { BsFillBellFill } from "react-icons/bs"
import { Icon } from '@iconify/react'
import ContractUs from "../../../common/components/contractUs"
import { useDimensions } from "../../mamager/ScreenManager"
import { getCheckUserIdOnDBFetch } from "../../../modules/home/API"
import { getUserByUidFetch } from "./API"
import { getAccountVerifyByIdFetch } from "../../../modules/account/API"
import VerifyIdentityIdCard from "../verify/idCard/VerifyIdentityIdCard"
import { detectSignIn } from "../../mamager/DetectManager"

const SubMenu = Menu.SubMenu

export default function Header(props) {
	const navigate = useNavigate()
	const location = useLocation()

	let queryMessage
	const [userIdActiveOnDB, setUserIdActiveOnDB] = useState(null)
	const [userInformationLoading, setUserInformationLoading] = useState(true)
	const [userInformation, setUserInformation] = useState({ userTypeId: 2 })
	const [chatReminder, setChatReminder] = useState()
	const [chatAmount, setChatAmount] = useState([])
	const [isSignIn, setIsSignIn] = useState(false)

	const courseIdRef = useRef()
	const headerIdRef = useRef()
	const toMemberRef = useRef()

	const [isModalContractUsVisible, setIsModalContractUsVisible] = useState(false)

	const { width } = useDimensions()

	const [loading, setLoading] = useState(true)

	const [state, setState] = useState({
		current: 'mail',
		visible: false
	})

	const [accountVerify, setAccountVerify] = useState(null)

	const showDrawer = () => {
		setState({
			visible: true
		})
	}

	const onClose = () => {
		setState({
			visible: false
		})
	}

	const showContractUsModal = () => {
		setIsModalContractUsVisible(true)
	}

	const handleContractUsCancel = () => {
		setIsModalContractUsVisible(false)
	}

	const [modalVerifyIdentityIdCardActive, setModalVerifyIdentityIdCardActive] = useState(false)

	const handleVerifyIdentityIdCardModal = () => {
		setModalVerifyIdentityIdCardActive(!modalVerifyIdentityIdCardActive)
	}

	const handleChatReminder = async (clickTab, db) => {
		if (props.user?.uid) {
			queryMessage = await db.collection("chat_message")
			queryMessage = await queryMessage.where("chatToMemberId", "==", props.user.uid)
			queryMessage = await queryMessage.where("chatMessageIsReadStatus", "==", false)
			queryMessage = await queryMessage.onSnapshot((querySnapshot) => {
				const dataMessageRead = querySnapshot.docs.map((doc) => ({
					...doc.data(),
					id: doc.id,
				}))

				// update reminder
				if (dataMessageRead.length > 0) {
					setChatReminder(true)
				} else {
					setChatReminder(false)
				}

				setChatAmount(dataMessageRead?.length)

				if (dataMessageRead.length > 0) {
					courseIdRef.current = dataMessageRead[0].chatCourseId
					toMemberRef.current = dataMessageRead[0].chatToMemberId
					headerIdRef.current = dataMessageRead[0].chatMessageHeaderId
				}

				// update read message
				if (clickTab === true && dataMessageRead.length > 0) {
					// console.log("update read [board chat] of : ", props.user.uid)
				}
			})
		}
	}

	const checkUserIdOnDB = async () => {
		if (props.firebase?.auth()?.currentUser?.uid) {
			const result = await getCheckUserIdOnDBFetch(props.firebase.auth().currentUser.uid)
			setUserIdActiveOnDB(result) // true = have uid in database
		} else {
			setUserIdActiveOnDB(false)
		}
	}

	const getUserInformation = async () => {
		setUserInformationLoading(true)

		if (props.user?.uid) {
			const result = await getUserByUidFetch(props.user ? props.user.uid : null)
			setUserInformation(result)
		}

		setUserInformationLoading(false)
	}

	const genProfileDisplay = () => {
		return (
			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					cursor: "pointer"
				}}
				onClick={() => {
					navigate("/account?tab=profile")
				}}
			>
				<Avatar
					style={{ float: "right", marginRight: 20, border: "1px solid #E5E5E5" }}
					className="mt-1"
					size={40}
					src={userInformation.imageProfile ? userInformation.imageProfile : `./assets/images/profile/personDefault.jpg`}
				/>

				<div style={{ display: (width > 950 ? 'block' : 'none'), float: "right", paddingRight: 18 }}>
					{userInformation.userName ? userInformation.userName : "-"}
				</div>
			</div>
		)
	}

	const genReminder = () => {
		return (
			<>
				{chatAmount > 0 ?
					<div
						style={{ cursor: "pointer", display: 'flex', alignItems: 'center', paddingRight: 12 }}
						onClick={async () => {
							const isLogin = await detectSignIn()
							if (isLogin) {
								if (headerIdRef.current === props.user.uid) {
									localStorage.setItem("toggleTabChat", 1) // off snap

									navigate("/chat-teacher" +
										"?course-id=" + courseIdRef.current +
										"&course-owner=" + headerIdRef.current +
										"&to-member=" + toMemberRef.current,
									)
								} else {
									// console.log("go to chat learner");
									localStorage.setItem("toggleTabChat", 1); // on snap

									navigate("/chat-learner" +
										"?course-id=" + courseIdRef.current +
										"&course-owner=" + headerIdRef.current +
										"&to-member=" + toMemberRef.current,
									)
								}
							}
						}}
					>
						<BsFillBellFill style={{ color: "green" }} />
						<span style={{ paddingLeft: 3 }}>{chatAmount}</span>
					</div>
					: []
				}
			</>
		)
	}

	const genRightMenu = () => {
		return (
			<Menu mode="inline">
				{location.pathname !== "/home" ?
					<Menu.Item
						key="4.0"
						onClick={() => navigate("/home")}
					>
						ไปหน้า Home
					</Menu.Item>
					: []}

				{location.pathname !== "/account" ?
					<Menu.Item
						key="9.9"
						onClick={() => {
							navigate("/account?tab=profile")
						}}
					>
						บัญชีของฉัน
					</Menu.Item>
					: []}

				<Menu.Item
					key="4.4"
					onClick={() => {
						props.firebase.auth().signOut()
						navigate("/login")
					}}
				>
					ออกจากระบบ
				</Menu.Item>
			</Menu>
		)
	}

	const genLeftMenu = () => {
		return (
			<Menu
				mode={width > 950 ? "horizontal" : "inline"}
				style={{ height: 47.5 }}
			>
				<Menu.Item
					key="1"
					onClick={() => {
						let path = location.pathname
						if (path === "/" || path === "/home") {
							scroller.scrollTo("pointview", {
								duration: 1500,
								delay: 100,
								smooth: true,
								offset: -210,
							});
						} else {
							navigate("/pointview")
						}
					}}
				>
					ความตั้งใจของเรา
				</Menu.Item>

				<SubMenu
					key="2"
					title="ผู้เรียน"
				>
					<Menu.Item
						key="2.1"
						onClick={() => navigate("/inspect-certificate")}
					>ตรวจสอบ Certificate</Menu.Item>

					<Menu.Item
						key="2.2"
						onClick={() => navigate("/classroom-course")}
					>คอร์สของฉัน</Menu.Item>
				</SubMenu>

				<SubMenu
					key="3"
					title="ผู้สอน"
				>
					<Menu.Item
						key="3.1"
						onClick={async () => {
							const isLogin = await detectSignIn()
							if (isLogin) {
								const result = await getAccountVerifyByIdFetch(props.user.uid)
								// console.log("getAccountVerifyByIdFetch : ", result)
								setAccountVerify(result)
								if (result) {
									if (result.isAccountVerifiedIdentify && result.isAccountVerifiedBank) {
										navigate("/registration-open-course")
									} else { // is open modal 
										setModalVerifyIdentityIdCardActive(true)
									}
								}
							} else {
								// Go to login page
								navigate("/login")
							}
						}}
					>เปิดสอนคอร์สเรียน</Menu.Item>

					{userInformation ?
						userInformation.userTypeId === 2 ?
							<Menu.Item
								key="4.2"
								onClick={() => navigate("/course-manage")}
							>จัดการคอร์ส</Menu.Item>
							: []
						: []}
				</SubMenu>

				<Menu.Item key="4" onClick={showContractUsModal}>
					ติดต่อ
				</Menu.Item>

				{width < 951 ?
					<Menu.Item
						key="5"
						onClick={() => {
							props.firebase.auth().signOut()
							navigate("/login")
						}}
					>
						ออกจากระบบ
					</Menu.Item>
					: []
				}

			</Menu>
		)

	}

	const getDetectSignIn = async () => {
		let resultDetectSign = await detectSignIn()
		// console.log("resultDetectSign : ", resultDetectSign)
		setIsSignIn(resultDetectSign)
	}

	const getBaseApi = async () => {
		setLoading(true)

		const isLogin = await detectSignIn()

		await getDetectSignIn()
		await getUserInformation()

		if (props.btnAutnActive === undefined) {
			if (userIdActiveOnDB === null) {
				await checkUserIdOnDB()
			}

			try {
				if (isLogin) {
					await handleChatReminder(false, props.firebase.firestore())
				}
			} catch (err) {
				// console.log(err)
			}
		}

		setLoading(false)
	}

	useEffect(() => {
		getBaseApi()
	}, [])

	return (
		<header>
			<div style={{ height: 42 }}>
				<div
					style={{
						paddingLeft: 0,
						// paddingRight: 20,
						paddingTop: 0,
						paddingBottom: 0,
						position: 'fixed',
						zIndex: 2,
						width: '100%',
						backgroundColor: '#fff',
						overflow: 'hidden',
						boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.2)',
						height: 50,
						// marginTop: -8
					}}
				>
					{!loading ?
						<>
							<Row>
								<Col xs={12} sm={12} md={5} lg={4} xl={3} xxl={3} style={{ height: 50 }} >
									<img
										src={`./assets/images/logo/toflynAddName.png`}
										style={{ width: 147, marginLeft: 15, padding: 3, cursor: 'pointer' }}
										onClick={() => {
											if (isSignIn) {
												navigate("/home")
											} else {
												navigate("/")
											}
										}}
									/>
								</Col>

								<Col xs={12} sm={12} md={19} lg={20} xl={21} xxl={21} style={{ height: 50 }} >
									<Row>
										{width > 950 ?
											<Col
												span={12}
												style={{ paddingTop: 0, paddingBottom: 0, height: 50 }}
											>
												<div
													style={{
														display: (width > 950 ? 'block' : 'none'),
														paddingTop: 2
													}}
												>
													{genLeftMenu()}
												</div>
											</Col>
											: []
										}

										<Col
											span={width > 950 ? 12 : 24}
											style={{ paddingTop: 0, paddingBottom: 0, height: 50 }}
										>

											<>
												{!userInformationLoading ? (
													<>
														{props.btnAutnActive === undefined ?
															<>
																{isSignIn && userIdActiveOnDB ?
																	<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
																		{genProfileDisplay()}

																		{chatReminder ? genReminder() : []}

																		<Drawer
																			title={<label style={{ fontSize: 16, fontWeight: 100 }}>Toflyn</label>} placement="right"
																			closable={false}
																			onClose={onClose}
																			visible={state.visible}
																			bodyStyle={{ padding: '0px' }}
																			size="default"
																		>
																			<div style={{ display: (width > 950 ? 'none' : 'block'), padding: 0 }}>
																				{genLeftMenu()}
																			</div>

																			<div style={{ display: (width <= 950 ? 'none' : 'block'), padding: 0 }}>
																				{genRightMenu()}
																			</div>
																		</Drawer>

																		<Button
																			type="primary"
																			onClick={showDrawer}
																			style={{
																				height: 50,
																				width: 55,
																				borderRadius: 0
																			}}
																		>
																			<Icon
																				icon="charm:menu-hamburger"
																				style={{
																					fontSize: '2.5em',
																					marginTop: -2,
																					marginLeft: -6,
																					color: "white"
																				}}
																			/>
																		</Button>
																	</div>
																	:
																	<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', height: "100%", paddingLeft: 10, paddingRight: 10 }}>
																		<Button type="text" onClick={() => navigate("/login")}>
																			เข้าสู่ระบบ
																		</Button>
																	</div>
																}
															</>
															: []
														}
													</>
												) : (
													[]
												)}
											</>
										</Col>
									</Row>
								</Col>
							</Row>

							<ContractUs
								isModalContractUsVisible={isModalContractUsVisible}
								handleContractUsCancel={handleContractUsCancel}
							/>

							<VerifyIdentityIdCard
								modalVerifyIdentityIdCardActive={modalVerifyIdentityIdCardActive}
								handleVerifyIdentityIdCardModal={handleVerifyIdentityIdCardModal}
								businessOperationId={accountVerify ? accountVerify.businessOperationId : null}
								user={props.user ? props.user : null}
								providerId={userInformation ? userInformation.providerId : null}
							/>
						</>
						: []}
				</div>
			</div>
		</header>
	)
}