/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from 'react'
import {
    Form,
    Select,
    Button,
    Row,
    Col,
    Card,
} from 'antd'
import { useDispatch, useSelector } from "react-redux"
import { Icon } from '@iconify/react'
import { setRegisterCourseCategoryInterestSuccess } from '../../../../actions/registerCourseCategoryInterest.action'
import { setRegisterCurrentStepStateToSuccess } from '../../../../actions/registerCurrentStep.action'
import { getCourseCategoryFetch } from '../../../course/API'

const { Option } = Select

export default function CourseCategoryInterest(props) {
    const [form] = Form.useForm()

    const dispatch = useDispatch()

    const [courseCategory1, setCourseCategory1] = useState([])
    const [courseCategory2, setCourseCategory2] = useState([])
    const [courseCategory3, setCourseCategory3] = useState([])

    const { resultRegisterCurrentStep } = useSelector(({ registerCurrentStepReducer }) => registerCurrentStepReducer)
    const { resultCourseCategoryInterest } = useSelector(({ registerCourseCategoryInterestReducer }) => registerCourseCategoryInterestReducer)

    let language = "th"

    const [courseCategory, setCourseCategory] = useState([])

    const initial = {
        courseCategoryInterestedRank1: resultCourseCategoryInterest.courseCategoryInterestedRank1,
        courseCategoryInterestedRank2: resultCourseCategoryInterest.courseCategoryInterestedRank2,
        courseCategoryInterestedRank3: resultCourseCategoryInterest.courseCategoryInterestedRank3,
    }

    const onFinish = async (values) => {
        dispatch(setRegisterCourseCategoryInterestSuccess(values))
        dispatch(setRegisterCurrentStepStateToSuccess(Number(resultRegisterCurrentStep) + 1))
    }

    const detectCourseCategory = (value, rank) => {
        let tempCourseCategory = courseCategory

        if (rank === 2 || rank === 3) {
            let rank2 = form.getFieldValue('courseCategoryInterestedRank2')
            let rank3 = form.getFieldValue('courseCategoryInterestedRank3')

            if (rank2) {
                tempCourseCategory = tempCourseCategory.filter(item => item.value !== rank2)
            }

            if (rank3) {
                tempCourseCategory = tempCourseCategory.filter(item => item.value !== rank3)
            }

            setCourseCategory1(tempCourseCategory)
        }

        if (rank === 1 || rank === 3) {
            let rank1 = form.getFieldValue('courseCategoryInterestedRank1')
            let rank3 = form.getFieldValue('courseCategoryInterestedRank3')

            if (rank1) {
                tempCourseCategory = tempCourseCategory.filter(item => item.value !== rank1)
            }

            if (rank3) {
                tempCourseCategory = tempCourseCategory.filter(item => item.value !== rank3)
            }

            setCourseCategory2(tempCourseCategory)
        }

        if (rank === 1 || rank === 2) {
            let rank1 = form.getFieldValue('courseCategoryInterestedRank1')
            let rank2 = form.getFieldValue('courseCategoryInterestedRank2')

            if (rank1) {
                tempCourseCategory = tempCourseCategory.filter(item => item.value !== rank1)
            }

            if (rank2) {
                tempCourseCategory = tempCourseCategory.filter(item => item.value !== rank2)
            }

            setCourseCategory3(tempCourseCategory)
        }
    }

    const getCourseCategory = async () => {
        if (courseCategory.length === 0) {
            const result = await getCourseCategoryFetch(language)
            setCourseCategory(result)
            setCourseCategory1(result)
            setCourseCategory2(result)
            setCourseCategory3(result)
        }
    }

    useEffect(() => {
        getCourseCategory()
    }, []);


    return (
        <>
            <Row>
                <Col span={24}>
                    <Card style={{ height: 290 }}>
                        <Row>
                            <Col xs={24} xl={12}>
                                <Form form={form} layout="vertical" onFinish={onFinish} initialValues={initial}>
                                    <Form.Item
                                        label="หมวดหมู่คอร์สที่ชื่นชอบ อันดับ 1"
                                        name="courseCategoryInterestedRank1"
                                        rules={[{ required: true, message: 'กรุณาเลือก หมวดหมู่คอร์สที่ชื่นชอบ อันดับ 1' }]}
                                    >
                                        <Select
                                            showSearch
                                            placeholder="อันดับ 1"
                                            optionFilterProp="children"
                                            style={{ width: '100%' }}
                                            allowClear
                                            onChange={(e) => {
                                                form.setFieldsValue({ courseCategoryInterestedRank2: undefined })
                                                form.setFieldsValue({ courseCategoryInterestedRank3: undefined })
                                                detectCourseCategory(e, 1)
                                            }}
                                        >
                                            {courseCategory1.map((val, index) =>
                                                <Option key={index} value={val.value}>{val.label}</Option>
                                            )}
                                        </Select>
                                    </Form.Item>
                                </Form>

                                <Form form={form} layout="vertical" onFinish={onFinish} initialValues={initial}>
                                    <Form.Item
                                        label="หมวดหมู่คอร์สที่ชื่นชอบ อันดับ 2"
                                        name="courseCategoryInterestedRank2"
                                        rules={[{ required: true, message: 'กรุณาเลือก หมวดหมู่คอร์สที่ชื่นชอบ อันดับ 2' }]}
                                    >
                                        <Select
                                            showSearch
                                            placeholder="อันดับ 2"
                                            optionFilterProp="children"
                                            style={{ width: '100%' }}
                                            allowClear
                                            onChange={(e) => {
                                                form.setFieldsValue({ courseCategoryInterestedRank3: undefined });
                                                detectCourseCategory(e, 2);
                                            }}
                                            disabled={form.getFieldValue('courseCategoryInterestedRank1') === undefined ? true : false}
                                        >
                                            {courseCategory2.map((val, index) =>
                                                <Option key={index} value={val.value}>{val.label}</Option>
                                            )}
                                        </Select>
                                    </Form.Item>
                                </Form>

                                <Form form={form} layout="vertical" onFinish={onFinish} initialValues={initial}>
                                    <Form.Item
                                        label="หมวดหมู่คอร์สที่ชื่นชอบ อันดับ 3"
                                        name="courseCategoryInterestedRank3"
                                        rules={[{ required: true, message: 'กรุณาเลือก หมวดหมู่คอร์สที่ชื่นชอบ อันดับ 3' }]}
                                    >
                                        <Select
                                            showSearch
                                            placeholder="อันดับ 3"
                                            optionFilterProp="children"
                                            style={{ width: '100%' }}
                                            allowClear
                                            onChange={(e) => {
                                                detectCourseCategory(e, 3);
                                            }}
                                            disabled={form.getFieldValue('courseCategoryInterestedRank1') === undefined || form.getFieldValue('courseCategoryInterestedRank2') === undefined ? true : false}
                                        >
                                            {courseCategory3.map((val, index) =>
                                                <Option key={index} value={val.value}>{val.label}</Option>
                                            )}
                                        </Select>
                                    </Form.Item>
                                </Form>
                            </Col>
                        </Row>
                    </Card>
                </Col>

                <Col span={24} style={{ paddingTop: 15 }}>
                    <Button
                        style={{ float: 'left', width: 130 }}
                        type="default"
                        onClick={() => dispatch(setRegisterCurrentStepStateToSuccess(Number(resultRegisterCurrentStep) - 1))}
                    >
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Icon icon="fluent:arrow-step-back-20-filled" />
                            <label style={{ paddingLeft: 10, cursor: "pointer" }}>ย้อนกลับ</label>
                        </div>
                    </Button>

                    <Button
                        style={{ float: 'right', width: 130 }}
                        type="primary"
                        onClick={() => form.submit()}
                    >
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Icon icon="ic:baseline-app-registration" style={{ color: "white" }} />
                            <label style={{ paddingLeft: 10, cursor: "pointer" }}>สมัครสมาชิก</label>
                        </div>
                    </Button>
                </Col>
            </Row>
        </>
    )
}