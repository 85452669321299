import "./styles/App.css"
import "./styles/App.less"

import Announce from "./common/components/announce"
import Certificate from "./common/components/certificate"
import ChatLearner from "./common/components/chat/ChatLearner"
import ChatTeacher from "./common/components/chat/ChatTeacher"
import PointView from "./common/components/pointView"
import Results from "./common/components/result"
import CourseAdjust from "./modules/courseMamage/adjust"
import CourseCreate from "./modules/courseMamage/create"
import CourseEdit from "./modules/courseMamage/edit"
import CourseManage from "./modules/courseMamage"
import CourseList from "./modules/courseList"
import CourseShop from "./modules/coursShop"
import Classroom from "./modules/classroom"
import ClassroomStudy from "./modules/classroomStudy"
import RequestOpenCourse from "./modules/requestOpenCourse"
import InspectCourse from "./modules/erp/inspect/course"
import ERP from "./modules/erp"
import LoginERP from "./modules/erp/manage/login"
import PasswordReset from "./modules/login/components/passwordReset"
import Login from "./modules/login"
import Home from "./modules/home"
import Welcome from "./modules/home/Welcome"
import CourseMonitor from "./modules/monitor"
import OrderReceived from "./modules/payment/components/orderReceived"
import Payment from "./modules/payment"
import Register from "./modules/register"
import Review from "./modules/review"
import ToflynPolicy from "./modules/policy"
import PrivacyPolicy from "./modules/policy/components/privacyPolicy"
import RefundPolicy from "./modules/policy/components/refundPolicy"
import ServiceCancelPolicy from "./modules/policy/components/serviceCancelPolicy"
import TermsBeingInstructor from "./modules/policy/components/termsBeingInstructor"
import TermsOfUse from "./modules/policy/components/termsOfUse"
import School from "./modules/school"
import GuidePlatform from "./modules/tutorial/platform/GuidePlatform"
import GuideRegister from "./modules/tutorial/platform/GuideRegister"
import GuidePayment from "./modules/tutorial/platform/GuidePayment"
import Account from "./modules/account"
import LoginDetect from "./modules/login/components/loginDetect"
import Salepage from "./modules/salepage"
import ManageSalepage from "./modules/courseMamage/salepage"
import { ConfigProvider } from 'antd'
import {
    BrowserRouter as Router,
    Routes,
    Route
} from "react-router-dom"
import { ThemeProvider } from '@mui/material/styles'
import themeMUI from "./styles/themeMUI"
import themeAntd from './styles/themeAntd'

const App = ({ authorized, firebase, user }) => (
    <ConfigProvider theme={{ token: themeAntd }}>
        <ThemeProvider theme={themeMUI}>
            <Router>
                <Routes>
                    <Route exact path="/erp-login" Component={() => (<LoginERP />)} />
                    <Route exact path="/erp-manage" Component={ERP} />
                    <Route exact path="/" element={<Welcome authorized={authorized} firebase={firebase} user={user} />} />
                    <Route exact path="/login" Component={() => (<Login authorized={authorized} firebase={firebase} user={user} />)} />
                    <Route exact path="/login-detect" Component={() => (<LoginDetect authorized={authorized} firebase={firebase} user={user} />)} />
                    <Route exact path="/home" element={<Home authorized={authorized} firebase={firebase} user={user} />} />
                    <Route exact path="/account" Component={() => (<Account authorized={authorized} firebase={firebase} user={user} />)} />
                    <Route exact path="/register" Component={Register} />
                    <Route exact path="/course-shop" Component={() => (<CourseShop authorized={authorized} firebase={firebase} user={user} />)} />
                    <Route exact path="/review" Component={() => (<Review authorized={authorized} firebase={firebase} user={user} />)} />
                    <Route exact path="/payment" Component={() => (<Payment authorized={authorized} firebase={firebase} user={user} />)} />
                    <Route exact path="/classroom-course-study" Component={() => (<ClassroomStudy authorized={authorized} firebase={firebase} user={user} />)} />
                    <Route exact path="/inspect-certificate" Component={() => (<Certificate authorized={authorized} firebase={firebase} user={user} />)} />
                    <Route exact path="/classroom-course" Component={() => (<Classroom authorized={authorized} firebase={firebase} user={user} />)} />
                    <Route exact path="/registration-open-course" Component={() => (<RequestOpenCourse authorized={authorized} firebase={firebase} user={user} />)} />
                    <Route exact path="/course-manage" Component={() => (<CourseManage authorized={authorized} firebase={firebase} user={user} />)} />
                    <Route exact path="/course-monitor" Component={() => (<CourseMonitor authorized={authorized} firebase={firebase} user={user} />)} />
                    <Route exact path="/result" Component={Results} />
                    <Route exact path="/erp-inspect-course" Component={InspectCourse} />
                    <Route exact path="/course-manage-edit" Component={() => (<CourseEdit authorized={authorized} firebase={firebase} user={user} />)} />
                    <Route exact path="/course-manage-adjust" Component={() => (<CourseAdjust authorized={authorized} firebase={firebase} user={user} />)} />
                    <Route exact path="/course-manage-add" Component={() => (<CourseCreate authorized={authorized} firebase={firebase} user={user} />)} />
                    <Route exact path="/course-manage-salepage" Component={() => (<ManageSalepage authorized={authorized} firebase={firebase} user={user} />)} />
                    <Route exact path="/course-list" Component={() => (<CourseList authorized={authorized} firebase={firebase} user={user} />)} />
                    <Route exact path="/chat-teacher" Component={() => (<ChatTeacher authorized={authorized} firebase={firebase} user={user} />)} />
                    <Route exact path="/chat-learner" Component={() => (<ChatLearner authorized={authorized} firebase={firebase} user={user} />)} />
                    <Route exact path="/announce" Component={() => (<Announce authorized={authorized} firebase={firebase} user={user} />)} />
                    <Route exact path="/school" Component={() => (<School authorized={authorized} firebase={firebase} user={user} />)} />
                    <Route exact path="/pointview" Component={() => (<PointView firebase={firebase} user={user} />)} />
                    <Route exact path="/order-received" Component={() => (<OrderReceived authorized={authorized} firebase={firebase} user={user} />)} />
                    <Route exact path="/toflyn-policy" Component={() => (<ToflynPolicy firebase={firebase} user={user} />)} />
                    <Route exact path="/privacy-policy" Component={() => (<PrivacyPolicy firebase={firebase} user={user} />)} />
                    <Route exact path="/service-cancel-policy" Component={() => (<ServiceCancelPolicy firebase={firebase} user={user} />)} />
                    <Route exact path="/refund-policy" Component={() => (<RefundPolicy firebase={firebase} user={user} />)} />
                    <Route exact path="/terms-being-instructor" Component={() => (<TermsBeingInstructor firebase={firebase} user={user} />)} />
                    <Route exact path="/terms-of-use" Component={() => (<TermsOfUse firebase={firebase} user={user} />)} />
                    <Route exact path="/password-reset" Component={() => (<PasswordReset firebase={firebase} />)} />
                    <Route exact path="/guide-platform" Component={() => (<GuidePlatform authorized={authorized} firebase={firebase} user={user} />)} />
                    <Route exact path="/guide-register" Component={() => (<GuideRegister authorized={authorized} firebase={firebase} user={user} />)} />
                    <Route exact path="/guide-payment" Component={() => (<GuidePayment authorized={authorized} firebase={firebase} user={user} />)} />
                    <Route exact path="/salepage" Component={() => (<Salepage authorized={authorized} firebase={firebase} user={user} />)} />
                    <Route path="/:id">Page not found!</Route>
                </Routes>
            </Router>
        </ThemeProvider>
    </ConfigProvider>
)

export default App
