import React from 'react'

export default function ServiceCancelPolicyItem(props) {
    return (
        <>
            <p style={{ textAlign: "center", fontSize: props.headerSize }}><strong>นโยบายการยกเลิกการใช้บริการ</strong></p>

            <span style={{ fontWeight: "bold" }}>การยกเลิกคอร์สเรียน</span><br />
            <p style={{ paddingBottom: 0, marginBottom: 0 }}><span>อายุของคอร์สเรียนที่ผู้เรียนใช้บริการ เเบ่งออกเป็น 2 เเบบ ได้เเก่</span></p>
            <p style={{ paddingBottom: 0, marginBottom: 0 }}><span>1. เเบบตลอดชีพ จะเป็นการซื้อคอร์สเพียงครั้งเดียวสามารถเรียนได้ตลอดไป เเม้ผู้สอนจะมีการอัปเดตข้อมูล หรือมีการเพิ่มเนื้อหาคอร์สเรียน ก็ยังสามารถเข้าถึงเเละใช้งานได้ปกติ โดยเเบบตลอดชีพนี้จะไม่สามารถยกเลิกคอร์สเรียนได้ เเละจะติดอยู่กับบัญชีของผู้เรียนไปตลอด จนกว่าจะมีการปิดบัญชี ที่อาจเกิดการทำผิดเงื่อนไขการให้บริการ หรือผู้เรียนต้องการลบบัญชีออกจากเเพลตฟอร์ม Toflyn</span></p>
            <p><span>2. เเบบรายปี เเบบราย 6 เดือน เเละเเบบราย 3 เดือน คอร์สเรียนดังกล่าวจะมีอายุตามระยะเวลาที่กำหนด เเละเมื่อเรียนครบกำหนดจะไม่สามารถเข้าไปเรียนในคอร์สได้ เเละจะต้องมีการต่ออายุการเรียน โดยอิงจากราคาปัจจุบัน ณ วันที่ซื้อ เพื่อใช้งานต่อ หรือผู้เรียนสามารถยกเลิกคอร์สเรียนเเบบรายปีได้ทุกเมื่อที่ “หน้าคอร์สเรียน” ระบบจะทำการยกเลิกให้ทันทีเเบบอัตโนมัติ เเม้ว่าจะยังเรียนไม่ครบปี ทางเราจะไม่มีการคืนเงินใด ๆ ในส่วนนี้</span></p>
            
            <span style={{ fontWeight: "bold" }}>การยกเลิกลงโฆษณา</span><br />
            <p><span style={{}}>โฆษณาจะมีอายุตามระยะเวลาที่ได้ทำสัญญากันระหว่างเเพลตฟอร์ม Toflyn กับผู้ลงโฆษณา เมื่อถึงวันสุดท้าย โฆษณาดังกล่าวจะหยุดการเผยเเพร่ สถานะจะถือเป็นยกเลิกบริการ จนกว่าจะมีการต่อสัญญาครั้งใหม่ เเต่หากเป็นการยกเลิกเผยเเพร่โฆษณาระหว่างที่ยังไม่หมดสัญญา ทางผู้ลงโฆษณาสามารถยกเลิกได้ทุกเมื่อ เเต่จะไม่มีการคืนเงินจากระยะเวลาที่เหลือโฆษณา</span></p>

            <span style={{ fontWeight: "bold" }}>การยกเลิกเป็นผู้สอน</span><br />
            <p><span style={{}}>ผู้สอนสามารถเเจ้งยกเลิกเป็นผู้สอนผ่านทางอีเมลนี้ </span><a href = "mailto: support@toflyn.com">support@toflyn.com</a><span style={{}}> โดยการยกเลิกเป็นผู้สอนจะเเบ่งออกเป็น 3 กรณี ดังนี้</span></p>
            <p><span style={{}}>กรณีที่ 1 เป็นผู้สอนที่เคยลงทะเบียนเปิดคอร์สเเล้ว เเต่ยังไม่มีคอร์สใดผ่านการตรวจสอบ หากต้องการยกเลิกเป็นผู้สอน สามารถติดต่อเรา จากช่องทางติดต่อดังกล่าว เมื่อทางเราได้รับแจ้งจากผู้สอนแล้ว เราจะทำการยกเลิกเป็นผู้สอนให้ โดยใช้ระยะเวลาดำเนินการภายใน 7 วันทำการ พร้อมกับเเจ้งผลการยกเลิกให้ผู้สอนทราบทางอีเมล เเละโทรศัพท์</span></p>
            <p><span style={{}}>กรณีที่ 2 เป็นผู้สอนที่มีคอร์สผ่านการตรวจสอบ ออกสู่สาธารณะเเล้ว เเละยังไม่มีผู้เข้าเรียนเลย หากต้องการยกเลิกเป็นผู้สอน สามารถติดต่อเรา จากช่องทางติดต่อดังกล่าว เมื่อทางเราได้รับแจ้งจากผู้สอนแล้ว เราจะติดต่อกลับไปหาผู้สอน เพื่อทราบถึงปัญหา หากยืนยันต้องการยกเลิก ทางเราจะนำคอร์สลงจากหน้าร้าน เเละทำการลบคอร์สเรียนออกจากเเพลตฟอร์มที่เป็นของผู้สอนดังกล่าวทั้งหมด โดยจะใช้ระยะเวลาดำเนินการภายใน 7 ถึง 14 วัน ไม่รวมวันหยุดนักขัตฤกษ์ พร้อมกับเเจ้งผลการยกเลิกให้ผู้สอนทราบทางอีเมล เเละโทรศัพท์</span></p>
            <p><span style={{}}>กรณีที่ 3 เป็นผู้สอนที่มีคอร์สผ่านการตรวจสอบ ออกสู่สาธารณะเเล้ว เเละมีผู้เรียนมากกว่า 1 คนขึ้นไป หากต้องการยกเลิกเป็นผู้สอน สามารถติดต่อเรา จากช่องทางติดต่อดังกล่าว เมื่อทางเราได้รับแจ้งจากผู้สอนแล้ว เราจะติดต่อกลับไปหาผู้สอน เพื่อทราบถึงปัญหา หากยืนยันต้องการยกเลิกจะต้องมีการจัดการกับคอร์สเรียนที่มีอยู่ มองเป็นเเต่ละคอร์สเรียน ดังนี้ </span></p>
            <p><span style={{}}>คอร์สเรียนที่มีผู้เรียนอยู่จะไม่สามารถลบคอร์สออกได้ คอร์สเรียนดังกล่าวจะยังคงอยู่ในเเพลตฟอร์มต่อไป เเต่จะถูกถอดออกจากหน้าร้าน เเละผู้เรียนจะไม่สามารถค้นหา เเละซื้อคอร์สเรียนได้อีก ซึ่งการเข้าถึงคอร์สดังกล่าวสามารถเข้าถึงได้เพียงผู้เรียนเก่าที่ซื้อคอร์สไปก่อนหน้านั้นจากช่องทางเดียวที่ “หน้าคอร์สของฉัน” ที่เข้าเรียนคอร์สได้ปกติ ยกเว้น ช่องทางเเชทจะถูกปิดลง ไม่สามารถติดต่อผู้สอนได้อีก</span></p>
            <p><span style={{}}>หากคอร์สเรียนที่ผ่านการตรวจสอบ ออกสู่สาธารณะเเล้ว เเละไม่มีผู้เรียนอยู่ จะดำเนินการตามกรณีที่ 2</span></p>
            <p><span style={{}}>โดยจะใช้ระยะเวลาดำเนินการภายใน 7 ถึง 28 วัน ไม่รวมวันหยุดนักขัตฤกษ์ พร้อมกับเเจ้งผลการยกเลิกให้ผู้สอนทราบทางอีเมล เเละโทรศัพท์</span></p>
            
            <span style={{ fontWeight: "bold" }}>การปิดบัญชี</span><br />
            <p><span style={{}}>กรณีผู้ใช้งานไม่ประสงค์จะใช้งานเเพลตฟอร์มต่อ เเละต้องการปิดบัญชี สามารถเเจ้งผ่านทางอีเมลนี้ </span><a href = "mailto: support@toflyn.com">support@toflyn.com</a></p>
            <p style={{}}><span>หากผู้ใช้งานเป็นผู้เรียน สามารถติดต่อทาง Tofyn เพื่อปิดบัญชีจากช่องทางติดต่อดังกล่าว เมื่อทางเราได้รับแจ้งจากผู้ใช้งานแล้ว ทางเราจะทำการปิดบัญชี และผู้ใช้งานจะไม่สามารถเรียกคืนเงินใด ๆ ที่ได้มีการชำระแล้วได้ โดยใช้ระยะเวลาดำเนินการภายใน 7 วันทำการ พร้อมกับเเจ้งผลการยกเลิกให้ผู้ใช้งานทราบทางอีเมล เเละโทรศัพท์</span></p>
            <p style={{}}><span>หากผู้ใช้งานเป็นผู้สอนที่ต้องการปิดบัญชี จะต้องยกเลิกเป็นผู้สอนเสร็จสิ้นมาเเล้วจึงจะขอปิดบัญชีได้ กรณีได้ยกเลิกการเป็นผู้สอนเเล้ว การดำเนินการปิดบัญชีจะเป็นเเบบเดียวกับผู้เรียน เพราะถือว่าไม่ได้เป็นผู้สอนเเล้ว</span></p>

            <p style={{}}><span>ฉบับวันที่ 1 มิถุนายน 2565</span></p>
        </>
    )
}
