/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from 'react'
import {
    Col,
    Divider,
    Image,
    Radio,
    Row,
    Input,
    Button,
    Form,
    Popconfirm,
    Spin,
} from 'antd'
import { Notification } from '../../../../../common/components/notification'
import { imgDefaltCardCourse, storageUrl } from '../../../../../constants'
import { updateInspectRequestEditCourseCardByIdFetch, updateInspectRequestOpenCourseCardByIdFetch } from '../../../API'
import LoadingPage from '../../../../../common/components/loading/page'

const { TextArea } = Input

export default function InspectCard(props) {

    const [listOriginal, setListOriginal] = useState(null)
    const [listReqtEdit, setListReqtEdit] = useState(null)
    const [inspect, setInspect] = useState(null)

    const [courseId, setCourseId] = useState(null)
    const [uid, setUid] = useState(null)
    const [isCloseInspect, setIsCloseInspect] = useState({
        status: null,
        message: null,
    })
    const [userType, setUserType] = useState(null)

    const [loading, setLoading] = useState(true)

    const [inspectCourseRadio, setInspectCourseRadio] = useState()

    const [formAdminInspectOpenCourse] = Form.useForm()
    const [formAdminInspectEditCourse] = Form.useForm()

    const [isSendResults, setIsSendResults] = useState(true)

    const detectReasonNotApploval = (rules, value) => {
        if (inspectCourseRadio === 2) {
            if (value) {
                return Promise.resolve()
            } else {
                return Promise.reject(new Error('กรุณากรอก เหตุผลที่ไม่ผ่าน'))
            }

        } else {
            return Promise.resolve()
        }
    }

    const onInspectCourseChange = e => {
        if (e.target.value === 1) {
            formAdminInspectOpenCourse.setFieldsValue({
                inspectCourseRadio: e.target.value,
                reasonNotApploval: null,
            })
        }
        setInspectCourseRadio(e.target.value)
    }

    const onAdminInspectOpenCourseFinish = async (values) => {
        if (!inspect.inspectionCourseCardAdjustId && !inspect.inspectionCourseCardApprovalId) { // inspect first time 
            // console.log("inspect first time")
            updateInspectCourseCard(values.inspectCourseRadio, values.reasonNotApploval)
        } else { // inspect second time or more 
            // console.log("inspect second time or more")
            updateInspectCourseCard(values.inspectCourseRadio, values.reasonNotApploval)
        }
    }

    const onAdminInspectEditCourseFinish = async (values) => {
        if (!inspect.inspectionCourseCardAdjustId && !inspect.inspectionCourseCardApprovalId) { // inspect first time 
            // console.log("inspect first time")
            updateInspectCourseCard(values.inspectCourseRadio, values.reasonNotApploval)
        } else { // inspect second time or more 
            // console.log("inspect second time or more")
            updateInspectCourseCard(values.inspectCourseRadio, values.reasonNotApploval)
        }
    }

    const updateInspectCourseCard = async (inspectCourseRadio, reasonNotApploval) => {
        let inspectCourseApploval = inspectCourseRadio ? inspectCourseRadio : null

        let obj = {
            courseId,
            uid,
            inspectionCourseCardApprovalId: inspectCourseApploval,
            inspectionCourseCardAdjustId: inspectCourseApploval === 2 ? 2 : (inspectCourseApploval === 1 ? null : inspectCourseApploval),
            inspectionCourseCardId: 1,
            reasonCourseCardNotApproval: reasonNotApploval ? reasonNotApploval : null,
        }
        // console.log("obj : ", obj)

        let result = { isSuccess: false }
        if (userType === "admin-inspect-open-course") {
            result = await updateInspectRequestOpenCourseCardByIdFetch(obj)
        } else if (userType === "admin-inspect-edit-course") {
            // console.log("course card : ", obj)
            result = await updateInspectRequestEditCourseCardByIdFetch(obj)
        }

        if (result.isSuccess) {
            props.reloadInspectCourse()
            Notification("success", "ระบบ", "บันทึกผลตรวจสอบสำเร็จ")
        } else {
            Notification("error", "ระบบ", "บันทึกผลตรวจสอบไม่สำเร็จ กรุณาลองใหม่อีกครั้ง")
        }
    }

    useEffect(() => {
        setLoading(true)

        setUserType(props.userType)
        setListOriginal(props.courseOriginal)

        setCourseId(props.courseId)
        setUid(props.uid)

        setInspectCourseRadio(props.inspect.course.inspectionCourseCardApprovalId)
        setInspect(props.inspect.course)

        if ( // open button send result to teacher
            props.inspect.inspectionCourseCardId !== 2 &&               // ต้องไม่รอตรวจสอบ
            props.inspect.inspectionCourseDescriptionId !== 2 &&        // ต้องไม่รอตรวจสอบ
            props.inspect.inspectionCoursePriceId !== 2 &&              // ต้องไม่รอตรวจสอบ

            props.inspect.inspectionCourseMediaIntroId !== 2 &&         // ต้องไม่รอตรวจสอบ
            props.inspect.inspectionCourseDocumentId !== 2 &&           // ต้องไม่รอตรวจสอบ
            props.inspect.inspectionCourseVideoId !== 2 &&              // ต้องไม่รอตรวจสอบ

            props.inspect.inspectionCourseId === 3                      // สถานะโดยรวม คือ รอตรวจสอบ [2]
        ) {
            setIsSendResults(true)
        } else {
            setIsSendResults(false)
        }

        setTimeout(function () {
            if (props.userType === "admin-inspect-open-course") {
                setIsCloseInspect(props.isCloseInspect)
                formAdminInspectOpenCourse.setFieldsValue({
                    inspectCourseRadio: props.inspect.course.inspectionCourseCardApprovalId ? props.inspect.course.inspectionCourseCardApprovalId : undefined,
                    reasonNotApploval: props.inspect.course.reasonCourseCardNotApproval ? props.inspect.course.reasonCourseCardNotApproval : undefined
                })
            } else if (props.userType === "admin-inspect-edit-course") {
                setListReqtEdit(props.courseReqtEdit);
                formAdminInspectEditCourse.setFieldsValue({
                    inspectCourseRadio: props.inspect.course.inspectionCourseCardApprovalId ? props.inspect.course.inspectionCourseCardApprovalId : undefined,
                    reasonNotApploval: props.inspect.course.reasonCourseCardNotApproval ? props.inspect.course.reasonCourseCardNotApproval : undefined
                })
            }

            setLoading(false)
        }, 500)
    }, [])

    const genAdminInspectOpenCourse = (isSaveInspect) => {
        return (
            <Form
                layout="vertical"
                form={formAdminInspectOpenCourse}
                onFinish={onAdminInspectOpenCourseFinish}
            >
                <Row>
                    <Col span={24} style={{ paddingLeft: 15, paddingBottom: 15 }}>
                        {listOriginal ?
                            <Image
                                style={{ width: 250 }}
                                alt="avatar"
                                src={`${listOriginal.courseImageLocation}`}
                                preview={{
                                    src: `${listOriginal.courseImageLocation}`,
                                }}
                            />
                            :
                            <Image
                                style={{ maxHeight: 300 }}
                                src="error"
                                fallback={imgDefaltCardCourse}
                            />
                        }
                    </Col>
                    <Col span={24} style={{ paddingLeft: 15 }}>
                        <Row gutter={[24, 24]}>
                            <Col span={8}>ชื่อคอร์ส</Col>
                            <Col span={16}>{listOriginal ? listOriginal.courseTitleName : "-"}</Col>

                            <Col span={8}>รายละเอียดคอร์สเบื้องต้น</Col>
                            <Col span={16}>{listOriginal ? listOriginal.courseSubDescriptionName : "-"}</Col>

                            <Col span={8}>ประเภทคอร์ส</Col>
                            <Col span={16}>{listOriginal ? listOriginal.courseCategoryName : "-"}</Col>

                            <Col span={8}>รูปเเบบคอร์ส</Col>
                            <Col span={16}>{listOriginal ? listOriginal.courseFormatName : "-"}</Col>

                            <Col span={8}>โหมดเช่าคอร์สรายเดือน</Col>
                            <Col span={16}>{listOriginal ? (listOriginal.monthlyPayment ? "เปิด" : "ปิด") : "-"}</Col>

                            <Col span={8}>ใบรับรอง (Certificate)</Col>
                            <Col span={16}>{listOriginal ? listOriginal.courseCertificateCategoryName : "-"}</Col>
                            
                            <Col span={8}>การชำระเงินเเบบรายวิดีโอ</Col>
                            <Col span={16}>
                                {listOriginal ? (
                                    listOriginal.isPaymentByVideo ? "ยินยอม" : "ไม่ยินยอม"
                                ) : "-"}
                            </Col>
                        </Row>
                    </Col>

                    {isSaveInspect ?
                        <>
                            <Divider style={{ marginBottom: 10, marginTop: 10 }} />
                            {!isCloseInspect.status ?
                                <>
                                    <Col span={24} style={{ textAlign: "center" }}>
                                        <Form.Item
                                            name="inspectCourseRadio"
                                            rules={[{ required: true, message: 'กรุณาเลือก ผลการตรวจ' }]}
                                        >
                                            <Radio.Group
                                                disabled={isSendResults}
                                                onChange={onInspectCourseChange}
                                                value={inspectCourseRadio}
                                            >
                                                <Radio value={1}>ผ่าน</Radio>
                                                <Radio value={2}>ไม่ผ่าน</Radio>
                                            </Radio.Group>
                                        </Form.Item>
                                    </Col>
                                    {inspectCourseRadio === 2 ?
                                        <Col span={24}>
                                            <Form.Item
                                                name="reasonNotApploval"
                                                label="เหตุผลที่ไม่ผ่าน"
                                                rules={[{ required: true, validator: detectReasonNotApploval }]}
                                            >
                                                <TextArea
                                                    disabled={isSendResults}
                                                    defaultValue={isSendResults}
                                                    placeholder="เหตุผลที่ไม่ผ่าน"
                                                    autoSize={{ minRows: 3, maxRows: 5 }}
                                                />
                                            </Form.Item>
                                        </Col>
                                        : []}
                                    <Col span={24} style={{ textAlign: "center" }}>
                                        <Popconfirm
                                            disabled={isSendResults}
                                            title="คุณยืนยันบันทึกผลตรวจสอบหรือไม่ ?"
                                            okText={<span style={{ width: 50 }}>ใช่</span>}
                                            onConfirm={() => formAdminInspectOpenCourse.submit()}
                                            cancelText={<span style={{ width: 50 }}>ไม่ใช่</span>}
                                        >
                                            <Button
                                                type="primary"
                                                style={{ width: 150 }}
                                                disabled={isSendResults}
                                            >
                                                บันทึกผลตรวจสอบ
                                            </Button>
                                        </Popconfirm>
                                    </Col>
                                </>
                                :
                                <Col span={24} style={{ textAlign: "center" }}>
                                    <center><label style={{ color: "red" }}>{isCloseInspect.message}</label></center>
                                </Col>
                            }
                        </>
                        : []}
                </Row>
            </Form>
        )
    }

    const genAdminInspectEditCourse = () => {
        return (
            <Row>
                <Col
                    span={12}
                    style={{
                        height: 30,
                        borderRight: "1px solid #DEDEDE",
                        borderBottom: "1px solid #DEDEDE"
                    }}
                ><center>เเบบเก่า</center></Col>
                <Col
                    span={12}
                    style={{
                        height: 30,
                        borderBottom: "1px solid #DEDEDE",
                        fontSize: 18
                    }}
                ><center>เเบบที่ขอเเก้ไข</center></Col>

                <Col
                    span={12}
                    style={{
                        paddingTop: 15,
                        borderRight: "1px solid #DEDEDE"
                    }}
                >
                    {genAdminInspectOpenCourse(false)}
                </Col>
                <Col
                    span={12}
                    style={{
                        paddingTop: 15,
                        paddingLeft: 15
                    }}
                >
                    <Row>
                        <Col span={24} style={{ paddingLeft: 15, paddingBottom: 15 }}>
                            {listOriginal ?
                                <Image
                                    style={{ width: '100%' }}
                                    alt="Image not found"
                                    src={listReqtEdit.courseImageLocation}
                                    preview={{
                                        src: listReqtEdit.courseImageLocation,
                                    }}
                                />
                                :
                                <Image
                                    style={{ maxHeight: 300 }}
                                    src="error"
                                    fallback={`${imgDefaltCardCourse}`}
                                />
                            }
                        </Col>
                        <Col span={24} style={{ paddingLeft: 15, fontSize: 14 }}>
                            <Row gutter={[24, 24]}>
                                <Col span={8}>ชื่อคอร์ส</Col>
                                <Col span={16}>{listReqtEdit ? listReqtEdit.courseTitleName : "-"}</Col>

                                <Col span={8}>รายละเอียดคอร์สเบื้องต้น</Col>
                                <Col span={16}>{listReqtEdit ? listReqtEdit.courseSubDescriptionName : "-"}</Col>

                                <Col span={8}>ประเภทคอร์ส</Col>
                                <Col span={16}>{listReqtEdit ? listReqtEdit.courseCategoryName : "-"}</Col>

                                <Col span={8}>รูปเเบบคอร์ส</Col>
                                <Col span={16}>{listReqtEdit ? listReqtEdit.courseFormatName : "-"}</Col>

                                <Col span={8}>โหมดเช่าคอร์สรายเดือน</Col>
                                <Col span={16}>{listReqtEdit ? (listReqtEdit.monthlyPayment ? "เปิด" : "ปิด") : "-"}</Col>

                                <Col span={8}>ใบรับรอง (Certificate)</Col>
                                <Col span={16}>{listReqtEdit ? listReqtEdit.courseCertificateName : "-"}</Col>

                                <Col span={8}>ยินยอมชำระเงินเเบบรายวิดีโอ</Col>
                                <Col span={16}>
                                    {listReqtEdit ? (
                                        listReqtEdit.isPaymentByVideo ? "ยินยอม" : "ไม่ยินยอม"
                                    ) : "-"}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>

                <Divider style={{ marginBottom: 10, marginTop: 10 }} />
                {props.inspect.inspectionCourseCardId ?
                    <Col span={24}>
                        <Form
                            layout="vertical"
                            form={formAdminInspectEditCourse}
                            onFinish={onAdminInspectEditCourseFinish}
                        >
                            <Col span={24} style={{ textAlign: "center" }}>
                                <Form.Item
                                    name="inspectCourseRadio"
                                    rules={[{ required: true, message: 'กรุณาเลือก ผลการตรวจ' }]}
                                >
                                    <Radio.Group
                                        disabled={isSendResults}
                                        onChange={onInspectCourseChange}
                                        value={inspectCourseRadio}
                                    >
                                        <Radio value={1}>ผ่าน</Radio>
                                        <Radio value={2}>ไม่ผ่าน</Radio>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                            {inspectCourseRadio === 2 ?
                                <Col span={24}>
                                    <Form.Item
                                        name="reasonNotApploval"
                                        label="เหตุผลที่ไม่ผ่าน"
                                        rules={[{ required: true, validator: detectReasonNotApploval }]}
                                    >
                                        <TextArea
                                            disabled={isSendResults}
                                            defaultValue={isSendResults}
                                            placeholder="เหตุผลที่ไม่ผ่าน"
                                            autoSize={{ minRows: 3, maxRows: 5 }}
                                        />
                                    </Form.Item>
                                </Col>
                                : []}
                            <Col span={24} style={{ textAlign: "center" }}>
                                <Popconfirm
                                    disabled={isSendResults}
                                    title="คุณยืนยันบันทึกผลตรวจสอบหรือไม่ ?"
                                    okText={<span style={{ width: 50 }}>ใช่</span>}
                                    onConfirm={() => formAdminInspectEditCourse.submit()}
                                    cancelText={<span style={{ width: 50 }}>ไม่ใช่</span>}
                                >
                                    <Button
                                        type="primary"
                                        style={{ width: 150 }}
                                        disabled={isSendResults}
                                    >
                                        บันทึกผลตรวจสอบ
                                    </Button>
                                </Popconfirm>
                            </Col>
                            :
                            <Col span={24} style={{ textAlign: "center" }}>
                                <center><label style={{ color: "red" }}>{isCloseInspect.message}</label></center>
                            </Col>
                        </Form>
                    </Col>
                    : []}
            </Row>
        )
    }

    return (
        <>
            {!loading ?
                <>
                    {/* {console.log("listOriginal : ", listOriginal)} */}
                    {userType === "admin-inspect-open-course" ? genAdminInspectOpenCourse(true) : []}

                    {/* {console.log("listReqtEdit : ", listReqtEdit)} */}
                    {userType === "admin-inspect-edit-course" ? genAdminInspectEditCourse() : []}
                </>
                :
                <LoadingPage loading={loading} isTitleActive={true} />
            }
        </>
    )
}
