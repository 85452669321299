import { server } from "../../../constants"
import { httpClient } from "../../../utils/HttpClient"

const getEarnedSalesRevenueMonitorByIdFetch = async (value) => { // Done 
    try {
        const result = await httpClient.post(server.GET_EARNED_SALES_REVENUE_MONITOR_BY_ID_URL, { value });
        if (result.data.isSuccess) {
            return result.data.formData;
        } else {
            return null;
        }
    } catch (err) { // status 404
        return null;
    }
}

const getCourseMonitorFetch = async (value) => { // Done 
    try {
        const result = await httpClient.post(server.GET_COURSE_MONITOR_BY_ID_URL, { value });
        if (result.data.isSuccess) {
            return result.data.formData;
        } else {
            return null;
        }
    } catch (err) { // status 404
        return null;
    }
}

export {
    // get
    getEarnedSalesRevenueMonitorByIdFetch,
    getCourseMonitorFetch
}