import { server } from "../../../constants"
import { httpClient } from "../../../utils/HttpClient"

const getDetectCourseHasBeenPurchasedFetch = async (value) => { // value[ learnerId, courseId ] : 
    try {
        const result = await httpClient.post(server.GET_DETECT_COURSE_HAS_BEEN_PURCHASED_URL, { value })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return []
        }
    } catch (err) { // status 404
        return null
    }
}

const getPaymentOrderByCourseIdFetch = async (value) => { // value[ courseId, isActive ] 
    try {
        const result = await httpClient.post(server.GET_PAYMENT_ORDER_BY_COURSE_ID_URL, { value })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return []
        }
    } catch (err) { // status 404
        return null
    }
}

export {
    // get
    getDetectCourseHasBeenPurchasedFetch,
    getPaymentOrderByCourseIdFetch
}