/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/alt-text */

import { useState, useEffect } from 'react'
import { BiPlayCircle } from "react-icons/bi"
import { Icon } from '@iconify/react'
import {
    List,
    Modal,
    Button,
    Image,
    Empty,
    Row,
    Col,
    Progress,
    Tooltip,
    Checkbox
} from 'antd'
import '../../css/video.css'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { useDimensions } from '../../../../common/mamager/ScreenManager'
import { setOrderPaymentChapterAndVideoCourseSuccess } from '../../../../actions/orderPaymentChapterAndVideoCourse.action'
import { storageUrl } from '../../../../constants'
import { genPaymentStatus } from '../../../payment/manager/PaymentManager'
import { filterPaymentVideo } from '../../../coursShop/mamager/filterPaymentVideo'
import Video from '../../../../common/components/video'
import { PathVideo } from '../../../../common/components/video/components/convert/pathVideo'
import { getPaymentOrderByCourseIdFetch } from '../../../coursShop/API'
import { secondsToHms, sumTime } from '../../../../common/mamager/TimeManager'
import { Notification } from '../../../../common/components/notification'

export default function CourseVideo(props) {
    const [sizeLayout, setsizeLayout] = useState()
    const [isModalVideoVisible, setIsModalVideoVisible] = useState(false)
    const [isPauseVideo, setIsPauseVideo] = useState(true)
    const [isModalBuyVisible, setIsModalBuyVisible] = useState(false)
    const [isModalBuyVideoVisible, setIsModalBuyVideoVisible] = useState(false)

    const navigate = useNavigate()

    const [loading, setLoading] = useState(false)

    const { width } = useDimensions()
    const [modalVideoList, setModalVideoList] = useState([])

    const [video, setVideo] = useState([])
    const [chapter, setChapter] = useState([])
    const [noneChapter, setNoneChapter] = useState([])

    const [paidStatus, setPaidStatus] = useState(null)
    const [isSelectVideoAll, setIsSelectVideoAll] = useState(false)

    const { resultOrderPaymentChapterAndVideoCourse } = useSelector(({ orderPaymentChapterAndVideoCourseReducer }) => orderPaymentChapterAndVideoCourseReducer)

    const dispatch = useDispatch()

    const [videoWatchingNow, setVideoWatchingNow] = useState(0)

    const showVideoModal = (videoId) => {
        setIsModalVideoVisible(true)
        setModalVideoList(video.find((val) => val.courseVideoId === videoId))
    }
    const showBuyModal = () => setIsModalBuyVisible(true)
    const showBuyVideoModal = (paidS) => {
        setPaidStatus(paidS)
        setIsModalBuyVideoVisible(true)
    }

    const handleVideoCancel = () => setIsModalVideoVisible(false)
    const handleBuyCancel = () => setIsModalBuyVisible(false)
    const handleBuyVideoCancel = () => setIsModalBuyVideoVisible(false)

    const handleTimeVideo = (timeVideo, displayTimeVideo) => { }
    const handlePercentWatchVideoCourse = (timeVideoCourse, timerWatchVideoCourse, percent, isFirstLoadApi, VideoCourseList, timerWatchVideoCourseList) => { }
    const handlePlayer = (player) => { }
    const handleControls = (controls) => { }

    const getBaseApi = () => {
        setLoading(true)

        setVideo(props.coureVideoGroup.video)
        setChapter(props.coureVideoGroup.chapter)
        setNoneChapter(props.coureVideoGroup.noneChapter)

        setLoading(false)
    }

    const addOderPaymentChapterAndVideoCourse = async (data) => {
        let isCanAddOrderPayment = false
        let tmpCourseVideoBefore = []

        if (data.courseChapterId) {
            let findChapter = chapter.find(val => val.courseChapterId === data.courseChapterId)
            let indexIsFirstOfChapter = false

            for (let i = 0; i < findChapter.videoList?.length; i++) {
                if (findChapter.videoList[i].courseVideoId === data.courseVideoId) {
                    indexIsFirstOfChapter = (i === 0 ? true : false)
                    break
                }
            }

            if (indexIsFirstOfChapter) { // check chapter before

                // find courseChapterNo
                let tmpCourseChapterNo = chapter.filter(val => val.courseChapterId === data.courseChapterId)[0]?.courseChapterNo

                // find courseChapterId before
                let tmpCourseChapterIdBefore = chapter.filter(val => val.courseChapterNo === (tmpCourseChapterNo - 1))[0]?.courseChapterId

                // find courseChapter before
                let tmpCourseChapterBefore = chapter.filter(val => val.courseChapterId === tmpCourseChapterIdBefore)[0]

                // find course video before 
                tmpCourseVideoBefore = tmpCourseChapterBefore?.videoList[tmpCourseChapterBefore.videoList?.length - 1]
            } else { // check video brfore

                // find video list in chapter
                let courseVideoByChapterId = chapter.filter(val => val.courseChapterId === data.courseChapterId)[0].videoList

                // find index of video before
                let courseVideoIndexBefore = null
                courseVideoByChapterId.map((val, index) => {
                    if (val.courseVideoId === data.courseVideoId) {
                        courseVideoIndexBefore = (index - 1)
                    }
                })

                // find course video before 
                tmpCourseVideoBefore = courseVideoByChapterId[courseVideoIndexBefore]
            }
        } else {
            // find index of video before
            let courseVideoIndexBefore = null
            noneChapter.map((val, index) => {
                if (val.courseVideoId === data.courseVideoId) {
                    courseVideoIndexBefore = (index - 1)
                }
            })

            if (courseVideoIndexBefore === -1) { // is first index of non chapter
                tmpCourseVideoBefore = chapter[chapter?.length - 1]?.videoList[chapter[chapter?.length - 1]?.videoList?.length - 1] // .videoList[tmpCourseChapterBefore.videoList?.length - 1]
            } else {
                // find course video before 
                tmpCourseVideoBefore = noneChapter[courseVideoIndexBefore]
            }
        }

        // detect is already paid
        if (tmpCourseVideoBefore?.paymentInfo?.length > 0) {
            isCanAddOrderPayment = true
        } else {
            isCanAddOrderPayment = false
        }

        // detect, previous items are already in the basket
        let isPreviousVideoInBasket = resultOrderPaymentChapterAndVideoCourse.video.filter(val => val.courseVideoId === tmpCourseVideoBefore?.courseVideoId)[0] ? true : false

        // detect video selected is first video in video list
        let isFirstVideoInVideoList = null
        if (props.coureVideoGroup.isVideoGroup) {
            isFirstVideoInVideoList = props.coureVideoGroup.chapter[0].videoList[0].courseVideoId === data.courseVideoId ? true : false
        } else {
            isFirstVideoInVideoList = props.coureVideoGroup.video[0].courseVideoId === data.courseVideoId ? true : false
        }

        if (isCanAddOrderPayment || isPreviousVideoInBasket || isFirstVideoInVideoList) {
            let tmpVideo = resultOrderPaymentChapterAndVideoCourse.video
            tmpVideo.push(data)

            let tmpChapter = resultOrderPaymentChapterAndVideoCourse.chapter
            if (props.coureVideoGroup.isVideoGroup && data.courseChapterId) {
                let isAlreadyHaveChapter = resultOrderPaymentChapterAndVideoCourse.chapter?.filter(val => val.courseChapterId === data.courseChapterId)[0]
                if (!isAlreadyHaveChapter) {
                    let findChapter = props.coureVideoGroup.chapter.filter(val => val.courseChapterId === data.courseChapterId)[0]
                    tmpChapter.push(findChapter)
                }
            }

            let obj = {
                video: tmpVideo,
                chapter: tmpChapter,
                courseId: props.course?.courseId,
                videoMain: video
            }
            dispatch(setOrderPaymentChapterAndVideoCourseSuccess(obj))
            props?.reloadOderPayment(tmpVideo)
        } else {

            const resultPaymentOrder = await getPaymentOrderByCourseIdFetch({
                courseId: props.course.courseId,
                isActive: 1
            })

            if (resultPaymentOrder?.length === 0 && resultOrderPaymentChapterAndVideoCourse.video?.length === 0) {
                Notification("warning", "เเจ้งเตือน", "ไม่สามารถเพิ่มวิดีโอนี้ใส่ตระกร้าได้ โปรดเลือกวิดีโอจากบนสุดก่อน")
            } else {
                Notification("warning", "เเจ้งเตือน", "ไม่สามารถเพิ่มวิดีโอนี้ใส่ตระกร้าได้ โปรดเลือกวิดีก่อนหน้าตามลำดับ")
            }
        }
    }

    const removeOderPaymentChapterAndVideoCourse = (videoKey, chapterId, videoId) => {
        let isRemoveVideoOnOrderPayment = false

        // step 0 check video selected is last video
        let isLastVideo = false;
        if (noneChapter?.length === 0 || !noneChapter) {
            let tmpLastVideoOfLastChapter = chapter[chapter?.length - 1]?.videoList[chapter[chapter.length - 1].videoList?.length - 1]
            isLastVideo = (tmpLastVideoOfLastChapter?.courseVideoId === videoId ? true : false)
        } else {
            let tmpLastVideoOfLastNoneChapter = noneChapter[noneChapter?.length - 1]
            isLastVideo = (tmpLastVideoOfLastNoneChapter?.courseVideoId === videoId ? true : false)
        }

        if (isLastVideo) {
            isRemoveVideoOnOrderPayment = true
        } else {
            if (chapterId) {
                // step 1 find video list in chapter current
                let findChapterByMainId = chapter.filter(val => val.courseChapterId === chapterId)[0].videoList

                // step 2 : find video next in video list
                let videoNextByVideoKey = null
                findChapterByMainId.map((val, index) => {
                    if (val.videoKey === videoKey) {
                        videoNextByVideoKey = findChapterByMainId[index + 1]
                    }
                })

                if (videoNextByVideoKey) {
                    // step 2.1 : find next video is first video in chapter from main chapter
                    // use videoId from chapter
                    isRemoveVideoOnOrderPayment = detectNextVideoInOrderPayment(videoNextByVideoKey?.courseVideoId)
                } else {
                    // step 2.2.1 : find chapter next from next index of main chapter
                    let findChapterNextByMainId = null
                    chapter.map((val, index) => {
                        if (val.courseChapterId === chapterId) {
                            findChapterNextByMainId = chapter[index + 1]?.videoList
                        }
                    })

                    // step 2.2.2 : check next video is video has type none chapter
                    if (findChapterNextByMainId) {
                        // use videoId from chapter
                        isRemoveVideoOnOrderPayment = detectNextVideoInOrderPayment(findChapterNextByMainId[0]?.courseVideoId)
                    } else {
                        // use videoId from none chapter
                        let findFirstVideoIsNoneChapter = noneChapter[0];
                        isRemoveVideoOnOrderPayment = detectNextVideoInOrderPayment(findFirstVideoIsNoneChapter?.courseVideoId)
                    }
                }
            } else {
                let findNextVideoIsNoneChapterByMainId = null
                noneChapter.map((val, index) => {
                    if (val.courseVideoId === videoId) {
                        findNextVideoIsNoneChapterByMainId = noneChapter[index + 1]
                    }
                })
                // use videoId from none chapter
                isRemoveVideoOnOrderPayment = detectNextVideoInOrderPayment(findNextVideoIsNoneChapterByMainId?.courseVideoId)
            }
        }

        // -----------------------------------------------------

        if (isRemoveVideoOnOrderPayment) {
            let tmpVideo = resultOrderPaymentChapterAndVideoCourse.video?.filter(val => val.videoKey !== videoKey)

            let tmpChapter = []
            if (props.coureVideoGroup.isVideoGroup) {
                let countChapterByVideo = tmpVideo?.filter(val => val.courseChapterId === chapterId)?.length
                if (countChapterByVideo === 0) { // chapter is do not have in oder payment by video list
                    tmpChapter = resultOrderPaymentChapterAndVideoCourse.chapter?.filter(val => val.courseChapterId !== chapterId)
                } else {
                    tmpChapter = resultOrderPaymentChapterAndVideoCourse.chapter
                }
            }

            let obj = {
                video: tmpVideo,
                chapter: tmpChapter,
                courseId: props.course?.courseId,
                videoMain: video
            }
            dispatch(setOrderPaymentChapterAndVideoCourseSuccess(obj))
            props.reloadOderPayment(tmpVideo)
        } else {
            Notification("warning", "เเจ้งเตือน", "ไม่สามารถนำวิดีโอนี้ออกจากตระกร้าได้ โปรดนำวิดีโอที่อยู่หลังวิดีโอนี้ออกก่อน")
        }
    }

    const detectNextVideoInOrderPayment = (videoId) => {
        let filter = resultOrderPaymentChapterAndVideoCourse.video?.filter(val => val.courseVideoId === videoId)[0]
        if (filter) {
            return false
        } else {
            return true
        }
    }

    const onHideVideo = (id) => {
        setLoading(true)
        let tempChapter = chapter.map(val => {
            return (val.courseChapterId === id ? {
                chapterCategoryId: val.chapterCategoryId,
                chapterCategoryName: val.chapterCategoryName,
                courseChapterTitleName: val.courseChapterTitleName,
                courseChapterDescriptionName: val.courseChapterDescriptionName,
                courseChapterId: val.courseChapterId,
                courseChapterNo: val.courseChapterNo,
                courseId: val.courseId,
                createdAt: val.createdAt,
                videoList: val.videoList,
                isOpen: !val.isOpen
            } : val)
        })
        setChapter(tempChapter)
        setLoading(false)
    }

    const onClickWatchVideo = async (item, index) => {
        if (props.pageName === "course-shop") {
            if (props.course?.isPaymentByVideo) {
                if (item.paymentInfo[0]?.payment_oder?.paymentStatusId === 1) {
                    showVideoModal(item.courseVideoId)
                }
            } else {
                if (props.isCourseHasBeenPurchased) {
                    showVideoModal(item.courseVideoId)
                } else {
                    if (index < 1) {
                        setLoading(true)

                        setIsPauseVideo(true)                  // ห้ามเอา await ออก
                        await props.handlePauseVideo(false)    // ห้ามเอา await ออก
                        showVideoModal(item.courseVideoId)     // ห้ามเอา await ออก

                        setLoading(false)
                    } else {
                        showBuyModal()
                    }
                }
            }
        } else {

            let isOpen = true;
            let paidStatus = {
                status: 1,
                title: "",
                detail: ""
            } // 1 : paid success, 2 paid waiting, 3 not paid

            // detect is paid 
            if (item.isPaymentByVideo) {
                if (item.paymentInfo?.length > 0) {
                    isOpen = false;
                    paidStatus = {
                        status: 3,
                        title: "ไม่สามารถเข้าชมวิดีโอนี้ได้ !",
                        detail: "โปรดซื้อวิดีโอนี้ เพื่อเข้ารับชม !"
                    }
                } else {
                    // detect paid seccess
                    if (item.paymentInfo[0]?.payment_oder?.paymentStatusId === 2) {
                        isOpen = false;
                        paidStatus = {
                            status: 2,
                            title: "ไม่สามารถเข้าชมวิดีโอนี้ได้ !",
                            detail: "วิดีโอนี้อยู่ระหว่างตรวจสอบชำระเงิน !"
                        }
                    }
                }
            }

            if (isOpen) {
                setVideoWatchingNow(item.videoKey)
                props.handleVideoCourseCurrent(item)
            } else {
                showBuyVideoModal(paidStatus)
            }
        }
    }

    const genColorPaidVideo = (item, index) => {
        let codeColor = ""
        if (item.isPaymentByVideo) {
            if (item.paymentInfo?.length > 0) {
                codeColor = "#390000"
            } else {
                // detect paid seccess
                if (item.paymentInfo[0]?.payment_oder?.paymentStatusId === 2) {
                    codeColor = " #392f00"
                }
            }
        }
        return codeColor
    }

    const genTitleName = (data) => {
        return (
            <label
                style={{
                    color: 'white',
                    marginTop: -4,
                    margin: '0px 0px 0px 0px',
                    height: 45,
                    cursor: 'pointer',
                    width: "100%",

                    WebkitLineClamp: 2,
                    WebkitBoxOrient: "vertical",
                    display: "-webkit-box",
                    maxHeight: 45,
                    textOverflow: "ellipsis",
                    overflow: "hidden",

                    paddingRight: (props.pageName === "classroom-course-study" ? 10 : 0)
                }}>
                {data}
            </label>
        )
    }

    const genVideo = (videoList, key) => {
        return (
            <>
                {videoList ?
                    <div
                        style={{
                            padding: (props.coureVideoGroup.isVideoGroup ? 6 : 0),
                            backgroundColor: "black",
                            color: "white",
                            borderRight: (props.coureVideoGroup.isVideoGroup ? '6px solid #494949' : ''),
                            borderLeft: (props.coureVideoGroup.isVideoGroup ? '6px solid #494949' : ''),
                            borderBottom: (props.coureVideoGroup.isVideoGroup ? '6px solid #494949' : '')
                        }}
                    >
                        {videoList.length > 0 ?
                            <List
                                itemLayout="horizontal"
                                dataSource={videoList}
                                renderItem={(item, index) => {
                                    return (
                                        <Row
                                            style={{
                                                width: "100%",
                                                cursor: 'pointer',
                                                borderBottom: (videoList.length === (index + 1) ? '' : '1px solid #696969'),
                                                backgroundColor: (videoWatchingNow === item.videoKey ? "#3D3D3D" : genColorPaidVideo(item, index))
                                            }}
                                            className={"video-item-group"}
                                        >
                                            <Col style={{ width: props.pageName === "course-shop" ? "66%" : "100%" }}>
                                                <List.Item
                                                    style={{
                                                        paddingLeft: 15,
                                                        paddingTop: 0,
                                                        paddingBottom: 0,
                                                        height: 75
                                                    }}
                                                    onClick={async () => await onClickWatchVideo(item, index)}
                                                >
                                                    <List.Item.Meta
                                                        avatar={
                                                            <Row>
                                                                <Col span={24} style={{ marginLeft: -12 }}>
                                                                    {(item.created_at ? (moment().diff(moment(item.created_at, "YYYY-MM-DD HH:mm:ss"), "day") <= 30) : false) ?
                                                                        <div style={{ position: 'absolute', top: 0, left: 0, zIndex: 1, marginTop: -10 }}>
                                                                            <label style={{ color: "white", fontWeight: 'bold', backgroundColor: "red", borderRadius: '0px 0px 20px 0px', paddingRight: 8 }}>ใหม่!</label>
                                                                        </div>
                                                                        : []
                                                                    }

                                                                    <div style={{ position: 'relative', textAlign: 'center' }}>
                                                                        <div>
                                                                            <BiPlayCircle
                                                                                style={{
                                                                                    width: 30,
                                                                                    height: 30,
                                                                                    position: 'absolute',
                                                                                    zIndex: 1,
                                                                                    top: "22%", // ทำให้ปุ่มอยู่กึ่งกลางตามแนวดิ่ง
                                                                                    left: "57%", // ทำให้ปุ่มอยู่กึ่งกลางตามแนวนอน
                                                                                    transform: "translate(-50%, -50%)",
                                                                                    color: 'white',
                                                                                    opacity: 0.8
                                                                                }}
                                                                            />
                                                                        </div>
                                                                        <Image
                                                                            style={{ width: 100, height: 60, marginTop: -10, border: "1px solid white" }}
                                                                            src="error"
                                                                            fallback={`${item?.courseVideoImgLocation}`}
                                                                            preview={false}
                                                                        />
                                                                        <div
                                                                            style={{
                                                                                color: 'white',
                                                                                cursor: 'pointer',
                                                                                // marginTop: -22,
                                                                                position: "absolute",
                                                                                display: 'flex',
                                                                                // marginLeft: 22,
                                                                                backgroundColor: "black",
                                                                                top: "70%", // ทำให้ปุ่มอยู่กึ่งกลางตามแนวดิ่ง
                                                                                left: "57%", // ทำให้ปุ่มอยู่กึ่งกลางตามแนวนอน
                                                                                transform: "translate(-50%, -50%)",
                                                                                height: 15,
                                                                                paddingLeft: 2,
                                                                                paddingRight: 2,
                                                                                opacity: 0.8
                                                                            }}
                                                                        >
                                                                            <label style={{ marginTop: -4 }}>{secondsToHms(item.courseVideoDuration)}</label>
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        }

                                                        title={
                                                            <div style={{ fontWeight: 100 }}>
                                                                {item.courseVideoTitleName.length > 75 ?
                                                                    <Tooltip
                                                                        color={"white"}
                                                                        placement="left"
                                                                        title={<div style={{ color: 'black' }}>{item.courseVideoTitleName}</div>}
                                                                        style={{ margin: -3 }}
                                                                    >
                                                                        {genTitleName(item.courseVideoTitleName)}
                                                                    </Tooltip>
                                                                    :
                                                                    genTitleName(item.courseVideoTitleName)
                                                                }
                                                            </div>
                                                        }
                                                    />
                                                    {props.pageName === "classroom-course-study" ?
                                                        <Row style={{ width: '24%', fontSize: 12 }}>
                                                            <Col span={24}>
                                                                <label style={{ color: 'white', cursor: 'pointer', height: 10 }}>
                                                                    <Tooltip
                                                                        placement="left"
                                                                        title={<div style={{ color: 'black' }}>CVT ย่อมาจาก (Cumulative visit time) หมายถึง ระยะเวลาที่เข้าชมสะสม</div>}
                                                                        color={"white"}
                                                                    >
                                                                        CVT {secondsToHms(item.videoCourseTimer.timer)}
                                                                    </Tooltip>
                                                                </label>
                                                            </Col>

                                                            <Col span={24}>
                                                                <label style={{ color: 'white', paddingRight: 15, cursor: 'pointer', height: 10 }}>
                                                                    <Tooltip
                                                                        placement="left"
                                                                        title={<div style={{ color: 'black' }}>ระยะเวลาที่คิดเป็นเปอร์เซนต์ของการรับชมวิดีโอเเล้ว เมื่อเทียบกับขนาดของวิดีโอ</div>}
                                                                        color={"white"}
                                                                    >
                                                                        <Progress
                                                                            percent={(item.videoCourseTimer.timer * 100) / item.courseVideoDuration}
                                                                            steps={6}
                                                                            size="small"
                                                                            strokeColor="#52c41a"
                                                                            trailColor={'#EEEEEE'}

                                                                            format={percent => <div style={{ color: 'white' }}>{parseFloat(percent.toFixed(0))}%</div>}
                                                                        />
                                                                    </Tooltip>
                                                                </label>
                                                            </Col>
                                                        </Row>
                                                        : []
                                                    }
                                                </List.Item>
                                            </Col>

                                            <Col
                                                style={{
                                                    width: "34%",
                                                    // paddingTop: 7
                                                }}>
                                                {props.pageName === "course-shop" ?
                                                    <>
                                                        {props.course.isPaymentByVideo ?
                                                            <Row style={{ paddingLeft: 10, marginTop: 0 }}>
                                                                <Col span={24}>
                                                                    {item.paymentInfo?.length > 0 ?
                                                                        <>
                                                                            {genPaymentStatus(item.paymentInfo[0]?.payment_oder?.paymentStatusId)}
                                                                        </>
                                                                        :
                                                                        <>
                                                                            {resultOrderPaymentChapterAndVideoCourse.video?.filter((val) => val.videoKey === item.videoKey)[0] ?
                                                                                <Button
                                                                                    type='default'
                                                                                    size='middle'
                                                                                    onClick={() => removeOderPaymentChapterAndVideoCourse(
                                                                                        item.videoKey,
                                                                                        item.courseChapterId,
                                                                                        item.courseVideoId
                                                                                    )}
                                                                                    ghost
                                                                                >
                                                                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                                                        <Icon icon="fontisto:shopping-basket-remove" style={{ color: "red", width: 20, height: 20, cursor: "pointer" }} />
                                                                                        <label style={{ paddingLeft: 5, cursor: "pointer" }}>เอาออก</label>
                                                                                    </div>
                                                                                </Button>
                                                                                :
                                                                                <Button
                                                                                    type='default'
                                                                                    size='middle'
                                                                                    onClick={() => addOderPaymentChapterAndVideoCourse(item)}
                                                                                    ghost
                                                                                >
                                                                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                                                        <Icon icon="fontisto:shopping-basket-add" style={{ color: "#23B14D", width: 20, height: 20, cursor: "pointer" }} />
                                                                                        <label style={{ paddingLeft: 5, cursor: "pointer" }}>ซื้อวิดีโอ</label>
                                                                                    </div>
                                                                                </Button>
                                                                            }
                                                                        </>
                                                                    }
                                                                </Col>

                                                                <Col span={24} style={{ paddingTop: 3 }}>
                                                                    <span style={{ color: "white" }}>ราคา {Intl.NumberFormat("en", { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(Number(item.courseVideoPrice))} บาท</span>
                                                                </Col>
                                                            </Row>
                                                            :
                                                            []
                                                        }
                                                    </>
                                                    : []
                                                }
                                            </Col>
                                        </Row>
                                    )
                                }
                                }
                            />
                            :
                            <Empty
                                image={Empty.PRESENTED_IMAGE_SIMPLE}
                                imageStyle={{
                                    height: 60,
                                }}
                                style={{ width: '100%' }}
                                description={
                                    <span style={{ color: 'white' }}>
                                        ไม่มีวิดีโอคอร์ส
                                    </span>
                                }
                            >
                            </Empty>
                        }
                    </div>
                    : []
                }
            </>
        )
    }

    useEffect(() => {
        // sum times
        sumTime(props.coureVideoGroup.video)

        getBaseApi()

        // set default size
        if (props.pageName === "classroom-course-study") {
            setsizeLayout(width < 992 ? 700 : 905)          // 1010
        } else if (props.pageName === "course-shop") {
            setsizeLayout(width < 992 ? 700 : 622)          // 618
        }

        // set default focus 
        if (videoWatchingNow === 0) {
            if (props.coureVideoGroup?.isVideoGroup) {
                setVideoWatchingNow(props.coureVideoGroup.chapter[0].videoList[0].videoKey)
            } else {
                setVideoWatchingNow(props.coureVideoGroup.video[0].videoKey)
            }
        }
    }, [
        (props.coureVideoGroup?.isVideoGroup ? props.coureVideoGroup?.chapter : props.coureVideoGroup.video)
    ])

    useEffect(() => {

    }, [isPauseVideo])

    return (
        <>
            {props.pageName === "course-shop" ?
                (props.course.isPaymentByVideo ?
                    <div style={{ backgroundColor: "black", paddingLeft: 10, paddingTop: 2 }}>
                        <Checkbox
                            onChange={() => {
                                setIsSelectVideoAll(!isSelectVideoAll)
                                if (!isSelectVideoAll) {
                                    // fliter video
                                    let fillOrderVideo = filterPaymentVideo(
                                        chapter,
                                        video,
                                        props.course.courseId
                                    )

                                    dispatch(setOrderPaymentChapterAndVideoCourseSuccess(fillOrderVideo))
                                    props.reloadOderPayment(fillOrderVideo.video)
                                } else {
                                    dispatch(setOrderPaymentChapterAndVideoCourseSuccess({
                                        video: [],
                                        chapter: [],
                                        courseId: null,
                                        videoMain: video
                                    }))
                                    props.reloadOderPayment([])
                                }
                            }}
                        >
                            <label style={{ color: "white" }}>เลือกทั้งหมด ({props.coureVideoGroup.video?.length} วิดีโอ)</label>
                        </Checkbox>
                    </div>
                    : []
                )
                : []}


            <div
                style={{
                    scrollbarColor: 'black',
                    overflow: 'auto',
                    height: sizeLayout,
                    width: 'auto',
                    overflowX: 'hidden',
                    backgroundColor: '#191919',
                    fontSize: 14
                }}
            >
                {!loading ?
                    <>
                        {chapter?.length > 0 ?
                            <>
                                {chapter.map((val, index) => {
                                    return (
                                        <>
                                            <div
                                                style={{
                                                    width: '100%',
                                                    paddingTop: 10,
                                                    paddingBottom: 10,
                                                    paddingLeft: 16,
                                                    paddingRight: 16,
                                                    borderTop: index === 0 ? '' : '6px dashed #23B14D',
                                                    backgroundColor: "#494949"
                                                }}
                                            >
                                                <div
                                                    style={{ float: "left", marginTop: -1, paddingRight: 8, cursor: "pointer", color: "white" }}
                                                    onClick={() => onHideVideo(val.courseChapterId)}
                                                >
                                                    {val.isOpen ?
                                                        <Icon icon="charm:chevron-down" style={{ width: 20, height: 20 }} />
                                                        :
                                                        <Icon icon="charm:chevron-right" style={{ width: 20, height: 20 }} />
                                                    }
                                                </div>
                                                <Tooltip placement="top" title={val.courseChapterDescriptionName}>
                                                    <span style={{ color: "white" }}>{`${val.chapterCategoryName} ${val.courseChapterNo} ${val.courseChapterTitleName}`}</span>
                                                </Tooltip>
                                            </div>
                                            {val.isOpen ? genVideo(val.videoList, index) : []}
                                        </>
                                    )
                                })}
                            </>
                            : []
                        }
                        <div style={{ borderTop: chapter?.length === 0 ? '' : '6px dashed #23B14D' }}>
                            {noneChapter?.length > 0 ? genVideo(noneChapter, null) : []}
                        </div>
                    </>
                    : []
                }
            </div>

            <Modal
                title={
                    <label style={{ paddingBottom: 0, marginBottom: 0, fontWeight: 100 }}>
                        {props.isCourseHasBeenPurchased ? "โปรดเข้าห้องเรียน เพื่อดูวิดีโอคอร์ส" : modalVideoList?.courseVideoTitleName}
                    </label>
                }
                visible={isModalVideoVisible}
                onCancel={async () => {
                    setLoading(true)

                    setIsPauseVideo(false) // ห้ามเอา await ออก
                    await props.handlePauseVideo(true) // ห้ามเอา await ออก
                    handleVideoCancel() // ห้ามเอา await ออก

                    setLoading(false)
                }}
                cancelButtonProps={{ hidden: true }}
                okButtonProps={{ hidden: true }}
                footer={null}
                width={props.isCourseHasBeenPurchased ? 360 : 560}
            >
                {props.isCourseHasBeenPurchased ?
                    <center>
                        <Button
                            type="primary"
                            style={{ width: 200 }}
                            onClick={() => {
                                navigate("/classroom-course-study?course-id=" + modalVideoList.courseId)
                            }}
                        >
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: "center" }}>
                                <Icon icon="material-symbols:school-outline" style={{ color: "white", width: 20, height: 20 }} />
                                <label style={{ paddingLeft: 5, cursor: "pointer" }}>เข้าห้องเรียน</label>
                            </div>
                        </Button>
                    </center>
                    :
                    <Row style={{ backgroundColor: "black" }}>
                        <Col span={24}>
                            {!loading ?
                                <Video
                                    courseId={null}
                                    learnerId={null}
                                    videoTitle=""
                                    videoLocation={PathVideo(modalVideoList.courseVideoLocation)}
                                    component={'course-shop'}
                                    width="100%"
                                    isPlaying={true}
                                    isBreak={isPauseVideo}
                                    timerRunning={false}
                                    handleTimeVideo={handleTimeVideo}
                                    handlePercentWatchVideoCourse={handlePercentWatchVideoCourse}
                                    handlePlayer={handlePlayer}
                                    handleControls={handleControls}
                                />
                                : []
                            }
                        </Col>
                        <Col span={24} style={{ paddingLeft: 12, paddingRight: 12, color: 'white', borderBottom: "1px solid #b2b2b2", fontWeight: "bold" }}>
                            <label>รายละเอียดวิดีโอ</label>
                        </Col>
                        <Col span={24} style={{ color: "white", paddingLeft: 12, paddingRight: 12, paddingTop: 6, paddingBottom: 6 }}>
                            <label>{modalVideoList?.courseVideoDescriptionName}</label>
                        </Col>
                    </Row>
                }
            </Modal>

            <Modal
                title="ซื้อคอร์สเพื่อเข้าชม"
                visible={isModalBuyVisible}
                onCancel={handleBuyCancel}
                cancelButtonProps={{ hidden: true }}
                okButtonProps={{ hidden: true }}
                footer={[
                    <Button
                        type="primary"
                        onClick={() => {
                            navigate("/payment?course-id=" + props.course.courseId)
                        }}
                    >
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <Icon icon="icons8:buy" style={{ color: "white", width: 20, height: 20 }} />
                            <label style={{ paddingLeft: 5, cursor: "pointer" }}>ซื้อคอร์สนี้</label>
                        </div>
                    </Button>
                ]}
            >
                <center>
                    <label>
                        {Number(props?.course?.price) === 0 ?
                            <>ฟรี</>
                            :
                            <>
                                ราคา{" "}
                                {props?.course?.discountStatus
                                    ? Intl.NumberFormat('en').format((Number(props.course.price) - Number(props.course.discount)))
                                    : Intl.NumberFormat('en').format(Number(props.course.price))
                                }{" "}
                                บาท
                            </>
                        }
                    </label>
                </center>
            </Modal>

            <Modal
                title={<>{paidStatus?.title}</>}
                visible={isModalBuyVideoVisible}
                onCancel={handleBuyVideoCancel}
                cancelButtonProps={{ hidden: true }}
                okButtonProps={{ hidden: true }}
                footer={[
                    <Button
                        type="primary"
                        onClick={() => {
                            navigate("/course-shop?course-id=" + props.course.courseId)
                        }}
                    >
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <Icon icon="icons8:buy" style={{ color: "white", width: 20, height: 20 }} />
                            <label style={{ paddingLeft: 5, cursor: "pointer" }}>ไปที่หน้าร้านคอร์ส</label>
                        </div>
                    </Button>
                ]}
            >
                <center>
                    <label>{paidStatus?.detail}</label>
                </center>
            </Modal>
        </>
    )
}

