import { Col, Empty, Row } from 'antd'
import { useEffect, useState } from 'react'
import { Cell, Pie, PieChart, ResponsiveContainer, Sector } from 'recharts'
import { useDimensions } from '../../../../../../common/mamager/ScreenManager'

const COLORS1 = ['#23B14D', '#29CD5A', '#2EE564', '#35FF71', '#35FF71', '#35FF71', '#35FF71', '#35FF71', '#35FF71', '#35FF71', '#35FF71', '#35FF71', '#35FF71', '#35FF71', '#35FF71', '#35FF71', '#35FF71', '#35FF71', '#35FF71', '#35FF71', '#35FF71', '#35FF71', '#35FF71', '#35FF71', '#35FF71', '#35FF71', '#35FF71', '#35FF71', '#35FF71', '#35FF71', '#35FF71', '#35FF71', '#35FF71', '#35FF71', '#35FF71', '#35FF71', '#35FF71', '#35FF71', '#35FF71', '#35FF71', '#35FF71', '#35FF71', '#35FF71', '#35FF71', '#35FF71', '#35FF71', '#35FF71', '#35FF71', '#35FF71', '#35FF71', '#35FF71', '#35FF71', '#35FF71', '#35FF71', '#35FF71', '#35FF71', '#35FF71', '#35FF71', '#35FF71', '#35FF71', '#35FF71', '#35FF71', '#35FF71', '#35FF71', '#35FF71', '#35FF71', '#35FF71', '#35FF71', '#35FF71', '#35FF71', '#35FF71', '#35FF71', '#35FF71', '#35FF71', '#35FF71', '#35FF71', '#35FF71', '#35FF71', '#35FF71', '#35FF71', '#35FF71', '#35FF71', '#35FF71', '#35FF71', '#35FF71', '#35FF71', '#35FF71', '#35FF71', '#35FF71', '#35FF71', '#35FF71', '#35FF71', '#35FF71', '#35FF71', '#35FF71', '#35FF71', '#35FF71', '#35FF71', '#35FF71', '#35FF71', '#35FF71', '#35FF71', '#35FF71', '#35FF71', '#35FF71', '#35FF71', '#35FF71', '#35FF71', '#35FF71', '#35FF71', '#35FF71', '#35FF71', '#35FF71', '#35FF71', '#35FF71', '#35FF71', '#35FF71', '#35FF71', '#35FF71', '#35FF71', '#35FF71', '#35FF71', '#35FF71', '#35FF71', '#35FF71', '#35FF71', '#35FF71', '#35FF71', '#35FF71']
const COLORS2 = ['#8A3CC6', '#9A44DB', '#B24DFF', '#C477FF', '#C477FF', '#C477FF', '#C477FF', '#C477FF', '#C477FF', '#C477FF', '#C477FF', '#C477FF', '#C477FF', '#C477FF', '#C477FF', '#C477FF', '#C477FF', '#C477FF', '#C477FF', '#C477FF', '#C477FF', '#C477FF', '#C477FF', '#C477FF', '#C477FF', '#C477FF', '#C477FF', '#C477FF', '#C477FF', '#C477FF', '#C477FF', '#C477FF', '#C477FF', '#C477FF', '#C477FF', '#C477FF', '#C477FF', '#C477FF', '#C477FF', '#C477FF', '#C477FF', '#C477FF', '#C477FF', '#C477FF', '#C477FF', '#C477FF', '#C477FF', '#C477FF', '#C477FF', '#C477FF', '#C477FF', '#C477FF', '#C477FF', '#C477FF', '#C477FF', '#C477FF', '#C477FF', '#C477FF', '#C477FF', '#C477FF', '#C477FF', '#C477FF', '#C477FF', '#C477FF', '#C477FF', '#C477FF', '#C477FF', '#C477FF', '#C477FF', '#C477FF', '#C477FF', '#C477FF', '#C477FF', '#C477FF', '#C477FF', '#C477FF', '#C477FF', '#C477FF', '#C477FF', '#C477FF', '#C477FF', '#C477FF', '#C477FF', '#C477FF', '#C477FF', '#C477FF', '#C477FF', '#C477FF', '#C477FF', '#C477FF', '#C477FF', '#C477FF', '#C477FF', '#C477FF', '#C477FF', '#C477FF', '#C477FF', '#C477FF', '#C477FF', '#C477FF', '#C477FF', '#C477FF', '#C477FF', '#C477FF', '#C477FF', '#C477FF', '#C477FF', '#C477FF', '#C477FF', '#C477FF', '#C477FF', '#C477FF', '#C477FF', '#C477FF', '#C477FF', '#C477FF', '#C477FF', '#C477FF', '#C477FF', '#C477FF', '#C477FF', '#C477FF', '#C477FF', '#C477FF', '#C477FF', '#C477FF', '#C477FF', '#C477FF', '#C477FF']

export default function CourseRank(props) {
    const { width } = useDimensions()

    const [stateCourseBuy, setStateCourseBuy] = useState({
        activeIndex: 0
    })

    const onCourseBuyPieEnter = (_, index) => {
        setStateCourseBuy({
            activeIndex: index,
        })
    }

    const [stateCourseView, setStateCourseView] = useState({
        activeIndex: 0
    })

    const onCourseViewPieEnter = (_, index) => {
        setStateCourseView({
            activeIndex: index,
        })
    }

    const renderActiveShape = (props) => {
        const RADIAN = Math.PI / 180
        const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props
        const sin = Math.sin(-RADIAN * midAngle)
        const cos = Math.cos(-RADIAN * midAngle)
        const sx = cx + (outerRadius + 10) * cos
        const sy = cy + (outerRadius + 10) * sin
        const mx = cx + (outerRadius + 30) * cos
        const my = cy + (outerRadius + 30) * sin
        const ex = mx + (cos >= 0 ? 1 : -1) * genEx(width)
        const ey = my
        const textAnchor = cos >= 0 ? 'start' : 'end'

        return (
            <g>
                <text x={cx} y={cy - 25} dy={12} textAnchor="middle" fill={fill} style={{ fontWeight: "bold", fontSize: fontSizeHeaderPieChart(width) }}>
                    {payload.type === "view course" ? "เข้าชมคอร์ส" : []}
                    {payload.type === "buy course" ? "ซื้อคอร์ส" : []}
                </text>
                <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
                    {payload.name.length > 5 ? payload.name.substring(0, 20) + "..." : payload.name}
                </text>
                <Sector
                    cx={cx}
                    cy={cy}
                    innerRadius={innerRadius}
                    outerRadius={outerRadius}
                    startAngle={startAngle}
                    endAngle={endAngle}
                    fill={fill}
                />
                <Sector
                    cx={cx}
                    cy={cy}
                    startAngle={startAngle}
                    endAngle={endAngle}
                    innerRadius={outerRadius + 6}
                    outerRadius={outerRadius + (width > 768 ? 10 : 8)}
                    fill={fill}
                />
                <path d={`M${sx+4},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
                <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
                <text
                    x={ex + (cos >= 0 ? 1 : -1) * (width > 768 ? 12 : 6)} y={ey}
                    textAnchor={textAnchor}
                    fill="#333"
                    style={{ fontSize: (width > 768 ? 14 : 10) }}
                >
                    {payload.type === "buy course" ? `ซื้อ ${Intl.NumberFormat("en").format(Number(value))} ครั้ง ` : []}
                    {payload.type === "view course" ? `เข้าชม ${Intl.NumberFormat("en").format(Number(value))} ครั้ง ` : []}
                </text>
                <text
                    x={ex + (cos >= 0 ? 1 : -1) * (width > 768 ? 12 : 2)}
                    y={ey} dy={18} textAnchor={textAnchor}
                    fill="#999"
                    style={{ fontSize: (width > 768 ? 14 : 9) }}
                >
                    {`(คิดเป็น ${(percent * 100).toFixed(2)}%)`}
                </text>
            </g>
        );
    };

    const getGraphFound = (message, pdTop) => {
        return (
            <div style={{ paddingBottom: 50, width: '100%', paddingTop: pdTop }}>
                <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    imageStyle={{
                        height: 80
                    }}
                    style={{ width: '100%' }}
                    description={
                        <span style={{ color: 'gray' }}>{message}</span>
                    }
                >
                </Empty>
            </div>
        )
    }

    const genEx = (val) => {
        if (val >= 992) {
            return 22
        } else if (val >= 768 && val < 992) {
            return 18
        } else if (val >= 576 && val < 768) {
            return 14
        } else if (val < 576) {
            return 0
        } else {
            return 20
        }
    }

    const fontSizeHeaderPieChart = (val) => {
        if (val >= 992) {
            return 18
        } else if (val >= 768 && val < 992) {
            return 16
        } else if (val >= 576 && val < 768) {
            return 14
        } else if (val < 576) {
            return 10.5
        } else {
            return 20
        }
    }

    const genInnerRadius = (val) => {
        if (val >= 992) {
            return 80
        } else if (val >= 768 && val < 992) {
            return 60
        } else if (val >= 576 && val < 768) {
            return 50
        } else if (val >= 480 && val < 576) {
            return 40
        } else if (val < 480) {
            return 35
        } else {
            return "100%"
        }
    }

    const genOuterRadius = (val) => {
        if (val >= 992) {
            return 100
        } else if (val >= 768 && val < 992) {
            return 80
        } else if (val >= 576 && val < 768) {
            return 60
        } else if (val >= 480 && val < 576) {
            return 50
        } else if (val < 480) {
            return 40
        } else {
            return "100%"
        }
    }

    useEffect(() => {

    }, [props])

    return (
        <Row>
            {props.switchChartData === "1" || props.switchChartData === "2" ?
                <Col xs={24} lg={props.switchChartData === "2" ? 24 : 12}>
                    {props.data.courseVisit?.length > 0 ?
                        <Row>
                            <Col span={24} style={width > 576 ? { borderRight: '1px solid #EEEEEE' } : {}}>
                                <ResponsiveContainer
                                    width="99%"
                                    aspect={1.0 / (props.switchChartData === "2" ? 0.35 : 0.7)}
                                >
                                    <PieChart
                                        width="100%"
                                        height="100%"
                                    >
                                        <Pie
                                            activeIndex={stateCourseView.activeIndex}
                                            activeShape={renderActiveShape}
                                            data={props.data.courseVisit}
                                            cx="50%"
                                            cy="50%"
                                            innerRadius={genInnerRadius(width)}
                                            outerRadius={genOuterRadius(width)}
                                            fill="#23B14D"
                                            dataKey="value"
                                            onMouseEnter={onCourseViewPieEnter}
                                        >
                                            {props.data.courseVisit.map((entry, index) => (
                                                <Cell key={`cell-${index}`} fill={COLORS1[index % COLORS1.length]} />
                                            ))}
                                        </Pie>
                                    </PieChart>
                                </ResponsiveContainer>
                            </Col>

                            <Col span={24} style={{ paddingLeft: 5, paddingRight: 5, borderRight: '1px solid #EEEEEE' }}>
                                <div style={props.data?.courseVisit?.length > 3 ? { overflow: 'auto', overflowX: 'hidden', height: 200 } : {}}>
                                    {props.data.courseVisit.map((entry, index) => {
                                        return (
                                            <div
                                                key={index}
                                                style={{
                                                    padding: '6px',
                                                    background: index === 0 ? '#B9FFBC' : (index === 1 ? '#D9FFDB' : (index === 2 ? '#ECFFED' : 'white')),
                                                    // marginRight: '10px',
                                                    marginBottom: '10px',
                                                    borderRadius: '5px',
                                                    border: '1px solid #B9FFBC',
                                                }}
                                            >
                                                <span style={{ paddingRight: 5, fontWeight: "bold" }}>{index + 1})</span>
                                                <span>คอร์ส {entry.name}</span>
                                                <br />
                                                <span>{`เข้าชม ${Intl.NumberFormat("en").format(Number(entry.value))} ครั้ง `}</span>
                                                {`(${((entry.value * 100) / props.countTotal).toFixed(2)}%)`}
                                            </div>
                                        )
                                    })}
                                </div>
                            </Col>
                        </Row>
                        :
                        getGraphFound("ไม่พบข้อมูล ของอันดับซื้อคอร์ส", 40)
                    }
                </Col>
                : []
            }

            {props.switchChartData === "1" || props.switchChartData === "3" ?
                <Col xs={24} lg={props.switchChartData === "3" ? 24 : 12}>
                    {/* {console.log("props.data.courseBuy : ", props?.data?.courseBuy)} */}
                    {props.data.courseBuy?.length > 0 ?
                        <Row>
                            <Col span={24}>
                                <ResponsiveContainer
                                    width="99%"
                                    aspect={1.0 / (props.switchChartData === "3" ? 0.35 : 0.7)}
                                >
                                    <PieChart
                                        width="100%"
                                        height="100%"
                                    >
                                        <Pie
                                            activeIndex={stateCourseBuy.activeIndex}
                                            activeShape={renderActiveShape}
                                            data={props.data.courseBuy}
                                            cx="50%"
                                            cy="50%"
                                            innerRadius={genInnerRadius(width)}
                                            outerRadius={genOuterRadius(width)}
                                            fill="#8884d8"
                                            dataKey="value"
                                            onMouseEnter={onCourseBuyPieEnter}
                                        >
                                            {props.data.courseBuy.map((entry, index) => (
                                                <Cell key={`cell-${index}`} fill={COLORS2[index % COLORS2.length]} />
                                            ))}
                                        </Pie>
                                    </PieChart>
                                </ResponsiveContainer>
                            </Col>

                            <Col span={24} style={{ paddingLeft: 5, paddingRight: 5 }}>
                                <div style={props.data?.courseBuy?.length > 3 ? { overflow: 'auto', overflowX: 'hidden', height: 200 } : {}}>
                                    {props.data.courseBuy.map((entry, index) => {
                                        return (
                                            <div
                                                key={index}
                                                style={{
                                                    padding: '6px',
                                                    background: index === 0 ? '#C7C4FF' : (index === 1 ? '#D6D4FF' : (index === 2 ? '#E2E0FF' : 'white')),
                                                    // marginRight: '10px',
                                                    marginBottom: '10px',
                                                    borderRadius: '5px',
                                                    border: '1px solid #C7C4FF',
                                                }}
                                            >
                                                <span style={{ paddingRight: 5, fontWeight: "bold" }}>{index + 1})</span>
                                                <span>คอร์ส {entry.name}</span>
                                                <br />
                                                <span>{`ซื้อ ${entry.value} ครั้ง `}</span>
                                                {`(${((entry.value * 100) / props.countTotal).toFixed(2)}%)`}
                                            </div>
                                        )
                                    })}
                                </div>
                            </Col>
                        </Row>
                        :
                        getGraphFound("ไม่พบข้อมูล ของอันดับเข้าชมคอร์ส", 40)
                    }
                </Col>
                : []
            }
        </Row>
    )
}