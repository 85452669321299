/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */

import { useState, useEffect, useRef } from "react"
import { useNavigate } from 'react-router-dom'
import { Element } from 'react-scroll'
import { Layout } from 'antd'
import "../home/css/index.css"
import Marquees from "../../common/components/announce/Marquees"
import Banner from "../../common/components/banner"
import Feedback from "../../common/components/feedback"
import Footer from "../../common/components/footer"
import Header from "../../common/components/header"
import LoadingPage from "../../common/components/loading/page"
import { useDimensions } from "../../common/mamager/ScreenManager"
import CourseCategory from "../../modules/course/components/category"
import CourseSearch from "../../modules/course/components/search"
import Course from "../../modules/course"
import OpenCourse from "./components/openCourse"
import PointViewItem from "../../common/components/pointView/PointViewItem"
import { getAttendLatestCourseByUserIdFetch, getCourseCategoryFetch, getCourseRatingFetch, getCourseSearchByIdFetch } from "../course/API"
import { getBannerFetch, getCheckUserIdOnDBFetch, getCourseAlreadyTakenPlaceFetch } from "./API"
import { getUserByUidFetch } from "../../common/components/header/API"
import { getFeedbackFetch } from "../../common/components/feedback/API"

const { Content } = Layout

export default function Home({ authorized, firebase, user }) {
    const navigate = useNavigate()
    const language = "th"
    const { width } = useDimensions()
    const [courseGroupLatest, setCourseGroupLatest] = useState(null)
    const [courseGroupInterest, setCourseInterest] = useState([])
    const [attendLatestCourse, setAttendLatestCourse] = useState(null)
    const [bannerData, setBannerData] = useState([])
    const [courseCategory, setCourseCategory] = useState([])
    const [courseRating, setCourseRating] = useState([])
    const [feedback, setFeedback] = useState([])
    const [loading, setLoading] = useState(true)

    const userIdActiveOnDbRef = useRef(null)

    const [userInfo, setUserInfo] = useState(null)
    const [uid, setUid] = useState()

    const getAttendLatestCourseByUserId = async () => {
        const result = await getAttendLatestCourseByUserIdFetch({
            uid: firebase.auth().currentUser.uid,
            loadMoreActive: true,
            paginate: 2,
            language
        })
        setAttendLatestCourse(result)
        // console.log("getAttendLatestCourseByUserIdFetch : ", result)
    }

    const getCourseGroupLatest = async () => {
        setCourseGroupLatest(
            await getCourseById(
                null,       // category
                1,          // rating
                null,       // text
                true,       // loadMoreActive
                12,         // paginate
            )
        )
    }

    const randomCourseGroup = async (max) => {
        return Math.floor(Math.random() * max)
    }

    const getCourseGroupInterest = async (userInformation) => {
        try {
            // ------------- Random Course -------------
            const courseCategoryBeingPutToUse = await getCourseAlreadyTakenPlaceFetch()
            // console.log("getCourseAlreadyTakenPlaceFetch : ", courseCategoryBeingPutToUse)

            const courseCategoryBeingPutToUseId = courseCategoryBeingPutToUse.map((val) => { return val.courseCategoryId })

            const rankInterest = [
                userInformation.courseCategoryLike1Id,
                userInformation.courseCategoryLike2Id,
                userInformation.courseCategoryLike3Id
            ]

            let tempmatch
            for (let i = 0; i < rankInterest.length; i++) {
                tempmatch = courseCategoryBeingPutToUseId.find((val) => val === rankInterest[i])
                if (tempmatch) {
                    break
                }
            }

            if (tempmatch) {
                // INTEREST ====================
                setCourseInterest(
                    await getCourseById(
                        tempmatch,              // category
                        1,                      // rating
                        null,                   // text
                        true,                   // loadMoreActive
                        6,                      // paginate
                    )
                )
            } else {
                // RANDOM =====================
                let tmpArrRan = [];
                for (var i = 0; i < 3; i++) {
                    let loopCheckRan = true;

                    while (loopCheckRan) {
                        let tmpRan = await randomCourseGroup(courseCategoryBeingPutToUse.length)
                        let tmpResultCheck = tmpArrRan.find(
                            (ran) => ran === courseCategoryBeingPutToUse[tmpRan].courseCategoryId
                        );
                        if (tmpResultCheck === undefined) {
                            tmpArrRan = [
                                ...tmpArrRan,
                                ...[courseCategoryBeingPutToUse[tmpRan].courseCategoryId],
                            ];
                            loopCheckRan = false;
                        }
                    }
                }

                setCourseInterest(
                    await getCourseById(
                        tmpArrRan[0],           // category
                        1,                      // rating
                        null,                   // text
                        true,                   // loadMoreActive
                        6,                      // paginate
                    )
                );
            }
        } catch (e) {
            // console.log(e);
        }
    }

    const getCourseById = async (category, rating, text, loadMoreActive, paginate) => { // category is courseCategoryId
        let obj = {
            category,
            text,
            rating,
            search: null,
            uid: firebase.auth().currentUser.uid,
            loadMoreActive,
            paginate,
            language
        }
        const result = await getCourseSearchByIdFetch(obj)
        // console.log("getCourseSearchByIdFetch : ", result)
        return result
    }

    const getCourseCategory = async () => {
        const result = await getCourseCategoryFetch(language)
        setCourseCategory(result)
    }

    const getFeedback = async () => { // Done
        const result = await getFeedbackFetch()
        setFeedback(result)
    }

    const getCourseRating = async () => {
        const result = await getCourseRatingFetch(authorized, language)
        setCourseRating(result)
    }

    const getBanner = async () => {
        const result = await getBannerFetch()
        setBannerData(result)
    }

    const getUser = async () => {
        const result = await getUserByUidFetch(firebase.auth().currentUser.uid)
        setUserInfo(result)
        return result
    }

    const checkUserIdOnDB = async () => { // Done
        try {
            const result = await getCheckUserIdOnDBFetch(firebase.auth().currentUser.uid)
            userIdActiveOnDbRef.current = result ? true : false // true = have uid in database
        } catch (err) {
            userIdActiveOnDbRef.current = false
        }
    }

    const onAddFeedback = async (values) => {
        let tempFeedback = feedback
        tempFeedback.push({
            id: null,
            feedbackName: values.feedbackName,
            user: {
                userName: userInfo.userName,
                imageProfile: userInfo.imageProfile,
            }
        })

        setFeedback(tempFeedback)
    }

    const getRequestBaseApi = async () => {
        setLoading(true)

        await checkUserIdOnDB()

        if (authorized && userIdActiveOnDbRef.current) {
            const resultUser = await getUser()
            await getAttendLatestCourseByUserId()
            await getCourseGroupLatest()
            await getCourseGroupInterest(resultUser)
            await getCourseCategory()
            await getCourseRating()
            await getBanner()

            if (feedback.length === 0) {
                getFeedback();
            }

            // authen
            try {
                setUid(firebase.auth().currentUser.uid)
            } catch (err) {
                setUid(null)
            }
        } else {
            navigate("/login")
        }

        setLoading(false)
    }

    const handleCourseSearch = async (values) => { }

    useEffect(() => {
        getRequestBaseApi()
    }, [])

    return (
        <>
            {/* {userInfo?.providerId === "email/password" ? <VerifyIdentityEmail uid={firebase.auth().currentUser ? firebase.auth().currentUser.uid : null} /> : []} */}

            <Header firebase={firebase} user={user} />

            <Content style={{ backgroundColor: "#F5F5F5" }}>
                {!loading ?
                    <>
                        <div style={{ paddingBottom: 6, paddingTop: 6 }}>
                            <Banner bannerData={bannerData} />
                        </div>
                        <div>
                            <Marquees />
                        </div>
                        <div style={{ paddingBottom: 12, paddingTop: 12 }}>
                            <CourseSearch
                                uid={uid}
                                courseRating={courseRating}
                                courseCategory={courseCategory}
                                user={user}
                                isRedirectUrl={true}
                                handleCourseSearch={handleCourseSearch}
                                pageName="main"
                            />
                        </div>

                        <div
                            style={{
                                paddingLeft: (width >= 912 ? '5%' : 0),
                                paddingRight: (width >= 912 ? '5%' : 0)
                            }}
                        >
                            {attendLatestCourse?.courseItem?.length > 0 ?
                                <div style={{ paddingBottom: 5 }}>
                                    <Course
                                        uid={uid}
                                        courses={attendLatestCourse}
                                        isOpenCourseClassroom={true}
                                        isBoardLayoutHalve={true}
                                    />
                                </div>
                                : []
                            }
                        </div>

                        <OpenCourse
                            uid={firebase.auth().currentUser.uid}
                            attendLatestCourse={attendLatestCourse}
                        />

                        <div
                            style={{
                                paddingLeft: (width >= 912 ? '5%' : 0),
                                paddingRight: (width >= 912 ? '5%' : 0),
                                paddingTop: 16
                            }}
                        >
                            <Course uid={uid} courses={courseGroupLatest} />
                            <CourseCategory uid={uid} courseCategory={courseCategory} />
                            <Course uid={uid} courses={courseGroupInterest} />
                            <Element name="pointview">
                                <PointViewItem />
                            </Element>
                            <Feedback
                                feedback={feedback}
                                auth={firebase.auth()}
                                authorized={authorized}
                                onAddFeedback={onAddFeedback}
                            />
                        </div>
                    </>
                    :
                    <LoadingPage loading={loading} />
                }
            </Content>

            <Footer />
        </>
    )
}
