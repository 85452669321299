/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */

import { Button, Col, Row } from 'antd'
import { Icon } from '@iconify/react'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDimensions } from '../../../../common/mamager/ScreenManager'
import { getUserByUidFetch } from '../../../../common/components/header/API'
import { getAccountVerifyByIdFetch } from '../../../account/API'
import { detectSignIn } from '../../../../common/mamager/DetectManager'
import VerifyIdentityIdCard from '../../../../common/components/verify/idCard/VerifyIdentityIdCard'

export default function OpenCourse(props) {

    const navigate = useNavigate()
    const { width } = useDimensions()

    const [modalVerifyIdentityIdCardActive, setModalVerifyIdentityIdCardActive] = useState(false)
    const [accountVerify, setAccountVerify] = useState(null);
    const [userInformation, setUserInformation] = useState({ userTypeId: 2 })
    const [userInformationLoading, setUserInformationLoading] = useState()

    const handleVerifyIdentityIdCardModal = () => {
        setModalVerifyIdentityIdCardActive(!modalVerifyIdentityIdCardActive)
    }

    const getUserInformation = async () => {
        setUserInformationLoading(true)

        if (props?.uid) {
            let result = await getUserByUidFetch(props.uid ? props.uid : null)
            // console.log("getUserByUidFetch Header : ", result)
            setUserInformation(result)
        }

        setUserInformationLoading(false)
    }

    useEffect(() => {
        getUserInformation()
    }, [])

    const boardOpenCourseRight = () => {
        return (
            <Col xs={24} lg={12} style={{ backgroundColor: "white", paddingTop: 45, paddingBottom: 45 }}>
                <img
                    style={{ maxHeight: 250 }}
                    src="./assets/images/form-request-open-course/registration-open-courses.png"
                />
                <div style={{ paddingTop: 12, paddingLeft: 12, paddingRight: 12, fontSize: (width <= 768 ? 21 : 24) }}>
                    <span style={{ color: "#23B14D", fontWeight: "bold", height: 60 }}>
                        ทักษะ ความรู้ เเละความสามารถที่คุณมี
                    </span>
                    <br />
                    <span style={{ color: "#23B14D", fontWeight: "bold", height: 60 }}>
                        อาจเป็นประโยชน์ที่เปลี่ยนชีวิตให้กับใครบางคน
                    </span>
                </div>
            </Col>
        )
    }

    return (
        <>
            {!userInformationLoading ?
                <>
                    <div style={{ boxShadow: "0 2px 4px 0 rgba(0,0,0,.1)" }}>
                        <Row style={{ textAlign: "center" }}>
                            {width < 992 ? boardOpenCourseRight() : []}
                            <Col xs={24} lg={12} style={{ backgroundColor: "#23B14D" }}>
                                <Row
                                    style={{
                                        paddingTop: 95,
                                        paddingBottom: 95,
                                        color: "white",
                                        height: 425,
                                        backgroundImage: "url(./assets/images/form-request-open-course/background.jpg)",
                                        paddingLeft: 12, 
                                        paddingRight: 12
                                    }}
                                >
                                    <Col span={24}>
                                        <label
                                            style={{
                                                fontSize: width > 576 ? 40 : 35,
                                                fontWeight: "bold"
                                            }}
                                        >ลงคอร์สเรียนกับเราที่นี่</label>
                                        <Icon
                                            icon="fluent:arrow-down-12-filled"
                                            style={{
                                                color: "white",
                                                width: 45,
                                                height: 45,
                                                marginTop: -20
                                            }}
                                        />
                                    </Col>
                                    <Col span={24}>
                                        <label
                                            style={{
                                                fontSize: 30,
                                                fontWeight: "bold"
                                            }}
                                        >ฟรี !</label>
                                    </Col>
                                    <Col span={24} style={{ paddingTop: 12 }}>
                                        <Button
                                            size='large'
                                            type='default'
                                            style={{ color: "#23B14D", fontWeight: "bold", fontSize: 20, height: 60, paddingLeft: 24, paddingRight: 24 }}
                                            onClick={async () => {
                                                const isLogin = await detectSignIn()
                                                if (isLogin) {
                                                    const result = await getAccountVerifyByIdFetch(props.uid)
                                                    setAccountVerify(result)
                                                    if (result) {
                                                        if (result.isAccountVerifiedIdentify && result.isAccountVerifiedBank) {
                                                            navigate("/registration-open-course")
                                                        } else { // is open modal 
                                                            setModalVerifyIdentityIdCardActive(true)
                                                        }
                                                    }
                                                } else {
                                                    // Go to login page
                                                    navigate("/login")
                                                }
                                            }}
                                        >คลิก ลงทะเบียน</Button>
                                    </Col>
                                </Row>
                            </Col>
                            {width >= 992 ? boardOpenCourseRight() : []}
                        </Row>
                    </div>

                    <VerifyIdentityIdCard
                        modalVerifyIdentityIdCardActive={modalVerifyIdentityIdCardActive}
                        handleVerifyIdentityIdCardModal={handleVerifyIdentityIdCardModal}
                        businessOperationId={accountVerify ? accountVerify.businessOperationId : null}
                        user={props.user ? props.user : null}
                        providerId={userInformation ? userInformation.providerId : null}
                    />
                </>
                : []
            }
        </>
    )
}
