/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react'

import {
    Table,
    Row,
    Col,
    Button,
    Form,
    Input,
    Select,
    DatePicker,
    Popconfirm,
    Modal,
} from 'antd'
import moment from 'moment'
import { AiFillCheckCircle } from "react-icons/ai"
import { IoMdCloseCircle } from "react-icons/io"
import { RiDeleteBin6Line } from "react-icons/ri"
import { deleteFeedbackFetch, getInspectFeedbackFetch, updateInspectFeedbackFetch } from '../../API'

const { Option } = Select

const { RangePicker } = DatePicker

const columns = [
    {
        title: 'No.',
        dataIndex: 'index',
        width: "5%",
    },
    {
        title: 'Owner',
        dataIndex: 'ownerName',
        width: "15%",
    },
    {
        title: 'feedbackName',
        dataIndex: 'feedbackName',
        width: "25%",
    },
    {
        title: 'วันที่สร้าง',
        dataIndex: 'createdAt',
        width: "10%",
    },
    {
        title: 'ผลตรวจสอบ',
        dataIndex: 'inspect',
        width: "10%",
    },
    {
        title: 'สถานะตรวจสอบ',
        dataIndex: 'operation',
        // align: "center",
        width: "20%",
    },
]

const formatTime = "HH:mm"
const formatDateTime = "YYYY-MM-DD HH:mm"

export default function InspectFeedback() {
    const language = "th"

    const [form] = Form.useForm()

    const [list, setList] = useState([])
    const [loading, setLoading] = useState(false) // true

    const [modal, setModal] = useState({
        isOpen: false,
        id: null
    })

    const [pageCurrent, setPageCurrent] = useState(1)

    const onFinish = async (value) => {
        let ownerName = value.ownerName ? value.ownerName : null
        let startedDate = value.dateRange ? moment(value.dateRange[0]).format(formatDateTime) : null
        let endDate = value.dateRange ? moment(value.dateRange[1]).format(formatDateTime) : null
        let inspectionFeedback = value.inspectionFeedback ? value.inspectionFeedback : null

        let request = {
            ownerName, startedDate, endDate, inspectionFeedback
        }
        // console.log("request : ", request)

        if (!ownerName && !startedDate && !endDate && !inspectionFeedback) {
            getInspectRequestEditCourseAllApi("allData", {
                ownerName: null,
                startedDate: null,
                endDate: null,
                inspectionFeedback: null,
                language
            })
        } else {
            getInspectRequestEditCourseAllApi("bySearch", request)
        }
    }

    const getInspectRequestEditCourseAllApi = async (type, value) => {
        const result = await getInspectFeedbackFetch(type, value)

        let genData = []
        for (let i = 0; i < result.length; i++) {
            genData.push({
                index: (i + 1),
                ownerName: result[i].userInfo.userName,
                feedbackName: result[i].feedbackName,
                createdAt: moment(result[i].createdAt).format(formatDateTime),
                inspect: genInspectResult(result[i].isActive),
                operation: genButtonInspectionCourse(result[i]),
            })
        }
        setList(genData)
    }

    const onDetectFeedback = async (id) => {
        // console.log("Id : ", id)
        // console.log("--------------")

        await deleteFeedbackFetch(id)

        // reload
        getBaseApi()
    }

    const onInspectFeedback = async (type, id) => {
        // console.log("type : ", type)
        // console.log("Id : ", id)
        // console.log("--------------")

        let obj = {
            id,
            type
        }
        await updateInspectFeedbackFetch(obj)

        // reload
        getBaseApi()
    }

    const genInspectResult = (isActive) => {
        return (
            <>
                {isActive === 1 ?
                    <center><AiFillCheckCircle style={{ width: 25, height: 25, color: "green" }} /></center>
                    :
                    (isActive === 0 ? <center><IoMdCloseCircle style={{ width: 25, height: 25, color: "red" }} /></center> : [])}
            </>
        )
    }

    const genButtonInspectionCourse = (value) => {

        return (
            <>
                <Popconfirm
                    title="คุณยืนยันที่จะลบออกหรือไม่ ?"
                    okText={<span style={{ width: 50 }}>ใช่</span>}
                    onConfirm={() => {
                        onDetectFeedback(value.feedbackId)
                    }}
                    cancelText={<span style={{ width: 50 }}>ไม่ใช่</span>}
                >
                    <Button
                        icon={<RiDeleteBin6Line style={{ marginTop: -3, color: "white" }} />}
                        danger
                        type="primary"
                        style={{
                            width: 35,
                            borderRadius: 50
                        }}
                    ></Button>{" "}
                </Popconfirm>
                {value.isActive === null ?
                    <Button
                        type="primary"
                        style={{
                            width: 90,
                            borderRadius: 50
                        }}
                        onClick={() => {
                            setModal({
                                isOpen: true,
                                id: value.feedbackId
                            })
                        }}
                    >ตรวจสอบ</Button>
                    : []}
            </>
        )
    }

    const onPagine = (n) => {
        setPageCurrent(n.current)
    }

    const getBaseApi = async () => {
        await getInspectRequestEditCourseAllApi("allData", {
            ownerName: null,
            startedDate: null,
            endDate: null,
            inspectionFeedback: null,
            language
        })
    }

    useEffect(() => {
        setLoading(true)

        getBaseApi()

        setLoading(false)
    }, [])

    return (
        <>
            <Row>
                <Col span={5}>
                    <label>ตรวจสอบข้อความ feedback</label>
                </Col>
                <Col span={19} style={{ paddingBottom: 20 }}>
                    <Form
                        layout="vertical"
                        form={form}
                        onFinish={onFinish}
                    >
                        <Row>
                            <Col xs={12} lg={5}>
                                <Form.Item name="inspectionFeedback" >
                                    <Select
                                        showSearch
                                        style={{ width: '90%' }}
                                        placeholder="สถานะตรวจสอบ"
                                        optionFilterProp="children"
                                        allowClear
                                    >
                                        <Option value={'pass'}>ผ่าน</Option>
                                        <Option value={'not-pass'}>ไม่ผ่าน</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={12} lg={7}>
                                <Form.Item name="dateRange" >
                                    <RangePicker
                                        style={{ width: '90%' }}
                                        showTime={{ format: formatTime }}
                                        format={formatDateTime}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={12} lg={10}>
                                <Form.Item name="ownerName" >
                                    <Input
                                        style={{ width: '100%' }}
                                        placeholder="ชื่อผู้ใช้"
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={12} lg={2}>
                                <Button
                                    type="primary"
                                    onClick={() => form.submit()}
                                >
                                    ค้นหา
                                </Button>
                            </Col>
                        </Row>
                    </Form>

                </Col>
                <Col span={24}>
                    <Table
                        loading={loading}
                        columns={columns}
                        dataSource={list}
                        pagination={{
                            current: pageCurrent,
                            pageSize: 10,
                            total: list.length
                        }}
                        onChange={(n) => onPagine(n)}
                    >
                    </Table>
                </Col>
            </Row>

            <Modal
                title={"ตรวจสอบ"}
                visible={modal.isOpen}
                onCancel={() => {
                    setModal({
                        isOpen: false,
                        id: null
                    })
                }}
                width={300}
                footer={false}
            >
                <Row>
                    <Col span={12}>
                        <center>
                            <Button
                                style={{ width: 100 }}
                                type="primary"
                                onClick={() => {
                                    onInspectFeedback("pass", modal.id)

                                    setModal({
                                        isOpen: false,
                                        id: null
                                    })
                                }}
                            >
                                ผ่าน
                            </Button>
                        </center>
                    </Col>
                    <Col span={12}>
                        <center>
                            <Button
                                style={{ width: 100 }}
                                danger
                                type="primary"
                                onClick={() => {
                                    onInspectFeedback("not-pass", modal.id)

                                    setModal({
                                        isOpen: false,
                                        id: null
                                    })
                                }}
                            >
                                ไม่ผ่าน
                            </Button>
                        </center>
                    </Col>
                </Row>
            </Modal>
        </>
    )
}
