/* eslint-disable react-hooks/exhaustive-deps */

import { useState, useEffect } from 'react'
import { Navigate } from 'react-router-dom'
import { getFetchUid } from '../../API'

export default function LoginDetect({ firebase, authorized }) {
    const [registered, setRegistered] = useState(false)
    const [redirect, setRedirect] = useState(false)
    let sizeProviderData = 0

    const fetchUID = async () => {
        let request = null
        try {
            sizeProviderData = await firebase.auth().currentUser.providerData.length - 1
            let request = await getFetchUid(firebase.auth().currentUser.uid)
            if (request.length > 0) {
                setRegistered(true)
            }
        } catch (err) {
            // console.log(err)
        }
        setRedirect(true)
        return request
    }

    useEffect(() => {
        fetchUID()
    }, [])
    
    return (
        <>
            {redirect ?
                <>
                    {registered ?
                        <Navigate to={{ pathname: "/home" }} />
                        :
                        <Navigate
                            to={{
                                pathname: "/register",
                                state: {
                                    authorized: authorized,
                                    displayName: firebase.auth().currentUser.providerData[sizeProviderData].displayName,
                                    email: firebase.auth().currentUser.providerData[sizeProviderData].email,
                                    phoneNumber: firebase.auth().currentUser.providerData[sizeProviderData].phoneNumber,
                                    photoURL: firebase.auth().currentUser.providerData[sizeProviderData].photoURL,
                                    providerId: firebase.auth().currentUser.providerData[sizeProviderData].providerId,
                                    socialMediaId: firebase.auth().currentUser.providerData[sizeProviderData].uid,
                                    uid: firebase.auth().currentUser.uid,
                                    access: true
                                }
                            }}
                        />
                    }
                </>
                : []
            }
        </>
    )
}
