import { 
    HTTP_COURSE_MANAGE_DESCRIPTION_FETCHING, 
    HTTP_COURSE_MANAGE_DESCRIPTION_SUCCESS, 
    HTTP_COURSE_MANAGE_DESCRIPTION_FAILED, 
} from "../constants"

export const setCourseManageDescriptionStateToFetching = () => ({
    type: HTTP_COURSE_MANAGE_DESCRIPTION_FETCHING,
})

export const setCourseManageDescriptionStateToSuccess = (payload) => ({
    type: HTTP_COURSE_MANAGE_DESCRIPTION_SUCCESS,
    payload
})

export const setCourseManageDescriptionStateToFailed = () => ({
    type: HTTP_COURSE_MANAGE_DESCRIPTION_FAILED,
})