

import { server } from "../../../constants"
import { httpClient } from "../../../utils/HttpClient"

const sendEmailServiceFetch = async (value) => { // Done
    try {
        const result = await httpClient.post(server.SEND_EMAIL_SERVICE_URL, { value })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

export {
    // get
    sendEmailServiceFetch

    // insert

    // update

    // delete

}
