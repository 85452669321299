import { Content } from "antd/lib/layout/layout"
import Footer from "../../../../common/components/footer"
import Header from "../../../../common/components/header"
import { useDimensions } from "../../../../common/mamager/ScreenManager"
import ServiceCancelPolicyItem from "./ServiceCancelPolicyItem"

export default function ServiceCancelPolicy(props) {
    const { width } = useDimensions()

    return (
        <>
            <Header
                firebase={props.firebase}
                user={props.user}
            />

            <article>
                <Content style={{ backgroundColor: "#F5F5F5" }}>
                    <div style={width >= 1500 ? { paddingLeft: '20%', paddingRight: '20%' } : ((width > 1200 && width < 1500) ? { paddingLeft: '10%', paddingRight: '10%' } : { paddingLeft: '0%', paddingRight: '0%' })}>
                        <div style={{ backgroundColor: "white", paddingTop: 80, paddingLeft: (width > 1000 ? 80 : 24), paddingRight: (width > 1000 ? 80 : 24), paddingBottom: 80 }}>
                            <ServiceCancelPolicyItem headerSize={18} />
                        </div>
                    </div>
                </Content>
            </article>

            <Footer />
        </>
    )
}
