import { useEffect } from "react"
import {
    Image,
    Card,
    Row,
    Col
} from 'antd'
import Footer from "../../common/components/footer"
import Header from "../../common/components/header"
import { useDimensions } from "../../common/mamager/ScreenManager"
import LoginItem from "./LoginItem"
import "../login/css/index.css"

export default function Login({ authorized, firebase, user }) {
    const { width } = useDimensions()

    useEffect(() => {

    }, [authorized])

    return (
        <>
            <Header firebase={firebase} authorized={authorized} user={user} btnAutnActive={false} />

            <article>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <Row
                        style={width >= 1500 ?
                            { paddingTop: '2%', paddingRight: '10%', paddingLeft: '10%', paddingBottom: '10%' }
                            :
                            ((width > 912 && width < 1500) ?
                                { paddingTop: '5%', paddingRight: '5%', paddingLeft: '5%', paddingBottom: '5%' }
                                :
                                { paddingTop: '2%', paddingRight: '2%', paddingLeft: '2%', paddingBottom: '2%' }
                            )
                        }
                    >
                        <Col xs={24} sm={24} md={12} lg={12} xl={14} style={{ padding: '0%', display: 'flex', justifyContent: "center" }}>
                            <Image
                                width={"100%"}
                                height={"auto"}
                                src="error"
                                preview={false}
                                fallback="./assets/images/login/img-login.png"
                            />
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={10} style={{ paddingTop: 24, paddingBlock: 24 }}>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <Card
                                    style={{
                                        minWidth: 300, // width >= 1500 ? "450px" : ((width > 712 && width < 1500) ? "450px" : "100%"),
                                        maxWidth: 440,
                                        borderRadius: 20,
                                        boxShadow: "2px 2px 16px 5px rgba(208, 216, 243, 1)",
                                    }}
                                >
                                    <Row gutter={[10, 10]} style={{ paddingTop: 24, paddingBottom: 24 }}>
                                        <Col span={width >= 1500 ? 3 : ((width > 912 && width < 1500) ? 2 : 1)}></Col>
                                        <Col span={width >= 1500 ? 18 : ((width > 912 && width < 1500) ? 20 : 22)}><LoginItem firebase={firebase} authorized={authorized} /></Col>
                                        <Col span={width >= 1500 ? 3 : ((width > 912 && width < 1500) ? 2 : 1)}></Col>
                                    </Row>
                                </Card>
                            </div>
                        </Col>
                    </Row>
                </div>
            </article>

            <Footer />
        </>
    )
}

