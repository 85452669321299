/* eslint-disable no-loop-func */
/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from "react-router-dom"
import { Layout, Row, Col, Menu, Avatar } from 'antd'
import { BsFillBellFill } from "react-icons/bs"
import ChatItem from './ChatItem'
import Footer from '../footer'
import Header from '../header'
import { getCourseByUserIdFetch } from '../../../modules/course/API'
import { useDimensions } from '../../mamager/ScreenManager'

const { Content } = Layout

export default function ChatLearner(props) {
    const navigate = useNavigate()

    const db = props.firebase.firestore()

    const { width } = useDimensions()

    const location = useLocation()
    const params = new URLSearchParams(location.search)
    // const courseId = params.get("course-id")
    const courseOwner = params.get("course-owner")
    const toMember = params.get("to-member")

    const [courseId, setCourseId] = useState() // params.get("course-id")

    const [chatRoomList, setChatRoomList] = useState([])
    const [roomId, setRoomId] = useState(null)

    const detectOwnerChat = () => {
        if (props.user.uid !== courseOwner && props.user.uid !== toMember) {
            navigate("/")
        }
        return false
    }

    const genRoomAll = async () => {
        let queryRoom = null
        let queryMessage = null

        if (db) {
            queryRoom = db.collection("chat_room")
            queryRoom = queryRoom.where("chatRoomBuilderId", "==", props.user.uid)
            await queryRoom.onSnapshot(async (val1) => {
                let dataRoom = val1.docs.map((doc) => ({
                    ...doc.data(),
                    id: doc.id,
                }))

                // ---------------
                if (dataRoom?.length > 0) {
                    // get chat_message --------------------------------------------------------------------------
                    queryMessage = db.collection("chat_message").orderBy("createAt");
                    queryMessage = queryMessage.where("chatMessageHeaderId", "==", courseOwner);
                    queryMessage = queryMessage.where("chatMessageIsReadStatus", "==", false);
                    await queryMessage.onSnapshot(async (val2) => {
                        let dataMessage = val2.docs.map((doc) => ({
                            ...doc.data(),
                            id: doc.id,
                        }))

                        // console.log("dataMessage : ", dataMessage);

                        let tempDataRoom = []
                        for (let i = 0; i < dataRoom.length; i++) {
                            let obj = {
                                courseId: dataRoom[i].chatCourseId,
                                uid: dataRoom[i].chatRoomHeaderId
                            }
                            let courseName = ""
                            let courseImage = ""
                            let resultCourse = await getCourseByUserIdFetch(obj)
                            if (resultCourse) {
                                courseName = resultCourse.courseTitleName
                                courseImage = resultCourse.courseImageLocation
                            }
                            // console.log("getCourseByUserIdFetch : ", result)

                            let tempBuilderWaitRead = dataMessage.filter(item => item.chatCourseId === dataRoom[i].chatCourseId && item.chatToMemberId === props.user.uid)
                            // console.log("tempBuilderWaitRead : ", tempBuilderWaitRead, tempBuilderWaitRead?.length, props.user.uid)

                            tempDataRoom.push({
                                chatRoomId: dataRoom[i].chatRoomId,
                                chatCourseId: dataRoom[i].chatCourseId,
                                chatRoomName: dataRoom[i].chatRoomName,
                                isActive: dataRoom[i].isActive,
                                updateAt: dataRoom[i].updateAt,
                                chatRoomHeaderId: dataRoom[i].chatRoomHeaderId,
                                chatRoomBuilderId: dataRoom[i].chatRoomBuilderId,
                                chatRoomCourseName: courseName?.length > 30 ? courseName?.substr(0, 30) + "..." : courseName,
                                chatRoomCourseImage: courseImage,
                                waitRead: tempBuilderWaitRead ? tempBuilderWaitRead.length : 0
                            })
                        }
                        dataRoom = tempDataRoom

                        // console.log("dataRoom : ", dataRoom)
                        setChatRoomList(dataRoom)
                    })
                }
                // ---------------
            })
        }
    }

    useEffect(() => {
        detectOwnerChat()
        genRoomAll()

        // Detect listener
        return navigate((location) => {
            localStorage.setItem('toggleTabChat', 1) // off snap
            localStorage.setItem('lsRoomId', null)
        })
    }, [])

    return (
        <div>
            <Header
                firebase={props.firebase}
                authorized={props.authorized}
                user={props.user}
            />

            <article>
                <Content>
                    <div
                        style={
                            width > 1500 ?
                                { backgroundColor: "white", marginLeft: '5%', marginRight: '5%' }
                                :
                                (
                                    (width > 912 && width <= 1500) ?
                                        { backgroundColor: "white", marginLeft: '5%', marginRight: '5%' }
                                        :
                                        { backgroundColor: "white", marginLeft: '0%', marginRight: '0%' }
                                )
                        }
                    >
                        <Row>
                            <Col
                                span={24}
                                style={{
                                    borderLeft: '1px solid #DDDDDD',
                                    borderRight: '1px solid #DDDDDD',
                                    borderTop: '1px solid #DDDDDD',
                                    paddingTop: 15, paddingLeft: 20, paddingRight: 15, paddingBottom: 10
                                }}>
                                <label>กล่องข้อความ : ผู้เรียน</label>
                            </Col>
                            <Col span={24}>
                                <div style={{ display: "flex", alignItems: "flex-start" }}>
                                    <div style={{ overflow: "auto", height: 477, width: width > 912 ? 350 : 150, borderBottom: '1px solid #DDDDDD' }}>
                                        <Menu
                                            selectedKeys={[roomId]}
                                            style={{ border: '1px solid #DDDDDD', height: 477 }}
                                            onClick={(e) => {
                                                // console.log("Clicked : ", e.key, chatRoomList?.find(item => item.chatRoomId === e.key)?.chatCourseId)
                                                setCourseId(chatRoomList?.find(item => item.chatRoomId === e.key)?.chatCourseId)
                                                localStorage.setItem('lsRoomId', e.key)
                                                localStorage.setItem('toggleTabChat', 3)

                                                setRoomId(e.key)
                                            }}
                                        >
                                            {chatRoomList.map(item =>
                                                <Menu.Item
                                                    key={item.chatRoomId}
                                                    style={{
                                                        height: 65,
                                                        borderBottom: '1px solid #DDDDDD'
                                                    }}
                                                >
                                                    <div style={{ display: "flex", alignItems: "center" }}>
                                                        <div style={{ display: "flex", alignItems: "center" }}>
                                                            <Avatar style={{ height: 45, width: 45, marginTop: 9 }} src={item.chatRoomCourseImage} />
                                                        </div>


                                                        <Row gutter={[0, 0]} style={{ paddingLeft: 10 }}>
                                                            <Col span={24} style={{ display: "flex", alignItems: "center" }}>
                                                                <label style={{ paddingBottom: 0, marginBottom: 0, cursor: "pointer" }}>{item.chatRoomCourseName}</label>
                                                            </Col>

                                                            {item.waitRead > 0 ?
                                                                <Col span={24} style={{ display: "flex", alignItems: "center" }}>
                                                                    <BsFillBellFill style={{ color: "green", cursor: "pointer" }} />
                                                                    <label style={{ paddingLeft: 5, cursor: "pointer" }}>{item.waitRead}</label>
                                                                </Col>
                                                                : []
                                                            }
                                                        </Row>
                                                    </div>
                                                </Menu.Item>
                                            )}
                                        </Menu>
                                    </div>

                                    <div style={{ display: "grid", alignItems: "center", width: '100%' }}>
                                        <ChatItem
                                            // authorized={props.authorized}
                                            db={props.firebase.firestore()}
                                            learnerId={props.user.uid}
                                            teacherId={courseOwner}
                                            courseId={courseId}
                                            chatType="learner-manage"
                                            roomId={roomId}
                                        />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Content>
            </article>

            <Footer />
        </div>
    )
}

