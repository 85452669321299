/* eslint-disable react-hooks/exhaustive-deps */

import { useState } from "react"
import {
    Form,
    Button,
    Row,
    Col,
    Upload,
    Image,
    // Input
} from "antd"
import {
    LoadingOutlined,
    UploadOutlined
} from "@ant-design/icons"
import ImgCrop from "antd-img-crop"
import { Icon } from '@iconify/react'
import { AiOutlineUpload } from 'react-icons/ai'
import { useDimensions } from "../../../../common/mamager/ScreenManager"
import LoadingPage from "../../../../common/components/loading/page"
import { apiStorageUrl } from "../../../../constants"
import { updateUser } from "../../API"
import { Notification } from "../../../../common/components/notification"
import { resizeImage } from "../../../../common/mamager/ImageMamager"
// import { detectPhone } from "../../../../common/mamager/DetectManager"

export default function Profile(props) {
    const [loading, setLoading] = useState(false)
    const { width } = useDimensions()
    const [form] = Form.useForm()

    const [courseCoverImage, setCourseCoverImage] = useState({
        loading: false,
        imageProfile: props.user ? (props.user.imageProfile ? props.user.imageProfile : null) : null
    })

    const onFinish = async (values) => {
        setLoading(true)

        let objUser = {
            uid: props.user ? (props.user.uid ? props.user.uid : null) : null,
            phone: values.phone ? values.phone : null,
            imageProfile: courseCoverImage.imageProfile ? courseCoverImage.imageProfile : null
        }
        const result = await updateUser(objUser)

        if (result.isSuccess) {
            Notification("success", "ระบบ", "บันทึกสำเร็จ")
            props.handleReload()
        } else {
            Notification('error', 'ไม่สามารถบันทึกข้อมูลได้ กรุณาลองใหม่อีกครั้ง')
        }

        setLoading(false)
    }

    const getBase64 = (img, callback) => {
        const reader = new FileReader()
        reader.addEventListener("load", () => callback(reader.result))
        reader.readAsDataURL(img)
    }

    const handleChange = (info) => {
        if (info.file.status !== "uploading") {
            resizeImage(info.file.originFileObj, 300, 300).then((res) => {
                getBase64(res, (imageProfile) => {
                    setCourseCoverImage({
                        loading: false,
                        imageProfile
                    })
                })
            })
        } else {
            setCourseCoverImage({ loading: true })
        }

        if (info.file.status === "done") {
            Notification("success", "ระบบ", "อัพโหลดรูปภาพสำเร็จ")
        } else if (info.file.status === "error") {
            Notification("error", "ระบบ", "อัพโหลดรูปภาพไม่สำเร็จ")
        }
    }

    return (
        <Form
            form={form}
            layout="vertical"
            initialValues={{
                userName: props.user.userName,
                email: props.user.email,
                phone: props.user.phone
            }}
            onFinish={onFinish}
        >
            {!loading ?
                <Row gutter={[16, 0]}>
                    <Col xs={24} xl={8}>
                        <Row>
                            <Col span={24}>
                                <center>
                                    {courseCoverImage.imageProfile ?
                                        <Image
                                            style={{ height: "160px", width: "160px", borderRadius: "50%" }}
                                            src={courseCoverImage.imageProfile}
                                            preview={false}
                                        />
                                        :
                                        <Image
                                            style={{ height: "160px", width: "160px", borderRadius: "50%" }}
                                            src={props.user.imageProfile !== null ? props.user.imageProfile : `./assets/images/profile/personDefault.jpg`}
                                            preview={false}
                                        />
                                    }
                                </center>
                            </Col>

                            <Col span={24} style={{ paddingTop: 15 }}>
                                <center>
                                    <Form.Item name="uploadCourseCoverImage">
                                        <ImgCrop
                                            modalTitle={<label style={{ fontWeight: 100 }}>ปรับเเต่งรูปภาพ</label>}
                                            modalOk={
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <AiOutlineUpload style={{ width: 20, height: 20 }} />
                                                    <label style={{ paddingLeft: 10, cursor: "pointer" }}>อัพโหลด</label>
                                                </div>
                                            }
                                            modalCancel={
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <Icon icon="mdi:cancel" style={{ width: 20, height: 20 }} />
                                                    <label style={{ paddingLeft: 10, cursor: "pointer" }}>ยกเลิก</label>
                                                </div>
                                            }
                                            aspect={300 / 300}
                                        >
                                            <Upload
                                                accept=".jpeg, .jpg, .png"
                                                maxCount={1}
                                                showUploadList={{ showRemoveIcon: false }}
                                                action={`${apiStorageUrl}/upload/active`}
                                                onChange={handleChange}
                                            >
                                                <Button
                                                    type="primary"
                                                    style={{ width: 200 }}
                                                    icon={courseCoverImage.loading ? <LoadingOutlined /> : <UploadOutlined />}
                                                >
                                                    อัพโหลด
                                                </Button>
                                            </Upload>
                                        </ImgCrop>
                                    </Form.Item>
                                </center>
                            </Col>
                        </Row>
                    </Col>

                    <Col xs={24} xl={16}>
                        <Row gutter={[16, 16]} style={{ paddingBottom: 16 }}>
                            <Col xs={24} md={6} xl={4} style={{ textAlign: (width > 808 ? "right" : "left") }}>
                                ชื่อผู้ใช้งาน
                            </Col>
                            <Col xs={24} md={18} xl={20} >
                                {props.user.userName}
                            </Col>

                            <Col xs={24} md={6} xl={4} style={{ textAlign: (width > 808 ? "right" : "left") }}>
                                อีเมล
                            </Col>
                            <Col xs={24} md={18} xl={20} >
                                {props.user.email}
                            </Col>

                            <Col xs={24} md={6} xl={4} style={{ textAlign: (width > 808 ? "right" : "left") }}>
                                เบอร์โทร
                            </Col>
                            <Col xs={24} md={18} xl={20} >
                                {props.user.phone}
                            </Col>
                        </Row>
                    </Col>

                    <Col span={24} style={{ borderTop: "1px solid #eeeeee" }}>
                        <div style={{ paddingTop: 16, paddingBottom: 16, paddingLeft: 8, paddingRight: 8, display: 'flex', alignItems: 'center' }}>
                            <label style={{ paddingRight: 5 }}>เข้าสู่ระบบโดย</label>
                            {props.user.providerId === "google.com" ?
                                <>
                                    <Icon icon="ant-design:google-square-filled" style={{ color: "red", width: 20, height: 20 }} />
                                    <label style={{ paddingLeft: 5 }}>Google</label>
                                </>
                                : []
                            }

                            {props.user.providerId === "facebook.com" ?
                                <>
                                    <Icon icon="bxl:facebook-square" style={{ color: "#0080ff", width: 20, height: 20 }} />
                                    <label style={{ paddingLeft: 5 }}>Facebook</label>
                                </>
                                : []
                            }

                            {props.user.providerId === "email/password" ?
                                <>
                                    <Icon icon="clarity:email-solid" style={{ color: "#23b14d", width: 20, height: 20 }} />
                                    <label style={{ paddingLeft: 5 }}>Email/Password</label>
                                </>
                                : []
                            }
                        </div>
                    </Col>

                    <Col span={24} style={{ textAlign: "center", borderTop: "1px solid #eeeeee" }}>
                        <div style={{ paddingTop: 15 }}>
                            <Button type="primary" onClick={() => form.submit()}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Icon icon="bx:save" style={{ width: 19, height: 19, color: "white" }} />
                                    <label style={{ paddingLeft: 10, cursor: "pointer" }}>บันทึกข้อมูล</label>
                                </div>
                            </Button>
                        </div>
                    </Col>
                </Row>
                :
                <LoadingPage loading={loading} height={190} />
            }
        </Form>
    )
}
