/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react-hooks/exhaustive-deps */

import { useState, useEffect } from 'react'
import {
    Col,
    Row,
    Form,
    Select,
    DatePicker,
    Input,
    Button,
    Table,
    Modal,
    Image,
    Radio
} from 'antd'
import moment from 'moment'
import { AiFillCheckCircle } from "react-icons/ai"
import { IoMdCloseCircle } from "react-icons/io"
import { getInspectAccountVerifyFetch, getInspectFileRegistrationCertificateFetch, updateInspectAccountVerifyBankFetch, updateInspectAccountVerifyFetch, updateInspectAccountVerifyIdentifyFetch, updateInspectFileRegistrationCertificateFetch, updateIsHasBeenAccountVerifiedBankById } from '../../API'
import { getฺBankCategoryAllFetch } from '../../../account/API'
import { inspectBankAccountNotPass, inspectBankAccountPass, inspectIdCardAccountNotPass, inspectIdCardAccountPass } from '../../../../common/mamager/EmailMamager'
import { Notification } from '../../../../common/components/notification'
import { emailSubject, emailSupport, storageUrl } from '../../../../constants'

const { Option } = Select
const { RangePicker } = DatePicker
const formatTime = "HH:mm"
const formatDateTime = "YYYY-MM-DD HH:mm"

const columns = [
    {
        title: 'No.',
        dataIndex: 'index',
        width: "5%",
    },
    {
        title: 'Owner',
        dataIndex: 'ownerName',
        width: "15%",
    },
    {
        title: 'วันที่สร้าง',
        dataIndex: 'createdAt',
        width: "10%",
    },
    {
        title: 'ผลตรวจสอบ',
        dataIndex: 'inspect',
        width: "10%",
    },
    {
        title: 'สถานะตรวจสอบ',
        dataIndex: 'operation',
        align: "center",
        width: "20%",
    },
]

export default function VerifyIdentity() {
    const language = "th"

    const [form] = Form.useForm()
    const [formInspectDetail] = Form.useForm()

    const [list, setList] = useState([])
    const [loading, setLoading] = useState(false) // true

    const [modal, setModal] = useState({
        isOpen: false,
        data: null,
        openInspect: true
    })

    const [pageCurrent, setPageCurrent] = useState(1)
    const [bankCategory, setBankCategory] = useState([])
    const [accountVerify, setAccountVerify] = useState([])
    const [accountVerifyDetail, setAccountVerifyDetail] = useState([])

    const onPagine = (n) => {
        setPageCurrent(n.current)
    }

    const onFinish = async (values) => {
        let ownerName = values.ownerName ? values.ownerName : null
        let startedDate = values.dateRange ? moment(values.dateRange[0]).format(formatDateTime) : null
        let endDate = values.dateRange ? moment(values.dateRange[1]).format(formatDateTime) : null
        let inspectVerify = values.inspectVerify ? values.inspectVerify : null

        let request = {
            ownerName, startedDate, endDate, inspectVerify
        }

        if (!ownerName && !startedDate && !endDate && !inspectVerify) {
            await getInspectAccountVerify("allData", {
                ownerName: null,
                startedDate: null,
                endDate: null,
                isInspect: null,
                language
            })
        } else {
            await getInspectAccountVerify("bySearch", request)
        }
    }

    const onInspectDetailFinish = async (values) => {
        // console.log("values : ", values);
        try {
            let objEmail = {
                ownerName: modal.data.userInfo.userName
            }

            let resultUpdateInspectAccountVerify = { isSuccess: true }
            let resultUpdateInspectAccountVerifyIdentify = { isSuccess: true }
            let resultUpdateInspectAccountVerifyBank = { isSuccess: true }
            let resultFileRegistrationCertificate = { isSuccess: true }

            // verify ---------------------------------
            let isInspectVerifiedIdentify = false;
            let findAccountVerifiedRegistrationCertificate = []
            if (modal.data.businessOperationId === 1) {
                isInspectVerifiedIdentify = (
                    // values.isInspectIdCardBack === 1 &&
                    // values.isInspectIdCardFace === 1 &&
                    values.isInspectIdCardFront === 1 &&
                    values.isInspectIdCardInfo === 1
                ) ? true : false;
            } else if (modal.data.businessOperationId === 2) {
                findAccountVerifiedRegistrationCertificate = accountVerifyDetail.filter(item => item.fileRegCerApprovalId === 2)
                if (findAccountVerifiedRegistrationCertificate.length === 0 && values.companyInfoApproval === 1) {
                    isInspectVerifiedIdentify = true;
                }
                // console.log("isInspectVerifiedIdentify : ", isInspectVerifiedIdentify)
            }

            if (!accountVerify?.isAccountVerifiedBank || !accountVerify?.isInspectVerifiedIdentify) {
                let obj = {
                    isAccountVerifiedIdentify: isInspectVerifiedIdentify,

                    isAccountVerifiedBank: (
                        values.isInspectFileBank === 1 &&
                        values.isInspectBankInfo === 1
                    ) ? true : false,
                    isOpenVerifyBankAccount: accountVerify?.isOpenVerifyBankAccount, // ***

                    countInspect: (accountVerify?.countInspect + 1),

                    accountVerifiedId: accountVerify?.accountVerifiedId
                }
                // console.log("objAccountVerify : ", obj);
                resultUpdateInspectAccountVerify = await updateInspectAccountVerifyFetch(obj)
            }

            // identify --------------------------------
            if (accountVerify?.accountVerifiedIdentify.approvalStatusId !== 1) {
                let obj = null
                if (modal.data.businessOperationId === 1) { // บุลคลธรรมดา
                    obj = {
                        accountVerifiedIdentifyId: accountVerify?.accountVerifiedIdentify.accountVerifiedIdentifyId,
                        approvalStatusId: isInspectVerifiedIdentify ? 1 : 2, // 1 ผ่าน 2 ไม่ผ่าน
                        isInspectIdCardBack: null, // values.isInspectIdCardBack,
                        isInspectIdCardFace: null, // values.isInspectIdCardFace,
                        isInspectIdCardFront: values.isInspectIdCardFront,
                        isInspectIdCardInfo: values.isInspectIdCardInfo,
                        isInspectRegistrationCertificate: null,
                        isInspectCompanyInfo: null
                    }
                } else if (modal.data.businessOperationId === 2) { // นิติบุคคล
                    obj = {
                        accountVerifiedIdentifyId: accountVerify?.accountVerifiedIdentify.accountVerifiedIdentifyId,
                        approvalStatusId: isInspectVerifiedIdentify ? 1 : 2, // 1 ผ่าน 2 ไม่ผ่าน
                        isInspectIdCardBack: null,
                        isInspectIdCardFace: null,
                        isInspectIdCardFront: null,
                        isInspectIdCardInfo: null,
                        isInspectRegistrationCertificate: findAccountVerifiedRegistrationCertificate.length === 0 ? 1 : 2, // 1 ผ่าน 2 ไม่ผ่าน
                        isInspectCompanyInfo: values.companyInfoApproval
                    }

                    resultFileRegistrationCertificate = await updateInspectFileRegistrationCertificateFetch(accountVerifyDetail)
                }
                // console.log("objAccountVerifyIdentify : ", obj)
                const resultAccountVerifyIdentify = await updateInspectAccountVerifyIdentifyFetch(obj)

                if (resultAccountVerifyIdentify.isSuccess && resultFileRegistrationCertificate.isSuccess) {
                    resultUpdateInspectAccountVerifyIdentify = { isSuccess: true }
                } else {
                    resultUpdateInspectAccountVerifyIdentify = { isSuccess: false }
                }

                // send email
                if (isInspectVerifiedIdentify) {
                    inspectIdCardAccountPass(
                        emailSupport,                           // support@toflyn.com
                        modal.data.userInfo.email,        // email of user
                        emailSubject + "เเจ้งผล การยืนยันตัวตนด้วยบัตรประชาชน ผ่านการตรวจสอบ",
                        objEmail,
                    )
                } else {
                    inspectIdCardAccountNotPass(
                        emailSupport,                           // support@toflyn.com
                        modal.data.userInfo.email,        // email of user
                        emailSubject + "เเจ้งผล การยืนยันตัวตนด้วยบัตรประชาชน ไม่ผ่านการตรวจสอบ",
                        objEmail,
                    )
                }
            }

            // bank -----------------------------------
            if (accountVerify?.isOpenVerifyBankAccount && accountVerify?.accountVerifiedBank.approvalStatusId !== 1) {
                let approvalStatusId = (
                    values.isInspectFileBank === 1 &&
                    values.isInspectBankInfo === 1
                ) ? 1 : 2

                let obj = {
                    accountVerifiedBankId: accountVerify?.accountVerifiedBank.accountVerifiedBankId,
                    approvalStatusId,
                    isInspectFileBank: values.isInspectFileBank,
                    isInspectBankInfo: values.isInspectBankInfo
                }
                // console.log("objAccountVerifyBank : ", obj);
                resultUpdateInspectAccountVerifyBank = await updateInspectAccountVerifyBankFetch(obj)

                // send email
                if (approvalStatusId === 1) {
                    inspectBankAccountPass(
                        emailSupport,                           // support.toflyn.com
                        modal.data.userInfo.email,        // email of user
                        emailSubject + "เเจ้งผล การยืนยันตัวตนด้วยบัญชีธนาคาร ผ่านการตรวจสอบ",
                        objEmail,
                    )
                } else if (approvalStatusId === 2) {
                    inspectBankAccountNotPass(
                        emailSupport,                           // support.toflyn.com
                        modal.data.userInfo.email,        // email of user
                        emailSubject + "เเจ้งผล การยืนยันตัวตนด้วยบัญชีธนาคาร ไม่ผ่านการตรวจสอบ",
                        objEmail,
                    )
                }
            }

            // stamp isHasBeenVerified
            if (
                !accountVerify?.accountVerifiedBank.isHasBeenVerified &&
                values.isInspectFileBank === 1 &&
                values.isInspectBankInfo === 1
            ) {
                let obj = {
                    accountVerifiedBankId: accountVerify?.accountVerifiedBank.accountVerifiedBankId,
                    isHasBeenVerified: true
                }
                // console.log("objIsHasBeenVerified : ", obj)
                await updateIsHasBeenAccountVerifiedBankById(obj)
            }

            if (
                resultUpdateInspectAccountVerify?.isSuccess &&
                resultUpdateInspectAccountVerifyIdentify.isSuccess &&
                resultUpdateInspectAccountVerifyBank.isSuccess
            ) {
                Notification("success", "ระบบ", "ส่งผลตรวจสอบสำเร็จ")
                // set default
                setModal({
                    isOpen: false,
                    data: null,
                    openInspect: true
                })

                // reload
                getBaseApi()
            } else {
                Notification("error", "ระบบ", "ส่งผลตรวจสอบไม่สำเร็จ")
            }
        } catch (err) {}
    }

    const getInspectAccountVerify = async (type, value) => {
        const result = await getInspectAccountVerifyFetch(type, value)
        // console.log("getInspectAccountVerifyFetch : ", result)
        let genData = [];
        for (let i = 0; i < result.length; i++) {
            genData.push({
                index: (i + 1),
                ownerName: result[i]?.userInfo ? result[i]?.userInfo?.userName : "",
                createdAt: moment(result[i].createdAt).format(formatDateTime),
                inspect: genInspectResult(result[i]),
                operation: genButtonInspect(result[i]),
            })
        }
        setList(genData)
    }

    const getInspectAccountVerifyDetail = async (value) => {
        let obj = {
            language,
            uid: value.userInfo.uid
        }
        const result = await getInspectFileRegistrationCertificateFetch(obj)
        // console.log("getInspectFileRegistrationCertificateFetch : ", result)
        setAccountVerifyDetail(result)
    }

    const bankAccountIdFormat = (id) => {
        let accountId = "ไม่พบเลขบัญชี"
        if (id) {
            accountId = ""
            for (let i = 0; i < id.length; i++) {
                if (i === 3 || i === 4 || i === 9) {
                    accountId += "-" + id[i]
                } else {
                    accountId += id[i]
                }
            }
        }
        return accountId
    }

    const genInspectResult = (value) => {
        return (
            <>
                {value.isOpenVerifyBankAccount === null ?
                    <>
                        {value.accountVerifiedIdentify.approvalStatusId === 1 ?
                            <center><AiFillCheckCircle style={{ width: 25, height: 25, color: "green" }} /></center>
                            :
                            (value.countInspect > 0 ?
                                <center><IoMdCloseCircle style={{ width: 25, height: 25, color: "red" }} /></center>
                                : []
                            )
                        }
                    </>
                    :
                    <>
                        {value.accountVerifiedBank.approvalStatusId === 1 && value.accountVerifiedIdentify.approvalStatusId === 1 ?
                            <center><AiFillCheckCircle style={{ width: 25, height: 25, color: "green" }} /></center>
                            :
                            (value.countInspect > 0 && (value.accountVerifiedBank.approvalStatusId === 2 || value.accountVerifiedIdentify.approvalStatusId === 2) ?
                                <center><IoMdCloseCircle style={{ width: 25, height: 25, color: "red" }} /></center>
                                : []
                            )
                        }
                    </>
                }
            </>
        )
    }

    const genButtonInspect = (data) => {

        let obj = {
            // บุคคลธรรมดา
            isInspectIdCardFront: data.accountVerifiedIdentify.idCardFrontApprovalId ?
                (data.accountVerifiedIdentify.idCardFrontApprovalId === 12 ? undefined : data.accountVerifiedIdentify.idCardFrontApprovalId)
                : undefined,
            isInspectIdCardInfo: data.accountVerifiedIdentify.idCardInfoApprovalId ?
                (data.accountVerifiedIdentify.idCardInfoApprovalId === 12 ? undefined : data.accountVerifiedIdentify.idCardInfoApprovalId)
                : undefined,

            // นิติบุคคล
            companyInfoApproval: data.accountVerifiedIdentify.companyInfoApprovalId ?
                (data.accountVerifiedIdentify.companyInfoApprovalId === 12 ? undefined : data.accountVerifiedIdentify.companyInfoApprovalId)
                : undefined,

            // bank
            isInspectFileBank: data.accountVerifiedBank.bankFileApprovalId ?
                (data.accountVerifiedBank.bankFileApprovalId === 12 ? undefined : data.accountVerifiedBank.bankFileApprovalId)
                : undefined,
            isInspectBankInfo: data.accountVerifiedBank.bankInfoApprovalId ?
                (data.accountVerifiedBank.bankInfoApprovalId === 12 ? undefined : data.accountVerifiedBank.bankInfoApprovalId)
                : undefined
        }
        // console.log("obj : ", obj);
        // console.log("data : ", data);

        return (
            <>
                {data.accountVerifiedBank.approvalStatusId === 1 && data.accountVerifiedIdentify.approvalStatusId === 1 ?
                    <Button
                        type="primary"
                        style={{ width: 110 }}
                        onClick={async () => {
                            setAccountVerify(data)

                            if (data.accountVerifiedIdentify.businessOperationId === 2) { // ของ นิติบุคคล
                                await getInspectAccountVerifyDetail(data)
                            }

                            formInspectDetail.setFieldsValue(obj)

                            setModal({
                                isOpen: true,
                                data: data,
                                openInspect: false
                            })
                        }}
                    >ดูรายละเอียด</Button>
                    :
                    (data.accountVerifiedBank.approvalStatusId === 12 || data.accountVerifiedIdentify.approvalStatusId === 12 ?
                        <Button
                            type="primary"
                            style={{ width: 110 }}
                            onClick={async () => {
                                setAccountVerify(data)

                                if (data.accountVerifiedIdentify.businessOperationId === 2) { // ของ นิติบุคคล
                                    await getInspectAccountVerifyDetail(data)
                                }

                                formInspectDetail.setFieldsValue(obj)

                                setModal({
                                    isOpen: true,
                                    data: data,
                                    openInspect: true
                                })
                            }}
                        >รอตรวจสอบ</Button>
                        :
                        <>
                            {data.isOpenVerifyBankAccount !== null || data.accountVerifiedIdentify.approvalStatusId === 2 ?
                                <label>รอตอบกลับ</label>
                                :
                                <Button
                                    type="primary"
                                    style={{ width: 110, /* borderRadius: 50 */ }}
                                    onClick={() => {
                                        setAccountVerify(data)

                                        setModal({
                                            isOpen: true,
                                            data: data,
                                            openInspect: false
                                        })
                                    }}
                                >ดูรายละเอียด</Button>
                            }
                        </>
                    )
                }
            </>
        )
    }

    const getBankCategoryAll = async () => {
        let obj = {
            language
        }
        const resultBankCategoryAll = await getฺBankCategoryAllFetch(obj)
        setBankCategory(resultBankCategoryAll)
    }

    const getBaseApi = async () => {
        await getBankCategoryAll()
        await getInspectAccountVerify("allData", {
            ownerName: null,
            startedDate: null,
            endDate: null,
            isInspect: null,
            language
        })
    }

    useEffect(() => {
        setLoading(true)

        getBaseApi()

        setLoading(false)
    }, [])

    return (
        <>
            {!loading ?
                <>
                    <Row>
                        <Col span={5}>
                            <label>ตรวจสอบ</label>
                        </Col>

                        <Col span={19} style={{ paddingBottom: 20 }}>
                            <Form
                                layout="vertical"
                                form={form}
                                onFinish={onFinish}
                            >
                                <Row>
                                    <Col xs={12} lg={5}>
                                        <Form.Item name="inspectVerify" >
                                            <Select
                                                showSearch
                                                style={{ width: '90%' }}
                                                placeholder="สถานะตรวจสอบ"
                                                optionFilterProp="children"
                                                allowClear
                                            >
                                                <Option value={'pass'}>ผ่าน</Option>
                                                <Option value={'not-pass'}>ไม่ผ่าน</Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={12} lg={7}>
                                        <Form.Item name="dateRange" >
                                            <RangePicker
                                                style={{ width: '90%' }}
                                                showTime={{ format: formatTime }}
                                                format={formatDateTime}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={12} lg={10}>
                                        <Form.Item name="ownerName" >
                                            <Input
                                                style={{ width: '100%' }}
                                                placeholder="ชื่อจริง"
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={12} lg={2}>
                                        <Button
                                            type="primary"
                                            onClick={() => form.submit()}
                                        >
                                            ค้นหา
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </Col>

                        <Col span={24}>
                            <Table
                                loading={loading}
                                columns={columns}
                                dataSource={list}
                                pagination={{
                                    current: pageCurrent,
                                    pageSize: 10,
                                    total: list.length
                                }}
                                onChange={(n) => onPagine(n)}
                            >
                            </Table>
                        </Col>
                    </Row>

                    <Modal
                        title={"ตรวจสอบ"}
                        visible={modal.isOpen}
                        onCancel={() => {
                            setModal({
                                isOpen: false,
                                data: null,
                                openInspect: true
                            });
                        }}
                        width={1050}
                        footer={[
                            <Button key="back"
                                type="primary"
                                disabled={modal.data ? (modal.data.isAccountVerifiedBank && modal.data.isAccountVerifiedIdentify ? true : false) : true}
                                onClick={() => {
                                    formInspectDetail.submit()
                                }}
                            >
                                ส่งผลตรวจสอบ
                            </Button>,
                        ]}
                    >
                        {/* {console.log("accountVerify : ", accountVerify)} */}
                        <Form
                            layout="inline"
                            form={formInspectDetail}
                            onFinish={onInspectDetailFinish}
                        >
                            {modal.data ?
                                <>
                                    {modal.data?.accountVerifiedIdentify ?
                                        <div style={{ border: "1px solid #DEDEDE" }}>
                                            <Row style={{ padding: 15 }}>
                                                {modal.data?.businessOperationId === 1 ?
                                                    <>
                                                        <Col span={24} style={{ background: "#DEDEDE" }}>
                                                            <label style={{ marginTop: 8, paddingLeft: 20, paddingRight: 10 }}>1. บัตรประชาชน</label>
                                                        </Col>

                                                        <Col span={24} style={{ background: "#EEEEEE" }}>
                                                            <label style={{ marginTop: 6, paddingLeft: 20, paddingRight: 10 }}>ข้อมูลบัตรประชาชน</label>
                                                            {accountVerify?.accountVerifiedIdentify?.idCardInfoApprovalId === 1 ?
                                                                <label style={{ color: "#23B14D", marginTop: 6, paddingLeft: 20, paddingRight: 10, float: "right" }}>ผ่านการตรวจสอบ</label>
                                                                : []}
                                                        </Col>
                                                        <Col span={18} style={{ paddingLeft: 15, paddingBottom: 15 }}>
                                                            <Row>
                                                                <Col span={24} style={{ paddingTop: 15 }}>
                                                                    <label>ชื่อจริง - นามสกุล : {modal.data.accountVerifiedIdentify.idCardFirstName + " " + modal.data.accountVerifiedIdentify.idCardLastName}</label>
                                                                </Col>

                                                                <Col span={24} style={{ paddingTop: 15 }}>
                                                                    <label>รหัสบัตรประชาชน : {modal.data.accountVerifiedIdentify.idCardNumber}</label>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        <Col span={6} style={{ paddingRight: 15, paddingBottom: 15, paddingTop: 15 }}>
                                                            <Form.Item
                                                                name="isInspectIdCardInfo"
                                                                rules={[{ required: true, message: "กรุณาตรวจสอบข้อมูลบัตรประชาชน" }]}
                                                            >
                                                                <Radio.Group disabled={accountVerify?.accountVerifiedIdentify?.idCardInfoApprovalId === 1 ? true : false}>
                                                                    <Radio value={1}>ผ่าน</Radio>
                                                                    <Radio value={2}>ไม่ผ่าน</Radio>
                                                                </Radio.Group>
                                                            </Form.Item>
                                                        </Col>

                                                        <div style={{ paddingTop: 15, paddingBottom: 15 }}></div>

                                                        <Col span={24} style={{ background: "#EEEEEE" }}>
                                                            <label style={{ marginTop: 8, paddingLeft: 20, paddingRight: 10 }}>ภาพถ่ายบัตรประชาด้านหน้า</label>
                                                            {accountVerify?.accountVerifiedIdentify?.idCardFrontApprovalId === 1 ?
                                                                <label style={{ color: "#23B14D", marginTop: 8, paddingLeft: 20, paddingRight: 10, float: "right" }}>ผ่านการตรวจสอบ</label>
                                                                : []}
                                                        </Col>
                                                        <Col span={18} style={{ padding: 15 }}>
                                                            <Image
                                                                width={"auto"}
                                                                height={200}
                                                                style={{ border: "1px solid #e8e8e8" }}
                                                                alt="avatar"
                                                                src={`${modal.data.accountVerifiedIdentify?.fileIdCardFrontLocation}`}
                                                                preview={{
                                                                    src: `${modal.data.accountVerifiedIdentify?.fileIdCardFrontLocation}`,
                                                                }}
                                                            />
                                                        </Col>
                                                        <Col span={6} style={{ paddingRight: 15, paddingBottom: 15, paddingTop: 15 }}>
                                                            <Form.Item
                                                                name="isInspectIdCardFront"
                                                                rules={[{ required: true, message: "กรุณาตรวจสอบภาพถ่ายบัตรประชาด้านหน้า" }]}
                                                            >
                                                                <Radio.Group disabled={accountVerify?.accountVerifiedIdentify.idCardFrontApprovalId === 1 ? true : false}>
                                                                    <Radio value={1}>ผ่าน</Radio>
                                                                    <Radio value={2}>ไม่ผ่าน</Radio>
                                                                </Radio.Group>
                                                            </Form.Item>
                                                        </Col>
                                                    </>
                                                    :
                                                    <>
                                                        <Col span={24} style={{ background: "#D6D6D6" }}>
                                                            <label style={{ marginTop: 8, paddingLeft: 20, paddingRight: 10 }}>1. หนังสือรับรอง หรือใบสําคัญแสดงการจดทะเบียนห้างหุ้นส่วนบริษัท</label>
                                                        </Col>

                                                        <Col span={24} style={{ paddingTop: 8, background: "#EEEEEE" }}>
                                                            <label style={{ marginTop: 0, paddingLeft: 20, paddingRight: 10, }}>ไฟล์เอกสาร</label>
                                                            {accountVerify?.accountVerifiedIdentify.registrationCertificateApprovalId === 1 ?
                                                                <label style={{ color: "#23B14D", marginTop: 0, paddingLeft: 20, paddingRight: 10, float: "right" }}>ผ่านการตรวจสอบ</label>
                                                                : []}
                                                        </Col>
                                                        <Col span={24}>
                                                            <div
                                                                style={{
                                                                    height: 400,
                                                                    overflow: 'auto',
                                                                    overflowX: 'hidden',
                                                                    paddingTop: 10
                                                                }}
                                                            >
                                                                {accountVerifyDetail.map((item, index) => {
                                                                    return (
                                                                        <Row
                                                                            style={{
                                                                                marginBottom: 10,
                                                                                height: 63,
                                                                                border: "1px solid #EEEEEE"
                                                                            }}
                                                                        >
                                                                            <Col span={4}>
                                                                                {item.fileRegCerTypeId === 1 ?
                                                                                    <a
                                                                                        target="_blank"
                                                                                        href={`${item.fileRegCerLocation}`}
                                                                                        style={{ cursor: "pointer" }}
                                                                                    >
                                                                                        <Image
                                                                                            width={"auto"}
                                                                                            height={60}
                                                                                            src="error"
                                                                                            style={{
                                                                                                padding: 5
                                                                                            }}
                                                                                            fallback={"./assets/images/default/pdf.png"}
                                                                                        />
                                                                                    </a>
                                                                                    : []}

                                                                                {item.fileRegCerTypeId === 2 ?
                                                                                    <Image
                                                                                        width={"auto"}
                                                                                        height={60}
                                                                                        style={{
                                                                                            padding: 5
                                                                                        }}
                                                                                        src={`${item.fileRegCerLocation}`}
                                                                                    />
                                                                                    : []
                                                                                }
                                                                            </Col>

                                                                            <Col span={15}>
                                                                                <label style={{ marginTop: 5, cursor: "pointer" }}>{item.fileRegCerName}</label>
                                                                                <br />
                                                                                <label style={{ marginTop: 5 }}>{item.fileRegCerTypeName}</label>
                                                                            </Col>

                                                                            <Col span={5}>
                                                                                {item.isHasBeenPassInspection ? [] :
                                                                                    <Form.Item
                                                                                        name={`fileRegCer${item.fileRegistrationCertificateId}`}
                                                                                        rules={[{ required: true, message: "กรุณาตรวจสอบ" }]}
                                                                                    >
                                                                                        <Radio.Group
                                                                                            style={{ marginTop: 10 }}
                                                                                            onChange={(e) => {
                                                                                                setAccountVerifyDetail(accountVerifyDetail.map((itemU, indexU) => {
                                                                                                    if (index === indexU) {
                                                                                                        itemU.fileRegCerApprovalId = e.target.value;
                                                                                                    }
                                                                                                    return itemU;
                                                                                                }))
                                                                                            }}
                                                                                        >
                                                                                            <Radio value={1}>ผ่าน</Radio>
                                                                                            <Radio value={2}>ไม่ผ่าน</Radio>
                                                                                        </Radio.Group>
                                                                                    </Form.Item>
                                                                                }
                                                                            </Col>
                                                                        </Row>
                                                                    )
                                                                })}
                                                            </div>
                                                        </Col>

                                                        <div style={{ padding: 7.5 }}></div>

                                                        <Col span={24} style={{ paddingTop: 0, background: "#EEEEEE" }}>
                                                            <label style={{ marginTop: 8, paddingLeft: 20, paddingRight: 10 }}>ข้อมูลบริษัท</label>
                                                            {accountVerify?.accountVerifiedIdentify.companyInfoApprovalId === 1 ?
                                                                <label style={{ color: "#23B14D", marginTop: 8, paddingLeft: 20, paddingRight: 10, float: "right" }}>ผ่านการตรวจสอบ</label>
                                                                : []}
                                                        </Col>

                                                        <Col span={18}>
                                                            <Row>
                                                                <Col span={6} style={{ paddingTop: 15 }}>
                                                                    <label style={{ float: "right", paddingRight: 10 }}>ชื่อบริษัท ภาษาไทย</label>
                                                                </Col>
                                                                <Col span={18} style={{ paddingTop: 15 }}>
                                                                    <label style={{ paddingLeft: 4 }}> : {accountVerify?.accountVerifiedIdentify.companyNameTH}</label>
                                                                </Col>

                                                                <Col span={6} style={{ paddingTop: 15 }}>
                                                                    <label style={{ float: "right", paddingRight: 10 }}>ชื่อบริษัท ภาษาอังกฤษ</label>
                                                                </Col>
                                                                <Col span={18} style={{ paddingTop: 15 }}>
                                                                    <label style={{ paddingLeft: 4 }}> : {accountVerify?.accountVerifiedIdentify.companyNameENG}</label>
                                                                </Col>

                                                                <Col span={6} style={{ paddingTop: 15 }}>
                                                                    <label style={{ float: "right", paddingRight: 10 }}>เลขประจำตัวผู้เสียภาษี</label>
                                                                </Col>
                                                                <Col span={18} style={{ paddingTop: 15 }}>
                                                                    <label style={{ paddingLeft: 4 }}> : {accountVerify?.accountVerifiedIdentify.companyTaxIdNumber}</label>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        <Col span={6} style={{ paddingLeft: 20, paddingTop: 15 }}>
                                                            <Form.Item
                                                                name="companyInfoApproval"
                                                                rules={[{ required: true, message: "กรุณาตรวจสอบ" }]}
                                                            >
                                                                <Radio.Group disabled={accountVerify?.accountVerifiedIdentify.companyInfoApprovalId === 1 ? true : false}>
                                                                    <Radio value={1}>ผ่าน</Radio>
                                                                    <Radio value={2}>ไม่ผ่าน</Radio>
                                                                </Radio.Group>
                                                            </Form.Item>
                                                        </Col>
                                                    </>
                                                }
                                            </Row>
                                        </div>
                                        : []
                                    }

                                    {modal.data.accountVerifiedBank.approvalStatusId ?
                                        <div style={{ border: "1px solid #D6D6D6", width: "100%", marginTop: 15 }}>
                                            <Row style={{ padding: 15 }}>
                                                <Col span={24} style={{ background: "#E0E0E0" }}>
                                                    <label style={{ marginTop: 8, paddingLeft: 20, paddingRight: 10 }}>2. บัญชีธนาคาร</label>
                                                </Col>

                                                <Col span={24} style={{ background: "#EEEEEE" }}>
                                                    <label style={{ marginTop: 8, paddingLeft: 20, paddingRight: 10 }}>ภาพถ่ายหน้าบัญชีธนาคาร</label>
                                                    {accountVerify?.accountVerifiedBank.bankFileApprovalId === 1 ?
                                                        <label style={{ color: "#23B14D", marginTop: 8, paddingLeft: 20, paddingRight: 10, float: "right" }}>ผ่านการตรวจสอบ</label>
                                                        : []}
                                                </Col>
                                                <Col span={18} style={{ paddingLeft: 15, paddingTop: 15, paddingBottom: 15 }}>
                                                    <Image
                                                        height={200}
                                                        width={"auto"}
                                                        style={{ border: "1px solid #EEEEEE" }}
                                                        alt="avatar"
                                                        src={`${modal.data.accountVerifiedBank?.fileBankAccountLocation}`}
                                                        preview={{
                                                            src: `${modal.data.accountVerifiedBank?.fileBankAccountLocation}`,
                                                        }}
                                                    />
                                                </Col>
                                                <Col span={6} style={{ paddingRight: 15, paddingBottom: 15, paddingTop: 15 }}>
                                                    <Form.Item
                                                        name="isInspectFileBank"
                                                        rules={[{ required: true, message: "กรุณาตรวจสอบ" }]}
                                                    >
                                                        <Radio.Group disabled={accountVerify?.accountVerifiedBank?.bankFileApprovalId === 1 ? true : false}>
                                                            <Radio value={1}>ผ่าน</Radio>
                                                            <Radio value={2}>ไม่ผ่าน</Radio>
                                                        </Radio.Group>
                                                    </Form.Item>
                                                </Col>

                                                <div style={{ padding: 7.5 }}></div>

                                                <Col span={24} style={{ background: "#EEEEEE" }}>
                                                    <label style={{ marginTop: 8, paddingLeft: 20, paddingRight: 10 }}>ข้อมูลบัญชีธนาคาร</label>
                                                    {accountVerify?.accountVerifiedBank?.bankInfoApprovalId === 1 ?
                                                        <label style={{ color: "#23B14D", marginTop: 8, paddingLeft: 20, paddingRight: 10, float: "right" }}>ผ่านการตรวจสอบ</label>
                                                        : []}
                                                </Col>
                                                <Col span={18} style={{ paddingTop: 15 }}>
                                                    <Row gutter={[8, 8]}>
                                                        <Col span={6}><label style={{ float: "right", paddingRight: 5 }}>ธนาคาร</label></Col>
                                                        <Col span={18}><label>: {bankCategory.find(val => val.value === modal.data.accountVerifiedBank.bankCategoryId).label}</label></Col>

                                                        <Col span={6}><label style={{ float: "right", paddingRight: 5 }}>บัญชีเลขที่</label></Col>
                                                        <Col span={18}><label>: {bankAccountIdFormat(modal.data.accountVerifiedBank.bankAccountId)}</label></Col>

                                                        <Col span={6}><label style={{ float: "right", paddingRight: 5 }}>ชื่อบัญชี</label></Col>
                                                        <Col span={18}><label>: {modal.data.accountVerifiedBank.bankAccountName}</label></Col>
                                                    </Row>
                                                </Col>
                                                <Col span={6} style={{ paddingRight: 15, paddingBottom: 15, paddingTop: 15 }}>
                                                    <Form.Item
                                                        name="isInspectBankInfo"
                                                        rules={[{ required: true, message: "กรุณาตรวจสอบ" }]}
                                                    >
                                                        <Radio.Group disabled={accountVerify?.accountVerifiedBank.bankInfoApprovalId === 1 ? true : false}>
                                                            <Radio value={1}>ผ่าน</Radio>
                                                            <Radio value={2}>ไม่ผ่าน</Radio>
                                                        </Radio.Group>
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </div>
                                        : []
                                    }
                                </>
                                : []}
                        </Form>
                    </Modal>
                </>
                : []
            }
        </>
    )
}
