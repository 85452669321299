/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-const-assign */
/* eslint-disable react-hooks/exhaustive-deps */

import { useState, useEffect } from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import parse from 'html-react-parser'
import _uniqueId from 'lodash/uniqueId'
import ImgCrop from 'antd-img-crop'
import { useDispatch, useSelector } from "react-redux"
import { Icon } from '@iconify/react'
import { LoadingOutlined, UploadOutlined } from '@ant-design/icons'
import { useNavigate, useLocation } from "react-router-dom"
import {
    Button,
    Image,
    Row,
    Col,
    Modal,
    Form,
    Input,
    Upload,
    Empty,
    Popconfirm,
    Divider,
    Spin,
    Collapse,
    Select,
    InputNumber
} from 'antd'
import { AiOutlineUpload } from 'react-icons/ai'
import axios from 'axios'
import "../../css/manageCard.css"
import "../../css/manageVideo.css"
import { setCourseManageChapterStateToSuccess } from '../../../../actions/courseManageChapter.action'
import { setCourseManageAddCurrentStepStateToSuccess } from '../../../../actions/courseManageAddCurrentStep.action'
import { setCourseManageVideoListStateToSuccess } from '../../../../actions/courseManageVideoList.action'
import { Notification } from '../../../../common/components/notification'
import Video from '../../../../common/components/video'
import { PathVideo } from '../../../../common/components/video/components/convert/pathVideo'
import CoverImage from '../../../../common/components/video/components/coverImage'
import { useDimensions } from '../../../../common/mamager/ScreenManager'
import { apiStorageUrl, bucketName, storageUrl } from '../../../../constants'
import ManageChapter from '../chapter'
import ManageVideoItem from './VideoItem'
import CourseVideoPreview from '../../../course/components/video/CourseVideoPreview'
import { getRankCourseVideoListFetch, insertCourseChapterListFetch, insertCourseVideoListFetch, insertInspectRequestOpenCourseFetch, insertNewCourseChapterFetch, insertNewCourseDocumentFetch, insertNewCourseFetch, insertNewCourseMediaIntroFetch, insertNewCourseVideoFetch, updateCourseChapterListFetch, updateCourseVideoListFetch, updateRankCourseVideoListFetch, updateRequestOpenCourseVideoAdjustFetch } from '../../API'
import { updateRequestOpenCourseUploadedFetch } from '../../../requestOpenCourse/API'
import { insertCourseReviewOverviewFetch } from '../../../review/API'
import { getChapterCategoryFetch, getCourseCategoryFetch, getCourseCertificateFetch, getCourseFormatFetch } from '../../../course/API'
import { convertBase64ToFile } from '../../../../common/mamager/ImageMamager'

const { Option } = Select
const { TextArea } = Input

function ManageVideo(props) {

    const navigate = useNavigate()
    const language = "th"
    const location = useLocation()
    const params = new URLSearchParams(location.search)
    const token = params.get("token")
    const formROCId = params.get("formROCId")
    const inspectId = params.get("inspectId")

    const dispatch = useDispatch()

    const { width } = useDimensions()

    const [switchVideo, setSwitchVideo] = useState(true)
    const [switchWorkshop, setSwitchWorkshop] = useState(true)

    const [courseId, setCourseId] = useState(null)
    const [user, setUser] = useState({ uid: null })
    const [isStepActive, setIsStepActive] = useState(null)
    const [courseInspect, setcourseInspect] = useState(null)
    const [switchType, setSwitchType] = useState(null)
    const [authorized, setAuthorized] = useState(null)
    const [isFormDisable, setisFormDisable] = useState(false)

    const [loading, setLoading] = useState(true)
    const [sendLoading, setSendLoading] = useState(false)

    const [formAddVideo] = Form.useForm()
    const [formAddChapter] = Form.useForm()

    const [chapterCategory, setChapterCategory] = useState([])
    const [chapterCategoryUsed, setChapterCategoryUsed] = useState({
        id: null,
        name: null
    })

    const [modalAddVideo, setModalAddVideo] = useState(false)
    const [modalAddChapter, setModalAddChapter] = useState(false)

    const [modalCheckInformation, setModalCheckInformation] = useState(false)
    const [modalVideoCoverImage, setModalVideoCoverImage] = useState(false)

    const [videoCourseCoverImage, setVideoCourseCoverImage] = useState({
        loading: false,
    })

    const [summaryVideoPrice, setSummaryVideoPrice] = useState({
        videoAmount: 0,
        coursePrice: 0,
        hasHappened: 0,
        remaining: 0
    })

    const { resultCourseManageAddCurrentStep } = useSelector(({ courseManageAddCurrentStepReducer }) => courseManageAddCurrentStepReducer)

    const { resultCard } = useSelector(({ courseManageCardReducer }) => courseManageCardReducer)
    const { resultIntro } = useSelector(({ courseManageIntroReducer }) => courseManageIntroReducer)
    const { resultPrice } = useSelector(({ courseManagePriceReducer }) => courseManagePriceReducer)
    const { resultDescription } = useSelector(({ courseManageDescriptionReducer }) => courseManageDescriptionReducer)
    const { resultDocument } = useSelector(({ courseManageDocumentReducer }) => courseManageDocumentReducer)
    const { resultVideoList } = useSelector(({ courseManageVideoListReducer }) => courseManageVideoListReducer)
    const { resultChapter } = useSelector(({ courseManageChapterReducer }) => courseManageChapterReducer)

    // console.log("courseChapter : ", props.courseChapter)
    // console.log("resultChapter : ", resultChapter)
    // console.log("resultVideoList : ", resultVideoList)

    const [courseCategory, setCourseCategory] = useState([])
    const [courseFormat, setCourseFormat] = useState([])
    const [courseCertificateCategory, setCourseCertificateCategory] = useState([])

    const [isPaymentByVideo, setIsPaymentByVideo] = useState(null)

    const [videoCourse, setVideoCourse] = useState({
        loading: false,
        videoUrl: null,
    })

    const [duration, setDuration] = useState({
        timeVideo: null,
        displayTimeVideo: null
    })

    const optionVideo = {
        name: 'file',
        action: `${apiStorageUrl}/upload/file`,
        data: {
            // uploaded/video/course-video-list
            bucket: bucketName,
            folder: "video/course/content"
        },
        headers: {
            authorization: 'authorization-text',
        },
        onChange(info) {
            if (info.file.status === 'done') {
                let result = info.file.response;
                console.log("info : ", info.file.response)
                if (result?.isSuccess) {
                    setVideoCourse({
                        videoUrl: result.url,
                        loading: false,
                    });
                }
            } else {
                setVideoCourse({
                    loading: true,
                    videoUrl: videoCourse.videoUrl
                });
            }

            if (info.file.status === 'done') {
                Notification("success", "ระบบ", "อัพโหลดวิดีโอคอร์สสำเร็จ")
            } else if (info.file.status === 'error') {
                Notification("error", "ระบบ", "ไม่สามารถอัพโหลดวิดีโอคอร์สได้ กรุณาตรวจสอบข้อมูล")
            }
        },
        progress: {
            strokeColor: {
                '0%': '#108ee9',
                '100%': '#87d068',
                width: 100,
            },
            strokeWidth: 3,
            width: '10%',
            format: percent => `${parseFloat(percent.toFixed(0))}%`,
        },
    }

    const optionCoverVideo = {
        name: 'file',
        action: `${apiStorageUrl}/upload/file`,
        data: {
            // uploaded/img/course-video-list-cover
            bucket: bucketName,
            folder: "image/course/content-cover"
        },
        headers: {
            authorization: 'authorization-text',
        },
        onChange(info) {
            if (info.file.status === 'done') {
                let result = info.file.response;
                console.log("info : ", info.file.response)
                if (result?.isSuccess) {
                    setVideoCourseCoverImage({
                        imageUrl: result.url,
                        loading: false
                    });
                }
            } else {
                setVideoCourseCoverImage({ loading: true });
            }

            if (info.file.status === 'done') {
                Notification("success", "ระบบ", "อัพโหลดภาพปกวิดีโอคอร์สสำเร็จ")
            } else if (info.file.status === 'error') {
                Notification("error", "ระบบ", "ไม่สามารถอัพโหลดภาพปกวิดีโอคอร์สได้ กรุณาลองใหม่อีกครั้ง")
            }
        },
        progress: {
            strokeColor: {
                '0%': '#108ee9',
                '100%': '#87d068',
            },
            strokeWidth: 3,
            format: percent => `${parseFloat(percent.toFixed(2))}%`,
        },
    }

    const onSwitchVideo = () => {
        setSwitchVideo(!switchVideo)
    }

    const onSwitchWorkshop = () => {
        setSwitchWorkshop(!switchWorkshop)
    }

    const setDefaultVideo = () => {
        formAddVideo.resetFields()

        // set default cover image
        setVideoCourseCoverImage({
            imageUrl: null,
            loading: false,
        })

        // set default video
        setVideoCourse({
            loading: false,
            videoUrl: null
        })

        // close modal video
        setModalAddVideo(false)
    }

    const setDefaultChapter = () => {
        formAddChapter.resetFields()

        // close modal chapter
        setModalAddChapter(false)
    }

    const handleOnDragVideoEnd = async (result) => {
        if (!result.destination) return

        const items = Array.from(resultVideoList)
        const [reorderedItem] = items.splice(result.source.index, 1)
        items.splice(result.destination.index, 0, reorderedItem)

        setReducer(items, "video")
    }

    const handleOnDragChapterEnd = async (result) => {
        if (!result.destination) return

        const items = Array.from(resultChapter);
        const [reorderedItem] = items.splice(result.source.index, 1)
        items.splice(result.destination.index, 0, reorderedItem)

        setReducer(items, "chapter")
    }

    const setReducer = (data, type) => {
        // reducer
        if (type === "video") {
            dispatch(setCourseManageVideoListStateToSuccess(data))
        } else if (type === "chapter") {
            dispatch(setCourseManageChapterStateToSuccess(data))
        }
    }

    const onProcessComplete = async () => {
        setSendLoading(true)

        try {
            let courseLatestId = null

            let courseIsSuccess = false
            let courseMediaIntroIsSuccess = false
            let courseDocumentIsSuccess = false
            let courseVideoIsSuccess = false
            let courseChapterIsSuccess = false

            let inspectRequestOpenCourseIsSuccess = false
            let updateFormRequestOpenCourseIsSuccess = false
            let courseReviewOverviewIsSuccess = false

            // course ---------------------------------------------------------------------------------------------------------------------
            const objCourse = {
                uid: user.uid,
                courseImageLocation: resultCard.courseCoverImage,
                courseSubDescriptionName: resultCard.subDescription,
                isPaymentByVideo: resultCard.isPaymentByVideo,
                courseTitleName: resultCard.title,
                courseCategory: resultCard.courseCategory,
                courseFormat: resultCard.courseFormat,
                courseCertificateCategory: resultCard.courseCertificateCategory,
                monthlyPayment: resultCard.monthlyPayment,

                price: Number(resultPrice.price),
                discountStatus: resultPrice.isDiscount,
                discount: resultPrice.isDiscount ? Number(resultPrice.priceDiscount) : null,
                discountDateEnd: resultPrice.dateTimeEndDiscount,

                courseDescriptionName: resultDescription.description,
            }
            const resultCourse = await insertNewCourseFetch(objCourse)

            if (resultCourse) {
                courseIsSuccess = resultCourse.data.isSuccess
                courseLatestId = resultCourse.data.formData.courseId

                // course media intro -----------------------------------------------------------------------------------------------------
                let objVideoIntro = {
                    courseId: courseLatestId,
                    courseMediaIntroTitleName: resultIntro.title,
                    courseMediaIntroLocation: resultIntro.location,
                    courseMediaIntroCategoryId: 1, // video
                    courseMediaIntroDuration: resultIntro.duration.timeVideo
                }
                const resultCourseMediaIntro = await insertNewCourseMediaIntroFetch(user.uid, objVideoIntro)
                courseMediaIntroIsSuccess = resultCourseMediaIntro ? resultCourseMediaIntro.data.isSuccess : false

                // course document --------------------------------------------------------------------------------------------------------
                const resultCourseDocument = await insertNewCourseDocumentFetch(user.uid, resultDocument, courseLatestId)
                courseDocumentIsSuccess = resultCourseDocument ? resultCourseDocument.data.isSuccess : false

                // course chapter ---------------------------------------------------------------------------------------------------------
                const resultCourseChapter = await insertNewCourseChapterFetch(resultChapter, courseLatestId)
                courseChapterIsSuccess = resultCourseChapter ? resultCourseChapter.data.isSuccess : false

                // course video -----------------------------------------------------------------------------------------------------------
                let tmpVideoList = resultVideoList.map(obj => {
                    let findObj = resultCourseChapter.data.formData.find(val => obj.courseChapterId === val.idOld)
                    if (findObj) {
                        return { ...obj, courseChapterId: findObj.idNew }
                    } else {
                        return obj
                    }
                })
                // console.log("tmpVideoList : ", tmpVideoList)
                const resultCourseVideo = await insertNewCourseVideoFetch(user.uid, tmpVideoList, courseLatestId)
                courseVideoIsSuccess = resultCourseVideo ? resultCourseVideo.data.isSuccess : false

                // inspect course
                let objInspectCourse = {
                    uid: user.uid,
                    courseId: courseLatestId,

                    inspectionCourseCategoryId: 2,
                    inspectionCourseApprovalCategoryId: null,

                    inspectionCourseCardId: 2,
                    inspectionCourseMediaIntroId: 2,
                    inspectionCoursePriceId: 2,
                    inspectionCourseDescriptionId: 2,
                    inspectionCourseDocumentId: 2,
                    inspectionCourseVideoId: 2,
                    inspectionCourseChapterId: 2,

                    isOverdue: null,
                    isActive: 1,
                    amountAdjust: 0,
                    dateEndAdjust: null,
                }
                const resultInspectCourse = await insertInspectRequestOpenCourseFetch(objInspectCourse)
                inspectRequestOpenCourseIsSuccess = resultInspectCourse ? resultInspectCourse.data.isSuccess : false

                let objCloseToken = {
                    token,
                    formROCId,
                    inspectId
                }
                const resultUpdateFormRequestOpenCourse = await updateRequestOpenCourseUploadedFetch(objCloseToken)
                updateFormRequestOpenCourseIsSuccess = resultUpdateFormRequestOpenCourse ? resultUpdateFormRequestOpenCourse.data.isSuccess : false

                // insert course review overview
                let objCourseReviewOverview = {
                    courseId: courseLatestId,
                    uidOwner: user.uid,
                }
                const resultCourseReviewOverview = await insertCourseReviewOverviewFetch(objCourseReviewOverview)
                courseReviewOverviewIsSuccess = resultCourseReviewOverview ? resultCourseReviewOverview.isSuccess : false

                if (
                    courseIsSuccess &&
                    courseMediaIntroIsSuccess &&
                    courseDocumentIsSuccess &&
                    courseVideoIsSuccess &&
                    courseChapterIsSuccess &&
                    inspectRequestOpenCourseIsSuccess &&
                    updateFormRequestOpenCourseIsSuccess &&
                    courseReviewOverviewIsSuccess
                ) {
                    // send email request received
                    // 

                    // open page success
                    navigate(
                        "/result",
                        {
                            state: {
                                authorized,

                                type: "success",
                                title: "ส่งคำขอเพิ่มคอร์สเรียนสำเร็จ!",
                                subTitle: "โปรดรอรอ 2-3 วันในการตรวจสอบ เราจะเเจ้งผลท่านไปทาง email",
                                btnWidth: 170,

                                btnName1: "กลับไปหน้าจัดการคอร์ส",
                                btnPathName1: "course-manage",
                                btnIsActive1: true
                            }
                        }
                    )
                } else {
                    Notification("error", "เเจ้งเตือน", "ไม่สามารถส่งคำขอเพิ่มคอร์สเรียนได้ กรุณาตรวจสอบข้อมูล หรือลองใหม่อีกครั้ง!")
                }
            } else {
                Notification("error", "เเจ้งเตือน", "เกิดข้อผิดพลาดในการส่งข้อมูล โปรดลองใหม่อีกครั้ง!")
            }

            setSendLoading(false)
        } catch (err) {
            Notification("error", "เเจ้งเตือน", err)
            setSendLoading(false)
        }
    }

    const onFinishAddVideo = (values) => {
        setLoading(true)
        let value = {
            approvalStatusId: 3,
            approvalStatusName: "เพิ่มวิดีโอ รอการตรวจสอบ",

            courseVideoId: _uniqueId('temporary-=|=-'), // get id from insert
            courseVideoImgLocation: videoCourseCoverImage.imageUrl,
            courseVideoLocation: videoCourse.videoUrl,
            courseVideoTitleName: values.videoTitle,
            courseVideoDescriptionName: values.videoDescription,
            courseVideoPrice: values.videoPrice,
            courseVideoDuration: duration.timeVideo ? duration.timeVideo : 0.00,
            courseChapterId: values.courseChapterId
        }

        let tmpVideoList = resultVideoList ? resultVideoList : []
        tmpVideoList.push(value)

        getSummaryVideoPrice(tmpVideoList, (resultPrice ? resultPrice?.price : 0))

        setReducer(tmpVideoList, "video")

        // notify
        Notification("success", "ระบบ", "เพิ่มรายการวิดีโอคอร์สสำเร็จ")

        // set default
        setDefaultVideo()
        setLoading(false)
    }

    const getSummaryVideoPrice = (data, coursePrice) => {
        let videoAmount = data ? data?.length : 0
        let hasHappened = 0
        let remaining = 0

        data?.map(val => {
            hasHappened += (val?.courseVideoPrice ? val.courseVideoPrice : 0)
        })
        remaining = coursePrice - hasHappened

        setSummaryVideoPrice({
            videoAmount,
            coursePrice,
            hasHappened,
            remaining
        })
    }

    const onFinishAddChapter = (values) => {
        if (chapterCategoryUsed.id && chapterCategoryUsed.name) {
            setLoading(true)
            let value = {
                approvalStatusId: 13,
                approvalStatusName: "เพิ่มกลุ่มวิดีโอ รอการตรวจสอบ",

                chapterCategoryId: chapterCategoryUsed.id,
                chapterCategoryName: chapterCategoryUsed.name,

                courseChapterId: _uniqueId('temporary-=|=-'), // get id from insert
                courseChapterTitleName: values.courseChapterTitleName,
                courseChapterDescriptionName: values.courseChapterDescriptionName
            }

            let tmpChapter = resultChapter ? resultChapter : []
            tmpChapter.push(value)

            setReducer(tmpChapter, "chapter")

            // notify
            Notification("success", "เเจ้งเตือน", "เพิ่มกลุ่มวิดีโอสำเร็จ")

            // set default
            setDefaultChapter()

            setLoading(false)
        } else {
            Notification("warning", "เเจ้งเตือน", "กรุณาเลือกประเภทคำนำหน้า !")
        }
    }

    const detectVideoCourseCoverImage = () => {
        if (videoCourseCoverImage.imageUrl) {
            return Promise.resolve()
        } else {
            return Promise.reject(new Error('กรุณาอัพโหลดภาพปกวิดีโอ'))
        }
    }

    const handleCourseVideoListReload = () => {
        props.handleCourseVideoListReload()
    }

    const handleUpdateChapterList = async (updateType) => {
        let chapterListUpdate = []
        let chapterListInsert = []
        let chapterSolveBack = []
        let resultUpdate = { data: { isSuccess: true } }
        let resultInsert = { data: { isSuccess: true } }
        let resultSolveBack = { data: { isSuccess: true } }
        let resultNewRankChapterCourse = { data: { isSuccess: true } }

        // update api
        for (let i = 0; i < resultChapter.length; i++) {
            let tempVideoId = String(resultChapter[i].courseChapterId).split("-=|=-") // split a string using "-=|=-" form add chapter

            // update course chapter ==============================================================================================
            if (
                tempVideoId[0] !== "temporary" &&
                resultChapter[i].approvalStatusId !== 1 &&
                resultChapter[i].isUpdate
            ) {
                chapterListUpdate.push({
                    approvalStatusId: resultChapter[i] ? resultChapter[i].approvalStatusId : null,
                    courseChapterDescriptionName: resultChapter[i] ? resultChapter[i].courseChapterDescriptionName : null,
                    courseId: resultChapter[i] ? resultChapter[i].courseId : null,
                    courseChapterId: resultChapter[i] ? resultChapter[i].courseChapterId : null,
                    chapterCategoryId: resultChapter[i] ? resultChapter[i].chapterCategoryId : null,
                    courseChapterNo: (i + 1),
                    courseChapterTitleName: resultChapter[i] ? resultChapter[i].courseChapterTitleName : null,
                    createdAt: resultChapter[i] ? new Date(resultChapter[i].createdAt) : null,
                    updatedAt: resultChapter[i] ? new Date(resultChapter[i].updatedAt) : null
                })
            }

            // add course chapter =================================================================================================
            if (tempVideoId[0] === "temporary") {
                chapterListInsert.push({
                    chapterCategoryId: resultChapter[i] ? resultChapter[i].chapterCategoryId : null,
                    courseChapterTitleName: resultChapter[i] ? resultChapter[i].courseChapterTitleName : null,
                    courseChapterDescriptionName: resultChapter[i] ? resultChapter[i].courseChapterDescriptionName : null,
                    approvalStatusId: 13,
                    courseChapterNo: (i + 1),
                    courseId: resultChapter[i] ? resultChapter[i].courseId : null
                })
            }

            // adjust course chapter ==============================================================================================
            if (resultChapter[i].isUpdate && updateType === "solveBack") {
                chapterSolveBack.push({
                    approvalStatusId: resultChapter[i] ? resultChapter[i].approvalStatusId : null,
                    courseChapterDescriptionName: resultChapter[i] ? resultChapter[i].courseChapterDescriptionName : null,
                    courseId: resultChapter[i] ? resultChapter[i].courseId : null,
                    courseChapterId: resultChapter[i] ? resultChapter[i].courseChapterId : null,
                    chapterCategoryId: resultChapter[i] ? resultChapter[i].chapterCategoryId : null,
                    courseChapterNo: (i + 1),
                    courseChapterTitleName: resultChapter[i] ? resultChapter[i].courseChapterTitleName : null,
                    createdAt: resultChapter[i] ? new Date(resultChapter[i].createdAt) : null,
                    updatedAt: resultChapter[i] ? new Date(resultChapter[i].updatedAt) : null
                })
            }
        }

        if (chapterListUpdate?.length > 0) {
            resultUpdate = await updateCourseChapterListFetch(
                user.uid,                   // uid
                chapterListUpdate,          // chapter
                1,                          // isActive
                Number(courseId),           // courseId
                "courseChapter"             // courseComponent
            )
        }

        if (chapterListInsert?.length > 0) {
            resultInsert = await insertCourseChapterListFetch(
                user.uid,                   // uid
                chapterListInsert,          // chapter
                1,                          // isActive
                Number(courseId),           // courseId
                "courseChapter"             // courseComponent
            )
        }

        if (chapterSolveBack?.length > 0) {
            resultSolveBack = await updateCourseChapterListFetch(
                user.uid,                   // uid
                chapterSolveBack,           // chapter
                1,                          // isActive
                Number(courseId),           // courseId
                "courseChapter",            // courseComponent
            )
        }

        if (
            resultUpdate.data.isSuccess &&
            resultInsert.data.isSuccess &&
            resultSolveBack.data.isSuccess &&
            resultNewRankChapterCourse.data.isSuccess
        ) {
            // reload
            props.handleCourseChapterReload()

            Notification("success", "ระบบ", "บันทึกการเปลี่ยนเเปลงกลุ่มวิดีโอสำเร็จ")
        } else {
            Notification("error", "ระบบ", "บันทึกการเปลี่ยนเเปลงกลุ่มวิดีโอไม่สำเร็จ")
        }
    }

    const handleUpdateVideoList = async (updateType) => {
        let videoListUpdate = []
        let videoListInsert = []
        let videoSolveBack = []
        let isDiff = false

        let resultNewRankVideoCourse = { isSuccess: true }
        let resultSolveBack = { isSuccess: true }
        let resultUpdate = { isSuccess: true }
        let resultInsert = { isSuccess: true }

        // update api
        for (let i = 0; i < resultVideoList.length; i++) {
            let tempVideoId = String(resultVideoList[i].courseVideoId).split("-=|=-") // split a string using "-=|=-" form add video

            // update course video ==============================================================================================
            if (
                tempVideoId[0] !== "temporary" &&
                resultVideoList[i].approvalStatusId !== 1 &&
                resultVideoList[i].isUpdate
            ) {
                videoListUpdate.push({
                    approvalStatusId: resultVideoList[i] ? resultVideoList[i].approvalStatusId : null,
                    courseVideoDescriptionName: resultVideoList[i] ? resultVideoList[i].courseVideoDescriptionName : null,
                    courseVideoPrice: resultVideoList[i] ? resultVideoList[i].courseVideoPrice : null,
                    courseVideoDuration: resultVideoList[i] ? resultVideoList[i].courseVideoDuration : null,
                    courseVideoId: resultVideoList[i] ? resultVideoList[i].courseVideoId : null,
                    courseVideoImgLocation: resultVideoList[i] ? resultVideoList[i]?.courseVideoImgLocation : null,
                    courseVideoLocation: resultVideoList[i] ? resultVideoList[i].courseVideoLocation : null,
                    courseVideoRank: (i + 1),
                    courseVideoTitleName: resultVideoList[i] ? resultVideoList[i].courseVideoTitleName : null,
                    created_at: resultVideoList[i] ? new Date(resultVideoList[i].created_at) : null,
                    updated_at: resultVideoList[i] ? new Date(resultVideoList[i].updated_at) : null,
                    view: resultVideoList[i] ? resultVideoList[i].view : null, // *** 
                    courseChapterId: resultVideoList[i].courseChapterId,
                })
            }

            // add course viode =================================================================================================
            if (tempVideoId[0] === "temporary") {
                videoListInsert.push({
                    courseVideoLocation: resultVideoList[i] ? resultVideoList[i].courseVideoLocation : null,
                    courseVideoTitleName: resultVideoList[i] ? resultVideoList[i].courseVideoTitleName : null,
                    courseVideoDescriptionName: resultVideoList[i] ? resultVideoList[i].courseVideoDescriptionName : null,
                    courseVideoPrice: resultVideoList[i] ? resultVideoList[i].courseVideoPrice : null,
                    approvalStatusId: 3,
                    courseVideoRank: (i + 1),
                    courseVideoDuration: duration.timeVideo,
                    courseVideoImgLocation: resultVideoList[i] ? resultVideoList[i]?.courseVideoImgLocation : null,
                    view: resultVideoList[i] ? resultVideoList[i].view : null, // *** 
                    courseChapterId: resultVideoList[i].courseChapterId,
                })
            }
        }

        // click "บันทึกการปรับเเก้" video

        if (updateType === "solveBack") {
            for (let i = 0; i < resultVideoList.length; i++) {
                if (resultVideoList[i].isUpdate) {
                    videoSolveBack.push({
                        approvalStatusId: resultVideoList[i] ? resultVideoList[i].approvalStatusId : null,
                        courseVideoDescriptionName: resultVideoList[i] ? resultVideoList[i].courseVideoDescriptionName : null,
                        courseVideoPrice: resultVideoList[i] ? resultVideoList[i].courseVideoPrice : null,
                        courseVideoDuration: resultVideoList[i] ? resultVideoList[i].courseVideoDuration : null,
                        courseVideoId: resultVideoList[i] ? resultVideoList[i].courseVideoId : null,
                        courseVideoImgLocation: resultVideoList[i] ? resultVideoList[i]?.courseVideoImgLocation : null,
                        courseVideoLocation: resultVideoList[i] ? resultVideoList[i].courseVideoLocation : null,
                        courseVideoRank: (i + 1),
                        courseVideoTitleName: resultVideoList[i] ? resultVideoList[i].courseVideoTitleName : null,
                        created_at: resultVideoList[i] ? new Date(resultVideoList[i].created_at) : null,
                        updated_at: resultVideoList[i] ? new Date(resultVideoList[i].updated_at) : null,
                        view: resultVideoList[i] ? resultVideoList[i].view : null, // *** 
                        courseChapterId: resultVideoList[i].courseChapterId,
                    })
                }
            }

            resultSolveBack = await updateCourseVideoListFetch(
                user.uid,           // uid
                videoSolveBack,    // video
                1,                  // isActive
                Number(courseId),   // courseId
                "courseVideo",      // courseComponent
            )
        }

        resultUpdate = await updateCourseVideoListFetch(
            user.uid,           // uid
            videoListUpdate,    // video
            1,                  // isActive
            Number(courseId),   // courseId
            "courseVideo",      // courseComponent
        )

        resultInsert = await insertCourseVideoListFetch(
            user.uid,           // uid
            videoListInsert,    // video
            1,                  // isActive
            Number(courseId),   // courseId
            "courseVideo",      // courseComponent
        )

        console.log("videoSolveBack : ", videoSolveBack)
        console.log("videoListUpdate : ", videoListUpdate)
        console.log("videoListInsert  : ", videoListInsert)
        console.log("--------------------")

        // update rank course video =============================================================================================
        let resultOldRankVideoCourse = await getRankCourseVideoListFetch(
            user.uid,           // uid
            1,                  // isActive
            Number(courseId)    // courseId
        )
        console.log("oldRankVideoCourse : ", resultOldRankVideoCourse)

        for (let i = 0; i < resultVideoList.length; i++) {
            if (resultVideoList[i].courseVideoRank !== resultOldRankVideoCourse[i]?.courseVideoRank) {
                isDiff = true
            }
        }

        // send api update rank video course
        videoListUpdate = []
        if (isDiff) {
            for (let i = 0; i < resultVideoList.length; i++) {
                videoListUpdate.push({
                    courseVideoRank: (i + 1),
                    courseVideoId: resultVideoList[i] ? resultVideoList[i].courseVideoId : null,
                })
            }
            resultNewRankVideoCourse = await updateRankCourseVideoListFetch(user.uid, videoListUpdate, 1, Number(courseId))
        }

        console.log("newRankVideoCourse : ", videoListUpdate, isDiff)

        console.log("resultUpdate             : ", resultUpdate)
        console.log("resultInsert             : ", resultInsert)
        console.log("resultSolveBack          : ", resultSolveBack)
        console.log("resultNewRankVideoCourse : ", resultNewRankVideoCourse)
        console.log("------------------------------------------")

        if (
            resultUpdate.isSuccess &&
            resultInsert.isSuccess &&
            resultSolveBack.isSuccess &&
            resultNewRankVideoCourse.isSuccess
        ) {
            // reload
            props.handleCourseVideoListReload()

            Notification("success", "ระบบ", "บันทึกการเปลี่ยนเเปลงวิดีโอสำเร็จ")
        } else {
            Notification("error", "ระบบ", "บันทึกการเปลี่ยนเเปลงวิดีโอไม่สำเร็จ")
        }
    }

    const handleAdjustVideoList = async () => {
        const resultUpdate = await updateRequestOpenCourseVideoAdjustFetch(
            user.uid,           // uid                              
            resultVideoList,    // course video                         
            1,                  // inspectionCourseAdjustCategoryId     
            1,                  // isActive                             
            courseId,           // courseId
        )
        if (resultUpdate.data.isSuccess) {
            Notification("success", "ระบบ", "บันทึกการปรับเเก้สำเร็จ")
        } else {
            Notification("error", "ระบบ", "บันทึกการปรับเเก้ไม่สำเร็จ")
        }

        if (!isStepActive) {
            // reload
            props.handleCourseVideoListReload()
        }
    }

    const handleTimeVideo = (duration) => {
        setDuration(duration)
    }

    const onChangeChapterCategory = (id, name) => {
        let tmpChapter = []
        for (let i = 0; i < resultChapter?.length; i++) {
            tmpChapter.push({
                approvalStatusName: resultChapter[i].approvalStatusName,
                approvalStatusId: resultChapter[i].approvalStatusId,
                chapterCategoryId: id,
                chapterCategoryName: name,
                courseChapterId: resultChapter[i].courseChapterId,
                courseChapterTitleName: resultChapter[i].courseChapterTitleName,
                courseChapterDescriptionName: resultChapter[i].courseChapterDescriptionName
            })
        }
        // console.log("tmpChapter : ", tmpChapter)
        setChapterCategoryUsed({ id, name })
        setReducer(tmpChapter, "chapter")
    }

    const handlePercentWatchVideoCourse = (timeVideoCourse, timerWatchVideoCourse, percent, isFirstLoadApi, VideoCourseList, timerWatchVideoCourseList) => { }
    const handlePlayer = (player) => { }
    const handleControls = (controls) => { }

    const genBtnAddVideo = () => {
        return (
            <Button
                type="primary"
                style={{ float: "right", width: 120 }}
                disabled={switchType === "adjust-course" ? isFormDisable : false}
                onClick={() => {
                    setDefaultVideo()
                    setModalAddVideo(true)
                }}
            > + เพิ่มวิดีโอ</Button>
        )
    }

    const genBtnAddChapter = () => {
        return (
            <Button
                type="primary"
                style={{ width: 120 }}
                disabled={switchType === "adjust-course" ? isFormDisable : false}
                onClick={() => {
                    if (chapterCategoryUsed.id && chapterCategoryUsed.name) {
                        setDefaultChapter()
                        setModalAddChapter(true)
                    } else {
                        Notification("warning", "เเจ้งเตือน", "กรุณาเลือกประเภทคำนำหน้า !")
                    }
                }}
            > + เพิ่มกลุ่มวิดีโอ</Button>
        )
    }

    const handleCaptureCoverImage = async (imgBase64) => {
        const fileToUpload = convertBase64ToFile(imgBase64, 'capture-video.png')
        const formData = new FormData()
        formData.append('file', fileToUpload)
        formData.append('name', (props.user ? props.user.uid : "my-user"))
        formData.append('path', "uploaded/img/course-video-list-cover")

        const config = {
            authorization: 'authorization-text'
        }

        axios
            .post(`${apiStorageUrl}/upload/file`, formData, config)
            .then((res) => {
                // console.log("res : ", res)

                setVideoCourseCoverImage({
                    imageUrl: res?.data?.filePath,
                    loading: false
                })

                Notification("success", "ระบบ", "อัพโหลดภาพปกวิดีโอคอร์สสำเร็จ")
            })
            .catch((err) => {
                Notification("error", "ระบบ", "ไม่สามารถอัพโหลดภาพปกวิดีโอคอร์สได้ กรุณาตรวจสอบข้อมูล")
            })

        // close modal
        setModalVideoCoverImage(false)
    }

    const getRequestBaseApi = async () => {
        const language = "th"

        getSummaryVideoPrice(resultVideoList, (resultPrice ? resultPrice?.price : 0))

        if (resultCard) {
            const category = await getCourseCategoryFetch(language)

            setCourseCategory(category.filter(val => val.value === resultCard.courseCategory)[0].label)

            setIsPaymentByVideo(resultCard?.isPaymentByVideo)

            const format = await getCourseFormatFetch(language)
            setCourseFormat(format.filter(val => val.value === resultCard.courseFormat)[0].label)

            const certificate = await getCourseCertificateFetch(language)
            setCourseCertificateCategory(certificate.filter(val => val.value === resultCard.courseCertificateCategory)[0].label)
        }
    }

    const getChapterCategory = async () => {
        let result = await getChapterCategoryFetch(language)
        // console.log("getChapterCategoryFetch : ", result)
        setChapterCategory(result)
        return result
    }

    const reload = () => {
        // setLoading(true)

        // setLoading(false)
    }

    const reloadSummaryVideoPrice = (data) => {
        setSummaryVideoPrice(data)
    }

    useEffect(() => {
        setLoading(true)

        setTimeout(async function () {
            setcourseInspect(props.courseInspect)
            setCourseId(props.courseId)
            setUser(props.user)
            setIsStepActive(props.isStepActive)
            setSwitchType(props.switchType)
            setAuthorized(props.authorized)

            let resultChapterCategory = await getChapterCategory()

            // adjust
            if (switchType === "adjust-course" && (props.courseInspect ? props.courseInspect.inspectionCourseId !== 3 : false)) {
                setisFormDisable(true)
            } else {
                setisFormDisable(false)
            }

            if (props.isStepActive) {
                // set default base api
                getRequestBaseApi()
            } else {
                setReducer(props.courseVideoList, "video")

                setVideoCourseCoverImage({
                    imageUrl: props.courseVideoList?.courseVideoImgLocation,
                    loading: false
                })

                setVideoCourse({
                    videoUrl: props.courseVideoList ?
                        (props.courseVideoList.courseVideoLocation ? props.courseVideoList.courseVideoLocation : null)
                        : null,
                    loading: false
                })

                setIsPaymentByVideo(props?.isPaymentByVideo)

                getSummaryVideoPrice(props?.courseVideoList, (props.coursePrice ? props.coursePrice : 0))

                // setCourseChapter(props.courseChapter ? props.courseChapter : [])
                setReducer(props.courseChapter ? props.courseChapter : [], "chapter")

                setChapterCategoryUsed({
                    id: (props.courseChapter?.length > 0 ? props.courseChapter[0].chapterCategoryId : undefined),
                    name: (props.courseChapter?.length > 0 ? resultChapterCategory.find(val => props.courseChapter[0].chapterCategoryId === val.value).label : undefined)
                })
            }

            setLoading(false)
        }, 500)
    }, [])

    const genVideoWorkshop = () => {
        return (
            <Row>
                <Col span={24}>
                    {/* ============================================================================================= */}
                    <div style={{ paddingBottom: 0 }}>
                        <DragDropContext onDragEnd={handleOnDragChapterEnd}>
                            <Droppable droppableId="course-manage-edit-chapter-item">
                                {(provided) => (
                                    <ul
                                        className="course-manage-edit-chapter-item"
                                        {...provided.droppableProps} ref={provided.innerRef}
                                        style={{ padding: 9 }}
                                    >
                                        {resultChapter?.length > 0 ?
                                            <>
                                                {resultChapter.map((val, index) => {
                                                    return (
                                                        <Draggable
                                                            key={val.courseChapterId}
                                                            draggableId={String(val.courseChapterId)}
                                                            index={index}
                                                        >
                                                            {(provided) => (
                                                                <li
                                                                    ref={provided.innerRef}
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}
                                                                    className='drag-drop-border'
                                                                >
                                                                    <ManageChapter
                                                                        key={index}
                                                                        index={index}

                                                                        approvalStatusId={val.approvalStatusId}
                                                                        approvalStatusName={val.approvalStatusName}
                                                                        courseChapterId={val.courseChapterId}
                                                                        chapterCategoryName={val.chapterCategoryName}
                                                                        courseChapterTitleName={val.courseChapterTitleName}
                                                                        courseChapterDescriptionName={val.courseChapterDescriptionName}
                                                                        createdAt={val.createdAt}
                                                                        inspectCourseApprovalId={val.inspectCourseApprovalId}
                                                                        inspectCourseApprovalName={val.inspectCourseApprovalName}
                                                                        reasonCourseNotApproval={val.reasonCourseNotApproval}
                                                                        user={user}
                                                                        courseInspect={props.courseInspect}
                                                                        switchType={props.switchType}
                                                                        isFormDisable={isFormDisable}
                                                                        chapterCategoryUsed={chapterCategoryUsed}
                                                                        reload={reload}
                                                                    />
                                                                </li>
                                                            )}
                                                        </Draggable>
                                                    );
                                                })}
                                                {provided.placeholder}
                                            </>
                                            :
                                            <center>
                                                <div style={{ paddingTop: 20, width: '100%' }}>
                                                    <Empty
                                                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                                                        imageStyle={{
                                                            height: 50
                                                        }}
                                                        style={{ width: '100%' }}
                                                        description={
                                                            <span style={{ color: 'gray' }}>ไม่พบข้อมูล</span>
                                                        }
                                                    >
                                                    </Empty>
                                                </div>
                                            </center>
                                        }
                                    </ul>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </div>

                    <Divider />
                    <div style={{ fontSize: 14 }}>
                        <label>หมายเหตุ !</label><br />
                        <ul>
                            <li>ส่วนของ "รายการกลุ่มวิดีโอ" ใช้ในการจัดกลุ่มของวิดีโอให้เเยากตามบท หรื่อเรื่องต่าง ๆ </li>
                            <li>รายการกลุ่มวิดีโอนี้ ผู้สอนจะมีหรือไม่มีก็ได้ ขึ้นอยู่กับเนื้อหาคอร์สเรียนกับรายวิดีโอที่จะเเบ่งเป็นกลุ่มเนื่องหาหรือไม่</li>
                        </ul>
                    </div>
                    {/* ============================================================================================= */}
                </Col>
            </Row>
        );
    }

    const getPriceFormat = (price) => {
        return (
            <>{Intl.NumberFormat('en', { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(Number(price))}</>
        )
    }

    const genVideo = () => {
        return (
            <Row>
                {isPaymentByVideo ?
                    <>
                        <Col xs={8} lg={6}>
                            <label>ราคาคอร์สตั้งต้น</label>
                        </Col>
                        <Col xs={16} lg={18}>
                            <label>{getPriceFormat(summaryVideoPrice.coursePrice)} บาท</label>
                        </Col>

                        <Col xs={8} lg={6}>
                            <label>จำนวนวิดีโอ</label>
                        </Col>
                        <Col xs={16} lg={18}>
                            <label>{summaryVideoPrice.videoAmount} วิดีโอ</label>
                        </Col>

                        <Col xs={8} lg={6}>
                            <label>ราคาวิดีโอทั้งหมด</label>
                        </Col>
                        <Col xs={16} lg={18} style={{ paddingBottom: 4 }}>
                            <label style={{ color: (summaryVideoPrice.hasHappened > summaryVideoPrice.coursePrice ? "red" : "black") }}>{getPriceFormat(summaryVideoPrice.hasHappened)}</label> บาท
                            {summaryVideoPrice.remaining > 0 ?
                                <label style={{ color: "red", paddingLeft: 5 }}>เหลืออีก {getPriceFormat(summaryVideoPrice.remaining)} บาท</label>
                                : []}
                            {/* {console.log("summaryVideoPrice : ", summaryVideoPrice.hasHappened, summaryVideoPrice.coursePrice)} */}
                            {summaryVideoPrice.hasHappened > summaryVideoPrice.coursePrice ?
                                <label style={{ color: "red", paddingLeft: 5 }}>(ราคาเกินมา {getPriceFormat(summaryVideoPrice.hasHappened - summaryVideoPrice.coursePrice)} บาท)</label>
                                : []}
                        </Col>
                    </>
                    : []
                }

                <Col span={24}>
                    <div style={{ paddingBottom: 0 }}>
                        <DragDropContext onDragEnd={handleOnDragVideoEnd}>
                            <Droppable droppableId="course-manage-edit-video-item">
                                {(provided) => (
                                    <ul
                                        className="course-manage-edit-video-item"
                                        {...provided.droppableProps} ref={provided.innerRef}
                                        style={{ padding: 9 }}
                                    >
                                        {resultVideoList?.length > 0 ?
                                            <>
                                                {resultVideoList.map((val, index) => {
                                                    return (
                                                        <Draggable
                                                            key={val.courseVideoId}
                                                            draggableId={String(val.courseVideoId)}
                                                            index={index}
                                                        >
                                                            {(provided) => (
                                                                <li
                                                                    ref={provided.innerRef}
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}
                                                                    className='drag-drop-border'
                                                                >
                                                                    <ManageVideoItem
                                                                        key={index}
                                                                        courseId={val.courseId}
                                                                        courseVideoId={val.courseVideoId}
                                                                        courseVideoTitleName={val.courseVideoTitleName}
                                                                        courseVideoImgLocation={val?.courseVideoImgLocation}

                                                                        approvalStatusName={val.approvalStatusName}
                                                                        approvalStatusId={val.approvalStatusId}

                                                                        isPaymentByVideo={isPaymentByVideo}

                                                                        inspectCourseApprovalName={val.inspectCourseApprovalName}
                                                                        inspectCourseApprovalId={val.inspectCourseApprovalId}

                                                                        courseVideoDescriptionName={val.courseVideoDescriptionName}
                                                                        courseVideoPrice={val.courseVideoPrice}
                                                                        courseVideoLocation={val.courseVideoLocation}
                                                                        user={user}
                                                                        isUpdate={val.isUpdate}
                                                                        courseVideoDuration={val.courseVideoDuration}
                                                                        courseVideoRank={val.courseVideoRank}
                                                                        view={val.view}
                                                                        courseChapterId={val.courseChapterId}
                                                                        created_at={val.created_at}
                                                                        index={index}
                                                                        reasonCourseNotApproval={val.reasonCourseNotApproval}
                                                                        switchType={props.switchType}
                                                                        isFormDisable={isFormDisable}
                                                                        coursePrice={props.coursePrice}

                                                                        courseInspect={props.courseInspect}

                                                                        handleCourseVideoListReload={handleCourseVideoListReload}
                                                                        reloadSummaryVideoPrice={reloadSummaryVideoPrice}
                                                                    />
                                                                </li>
                                                            )}
                                                        </Draggable>
                                                    );
                                                })}
                                                {provided.placeholder}
                                            </>
                                            :
                                            <center>
                                                <div style={{ paddingTop: 20, width: '100%' }}>
                                                    <Empty
                                                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                                                        imageStyle={{
                                                            height: 50
                                                        }}
                                                        style={{ width: '100%' }}
                                                        description={
                                                            <span style={{ color: 'gray' }}>ไม่พบข้อมูล</span>
                                                        }
                                                    >
                                                    </Empty>
                                                </div>
                                            </center>
                                        }
                                    </ul>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </div>

                    <Divider />
                    <div style={{ fontSize: 14 }}>
                        <label>หมายเหตุ !</label><br />
                        <ul>
                            <li>ส่วนของ "รายการวิดีโอ" ใช้เป็นเนื้อหาการสอนหลัก </li>
                            <li>คอร์สเรียนต้องมีรายการวิดีโอที่เป็นเนื้อหาเรียนขั้นต่ำ 3 วิดีโอขึ้นไป เเละไม่จำกัดจำนวนวิดีโอ</li>
                            <li>รายการวิดีโอเนื้อหาการสอนเหล่านี้ ระบบจะเปิดวิดีโอตัวเเรกเป็นเเบบสาธารณะ เพื่อใช้เป็นตัวอย่างการสอน ส่วนวิดีโออื่น ๆ ทังหมดจะเป็นเเบบไม่สาธารณะ ดูได้ก็ต่อเมื่อซื้อคอร์สของท่านเเล้วเท่านั้น</li>
                            <li>เมื่อ "รายการวิดีโอ" ผ่านการตรวจสอบเเล้ว จะเผยแพร่ให้เฉพาะผู้เรียนที่ชำระเงินซื้อคอร์สนี้เเล้วเห็นได้ทันที ยกเว้นวิดีโอตัวเเรกในลำดับที่ 1 ของรายการวิดีโอนี้ ที่ผู้คนทั่วไปจะมองเห็นได้</li>
                        </ul>
                    </div>
                </Col>
            </Row>
        );
    }

    const genPreview = () => {
        return (
            <CourseVideoPreview
                resultChapter={resultChapter}
                resultVideo={resultVideoList}
                isPaymentByVideo={isPaymentByVideo}
                bgChapterTitle="#494949"
                bgNonChapterTitle="#494949"
                bgChapterBody="black"
                bgVideo="#292929"
                borderLine="#EEEEEE"
                fontColor="white"
            />
        );
    }

    const genBoard = () => {
        return (
            <Row>
                <Col span={24}>
                    <Row>
                        <Col xs={24} sm={18} md={16} lg={16}>
                            <Collapse ghost>
                                <div
                                    style={{
                                        padding: 12,
                                        border: "1px solid #EEEEEE"
                                    }}
                                >
                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                                        <div
                                            style={{ display: "flex", alignItems: "center" }}
                                            onClick={() => onSwitchWorkshop()}
                                        >
                                            {switchWorkshop ?
                                                <Icon icon="charm:chevron-down" style={{ width: 23, height: 23 }} />
                                                :
                                                <Icon icon="charm:chevron-right" style={{ width: 23, height: 23 }} />
                                            }

                                            <label
                                                style={{ cursor: "pointer" }}
                                                onClick={() => onSwitchWorkshop()}
                                            >รายการกลุ่มวิดีโอ</label>
                                        </div>

                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <div style={{ paddingRight: 10 }}>
                                                {switchType === "edit-course" ?
                                                    genBtnAddChapter()
                                                    :
                                                    genBtnAddChapter()
                                                }
                                            </div>

                                            <Select
                                                defaultValue={chapterCategoryUsed.id ? chapterCategoryUsed.id : undefined}
                                                showSearch
                                                placeholder="เลือกประเภทคำนำหน้า"
                                                optionFilterProp="children"
                                                // allowClear
                                                style={{ width: 170 }}
                                                onChange={(e) => {
                                                    onChangeChapterCategory(e, chapterCategory.find(val => e === val.value).label);
                                                }}
                                            >
                                                {chapterCategory.map((val, index) => <Option key={index} value={val.value}>{val.label}</Option>)}
                                            </Select>
                                        </div>
                                    </div>
                                </div>
                                {switchWorkshop ?
                                    <div
                                        style={{
                                            width: "100%",
                                            borderBottom: "1px solid #EEEEEE",
                                            borderLeft: "1px solid #EEEEEE",
                                            borderRight: "1px solid #EEEEEE",
                                            cursor: "pointer"
                                        }}
                                    >
                                        <div style={{ padding: 15 }}>
                                            {genVideoWorkshop()}
                                        </div>
                                    </div>
                                    : []
                                }

                                <div style={{ paddingBottom: 20 }}></div>

                                <div
                                    style={{
                                        padding: 12,
                                        border: "1px solid #EEEEEE"
                                    }}
                                >
                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                                        <div
                                            style={{ display: "flex", alignItems: "center" }}
                                            onClick={() => onSwitchVideo()}
                                        >
                                            {switchVideo ?
                                                <Icon icon="charm:chevron-down" style={{ width: 23, height: 23 }} />
                                                :
                                                <Icon icon="charm:chevron-right" style={{ width: 23, height: 23 }} />
                                            }

                                            <label
                                                style={{ cursor: "pointer" }}
                                                onClick={() => onSwitchVideo()}
                                            >รายการวิดีโอ</label>
                                        </div>

                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            {switchType === "edit-course" ?
                                                props.courseInspect ?
                                                    props.courseInspect.inspectionCourseId !== 2 && props.courseInspect.inspectionCourseApprovalId !== 2 ?
                                                        genBtnAddVideo()
                                                        :
                                                        !props.courseInspect.courseVideo.inspectionCourseAdjustIdSummary ?
                                                            genBtnAddVideo()
                                                            :
                                                            <Button type="primary" style={{ float: "right" }} disabled={true}> + เพิ่มวิดีโอ</Button>
                                                    :
                                                    genBtnAddVideo()
                                                :
                                                <>
                                                    {props.courseInspect?.inspectionCourseVideoId === 2 ?
                                                        <Button type="primary" style={{ float: "right" }} disabled={true}> + เพิ่มวิดีโอ</Button>
                                                        :
                                                        genBtnAddVideo()
                                                    }
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>
                                {switchVideo ?
                                    <div
                                        style={{
                                            width: "100%",
                                            borderBottom: "1px solid #EEEEEE",
                                            borderLeft: "1px solid #EEEEEE",
                                            borderRight: "1px solid #EEEEEE",
                                            cursor: "pointer"
                                        }}
                                    >
                                        <div style={{ padding: 15 }}>
                                            {genVideo()}
                                        </div>
                                    </div>
                                    : []
                                }
                            </Collapse>
                        </Col>

                        <Col
                            xs={24} sm={6} md={8} lg={8}
                            style={{ paddingLeft: 20 }}
                        >
                            <div
                                style={{
                                    height: "100%",
                                    border: "1px solid #EEEEEE",
                                    backgroundColor: "#EEEEEE",
                                    borderRadius: "15px 15px 15px 15px"
                                }}
                            >
                                <div
                                    style={{
                                        paddingTop: 8,
                                        paddingBottom: 8,
                                        paddingLeft: 12,
                                        paddingRight: 12,
                                        fontSize: 16
                                    }}
                                >
                                    <span>เเสดงตัวอย่างรายการวิดีโอ</span>
                                </div>
                                {genPreview()}
                            </div>
                        </Col>
                    </Row>
                </Col>

                <Col span={24}>
                    <Divider style={{ marginTop: 20, marginBottom: 20 }} />
                    <Col span={24}>
                        {isStepActive ?
                            <center>
                                <Row gutter={[24, 24]}>
                                    <Col xs={24} sm={8} md={9} lg={10}>
                                        <Button
                                            style={width > 992 ? { float: 'right', width: 130 } : ((width < 992 && width > 576) ? { float: 'right', width: 130 } : { width: '100%' })}
                                            type="default"
                                            onClick={() => dispatch(setCourseManageAddCurrentStepStateToSuccess(Number(resultCourseManageAddCurrentStep) - 1))}
                                        >
                                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                <Icon icon="fluent:arrow-step-back-20-filled" />
                                                <label style={{ paddingLeft: 10, cursor: "pointer" }}>ย้อนกลับ</label>
                                            </div>
                                        </Button>
                                    </Col>
                                    <Col xs={24} sm={8} md={6} lg={4}>
                                        <Button
                                            style={width > 992 ? { width: 130 } : ((width < 992 && width > 576) ? { width: 130 } : { width: '100%' })}
                                            type="primary"
                                            onClick={() => {
                                                if (resultVideoList) {
                                                    if (resultVideoList.length <= 2) {
                                                        Notification("warning", "ระบบ", "กรุณาเพิ่มคอร์สวิดีโอ อย่างน้อย 3 วิดีโอ!")
                                                    } else {
                                                        if (isPaymentByVideo) {
                                                            if (summaryVideoPrice.remaining === 0) {
                                                                // set session
                                                                localStorage.setItem("stepsVideoClickable", 1)
                                                                setModalCheckInformation(true)
                                                            } else {
                                                                Notification("warning", "ระบบ", "ราคาของวิดีโอทั้งหมดรวมกันต้องเท่ากับราคาคอร์สตั้งต้น!")
                                                            }
                                                        } else {
                                                            // set session
                                                            localStorage.setItem("stepsVideoClickable", 1)
                                                            setModalCheckInformation(true)
                                                        }
                                                    }
                                                } else {
                                                    Notification("warning", "ระบบ", "กรุณาเพิ่มคอร์สวิดีโอ อย่างน้อย 3 วิดีโอ!")
                                                }
                                            }}
                                        >
                                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                <Icon icon="bi:send" style={{ color: "white" }} />
                                                <label style={{ paddingLeft: 10, cursor: "pointer" }}>ส่งตรวจสอบ</label>
                                            </div>
                                        </Button>
                                    </Col>
                                    <Col xs={24} sm={8} md={9} lg={10}>
                                        <Popconfirm
                                            title="คุณยืนยันส่งคำขอเพิ่มคอร์สหรือไม่ ?"
                                            okText={<span style={{ width: 50 }}>ใช่</span>}
                                            onConfirm={() => {
                                                // onProcessCancel()
                                            }}
                                            cancelText={<span style={{ width: 50 }}>ไม่ใช่</span>}
                                        >
                                            <Button style={width > 992 ? { float: 'left', width: 130 } : ((width < 992 && width > 576) ? { float: 'left', width: 130 } : { width: '100%' })}>
                                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                    <Icon icon="mdi:cancel" />
                                                    <label style={{ paddingLeft: 10, cursor: "pointer" }}>ยกเลิก</label>
                                                </div>
                                            </Button>
                                        </Popconfirm>
                                    </Col>
                                </Row>
                            </center>
                            : []}
                    </Col>
                </Col>
            </Row>
        )
    }

    return (
        <>
            {!loading ?
                <>
                    {isStepActive ?
                        <>
                            {genBoard()}
                        </>
                        :
                        <>
                            <Row style={{ padding: 10 }}>
                                <Col span={24} style={{ paddingLeft: 10, paddingRight: 10, paddingTop: 10 }}>
                                    {genBoard()}
                                </Col>
                                <Col span={24} style={{ paddingBottom: 10 }}>
                                    <center>
                                        {(courseInspect ? courseInspect.courseVideo.inspectionCourseApprovalIdSummary === 2 : false) ?
                                            <Popconfirm
                                                title="คุณยืนยันบันทึกการปรับเเก้หรือไม่ ?"
                                                okText={<span style={{ width: 50 }}>ใช่</span>}
                                                onConfirm={() => {
                                                    // console.log("isPaymentByVideo : ", isPaymentByVideo);
                                                    // console.log("summaryVideoPrice.remaining : ", summaryVideoPrice.remaining);
                                                    if (isPaymentByVideo) {
                                                        if (summaryVideoPrice.remaining === 0) {
                                                            switchType === "adjust-course" ? handleAdjustVideoList() : handleUpdateVideoList("solveBack")
                                                        } else {
                                                            Notification("warning", "ระบบ", "ราคาของวิดีโอทั้งหมดรวมกันต้องเท่ากับราคาคอร์สตั้งต้น!");
                                                        }
                                                    } else {
                                                        switchType === "adjust-course" ? handleAdjustVideoList() : handleUpdateVideoList("solveBack")
                                                    }
                                                }}
                                                cancelText={<span style={{ width: 50 }}>ไม่ใช่</span>}
                                                disabled={courseInspect.inspectionCourseId === 3 ? false : true}
                                            >
                                                <Row>
                                                    <Col span={24}>
                                                        <Button
                                                            type="primary"
                                                            disabled={courseInspect.inspectionCourseId === 3 ? false : true}
                                                        >
                                                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                                <Icon icon="bx:save" style={{ color: "white" }} />
                                                                <label style={{ paddingLeft: 10, cursor: "pointer" }}>บันทึกการปรับเเก้</label>
                                                            </div>
                                                        </Button>
                                                    </Col>
                                                    <Col span={24}>
                                                        {switchType === "edit-course" && courseInspect.inspectionCourseId === 2 && courseInspect.inspectionCourseVideoId === 1 ?
                                                            <label style={{ paddingTop: 10 }}>กำลังอยู่ระหว่างการตรวจสอบ ทำให้ไม่สามารถกดปุ่ม "บันทึกการเเก้ไข" ได้ในขณะนี้! จนกว่าจะตรวจสอบเสร็จ เเละเเจ้งผลให้ทราบ</label>
                                                            : []}
                                                    </Col>
                                                </Row>
                                            </Popconfirm>
                                            :
                                            <>
                                                {switchType === "edit-course" ?
                                                    <Popconfirm
                                                        title="คุณยืนยันการบันทึกการปรับเเต่งหรือไม่ ?"
                                                        okText={<span style={{ width: 50 }}>ใช่</span>}
                                                        onConfirm={() => {
                                                            if (isPaymentByVideo) {
                                                                if (summaryVideoPrice.remaining === 0) {
                                                                    handleUpdateVideoList(null);
                                                                    handleUpdateChapterList(null);
                                                                } else {
                                                                    Notification("warning", "ระบบ", "ราคาของวิดีโอทั้งหมดรวมกันต้องเท่ากับราคาคอร์สตั้งต้น!");
                                                                }
                                                            } else {
                                                                handleUpdateVideoList(null);
                                                                handleUpdateChapterList(null);
                                                            }
                                                        }}
                                                        cancelText={<span style={{ width: 50 }}>ไม่ใช่</span>}
                                                    >
                                                        <Button type="primary">
                                                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                                <Icon icon="bx:save" style={{ color: "white" }} />
                                                                <label style={{ paddingLeft: 10, cursor: "pointer" }}>บันทึกการปรับเเต่ง</label>
                                                            </div>
                                                        </Button>
                                                    </Popconfirm>
                                                    : []}
                                            </>
                                        }
                                    </center>
                                </Col>
                            </Row>

                            {switchType === "adjust-course" ?
                                (courseInspect ? courseInspect.courseVideo.inspectionCourseApprovalIdSummary === 2 : false)
                                :
                                (switchType === "edit-course" ?
                                    (
                                        (courseInspect ? (
                                            (courseInspect.courseVideo.inspectionCourseApprovalIdSummary === 2 && courseInspect.inspectionCourseVideoId === 1 && courseInspect.inspectionCourseId === 3) ||
                                            (courseInspect.amountEdit > 0 && courseInspect.courseVideo.inspectionCourseApprovalIdSummary === 2)
                                        ) : false)
                                    ) : false
                                )
                                    ?
                                    <div style={{ paddingLeft: 20, paddingRight: 20, paddingBottom: 20, backgroundColor: 'white' }}>
                                        <Row style={{ padding: 10, border: '1px solid #EEEEEE', fontSize: 14 }}>
                                            <Col span={24} style={{ padding: 10 }}>
                                                ตัวอย่าง เกร็ดความรู้ เเละสิ่งที่ควรหลีกเลี่ยง
                                            </Col>
                                            <Col span={24}>
                                                <label style={{ paddingLeft: 20 }}>1. วีดีโอควรสอดคล้องกับหัวข้อที่ได้ทำการสอน เเละควรอยู่ในองค์ประกอบที่เหมาะสม</label>
                                            </Col>
                                            <Col span={24}>
                                                <label style={{ paddingLeft: 20 }}>2. ขนาดของระยะเวลาการเรียนคอร์สขั้นต่ำ รวมทุกวิดีโอต้อง 45 นาทีขึ้นไป</label>
                                            </Col>
                                            <Col span={24}>
                                                <label style={{ paddingLeft: 20 }}>3. จำนวนวิดีโอคอร์ส ต้องมี 3 วิดีโอขึ้นไป โดยเเต่ละวิดีโอจะมีความยาวเท่าใดก็ได้ เเต่เมื่อรวมกันเเล้วจะต้องเป็นไปตามข้อ 1 ทั้งนี้จะไม่รวมวิดีโอนำเสนอ ที่ต้องเเยกออกมาอีก 1 วิดีโอ สำหรับเเนะนำคอร์สเบื้องต้นให้กับผู้เรียน</label>
                                            </Col>
                                            <Col span={24}>
                                                <label style={{ paddingLeft: 20 }}>2. ขนาดของความละเอียดของวิดีโอคอร์ส ต้อง HD 720p (1,280 x 720 พิกเซล) ขึ้นไป</label>
                                            </Col>
                                            <Col span={24}>
                                                <label style={{ paddingLeft: 20 }}>3. เสียงวิดีโอต้องมีความคมชัด ไม่มีเสียงซ่า หรือเสียงรบกวน</label>
                                            </Col>

                                        </Row>
                                    </div>
                                    : []
                            }
                        </>
                    }

                    {/* Start modal check information */}
                    <Modal
                        title="ตรวจสอบข้อมูล"
                        visible={modalCheckInformation}
                        onCancel={() => {
                            setModalCheckInformation(false);
                        }}
                        width={width > 992 ? "60%" : "90%"}
                        maskClosable={false}
                        footer={[
                            <Button
                                type="default"
                                style={{ width: 130 }}
                                onClick={() => setModalCheckInformation(false)}
                            >
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                    <Icon icon="fluent:arrow-step-back-20-filled" style={{ color: "white" }} />
                                    <label style={{ paddingLeft: 10, cursor: "pointer" }}>ย้อนกลับ</label>
                                </div>
                            </Button>,
                            <Button
                                type="primary"
                                style={{ width: 130 }}
                                onClick={() => onProcessComplete()}
                                loading={sendLoading}
                            >
                                {!sendLoading ?
                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                        <Icon icon="bi:send" style={{ color: "white" }} />
                                        <label style={{ paddingLeft: 10, cursor: "pointer" }}>ยืนยันส่ง</label>
                                    </div>
                                    : []
                                }
                            </Button>
                        ]}
                        loading={loading}
                    >
                        <Row>
                            <Col span={6} style={{ padding: 10 }}>
                                <lable style={{ fontWeight: 'bold' }}>ชื่อคอร์ส</lable>
                            </Col>
                            <Col span={18} style={{ padding: 10 }}>
                                <lable>{resultCard ? resultCard.title : '-'}</lable>
                            </Col>

                            <Col span={6} style={{ padding: 10 }}>
                                <lable style={{ fontWeight: 'bold' }}>ภาพคอร์ส</lable>
                            </Col>
                            <Col span={18} style={{ padding: 10 }}>
                                {resultCard ?
                                    <Image
                                        style={{ width: 250 }}
                                        src={`${resultCard.courseCoverImage}`}
                                    />
                                    : []}
                            </Col>

                            <Col span={6} style={{ padding: 10 }}>
                                <lable style={{ fontWeight: 'bold' }}>เเนะนำคอร์สเบื้องต้น</lable>
                            </Col>
                            <Col span={18} style={{ padding: 10 }}>
                                <lable>{resultCard ? resultCard.subDescription : '-'}</lable>
                            </Col>

                            <Col span={6} style={{ padding: 10 }}>
                                <lable style={{ fontWeight: 'bold' }}>ประเภทคอร์ส</lable>
                            </Col>
                            <Col span={18} style={{ padding: 10 }}>
                                <lable>{courseCategory ? courseCategory : '-'}</lable>
                            </Col>

                            <Col span={6} style={{ padding: 10 }}>
                                <lable style={{ fontWeight: 'bold' }}>รูปเเบบคอร์ส</lable>
                            </Col>
                            <Col span={18} style={{ padding: 10 }}>
                                <lable>{courseFormat ? courseFormat : '-'}</lable>
                            </Col>

                            <Col span={6} style={{ padding: 10 }}>
                                <lable style={{ fontWeight: 'bold' }}>โหมดเช่าคอร์สรายเดือน</lable>
                            </Col>
                            <Col span={18} style={{ padding: 10 }}>
                                <lable>{resultCard ? 'เปิด' : 'ปิด'}</lable>
                            </Col>

                            <Col span={6} style={{ padding: 10 }}>
                                <lable style={{ fontWeight: 'bold' }}>ใบรับรอง (Certificate)</lable>
                            </Col>
                            <Col span={18} style={{ padding: 10 }}>
                                <lable>{courseCertificateCategory ? courseCertificateCategory : '-'}</lable>
                            </Col>

                            <Col span={24}>
                                <Divider />
                            </Col>
                            <Col span={6} style={{ padding: 10 }}>
                                <lable style={{ fontWeight: 'bold' }}>ชื่อสื่อนำเสนอ</lable>
                            </Col>
                            <Col span={18} style={{ padding: 10 }}>
                                <lable>{resultIntro ? resultIntro.title : '-'}</lable>
                            </Col>

                            <Col span={6} style={{ padding: 10 }}>
                                <lable style={{ fontWeight: 'bold' }}>สื่อนำเสนอ</lable>
                            </Col>
                            <Col span={18} style={{ padding: 10 }}>
                                {resultIntro ?
                                    <Video
                                        courseId={null}
                                        learnerId={null}

                                        videoTitle=""
                                        videoLocation={PathVideo(resultIntro.location)}
                                        component={"upload-video"}
                                        handleTimeVideo={handleTimeVideo}
                                        handlePercentWatchVideoCourse={handlePercentWatchVideoCourse}
                                        handlePlayer={handlePlayer}
                                        handleControls={handleControls}
                                    />
                                    : []}
                            </Col>

                            <Col span={24}>
                                <Divider />
                            </Col>
                            <Col span={6} style={{ padding: 10 }}>
                                <lable style={{ fontWeight: 'bold' }}>ราคาปกติ</lable>
                            </Col>
                            <Col span={18} style={{ padding: 10 }}>
                                <lable>{resultPrice ? (resultPrice.price ? <label>{Intl.NumberFormat('en').format(Number(resultPrice.price))} บาท</label> : '-') : '-'}</lable>
                            </Col>

                            <Col span={6} style={{ padding: 10 }}>
                                <lable style={{ fontWeight: 'bold' }}>ราคาที่ลด</lable>
                            </Col>
                            <Col span={18} style={{ padding: 10 }}>
                                <lable>{resultPrice ? (resultPrice.priceDiscount ? <label>{Intl.NumberFormat('en').format(Number(resultPrice.priceDiscount))} บาท</label> : '-') : '-'}</lable>
                            </Col>

                            <Col span={6} style={{ padding: 10 }}>
                                <lable style={{ fontWeight: 'bold' }}>ลดถึง</lable>
                            </Col>
                            <Col span={18} style={{ padding: 10 }}>
                                <p>{resultPrice ? (resultPrice.dateTimeEndDiscount ? <label>{resultPrice.dateTimeEndDiscount}</label> : '-') : '-'}</p>
                            </Col>

                            <Col span={24}>
                                <Divider />
                            </Col>
                            <Col span={6} style={{ padding: 10 }}>
                                <lable style={{ fontWeight: 'bold' }}>รายละเอียดคอร์ส</lable>
                            </Col>
                            <Col span={18} style={{ padding: 10 }}>
                                <lable>{resultDescription ? parse(resultDescription.description) : '-'}</lable>
                            </Col>

                            <Col span={24}>
                                <Divider />
                            </Col>
                            <Col span={6} style={{ padding: 10 }}>
                                <lable style={{ fontWeight: 'bold' }}>เอกสารเพิ่มเติม</lable>
                            </Col>
                            <Col span={18}>
                                <>
                                    {resultDocument?.length > 0 ?
                                        <>
                                            <Row style={{ backgroundColor: '#23B14D', color: "white" }}>
                                                <Col span={24} style={{ padding: 10 }}>ชื่อเอกสาร</Col>
                                            </Row>
                                            <>
                                                {resultDocument.map(val => {
                                                    return <Row style={{ borderBottom: '1px solid #23B14D', borderLeft: '1px solid #23B14D', borderRight: '1px solid #23B14D', paddingBottom: 8, paddingTop: 8 }}><Col span={24} style={{ paddingLeft: 10 }}>{val.coureDocumentationName}</Col></Row>
                                                })}
                                            </>
                                        </>
                                        :
                                        <label style={{ marginTop: 4 }}>-</label>
                                    }
                                </>

                            </Col>

                            <Col span={24}>
                                <Divider />
                            </Col>
                            <Col span={6} style={{ padding: 10 }}>
                                <lable style={{ fontWeight: 'bold' }}>รายการกลุ่มวิดีโอ</lable>
                            </Col>
                            <Col span={18}>
                                <>
                                    {resultChapter?.length > 0 ?
                                        <>
                                            <Row style={{ padding: 10, backgroundColor: '#23B14D', color: "white" }}>
                                                <Col span={12}>ชื่อกลุ่มวิดีโอ</Col>
                                                <Col span={12}>รายละเอียดกลุ่มวิดีโอ</Col>
                                            </Row>
                                            {resultChapter ?
                                                <>
                                                    {resultChapter.map(val => {
                                                        return <Row style={{ paddingLeft: 10, paddingRight: 10, paddingBottom: 10, borderBottom: '1px solid #23B14D', borderLeft: '1px solid #23B14D', borderRight: '1px solid #23B14D' }}>
                                                            <Col span={12} style={{ paddingTop: 8 }}>{val.courseChapterTitleName}</Col>
                                                            <Col span={12} style={{ paddingTop: 8 }}>{val.courseChapterDescriptionName}</Col>
                                                        </Row>
                                                    })}
                                                </>
                                                : []
                                            }
                                        </>
                                        :
                                        <label style={{ marginTop: 4 }}>-</label>
                                    }
                                </>

                            </Col>

                            <Col span={24}>
                                <Divider />
                            </Col>
                            <Col span={6} style={{ padding: 10 }}>
                                <lable style={{ fontWeight: 'bold' }}>รายการวิดีโอ</lable>
                            </Col>
                            <Col span={18}>
                                <>
                                    {resultVideoList?.length > 0 ?
                                        <>
                                            <Row style={{ padding: 10, backgroundColor: '#23B14D', color: "white" }}>
                                                <Col span={8}>ชื่อวิดีโอ</Col>
                                                <Col span={13}>รายละเอียดวิดีโอ</Col>
                                                <Col span={3}>ปกวิดีโอ</Col>
                                            </Row>
                                            {resultVideoList ?
                                                <>
                                                    {resultVideoList.map(val => {
                                                        return <Row style={{ paddingLeft: 10, paddingRight: 8, borderBottom: '1px solid #23B14D', borderLeft: '1px solid #23B14D', borderRight: '1px solid #23B14D' }}>
                                                            <Col xs={8} md={8} lg={8} style={{ paddingTop: 8 }}>{val.courseVideoTitleName}</Col>
                                                            <Col xs={10} md={10} lg={12} style={{ paddingTop: 8 }}>{val.courseVideoDescriptionName}</Col>
                                                            <Col xs={6} md={6} lg={4}>
                                                                <center style={{ paddingTop: 5 }}>
                                                                    <Image
                                                                        style={{ width: "100%", border: '1px solid #23B14D' }}
                                                                        src={`${val?.courseVideoImgLocation}`}
                                                                    />
                                                                </center>
                                                            </Col>
                                                        </Row>
                                                    })}
                                                </>
                                                : []
                                            }
                                        </>
                                        :
                                        <label style={{ marginTop: 4 }}>-</label>
                                    }
                                </>

                            </Col>
                        </Row>
                    </Modal>
                    {/* End modal check information */}

                    {/* Start modal add video */}
                    <Modal
                        title="เพิ่มวิดีโอ"
                        visible={modalAddVideo}
                        width={width > 992 ? "60%" : "90%"}
                        maskClosable={false}
                        onCancel={() => {
                            // set default
                            setDefaultVideo()
                        }}
                        onOk={() => formAddVideo.submit()}
                        okText={
                            <div style={{ width: 110 }}>
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                    <Icon icon="bx:save" style={{ color: "white" }} />
                                    <label style={{ paddingLeft: 10, cursor: "pointer" }}>บันทึก</label>
                                </div>
                            </div>
                        }
                        cancelText={
                            <div style={{ width: 100 }}>
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                    <Icon icon="mdi:cancel" />
                                    <label style={{ paddingLeft: 10, cursor: "pointer" }}>ยกเลิก</label>
                                </div>
                            </div>
                        }
                    >
                        <Form layout="vertical" form={formAddVideo} onFinish={onFinishAddVideo}>
                            <Row>
                                <Col xs={24} lg={24} xl={11}>
                                    <Col span={24} style={{ paddingLeft: 0 }}>
                                        <Row>
                                            <Col span={24}>
                                                <label style={{ color: 'red' }}>*</label>
                                                <label style={{ paddingLeft: 8 }}>วิดีโอ</label>
                                            </Col>
                                            <Col span={24}>
                                                {videoCourse.videoUrl ?
                                                    <Video
                                                        courseId={null}
                                                        learnerId={null}

                                                        videoTitle=""
                                                        videoLocation={PathVideo(videoCourse.videoUrl)}
                                                        component={"upload-video"}
                                                        handleTimeVideo={handleTimeVideo}
                                                        handlePercentWatchVideoCourse={handlePercentWatchVideoCourse}
                                                        handlePlayer={handlePlayer}
                                                        handleControls={handleControls}
                                                    />
                                                    :
                                                    <img
                                                        style={{ width: '100%', border: "1px solid #EEEEEE" }}
                                                        src={`./assets/images/default/df-vdo.png`}
                                                    />
                                                }
                                            </Col>
                                            <Col span={24}>
                                                <div style={{ paddingTop: 12 }}>
                                                    <Form.Item
                                                        name="uploadVideoCourse"
                                                        rules={[{ required: true, message: 'กรุณาอัพโหลดวิดีโอ' }]}
                                                    >
                                                        <Upload
                                                            {...optionVideo}
                                                            accept='.mp4'
                                                            maxCount={1}
                                                            showUploadList={{ showRemoveIcon: false }}
                                                        >
                                                            <Button
                                                                type="primary"
                                                                style={{ width: '100%' }}
                                                                icon={videoCourse.loading ? <LoadingOutlined /> : <UploadOutlined />}
                                                            >อัพโหลดวิดีโอ</Button>
                                                        </Upload>
                                                    </Form.Item>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>

                                    <Col span={24} style={{ paddingBottom: 15, paddingRight: 0 }}>
                                        <Row gutter={[12, 0]}>
                                            <Col span={24}>
                                                <label style={{ color: 'red' }}>*</label>
                                                <label style={{ paddingLeft: 8 }}>ภาพปกวิดีโอ</label>
                                            </Col>
                                            <Col span={24}>
                                                {videoCourseCoverImage.imageUrl ?
                                                    <img
                                                        style={{ width: '100%', border: "1px solid #EEEEEE" }}
                                                        src={`${videoCourseCoverImage.imageUrl}`}
                                                    />
                                                    :
                                                    <img
                                                        style={{ width: '100%', border: "1px solid #EEEEEE" }}
                                                        src={`./assets/images/default/df-img.png`}
                                                    />
                                                }
                                            </Col>
                                            <Col xs={24} sm={24} md={12} xl={24} lg={12}>
                                                <div style={{ paddingTop: 12 }}>
                                                    <Form.Item
                                                        name="uploadImage"
                                                        rules={[{ required: true, validator: detectVideoCourseCoverImage }]}
                                                    >
                                                        <ImgCrop
                                                            modalTitle={<label style={{ fontWeight: 'bold', padding: 0, margin: 0 }}>ปรับเเต่งรูปภาพ</label>}
                                                            modalOk={<label style={{ width: 90, cursor: "pointer" }}><AiOutlineUpload style={{ width: 20, height: 20, paddingRight: 5 }} /> อัพโหลด</label>}
                                                            modalCancel={<label style={{ width: 90, cursor: "pointer" }}><Icon icon="mdi:cancel" style={{ width: 20, height: 20, paddingRight: 5 }} />ยกเลิก</label>}
                                                            aspect={531 / 300}
                                                        >
                                                            <Upload
                                                                {...optionCoverVideo}
                                                                accept='image/jpeg, image/png, image/jfif'
                                                                maxCount={1}
                                                                showUploadList={{ showRemoveIcon: false }}
                                                            >
                                                                <Button
                                                                    type="primary"
                                                                    style={{ width: '100%' }}
                                                                    icon={videoCourseCoverImage.loading ? <LoadingOutlined /> : <UploadOutlined />}
                                                                >อัพโหลดภาพปก</Button>
                                                            </Upload>
                                                        </ImgCrop>
                                                    </Form.Item>
                                                </div>
                                            </Col>
                                            <Col xs={24} sm={24} md={12} xl={24} lg={12}>
                                                <Button
                                                    type="primary"
                                                    style={{ width: '100%' }}
                                                    onClick={() => { setModalVideoCoverImage(true) }}
                                                    disabled={videoCourse.videoUrl ? false : true}
                                                >
                                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                        <Icon icon="dashicons:cover-image" style={{ color: videoCourse.videoUrl ? "white" : "" }} />
                                                        <label style={{ paddingLeft: 10, cursor: "pointer" }}>เลือกภาพจาก Video</label>
                                                    </div>
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Col>
                                <Col xs={24} lg={24} xl={13} style={width > 992 ? { paddingLeft: 25 } : {}}>
                                    <Form.Item
                                        name="videoTitle"
                                        label={
                                            <span>
                                                ชื่อวิดีโอคอร์ส
                                                <span style={{ paddingLeft: 10 }}> (จำกัดตัวอักษร 100 ตัว)</span>
                                            </span>
                                        }
                                        rules={[{ required: true, message: 'กรุณากรอกชื่อวิดีโอคอร์ส' }]}
                                    >
                                        <Input
                                            showCount
                                            maxLength={100}
                                        />
                                    </Form.Item>

                                    <Form.Item
                                        name="videoDescription"
                                        label={
                                            <span>
                                                รายละเอียดวิดีโอคอร์ส
                                                <span style={{ paddingLeft: 10 }}> (จำกัดตัวอักษร 500 ตัว)</span>
                                            </span>
                                        }
                                        rules={[{ required: true, message: 'กรุณากรอกรายละเอียดวิดีโอคอร์ส' }]}
                                    >
                                        <TextArea
                                            showCount
                                            maxLength={500}
                                            autoSize={{ minRows: 10, maxRows: 10 }}
                                        />
                                    </Form.Item>

                                    {isPaymentByVideo ?
                                        <Form.Item
                                            name="videoPrice"
                                            label={<span>ราคาวิดีโอคอร์ส</span>}
                                            rules={[{ required: true, message: 'กรุณากรอกราคาวิดีโอคอร์ส' }]}
                                        >
                                            <InputNumber
                                                precision={2}
                                                min={0}
                                                max={100000}
                                                step={0.1}
                                                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                                style={{ width: '50%' }}
                                            />
                                        </Form.Item>
                                        : []}
                                </Col>
                            </Row>
                        </Form>
                    </Modal>
                    {/* End modal add video */}

                    {/* Start modal add chapter */}
                    <Modal
                        title="เพิ่มกลุ่มวิดีโอ"
                        visible={modalAddChapter}
                        width={width > 992 ? 650 : ((width <= 992 && width >= 576) ? "80%" : "100%")}
                        maskClosable={false}
                        onCancel={() => {
                            // set default
                            setDefaultChapter();
                        }}
                        onOk={() => formAddChapter.submit()}
                        okText={
                            <div style={{ width: 110 }}>
                                <Icon icon="bx:save" style={{ color: "white" }} />
                                <label style={{ paddingLeft: 10, cursor: "pointer" }}>บันทึก</label>
                            </div>
                        }
                        cancelText={
                            <div style={{ width: 100 }}>
                                <Icon icon="mdi:cancel" />
                                <label style={{ paddingLeft: 10, cursor: "pointer" }}>ยกเลิก</label>
                            </div>
                        }
                    >
                        <Form layout="vertical" form={formAddChapter} onFinish={onFinishAddChapter}>
                            <Row gutter={[24, 0]}>
                                <Col xs={24} lg={12}>
                                    <Form.Item
                                        name="courseChapterTitleName"
                                        label={
                                            <>
                                                <span>ชื่อกลุ่มวิดีโอ</span>
                                                <span style={{ paddingLeft: 8 }}> (จำกัดตัวอักษร 200 ตัว)</span>
                                            </>
                                        }
                                        rules={[{ required: true, message: 'กรุณากรอกกลุ่มวิดีโอในคอร์ส' }]}
                                    >
                                        <Input maxLength={200} />
                                    </Form.Item>
                                </Col>

                                <Col span={24}>
                                    <Form.Item
                                        name="courseChapterDescriptionName"
                                        label={
                                            <>
                                                <span>รายละเอียดกลุ่มวิดีโอ</span>
                                                <span style={{ paddingLeft: 8 }}> (จำกัดตัวอักษร 500 ตัว)</span>
                                            </>
                                        }
                                        rules={[{ required: true, message: 'กรุณากรอกรายละเอียดกลุ่มวิดีโอในคอร์ส' }]}
                                    >
                                        <TextArea
                                            showCount
                                            maxLength={500}
                                            autoSize={{ minRows: 5, maxRows: 5 }}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </Modal>
                    {/* End modal add chapter */}

                    <Modal
                        title="ภาพปกวิดีโอ"
                        visible={modalVideoCoverImage}
                        width={526}
                        maskClosable={false}
                        onCancel={() => {
                            setModalVideoCoverImage(false);
                        }}

                        footer={false}
                    >
                        <CoverImage
                            videoUrl={PathVideo(videoCourse.videoUrl)}
                            duration={duration}
                            handleCaptureCoverImage={handleCaptureCoverImage}
                        />
                    </Modal>
                </>
                :
                <center><Spin size="large" style={{ marginTop: 200, marginBottom: 200 }} /></center>
            }
        </>
    )
}

export default ManageVideo
