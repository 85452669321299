/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */

import { useState, useEffect, useRef } from 'react'
import {
    Steps,
    Row,
    Col,
    Button,
    Modal,
    Statistic,
    Form,
    Divider,
    Image,
    Select,
    Input,
    InputNumber,
    Popconfirm,
    Upload,
    Popover
} from 'antd'
import { AiOutlineQuestionCircle } from "react-icons/ai"
import { Icon } from '@iconify/react'
import {
    AiOutlineCheck,
    AiOutlineClose,
    AiOutlineReload,
    AiOutlineExclamation
} from "react-icons/ai"
import { LoadingOutlined, UploadOutlined } from '@ant-design/icons'
import thaiIdCard from 'thai-id-card'
import Checkbox from 'antd/lib/checkbox/Checkbox'
import { BiTrashAlt } from "react-icons/bi"
import { useDimensions } from './../../../../common/mamager/ScreenManager'
import { apiStorageUrl, bucketName, imgDefaultUrl, storageUrl } from '../../../../constants'
import OTPInput from './../../../../common/components/otp'
import { getCourseOwnerFetch } from './../../../course/API'
import {
    deleteFileAuthorizedSignatoryFetch,
    deleteFileRegistrationCertificateFetch,
    getAccountVerifyBankByIdFetch,
    getAccountVerifyByIdFetch,
    getAccountVerifyIdentifyByIdFetch,
    getAccountVerifyTokenByIdFetch,
    getFileAuthorizedSignatoryByIdFetch,
    getFileRegistrationCertificateByIdFetch,
    getฺBankCategoryAllFetch,
    insertFileAuthorizedSignatoryFetch,
    insertFileRegistrationCertificateFetch,
    updateAccountVerifyEmailByIdFetch,
    updateIsOpenBankAccountVerifyByIdFetch,
    uploadAccountVerifyBankByIdFetch,
    uploadAccountVerifyIdentifyByIdFetch
} from './../../../account/API'
import { detectBankAccountName, detectFirstName, detectLastName } from './../../../../common/mamager/DetectManager'
import { expiredToken, verifyIdentityToken } from './../../../../common/mamager/IdManager'
import { Notification } from './../../../../common/components/notification'
import { useNavigate } from 'react-router-dom'

const { Step } = Steps
const { Option } = Select
const { Countdown } = Statistic

export default function VerifyIdentity(props) {
    const [form] = Form.useForm()

    const navigate = useNavigate()

    let language = "th"
    const { width } = useDimensions()

    const [accountVerifiedIdentify, setAccountVerifiedIdentify] = useState(null)
    const [accountVerifiedBank, setAccountVerifiedBank] = useState(null)
    const [loading, setLoading] = useState(false)
    const [loadingEmail, setLoadingEmail] = useState(false)
    const [loadingPersonInfo, setLoadingPersonInfo] = useState(false)
    const [loadingUpload, setLoadingUpload] = useState(false)

    const [token, setToken] = useState("")

    const [switchVerify, setSwitchVerify] = useState(true)
    const [switchImage, setSwitchImage] = useState(true)

    const [step, setStep] = useState(0) // type 2 is verify success all

    const [emailButtonVerifyClose, setEmailButtonVerifyClose] = useState(false)
    const [personInfoButtonVerifyClose, setPersonInfoButtonVerifyClose] = useState(false)

    const [readMoreEmail, setReadMoreEmail] = useState(true)
    const [readMoreIdCard, setReadMoreIdCard] = useState(true)

    const [fileIdcardFront, setFileIdcardFront] = useState({
        data: null,
        loading: false
    })

    const [fileRegistrationCertificate, setFileRegistrationCertificate] = useState([])
    const [fileAuthorizedSignatory, setFileAuthorizedSignatory] = useState([])

    const [fileBankAccount, setFileBankAccount] = useState({
        data: null,
        loading: false
    })

    const [isOpenVerifyBankAccount, setIsOpenVerifyBankAccount] = useState(false)
    const [accountVerify, setAccountVerify] = useState(null)

    const [tokenCorrect, setTokenCorrect] = useState(null)

    const [confirmNotActive, setConfirmNotActive] = useState(false)

    const [userInfo, setUserInfo] = useState(null)

    const submitFromRef = useRef(null)

    const [modal, setModal] = useState({
        isShow: false,
        title: null,
    })

    const [deadline, setDeadline] = useState() // 600000

    const [bankCategory, setBankCategory] = useState([])

    const optionImgIdcardFront = {
        name: 'file',
        action: `${apiStorageUrl}/upload/file`,
        data: {
            // uploaded/img/verify/idcard-front
            bucket: bucketName,
            folder: "image/verify/idcard-front"
        },
        headers: {
            authorization: 'authorization-text',
        },
        onChange(info) {
            if (info.file.status === 'done') {
                let result = info.file.response;
                console.log("info : ", info.file.response)
                if (result?.isSuccess) {
                    setFileIdcardFront({
                        data: result.url,
                        loading: false
                    })
                }
            } else {
                setFileIdcardFront({ loading: true })
            }

            if (info.file.status === 'done') {
                Notification("success", "ระบบ", "อัพโหลดบัตรประชาชนสำเร็จ")
            } else if (info.file.status === 'error') {
                Notification("error", "ระบบ", "ไม่สามารถอัพโหลดบัตรประชาชนได้ กรุณาตรวจสอบข้อมูล")
            }
        },
        progress: {
            strokeColor: {
                '0%': '#108ee9',
                '100%': '#87d068',
            },
            strokeWidth: 3,
            format: percent => `${parseFloat(percent.toFixed(2))}%`,
        },
    }

    const optionFileBank = {
        name: 'file',
        action: `${apiStorageUrl}/upload/file`,
        data: {
            // uploaded/img/verify/bank-account
            bucket: bucketName,
            folder: "image/verify/bank-account"
        },
        headers: {
            authorization: 'authorization-text',
        },
        onChange(info) {
            if (info.file.status === 'done') {
                let result = info.file.response;
                console.log("info : ", info.file.response)
                if (result?.isSuccess) {
                    setFileBankAccount({
                        data: result.url,
                        loading: false
                    })
                }
            } else {
                setFileBankAccount({ loading: true })
            }

            if (info.file.status === 'done') {
                Notification("success", "ระบบ", "อัพโหลดบัญชีธนาคารสำเร็จ")
            } else if (info.file.status === 'error') {
                Notification("error", "ระบบ", "ไม่สามารถอัพโหลดบัญชีธนาคารได้ กรุณาตรวจสอบข้อมูล")
            }
        },
        progress: {
            strokeColor: {
                '0%': '#108ee9',
                '100%': '#87d068',
            },
            strokeWidth: 3,
            format: percent => `${parseFloat(percent.toFixed(2))}%`,
        },
    }

    const optionFileRegCer = {
        name: 'file',
        action: `${apiStorageUrl}/upload/file`,
        data: {
            // uploaded/file/verify/registration-certificate
            bucket: bucketName,
            folder: "image/verify/registration-certificate"
        },
        headers: {
            authorization: 'authorization-text',
        },
        async onChange(info) {
            setLoadingUpload(true);

            if (info.file.status === 'done') {
                let fileData = info.file.response;
                console.log("info : ", info.file.response)
                if (fileData?.isSuccess) {
                    let fileCategory;
                    if (info.file.type === "application/pdf") { // PDF
                        fileCategory = 1
                    } else if (
                        info.file.type === "image/png" ||
                        info.file.type === "image/jpeg"
                    ) { // IMG
                        fileCategory = 2
                    }

                    // insert db
                    let obj = {
                        uid: props?.user ? props?.user.uid : null,
                        name: fileData.name,
                        path: fileData.url,
                        approvalStatusId: 9,
                        type: fileCategory
                    }
                    const result = await insertFileRegistrationCertificateFetch(obj)

                    if (result.isSuccess) {
                        // state
                        setFileRegistrationCertificate([{
                            id: result.formData.id,
                            name: fileData.name,
                            path: fileData.url,
                            approvalStatusId: 9,
                            approvalStatusName: language === "th" ? "เพิ่มเอกสาร รอการตรวจสอบ" : "Adding document, waiting for verification",
                            type: fileCategory
                        }, ...fileRegistrationCertificate])
                    }
                }
            }

            if (info.file.status === 'done') {
                setLoadingUpload(false)
                Notification("success", "ระบบ", "อัพโหลดเอกสารสำเร็จ")
            } else if (info.file.status === 'error') {
                Notification("error", "ระบบ", "ไม่สามารถอัพโหลดเอกสารได้ กรุณาตรวจสอบข้อมูล")
            }
        },
        progress: {
            strokeColor: {
                '0%': '#108ee9',
                '100%': '#87d068',
            },
            strokeWidth: 3,
            format: percent => `${parseFloat(percent.toFixed(2))}%`,
        },
    }

    const optionFileAuthSign = {
        name: 'file',
        action: `${apiStorageUrl}/upload/file`,
        data: {
            // uploaded/file/verify/authorized-signatory
            bucket: bucketName,
            folder: "image/verify/authorized-signatory"
        },
        headers: {
            authorization: 'authorization-text',
        },
        async onChange(info) {
            setLoadingUpload(true);

            if (info.file.status === 'done') {
                let fileData = info.file.response;
                console.log("info : ", info.file.response)
                if (fileData?.isSuccess) {
                    let fileCategory
                    if (info.file.type === "application/pdf") { // PDF
                        fileCategory = 1
                    } else if (
                        info.file.type === "image/png" ||
                        info.file.type === "image/jpeg"
                    ) { // IMG
                        fileCategory = 2
                    }

                    // insert db
                    let obj = {
                        uid: props?.user ? props?.user.uid : null,
                        name: fileData.name,
                        path: fileData.url,
                        approvalStatusId: 9,
                        type: fileCategory
                    }
                    const result = await insertFileAuthorizedSignatoryFetch(obj)
                    // console.log("insertFileAuthorizedSignatoryFetch : ", result)

                    if (result.isSuccess) {
                        // state
                        setFileAuthorizedSignatory([{
                            id: result.formData.id,
                            name: fileData.name,
                            path: fileData.url,
                            approvalStatusId: 9,
                            approvalStatusName: language === "th" ? "เพิ่มเอกสาร รอการตรวจสอบ" : "Adding document, waiting for verification",
                            type: fileCategory
                        }, ...fileAuthorizedSignatory])
                    }
                }
            }

            if (info.file.status === 'done') {
                setLoadingUpload(false);
                Notification("success", "ระบบ", "อัพโหลดเอกสารสำเร็จ")
            } else if (info.file.status === 'error') {
                Notification("error", "ระบบ", "ไม่สามารถอัพโหลดเอกสารได้ กรุณาตรวจสอบข้อมูล")
            }
        },
        progress: {
            strokeColor: {
                '0%': '#108ee9',
                '100%': '#87d068',
            },
            strokeWidth: 3,
            format: percent => `${parseFloat(percent.toFixed(2))}%`,
        },
    }

    const onDeleteFileRegistrationCertificate = async (id) => {
        const result = await deleteFileRegistrationCertificateFetch(id)

        if (result.isSuccess) {
            setLoadingUpload(false)
            Notification("success", "ระบบ", "ลบเอกสารสำเร็จ")
        } else {
            Notification("error", "ระบบ", "ไม่สามารถลบเอกสารได้ กรุณาตรวจสอบข้อมูล")
        }

        let tempFile = fileRegistrationCertificate.filter(file => file.id !== id)
        setFileRegistrationCertificate(tempFile)
    }

    const onDeleteFileAuthorizedSignatory = async (id) => {
        const result = await deleteFileAuthorizedSignatoryFetch(id)

        if (result.isSuccess) {
            setLoadingUpload(false)
            Notification("success", "ระบบ", "ลบเอกสารสำเร็จ")
        } else {
            Notification("error", "ระบบ", "ไม่สามารถลบเอกสารได้ กรุณาตรวจสอบข้อมูล")
        }

        let tempFile = fileAuthorizedSignatory.filter(file => file.id !== id)
        setFileAuthorizedSignatory(tempFile)
    }

    const onOutTime = async () => {
        setConfirmNotActive(true)

        // [API] update token isActive to false/0 
        await expiredToken(props?.user?.uid, token)
    }

    const setDefaultEmailVerify = () => {
        // new countdown 
        setDeadline(Date.now() + 600000) // 600000
        setConfirmNotActive(false)
        setSwitchVerify(!switchVerify)
    }

    const setDefaultImageUrl = () => {
        // set form focus
        submitFromRef.current = null

        setSwitchImage(!switchImage)

        // open modal
        setModal({
            isShow: true,
            title: "idCard",
        })
    }

    const getBaseApi = async () => {
        setLoading(true)

        let tempStep

        // User Information ===========================================================
        const resultUserInfo = await getCourseOwnerFetch(props?.user?.uid)
        setUserInfo(resultUserInfo)

        // Account Verify =============================================================
        const resultAccountVerify = await getAccountVerifyByIdFetch(props?.user?.uid)

        if (resultUserInfo?.providerId === "email/password") {
            if (resultAccountVerify.isAccountVerifiedEmail === false && (resultAccountVerify.isAccountVerifiedIdentify === false || resultAccountVerify.isAccountVerifiedBank === false)) { // email and idcard is not verified yet
                tempStep = 0
                setPersonInfoButtonVerifyClose(true)
            } else if (resultAccountVerify.isAccountVerifiedEmail === true && (resultAccountVerify.isAccountVerifiedIdentify === false || resultAccountVerify.isAccountVerifiedBank === false)) { // email verified but identify is not verified yet
                tempStep = 1
                setEmailButtonVerifyClose(true)
            } else if (resultAccountVerify.isAccountVerifiedEmail === true && (resultAccountVerify.isAccountVerifiedIdentify === true || resultAccountVerify.isAccountVerifiedBank === true)) { // email and idcard is verified
                tempStep = 2
                setEmailButtonVerifyClose(true)
                setPersonInfoButtonVerifyClose(true)
            }
        } else if (resultUserInfo?.providerId === "google.com" || resultUserInfo?.providerId === "facebook.com") {
            if (resultAccountVerify.isAccountVerifiedIdentify === true && resultAccountVerify.isAccountVerifiedBank === true) {
                setPersonInfoButtonVerifyClose(true)
            }
        }

        setIsOpenVerifyBankAccount(resultAccountVerify.isOpenVerifyBankAccount === null ? false : resultAccountVerify.isOpenVerifyBankAccount);  // Fi first time in database will be set default null
        setAccountVerify(resultAccountVerify)

        // Account Verify IdCard ======================================================
        const resultAccountVerifyIdCard = await getAccountVerifyIdentifyByIdFetch(props?.user?.uid, language)
        setAccountVerifiedIdentify(resultAccountVerifyIdCard)
        setFileIdcardFront({
            data: resultAccountVerifyIdCard ? resultAccountVerifyIdCard.fileIdCardFrontLocation : null,
            loading: false
        })

        // Account Verify File Registration Certificate ===============================
        const resultFileRegistrationCertificate = await getFileRegistrationCertificateByIdFetch(props?.user?.uid, language)
        let tempFileRegistrationCertificate = []
        for (let i = 0; i < resultFileRegistrationCertificate.length; i++) {
            tempFileRegistrationCertificate.push({
                id: resultFileRegistrationCertificate[i].fileRegistrationCertificateId,
                name: resultFileRegistrationCertificate[i].fileRegCerName,
                path: resultFileRegistrationCertificate[i].fileRegCerLocation,
                approvalStatusId: resultFileRegistrationCertificate[i].fileRegCerApprovalId,
                approvalStatusName: resultFileRegistrationCertificate[i].fileRegCerApprovalName,
                type: resultFileRegistrationCertificate[i].fileRegCerType
            })
        }
        setFileRegistrationCertificate(tempFileRegistrationCertificate)

        // Account Verify File Authorized Signatory ===================================
        const resultFileAuthorizedSignatory = await getFileAuthorizedSignatoryByIdFetch(props?.user?.uid, language)
        let tempFileAuthorizedSignatory = []
        for (let i = 0; i < resultFileAuthorizedSignatory.length; i++) {
            tempFileAuthorizedSignatory.push({
                id: resultFileAuthorizedSignatory[i].fileAuthorizedSignatoryId,
                name: resultFileAuthorizedSignatory[i].fileAuthSignName,
                path: resultFileAuthorizedSignatory[i].fileAuthSignLocation,
                approvalStatusId: resultFileAuthorizedSignatory[i].fileAuthSignApprovalId,
                approvalStatusName: resultFileAuthorizedSignatory[i].fileAuthSignApprovalName,
                type: resultFileAuthorizedSignatory[i].fileAuthSignType
            })
        }
        setFileAuthorizedSignatory(tempFileAuthorizedSignatory)

        // Account Verify Bank ========================================================
        const resultAccountVerifyBank = await getAccountVerifyBankByIdFetch(props?.user?.uid, language)
        setAccountVerifiedBank(resultAccountVerifyBank)
        setFileBankAccount({
            data: resultAccountVerifyBank ? resultAccountVerifyBank.fileBankAccountLocation : null,
            loading: false
        })

        // Bank Category ==============================================================
        let obj = { language }
        const resultBankCategoryAll = await getฺBankCategoryAllFetch(obj)
        setBankCategory(resultBankCategoryAll)

        // Detect disable button ======================================================
        if (tempStep === 1) {
            if (resultAccountVerifyIdCard.approvalStatusId === 1 && resultAccountVerifyBank.approvalStatusId === 1) { // disable button when approvalStatusId is waiting, success
                setPersonInfoButtonVerifyClose(true)
            } else {
                setPersonInfoButtonVerifyClose(false)
            }
        }
        setStep(tempStep)
        setLoading(false)
    }

    const onFinish = async (values) => {
        if (submitFromRef.current === "confirm") {
            setLoadingPersonInfo(true)

            if (values.idCardFirstName && values.idCardLastName) {
                let resultUploadVerifyIdentify = { isSuccess: true }
                let resultUploadVerifyBank = { isSuccess: true }

                // [API] update data on account_verified_idcard db
                if (accountVerifiedIdentify.approvalStatusId !== 1) { // idCard is not yet pass
                    let objUploadVerifyIdentify = null;
                    if (accountVerify.businessOperationId === 1) {
                        objUploadVerifyIdentify = {
                            uid: props?.user?.uid,
                            approvalStatusId: accountVerifiedIdentify.approvalStatusId !== 1 ? 12 : 1,                  // Waiting for inspect
                            idCardFrontApprovalId: accountVerifiedIdentify.idCardFrontApprovalId !== 1 ? 12 : 1,        // Waiting for inspect
                            idCardBackApprovalId: null,                                                                 // Waiting for inspect
                            idCardFaceApprovalId: null,                                                                 // Waiting for inspect
                            idCardInfoApprovalId: accountVerifiedIdentify.idCardInfoApprovalId !== 1 ? 12 : 1,          // Waiting for inspect
                            registrationCertificateApprovalId: null,
                            companyInfoApprovalId: null,

                            // file
                            fileIdCardFrontLocation: fileIdcardFront.data,
                            fileIdCardBackLocation: null, // fileIdcardBack.data,
                            fileIdCardFaceLocation: null, // fileIdcardFace.data,

                            // info
                            idCardNumber: values.idCardNumber,
                            idCardFirstName: values.idCardFirstName,
                            idCardLastName: values.idCardLastName,

                            companyTaxIdNumber: null,
                            companyNameTH: null,
                            companyNameENG: null,

                            isActive: 1
                        }
                    } else if (accountVerify.businessOperationId === 2) {
                        objUploadVerifyIdentify = {
                            uid: props?.user?.uid,
                            approvalStatusId: accountVerifiedIdentify.approvalStatusId !== 1 ? 12 : 1,                                          // Waiting for inspect
                            idCardFrontApprovalId: null,
                            idCardBackApprovalId: null,
                            idCardFaceApprovalId: null,
                            idCardInfoApprovalId: null,
                            registrationCertificateApprovalId: accountVerifiedIdentify.registrationCertificateApprovalId !== 1 ? 12 : 1,        // Waiting for inspect
                            companyInfoApprovalId: accountVerifiedIdentify.companyInfoApprovalId !== 1 ? 12 : 1,                                // Waiting for inspect

                            // file
                            fileIdCardFrontLocation: null,
                            fileIdCardBackLocation: null,
                            fileIdCardFaceLocation: null,

                            // info
                            idCardNumber: null,
                            idCardFirstName: null,
                            idCardLastName: null,

                            companyTaxIdNumber: values.companyTaxIdNumber,
                            companyNameTH: values.companyNameTH,
                            companyNameENG: values.companyNameENG,

                            isActive: 1
                        }
                    }
                    resultUploadVerifyIdentify = await uploadAccountVerifyIdentifyByIdFetch(objUploadVerifyIdentify)
                }

                // [API] update data on account_verified_bank db
                if (isOpenVerifyBankAccount && accountVerifiedBank.approvalStatusId !== 1) { // bank is not yet pass
                    let objAccountVerifyBank = {
                        uid: props?.user?.uid,

                        approvalStatusId: accountVerifiedBank.approvalStatusId !== 1 ? 12 : 1,                      // Waiting for inspect
                        bankFileApprovalId: accountVerifiedBank.bankFileApprovalId !== 1 ? 12 : 1,                  // Waiting for inspect
                        bankInfoApprovalId: accountVerifiedBank.bankInfoApprovalId !== 1 ? 12 : 1,                  // Waiting for inspect

                        // img
                        fileBankAccountLocation: fileBankAccount.data,

                        // info
                        bankCategoryId: values.bankName,
                        bankAccountId: values.bankAccountId,
                        bankAccountName: values.bankAccountName,

                        isActive: 1
                    }
                    resultUploadVerifyBank = await uploadAccountVerifyBankByIdFetch(objAccountVerifyBank)

                    let objUpdateIsOpenBankAccountVerify = {
                        accountVerifiedId: accountVerify.accountVerifiedId,
                        isOpenVerifyBankAccount
                    }
                    await updateIsOpenBankAccountVerifyByIdFetch(objUpdateIsOpenBankAccountVerify);
                }

                if (resultUploadVerifyIdentify.isSuccess && resultUploadVerifyBank.isSuccess) {
                    // close modal
                    setModal({
                        isShow: false,
                        title: null
                    })

                    Notification("success", "ระบบ", 'ส่งข้อมูลไปตรวจสอบสำเร็จ')
                    getBaseApi()
                    // reload Account.js
                    props?.handleReload()
                } else {
                    Notification("error", "ระบบ", 'ส่งข้อมูลไปตรวจสอบไม่สำเร็จ กรุณาลองใหม่อีกครั้ง')
                }
            } else {
                if (accountVerify.businessOperationId === 1) {
                    Notification("warning", "เเจ้งเตือน", 'ชื่อจริง กับนามสกุลห้ามเป็นค่าว่าง โปรดกรอกชื่อจริง เเละนามสกุลที่เมนู "ข้อมูลผู้ใช้" !')
                } else if (accountVerify.businessOperationId === 2) {
                    Notification("success", "เเจ้งเตือน", 'ชื่อบริษัท หรือห้างหุ้นส่วนห้ามเป็นค่าว่าง โปรดกรอกชื่อบริษัท หรือห้างหุ้นส่วนที่เมนู "ข้อมูลผู้ใช้" !')
                }
            }

            setLoadingPersonInfo(false)
        }
    }

    const detectIdCard = (rule, value) => {
        const result = thaiIdCard.verify(value)
        if (result) {
            return Promise.resolve()
        } else {
            return Promise.reject('เลขบัตรประชาชนมี Format ไม่ถูกต้อง')
        }
    }

    const detectImgCardFront = (rule, value) => {
        if (fileIdcardFront.data) {
            return Promise.resolve()
        } else {
            return Promise.reject(new Error('กรุณาอัพโหลดภาพถ่ายบัตรประชาชนด้านหน้า'))
        }
    }

    const detectImgBank = (rule, value) => {
        if (fileBankAccount.data) {
            return Promise.resolve()
        } else {
            return Promise.reject(new Error('กรุณาอัพโหลดภาพบัญชีธนาคาร'))
        }
    }

    const detectTaxIdNumber = (rules, value) => {
        if (value === "" || value === undefined) {
            return Promise.reject('กรุณากรอกเลขประจำตัวผู้เสียภาษี')
        } else {
            if (isNumber(value)) {
                return Promise.resolve()
            } else {
                return Promise.reject('เลขประจำตัวผู้เสียภาษีเป็นตัวเลขเท่านั้น')
            }
        }
    }

    const detectBankAccountId = (rules, value) => {
        if (value === "" || value === undefined) {
            return Promise.reject('กรุณากรอกบัญชีเลขที่')
        } else {
            if (isNumber(value)) {
                return Promise.resolve()
            } else {
                return Promise.reject('กรุณากรอกบัญชีเลขที่')
            }
        }
    }

    const isNumber = (str) => {
        return !isNaN(str)
    }

    useEffect(() => {
        getBaseApi()
    }, [])

    const exampleIdentityVerificationBankAccount = (typePerson) => {
        return (
            <>
                <Divider style={{ marginTop: 1, marginBottom: 1 }} />
                <Col span={24} style={{ paddingBottom: 16, paddingTop: 15 }}>
                    <label>เหตุใดต้องมีการยืนยันตัวตนด้วย บัญชีธนาคาร ?</label><br />
                    <label>1. เพื่อใช้เป็นช่องทางในการโอนเงินจากเเพลตฟอร์มไปยังผู้สอน เมื่อผู้เรียนได้ชำระเงินซื้อคอร์ส</label><br />
                    <label>2. เพื่อใช้ตรวจสอบผู้สอนที่จะลงทะเบียนเปิดคอร์สเรียนครั้งเเรกที่นี่</label><br />
                    <br />
                </Col>

                <Col span={24}>
                    <label>ตัวอย่างการยืนยันตัวตนด้วย บัญชีธนาคาร</label><br />
                    <Row style={{ paddingTop: 8, paddingBottom: 8 }}>
                        <Col xs={24} lg={12}>
                            <center>
                                <Image
                                    width={width > 576 ? 300 : "100%"}
                                    height={width > 576 ? 230 : "auto"}
                                    src="error"
                                    fallback={`./assets/images/verify/verify-bank-account.png`}
                                />
                            </center>
                        </Col>

                        <Col xs={24} lg={12}>
                            <label>1. ชื่อบัญชีจะต้องตรงกับ
                                {typePerson === "natural-person" ? "ชื่อบัตรประชาชน" : []}
                                {typePerson === "juristic-person" ? "ชื่อบริษัท" : []}
                            </label><br />
                            <label>2. ภาพถ่ายต้องไม่มีอะไรมาปิดหรือบดบังบัญชีธนาคาร</label><br />
                            <label>3. ภาพถ่ายต้องไม่มีการปรับแต่งใด ๆ</label><br />
                            <label>4. ภาพถ่ายต้องไม่เบลอ เห็นรายละเอียดชัดเจน</label><br />
                            <br />
                            <label>หากผู้ใช้งานต้องการเปลี่ยนเเปลงบัญชีธนาคารหลังจากนี้ สามารถเข้าไปเปลี่ยนเเปลงได้ที่ เมนู "บัญชีธนาคาร" ซึ่งจะปรากฏขึ้นหลังจากผ่านการยืนยันบัญชีธนาคารเเล้ว</label>
                        </Col>
                    </Row>

                    <a
                        href="#"
                        style={{ color: "#23B14D" }}
                        onClick={() => {
                            setReadMoreIdCard(false)
                        }}
                    >ซ่อนข้อมูลเพิ่มเติม</a>
                </Col>
            </>
        )
    }

    const genBtnDeleteDisable = () => {
        return (
            <div
                style={{
                    backgroundColor: '#BFBFBF',
                    padding: 4,
                    borderRadius: 20,
                    cursor: 'pointer',
                    width: 30,
                    marginTop: 14,
                    marginRight: 14,
                    float: 'right'
                }}
            >
                <center>
                    <BiTrashAlt size={16} style={{ color: 'white', marginTop: -2 }} />
                </center>
            </div>
        )
    }

    const genVerifyEmail = () => {
        return (
            <div style={{ border: "1px solid #DEDEDE", padding: 20, borderRadius: 15 }}>
                <Row>
                    <Col xs={24} sm={16} md={16} lg={16}>
                        {emailButtonVerifyClose ?
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <AiOutlineCheck />
                                <label style={{ paddingLeft: 10 }}>ยืนยันตัวตนด้วย Email เรียบร้อย</label>
                            </div>
                            :
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <AiOutlineExclamation />
                                <label style={{ paddingLeft: 10 }}>ยังไม่ได้ยืนยันตัวตนด้วย Email</label>
                            </div>
                        }
                    </Col>

                    <Col xs={24} sm={8} md={8} lg={8}>
                        <Button
                            disabled={emailButtonVerifyClose}
                            type="primary"
                            style={{ float: "right", width: (width > 576 ? "auto" : "100%") }}
                            onClick={async () => {
                                // new countdown 
                                setDefaultEmailVerify()

                                setTokenCorrect(null)

                                const result = await getAccountVerifyByIdFetch(props?.user?.uid, language)

                                if (!result.isAccountVerifiedEmail) {
                                    await verifyIdentityToken(props?.user?.uid, userInfo?.email)
                                    setModal({ isShow: true, title: "email" })
                                } else {
                                    alert("คุณยืนยันตัวตนผ่านเเล้ว!")
                                }
                            }}
                        >
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Icon icon="ic:baseline-domain-verification" style={{ color: (emailButtonVerifyClose ? "#C9C9C9" : "white"), width: 20, height: 20 }} />
                                <label style={{ paddingLeft: 10, cursor: "pointer" }}>ยืนยันตัวตน</label>
                            </div>
                        </Button>
                    </Col>

                    <Col span={24} style={{ paddingTop: (!readMoreEmail ? 6 : 0) }}>
                        {!readMoreEmail ?
                            <a
                                href="#"
                                onClick={() => {
                                    setReadMoreEmail(true)
                                }}
                            >ดูข้อมูลเพิ่มเติม</a>
                            : []}
                    </Col>
                    {readMoreEmail ?
                        <>
                            <Divider style={{ marginTop: 20, marginBottom: 20 }} />
                            <Col span={24} style={{ borderTop: "1px solid #EEEEEE" }}>
                                <label>ตัวอย่างการยืนยันอีเมล</label><br />
                                <label>1. กรุณายืนยันอีเมลตามเวลาที่กำหนด</label><br />
                                <label>2. หากไม่พบอีเมลที่ใช้ในการยืนยันตัวตนในกล่องจดหมาย กรุณาตรวจสอบอีเมลที่จดหมายขยะ หรือที่แท็บโปรโมชัน</label><br />
                                <label>3. หากไม่พบอีเมลในกล่องจดหมาย ท่านสามารถกดปุ่ม ส่งรหัสยืยันอีกครั้งได้</label><br />
                                <a
                                    href="#"
                                    onClick={() => {
                                        setReadMoreEmail(false)
                                    }}
                                    style={{ color: "#23B14D" }}
                                >ซ่อนข้อมูลเพิ่มเติม</a>
                            </Col>
                        </>
                        : []
                    }
                </Row>
            </div>
        )
    }

    const genVerifyNaturalPerson = () => {
        return (
            <div style={{ border: "1px solid #DEDEDE", padding: 20, borderRadius: 15 }}>
                <Row
                // gutter={[0,16]}
                >
                    <Col xs={24} sm={16} md={16} lg={16} style={{ paddingBottom: 16 }}>
                        <Row>
                            <Col span={24}>
                                {!accountVerifiedIdentify.approvalStatusId ?
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <AiOutlineExclamation />
                                        <label style={{ paddingLeft: 10 }}>ยังไม่ได้ยืนยันตัวตนด้วยบัตรประชาชน</label>
                                    </div>
                                    : []}

                                {accountVerifiedIdentify.approvalStatusId === 1 ?
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <AiOutlineCheck />
                                        <label style={{ paddingLeft: 10 }}>ยืนยันตัวตนด้วยบัตรประชาชนเรียบร้อย</label>
                                    </div>
                                    : []}

                                {accountVerifiedIdentify.approvalStatusId === 12 ?
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <AiOutlineReload style={{ color: "orange" }} />
                                        <label style={{ paddingLeft: 10, color: "orange" }}>ระบบกำลังตรวจสอบบัตรประชาชนของท่าน กรุณารอ 1 - 3 วันทำการ</label>
                                    </div>
                                    : []}

                                {accountVerifiedIdentify.approvalStatusId === 2 ?
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <AiOutlineClose style={{ color: "red" }} />
                                        <label style={{ paddingLeft: 10, color: "red" }}>ระบบไม่สามารถยืนยันตัวตนด้วยบัตรประชาชนที่ท่านส่งมาได้ กรุณาทำการยืนยันตัวตนใหม่อีกครั้ง</label>
                                    </div>
                                    : []}
                            </Col>

                            <Col span={24}>
                                {!accountVerifiedBank.approvalStatusId ?
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <AiOutlineExclamation />
                                        <label style={{ paddingLeft: 10 }}>ยังไม่ได้ยืนยันตัวตนด้วยบัญชีธนาคาร</label>
                                    </div>
                                    : []}

                                {accountVerifiedBank.approvalStatusId === 1 ?
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <AiOutlineCheck />
                                        <label style={{ paddingLeft: 10 }}>ยืนยันตัวตนด้วยบัญชีธนาคารเรียบร้อย</label>
                                    </div>
                                    : []}

                                {accountVerifiedBank.approvalStatusId === 12 ?
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <AiOutlineReload style={{ color: "orange" }} />
                                        <label style={{ paddingLeft: 10, color: "orange" }}>ระบบกำลังตรวจสอบบัญชีธนาคารของท่าน กรุณารอ 1 - 3 วันทำการ</label>
                                    </div>
                                    : []}

                                {accountVerifiedBank.approvalStatusId === 2 ?
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <AiOutlineClose style={{ color: "red" }} />
                                        <label style={{ paddingLeft: 10, color: "red" }}>ระบบไม่สามารถยืนยันตัวตนด้วยบัญชีธนาคารที่ท่านส่งมาได้ กรุณาทำการยืนยันตัวตนใหม่อีกครั้ง</label>
                                    </div>
                                    : []}
                            </Col>
                        </Row>
                    </Col>

                    <Col xs={24} sm={8} md={8} lg={8} style={{ paddingBottom: 15 }}>
                        <Button
                            disabled={personInfoButtonVerifyClose}
                            type="primary"
                            style={{ float: "right", width: (width > 576 ? "auto" : "100%") }}
                            onClick={() => {
                                form.setFieldsValue({
                                    bankName: accountVerifiedBank ? accountVerifiedBank.bankCategoryId : null,
                                    bankAccountId: accountVerifiedBank ? accountVerifiedBank.bankAccountId : null,
                                    bankAccountName: accountVerifiedBank ? accountVerifiedBank.bankAccountName : null,

                                    idCardNumber: accountVerifiedIdentify.idCardInfoApprovalId === null ? undefined : accountVerifiedIdentify.idCardNumber,
                                    idCardFirstName: accountVerifiedIdentify.idCardInfoApprovalId === null ? (userInfo ? userInfo.firstName : undefined) : (accountVerifiedIdentify.idCardFirstName),
                                    idCardLastName: accountVerifiedIdentify.idCardInfoApprovalId === null ? (userInfo ? userInfo.lastName : undefined) : (accountVerifiedIdentify.idCardLastName),
                                });

                                // set default
                                setDefaultImageUrl()
                            }}
                        >
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Icon icon="ic:baseline-domain-verification" style={{ color: (personInfoButtonVerifyClose ? "#C9C9C9" : "white"), width: 20, height: 20 }} />
                                <label style={{ paddingLeft: 10, cursor: "pointer" }}>ยืนยันตัวตน</label>
                            </div>
                        </Button>
                    </Col>

                    {!readMoreIdCard ?
                        <Col span={24}>

                            <a
                                href="#"
                                onClick={() => {
                                    setReadMoreIdCard(true)
                                }}
                            >ดูข้อมูลเพิ่มเติม</a>

                        </Col>
                        :
                        <Divider style={{ marginTop: 1, marginBottom: 1 }} />
                    }

                    {readMoreIdCard ?
                        <>
                            {/* Verify Email ------------------------------------------------------------------------------------------------- */}
                            <Col span={24} style={{ paddingBottom: 16, paddingTop: 15 }}>
                                <label>เหตุใดต้องมีการยืนยันตัวตนด้วย บัตรประชาชน ?</label><br />
                                <label>1. เพื่อใช้เป็นข้อมูลประกอบในการสร้างใบประกาศ (Certificate) ให้เเก่ผู้เรียน ในกรณีที่คอร์สนั้น ๆ มีใบประกาศให้ผู้เรียน หลังจากเรียนจบหลักสูตร</label><br />
                                <label>2. เพื่อใช้ตรวจสอบผู้สอนที่จะลงทะเบียนเปิดคอร์สเรียนครั้งเเรกที่นี่</label><br />
                                <br />
                            </Col>

                            <Col span={24} style={{ paddingBottom: 15 }}>
                                <label>ตัวอย่างการยืนยันตัวตนด้วย บัตรประชาชน</label><br />
                                <Row style={{ paddingTop: 8, paddingBottom: 8 }}>
                                    <Col xs={24} lg={12}>
                                        <center>
                                            <Image
                                                width={width > 576 ? 300 : "100%"}
                                                height={width > 576 ? 230 : "auto"}
                                                src="error"
                                                fallback={`./assets/images/verify/verify-idcard-front.png`}
                                            />
                                        </center>
                                    </Col>
                                    <Col xs={24} lg={12}></Col>
                                </Row>
                                <label>1. ภาพถ่ายต้องไม่มีอะไรมาปิดหรือบทบังข้อมูลบนบัตร และใบหน้าผู้ยืนยันตัวตน</label><br />
                                <label>2. ภาพถ่ายต้องไม่มีการปรับแต่งใด ๆ</label><br />
                                <label>3. ภาพถ่ายไม่เบลอ เห็นรายละเอียดชัดเจน</label><br />
                            </Col>
                            {/* Verify Email ------------------------------------------------------------------------------------------------- */}

                            {/* Verify Id Card & Bank----------------------------------------------------------------------------------------- */}
                            {exampleIdentityVerificationBankAccount("natural-person")}
                            {/* Verify Id Card & Bank----------------------------------------------------------------------------------------- */}
                        </>
                        : []
                    }
                </Row>
            </div >
        )
    }

    const genVerifyJuristicPerson = () => {
        return (
            <div style={{ border: "1px solid #DEDEDE", padding: 20, borderRadius: 15 }}>
                <Row
                // gutter={[0,16]}
                >
                    <Col xs={24} sm={16} md={16} lg={16} style={{ paddingBottom: 16 }}>
                        <Row>
                            <Col span={24}>
                                {!accountVerifiedIdentify.approvalStatusId ?
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <AiOutlineExclamation />
                                        <label style={{ paddingLeft: 10 }}>ยังไม่ได้ยืนยันตัวตนด้วยหนังสือรับรอง หรือใบสําคัญแสดงการจดทะเบียนห้างหุ้นส่วนบริษัท</label>
                                    </div>
                                    : []}

                                {accountVerifiedIdentify.approvalStatusId === 1 ?
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <AiOutlineCheck />
                                        <label style={{ paddingLeft: 10 }}>ยืนยันตัวตนด้วยหนังสือรับรอง หรือใบสําคัญแสดงการจดทะเบียนห้างหุ้นส่วนบริษัทเรียบร้อย</label>
                                    </div>
                                    : []}

                                {accountVerifiedIdentify.approvalStatusId === 12 ?
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <AiOutlineReload style={{ color: "orange" }} />
                                        <label style={{ paddingLeft: 10, color: "orange" }}>ระบบกำลังตรวจสอบหนังสือรับรอง หรือใบสําคัญแสดงการจดทะเบียนห้างหุ้นส่วนบริษัทของท่าน กรุณารอ 1 - 3 วันทำการ</label>
                                    </div>
                                    : []}

                                {accountVerifiedIdentify.approvalStatusId === 2 ?
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <AiOutlineClose style={{ color: "red" }} />
                                        <label style={{ paddingLeft: 10, color: "red" }}>ระบบไม่สามารถยืนยันตัวตนด้วยหนังสือรับรอง หรือใบสําคัญแสดงการจดทะเบียนห้างหุ้นส่วนบริษัทที่ท่านส่งมาได้ กรุณาทำการยืนยันตัวตนใหม่อีกครั้ง</label>
                                    </div>
                                    : []}
                            </Col>

                            <Col span={24}>
                                {!accountVerifiedBank.approvalStatusId ?
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <AiOutlineExclamation />
                                        <label style={{ paddingLeft: 10 }}>ยังไม่ได้ยืนยันตัวตนด้วยบัญชีธนาคาร</label>
                                    </div>
                                    : []}

                                {accountVerifiedBank.approvalStatusId === 1 ?
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <AiOutlineCheck />
                                        <label style={{ paddingLeft: 10 }}>ยืนยันตัวตนด้วยบัญชีธนาคารเรียบร้อย</label>
                                    </div>
                                    : []}

                                {accountVerifiedBank.approvalStatusId === 12 ?
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <AiOutlineReload style={{ color: "orange" }} />
                                        <label style={{ paddingLeft: 10, color: "orange" }}>ระบบกำลังตรวจสอบบัญชีธนาคารของท่าน กรุณารอ 1 - 3 วันทำการ</label>
                                    </div>
                                    : []}

                                {accountVerifiedBank.approvalStatusId === 2 ?
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <AiOutlineClose style={{ color: "red" }} />
                                        <label style={{ paddingLeft: 10, color: "red" }}>ระบบไม่สามารถยืนยันตัวตนด้วยบัญชีธนาคารที่ท่านส่งมาได้ กรุณาทำการยืนยันตัวตนใหม่อีกครั้ง</label>
                                    </div>
                                    : []}
                            </Col>
                        </Row>
                    </Col>

                    <Col xs={24} sm={8} md={8} lg={8} style={{ paddingBottom: 15 }}>
                        <Button
                            disabled={personInfoButtonVerifyClose}
                            type="primary"
                            style={{ float: "right", width: (width > 576 ? "auto" : "100%") }}
                            onClick={() => {
                                form.setFieldsValue({
                                    bankName: accountVerifiedBank ? accountVerifiedBank.bankCategoryId : undefined,
                                    bankAccountId: accountVerifiedBank ? accountVerifiedBank.bankAccountId : undefined,
                                    bankAccountName: accountVerifiedBank ? accountVerifiedBank.bankAccountName : undefined,

                                    companyTaxIdNumber: accountVerifiedIdentify.companyInfoApprovalId === null ? undefined : accountVerifiedIdentify.companyTaxIdNumber,
                                    companyNameTH: accountVerifiedIdentify.companyInfoApprovalId === null ? (userInfo ? userInfo.companyNameTH : undefined) : accountVerifiedIdentify.companyNameTH,
                                    companyNameENG: accountVerifiedIdentify.companyInfoApprovalId === null ? (userInfo ? userInfo.companyNameENG : undefined) : accountVerifiedIdentify.companyNameENG
                                })

                                // set default
                                setDefaultImageUrl()
                            }}
                        >
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Icon icon="ic:baseline-domain-verification" style={{ color: (personInfoButtonVerifyClose ? "#C9C9C9" : "white"), width: 20, height: 20 }} />
                                <label style={{ paddingLeft: 10, cursor: "pointer" }}>ยืนยันตัวตน</label>
                            </div>
                        </Button>
                    </Col>

                    {!readMoreIdCard ?
                        <Col span={24}>
                            <a
                                href="#"
                                onClick={() => {
                                    setReadMoreIdCard(true);
                                }}
                            >ดูข้อมูลเพิ่มเติม</a>
                        </Col>
                        :
                        <Divider style={{ marginTop: 1, marginBottom: 1 }} />
                    }

                    {readMoreIdCard ?
                        <>
                            {/* Verify Email ------------------------------------------------------------------------------------------------- */}
                            <Col span={24} style={{ paddingBottom: 16, paddingTop: 15 }}>
                                <label>เหตุใดต้องมีการยืนยันตัวตนด้วยหนังสือรับรอง หรือใบสําคัญแสดงการจดทะเบียนห้างหุ้นส่วนบริษัท ?</label><br />
                                <label>เพื่อใช้ตรวจสอบการมีอยู่จริงของห้างหุ้นส่วนบริษัท</label><br />
                                <br />
                            </Col>

                            <Col span={24} style={{ paddingBottom: 15 }}>
                                <label>ตัวอย่างการยืนยันตัวตนด้วยหนังสือรับรอง หรือใบสําคัญแสดงการจดทะเบียนห้างหุ้นส่วนบริษัท</label><br />
                                <Row style={{ paddingTop: 8, paddingBottom: 8 }}>
                                    <Col xs={24} lg={12}>
                                        <center>
                                            <Image
                                                width={width > 576 ? 300 : "100%"}
                                                height={width > 576 ? 230 : "auto"}
                                                src="error"
                                                fallback={`./assets/images/verify/verify-partnership-registration-certificate.png`}
                                            />
                                        </center>
                                    </Col>
                                    <Col xs={24} lg={12}></Col>
                                </Row>
                                <label>1. สำเนาเอกสารให้กรรมการผู้มีอำนาจของบริษัทลงลายมือชื่อ เเละประทับตราบริษัททุกเเผ่น</label><br />
                                <label>2. เอกสารเห็นรายละเอียดชัดเจน ไม่เบลอ</label><br />
                                <label>3. เอกสารหากเป็น PDF ต้องไม่มีรหัสผ่าน</label><br />
                            </Col>
                            {/* Verify Email ------------------------------------------------------------------------------------------------- */}

                            {/* Verify Id Card & Bank----------------------------------------------------------------------------------------- */}
                            {exampleIdentityVerificationBankAccount("juristic-person")}
                            {/* Verify Id Card & Bank----------------------------------------------------------------------------------------- */}
                        </>
                        : []
                    }
                </Row>
            </div>
        )
    }

    return (
        <>
            {!loading && accountVerifiedIdentify && accountVerifiedBank ?
                <>
                    {userInfo?.providerId === "email/password" ?
                        <Steps direction="vertical" current={step}>
                            <Step
                                title={"ยืนยันตัวตนด้วย Email"}
                                description={genVerifyEmail()}
                            />

                            {accountVerify.businessOperationId === 1 ?
                                <Step
                                    title={"ยืนยันตัวตนด้วย ข้อมูลส่วนตัว"}
                                    description={genVerifyNaturalPerson()}
                                />
                                : []
                            }

                            {accountVerify.businessOperationId === 2 ?
                                <Step
                                    title={"ยืนยันตัวตนด้วยหนังสือรับรอง หรือใบสําคัญแสดงการจดทะเบียนห้างหุ้นส่วนบริษัท"}
                                    description={genVerifyJuristicPerson()}
                                />
                                : []
                            }
                        </Steps>
                        :
                        <>
                            {accountVerify.businessOperationId === 1 ?
                                <Row gutter={[0, 8]}>
                                    <Col span={24}>
                                        <label>
                                            ยืนยันตัวตนด้วย ข้อมูลส่วนตัว
                                        </label>
                                    </Col>
                                    <Col span={24}>
                                        {genVerifyNaturalPerson()}
                                    </Col>
                                </Row>
                                : []
                            }
                            {accountVerify.businessOperationId === 2 ?
                                <Row>
                                    <Col span={24}>
                                        <label style={{ paddingBottom: 8 }}>
                                            ยืนยันตัวตนด้วยหนังสือรับรอง หรือใบสําคัญแสดงการจดทะเบียนห้างหุ้นส่วนบริษัท
                                        </label>
                                    </Col>
                                    <Col span={24}>
                                        {genVerifyJuristicPerson()}
                                    </Col>
                                </Row>
                                : []
                            }
                        </>
                    }


                    <Modal
                        maskClosable={false}
                        title={
                            <strong>
                                <label
                                    className="topic-color-bold">{
                                        modal.title === "email" ?
                                            "ยืนยันตัวตนด้วย Email"
                                            :
                                            (accountVerifiedIdentify ?
                                                (accountVerifiedIdentify.businessOperationId === 1 ?
                                                    "ยืนยันตัวตนด้วย ข้อมูลส่วนตัว"
                                                    :
                                                    "ยืนยันตัวตนด้วย หนังสือรับรองหรือใบสําคัญแสดงการจดทะเบียนห้างหุ้นส่วนบริษัท"
                                                )
                                                :
                                                ""
                                            )
                                    }
                                </label>
                            </strong>
                        }
                        visible={modal.isShow}
                        width={modal.title === "email" ? 418 : 800}
                        onCancel={() => {

                            // close modal
                            setModal({
                                isShow: false,
                                title: modal.title
                            })
                        }}
                        footer={
                            <>
                                {modal.title === "email" ?
                                    <>
                                        <a
                                            href="#"
                                            onClick={async () => {
                                                // new countdown 
                                                setDefaultEmailVerify()

                                                setConfirmNotActive(false)

                                                await verifyIdentityToken(props?.user?.uid, userInfo?.email)
                                            }}
                                            style={{ float: 'left', marginTop: 5 }}
                                        >
                                            ส่งอีเมลอีกครั้ง
                                        </a>

                                        <Button
                                            type="primary"
                                            loading={loadingEmail}
                                            disabled={confirmNotActive}
                                            onClick={async () => {
                                                if (token.length !== 6) {
                                                    Notification("warning", "ระบบ", 'กรุณากรอกรหัสให้ครบ!')
                                                } else {
                                                    setLoadingEmail(true)

                                                    let request = {
                                                        uid: props?.user?.uid,
                                                        token: token
                                                    }
                                                    const resultGetToken = await getAccountVerifyTokenByIdFetch(request)
                                                    if (resultGetToken === "correct") {
                                                        // [API] update token old isActive to false/0 
                                                        await expiredToken(props?.user?.uid, token)

                                                        // [API] update db account_verified at isAccountVerifiedEmail is true
                                                        let request = {
                                                            uid: props?.user?.uid,
                                                            isAccountVerifiedEmail: 1 // true
                                                        }
                                                        const result = await updateAccountVerifyEmailByIdFetch(request)
                                                        if (result.isSuccess) {
                                                            setTokenCorrect(true)

                                                            Notification("success", "ระบบ", 'ยืนยันตัวตนด้วย Email สำเร็จ!')

                                                            // reload Account.js
                                                            // props?.handleRelode()
                                                            getBaseApi()

                                                            // close modal
                                                            setModal({
                                                                isShow: false,
                                                                title: null,
                                                            })
                                                        }
                                                    } else {
                                                        setTokenCorrect(false)
                                                    }

                                                    setLoadingEmail(false)
                                                }
                                            }}
                                        >
                                            {!loadingEmail ? "ยืนยันรหัส" : []}
                                        </Button>
                                    </>
                                    :
                                    <Popconfirm
                                        title="คุณยืนยันส่งตรวจสอบหรือไม่ ?"
                                        okText={<span style={{ width: 50 }}>ใช่</span>}
                                        onConfirm={() => {
                                            form.submit();
                                            submitFromRef.current = "confirm";
                                        }}
                                        cancelText={<span style={{ width: 50 }}>ไม่ใช่</span>}
                                    >
                                        <Button
                                            type="primary"
                                            loading={loadingPersonInfo}
                                            disabled={
                                                (accountVerifiedIdentify.approvalStatusId === 1 || accountVerifiedIdentify.approvalStatusId === 12) &&
                                                    (accountVerify.isOpenVerifyBankAccount !== null || accountVerifiedIdentify.approvalStatusId !== 12 ? (accountVerifiedBank.approvalStatusId === 1 || accountVerifiedBank.approvalStatusId === 12) : true) ? true : false
                                            }
                                        >
                                            {!loadingPersonInfo ?
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <Icon icon="bi:send" style={{ color: (accountVerifiedIdentify.approvalStatusId === 1 || accountVerifiedIdentify.approvalStatusId === 12) && (accountVerify.isOpenVerifyBankAccount !== null || accountVerifiedIdentify.approvalStatusId !== 12 ? (accountVerifiedBank.approvalStatusId === 1 || accountVerifiedBank.approvalStatusId === 12) : "#C9C9C9") ? "#C9C9C9" : "white" }} />
                                                    <label style={{ paddingLeft: 10, cursor: "pointer" }}>ส่งตรวจสอบ</label>
                                                </div>
                                                : []
                                            }
                                        </Button>
                                    </Popconfirm>
                                }
                            </>
                        }
                    >
                        {modal.title === "email" ?
                            <Row>
                                <Col span={24} style={{ textAlign: 'center' }}>
                                    <label >กรุณาตรวจสอบรหัสที่อีเมลของท่าน</label><br />
                                    <label>ที่ {userInfo.email}</label>
                                </Col>
                                <Col span={24} >
                                    <center>
                                        <OTPInput
                                            reset={switchVerify}
                                            autoFocus
                                            isNumberInput
                                            length={6}
                                            className="otpContainer"
                                            inputClassName="otpInput"
                                            onChangeOTP={(otp) => {
                                                setToken(otp);
                                            }}
                                            onPaste={(otp) => setToken(otp)}
                                        />
                                    </center>
                                </Col>
                                <Col span={24} style={{ textAlign: 'center', color: 'red' }}>
                                    {tokenCorrect === false ? <><label >รหัสไม่ถูกต้อง!</label><br /></> : []}
                                </Col>
                                <Col span={24} style={{ textAlign: "center", fontSize: 20 }}>
                                    <Countdown value={deadline} onFinish={onOutTime} />
                                </Col>
                            </Row>
                            :
                            <Form
                                layout="vertical"
                                form={form}
                                onFinish={onFinish}
                            >
                                {accountVerify.businessOperationId === 1 ?
                                    <div style={{ border: "2px solid #eeeeee", borderRadius: 20 }}>
                                        <Row>
                                            <Col span={24} style={{ backgroundColor: "#eeeeee", borderRadius: "15px 15px 0px 0px" }}>
                                                <label style={{ marginTop: 8, paddingLeft: 20, paddingRight: 10 }}>1. บัตรประชาชน</label>

                                                {accountVerifiedIdentify.approvalStatusId === 1 ?
                                                    <label style={{ color: "green" }}>(ผ่านการตรวจสอบ)</label> : []}

                                                {accountVerifiedIdentify.approvalStatusId === 12 ?
                                                    <label style={{ color: "orange" }}>(รอการตรวจสอบ)</label> : []}

                                                {accountVerifiedIdentify.approvalStatusId === 2 ?
                                                    <label style={{ color: "red" }}>(ไม่ผ่านการตรวจสอบ กรุณาตรวจสอบข้อมูลเเละส่งใหม่อีกครั้ง)</label> : []}
                                            </Col>

                                            {/* ภาพถ่ายบัตรประชาด้านหน้า */}
                                            <Col xs={24} lg={12} style={{ paddingTop: 12, paddingLeft: 20, paddingRight: 20 }}>
                                                <Form.Item
                                                    label={
                                                        <>
                                                            <label style={{ paddingRight: 10 }}>ภาพถ่ายบัตรประชาด้านหน้า</label>
                                                            {accountVerifiedIdentify.idCardFrontApprovalId === 1 ? <label style={{ color: "green" }}>ผ่านการตรวจสอบ</label> : []}
                                                            {accountVerifiedIdentify.idCardFrontApprovalId === 12 ? <label style={{ color: "orange" }}>รอการตรวจสอบ</label> : []}
                                                            {accountVerifiedIdentify.idCardFrontApprovalId === 2 ? <label style={{ color: "red" }}>ไม่ผ่านการตรวจสอบ</label> : []}
                                                        </>
                                                    }
                                                    name="fileIdcardFront"
                                                    rules={[{ required: true, validator: detectImgCardFront }]}
                                                >
                                                    {fileIdcardFront.data ?
                                                        <Image
                                                            style={{ width: '100%', height: 200, border: "1px solid #eeeeee" }}
                                                            alt="avatar"
                                                            src={`${fileIdcardFront.data}`}
                                                            preview={{
                                                                src: `${fileIdcardFront.data}`,
                                                            }}
                                                        />
                                                        :
                                                        <Image
                                                            style={{ width: '100%', height: 200, border: "1px solid #eeeeee" }}
                                                            src="error"
                                                            fallback={`${imgDefaultUrl}`}
                                                        />
                                                    }
                                                    <Upload
                                                        {...optionImgIdcardFront}
                                                        listType="picture"
                                                        accept='image/jpeg, image/png, image/jfif'
                                                        maxCount={1}
                                                        showUploadList={false}
                                                    >
                                                        <Button
                                                            type="primary"
                                                            disabled={(accountVerifiedIdentify.idCardFrontApprovalId === 1 || accountVerifiedIdentify.idCardFrontApprovalId === 12) ? true : false}
                                                            style={{ width: "100%" }}
                                                            icon={fileIdcardFront.loading ? <LoadingOutlined /> : <UploadOutlined />}
                                                        >อัพโหลด</Button>
                                                    </Upload>
                                                </Form.Item>
                                            </Col>

                                            {/* ภาพถ่ายบัตรประชาด้านหลัง */}
                                            {/* <Col xs={24} lg={12} style={{ paddingTop: 12, paddingLeft: 20, paddingRight: 20 }}>
                                                <Form.Item
                                                    label={
                                                        <>
                                                            <label style={{ paddingRight: 10 }}>ภาพถ่ายบัตรประชาด้านหลัง</label>
                                                            {accountVerifiedIdentify.idCardBackApprovalId === 1 ? <label style={{ color: "green" }}>ผ่านการตรวจสอบ</label> : []}
                                                            {accountVerifiedIdentify.idCardBackApprovalId === 12 ? <label style={{ color: "orange" }}>รอการตรวจสอบ</label> : []}
                                                            {accountVerifiedIdentify.idCardBackApprovalId === 2 ? <label style={{ color: "red" }}>ไม่ผ่านการตรวจสอบ</label> : []}
                                                        </>
                                                    }
                                                    name="fileIdcardBack"
                                                    rules={[{ required: true, validator: detectImgCardBack }]}
                                                >
                                                    {fileIdcardBack.data ?
                                                        <Image
                                                            style={{ width: '100%', height: 200, border: "1px solid #eeeeee" }}
                                                            alt="avatar"
                                                            src={`${fileIdcardBack.data}`}
                                                            preview={{
                                                                src: `${fileIdcardBack.data}`,
                                                            }}
                                                        />
                                                        :
                                                        <Image
                                                            style={{ width: '100%', height: 200, border: "1px solid #eeeeee" }}
                                                            src="error"
                                                            fallback={`${imgDefaultUrl}`}
                                                        />
                                                    }
                                                    <Upload
                                                        {...optionFileIdcardBack}
                                                        listType="picture"
                                                        accept='image/jpeg, image/png, image/jfif'
                                                        maxCount={1}
                                                        showUploadList={false}
                                                    >
                                                        <Button
                                                            type="primary"
                                                            disabled={(accountVerifiedIdentify.idCardBackApprovalId === 1 || accountVerifiedIdentify.idCardBackApprovalId === 12) ? true : false}
                                                            style={{ width: "100%" }}
                                                            icon={fileIdcardBack.loading ? <LoadingOutlined /> : <UploadOutlined />}
                                                        >อัพโหลด</Button>
                                                    </Upload>
                                                </Form.Item>
                                            </Col> */}

                                            {/* ข้อมูลบัตรประชาชน */}
                                            <Col xs={24} lg={12} style={{ paddingTop: 12, paddingLeft: 20, paddingRight: 20 }}>
                                                <Row>
                                                    <Col span={24}>
                                                        <label style={{ paddingRight: 10 }}>ข้อมูลบัตรประชาชน</label>
                                                        {accountVerifiedIdentify.idCardInfoApprovalId === 1 ? <label style={{ color: "green" }}>ผ่านการตรวจสอบ</label> : []}
                                                        {accountVerifiedIdentify.idCardInfoApprovalId === 12 ? <label style={{ color: "orange" }}>รอการตรวจสอบ</label> : []}
                                                        {accountVerifiedIdentify.idCardInfoApprovalId === 2 ? <label style={{ color: "red" }}>ไม่ผ่านการตรวจสอบ</label> : []}
                                                    </Col>

                                                    <Col span={24}>
                                                        <Form.Item
                                                            label="รหัสบัตรประชาชน"
                                                            name="idCardNumber"
                                                            rules={[{ required: true, validator: detectIdCard }]}
                                                        >
                                                            <Input
                                                                style={{ width: '100%' }}
                                                                maxLength={13}
                                                                disabled={(accountVerifiedIdentify.idCardInfoApprovalId === 1 || accountVerifiedIdentify.idCardInfoApprovalId === 12) ? true : false}
                                                                onBlur={(e) => form.setFieldsValue({ idCardNumber: e.target.value })}
                                                            />
                                                        </Form.Item>
                                                    </Col>

                                                    <Col span={24}>
                                                        <Form.Item
                                                            label={
                                                                <>
                                                                    <label style={{ paddingRight: 5, paddingBottom: 0, marginBottom: 0 }}>ชื่อจริง</label>
                                                                    <Popover content={<div style={{ width: 250 }}>เมื่อยืนยันตัวตนสำเร็จเเล้ว ทาง Toflyn จะใช้ชื่อจริงนี้ ในการทำธุรกรรมกับคุณเป็นหลัก</div>}>
                                                                        <AiOutlineQuestionCircle style={{ marginLeft: 5, cursor: 'pointer' }} />
                                                                    </Popover>
                                                                    {/* <label style={{ paddingLeft: 10, paddingBottom: 0, marginBottom: 0 }}>จัดการชื่อจริงได้ที่เมนู "ข้อมูลผู้ใช้"</label> */}
                                                                </>
                                                            }
                                                            name="idCardFirstName"
                                                            rules={[{ required: true, validator: detectFirstName }]}
                                                        >
                                                            <Input
                                                                disabled={true}
                                                                onBlur={(e) => form.setFieldsValue({ idCardFirstName: e.target.value })}
                                                                style={{ width: "100%" }}
                                                            />
                                                        </Form.Item>
                                                    </Col>

                                                    <Col span={24}>
                                                        <Form.Item
                                                            label={
                                                                <>
                                                                    <label style={{ paddingRight: 5, paddingBottom: 0, marginBottom: 0 }}>นามสกุล</label>
                                                                    <Popover content={<div style={{ width: 250 }}>เมื่อยืนยันตัวตนสำเร็จเเล้ว ทาง Toflyn จะใช้นามสกุลนี้ ในการทำธุรกรรมกับคุณเป็นหลัก</div>}>
                                                                        <AiOutlineQuestionCircle style={{ marginLeft: 5, cursor: 'pointer' }} />
                                                                    </Popover>
                                                                    {/* <label style={{ paddingLeft: 10, paddingBottom: 0, marginBottom: 0 }}>จัดการนามสกุลได้ที่เมนู "ข้อมูลผู้ใช้"</label> */}
                                                                </>
                                                            }
                                                            name="idCardLastName"
                                                            rules={[{ required: true, validator: detectLastName }]}
                                                        >
                                                            <Input
                                                                disabled={true}
                                                                onBlur={(e) => form.setFieldsValue({ idCardLastName: e.target.value })}
                                                                style={{ width: "100%" }}
                                                            />
                                                        </Form.Item>
                                                    </Col>

                                                    <Col span={24} style={{ paddingBottom: 12 }}>
                                                        <span>จัดการชื่อจริง-นามสกุล </span>
                                                        <span
                                                            style={{ textDecoration: 'underline', cursor: 'pointer' }}
                                                            onClick={() => {
                                                                // navigate('/account?tab=userInfo')
                                                                window.location = '/account?tab=userInfo'
                                                            }}
                                                        >
                                                            คลิกที่นี่
                                                        </span>
                                                    </Col>
                                                </Row>
                                            </Col>

                                            {/* ภาพถ่ายบัตรประชาชนคู่กับใบหน้า */}
                                            {/* <Col xs={24} lg={12} style={{ paddingLeft: 20, paddingRight: 20 }}>
                                                <Form.Item
                                                    label={
                                                        <>
                                                            <label style={{ paddingRight: 10 }}>ภาพถ่ายบัตรประชาชนคู่กับใบหน้า</label>
                                                            {accountVerifiedIdentify.idCardFaceApprovalId === 1 ? <label style={{ color: "green" }}>ผ่านการตรวจสอบ</label> : []}
                                                            {accountVerifiedIdentify.idCardFaceApprovalId === 12 ? <label style={{ color: "orange" }}>รอการตรวจสอบ</label> : []}
                                                            {accountVerifiedIdentify.idCardFaceApprovalId === 2 ? <label style={{ color: "red" }}>ไม่ผ่านการตรวจสอบ</label> : []}
                                                        </>
                                                    }
                                                    name="imgIdcardFace"
                                                    rules={[{ required: true, validator: detectImgCardFace }]}
                                                >
                                                    {fileIdcardFace.data ?
                                                        <Image
                                                            style={{ width: '100%', height: 200, border: "1px solid #eeeeee" }}
                                                            alt="avatar"
                                                            src={`${fileIdcardFace.data}`}
                                                            preview={{
                                                                src: `${fileIdcardFace.data}`,
                                                            }}
                                                        />
                                                        :
                                                        <Image
                                                            style={{ width: '100%', height: 200, border: "1px solid #eeeeee" }}
                                                            src="error"
                                                            fallback={`${imgDefaultUrl}`}
                                                        />
                                                    }
                                                    <Upload
                                                        {...optionFileIdcardFace}
                                                        listType="picture"
                                                        accept='image/jpeg, image/png, image/jfif'
                                                        maxCount={1}
                                                        showUploadList={false}
                                                    >
                                                        <Button
                                                            type="primary"
                                                            disabled={(accountVerifiedIdentify.idCardFaceApprovalId === 1 || accountVerifiedIdentify.idCardFaceApprovalId === 12) ? true : false}
                                                            style={{ width: "100%" }}
                                                            icon={fileIdcardFace.loading ? <LoadingOutlined /> : <UploadOutlined />}
                                                        >อัพโหลด</Button>
                                                    </Upload>
                                                </Form.Item>
                                            </Col> */}
                                        </Row>
                                    </div>
                                    : []
                                }

                                {accountVerify.businessOperationId === 2 ?
                                    <div style={{ border: "2px solid #eeeeee", borderRadius: 20 }}>
                                        <Row>
                                            <Col span={24} style={{ backgroundColor: "#eeeeee", borderRadius: "15px 15px 0px 0px" }}>
                                                <label style={{ marginTop: 8, paddingLeft: 20, paddingRight: 10 }}>1. ข้อมูลบริษัท</label>

                                                {accountVerifiedIdentify.approvalStatusId === 1 ?
                                                    <label style={{ color: "green" }}>ผ่านการตรวจสอบ</label>
                                                    : []
                                                }

                                                {accountVerifiedIdentify.approvalStatusId === 12 ?
                                                    <label style={{ color: "orange" }}>รอการตรวจสอบ</label>
                                                    : []
                                                }

                                                {accountVerifiedIdentify.approvalStatusId === 2 ?
                                                    <label style={{ color: "red" }}>ไม่ผ่านการตรวจสอบ</label>
                                                    : []
                                                }
                                            </Col>

                                            <Divider style={{ marginTop: 1, marginBottom: 1 }} />

                                            <Col span={24} style={{ paddingTop: 12, paddingLeft: 12, paddingRight: 12 }}>
                                                <label>
                                                    หนังสือรับรอง หรือใบสําคัญแสดงการจดทะเบียนห้างหุ้นส่วนบริษัท
                                                    {accountVerifiedIdentify.registrationCertificateApprovalId === 1 ? <label style={{ paddingLeft: 10, color: "green" }}>ผ่านการตรวจสอบ</label> : []}
                                                    {accountVerifiedIdentify.registrationCertificateApprovalId === 12 ? <label style={{ paddingLeft: 10, color: "orange" }}>รอการตรวจสอบ</label> : []}
                                                    {accountVerifiedIdentify.registrationCertificateApprovalId === 2 ? <label style={{ paddingLeft: 10, color: "red" }}>ไม่ผ่านการตรวจสอบ</label> : []}
                                                </label>

                                                <div style={{ float: "right" }}>
                                                    <Upload
                                                        {...optionFileRegCer}
                                                        listType="picture"
                                                        maxCount={1}
                                                        showUploadList={false}
                                                        // mpaddultiple
                                                        accept='application/pdf, image/jpeg, image/png'
                                                    >
                                                        <Button
                                                            type="primary"
                                                            loading={loadingUpload}
                                                            disabled={accountVerifiedIdentify.approvalStatusId === 12 ? true : (accountVerifiedIdentify.registrationCertificateApprovalId === 1 ? true : false)}
                                                        >
                                                            {!loadingUpload ?
                                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                    <Icon icon="bx:upload" style={{ cursor: "pointer", color: (accountVerifiedIdentify.approvalStatusId ? "#C3C3C3" : "white"), width: 20, height: 20 }} />
                                                                    <label style={{ paddingLeft: 10, cursor: "pointer" }}>อัพโหลดเอกสาร</label>
                                                                </div>
                                                                : []
                                                            }
                                                        </Button>
                                                    </Upload>
                                                </div>
                                            </Col>

                                            <Col
                                                span={24}
                                                style={{
                                                    padding: 12,
                                                    width: "100%"
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        height: 300,
                                                        width: "100%",
                                                        padding: 12,
                                                        background: "#EEEEEE",
                                                        borderRadius: 10,
                                                        overflow: 'auto',
                                                        overflowX: 'hidden'
                                                    }}
                                                >
                                                    {fileRegistrationCertificate.map((file, index) => {
                                                        return (
                                                            <div
                                                                style={{
                                                                    height: 60,
                                                                    background: "white",
                                                                    marginBottom: 12,
                                                                    borderRadius: 10,
                                                                }}
                                                            >
                                                                <Row>
                                                                    <Col span={4}>
                                                                        {file.type === 1 ?
                                                                            <>
                                                                                <Image
                                                                                    width={"auto"}
                                                                                    height={60}
                                                                                    src="error"
                                                                                    style={{
                                                                                        padding: 5
                                                                                    }}
                                                                                    fallback={"./assets/images/default/pdf.png"}
                                                                                />
                                                                            </>
                                                                            : []}

                                                                        {file.type === 2 ?
                                                                            <Image
                                                                                width={"auto"}
                                                                                height={60}
                                                                                src="error"
                                                                                style={{
                                                                                    padding: 5
                                                                                }}
                                                                                fallback={`${file.path}`}
                                                                            />
                                                                            : []
                                                                        }
                                                                    </Col>

                                                                    <Col span={14}>
                                                                        <label style={{ marginTop: 5 }}>{file.name}</label>
                                                                        <br />
                                                                        <label
                                                                            style={{
                                                                                marginTop: 0,
                                                                                color: file.approvalStatusId === 9 ? "orange" :
                                                                                    (file.approvalStatusId === 2 ? "red" :
                                                                                        (file.approvalStatusId === 1 ? "green" : "black")),
                                                                            }}
                                                                        >{file.approvalStatusName}</label>
                                                                    </Col>

                                                                    <Col span={6}>
                                                                        {accountVerifiedIdentify.approvalStatusId === 12 ?
                                                                            genBtnDeleteDisable()
                                                                            :
                                                                            <>
                                                                                {file.approvalStatusId !== 1 ?
                                                                                    <Popconfirm
                                                                                        title="คุณต้องการลบเอกสารหรือไม่ ?"
                                                                                        okText={<span style={{ width: 50 }}>ใช่</span>}
                                                                                        onConfirm={() => {
                                                                                            onDeleteFileRegistrationCertificate(file.id);
                                                                                        }}
                                                                                        cancelText={<span style={{ width: 50 }}>ไม่ใช่</span>}
                                                                                    >

                                                                                        <div
                                                                                            style={{
                                                                                                backgroundColor: 'red',
                                                                                                padding: 4,
                                                                                                borderRadius: 20,
                                                                                                cursor: 'pointer',
                                                                                                width: 30,
                                                                                                marginTop: 14,
                                                                                                marginRight: 12,
                                                                                                float: 'right'
                                                                                            }}>
                                                                                            <center>
                                                                                                <BiTrashAlt size={16} style={{ color: 'white', cursor: 'pointer', marginTop: -2 }} />
                                                                                            </center>
                                                                                        </div>
                                                                                    </Popconfirm>
                                                                                    :
                                                                                    genBtnDeleteDisable()
                                                                                }
                                                                            </>
                                                                        }
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </Col>

                                            <Divider style={{ marginTop: 1, marginBottom: 1 }} />

                                            <Col span={24} style={{ paddingTop: 12, paddingLeft: 12, paddingRight: 12 }}>
                                                <label>
                                                    บัตรประชาชนของผู้มีอำนาจลงนาม เช่น กรรมการบริษัท เจ้าของบริษัท ฯลฯ
                                                    {accountVerifiedIdentify.authorizedSignatoryApprovalId === 1 ? <label style={{ paddingLeft: 10, color: "green" }}>ผ่านการตรวจสอบ</label> : []}
                                                    {accountVerifiedIdentify.authorizedSignatoryApprovalId === 12 ? <label style={{ paddingLeft: 10, color: "orange" }}>รอการตรวจสอบ</label> : []}
                                                    {accountVerifiedIdentify.authorizedSignatoryApprovalId === 2 ? <label style={{ paddingLeft: 10, color: "red" }}>ไม่ผ่านการตรวจสอบ</label> : []}
                                                </label>

                                                <div style={{ float: "right" }}>
                                                    <Upload
                                                        {...optionFileAuthSign}
                                                        listType="picture"
                                                        maxCount={1}
                                                        showUploadList={false}
                                                        // mpaddultiple
                                                        accept='application/pdf, image/jpeg, image/png'
                                                    >
                                                        <Button
                                                            type="primary"
                                                            loading={loadingUpload}
                                                            disabled={accountVerifiedIdentify.approvalStatusId === 12 ? true : (accountVerifiedIdentify.registrationCertificateApprovalId === 1 ? true : false)}
                                                        >
                                                            {!loadingUpload ?
                                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                    <Icon icon="bx:upload" style={{ cursor: "pointer", color: (accountVerifiedIdentify.approvalStatusId ? "#C3C3C3" : "white"), width: 20, height: 20 }} />
                                                                    <label style={{ paddingLeft: 10, cursor: "pointer" }}>อัพโหลดเอกสาร</label>
                                                                </div>
                                                                : []
                                                            }
                                                        </Button>
                                                    </Upload>
                                                </div>
                                            </Col>
                                            <Col span={24}>
                                                <div style={{ paddingLeft: 12, paddingRight: 12 }}><span style={{ color: "red" }}>*</span> เขียนลงนามหน้าบัตรดังนี้ </div>
                                                <div style={{ paddingLeft: 12, paddingRight: 12 }}>1. "<span style={{ fontStyle: "italic" }}>ใช้เพื่อยืนยันตัวตนการมีอยู่จริงของบริษัท</span>"</div>
                                                <div style={{ paddingLeft: 12, paddingRight: 12 }}>2. ลายเซนต์เจ้าของบัตรประชาชน พร้อมทั้งตราประทับบริษัท (ถ้ามี)</div>
                                            </Col>

                                            <Col
                                                span={24}
                                                style={{
                                                    padding: 12,
                                                    width: "100%"
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        height: 300,
                                                        width: "100%",
                                                        padding: 12,
                                                        background: "#EEEEEE",
                                                        borderRadius: 10,
                                                        overflow: 'auto',
                                                        overflowX: 'hidden'
                                                    }}
                                                >
                                                    {fileAuthorizedSignatory.map((file, index) => {
                                                        return (
                                                            <div
                                                                style={{
                                                                    height: 60,
                                                                    background: "white",
                                                                    marginBottom: 12,
                                                                    borderRadius: 10,
                                                                }}
                                                            >
                                                                <Row>
                                                                    <Col span={4}>
                                                                        {file.type === 1 ?
                                                                            <>
                                                                                <Image
                                                                                    width={"auto"}
                                                                                    height={60}
                                                                                    src="error"
                                                                                    style={{
                                                                                        padding: 5
                                                                                    }}
                                                                                    fallback={"./assets/images/default/pdf.png"}
                                                                                />
                                                                            </>
                                                                            : []}

                                                                        {file.type === 2 ?
                                                                            <Image
                                                                                width={"auto"}
                                                                                height={60}
                                                                                src="error"
                                                                                style={{
                                                                                    padding: 5
                                                                                }}
                                                                                fallback={`${file.path}`}
                                                                            />
                                                                            : []
                                                                        }
                                                                    </Col>

                                                                    <Col span={14}>
                                                                        <label style={{ marginTop: 5 }}>{file.name}</label>
                                                                        <br />
                                                                        <label
                                                                            style={{
                                                                                marginTop: 0,
                                                                                color: file.approvalStatusId === 9 ? "orange" :
                                                                                    (file.approvalStatusId === 2 ? "red" :
                                                                                        (file.approvalStatusId === 1 ? "green" : "black")),
                                                                            }}
                                                                        >{file.approvalStatusName}</label>
                                                                    </Col>

                                                                    <Col span={6}>
                                                                        {accountVerifiedIdentify.approvalStatusId === 12 ?
                                                                            genBtnDeleteDisable()
                                                                            :
                                                                            <>
                                                                                {file.approvalStatusId !== 1 ?
                                                                                    <Popconfirm
                                                                                        title="คุณต้องการลบเอกสารหรือไม่ ?"
                                                                                        okText={<span style={{ width: 50 }}>ใช่</span>}
                                                                                        onConfirm={() => {
                                                                                            onDeleteFileAuthorizedSignatory(file.id);
                                                                                        }}
                                                                                        cancelText={<span style={{ width: 50 }}>ไม่ใช่</span>}
                                                                                    >

                                                                                        <div
                                                                                            style={{
                                                                                                backgroundColor: 'red',
                                                                                                padding: 4,
                                                                                                borderRadius: 20,
                                                                                                cursor: 'pointer',
                                                                                                width: 30,
                                                                                                marginTop: 14,
                                                                                                marginRight: 12,
                                                                                                float: 'right'
                                                                                            }}>
                                                                                            <center>
                                                                                                <BiTrashAlt size={16} style={{ color: 'white', cursor: 'pointer', marginTop: -2 }} />
                                                                                            </center>
                                                                                        </div>
                                                                                    </Popconfirm>
                                                                                    :
                                                                                    genBtnDeleteDisable()
                                                                                }
                                                                            </>
                                                                        }
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </Col>

                                            <Divider style={{ marginTop: 1, marginBottom: 1 }} />

                                            <Col span={24} style={{ paddingTop: 12, paddingLeft: 12, paddingRight: 12 }}>
                                                <label>
                                                    รายละเอียดบริษัท
                                                    {accountVerifiedIdentify.companyInfoApprovalId === 1 ? <label style={{ paddingLeft: 10, color: "green" }}>ผ่านการตรวจสอบ</label> : []}
                                                    {accountVerifiedIdentify.companyInfoApprovalId === 12 ? <label style={{ paddingLeft: 10, color: "orange" }}>รอการตรวจสอบ</label> : []}
                                                    {accountVerifiedIdentify.companyInfoApprovalId === 2 ? <label style={{ paddingLeft: 10, color: "red" }}>ไม่ผ่านการตรวจสอบ</label> : []}
                                                </label>
                                            </Col>

                                            <Col span={24} style={{ paddingTop: 12, paddingLeft: 12, paddingRight: 12 }}>
                                                <Form.Item
                                                    label={
                                                        <>
                                                            <label style={{ paddingRight: 10, paddingBottom: 0, marginBottom: 0 }}>ชื่อบริษัท ภาษาไทย</label>
                                                            <Popover content={<div style={{ width: 250 }}>เมื่อยืนยันตัวตนสำเร็จเเล้ว ทาง Toflyn จะใช้ชื่อบริษัทภาษาไทยนี้ ในการทำธุรกรรมกับคุณเป็นหลัก</div>}>
                                                                <AiOutlineQuestionCircle style={{ marginLeft: 5, cursor: 'pointer' }} />
                                                            </Popover>
                                                        </>
                                                    }
                                                    name="companyNameTH"
                                                    rules={[{ required: true, message: "กรุณากรอกชื่อบริษัท ภาษาไทย" }]}
                                                >
                                                    <Input
                                                        onBlur={(e) => form.setFieldsValue({ companyNameTH: e.target.value })}
                                                        disabled={true}
                                                    />
                                                </Form.Item>
                                            </Col>

                                            <Col span={24} style={{ paddingTop: 12, paddingLeft: 12, paddingRight: 12 }}>
                                                <Form.Item
                                                    label={
                                                        <>
                                                            <label style={{ paddingRight: 10, paddingBottom: 0, marginBottom: 0 }}>ชื่อบริษัท ภาษาอังกฤษ</label>
                                                            <Popover content={<div style={{ width: 250 }}>เมื่อยืนยันตัวตนสำเร็จเเล้ว ทาง Toflyn จะใช้ชื่อบริษัทภาษาอังกฤษนี้ ในการทำธุรกรรมกับคุณเป็นหลัก</div>}>
                                                                <AiOutlineQuestionCircle style={{ marginLeft: 5, cursor: 'pointer' }} />
                                                            </Popover>
                                                        </>
                                                    }
                                                    name="companyNameENG"
                                                    rules={[{ required: true, message: "กรุณากรอกชื่อบริษัท ภาษาอังกฤษ" }]}
                                                >
                                                    <Input
                                                        onBlur={(e) => form.setFieldsValue({ companyNameENG: e.target.value })}
                                                        disabled={true}
                                                    />
                                                </Form.Item>
                                            </Col>

                                            <Col span={24} style={{ paddingTop: 12, paddingLeft: 12, paddingRight: 12 }}>
                                                <Form.Item
                                                    label={<label style={{ paddingRight: 10 }}>เลขประจำตัวผู้เสียภาษี</label>}
                                                    name="companyTaxIdNumber"
                                                    rules={[{ required: true, validator: detectTaxIdNumber }]}
                                                >
                                                    <InputNumber
                                                        style={{ width: '100%' }}
                                                        maxLength={13}
                                                        // onBlur={(e) => form.setFieldsValue({ companyTaxIdNumber: e.target.value })}
                                                        disabled={accountVerifiedIdentify.approvalStatusId === 12 ? true : (accountVerifiedIdentify.companyInfoApprovalId === 1 ? true : false)}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </div>
                                    : []
                                }

                                <div style={{ paddingTop: 10, paddingBottom: 10 }}></div>

                                <div style={{ border: "2px solid #eeeeee", borderRadius: 20 }}>
                                    <Row>
                                        <Col span={24} style={{ backgroundColor: "#eeeeee", borderRadius: "15px 15px 0px 0px" }}>
                                            <label style={{ marginTop: 8, paddingLeft: 20, paddingRight: 10 }}>2. บัญชีธนาคาร (เป็นส่วนสำหรับผู้ที่ต้องการเปิดคอร์สเรียน หากมิใช่ให้กดข้าม)</label>
                                            <div style={{ paddingRight: 10, paddingTop: 8, paddingBottom: 10, float: "right" }}>
                                                <Checkbox
                                                    checked={!isOpenVerifyBankAccount}
                                                    onClick={e => setIsOpenVerifyBankAccount(!e.target.checked)}
                                                >ข้าม</Checkbox>
                                            </div>

                                            {accountVerifiedBank.approvalStatusId === 1 ?
                                                <>
                                                    <br />
                                                    <label style={{ color: "green", paddingLeft: 20 }}>(ผ่านการตรวจสอบ)</label>
                                                </>
                                                : []}

                                            {accountVerifiedBank.approvalStatusId === 12 ?
                                                <>
                                                    <br />
                                                    <label style={{ color: "orange", paddingLeft: 20 }}>(รอการตรวจสอบ)</label>
                                                </>
                                                : []}

                                            {accountVerifiedBank.approvalStatusId === 2 ?
                                                <>
                                                    <br />
                                                    <label style={{ color: "red", paddingLeft: 20 }}>(ไม่ผ่านการตรวจสอบ กรุณาตรวจสอบข้อมูลเเละส่งใหม่อีกครั้ง)</label>
                                                </>
                                                : []}
                                        </Col>

                                        {isOpenVerifyBankAccount ?
                                            <Col xs={24} lg={12} style={{ paddingTop: 12, paddingLeft: 20, paddingRight: 20 }}>
                                                <Form.Item
                                                    label={
                                                        <>
                                                            <label style={{ paddingRight: 10 }}>ภาพถ่ายหน้าบัญชีธนาคาร</label>
                                                            {accountVerifiedBank.bankFileApprovalId === 1 ? <label style={{ color: "green" }}>ผ่านการตรวจสอบ</label> : []}
                                                            {accountVerifiedBank.bankFileApprovalId === 12 ? <label style={{ color: "orange" }}>รอการตรวจสอบ</label> : []}
                                                            {accountVerifiedBank.bankFileApprovalId === 2 ? <label style={{ color: "red" }}>ไม่ผ่านการตรวจสอบ</label> : []}
                                                        </>
                                                    }
                                                    name="fileBankAccount"
                                                    rules={[{ required: true, validator: detectImgBank }]}
                                                >
                                                    {fileBankAccount.data ?
                                                        <Image
                                                            style={{ width: '100%', height: 200, border: "1px solid #eeeeee" }}
                                                            alt="avatar"
                                                            src={`${fileBankAccount.data}`}
                                                            preview={{
                                                                src: `${fileBankAccount.data}`,
                                                            }}
                                                        />
                                                        :
                                                        <Image
                                                            style={{ width: '100%', height: 200, border: "1px solid #eeeeee" }}
                                                            src="error"
                                                            fallback={`${imgDefaultUrl}`}
                                                        />
                                                    }
                                                    <Upload
                                                        {...optionFileBank}
                                                        listType="picture"
                                                        accept='image/jpeg, image/png, image/jfif'
                                                        maxCount={1}
                                                        showUploadList={false}
                                                    >
                                                        <Button
                                                            type="primary"
                                                            disabled={(accountVerifiedBank.bankFileApprovalId === 1 ? true : ((accountVerify.isOpenVerifyBankAccount !== null || accountVerifiedIdentify.approvalStatusId !== 12 ? (accountVerifiedBank.approvalStatusId === 1 || accountVerifiedBank.approvalStatusId === 12) : true) ? true : false))}
                                                            style={{ width: "100%" }}
                                                            icon={fileBankAccount.loading ? <LoadingOutlined /> : <UploadOutlined />}
                                                        >อัพโหลด</Button>
                                                    </Upload>
                                                </Form.Item>
                                            </Col>
                                            : []
                                        }

                                        {isOpenVerifyBankAccount ?
                                            <Col xs={24} lg={12} style={{ paddingTop: 12, paddingLeft: 20, paddingRight: 20 }}>
                                                <Row style={{ paddingLeft: 12 }}>
                                                    <Col span={24}>
                                                        <label style={{ paddingRight: 10 }}>ข้อมูลบัญชีธนาคาร</label>
                                                        {accountVerifiedBank.bankInfoApprovalId === 1 ? <label style={{ color: "green" }}>ผ่านการตรวจสอบ</label> : []}
                                                        {accountVerifiedBank.bankInfoApprovalId === 12 ? <label style={{ color: "orange" }}>รอการตรวจสอบ</label> : []}
                                                        {accountVerifiedBank.bankInfoApprovalId === 2 ? <label style={{ color: "red" }}>ไม่ผ่านการตรวจสอบ</label> : []}
                                                    </Col>

                                                    <Col span={24}>
                                                        <Form.Item
                                                            label="ธนาคาร"
                                                            name="bankName"
                                                            rules={[{ required: true, message: "กรุณาเลือกธนาคาร" }]}
                                                        >
                                                            <Select
                                                                showSearch
                                                                style={{ width: '100%' }}
                                                                optionFilterProp="children"
                                                                allowClear
                                                                disabled={accountVerifiedBank.bankInfoApprovalId === 1 ? true : ((accountVerify.isOpenVerifyBankAccount !== null || accountVerifiedIdentify.approvalStatusId !== 12 ? (accountVerifiedBank.approvalStatusId === 1 || accountVerifiedBank.approvalStatusId === 12) : true) ? true : false)}
                                                            >
                                                                {bankCategory.map((val, index) =>
                                                                    <Option key={index} value={val.value}>{val.label}</Option>
                                                                )}
                                                            </Select>
                                                        </Form.Item>
                                                    </Col>

                                                    <Col span={24}>
                                                        <Form.Item
                                                            label="บัญชีเลขที่"
                                                            name="bankAccountId"
                                                            rules={[{ required: true, validator: detectBankAccountId }]}
                                                        >
                                                            <Input
                                                                maxLength={12}
                                                                disabled={accountVerifiedIdentify.approvalStatusId === 12 ? true : (accountVerifiedBank.bankInfoApprovalId === 1 ? true : false)}
                                                                style={{ width: "100%" }}
                                                            />
                                                        </Form.Item>
                                                    </Col>

                                                    <Col span={24}>
                                                        <Form.Item
                                                            label="ชื่อบัญชี"
                                                            name="bankAccountName"
                                                            rules={[{ required: true, validator: detectBankAccountName }]}
                                                        >
                                                            <Input
                                                                onBlur={(e) => form.setFieldsValue({ bankAccountName: e.target.value })}
                                                                disabled={accountVerifiedIdentify.approvalStatusId === 12 ? true : (accountVerifiedBank.bankInfoApprovalId === 1 ? true : false)}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            : []
                                        }
                                    </Row>
                                </div>

                            </Form>
                        }
                    </Modal >
                </>
                : []
            }
        </>
    )
}
