import { useState, useEffect } from 'react'
import {
    Button,
    Image,
    Row,
    Col,
    Upload
} from 'antd'
import { LoadingOutlined, UploadOutlined } from '@ant-design/icons'
import ImgCrop from 'antd-img-crop'
import { Icon } from '@iconify/react'
import { AiOutlineUpload } from 'react-icons/ai'
import { Notification } from '../../notification'
import { apiStorageUrl, imgDefaultUrl } from '../../../../constants'
import { resizeImage } from '../../../mamager/ImageMamager'

export default function ImageUpload(props) {

    const [videoCourseCoverImage, setVideoCourseCoverImage] = useState({
        loading: false
    })

    function getBase64(img, callback) {
        const reader = new FileReader()
        reader.addEventListener('load', () => callback(reader.result))
        reader.readAsDataURL(img)
    }

    const handleImageChange = info => {
        if (info.file.status !== 'uploading') {
            resizeImage(info.file.originFileObj, props.aspectImageHeight, props.aspectImageWidth).then((res) => {
                getBase64(res, imageUrl => {
                    setVideoCourseCoverImage({
                        imageUrl,
                        loading: false
                    })

                    props.handleImageUrl(imageUrl, props.requestName)
                })
            })
        } else {
            setVideoCourseCoverImage({ loading: true })
        }

        if (info.file.status === 'done') {
            Notification("success", "ระบบ", props.messegeSuccess)
        } else if (info.file.status === 'error') {
            Notification("error", "ระบบ", props.messegeFail)
        }
    };

    useEffect(() => {
        setVideoCourseCoverImage({
            loading: false,
            imageUrl: props.imageUrl
        })
    }, [props.imageUrl])

    return (
        <Row>
            <Col span={24}>
                {videoCourseCoverImage.imageUrl ?
                    <Image
                        style={{ width: '100%', height: props.height, border: "1px solid #e8e8e8" }}
                        alt="avatar"
                        src={videoCourseCoverImage.imageUrl}
                        preview={{ src: videoCourseCoverImage.imageUrl }}
                    />
                    :
                    <Image
                        style={{ height: '100%', width: '100%', border: "1px solid #e8e8e8" }}
                        src="error"
                        fallback={`${imgDefaultUrl}`}
                    />
                }
            </Col>
            <Col span={24}>
                <ImgCrop
                    modalTitle={<label style={{ fontWeight: 'bold', padding: 0, margin: 0 }}>ปรับเเต่งรูปภาพ</label>}
                    modalOk={
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <AiOutlineUpload style={{ width: 20, height: 20 }} />
                            <label style={{  cursor: "pointer", paddingLeft: 10 }}>อัพโหลด</label>
                        </div>
                    }
                    modalCancel={
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <Icon icon="mdi:cancel" style={{ width: 20, height: 20 }} />
                            <label style={{ cursor: "pointer", paddingLeft: 10 }}>ยกเลิก</label>
                        </div>
                    }
                    aspect={props.aspectImageWidth / props.aspectImageHeight}
                    modalWidth={props.aspectImageWidth + 50}
                >
                    <Upload
                        accept='.jpeg, .jpg, .png'
                        maxCount={1}
                        showUploadList={{ showRemoveIcon: false }}
                        action={`${apiStorageUrl}/upload/active`}
                        onChange={handleImageChange}
                    >
                        <Button
                            type="primary"
                            disabled={props.isDisabled}
                            style={{ width: '100%' }}
                            icon={videoCourseCoverImage.loading ? <LoadingOutlined /> : <UploadOutlined />}
                        >{props.buttonName}</Button>
                    </Upload>
                </ImgCrop>
            </Col>
        </Row>
    )
}
