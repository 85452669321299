import { 
    HTTP_ORDER_PAYMENT_CHAPTER_AND_VIDEO_COURSE_FETCHING, 
    HTTP_ORDER_PAYMENT_CHAPTER_AND_VIDEO_COURSE_SUCCESS, 
    HTTP_ORDER_PAYMENT_CHAPTER_AND_VIDEO_COURSE_FAILED, 
} from "../constants"

export const setOrderPaymentChapterAndVideoCourseFetching = () => ({
    type: HTTP_ORDER_PAYMENT_CHAPTER_AND_VIDEO_COURSE_FETCHING,
})

export const setOrderPaymentChapterAndVideoCourseSuccess = (payload) => ({
    type: HTTP_ORDER_PAYMENT_CHAPTER_AND_VIDEO_COURSE_SUCCESS,
    payload
})

export const setOrderPaymentChapterAndVideoCourseFailed = () => ({
    type: HTTP_ORDER_PAYMENT_CHAPTER_AND_VIDEO_COURSE_FAILED,
})
