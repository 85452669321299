import {
    HTTP_REGISTER_ADDRESS_FETCHING,
    HTTP_REGISTER_ADDRESS_SUCCESS,
    HTTP_REGISTER_ADDRESS_FAILED
} from "../constants"

const initialState = {
    resultRegisterAddress: {
        companyAddress: undefined,
        zipCode: undefined,

        cityId: undefined,
        cityName: undefined,
        
        stateId: undefined,
        stateName: undefined,

        countryId: undefined,
        countryName: undefined
    },
    isFetching: false,
    isError: false
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, { type, payload }) => {
    switch (type) {

        case HTTP_REGISTER_ADDRESS_FETCHING:
            return { ...state, resultRegisterAddress: null, isFetching: true, isError: false }

        case HTTP_REGISTER_ADDRESS_SUCCESS:
            return { ...state, resultRegisterAddress: payload, isFetching: false, isError: false }

        case HTTP_REGISTER_ADDRESS_FAILED:
            return { ...state, resultRegisterAddress: null, isFetching: false, isError: true }

        default:
            return state
    }
}
