/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable jsx-a11y/anchor-is-valid */

import { useState, useEffect, useMemo, useRef } from 'react'
import moment from 'moment'
import { useLocation } from "react-router-dom"
import {
    Menu,
    Modal,
    Row,
    Col,
    Popconfirm,
    Button,
    Divider
} from 'antd'
import { PageHeader } from '@ant-design/pro-layout'
import { Icon } from '@iconify/react'
import { BsInfoCircle } from "react-icons/bs"
import { ExclamationCircleOutlined } from "@ant-design/icons"
import { useDispatch, useSelector } from "react-redux"
import { setCourseManageCardStateToSuccess } from '../../../actions/courseManageCard.action'
import { setCourseManageIntroStateToSuccess } from '../../../actions/courseManageIntro.action'
import { setCourseManagePriceStateToSuccess } from '../../../actions/courseManagePrice.action'
import { setCourseManageDescriptionStateToSuccess } from '../../../actions/courseManageDescription.action'
import Footer from '../../../common/components/footer'
import Header from '../../../common/components/header'
import LoadingPage from '../../../common/components/loading/page'
import { Notification } from '../../../common/components/notification'
import { useDimensions } from '../../../common/mamager/ScreenManager'
import ManageCard from '../components/card'
import ManageDescript from '../components/descript'
import ManageDocument from '../components/document'
import ManageMediaIntro from '../components/mediaIntro'
import ManagePrice from '../components/price'
import ManageVideo from '../components/video'
import { getAdminInspectOpenCourseByIdFetch, getCourseInspectChapterFetch, getCourseInspectDocumentApiFetch, getCourseInspectFetch, getCourseInspectMediaIntroFetch, getCourseInspectVideoListFetch } from '../../erp/API'
import { updateRequestOpenCourseInspectFetch } from '../API'
import "../css/adjust.css"

const { confirm } = Modal;

const formatDate = "YYYY-MM-DD"
const formatDateTime = "DD/MM/YYYY HH:mm"

export default function CourseAdjust({ firebase, authorized, user }) {
    const language = "th"
    const { width } = useDimensions()

    const dispatch = useDispatch()
    const location = useLocation()
    const params = new URLSearchParams(location.search)
    const courseId = params.get("course-id")

    const isSavedRef = useRef(false)

    const { resultCard } = useSelector(({ courseManageCardReducer }) => courseManageCardReducer)
    const { resultIntro } = useSelector(({ courseManageIntroReducer }) => courseManageIntroReducer)
    const { resultPrice } = useSelector(({ courseManagePriceReducer }) => courseManagePriceReducer)
    const { resultDescription } = useSelector(({ courseManageDescriptionReducer }) => courseManageDescriptionReducer)
    const { resultDocument } = useSelector(({ courseManageDocumentReducer }) => courseManageDocumentReducer)
    const { resultVideoList } = useSelector(({ courseManageVideoListReducer }) => courseManageVideoListReducer)

    const [inspect, setInspect] = useState([])
    const [course, setCourse] = useState([])
    const [courseMediaIntro, setCourseMediaIntro] = useState([])
    const [courseDocument, setCourseDocument] = useState([])
    const [courseVideoList, setCourseVideoList] = useState([])
    const [courseChapter, setCourseChapter] = useState([])

    const [constantCourseVideoList, setConstantCourseVideoList] = useState([])
    const [constantCourseDocument, setConstantCourseDocument] = useState([])

    const [modalActive, setModalActive] = useState(false)

    const [loading, setLoading] = useState(true)

    const [selectedMenuItem, setSelectedMenuItem] = useState("menu1")

    const [isNotOpenSendAdjust, setNotIsOpenSendAdjust] = useState(true) // disable or enable btn send adjust

    const getCourseApi = async () => {
        let obj = {
            courseId: courseId,
            uid: user.uid,
            isActive: 1,
            language
        }
        const resultCourse = await getCourseInspectFetch(obj, "admin-inspect-open-course")
        setCourse(resultCourse.dataOriginal)
    }

    const getCourseMediaIntroApi = async () => {
        let obj = {
            courseId: courseId,
            uid: user.uid,
            isActive: 1,
            language
        }
        const resultCourseMediaIntro = await getCourseInspectMediaIntroFetch(obj, "admin-inspect-open-course")
        setCourseMediaIntro(resultCourseMediaIntro.dataOriginal);
    }

    const getCourseManageDocumentApi = async () => {
        let obj = {
            courseId: courseId,
            uid: user.uid,
            isActive: 1,
            language
        }
        const resultCourseDocument = await getCourseInspectDocumentApiFetch(obj, "admin-inspect-open-course")          // course document

        let tempDocument = []
        if (resultCourseDocument.dataOriginal) {
            const resultInspect = await getInspectApproval()                                                           // course inspect

            resultCourseDocument.dataOriginal.map(val => {
                let tempInspect = resultInspect.courseDocument.formData.find(val2 => val2.id === val.coureDocumentationId)
                tempDocument.push({
                    // courseDocument
                    coureDocumentationId: val.coureDocumentationId,
                    coureDocumentationLocation: val.coureDocumentationLocation,
                    coureDocumentationName: val.coureDocumentationName,
                    courseId: val.courseId,
                    created_at: val.created_at,
                    fileCategoryId: val.fileCategoryId,
                    fileCategoryName: val.fileCategoryName,
                    isUpdate: val.isUpdate,
                    approvalStatusId: val.approvalStatusId,
                    approvalStatusName: val.approvalStatusName,

                    // courseInspect
                    inspectCourseApprovalId: tempInspect.inspectionCourseApprovalId,
                    inspectCourseApprovalName: tempInspect.inspectionCourseApprovalName,
                    reasonCourseNotApproval: tempInspect.reasonCourseNotApproval,
                })
            })
        }

        setCourseDocument(tempDocument) // resultCourseDocument
        return tempDocument
    }

    const getCourseVideoApi = async () => {
        let obj = {
            courseId: courseId,
            uid: user.uid,
            isActive: 1,
            language
        }
        const resultCourseVideo = await getCourseInspectVideoListFetch(obj, "admin-inspect-open-course") // course video

        let tempVideo = []
        if (resultCourseVideo.dataOriginal) {
            const resultInspect = await getInspectApproval() // course inspect

            resultCourseVideo.dataOriginal.map(val => {
                let tempInspect = resultInspect.courseVideo.formData.find(val2 => val2.id === val.courseVideoId)
                tempVideo.push({
                    // courseVideo
                    courseId: val.courseId,
                    courseChapterId: val.courseChapterId,
                    courseVideoDescriptionName: val.courseVideoDescriptionName,
                    courseVideoDuration: val.courseVideoDuration,
                    courseVideoId: val.courseVideoId,
                    courseVideoImgLocation: val.courseVideoImgLocation,
                    courseVideoLocation: val.courseVideoLocation,
                    courseVideoRank: val.courseVideoRank,
                    courseVideoTitleName: val.courseVideoTitleName,
                    created_at: val.created_at,
                    isUpdate: val.isUpdate,
                    updated_at: val.updated_at,
                    view: val.view,
                    approvalStatusId: val.approvalStatusId,
                    approvalStatusName: val.approvalStatusName,
                    courseVideoPrice: val.courseVideoPrice,

                    // courseInspect
                    inspectCourseApprovalId: tempInspect.inspectionCourseApprovalId,
                    inspectCourseApprovalName: tempInspect.inspectionCourseApprovalName,
                    reasonCourseNotApproval: tempInspect.reasonCourseNotApproval,
                })
            })
        }

        setCourseVideoList(tempVideo)
        return tempVideo
    }

    const getCourseChapterApi = async () => {
        let obj = {
            courseId: courseId,
            uid: user.uid,
            isActive: 1,
            language
        }
        const resultCourseChapter = await getCourseInspectChapterFetch(obj, "admin-inspect-open-course")

        let tempChapter = [];
        if (resultCourseChapter.dataOriginal) {
            const resultInspect = await getInspectApproval() // course inspect

            resultCourseChapter.dataOriginal.map(val => {
                let tempInspect = resultInspect.courseChapter.formData.find(val2 => val2.id === val.courseChapterId)
                tempChapter.push({
                    // courseChapter
                    approvalStatusId: val.approvalStatusId,
                    approvalStatusName: val.approvalStatusName,
                    chapterCategoryId: val.chapterCategoryId,
                    chapterCategoryName: val.chapterCategoryName,
                    courseChapterDescriptionName: val.courseChapterDescriptionName,
                    courseChapterId: val.courseChapterId,
                    courseChapterNo: val.courseChapterNo,
                    courseChapterTitleName: val.courseChapterTitleName,
                    courseId: val.courseId,
                    createdAt: val.createdAt,

                    // courseInspect
                    inspectCourseApprovalId: tempInspect.inspectionCourseApprovalId,
                    inspectCourseApprovalName: tempInspect.inspectionCourseApprovalName,
                    reasonCourseNotApproval: tempInspect.reasonCourseNotApproval,
                })
            })
        }
        setCourseChapter(tempChapter)
    }

    const getInspectApproval = async () => {
        let obj = {
            courseId: courseId,
            uid: user.uid
        }
        const result = await getAdminInspectOpenCourseByIdFetch(obj, language)
        setInspect(result)

        // switch button send result adjust
        if (
            (result.course.inspectionCourseCardAdjustId === 1 || !result.course.inspectionCourseCardAdjustId) &&
            (result.course.inspectionCoursePriceAdjustId === 1 || !result.course.inspectionCoursePriceAdjustId) &&
            (result.course.inspectionCourseDescriptionAdjustId === 1 || !result.course.inspectionCourseDescriptionAdjustId) &&
            (result.courseMediaIntro.inspectionCourseAdjustIdSummary === 1 || !result.courseMediaIntro.inspectionCourseAdjustIdSummary) &&
            (result.courseDocument.inspectionCourseAdjustIdSummary === 1 || !result.courseDocument.inspectionCourseAdjustIdSummary) &&
            (result.courseVideo.inspectionCourseAdjustIdSummary === 1 || !result.courseVideo.inspectionCourseAdjustIdSummary)
        ) {
            setNotIsOpenSendAdjust(false)
        } else {
            setNotIsOpenSendAdjust(true)
        }

        return result
    }

    const handleCourseMediaIntroReload = async () => {
        isSavedRef.current = true
        await getCourseMediaIntroApi()

        // reload inspect
        await getInspectApproval()
    }

    const handleDocumentReload = async () => {
        isSavedRef.current = true
        await getConstantData(true, false)
        await getCourseManageDocumentApi()

        // reload inspect
        await getInspectApproval()
    }

    const handleCourseVideoListReload = async () => {
        isSavedRef.current = true
        await getConstantData(false, true)
        await getCourseVideoApi()

        // reload inspect
        await getInspectApproval()
    }

    const handleCourseChapterReload = async () => {
        setLoading(true)

        isSavedRef.current = true
        await getConstantData()
        await getCourseChapterApi()

        // reload icon apploval
        await handleCourseReload()

        setLoading(false)
    }

    const handleCourseReload = async () => {
        isSavedRef.current = true
        await getCourseApi()
        await getInspectApproval()
    }

    const componentsSwitch = (type) => {
        switch (type) {
            case "menu1":
                return <ManageCard
                    isStepActive={false}
                    isCourseCategoryActive={false}
                    isCourseFormatActive={false}
                    isCourseCertificateActive={false}
                    user={user}
                    course={course}
                    courseInspect={inspect}
                    handleCourseReload={handleCourseReload}
                    switchType="adjust-course"
                />
            case "menu2":
                return <ManageMediaIntro
                    isStepActive={false}
                    user={user}
                    courseMediaIntro={courseMediaIntro[0]}
                    courseInspect={inspect}
                    handleCourseMediaIntroReload={handleCourseMediaIntroReload}
                    switchType="adjust-course"
                />
            case "menu3":
                return <ManagePrice
                    isStepActive={false}
                    user={user}
                    course={course}
                    courseInspect={inspect}
                    handleCourseReload={handleCourseReload}
                    switchType="adjust-course"
                />
            case "menu4":
                return <ManageDescript
                    isStepActive={false}
                    user={user}
                    course={course}
                    courseInspect={inspect}
                    handleCourseReload={handleCourseReload}
                    switchType="adjust-course"
                />
            case "menu5":
                return <ManageDocument
                    isStepActive={false}
                    user={user}
                    courseId={courseId}
                    courseDocument={courseDocument}
                    courseInspect={inspect}
                    handleDocumentReload={handleDocumentReload}
                    switchType="adjust-course"
                />
            case "menu6":
                return <ManageVideo
                    authorized={authorized}
                    isStepActive={false}
                    user={user}
                    courseId={courseId}
                    coursePrice={course?.coursePrice}
                    isPaymentByVideo={course?.isPaymentByVideo}
                    courseVideoList={courseVideoList}
                    courseChapter={courseChapter}
                    courseInspect={inspect}
                    handleCourseVideoListReload={handleCourseVideoListReload}
                    handleCourseChapterReload={handleCourseChapterReload}
                    switchType="adjust-course"
                />
            default:
                return null
        }
    }

    const reloadComponent = async (key) => {
        // reload
        if (
            key === 'menu1' ||
            key === 'menu3' ||
            key === 'menu4'
        ) {
            await getCourseApi()
        } else if (key === 'menu2') {
            await getCourseMediaIntroApi()
        } else if (key === 'menu5') {
            await getCourseManageDocumentApi()
        } else if (key === 'menu6') {
            await getCourseVideoApi()
        }
    }

    const getConstantData = async (isDocumentActive, isVideoActive) => {
        if (isDocumentActive) {
            const resultCourseDocument = await getCourseManageDocumentApi()
            setConstantCourseDocument(resultCourseDocument)
        }

        if (isVideoActive) {
            const resultCourseVideo = await getCourseVideoApi()
            setConstantCourseVideoList(resultCourseVideo)
        }
    }

    const detectVideoListChange = () => {
        if (constantCourseVideoList.length !== resultVideoList.length) { // row not equal
            return true // diff
        } else {
            let isDiff
            for (let i = 0; i < constantCourseVideoList.length; i++) { // row equal , next step check diff data
                if (
                    constantCourseVideoList[i].approvalStatus === resultVideoList[i].approvalStatus &&
                    constantCourseVideoList[i].approvalStatusId === resultVideoList[i].approvalStatusId &&
                    constantCourseVideoList[i].courseId === resultVideoList[i].courseId &&
                    constantCourseVideoList[i].courseVideoDescriptionName === resultVideoList[i].courseVideoDescriptionName &&
                    constantCourseVideoList[i].courseVideoDuration === resultVideoList[i].courseVideoDuration &&
                    constantCourseVideoList[i].courseVideoId === resultVideoList[i].courseVideoId &&
                    constantCourseVideoList[i].courseVideoImgLocation === resultVideoList[i].courseVideoImgLocation &&
                    constantCourseVideoList[i].courseVideoLocation === resultVideoList[i].courseVideoLocation &&
                    constantCourseVideoList[i].courseVideoRank === resultVideoList[i].courseVideoRank &&
                    constantCourseVideoList[i].courseVideoTitleName === resultVideoList[i].courseVideoTitleName &&
                    constantCourseVideoList[i].created_at === resultVideoList[i].created_at &&
                    constantCourseVideoList[i].isUpdate === resultVideoList[i].isUpdate &&
                    constantCourseVideoList[i].updated_at === resultVideoList[i].updated_at
                ) {
                    isDiff = false // not diff
                } else {
                    isDiff = true // diff
                    break
                }
            }
            return isDiff
        }
    }

    const detectDocumentChange = () => {
        if (constantCourseDocument.length !== resultDocument.length) { // row not equal
            return true // diff
        } else {
            let isDiff
            for (let i = 0; i < constantCourseDocument.length; i++) { // row equal , next step check diff data
                if (
                    constantCourseDocument[i].approvalStatus === resultDocument[i].approvalStatus &&
                    constantCourseDocument[i].approvalStatusId === resultDocument[i].approvalStatusId &&
                    constantCourseDocument[i].coureDocumentationId === resultDocument[i].coureDocumentationId &&
                    constantCourseDocument[i].coureDocumentationLocation === resultDocument[i].coureDocumentationLocation &&
                    constantCourseDocument[i].coureDocumentationName === resultDocument[i].coureDocumentationName &&
                    constantCourseDocument[i].courseId === resultDocument[i].courseId &&
                    constantCourseDocument[i].isUpdate === resultDocument[i].isUpdate
                ) {
                    isDiff = false // not diff
                } else {
                    isDiff = true // diff
                    break
                }
            }
            return isDiff
        }
    }

    const toggleMenuToDefault = (key) => {
        // reload
        reloadComponent(key)

        // update location menu
        setSelectedMenuItem(key)

        setDefaultReducer()

        // set default
        isSavedRef.current = false // not click update edit
    }

    const setDefaultReducer = () => {
        // default
        dispatch(setCourseManageCardStateToSuccess(null))
        dispatch(setCourseManageIntroStateToSuccess(null))
        dispatch(setCourseManagePriceStateToSuccess(null))
        dispatch(setCourseManageDescriptionStateToSuccess(null))
        // dispatch(setCourseManageDocumentStateToSuccess([]))
        // dispatch(setCourseManageVideoListStateToSuccess([]))
    }

    const handleSendResult = async () => {
        let obj = {
            inspectCourseId: 2,
            inspectCourseCardId: 2,
            inspectCourseMediaIntroId: 2,
            inspectCoursePriceId: 2,
            inspectCourseDescriptionId: 2,
            inspectCourseDocumentId: 2,
            inspectCourseVideoId: 2,
        }
        const result = await updateRequestOpenCourseInspectFetch(
            user.uid,           // uid
            obj,                // value
            courseId,           // courseId
            1                   // isActive
        )
        if (result.data.isSuccess) {
            Notification("success", "ระบบ", "บันทึกการปรับเเต่งสำเร็จ")
        } else {
            Notification("error", "ระบบ", "บันทึกการปรับเเต่งไม่สำเร็จ")
        }

        getRequestApi()
    }

    const getRequestApi = async () => {
        setLoading(true)

        const resultInspect = await getInspectApproval()
        if (resultInspect.inspectionCourseId === 2) {
            setModalActive(true)
        }

        await getCourseApi()
        await getCourseMediaIntroApi()
        await getCourseManageDocumentApi()
        await getCourseVideoApi()
        await getCourseChapterApi()

        setLoading(false)
    }

    useMemo(() => {
        // reducer default
        setDefaultReducer()

        getConstantData(true, true)
    }, [])

    useEffect(() => {
        getRequestApi()
    }, [])

    return (
        <div style={{ backgroundColor: '#ECECEC' }}>
            <Header firebase={firebase} user={user} />

            <article>
                {!loading ?
                    <div style={width >= 1500 ? { paddingLeft: '5%', paddingRight: '5%' } : ((width > 912 && width < 1500) ? { paddingLeft: '5%', paddingRight: '5%' } : { paddingLeft: '0%', paddingRight: '0%' })}>
                        <PageHeader title={<span style={{ fontSize: 18 }}>ปรับเเก้คำขอเปิดคอร์ส</span>} style={{ backgroundColor: 'white', borderBottom: '1px solid #ECECEC' }} />
                        <article style={{ backgroundColor: 'white', width: '100%', height: '100%' }}>
                            <Row>
                                <Col xs={8} sm={7} md={6} lg={5}>
                                    <Menu
                                        defaultSelectedKeys={['1']}
                                        defaultOpenKeys={['menu1']}
                                        mode="inline"
                                        selectedKeys={selectedMenuItem}
                                        style={{ height: '100%' }}
                                        onClick={async (e) => {
                                            let isOpenReminder = false;
                                            if (selectedMenuItem === 'menu1') {
                                                isOpenReminder = resultCard ? true : false;
                                            } else if (selectedMenuItem === 'menu2') {
                                                isOpenReminder = resultIntro ? true : false;
                                            } else if (selectedMenuItem === 'menu3') {
                                                isOpenReminder = resultPrice ? true : false;
                                            } else if (selectedMenuItem === 'menu4') {
                                                isOpenReminder = resultDescription ? true : false;
                                            } else if (selectedMenuItem === 'menu5') {
                                                isOpenReminder = resultDocument ? detectDocumentChange() : true;
                                            } else if (selectedMenuItem === 'menu6') {
                                                isOpenReminder = resultVideoList ? detectVideoListChange() : true;
                                            }

                                            // if have change and saved not show reminder
                                            if (isSavedRef.current) {
                                                isOpenReminder = false;
                                            }

                                            if (isOpenReminder) {
                                                if (selectedMenuItem !== e.key) {
                                                    confirm({
                                                        icon: <ExclamationCircleOutlined />,
                                                        content: "ออกจากเมนูนี้ โดยไม่บันทึกการเปลี่ยนเเปลงหรือไม่?",
                                                        okText: <span style={{ width: 50 }}>ใช่</span>,
                                                        cancelText: <span style={{ width: 50 }}>ไม่ใช่</span>,
                                                        async onOk() {
                                                            // set default menu or reload data when toggle menu
                                                            toggleMenuToDefault(e.key);
                                                        },
                                                        onCancel() { }
                                                    });
                                                }
                                            } else {
                                                // set default menu or reload data when toggle menu
                                                toggleMenuToDefault(e.key);
                                            }
                                        }}
                                    >
                                        <Menu.Item key="menu1" style={{ height: (width <= 768 ? 90 : 60), borderBottom: "1px solid #DDDDDD", padding: 0, margin: 0 }}>
                                            <div style={{ display: "flex", alignContent: "center" }}>
                                                <div style={{ display: "grid", alignContent: "center", width: 110 }}>
                                                    <label style={{ paddingLeft: ((width > 768 && width < 1200) ? 10 : 20), marginBottom: 0, cursor: 'pointer', fontWeight: "bold" }}>หน้าปก</label>
                                                </div>

                                                <div style={{ display: "grid", alignItems: "center" }}>
                                                    {inspect.course.inspectionCourseCardApprovalId === 2 ?
                                                        <label style={{ cursor: "pointer", height: 25 }}>
                                                            {inspect ? (inspect.course.inspectionCourseCardAdjustName ? inspect.course.inspectionCourseCardAdjustName : "-") : "-"}
                                                        </label>
                                                        : []
                                                    }

                                                    <label style={{ cursor: "pointer", color: inspect.course.inspectionCourseCardApprovalId === 1 ? "#00A854" : "#f5222d" }}>
                                                        {inspect.course ? (inspect.course.inspectionCourseCardApprovalName ? inspect.course.inspectionCourseCardApprovalName : "-") : "-"}
                                                    </label>
                                                </div>
                                            </div>
                                        </Menu.Item>

                                        <Menu.Item key="menu2" style={{ height: (width <= 768 ? 90 : 60), borderBottom: "1px solid #DDDDDD", padding: 0, margin: 0 }}>
                                            <div style={{ display: "flex", alignContent: "center" }}>
                                                <div style={{ display: "grid", alignContent: "center", width: 110 }}>
                                                    <label style={{ paddingLeft: ((width > 768 && width < 1200) ? 10 : 20), marginBottom: 0, cursor: 'pointer', fontWeight: "bold" }}>สื่อนำเสนอ</label>
                                                </div>

                                                <div style={{ display: "grid", alignItems: "center" }}>
                                                    {inspect.courseMediaIntro.inspectionCourseApprovalIdSummary === 2 ?
                                                        <label style={{ cursor: "pointer", height: 25 }}>
                                                            {inspect ? (inspect.courseMediaIntro.inspectionCourseAdjustNameSummary ? inspect.courseMediaIntro.inspectionCourseAdjustNameSummary : "-") : "-"}
                                                        </label>
                                                        : []
                                                    }

                                                    <label style={{ cursor: "pointer", color: inspect.courseMediaIntro.inspectionCourseApprovalIdSummary === 1 ? "#00A854" : "#f5222d" }}>
                                                        {inspect.courseMediaIntro ? (inspect.courseMediaIntro.inspectionCourseApprovalNameSummary ? inspect.courseMediaIntro.inspectionCourseApprovalNameSummary : "-") : "-"}
                                                    </label>
                                                </div>
                                            </div>
                                        </Menu.Item>

                                        <Menu.Item key="menu3" style={{ height: (width <= 768 ? 90 : 60), borderBottom: "1px solid #DDDDDD", padding: 0, margin: 0 }}>
                                            <div style={{ display: "flex", alignContent: "center" }}>
                                                <div style={{ display: "grid", alignContent: "center", width: 110 }}>
                                                    <span style={{ paddingLeft: ((width > 768 && width < 1200) ? 10 : 20), marginBottom: 0, cursor: 'pointer', fontWeight: "bold" }}>ราคา</span>
                                                </div>

                                                <div style={{ display: "grid", alignItems: "center" }}>
                                                    {inspect.course.inspectionCoursePriceApprovalId === 2 ?
                                                        <span style={{ cursor: "pointer", height: 25 }}>
                                                            {inspect ? (inspect.course.inspectionCoursePriceAdjustName ? inspect.course.inspectionCoursePriceAdjustName : "-") : "-"}
                                                        </span>
                                                        : []
                                                    }

                                                    <span style={{ cursor: "pointer", color: inspect.course.inspectionCoursePriceApprovalId === 1 ? "#00A854" : "#f5222d" }}>
                                                        {inspect.course ? (inspect.course.inspectionCoursePriceApprovalName ? inspect.course.inspectionCoursePriceApprovalName : "-") : "-"}
                                                    </span>
                                                </div>
                                            </div>
                                        </Menu.Item>

                                        <Menu.Item key="menu4" style={{ height: (width <= 768 ? 90 : 60), borderBottom: "1px solid #DDDDDD", padding: 0, margin: 0 }}>
                                            <div style={{ display: "flex", alignContent: "center" }}>
                                                <div style={{ display: "grid", alignContent: "center", width: 110 }}>
                                                    <span style={{ paddingLeft: ((width > 768 && width < 1200) ? 10 : 20), marginBottom: 0, cursor: 'pointer', fontWeight: "bold" }}>รายละเอียด</span>
                                                </div>

                                                <div style={{ display: "grid", alignItems: "center" }}>
                                                    {inspect.course.inspectionCourseDescriptionApprovalId === 2 ?
                                                        <span style={{ cursor: "pointer", height: 25 }}>
                                                            {inspect ? (inspect.course.inspectionCourseDescriptionAdjustName ? inspect.course.inspectionCourseDescriptionAdjustName : "-") : "-"}
                                                        </span>
                                                        : []
                                                    }

                                                    <span style={{ cursor: "pointer", color: inspect.course.inspectionCourseDescriptionApprovalId === 1 ? "#00A854" : "#f5222d" }}>
                                                        {inspect.course ? (inspect.course.inspectionCourseDescriptionApprovalName ? inspect.course.inspectionCourseDescriptionApprovalName : "-") : "-"}
                                                    </span>
                                                </div>
                                            </div>
                                        </Menu.Item>

                                        <Menu.Item key="menu5" style={{ height: (width <= 768 ? 90 : 60), borderBottom: "1px solid #DDDDDD", padding: 0, margin: 0 }}>
                                            <div style={{ display: "flex", alignContent: "center" }}>
                                                <div style={{ display: "grid", alignContent: "center", width: 110 }}>
                                                    <span style={{ paddingLeft: ((width > 768 && width < 1200) ? 10 : 20), marginBottom: 0, cursor: 'pointer', fontWeight: "bold" }}>เอกสารเพิ่มเติม</span>
                                                </div>

                                                <div style={{ display: "grid", alignItems: "center" }}>
                                                    {inspect.courseDocument.inspectionCourseApprovalIdSummary === 2 ?
                                                        <span style={{ cursor: "pointer", height: 25 }}>
                                                            {inspect ? (inspect.courseDocument.inspectionCourseAdjustNameSummary ? inspect.courseDocument.inspectionCourseAdjustNameSummary : "-") : "-"}
                                                        </span>
                                                        : []
                                                    }
                                                    <span style={{ cursor: "pointer", color: inspect.courseDocument.inspectionCourseApprovalIdSummary === 1 ? "#00A854" : "#f5222d" }}>
                                                        {inspect.courseDocument ? (inspect.courseDocument.inspectionCourseApprovalNameSummary ? inspect.courseDocument.inspectionCourseApprovalNameSummary : "-") : "-"}
                                                    </span>
                                                </div>
                                            </div>
                                        </Menu.Item>

                                        <Menu.Item key="menu6" style={{ height: (width <= 768 ? 90 : 60), borderBottom: "1px solid #DDDDDD", padding: 0, margin: 0 }}>
                                            <div style={{ display: "flex", alignContent: "center" }}>
                                                <div style={{ display: "grid", alignContent: "center", width: 110 }}>
                                                    <span style={{ paddingLeft: ((width > 768 && width < 1200) ? 10 : 20), marginBottom: 0, cursor: 'pointer', fontWeight: "bold" }}>วิดีโอ</span>
                                                </div>

                                                <div style={{ display: "grid", alignItems: "center" }}>
                                                    {inspect.courseVideo.inspectionCourseApprovalIdSummary === 2 ?
                                                        <span style={{ cursor: "pointer", height: 25 }}>
                                                            {inspect ? (inspect.courseVideo.inspectionCourseAdjustNameSummary ? inspect.courseVideo.inspectionCourseAdjustNameSummary : "-") : "-"}
                                                        </span>
                                                        : []
                                                    }

                                                    <span style={{ cursor: "pointer", color: inspect.courseVideo.inspectionCourseApprovalIdSummary === 1 ? "#00A854" : "#f5222d" }}>
                                                        {inspect.courseVideo ? (inspect.courseVideo.inspectionCourseApprovalNameSummary ? inspect.courseVideo.inspectionCourseApprovalNameSummary : "-") : "-"}
                                                    </span>
                                                </div>
                                            </div>
                                        </Menu.Item>
                                    </Menu>
                                </Col>

                                <Col xs={16} sm={17} md={18} lg={19}>
                                    <Row style={{ fontSize: 16 }}>
                                        <Col xs={24} sm={24} md={14} lg={19} style={{ padding: (width > 480 ? 15 : 5) }}>
                                            <Row gutter={[4, 4]}>
                                                <Col xs={9} sm={8} md={7} lg={7}><span style={{ float: "right" }}>สร้างเมื่อ : </span></Col>
                                                <Col xs={15} sm={16} md={17} lg={17}><span style={{ paddingLeft: 3 }}>{course.created_at ? moment(course.created_at).format(formatDateTime) : "-"}</span></Col>

                                                <Col xs={9} sm={8} md={7} lg={7}><span style={{ float: "right" }}>จำนวนตีกลับ : </span></Col>
                                                <Col xs={15} sm={16} md={17} lg={17}><span style={{ paddingLeft: 3 }}>{inspect.amountAdjust ? (inspect.amountAdjust + "  ครั้ง") : "-"}</span></Col>

                                                <Col xs={9} sm={8} md={7} lg={7}><span style={{ float: "right" }}>เหลือเเก้ไข : </span></Col>
                                                <Col xs={15} sm={16} md={17} lg={17}>
                                                    <span style={{ paddingLeft: 3 }}>
                                                        {inspect.dateEndAdjust ?
                                                            <>{moment(inspect.dateEndAdjust, formatDate).diff(new Date(), 'days')} วัน, {moment(inspect.dateEndAdjust).format(formatDateTime)}</>
                                                            : " -"}
                                                    </span>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col xs={24} sm={24} md={10} lg={5} style={width > 576 ? { padding: 20 } : { paddingLeft: 20, paddingTop: 5, paddingRight: 20, paddingBottom: 14 }}>
                                            <Popconfirm
                                                className='btn-send-adjust-info'
                                                title="คุณยืนยันส่งผลตรวจหรือไม่ ?"
                                                okText={<span style={{ width: 50 }}>ใช่</span>}
                                                onConfirm={() => handleSendResult()}
                                                cancelText={<span style={{ width: 50 }}>ไม่ใช่</span>}
                                                disabled={isNotOpenSendAdjust ? true : (inspect.inspectionCourseId === 3 ? false : true)}
                                                style={{ width: "100%" }}
                                            >
                                                <Button
                                                    loading={loading}
                                                    disabled={isNotOpenSendAdjust ? true : (inspect.inspectionCourseId === 3 ? false : true)}
                                                    type="primary"
                                                    style={{ width: '100%', float: "right" }}
                                                >
                                                    {!loading ?
                                                        <div style={{ display: "flex", alignItems: "center" }}>
                                                            <Icon icon="bi:send" style={{ color: isNotOpenSendAdjust ? "#C3C3C3" : (inspect.inspectionCourseId === 3 ? "white" : "#C3C3C3") }} />
                                                            <label style={{ paddingLeft: 10, cursor: "pointer" }}>ส่งการปรับเเก้</label>
                                                        </div>
                                                        : []
                                                    }
                                                </Button>
                                            </Popconfirm>
                                        </Col>
                                        <Divider style={{ marginBottom: 0, marginTop: 0 }} />
                                        <Col span={24}>
                                            <article>
                                                {componentsSwitch(selectedMenuItem)}
                                            </article>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </article>
                    </div >
                    :
                    <LoadingPage loading={loading} />
                }
            </article>

            <Footer />

            <Modal
                maskClosable={false}
                closable={false}
                title={<label style={{ marginBottom: 0 }}><BsInfoCircle style={{ marginRight: 10, width: 22, height: 22, marginTop: -3, color: '#006FFF' }} />ส่งข้อมูลปรับเเก้เเล้ว คอร์สอยู่ระหว่างการตรวจสอบ</label>}
                visible={modalActive}
                onOk={() => {
                    setModalActive(false);
                }}
                onCancel={() => {
                    setModalActive(false);
                }}
                okButtonProps={{ style: { display: 'none' } }}
                cancelText={<label style={{ width: 50, cursor: 'pointer' }}>ปิด</label>}
            >
                <Row>
                    <Col span={24}>
                        ระยะเวลาการตรวจสอบจะใช้ระยะเวลา 1-3 วัน เเละจะเเจ้งผู้สอนให้ทราบทาง Email
                    </Col>
                    <Col span={24}>
                        หากมีเรื่องสอบถามหรือข้อคิดเห็น สามารถติดต่อเราได้ที่ 02-114-3464
                    </Col>
                </Row>
            </Modal>
        </div >
    )
}
