/* eslint-disable array-callback-return */
/* eslint-disable no-array-constructor */
/* eslint-disable react-hooks/exhaustive-deps */
import {
    Col,
    Form,
    Row,
    DatePicker,
    Input,
    Button,
    Table,
    Spin,
    Modal,
    Switch,
    Select,
    InputNumber,
    Radio,
    Checkbox
} from 'antd'
import { useEffect, useState, useRef } from 'react'
import moment from 'moment'
import { getCurrencyFetch } from "./../../../../common/API/coupon"
import { getCouponManageAllFetch, insertCouponManageFetch } from './../../../../modules/erp/manage/coupon/API' // modules/erp/manage/coupon/API
import { getCourseAllFetch } from "./../../../../modules/course/API"
import { getUserManageAllFetch } from "./../../../../modules/erp/manage/user/API"
import { Notification } from '../../../../common/components/notification'

const { TextArea } = Input
const { RangePicker } = DatePicker

const formatTime = "HH:mm"
const formatDateTime = "YYYY-MM-DD HH:mm"

const options = []
for (let i = 10; i < 36; i++) {
    const value = i.toString(36) + i
    options.push({
        label: `Long Label: ${value}`,
        value,
    })
}

export default function CouponManage(props) {
    const columnsฺ = [
        {
            title: 'No',
            dataIndex: 'index',
            width: "2%",
        },
        // {
        //     title: 'คอร์สที่ใช้ได้',
        //     dataIndex: 'courseName',
        //     align: "center",
        //     width: "6%",
        // },
        {
            title: 'รหัสคูปอง',
            dataIndex: 'code',
            width: "6%",
        },
        {
            title: 'ชื่อคูปอง',
            dataIndex: 'couponTitle',
            width: "6%",
        },
        // {
        //     title: 'รายละเอียดคูปอง',
        //     dataIndex: '',
        //     width: "6%",
        // },
        // {
        //     title: 'ส่วนลดเปอร์เซนต์',
        //     dataIndex: 'privilegePercentage',
        //     width: "6%",
        //     render: (text, record) => {
        //         return (
        //             <>{record.privilegePercentage ? `${record.privilegePercentage} %` : '-'}</>
        //         )
        //     }
        // },
        // {
        //     title: 'ส่วนลดจำนวน',
        //     dataIndex: 'privilegePrice',
        //     width: "6%",
        // },
        // {
        //     title: 'สกุลเงิน',
        //     dataIndex: 'currencyName',
        //     width: "6%",
        // },
        // {
        //     title: 'รูปเเบบของส่วนลด',
        //     dataIndex: 'couponPrivilegeTypeName',
        //     width: "6%",
        // },
        // {
        //     title: 'เจาะจงผู้ใช้',
        //     dataIndex: '',
        //     width: "6%",
        //     render: (text, record) => {
        //         let statusName = '' 
        //         let fontColor = ''
        //         if (record.isOnlyAuthorizedUid) {
        //             statusName = 'เฉพาะผู้ใช้ที่ระบุไว้'
        //             fontColor = 'green'
        //         } else {
        //             statusName = 'ใช้ได้กับผู้ใช้ทุกคน'
        //             fontColor = 'red'
        //         }
        //         return (
        //             <span style={{ color: fontColor }}>{statusName}</span>
        //         )
        //     }
        // },
        // {
        //     title: 'ผู้ใช่ที่ใช้คูปองได้',
        //     dataIndex: 'uidAuthorized',
        //     width: "6%",
        // },
        {
            title: 'จำนวนความจุสิทธิ์',
            dataIndex: 'permissionCapacity',
            width: "6%",
        },
        {
            title: 'จำนวนสิทธ์ใช้เเล้ว',
            dataIndex: 'permissionUsed',
            width: "6%",
        },
        {
            title: 'สถานะ',
            dataIndex: '',
            width: "6%",
            render: (text, record) => {
                let statusName = ''
                let fontColor = ''
                if (record.isActive) {
                    statusName = 'เปิดใช้งาน'
                    fontColor = 'green'
                } else {
                    statusName = 'ปิดใช้งาน'
                    fontColor = 'red'
                }
                return (
                    <span style={{ color: fontColor }}>{statusName}</span>
                )
            }
        },
        // {
        //     title: 'วันที่เริ่มต้นสิทธิ์',
        //     dataIndex: 'startDate',
        //     align: "center",
        //     width: "6%",
        //     render: (text, record) => {
        //         return (
        //             <div>
        //                 {moment(record.startDate).format('DD/MM/YYYY HH:mm:ss')}
        //             </div>
        //         )
        //     }
        // },
        {
            title: 'วันที่สิ้นสุดสิทธิ์',
            dataIndex: 'endDate',
            align: "center",
            width: "6%",
            render: (text, record) => {
                return (
                    <div>
                        {moment(record.endDate).format('DD/MM/YYYY HH:mm:ss')}
                    </div>
                )
            }
        },
        {
            title: 'วันที่สร้าง',
            dataIndex: 'createdAt',
            align: "center",
            width: "6%",
            render: (text, record) => {
                return (
                    <div>
                        {moment(record.createdAt).format('DD/MM/YYYY HH:mm:ss')}
                    </div>
                )
            }
        },
        {
            title: '',
            dataIndex: '',
            align: "center",
            width: "6%",
            render: (text, record) => {
                return (
                    <>
                        <Button
                            type="primary"
                            loading={loading}
                            onClick={() => {
                                console.log("record : ", record)
                                handleCouponDetailOpenModal("รายละเอียดคูปอง " + record.code, record)
                            }}
                        >
                            {!loading ? <label style={{ cursor: "pointer" }}>รายละเอียด</label> : []}
                        </Button>
                    </>
                )
            }
        }
    ]

    const [formSearch] = Form.useForm()
    const [formModify] = Form.useForm()

    const [list, setList] = useState([])
    const [currency, setCurrency] = useState([])
    const [course, setCourse] = useState([])
    const [user, setUser] = useState([])
    const [loading, setLoading] = useState(false)

    const paginate = 10
    const searchRef = useRef('')

    const [couponModal, setCouponModal] = useState({
        isShow: false,
        title: null,
    })
    const [userModal, setUserModal] = useState({
        isShow: false,
        title: null,
    })

    const [courseModal, setCourseModal] = useState({
        isShow: false,
        title: null,
    })

    const [couponDetailModal, setCouponDetailModal] = useState({
        isShow: false,
        title: null,
        data: null
    })


    const [selectedCourse, setSelectedCourse] = useState([])
    const [selectedUser, setSelectedUser] = useState([])

    const [privilegePercentageType, setPrivilegePercentageType] = useState(null)

    const [isOnlyAuthorizedUid, setIsOnlyAuthorizedUid] = useState(false)
    const [isAllCourseCanUsed, setIsAllCourseCanUsed] = useState(true)
    const [isActive, setIsActive] = useState(true)

    const [pageCurrent, setPageCurrent] = useState(1)

    const onPagine = (n) => {
        setPageCurrent(n.current)
    }

    // const getSearchCourse = async (value) => {
    //     // const result = await getSearchSuggestFetch(value, paginate)
    //     // setCourse(result)
    //     // let data = []
    //     // result?.map((val) => data.push(val.courseTitleName))
    //     // let searchResult = data.map((data) => {
    //     //     return {
    //     //         value: data,
    //     //         label: (
    //     //             <div style={{ display: 'flex', justifyContent: 'space-between' }}>
    //     //                 <span>{data}</span>
    //     //             </div>
    //     //         )
    //     //     }
    //     // })
    //     // setOptionCourse(value ? searchResult : [])
    // }

    // const getSearchUser = async () => {
    //     // let objRequest = {
    //     //     userName: null,
    //     //     startedDate: null,
    //     //     endDate: null
    //     // }
    //     // const result = await getUserManageAllFetch(objRequest)
    //     // setUser(result)
    //     // let tmpUser = result.map((val) => {
    //     //     return {
    //     //         value: val.userName,
    //     //         label: val.userName
    //     //     }
    //     // })
    //     // setOptionUser(tmpUser)
    // }

    const handleCouponOpenModal = (title) => {
        setCouponModal({
            isShow: true,
            title
        })
    }

    const handleCourseOpenModal = (title) => {
        setCourseModal({
            isShow: true,
            title
        })
    }

    const handleUserOpenModal = (title) => {
        setUserModal({
            isShow: true,
            title
        })
    }

    const handleCouponDetailOpenModal = (title, data) => {
        setCouponDetailModal({
            isShow: true,
            title,
            data
        })
    }

    const handleCouponCloseModal = () => {
        setCouponModal({
            isShow: false,
            title: null
        })
    }

    const handleCourseCloseModal = () => {
        setCourseModal({
            isShow: false,
            title: null
        })
    }

    const handleUserCloseModal = () => {
        setUserModal({
            isShow: false,
            title: null
        })
    }

    const handleCouponDetailCloseModal = () => {
        setCouponDetailModal({
            isShow: false,
            title: null,
            data: null
        })
    }

    const onModifyFinish = async (value) => {
        setLoading(true)

        let startDate = value.couponRange ? value.couponRange[0] : null
        let endDate = value.couponRange ? value.couponRange[1] : null

        let code = value.code ?? null

        let couponTitle = value.couponTitle ?? null
        let couponDescription = value.couponDescription ?? null

        let currencyId = value.currencyId ?? null

        let relateCourse = null
        if (!isAllCourseCanUsed) {
            if (selectedCourse?.length > 0) {
                relateCourse = ""
                selectedCourse.map((val, index) => {
                    relateCourse = (index === 0 ? "" + val : relateCourse + "," + val)
                })
            }
        }

        let uidAuthorized = null
        if (isOnlyAuthorizedUid) {
            if (selectedUser?.length > 0) {
                uidAuthorized = ""
                selectedUser.map((val, index) => {
                    uidAuthorized = (index === 0 ? val : uidAuthorized + "," + val)
                })
            }
        }

        let couponPrivilegeTypeId = (privilegePercentageType ? privilegePercentageType : null)
        let privilegePercentage = value.privilegePercentage ? Number(value.privilegePercentage) : null
        let privilegePrice = value.privilegePrice ? Number(value.privilegePrice) : null

        let permissionCapacity = Number(value.permissionCapacity)

        let obj = {
            startDate,
            endDate,
            relateCourse,
            couponTypeId: couponTitle,
            code,
            couponTitle,
            couponDescription,
            couponPrivilegeTypeId,
            privilegePercentage,
            privilegePrice,
            currencyId,
            isOnlyAuthorizedUid: uidAuthorized?.length > 0 ? true : false,
            uidAuthorized,
            permissionCapacity,
            permissionUsed: 0,
            isActive
        }

        // console.log("obj : ", obj)
        const result = await insertCouponManageFetch(obj)
        // console.log("insertCouponManageFetch : ", result)

        if (result.data.isSuccess) {
            // reload
            await getCouponManageAll({
                code: null,
                startedDate: null,
                endDate: null
            })
            formModify.resetFields()
            handleCouponCloseModal()
            Notification("success", "ระบบ", "เพิ่มคูปองสำเร็จ!")
        } else {
            Notification("error", "ระบบ", "ไม่สามารถเพิ่มคูปองได้ โปรดลองอีกครั้ง")
        }

        setLoading(false)
    }

    const onCourseChange = (checked) => {
        setSelectedCourse(checked)
    }

    const onUserChange = (checked) => {
        setSelectedUser(checked)
    }

    const onSearchFinish = async (value) => {
        setLoading(true)

        let code = value.code ?? null
        let startedDate = value.dateRange ? moment(value.dateRange[0]).format(formatDateTime) : null
        let endDate = value.dateRange ? moment(value.dateRange[1]).format(formatDateTime) : null

        let request = {
            code,
            startedDate,
            endDate
        }

        await getCouponManageAll(request)
        setLoading(false)
    }

    const getCouponManageAll = async (value) => {
        const result = await getCouponManageAllFetch(value)
        // console.log("getCouponManageAllFetch : ", result)
        setList(result)
    }

    const getCurrency = async () => {
        const result = await getCurrencyFetch()
        if (result?.length > 0) {
            let tmpCurrency = new Array()
            result.map(val => {
                tmpCurrency.push({
                    value: val.currencyId,
                    label: val.currencyName
                })
            })
            setCurrency(tmpCurrency)
        }
    }

    const getCourseAll = async () => {
        let objRequest = {
            isActive: true
        }
        const result = await getCourseAllFetch(objRequest)
        setCourse(result)
    }

    const getUserAll = async () => {
        let objRequest = {
            userName: null,
            startedDate: null,
            endDate: null
        }
        const result = await getUserManageAllFetch(objRequest)
        setUser(result)
    }

    const getBaseApi = async () => {
        setLoading(true)

        await getCouponManageAll({
            code: null,
            startedDate: null,
            endDate: null
        })
        await getCurrency()
        await getCourseAll()
        await getUserAll()

        setLoading(false)
    }

    useEffect(() => {
        getBaseApi()
    }, [])

    return (
        <>
            <Row gutter={[0, 12]}>
                <Col span={5}>
                    <label>จัดการคูปอง</label>
                </Col>

                <Col span={19}>
                    <Form layout="vertical" form={formSearch} onFinish={onSearchFinish}>
                        <div style={{ display: "flex", alignItems: "flex-start", justifyContent: "flex-end" }}>
                            <Form.Item name="dateRange" >
                                <RangePicker
                                    style={{ width: '100%' }}
                                    showTime={{ format: formatTime }}
                                    format={formatDateTime}
                                />
                            </Form.Item>

                            <div style={{ paddingLeft: 10 }}>
                                <Form.Item name="code" >
                                    <Input style={{ width: '100%' }} placeholder="รหัสคูปอง" />
                                </Form.Item>
                            </div>

                            <div style={{ paddingLeft: 10 }}>
                                <Button type="primary" onClick={() => formSearch.submit()}>
                                    ค้นหา
                                </Button>
                            </div>

                            {!couponModal.isShow ?
                                <div style={{ paddingLeft: 10 }}>
                                    <Button type="primary" onClick={() => handleCouponOpenModal("เพิ่มคูปอง")}>
                                        เพิ่มคูปอง
                                    </Button>
                                </div>
                                : []
                            }
                        </div>
                    </Form>
                </Col>

                {couponModal.isShow ?
                    <Col span={24}>
                        <label>{couponModal.title}</label>
                        <Row gutter={[12, 12]}>
                            <Col xs={24} lg={12}>
                                <Form form={formModify} layout="vertical" onFinish={onModifyFinish} >
                                    <Form.Item
                                        name="couponTitle"
                                        label="ชื่อคูปอง"
                                        style={{ width: '100%' }}
                                        rules={[{ required: true, message: 'กรุณากรอกชื่อคูปอง' }]}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Form>
                            </Col>

                            <Col xs={24} lg={12}>
                                <Form form={formModify} layout="vertical" onFinish={onModifyFinish} >
                                    <Form.Item
                                        name="code"
                                        label="รหัสคูปอง"
                                        style={{ width: '100%' }}
                                        rules={[{ required: true, message: 'กรุณากรอกรหัสคูปอง' }]}
                                    >
                                        <Input style={{ width: '100%' }} />
                                    </Form.Item>
                                </Form>
                            </Col>

                            <Col xs={24} lg={12}>
                                <Row>
                                    <Col span={24}>
                                        <Radio.Group
                                            onChange={(val) => {
                                                if (val.target.value === 1) {
                                                    formModify.setFieldValue('privilegePercentage', null)
                                                }

                                                if (val.target.value === 2) {
                                                    formModify.setFieldValue('privilegePrice', null)
                                                }

                                                setPrivilegePercentageType(val.target.value)
                                            }}
                                            value={privilegePercentageType}
                                        >
                                            <Radio value={1}>ส่วนลดเปอร์เซนต์</Radio>
                                            <Radio value={2}>ส่วนลดจำนวนราคา</Radio>
                                        </Radio.Group>
                                    </Col>
                                    <Col span={24}>
                                        <Form form={formModify} layout="vertical" onFinish={onModifyFinish} >
                                            <Form.Item
                                                name="privilegePercentage"
                                                label="ส่วนลดเปอร์เซนต์"
                                                style={{ width: '100%' }}
                                                rules={[{ required: (privilegePercentageType === 1 ? true : false), message: 'กรุณากรอกส่วนลดเปอร์เซนต์' }]}
                                            >
                                                <Input
                                                    disabled={privilegePercentageType !== 1 ? true : false}
                                                    addonAfter="%"
                                                />
                                            </Form.Item>
                                        </Form>
                                    </Col>
                                    <Col span={24}>
                                        <Form form={formModify} layout="vertical" onFinish={onModifyFinish} >
                                            <Form.Item
                                                name="privilegePrice"
                                                label="ส่วนลดจำนวน"
                                                style={{ width: '100%' }}
                                                rules={[{ required: (privilegePercentageType === 2 ? true : false), message: 'กรุณากรอกส่วนลดจำนวน' }]}
                                            >
                                                <Input
                                                    disabled={privilegePercentageType !== 2 ? true : false}
                                                    addonAfter="$"
                                                />
                                            </Form.Item>
                                        </Form>
                                    </Col>
                                </Row>
                            </Col>

                            <Col xs={24} lg={12}>
                                <Form form={formModify} layout="vertical" onFinish={onModifyFinish} >
                                    <Form.Item
                                        name="couponDescription"
                                        label="รายละเอียดคูปอง"
                                        style={{ width: '100%' }}
                                        rules={[{ required: true, message: 'กรุณากรอกรายละเอียดคูปอง' }]}
                                    >
                                        <TextArea rows={6} placeholder="maxLength is 6" maxLength={300} />
                                    </Form.Item>
                                </Form>
                            </Col>

                            <Col xs={24} lg={12}>
                                <Row>
                                    <Col span={24} style={{ paddingBottom: 8 }}>
                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                            <label style={{ paddingRight: 8 }}>คอร์สที่ใช้ได้</label>
                                            <Switch
                                                checkedChildren="ทั้งหมด"
                                                unCheckedChildren="ไม่ทั้งหมด"
                                                defaultChecked
                                                checked={isAllCourseCanUsed}
                                                onChange={() => {
                                                    setIsAllCourseCanUsed(!isAllCourseCanUsed)
                                                }}
                                            />
                                        </div>
                                    </Col>

                                    <Col span={24} >
                                        <div style={{ display: "flex", alignItems: "flex-start", justifyContent: "space-between" }}>
                                            {!isAllCourseCanUsed ?
                                                <div style={{ display: "flow", width: '100%' }}>
                                                    {selectedCourse?.map((val, index) => {
                                                        return (
                                                            <div style={{ backgroundColor: '#F5F5F5', padding: 5, borderBottom: selectedCourse?.length === (index + 1) ? '' : '1px solid #E3E2E2' }}>
                                                                {course.find(fnd => fnd.courseId === val)?.courseTitleName ?? '-'}
                                                            </div>
                                                        )
                                                    })}
                                                    {selectedCourse?.length === 0 ? <div style={{ backgroundColor: '#F5F5F5', padding: 5 }}>ยังไม่ได้เลือกคอร์ส</div> : []}
                                                </div>
                                                :
                                                <div style={{ backgroundColor: '#F5F5F5', padding: 5 }}>คูปองนำไปใช้กับคอร์สทั้งหมด</div>
                                            }

                                            <div style={{ paddingLeft: 10 }}>
                                                <Button
                                                    type="primary"
                                                    disabled={isAllCourseCanUsed}
                                                    onClick={() => {
                                                        handleCourseOpenModal('รายการคอร์ส')
                                                    }}
                                                >
                                                    <label style={{ cursor: "pointer" }}>ค้นหา</label>
                                                </Button>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>

                            <Col xs={24} lg={12}>
                                <Form form={formModify} layout="vertical" onFinish={onModifyFinish} >
                                    <Form.Item
                                        name="currencyId"
                                        label="สกุลเงิน"
                                        style={{ width: '100%' }}
                                        rules={[{ required: true, message: 'กรุณาเลือกสกุลเงิน' }]}
                                    >
                                        <Select
                                            showSearch
                                            style={{ width: '100%' }}
                                            placeholder="ค้นหาสกุลเงิน"
                                            optionFilterProp="children"
                                            filterOption={(input, option) => (option?.label ?? '').includes(input)}
                                            filterSort={(optionA, optionB) =>
                                                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                            }
                                            options={currency}
                                        />
                                    </Form.Item>
                                </Form>
                            </Col>

                            <Col xs={24} lg={12}>
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                    <label style={{ paddingRight: 8 }}>เจาะจงผู้ใช้</label>
                                    <Switch
                                        checkedChildren="ใช่"
                                        unCheckedChildren="ไม่ใช่"
                                        defaultChecked
                                        checked={isOnlyAuthorizedUid}
                                        onChange={() => setIsOnlyAuthorizedUid(!isOnlyAuthorizedUid)}
                                    />
                                </div>
                            </Col>
                            <Col xs={24} lg={12}>
                                <div style={{ display: "flex", alignItems: "flex-start", justifyContent: "space-between" }}>
                                    {isOnlyAuthorizedUid ?
                                        <div style={{ display: "flow", width: '100%' }}>
                                            {selectedUser?.map((val, index) => {
                                                return (
                                                    <div style={{ backgroundColor: '#F5F5F5', padding: 5, borderBottom: selectedUser?.length === (index + 1) ? '' : '1px solid #E3E2E2' }}>
                                                        {user.find(fnd => fnd.uid === val)?.userName ?? '-'}
                                                    </div>
                                                )
                                            })}
                                            {selectedUser?.length === 0 ? <div style={{ backgroundColor: '#F5F5F5', padding: 5 }}>ยังไม่ได้เลือกผู้ใช้</div> : []}
                                        </div>
                                        :
                                        <div style={{ backgroundColor: '#F5F5F5', padding: 5 }}>คูปองนำไปใช้กับผู้ใช้ทั้งหมด</div>
                                    }

                                    <div style={{ paddingLeft: 10 }}>
                                        <Button
                                            type="primary"
                                            disabled={!isOnlyAuthorizedUid}
                                            onClick={() => {
                                                handleUserOpenModal("รายการผู้ใช้")
                                            }}
                                        >
                                            <label style={{ cursor: "pointer" }}>ค้นหา</label>
                                        </Button>
                                    </div>
                                </div>
                            </Col>

                            <Col xs={24} lg={12}>
                                <Form form={formModify} layout="vertical" onFinish={onModifyFinish} >
                                    <Form.Item
                                        name="permissionCapacity"
                                        label="จำนวนความจุสิทธิ์"
                                        style={{ width: '100%' }}
                                        rules={[{ required: true, message: 'กรุณากรอกจำนวนความจุสิทธิ์' }]}
                                    >
                                        <InputNumber style={{ width: '100%' }} />
                                    </Form.Item>
                                </Form>
                            </Col>

                            {/* <Col xs={24} lg={12}>
                            <Form form={formModify} layout="vertical" onFinish={onModifyFinish} >
                                <Form.Item
                                    name="permissionUsed"
                                    label="จำนวนสิทธ์ใช้เเล้ว"
                                    style={{ width: '100%' }}
                                    rules={[{ required: true, message: 'กรุณากรอกจำนวนสิทธ์ใช้เเล้ว' }]}
                                >
                                    <InputNumber style={{ width: '100%' }} />
                                </Form.Item>
                            </Form>
                        </Col> */}

                            <Col xs={24} lg={12}>
                                <Form form={formModify} layout="vertical" onFinish={onModifyFinish} >
                                    <Form.Item
                                        name="couponRange"
                                        label="ช่วงเวลาในการใช้คูปอง"
                                        style={{ width: '100%' }}
                                        rules={[{ required: true, message: 'กรุณาเลือกช่วงเวลาในการใช้คูปอง' }]}
                                    >
                                        <RangePicker
                                            style={{ width: '90%' }}
                                            showTime={{ format: formatTime }}
                                            format={formatDateTime}
                                        />
                                    </Form.Item>
                                </Form>
                            </Col>

                            <Col xs={24} lg={12}>
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                    <label style={{ paddingRight: 8 }}>สถานะใช้งานคูปอง</label>
                                    <Switch
                                        checkedChildren="เปิด"
                                        unCheckedChildren="ปิด"
                                        defaultChecked
                                        checked={isActive}
                                        onChange={() => setIsActive(!isActive)}
                                    />
                                </div>
                            </Col>

                            <Col span={24}>
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                                    <div>
                                        <Button
                                            type="text"
                                            loading={loading}
                                            onClick={() => {
                                                handleCouponCloseModal()
                                            }}
                                        >
                                            {!loading ? <label style={{ cursor: "pointer" }}>ยกเลิก</label>: []}
                                        </Button>
                                    </div>

                                    <div style={{ paddingLeft: 10 }}>
                                        <Button
                                            type="primary"
                                            loading={loading}
                                            onClick={() => {
                                                formModify.submit()
                                            }}
                                        >
                                            {!loading ? <label style={{ cursor: "pointer" }}>เพิ่มคูปอง</label>: []}
                                        </Button>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    : []
                }

                <Col span={24}>
                    {!loading ?
                        <Table
                            loading={loading}
                            columns={columnsฺ}
                            dataSource={list}
                            pagination={{
                                current: pageCurrent,
                                pageSize: 10,
                                total: list?.length
                            }}
                            onChange={(n) => onPagine(n)}
                        >
                        </Table>
                        :
                        <center><Spin size="large" style={{ paddingTop: 200 }} /></center>
                    }
                </Col>
            </Row >

            <Modal
                maskClosable={false}
                title={courseModal.title}
                visible={courseModal.isShow}
                width={700}
                loading={loading}
                onCancel={() => {
                    handleCourseCloseModal()
                }}
                footer={[
                    <Button
                        type="primary"
                        loading={loading}
                        onClick={() => {
                            handleCourseCloseModal()
                        }}
                    >
                        {!loading ? <label style={{ cursor: "pointer" }}>ตกลง</label> : []}
                    </Button>
                ]}
            >
                <div style={{ height: 300, overflowY: "auto", overflowX: "hidden" }}>
                    <Checkbox.Group
                        resetFields={true}
                        style={{ width: '100%' }}
                        onChange={onCourseChange}
                    >
                        <Row gutter={[12, 12]}>
                            {course.map(val => {
                                return (
                                    <>
                                        <Col xs={12} lg={4} style={{ borderBottom: '1px solid #E3E2E2', paddingBottom: 10 }}>
                                            <Checkbox value={val.courseId}></Checkbox>
                                        </Col>
                                        <Col xs={12} lg={20} style={{ borderBottom: '1px solid #E3E2E2', paddingBottom: 10 }}>
                                            {val.courseTitleName}
                                        </Col>
                                    </>
                                )
                            })}
                        </Row>
                    </Checkbox.Group>
                </div>
            </Modal>

            <Modal
                maskClosable={false}
                title={userModal.title}
                visible={userModal.isShow}
                width={700}
                loading={loading}
                onCancel={() => {
                    handleUserCloseModal()
                }}
                footer={[
                    <Button
                        type="primary"
                        loading={loading}
                        onClick={() => {
                            handleUserCloseModal()
                        }}
                    >
                        <label style={{ cursor: "pointer" }}>ตกลง</label>
                    </Button>
                ]}
            >
                <div style={{ height: 300, overflowY: "auto", overflowX: "hidden" }}>
                    <Checkbox.Group
                        style={{ width: '100%' }}
                        onChange={onUserChange}
                    >
                        <Row gutter={[12, 12]}>
                            {user.map(val => {
                                return (
                                    <>
                                        <Col xs={12} lg={4} style={{ borderBottom: '1px solid #E3E2E2', paddingBottom: 10 }}>
                                            <Checkbox value={val.uid}></Checkbox>
                                        </Col>
                                        <Col xs={12} lg={20} style={{ borderBottom: '1px solid #E3E2E2', paddingBottom: 10 }}>
                                            {val.userName}
                                        </Col>
                                    </>
                                )
                            })}
                        </Row>
                    </Checkbox.Group>
                </div>
            </Modal>

            <Modal
                maskClosable={false}
                title={couponDetailModal.title}
                visible={couponDetailModal.isShow}
                width={700}
                loading={loading}
                onCancel={() => {
                    handleCouponDetailCloseModal()
                }}
                footer={[]}
            >
                {couponDetailModal.data ?
                    <Row gutter={[12, 12]}>
                        <Col xs={12} lg={6}>
                            <label>ชื่อคูปอง</label>
                        </Col>
                        <Col xs={12} lg={18}>
                            <label>{couponDetailModal.data.couponTitle}</label>
                        </Col>

                        <Col xs={12} lg={6}>
                            <label>รายละเอียดคูปอง</label>
                        </Col>
                        <Col xs={12} lg={18}>
                            <label>{couponDetailModal.data.couponDescription}</label>
                        </Col>

                        <Col xs={12} lg={6}>
                            <label>คอร์สที่ใช้ได้</label>
                        </Col>
                        <Col xs={12} lg={18}>
                            {couponDetailModal.data.relateCourse.length > 0 ?
                                <Row style={{ border: "1px solid #EEEEEE", paddingTop: 2, paddingBottom: 2, paddingLeft: 6, paddingRight: 6 }}>
                                    {couponDetailModal.data.relateCourse.map((val, index) => {
                                        return (
                                            <>
                                                <Col span={1}>
                                                    <label>({index + 1})</label>
                                                </Col>
                                                <Col span={23}>
                                                    <label>{val.courseTitleName}</label>
                                                </Col>
                                            </>
                                        )
                                    })}
                                </Row>
                                : "ใช้ได้กับทุกคอร์ส"
                            }
                        </Col>

                        <Col xs={12} lg={6}>
                            <label>ผู้ใช้งานที่ใช้ได้</label>
                        </Col>
                        <Col xs={12} lg={18}>
                            {couponDetailModal.data.uidAuthorized.length > 0 ?
                                <Row style={{ border: "1px solid #EEEEEE", paddingTop: 2, paddingBottom: 2, paddingLeft: 6, paddingRight: 6 }}>
                                    {couponDetailModal.data.uidAuthorized.map((val, index) => {
                                        return (
                                            <>
                                                <Col span={1}>
                                                    <label>({index + 1})</label>
                                                </Col>
                                                <Col span={23}>
                                                    <label>{val.userName}</label>
                                                </Col>
                                            </>
                                        )
                                    })}
                                </Row>
                                : "ใช้ได้กับทุก User"
                            }
                        </Col>

                        <Col xs={12} lg={6}>
                            <label>ส่วนลดเปอร์เซนต์</label>
                        </Col>
                        <Col xs={12} lg={18}>
                            <label>{couponDetailModal.data.privilegePercentage + " %" ?? "-"}</label>
                        </Col>

                        <Col xs={12} lg={6}>
                            <label>ส่วนลดจำนวนราคา</label>
                        </Col>
                        <Col xs={12} lg={18}>
                            <label>{couponDetailModal.data.privilegePrice ?? "-"}</label>
                        </Col>

                        <Col xs={12} lg={6}>
                            <label>สกุลเงิน</label>
                        </Col>
                        <Col xs={12} lg={18}>
                            <label>{couponDetailModal.data.currencyName}</label>
                        </Col>

                        <Col xs={12} lg={6}>
                            <label>วันที่เริ่ม</label>
                        </Col>
                        <Col xs={12} lg={18}>
                            <label>{moment(couponDetailModal.data.startDate).format("DD/MM/YYYY HH:mm")}</label>
                        </Col>

                        <Col xs={12} lg={6}>
                            <label>วันที่หมดอายุ</label>
                        </Col>
                        <Col xs={12} lg={18}>
                            <label>{moment(couponDetailModal.data.endDate).format("DD/MM/YYYY HH:mm")}</label>
                        </Col>

                        <Col xs={12} lg={6}>
                            <label>เเก้ไขล่าสุด</label>
                        </Col>
                        <Col xs={12} lg={18}>
                            <label>{moment(couponDetailModal.data.updatedAt).format("DD/MM/YYYY HH:mm")}</label>
                        </Col>

                        <Col xs={12} lg={6}>
                            <label>สถานะ</label>
                        </Col>
                        <Col xs={12} lg={18}>
                            {couponDetailModal.data.isActive ?
                                <label style={{ color: 'green' }}>เปิดใช้งาน</label>
                                :
                                <label style={{ color: 'red' }}>ปิดใช้งาน</label>
                            }
                        </Col>
                    </Row >
                    : []
                }
            </Modal >
        </>
    )
}
