import { 
    HTTP_COURSE_MANAGE_INTRO_FETCHING, 
    HTTP_COURSE_MANAGE_INTRO_SUCCESS, 
    HTTP_COURSE_MANAGE_INTRO_FAILED,
} from "../constants"

export const setCourseManageIntroStateToFetching = () => ({
    type: HTTP_COURSE_MANAGE_INTRO_FETCHING,
})

export const setCourseManageIntroStateToSuccess = (payload) => ({
    type: HTTP_COURSE_MANAGE_INTRO_SUCCESS,
    payload
})

export const setCourseManageIntroStateToFailed = () => ({
    type: HTTP_COURSE_MANAGE_INTRO_FAILED,
})