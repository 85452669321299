import { server } from "../../../constants"
import { httpClient } from "../../../utils/HttpClient"

const insertRequestOpenCourseFetch = async ( uid, value, isActive ) => { // Done
    try {
        const result = await httpClient.post(server.INSERT_FORM_REQUEST_OPEN_COURSE_URL, { uid, value, isActive })
        return result
    } catch (err) { // status 404
        return null
    }
}

const insertRequestOpenCourseTopicFetch = async ( uid, value, isActive, formRegOpenCourseId ) => { // Done
    try {
        const result = await httpClient.post(server.INSERT_FORM_REQUEST_OPEN_COURSE_TOPIC_URL, { uid, value, isActive, formRegOpenCourseId })
        return result
    } catch (err) { // status 404
        return null
    }
}

const getRequestOpenCourseAllFetch = async (value) => { // Done
    try {
        const result = await httpClient.post(server.GET_FORM_REQUEST_OPEN_COURSE_ALL_URL, { value })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getRequestOpenCourseByIdFetch = async (value, language) => { // Done
    try {
        const result = await httpClient.post(server.GET_INSPECTION_FORM_REQUEST_OPEN_COURSE_BY_ID_URL, { value, language })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const updateRequestOpenCourseFetch = async ( value ) => { // Done
    try {
        const result = await httpClient.put(server.UPDATE_FORM_REQUEST_OPEN_COURSE_URL, { value })
        return result
    } catch (err) { // status 404
        return null
    }
}

const updateRequestOpenCourseUploadedFetch = async ( value ) => { // Done
    try {
        const result = await httpClient.put(server.UPDATE_FORM_REQUEST_OPEN_COURSE_UPLOADED_URL, { value })
        return result
    } catch (err) { // status 404
        return null
    }
}

const deleteRequestOpenCourseFetch = async ( value ) => { // Done
    try {
        const result = await httpClient.delete(server.DELETE_FORM_REQUEST_OPEN_COURSE_URL + `/${value.formROCId}&${value.inspectId}` )
        return result
    }catch (err) { // status 404
        return null
    }
}

export {
    // get
    getRequestOpenCourseAllFetch,
    getRequestOpenCourseByIdFetch,

    // update
    updateRequestOpenCourseFetch,
    updateRequestOpenCourseUploadedFetch,

    // insert
    insertRequestOpenCourseFetch,
    insertRequestOpenCourseTopicFetch,

    // delete
    deleteRequestOpenCourseFetch
}