/* eslint-disable no-loop-func */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/alt-text */

import { useState, useEffect } from "react"
import "../chat/css/chatTeacher.css"
import "../chat/css/message.css"
import { BsFillBellFill } from "react-icons/bs"
import { useNavigate } from 'react-router-dom'
import {
    Avatar,
    Row,
    Col,
    Menu,
    Layout
} from "antd"
import { useLocation } from "react-router-dom"
import ChatItem from "./ChatItem"
import Footer from "../footer"
import Header from "../header"
import { getCourseByUserIdFetch, getCourseOwnerFetch } from "../../../modules/course/API"
import { useDimensions } from "../../mamager/ScreenManager"

const { Content } = Layout

export default function ChatTeacher({
    firebase,
    authorized,
    user
}) {
    const navigate = useNavigate()
    const db = firebase.firestore()

    const [chatRoomList, setChatRoomList] = useState([])
    const [roomId, setRoomId] = useState(null)

    const { width } = useDimensions()

    const location = useLocation()
    const params = new URLSearchParams(location.search)
    const courseOwner = params.get("course-owner")
    const toMember = params.get("to-member")

    const [courseId, setCourseId] = useState(params.get("course-id"))

    const genRoomAll = async () => {
        let queryRoom = null
        let queryMessage = null

        if (db) {
            queryRoom = db.collection("chat_room");
            queryRoom = queryRoom.where("chatRoomHeaderId", "==", user.uid)
            await queryRoom.onSnapshot(async (val1) => {
                let dataRoom = val1.docs.map((doc) => ({
                    ...doc.data(),
                    id: doc.id,
                }))

                // ---------------
                if (dataRoom?.length > 0) {
                    // get chat_message --------------------------------------------------------------------------
                    queryMessage = db.collection("chat_message").orderBy("createAt")
                    queryMessage = queryMessage.where("chatMessageHeaderId", "==", user.uid)
                    queryMessage = queryMessage.where("chatMessageIsReadStatus", "==", false)
                    await queryMessage.onSnapshot(async (val2) => {
                        let dataMessage = val2.docs.map((doc) => ({
                            ...doc.data(),
                            id: doc.id,
                        }))

                        // console.log("dataMessage : ", dataMessage)

                        let tempDataRoom = []
                        for (let i = 0; i < dataRoom.length; i++) {
                            let obj = {
                                courseId: dataRoom[i].chatCourseId,
                                uid: dataRoom[i].chatRoomHeaderId
                            }
                            let courseName = ""
                            let courseImage = ""
                            let resultCourse = await getCourseByUserIdFetch(obj)
                            if (resultCourse) {
                                courseName = resultCourse.courseTitleName
                                courseImage = resultCourse.courseImageLocation
                            }

                            let name = ""
                            let imageProfile = ""
                            let resultUserInfo = await getCourseOwnerFetch(dataRoom[i].chatRoomBuilderId)
                            // console.log("getCourseOwnerFetch : ", resultUserInfo)
                            if (resultUserInfo) {
                                name = resultUserInfo.userName
                                imageProfile = resultUserInfo.imageProfile
                            }

                            let tempBuilderWaitRead = dataMessage.filter(item => item.chatCourseId === dataRoom[i].chatCourseId && item.chatToMemberId === user.uid)
                            // console.log("tempBuilderWaitRead : ", tempBuilderWaitRead ? tempBuilderWaitRead.length : 0, user.uid)

                            tempDataRoom.push({
                                chatRoomId: dataRoom[i].chatRoomId,
                                chatCourseId: dataRoom[i].chatCourseId,
                                chatRoomName: dataRoom[i].chatRoomName,
                                isActive: dataRoom[i].isActive,
                                updateAt: dataRoom[i].updateAt,
                                chatRoomHeaderId: dataRoom[i].chatRoomHeaderId,                                                                 // for teacher
                                chatRoomBuilderId: dataRoom[i].chatRoomBuilderId,                                                               // for learner
                                chatRoomBuilderName:
                                    name?.length > 15 ? name?.substr(0, 15) + "..." : name,
                                chatRoomBuilderImageProfile: imageProfile,
                                chatRoomCourseName: courseName,
                                chatRoomCourseImage: courseImage,
                                waitRead: tempBuilderWaitRead ? tempBuilderWaitRead.length : 0
                            })
                        }
                        dataRoom = tempDataRoom

                        // console.log("dataRoom : ", dataRoom)
                        setChatRoomList(dataRoom)
                    });
                }
                // ---------------
            })
        }
    }

    const detectOwnerChat = () => {
        if (user.uid !== courseOwner || user.uid !== toMember) {
            navigate("/")
        }
        return false
    }

    useEffect(() => {

        detectOwnerChat()
        genRoomAll()

        // Detect listener
        return navigate((location) => {
            localStorage.setItem('toggleTabChat', 1) // off snap
            localStorage.setItem('lsRoomId', null)
        })
    }, [db,])

    return (
        <div>
            <Header
                firebase={firebase}
                authorized={authorized}
                user={user}
            />

            <article>
                <Content>
                    <div
                        style={
                            width > 1500 ?
                                { backgroundColor: "white", marginLeft: '5%', marginRight: '5%' }
                                :
                                (
                                    (width > 912 && width <= 1500) ?
                                        { backgroundColor: "white", marginLeft: '5%', marginRight: '5%' }
                                        :
                                        { backgroundColor: "white", marginLeft: '0%', marginRight: '0%' }
                                )
                        }
                    >
                        <Row>
                            <Col
                                span={24}
                                style={{
                                    borderLeft: '1px solid #DDDDDD',
                                    borderRight: '1px solid #DDDDDD',
                                    borderTop: '1px solid #DDDDDD',
                                    paddingTop: 15, paddingLeft: 20, paddingRight: 15, paddingBottom: 10
                                }}>
                                <label>กล่องข้อความ : ผู้สอน</label>
                            </Col>
                            <Col span={24}>
                                <div style={{ display: "flex", alignItems: "flex-start" }}>
                                    <div style={{ overflow: "auto", height: 477, width: width > 912 ? 350 : 150, borderBottom: '1px solid #DDDDDD' }}>
                                        <Menu
                                            style={{ border: '1px solid #DDDDDD' }}
                                            onClick={(e) => {
                                                setCourseId(chatRoomList?.find(item => item.chatRoomId === e.key)?.chatCourseId)
                                                localStorage.setItem('toggleTabChat', 3)
                                                localStorage.setItem('lsRoomId', e.key)
                                                setRoomId(e.key)
                                            }}
                                        >
                                            {chatRoomList.map(item =>
                                                <Menu.Item
                                                    key={item.chatRoomId}
                                                    style={{
                                                        height: item.waitRead > 0 ? (width > 912 ? 85 : 50) : 50,
                                                        borderBottom: '1px solid #DDDDDD'
                                                    }}
                                                >
                                                    <div style={{ display: "flex", alignItems: "center" }}>
                                                        <div style={{ display: "flex", alignItems: "center" }}>
                                                            {width > 912 ? <Avatar style={{ height: 45, width: 45, borderColor: "#EEEEEE", borderWidth: 1 }} src={item.chatRoomCourseImage} /> : []}
                                                            <div style={{ paddingLeft: width > 912 ? 10 : 0 }}>
                                                                <Avatar style={{ height: 45, width: 45, borderColor: "#EEEEEE", borderWidth: 1 }} src={item.chatRoomBuilderImageProfile} />
                                                            </div>
                                                        </div>

                                                        {width > 912 ?
                                                            <Row gutter={[0, 0]} style={{ paddingLeft: 10 }}>
                                                                <Col span={24} style={{ display: "flex", alignItems: "center" }}>
                                                                    <label style={{ cursor: "pointer" }}>{item.chatRoomBuilderName}</label>
                                                                </Col>

                                                                {item.waitRead > 0 ?
                                                                    <Col span={24} style={{ display: "flex", alignItems: "center" }}>
                                                                        <div style={{ display: "flex", alignItems: "center" }}>
                                                                            <BsFillBellFill style={{ color: "green", cursor: "pointer" }} />
                                                                            <label style={{ cursor: "pointer" }}>{item.waitRead}</label>
                                                                        </div>
                                                                    </Col>
                                                                    : []
                                                                }
                                                            </Row>
                                                            : []
                                                        }
                                                    </div>
                                                </Menu.Item>
                                            )}
                                        </Menu>
                                    </div>

                                    <div style={{ display: "grid", alignItems: "center", width: '100%' }}>
                                        <ChatItem
                                            db={firebase.firestore()}
                                            learnerId={user.uid}
                                            teacherId={courseOwner}
                                            courseId={courseId}
                                            chatType="teacher"
                                            roomId={roomId}
                                        />
                                    </div>
                                </div>

                            </Col>
                        </Row>
                    </div>
                </Content>
            </article>

            <Footer />
        </div>
    )
}