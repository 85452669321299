import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/analytics'
import 'firebase/firestore'

const firebaseConfig = {
    apiKey: "AIzaSyCK-aSnFPLEiFt4OyxwolYIJmVTp-sP51c",
    authDomain: "cloud.toflyn.com",
    projectId: "cloud-toflyn",
    storageBucket: "cloud-toflyn.appspot.com",
    messagingSenderId: "667374851841",
    appId: "1:667374851841:web:172fa5a6f3f729cbcf6d00",
    measurementId: "G-JY8KE32XNT"
}

firebase.initializeApp(firebaseConfig)
const auth = firebase.auth()
firebase.analytics()
export { auth }
export default firebase
