import { server } from "../../../../constants"
import { httpClient } from "../../../../utils/HttpClient"
import { Notification } from "../../notification"

const getFeedbackFetch = async () => {
    try {
        const result = await httpClient.get(server.GET_FEEDBACK_URL)
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const insertFeedbackFetch = async (feedbackName, uid) => {
    try {
        const result = await httpClient.post(server.INSERT_FEEDBACK_URL, { feedbackName, uid })
        if (result.data.isSuccess) {
            Notification("success", "ระบบ", "บันทึกความคิดเห็นเรียบร้อย")
        } else {
            Notification("error", "ระบบ", "เพิ่มความคิดเห็นไม่สำเร็จ กรุณาลองใหม่อีกครั้ง")
        }
    } catch (err) { // status 404
        return null
    }
}

export {
    // get
    getFeedbackFetch,

    // insert
    insertFeedbackFetch
}