import { useRef, useEffect } from 'react'

function usePrevious<T>(value: T): T | undefined {
    // ใช้ null หรือ undefined เป็นค่าเริ่มต้นเพื่อให้ตรงกับความคาดหวังของ TypeScript
    const ref = useRef<T | undefined>(undefined);

    // Store current value in ref
    useEffect(() => {
        ref.current = value;
    }, [value]); // Only re-run if value changes

    // Return previous value (happens before update in useEffect above)
    return ref.current;
}

export default usePrevious;
