/* eslint-disable react-hooks/exhaustive-deps */
import {
    Form,
    Input,
    Button,
    Row,
    Col,
    Card
} from 'antd'
import { useDispatch, useSelector } from "react-redux"

// import firebase from 'firebase/compat/app'
import firebase from 'firebase/app'

import { Icon } from '@iconify/react'
import { setRegisterCurrentStepStateToSuccess } from '../../../../actions/registerCurrentStep.action'
import { setRegisterInformationSuccess } from '../../../../actions/registerInformation.action'
import { detectEmail, detectFormatPassword, detectUsername } from '../../../../common/mamager/DetectManager'

export default function Inforation(props) {
    const dispatch = useDispatch()

    const [form] = Form.useForm()

    const { resultRegisterCurrentStep } = useSelector(({ registerCurrentStepReducer }) => registerCurrentStepReducer)
    const { resultRegisterInformation } = useSelector(({ registerInformationReducer }) => registerInformationReducer)
    const providerId = props.auth.providerId;

    const businessOperation = resultRegisterInformation.businessOperation ? resultRegisterInformation.businessOperation : props.businessOperation

    const initial = {
        userName: resultRegisterInformation.userName ?
            resultRegisterInformation.userName
            :
            (providerId === "email/password" ? undefined : firebase.auth().currentUser.displayName),
        passWord: resultRegisterInformation.passWord,
        passWordAgain: resultRegisterInformation.passWordAgain,
        email: resultRegisterInformation.email ?
            resultRegisterInformation.email
            :
            (providerId === "email/password" ? undefined : firebase.auth().currentUser.email),
    }

    const onFinish = (values) => {
        let isNextStep = true
        if (providerId === "email/password") {
            if (values.passWord !== values.passWordAgain) {
                isNextStep = false
            }
        }

        if (isNextStep) {
            const object = {
                userName: values.userName,
                passWord: values.passWord,
                passWordAgain: values.passWordAgain,

                email: providerId === "email/password" ? values.email : initial.email,
                phone: values.phone,

                firstName: values.firstName,
                lastName: values.lastName,
                birthday: null,
                gender: values.gender,

                businessOperation: values.businessOperation,
                companyNameTH: values.companyNameTH,
                companyNameENG: values.companyNameENG
            }

            // reducer
            dispatch(setRegisterCurrentStepStateToSuccess(Number(resultRegisterCurrentStep) + 1))
            dispatch(setRegisterInformationSuccess(object))
        } else {
            Notification('warning', 'รหัสผ่านไม่ตรงกัน')
        }
    }

    return (
        <>
            <Row gutter={[24, 0]}>
                <Col xs={24} xl={12}>
                    <Card>
                        <Row gutter={[16, 0]}>
                            <Col span={24}>
                                <Form form={form} layout="vertical" initialValues={initial} onFinish={onFinish} >
                                    <Form.Item
                                        label="ชื่อผู้ใช้งาน"
                                        name="userName"
                                        style={{ width: '100%' }}
                                        rules={[{ required: true, validator: detectUsername }]}
                                    >
                                        <Input onBlur={(e) => form.setFieldsValue({ username: e.target.value })} />
                                    </Form.Item>
                                </Form>
                            </Col>

                            {providerId === "email/password" ?
                                <>
                                    <Col span={24}>
                                        <Form form={form} layout="vertical" initialValues={initial} onFinish={onFinish} >
                                            <Form.Item
                                                label="รหัสผ่าน"
                                                name="passWord"
                                                style={{ width: '100%' }}
                                                rules={[{ required: true, validator: detectFormatPassword }]}
                                            >
                                                <Input.Password onBlur={(e) => form.setFieldsValue({ username: e.target.value })} />
                                            </Form.Item>
                                        </Form>
                                    </Col>

                                    <Col span={24}>
                                        <Form form={form} layout="vertical" initialValues={initial} onFinish={onFinish} >
                                            <Form.Item
                                                label="รหัสผ่านอีกครั้ง"
                                                name="passWordAgain"
                                                style={{ width: '100%' }}
                                                rules={[{ required: true, validator: detectFormatPassword }]}
                                            >
                                                <Input.Password onBlur={(e) => form.setFieldsValue({ username: e.target.value })} />
                                            </Form.Item>
                                        </Form>
                                    </Col>
                                </>
                                : []
                            }

                            {providerId === "email/password" ?
                                <Col span={24}>
                                    <Form form={form} layout="vertical" initialValues={initial} onFinish={onFinish} >
                                        <Form.Item
                                            label={businessOperation === 1 ? "อีเมล" : "อีเมลบริษัท"}
                                            name="email"
                                            rules={[{ required: true, validator: detectEmail }]}
                                        >
                                            <Input
                                                disabled={providerId === "google.com" ? (props.auth.email ? true : false) : false}
                                                style={{ width: '100%' }}
                                            />
                                        </Form.Item>
                                    </Form>
                                </Col>
                                : []
                            }
                        </Row>
                    </Card>
                </Col>

                <Col xs={24} xl={12}></Col>

                <Col className="gutter-row" span={24} style={{ paddingTop: 15 }}>
                    <Button
                        style={{ float: 'right', width: 130 }}
                        type="primary"
                        onClick={() => form.submit()}
                    >
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Icon icon="fluent:arrow-next-20-filled" style={{ color: "white" }} />
                            <label style={{ paddingLeft: 10, cursor: "pointer" }}>ถัดไป</label>
                        </div>
                    </Button>
                </Col>
            </Row>
        </>
    )
}