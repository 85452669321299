import "../../css/search.css"
import { useEffect, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import { Form, Row, Col, Select, AutoComplete, Button } from 'antd'
import { Icon } from '@iconify/react'
import { getSearchSuggestFetch } from "../../API"
import { useDimensions } from "../../../../common/mamager/ScreenManager"

const { Option } = Select

export default function CourseSearch(props) {

    const [form] = Form.useForm()
    const { width } = useDimensions()

    const initial = {
        category: props.search ? props.search.category : undefined,
        rating: props.search ? props.search.rating : undefined,
        text: props.search ? props.search.text : undefined,
    }
    const paginate = 10
    const searchRef = useRef('')

    const navigate = useNavigate()

    const [options, setOptions] = useState([])

    const [scrollTop, setScrollTop] = useState(0)

    const getSearch = async (value) => {
        const result = await getSearchSuggestFetch(value, paginate)

        let data = []
        result?.map((val) => data.push(val.courseTitleName))
        let searchResult = data.map((data) => {
            return {
                value: data,
                label: (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                    >
                        <span>{data}</span>
                    </div>
                )
            }
        })
        setOptions(value ? searchResult : [])
    }

    const handleSearch = async (value) => {
        await getSearch(value)
    }

    const onFinish = (values) => {
        let text;
        if (searchRef.current.length) {
            text = searchRef.current
        } else {
            text = ''
        }

        let courseRating;
        if (values.rating) {
            courseRating = values.rating
        } else {
            courseRating = 1
        }

        let courseGroupId;
        if (values.category) {
            courseGroupId = values.category
        } else {
            courseGroupId = null
        }

        if (props.isRedirectUrl) {
            navigate("/course-list" +
                "?course-category=" + courseGroupId
                + "&course-text=" + text
                + "&course-rating=" + courseRating
                + "&is-search=" + true
            )
        } else {
            props.handleCourseSearch({
                category: courseGroupId,
                rating: courseRating,
                text: text,
                search: true
            })
        }

        searchRef.current = ''
    }

    const sizeLayout_L_R = (type) => {
        let w = width
        let L = w - (width >= 992 ? 105 : ((width >= 768 && width < 992) ? 140 : 70))
        let LP = (L * 100) / w
        let RP = 100 - LP
        if (type === "LP") {
            let lp = LP + "%"
            return lp
        } else if (type === "RP") {
            let rp = RP + "%"
            return rp
        }
    }

    const handleScroll = () => {
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop
        setScrollTop(scrollTop)
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll)
        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [])

    return (
        <div
            style={
                props.pageName === "main" && scrollTop > 300 ?
                    {
                        paddingTop: 15,
                        backgroundColor: '#E5E5E5',
                        position: 'fixed',
                        width: "100%",
                        top: 50,
                        zIndex: 3,
                        overflow: 'auto',
                    }
                    :
                    {
                        paddingTop: 15,
                        backgroundColor: '#E5E5E5',
                    }
            }
        >
            <Form
                layout="horizontal"
                onFinish={onFinish}
                initialValues={initial}
                form={form}
            >
                <Row style={width >= 1500 ? { paddingLeft: '5%', paddingRight: '5%' } : ((width > 912 && width < 1500) ? { paddingLeft: '5%', paddingRight: '5%' } : { paddingLeft: '0%', paddingRight: '0%' })}>
                    {width > 576 ?
                        <>
                            <Col
                                xs={24} sm={12} md={7} lg={6} xl={5} xxl={4}
                                style={{
                                    paddingLeft: width >= 1500 ? 5 : ((width > 992 && width < 1500) ? 5 : 15),
                                    paddingRight: (width > 992 ? 15 : 7.5)
                                }}
                            >
                                <Form.Item name="rating">
                                    <Select
                                        showSearch
                                        style={{ width: '100%', marginBottom: -10 }}
                                        placeholder="เลือกลำดับ"
                                        optionFilterProp="children"
                                        allowClear
                                        size="large"
                                    >
                                        {props.courseRating?.map((val, index) =>
                                            <Option key={index} value={val.value}>{val.label}</Option>
                                        )}
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col xs={24} sm={12} md={7} lg={6} xl={5} xxl={4} style={{ paddingLeft: (width > 992 ? 15 : 7.5), paddingRight: ((width >= 768 && width < 992) ? 7.5 : 15) }}>
                                <Form.Item name="category">
                                    <Select
                                        showSearch
                                        style={{ width: '100%', marginBottom: -10 }}
                                        placeholder="เลือกหมวดหมู่"
                                        optionFilterProp="children"
                                        allowClear
                                        size="large"
                                    >
                                        {props.courseCategory?.map((val, index) =>
                                            <Option key={index} value={val.value}>{val.label}</Option>
                                        )}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </>
                        : []
                    }

                    <Col xs={24} sm={24} md={10} lg={12} xl={14} xxl={16}
                        style={{
                            paddingLeft: (width >= 768 && width < 992) ? 7.5 : 15,
                            paddingRight: width >= 1500 ? 5 : ((width > 992 && width < 1500) ? 5 : 15),
                            paddingBottom: 15
                        }}
                    >
                        <Button
                            type="primary"
                            style={{
                                width: sizeLayout_L_R("RP"),
                                borderRadius: "0px 10px 10px 0px",
                                float: "right"
                            }}
                            size="large"
                            onClick={(e) => {
                                e.preventDefault()
                                form.submit()
                            }}
                        >
                            <Icon icon="bi:search" style={{ color: "white", width: 15, height: 15, marginTop: 5.5 }} />
                        </Button>

                        <div className="search-auto-complete">
                            <AutoComplete
                                onChange={(e) => searchRef.current = e}
                                ref={searchRef}
                                dropdownMatchSelectWidth={252}
                                style={{
                                    width: (width > 992 ? "45%" : (sizeLayout_L_R("LP"))),
                                    float: "right",
                                    borderRadius: "0px 0px 0px 0px"
                                }}
                                placeholder="ค้นหาชื่อคอร์ส"
                                options={options}
                                size="large"
                                onSearch={handleSearch}
                            />
                        </div>
                    </Col>
                </Row>
            </Form>
        </div>
    )
}
