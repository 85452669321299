/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */

import { useState, useEffect } from "react"
import { useLocation } from "react-router-dom"
import "../coursShop/css/courseShop.css"
import {
    Layout,
    Row,
    Col,
    Modal
} from 'antd'
import { Icon } from '@iconify/react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from "react-redux"
import { useDimensions } from "../../common/mamager/ScreenManager"
import { setOrderPaymentChapterAndVideoCourseSuccess } from "../../actions/orderPaymentChapterAndVideoCourse.action"
import { calculateOrderPaymentAmount } from "../payment/manager/PaymentManager"
import CourseOverview from "../course/components/overview"
import CourseVideo from "../course/components/video"
import Review from "../review"
import Header from "../../common/components/header"
import CourseMediaIntro from "../course/components/mediaIntro"
import CourseCRL from "../../common/components/crl"
import CourseProperty from "../course/components/property"
import Footer from "../../common/components/footer"
import LoadingPage from "../../common/components/loading/page"
import {
    getCourseDocumentationFetch,
    getCourseByIdFetch,
    getCourseMediaIntroFetch,
    getCourseOwnerFetch,
    getCourseUpdateViewCourseFetch,
    getVideoGroupFetch
} from "../course/API"
import { getDetectCourseHasBeenPurchasedFetch } from "./API"
import { sumTime } from "../../common/mamager/TimeManager"
import { detectSignIn } from "../../common/mamager/DetectManager"

const { Content } = Layout

export default function CourseShop(props) {

    const language = "th"
    const { width } = useDimensions()

    const dispatch = useDispatch()

    const { resultOrderPaymentChapterAndVideoCourse } = useSelector(({ orderPaymentChapterAndVideoCourseReducer }) => orderPaymentChapterAndVideoCourseReducer)

    const navigate = useNavigate()
    const location = useLocation()
    const params = new URLSearchParams(location.search)
    const courseId = params.get("course-id")

    const [isPauseVideo, setIsPauseVideo] = useState(true)

    const [course, setCourse] = useState(null)
    const [courseMediaIntro, setCourseMediaIntro] = useState([])
    const [coureDocumentation, setCourseDocumentation] = useState([])
    const [coureVideoGroup, setCourseVideoGroup] = useState([])

    const [isCourseHasBeenPurchased, setIsCourseHasBeenPurchased] = useState(false)

    const [loading, setLoading] = useState(true)

    const [orderPaymentAmount, setOrderPaymentAmount] = useState({
        totalAmount: 0,
        totalPrice: 0
    })

    const [courseOwner, setCourseOwner] = useState([])

    const [modalRenovate, setModalRenovate] = useState(false)

    const handlePauseVideo = (status) => {
        setIsPauseVideo(status)
    }

    const getCourse = async () => {
        const resultCourse = await getCourseByIdFetch(courseId, language) // params is courseId, language
        setCourse(resultCourse)

        if (resultCourse.courseId >= 1 && resultCourse.courseId <= 10) {
            setModalRenovate(true)
        } else {
            const isLogin = await detectSignIn()
            if (isLogin) {
                await getDetectCourseHasBeenPurchased(resultCourse)
            }
            await getCourseUpdateViewCourse(resultCourse)

            const resultCourseOwner = await getCourseOwnerFetch(resultCourse?.uid)
            setCourseOwner(resultCourseOwner)
        }
        return resultCourse
    }

    const getCourseMediaIntro = async () => {
        const resultCourseMediaIntro = await getCourseMediaIntroFetch(courseId, language)
        setCourseMediaIntro(resultCourseMediaIntro)
    }

    const getCourseDocumentation = async () => {
        const courseDocumentation = await getCourseDocumentationFetch(courseId)
        setCourseDocumentation(courseDocumentation)
    }

    const getVideoGroup = async () => {
        let obj = {
            uid: props.user ? props.user.uid : null,
            courseId,
            language,
        }
        const result = await getVideoGroupFetch(obj)
        // console.log("getVideoGroupFetch : ", result)
        setCourseVideoGroup(result ? result : [])
    }

    const getDetectCourseHasBeenPurchased = async (course) => {
        let obj = {
            learnerId: props.user ? props.user.uid : null,
            courseId: course.courseId,
            isActive: 1,
        }
        const resultLearnerCourse = await getDetectCourseHasBeenPurchasedFetch(obj)
        if (resultLearnerCourse) {
            setIsCourseHasBeenPurchased(true)
        } else {
            setIsCourseHasBeenPurchased(false)
        }
    }

    const getCourseUpdateViewCourse = async (value) => {
        // plus one view of video
        let obj = {
            courseId: value.courseId,
            visitorId: props.user ? props.user.uid : null,
            courseOwnerId: value?.uid,
        }
        await getCourseUpdateViewCourseFetch(obj)
    }

    const getRequestBaseApi = async () => {
        setLoading(true)

        await getCourse()
        await getCourseMediaIntro()
        await getCourseDocumentation()
        await getVideoGroup()

        // set Default
        if (resultOrderPaymentChapterAndVideoCourse.courseId !== Number(courseId) && courseId !== null) {
            dispatch(setOrderPaymentChapterAndVideoCourseSuccess({
                video: [],
                chapter: [],
                courseId: null
            }))
        } else {
            setOrderPaymentAmount(calculateOrderPaymentAmount(resultOrderPaymentChapterAndVideoCourse.video))
        }


        setLoading(false)
    }

    const reloadOderPayment = (data) => {
        setOrderPaymentAmount(calculateOrderPaymentAmount(data))
    }

    useEffect(() => { // authen
        getRequestBaseApi()
    }, [])

    useEffect(() => {

    }, [isPauseVideo])

    useEffect(() => {

    }, [orderPaymentAmount])

    const genCourseOverview = () => {
        return (
            <CourseOverview
                course={course}
                totalVideoTime={sumTime(coureVideoGroup?.video)}
                video={coureVideoGroup?.video}
                chapter={coureVideoGroup?.chapter}
                courseId={course.courseId}
                isPaymentByVideo={course?.isPaymentByVideo}
                courseOwner={courseOwner}
                authorized={props.authorized}
                user={props.user}
                isCourseHasBeenPurchased={isCourseHasBeenPurchased}
                orderPaymentAmount={orderPaymentAmount}
                reloadOderPayment={reloadOderPayment}
                pageName="course-shop"
            />
        )
    }

    const genCourseVideoList = () => {
        return (
            <CourseVideo
                coureVideoGroup={coureVideoGroup}
                course={course}
                handlePauseVideo={handlePauseVideo}
                isCourseHasBeenPurchased={isCourseHasBeenPurchased}
                reloadOderPayment={reloadOderPayment}
                pageName="course-shop"
            />
        )
    }

    const genMenuRight = () => {
        return (
            <div>
                {genCourseOverview()}

                {width >= 992 ?
                    <Review
                        courseId={course.courseId}
                        type="basic"
                    />
                    : []
                }

                {genCourseVideoList()}
            </div>
        );
    }

    return (
        <>
            <Header
                firebase={props.firebase}
                authorized={props.authorized}
                user={props.user}
            />

            <article>
                {!loading ?
                    <Content style={{ backgroundColor: "#F5F5F5" }}>
                        <div style={width >= 1500 ? { paddingLeft: '5%', paddingRight: '5%' } : { paddingLeft: '0%', paddingRight: '0%' }}>
                            <Row>
                                <Col xs={24} lg={16} style={{ backgroundColor: "#0c0c0c" }}>
                                    {width < 992 ? <div style={{ float: "none", borderBottom: "1px solid rgb(80, 80, 80)" }}>{genCourseOverview()}</div> : []}
                                    <CourseMediaIntro
                                        course={course}
                                        isPauseVideo={isPauseVideo}
                                        courseMediaIntro={courseMediaIntro[0]}
                                        coureVideoGroup={coureVideoGroup}
                                        handlePauseVideo={handlePauseVideo}
                                        isCourseHasBeenPurchased={isCourseHasBeenPurchased}
                                    />

                                    {course?.learnerCount > 0 ?
                                        <CourseCRL
                                            learnerCount={course?.learnerCount}
                                            pageName="course-shop"
                                            color={"black"}
                                        />
                                        : []
                                    }

                                    <CourseProperty
                                        course={course}
                                        coureDocumentation={coureDocumentation}
                                        authorized={props.authorized}
                                        firebase={props.firebase}
                                        user={props.user}
                                        courseOwner={course?.uid}
                                        courseId={course.courseId}
                                        pageName="course-shop"
                                    />

                                    <amp-ad width="100vw" height="320"
                                        type="adsense"
                                        data-ad-client="ca-pub-3065301527684417"
                                        data-ad-slot="1451461973"
                                        data-auto-format="rspv"
                                        data-full-width="">
                                        <div overflow=""></div>
                                    </amp-ad>

                                    {width < 992 ? <Review courseId={course.courseId} type="basic" /> : []}

                                </Col>

                                {width >= 992 ?
                                    <Col xs={24} lg={8}>
                                        {genMenuRight()}
                                    </Col>
                                    : []
                                }
                            </Row>
                        </div>
                    </Content>
                    :
                    <LoadingPage loading={loading} />
                }
            </article>

            <Footer />

            <Modal
                maskClosable={false}
                title={
                    <>
                        <Icon icon="iconoir:warning-circled-outline" style={{ color: "#23b14d", width: 20, height: 20 }} />
                        <label style={{ paddingLeft: 10 }}>ขออภัยในความไม่สะดวก</label>
                    </>
                }
                visible={modalRenovate}
                width={600}
                centered
                onCancel={() => {
                    // close modal
                    navigate("/")
                }}
                footer={false}
            >   <center>
                    <Icon icon="file-icons:renovate" style={{ color: "#23b14d", width: 200, height: 200 }} /><br />
                    <label style={{ paddingTop: 10, fontSize: 18, color: "#23b14d", fontWeight: "bold" }}>คอร์สนี้อยู่ระหว่างการปรับปรุงจากผู้สอน</label><br />
                    <label style={{ paddingTop: 10, fontSize: 18, color: "#23b14d", fontWeight: "bold" }}>เเละจะเปิดให้เข้าขมเร็ว ๆ นี้</label>
                </center>
            </Modal>
        </>
    )
}