import './fonts.css'

const themeAntd = {
    colorPrimary: "#23B14D",
    fontSize: 14,
    fontFamily: "Kanit",

    rateStarColor: "white", // สีของดาวที่ยังไม่ได้เลือก
    rateStarSelectedColor: "#23B14D", // สีของดาวที่ถูกเลือก
    rateStarSelectedBg: "transparent", // สีพื้นหลังของดาวที่ถูกเลือก
}

export default themeAntd