/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useMemo, useState } from 'react'
import {
    Steps,
    Layout,
    Divider
} from 'antd'
import { Content } from 'antd/lib/layout/layout'
import "../css/create.css"
import {
    useDispatch,
    useSelector
} from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"
import { setCourseManageAddCurrentStepStateToSuccess } from '../../../actions/courseManageAddCurrentStep.action'
import Footer from '../../../common/components/footer'
import Header from '../../../common/components/header'
import { useDimensions } from '../../../common/mamager/ScreenManager'
import ManageDescript from '../components/descript'
import ManageDocument from '../components/document'
import ManagePrice from '../components/price'
import ManageVideo from '../components/video'
import ManageMediaIntro from '../components/mediaIntro'
import ManageCard from '../components/card'
import { detectRepeatOpenCourseTokenFetch } from '../../erp/API'

const { Step } = Steps

export default function CourseCreate(props) {

    const navigate = useNavigate()
    const location = useLocation()
    const params = new URLSearchParams(location.search)
    const token = params.get("token")

    const { width } = useDimensions()

    const [loading, setLoading] = useState(true)
    const [isRepeat, setIsRepeat] = useState(null)

    const dispatch = useDispatch()

    const steps = [
        {
            title: 'หน้าปกคอร์ส',
            content:
                <ManageCard
                    isStepActive={true}
                    isCourseCategoryActive={false}
                    isCourseFormatActive={false}
                    isCourseCertificateActive={false}
                    user={props.user}
                    course={[]}
                    switchType="add-course"
                />,
        },
        {
            title: 'สื่อนำเสนอ',
            content:
                <ManageMediaIntro
                    isStepActive={true}
                    user={props.user}
                    courseVideoIntro={[]}
                    switchType="add-course"
                />,
        },
        {
            title: 'ราคาคอร์ส',
            content:
                <ManagePrice
                    isStepActive={true}
                    course={[]}
                    switchType="add-course"
                />,
        },
        {
            title: 'รายละเอียดคอร์ส',
            content:
                <ManageDescript
                    isStepActive={true}
                    course={[]}
                    switchType="add-course"
                />,
        },
        {
            title: 'เอกสารเพิ่มเติม',
            content:
                <ManageDocument
                    isStepActive={true}
                    user={props.user}
                    courseId={null}
                    switchType="add-course"
                />,
        },
        {
            title: 'รายการวิดีโอ',
            content:
                <ManageVideo
                    isStepActive={true}
                    courseVideoList={[]}
                    firebase={props.firebase}
                    authorized={props.authorized}
                    user={props.user}
                    switchType="add-course"
                />,
        },
    ]

    const { resultCourseManageAddCurrentStep } = useSelector(({ courseManageAddCurrentStepReducer }) => courseManageAddCurrentStepReducer)

    const onChange = (value) => {
        const cardClickable = Number(localStorage.getItem("stepsCardClickable"))
        const introClickable = Number(localStorage.getItem("stepsIntroClickable"))
        const priceClickable = Number(localStorage.getItem("stepsPriceClickable"))
        const descriptionClickable = Number(localStorage.getItem("stepsDescriptionClickable"))
        const documentClickable = Number(localStorage.getItem("stepsDocumentClickable"))
        const videoClickable = Number(localStorage.getItem("stepsVideoClickable"))

        // console.log("cardClickable : ", cardClickable)
        // console.log("introClickable : ", introClickable)
        // console.log("priceClickable : ", priceClickable)
        // console.log("descriptionClickable : ", descriptionClickable)
        // console.log("documentClickable : ", documentClickable)
        // console.log("videoClickable : ", videoClickable)
        // console.log("value : ", value)

        if (
            (value === 0 && cardClickable === 1) ||
            (value === 1 && (cardClickable === 1 || introClickable === 1)) ||
            (value === 2 && ((cardClickable === 1 && introClickable === 1) || priceClickable === 1)) ||
            (value === 3 && ((cardClickable === 1 && introClickable === 1 && priceClickable === 1) || descriptionClickable === 1)) ||
            (value === 4 && ((cardClickable === 1 && introClickable === 1 && priceClickable === 1 && descriptionClickable === 1) || documentClickable === 1)) ||
            (value === 5 && ((cardClickable === 1 && introClickable === 1 && priceClickable === 1 && descriptionClickable === 1 && documentClickable === 1) || videoClickable === 1))
        ) {
            dispatch(setCourseManageAddCurrentStepStateToSuccess(Number(value)))
        } else {
            // console.log("ห้ามกด")
        }
    }

    const detectRightAddCourse = async () => {
        const result = await detectRepeatOpenCourseTokenFetch(token, props.user.uid)
        setIsRepeat(result === "repeat" ? true : false)
        if (result === "unrepeat") {
            navigate("/course-manage")
        }
    }

    useMemo(() => {
        localStorage.setItem("stepsCardClickable", 0)
        localStorage.setItem("stepsIntroClickable", 0)
        localStorage.setItem("stepsPriceClickable", 0)
        localStorage.setItem("stepsDescriptionClickable", 0)
        localStorage.setItem("stepsDocumentClickable", 0)
        localStorage.setItem("stepsVideoClickable", 0)
    }, [])

    useEffect(() => {
        setLoading(true)

        if (!props.authorized) {
            navigate("/login")
        }

        detectRightAddCourse()

        setLoading(false)
    }, [])

    return (
        <>
            {!loading && isRepeat ?
                <>
                    <Layout className="layout">
                        <Header
                            firebase={props.firebase}
                            authorized={props.authorized}
                            user={props.user}
                        />

                        <article>
                            <Content>
                                <div
                                    style={width >= 1500 ?
                                        { paddingLeft: '5%', paddingRight: '5%' }
                                        :
                                        (
                                            (width > 912 && width < 1500) ?
                                                { paddingLeft: '5%', paddingRight: '5%' }
                                                :
                                                { paddingLeft: 0, paddingRight: 0 }
                                        )
                                    }
                                >
                                    <div style={{ backgroundColor: 'white', height: '100%', paddingTop: 24, paddingLeft: 24, paddingRight: 24 }}>
                                        <div style={{ textAlign: 'center', paddingBottom: 20 }}>
                                            <label>เพิ่มคอร์ส</label>
                                        </div>
                                        <Steps
                                            current={resultCourseManageAddCurrentStep}
                                            onChange={onChange}
                                            direction={width > 1200 ? "horizontal" : "vertical"}
                                        >
                                            {steps.map(item => (
                                                <Step key={item.title} title={item.title} />
                                            ))}
                                        </Steps>
                                        <Divider style={width > 1200 ? {} : { marginTop: 1 }} />
                                        <div
                                            className="steps-content"
                                            style={{ paddingBottom: "30px" }}
                                        >
                                            {steps[resultCourseManageAddCurrentStep].content}
                                        </div>
                                    </div>
                                </div>
                            </Content>
                        </article>

                        <Footer />
                    </Layout>
                </>
                : []
            }
        </>
    )
}