/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */

import { Button, Col, Form, Image, Input, Layout, Modal, Pagination, Progress, Rate, Row } from 'antd'
import { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import firebase from '../../config/firebase-config'
import { Icon } from '@iconify/react'
import moment from 'moment'
import { useDimensions } from '../../common/mamager/ScreenManager'
import { storageUrl } from '../../constants'
import ReviewItem from './components/ReviewItem'
import Header from '../../common/components/header'
import LoadingPage from '../../common/components/loading/page'
import Footer from '../../common/components/footer'
import { getCourseReviewByUserIdFetch, getCourseReviewListByCourseIdFetch, getCourseReviewOverviewByCourseIdFetch, insertCourseReviewByUserIdFetch, updateCourseReviewListByUserIdFetch, updateCourseReviewOverviewByCourseIdFetch } from './API'
import { getCourseByIdFetch } from '../course/API'
import { getUserByUidFetch } from '../../common/components/header/API'
import { detectSignIn } from '../../common/mamager/DetectManager'
import { Notification } from '../../common/components/notification'
import Rating from '../../common/components/rating'

const { TextArea } = Input

const pagingMax = 5

export default function Review(props) {
    const location = useLocation()
    const params = new URLSearchParams(location.search)
    const courseId = props.courseId ? props.courseId : params.get("courseId")
    const type = props.type ? props.type : "advance"

    const { width } = useDimensions()

    const navigate = useNavigate()
    const [reviewScore, setReviewScore] = useState(0)
    const [reviewOverview, setReviewOverview] = useState(null)

    const [writeReview, setWriteReview] = useState(false)
    const [course, setCourse] = useState(null)
    const [teacherInfo, setTeacherInfo] = useState(null)
    const [userInfo, setUserInfo] = useState(null)

    const [loading, setLoading] = useState(true)

    const [myReview, setMyReview] = useState(null)

    const [reviewList, setReviewList] = useState([])

    const [formReview] = Form.useForm()

    const [page, setPage] = useState({
        minValue: 0,
        maxValue: pagingMax
    })

    const handleChange = value => {
        if (value <= 1) {
            setPage({
                minValue: 0,
                maxValue: pagingMax
            })
        } else {
            setPage({
                minValue: (value - 1) * pagingMax,
                maxValue: value * pagingMax
            })
        }
    }

    const onReviewFinish = async (values) => {
        let resultCourseReviewList = { isSuccess: false }
        let resultCourseReviewOverview = { isSuccess: false }

        // Course review list --------------------------------------------------------------------
        let objCourseReviewList = {
            courseReviewOverviewId: reviewOverview.courseReviewOverviewId,
            reviewScore: values.score,
            reviewMessage: values.message,
            uidReview: firebase.auth().currentUser.uid,
            courseId: Number(courseId),
        }
        // console.log("onReviewFinish ... : ", objCourseReviewList)
        if (myReview) {
            // update
            resultCourseReviewList = await updateCourseReviewListByUserIdFetch(objCourseReviewList)
        } else {
            // insert
            resultCourseReviewList = await insertCourseReviewByUserIdFetch(objCourseReviewList)
        }

        // Course review overview --------------------------------------------------------------------
        let score = Number(values.score)
        let reviewTotal = ++reviewOverview.reviewTotal

        let reviewScore5 = score === 5 ? ++reviewOverview.reviewScore5 : reviewOverview.reviewScore5
        let reviewScore4 = score === 4 ? ++reviewOverview.reviewScore4 : reviewOverview.reviewScore4
        let reviewScore3 = score === 3 ? ++reviewOverview.reviewScore3 : reviewOverview.reviewScore3
        let reviewScore2 = score === 2 ? ++reviewOverview.reviewScore2 : reviewOverview.reviewScore2
        let reviewScore1 = score === 1 ? ++reviewOverview.reviewScore1 : reviewOverview.reviewScore1

        if (myReview) { // move score to new score
            reviewTotal = reviewOverview.reviewTotal - 1
            reviewScore5 = myReview.reviewScore === 5 ? --reviewOverview.reviewScore5 : reviewOverview.reviewScore5
            reviewScore4 = myReview.reviewScore === 4 ? --reviewOverview.reviewScore4 : reviewOverview.reviewScore4
            reviewScore3 = myReview.reviewScore === 3 ? --reviewOverview.reviewScore3 : reviewOverview.reviewScore3
            reviewScore2 = myReview.reviewScore === 2 ? --reviewOverview.reviewScore2 : reviewOverview.reviewScore2
            reviewScore1 = myReview.reviewScore === 1 ? --reviewOverview.reviewScore1 : reviewOverview.reviewScore1
        }

        let objCourseReviewOverview = {
            courseReviewOverviewId: reviewOverview.courseReviewOverviewId,
            reviewTotal,
            reviewScore5,
            reviewScore4,
            reviewScore3,
            reviewScore2,
            reviewScore1,
            reviewScoreAverage: Number(((reviewScore5 * 5) + (reviewScore4 * 4) + (reviewScore3 * 3) + (reviewScore2 * 2) + (reviewScore1 * 1)) / reviewTotal).toFixed(1)
        }
        // insert & update 
        resultCourseReviewOverview = await updateCourseReviewOverviewByCourseIdFetch(objCourseReviewOverview, courseId)

        if (resultCourseReviewList.isSuccess && resultCourseReviewOverview.isSuccess) {
            Notification("success", "สำเร็จ", "ส่งรีวิวคอร์สสำเร็จ")
            setWriteReview(false)

            // Set state review list
            if (myReview) {
                setReviewList(reviewList.map(item => {
                    if (item.userName === userInfo.userName) {
                        item.reviewScore = values.score
                        item.reviewMessage = values.message
                    }
                    return item
                }))
            } else {
                setReviewList([{
                    userName: userInfo.userName,
                    imageProfile: userInfo.imageProfile,
                    reviewMessage: values.message,
                    reviewScore: values.score,
                    createdAt: moment().format("DD/MM/YYYY HH:mm"),
                }, ...reviewList])
            }

            setMyReview({
                userName: userInfo.userName,
                imageProfile: userInfo.imageProfile,
                reviewMessage: values.message,
                reviewScore: values.score,
                updatedAt: myReview?.updatedAt ? moment().format("DD/MM/YYYY HH:mm") : null,
                createdAt: moment().format("DD/MM/YYYY HH:mm"),
            })

            // Set state review overview
            setReviewOverview(objCourseReviewOverview)
        } else {
            Notification("error", "เกิดข้อผิดพลาด", "ไม่สามารถส่งรีวิวคอร์สได้ กรุณาลองใหม่อีกครั้ง")
        }
    }

    const getCourseByUidFetch = async () => {
        const result = await getCourseByIdFetch(courseId, "th")
        // console.log("getCourseByIdFetch : ", result)
        setCourse(result)
        if (result) {
            return result.uid
        } else {
            return null
        }
    }

    const getUserByUid = async (uid) => {
        const result = await getUserByUidFetch(uid)
        // console.log("getUserByUid : ", result)
        setTeacherInfo(result)

        const isLogin = await detectSignIn()
        if (isLogin) {
            const result = await getUserByUidFetch(firebase.auth().currentUser.uid)
            // console.log("getUserByUid : ", result)
            setUserInfo(result)
        }
    }

    const getReviewList = async () => {
        const resultReviewByCourse = await getCourseReviewListByCourseIdFetch({ courseId: courseId })
        // console.log("getCourseReviewListByCourseIdFetch : ", resultReviewByCourse)
        if (type === "basic") {
            setReviewList(resultReviewByCourse?.length > 3 ? resultReviewByCourse.slice(0, 3) : resultReviewByCourse)
        } else {
            setReviewList(resultReviewByCourse)
        }

        const resultReviewOverview = await getCourseReviewOverviewByCourseIdFetch({ courseId: Number(courseId) })
        // console.log("getCourseReviewOverviewByCourseIdFetch : ", resultReviewOverview)
        setReviewOverview(resultReviewOverview)

        const isLogin = await detectSignIn()
        if (isLogin) {
            const resultReviewByUser = await getCourseReviewByUserIdFetch({ courseId: courseId, uidReview: firebase.auth().currentUser.uid })
            // console.log("getCourseReviewByUserIdFetch : ", resultReviewByUser)
            setMyReview(resultReviewByUser)
        }
    }

    const getBaseApi = async () => {
        setLoading(true)
        let uid = await getCourseByUidFetch()

        await getUserByUid(uid)
        await getReviewList()

        // ----
        // let obj = {
        //     courseId: courseId,
        //     uidOwner: uid,
        // }
        // const result = await ReviewApi.insertCourseReviewOverviewFetch(obj);
        // console.log("insertCourseReviewOverviewFetch : ", result);
        // if (result.isSuccess) {
        //     //...
        // }
        // ----

        setLoading(false)
    }

    useEffect(() => {
        getBaseApi()
    }, [])

    const getReviewFound = (mgTop, pdBottom) => {
        return (
            <>
                {type === "basic" ?
                    <div
                        style={{
                            paddingTop: mgTop,
                            paddingBottom: pdBottom,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: "center"
                        }}
                    >
                        <Icon icon="fontisto:preview" style={{ color: "gray", width: 30, height: 30 }} />
                        <span style={{ color: 'gray', paddingLeft: 8 }}>ไม่พบรายการ</span>
                    </div>
                    :
                    <div
                        style={{
                            paddingTop: mgTop * 2,
                            paddingBottom: pdBottom * 2,
                            backgroundColor: "white",
                            borderRadius: 10,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: "center"
                        }}
                    >
                        <Icon icon="fontisto:preview" style={{ color: "#dfdfdf", width: 30, height: 30 }} />
                        <span style={{ color: '#c1c1c1', paddingLeft: 8 }}>ไม่พบรายการ</span>
                    </div>
                }
            </>
        )
    }

    const genBtnReview = () => {
        return (
            <Button
                type="primary"
                style={{ marginTop: 12 }}
                onClick={async () => {
                    const isLogin = await detectSignIn()
                    if (isLogin) {
                        if (myReview) {
                            setReviewScore(myReview.reviewScore)
                            setWriteReview(true)
                            // console.log("myReview : ", myReview)
                            formReview.setFieldsValue({
                                score: myReview.reviewScore,
                                message: myReview.reviewMessage,
                            })
                        } else {
                            setReviewScore(0)
                            setWriteReview(true)
                            formReview.resetFields()
                        }
                    } else {
                        Notification("warning", "ระบบ", 'กรุณาเข้าสู่ระบบก่อนเขียนรีวิว !')
                    }
                }}
            >
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: "center" }}>
                    <Icon icon={myReview ? "ic:sharp-update" : "material-symbols:rate-review-outline"} style={{ cursor: "pointer", color: "white", width: 20, height: 20 }} />
                    <label style={{ paddingLeft: 10, cursor: "pointer" }}>{myReview ? "อัพเดทรีวิว" : "เขียนรีวิว"}</label>
                </div>
            </Button>
        )
    }

    const genReview = () => {
        return (
            <div
                style={type === "basic" ?
                    {
                        borderTop: "1px solid #505050",
                        padding: 24,
                        backgroundColor: "#191919",
                        color: "white",
                    } :
                    {
                        padding: 0,
                        color: "black",
                    }
                }
            >
                <Row>
                    <Col span={24}>
                        <Row
                            style={type === "basic" ? {} :
                                {
                                    backgroundColor: "white",
                                    color: "black",
                                    borderRadius: 10,
                                    paddingTop: 12,
                                    paddingLeft: 24,
                                    paddingRight: 12,
                                    paddingBottom: 6
                                }
                            }
                        >
                            <Col xs={(type === "basic" ? 12 : 24)} lg={14} style={type === "basic" ? {} : { paddingBottom: 6 }}>
                                {type === "basic" ?
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <label style={{ marginBottom: 10 }}>คะเเนนเเละรีวิว</label>
                                        <label style={{ marginBottom: 10, paddingLeft: 5, paddingRight: 5 }}>
                                            ({reviewOverview ? Intl.NumberFormat('en').format(Number(reviewOverview.reviewTotal)) : 0} คน)
                                        </label>
                                    </div>
                                    :
                                    <Row>
                                        <Col xs={7} xl={4}>
                                            <Image height={115} width={115} src="error" fallback={course ? `${course.courseImageUrl}` : null} />
                                        </Col>

                                        <Col xs={17} xl={20}>
                                            <Row>
                                                <Col span={5}>
                                                    <label style={{ float: "right" }}>ชื่อคอร์ส</label>
                                                </Col>
                                                <Col span={19}>
                                                    <label style={{ paddingLeft: 10 }}>{course ? course.courseTitleName : "-"}</label>
                                                </Col>

                                                <Col span={5}>
                                                    <label style={{ float: "right" }}>ผู้สอน</label>
                                                </Col>
                                                <Col span={19}>
                                                    <label style={{ paddingLeft: 10 }}>{teacherInfo ? teacherInfo.userName : "-"}</label>
                                                </Col>

                                                <Col span={5}></Col>
                                                <Col span={19}>
                                                    {genBtnReview()}
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                }
                            </Col>

                            <Col xs={(type === "basic" ? 12 : 24)} lg={10}>
                                {type === "basic" ?
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: "end" }}>
                                        <Rating value={reviewOverview ? reviewOverview.reviewScoreAverage : 0} />
                                        <label
                                            style={{
                                                color: '#23B14D',
                                                paddingLeft: 5,
                                                fontWeight: "bold"
                                            }}
                                        >
                                            {reviewOverview ? Number(reviewOverview.reviewScoreAverage).toFixed(1) : 0}
                                        </label>
                                    </div>
                                    :
                                    <>
                                        <div
                                            style={{
                                                float: "right",
                                                paddingLeft: 12,
                                                marginRight: -10,
                                            }}
                                        >
                                            <Progress
                                                percent={reviewOverview ? ((reviewOverview.reviewScore5 * 100) / reviewOverview.reviewTotal) : 0}
                                                format={() => '5'}
                                                strokeColor={"#23B14D"}
                                                // trailColor={"#D9D9D9"}
                                                style={{
                                                    width: 180,
                                                    float: "right"
                                                }}
                                            /><br />

                                            <Progress
                                                percent={reviewOverview ? ((reviewOverview.reviewScore4 * 100) / reviewOverview.reviewTotal) : 0}
                                                format={() => '4'}
                                                strokeColor={"#23B14D"}
                                                // trailColor={"#D9D9D9"}
                                                style={{
                                                    width: 180,
                                                    float: "right"
                                                }}
                                            /><br />

                                            <Progress
                                                percent={reviewOverview ? ((reviewOverview.reviewScore3 * 100) / reviewOverview.reviewTotal) : 0}
                                                format={() => '3'}
                                                strokeColor={"#23B14D"}
                                                // trailColor={"#D9D9D9"}
                                                style={{
                                                    width: 180,
                                                    float: "right"
                                                }}
                                            /><br />

                                            <Progress
                                                percent={reviewOverview ? ((reviewOverview.reviewScore2 * 100) / reviewOverview.reviewTotal) : 0}
                                                format={() => '2'}
                                                strokeColor={"#23B14D"}
                                                // trailColor={"#D9D9D9"}
                                                style={{
                                                    width: 180,
                                                    float: "right"
                                                }}
                                            /><br />

                                            <Progress
                                                percent={reviewOverview ? ((reviewOverview.reviewScore1 * 100) / reviewOverview.reviewTotal) : 0}
                                                format={() => '1'}
                                                strokeColor={"#23B14D"}
                                                // trailColor={"#D9D9D9"}
                                                style={{
                                                    width: 180,
                                                    float: "right"
                                                }}
                                            /><br />
                                        </div>

                                        <div style={{ float: "right" }}>
                                            <Row gutter={[0, 10]}>
                                                <Col span={24}>

                                                    <label
                                                        style={{
                                                            float: 'right',
                                                            color: "black",
                                                            height: 55,
                                                            fontWeight: "bold",
                                                            fontSize: 52,
                                                            display: 'flex', alignItems: 'center',
                                                        }}
                                                    >
                                                        {reviewOverview ? Number(reviewOverview.reviewScoreAverage).toFixed(1) : 0}
                                                    </label>
                                                </Col>

                                                <Col span={24}>
                                                    <Rate
                                                        disabled
                                                        allowHalf
                                                        value={reviewOverview ? reviewOverview.reviewScoreAverage : 0}
                                                        style={{
                                                            float: 'right',
                                                            color: '#23B14D'
                                                        }}
                                                    />
                                                </Col>

                                                <Col span={24}>
                                                    <label
                                                        style={{
                                                            color: "black",
                                                            float: "right",
                                                            fontWeight: "bold",
                                                        }}
                                                    >
                                                        {reviewOverview ? Intl.NumberFormat('en').format(Number(reviewOverview.reviewTotal)) : 0} คน
                                                    </label>
                                                </Col>
                                            </Row>
                                        </div>
                                    </>
                                }
                            </Col>
                        </Row>
                    </Col>

                    <Col span={24}>
                        <div
                            style={type === "basic" ?
                                { padding: 12, backgroundColor: "#272727" }
                                :
                                { paddingTop: 12 }
                            }
                        >
                            {reviewList?.length > 0 ?
                                reviewList
                                    .slice(page.minValue, page.maxValue)
                                    .map((data, index) => {
                                        return <ReviewItem key={index} type={type} review={data} />
                                    })
                                :
                                getReviewFound(15, 15)
                            }

                            {type === "basic" ?
                                <center>
                                    <a
                                        style={{
                                            paddingTop: 4,
                                            paddingBottom: 0,
                                            marginBottom: 0,
                                            textDecoration: "underline",
                                            color: "#23B14D"
                                        }}
                                        onClick={() => {
                                            navigate("/review" +
                                                '?courseId=' + courseId
                                                // + '&type=advance'
                                            )
                                        }}
                                    >เเสดงเพิ่มเติม</a>
                                </center>
                                : []
                            }
                        </div>
                    </Col>

                    {type === "advance" && reviewList?.length > pagingMax ?
                        <Col span={24} style={{ paddingBottom: 10 }}>
                            <center>
                                <Pagination
                                    defaultCurrent={1}
                                    defaultPageSize={pagingMax}
                                    onChange={handleChange}
                                    total={reviewList.length}
                                />
                            </center>
                        </Col>
                        : []
                    }

                    {type === "basic" ?
                        <Col span={24}>
                            <center>{genBtnReview()}</center>
                        </Col>
                        : []
                    }
                </Row>

                <Modal
                    title={<label style={{ fontWeight: 100 }}>{myReview?.updatedAt ? "อัพเดทรีวิว" : "เขียนรีวิว"}</label>}
                    visible={writeReview}
                    okText={
                        <div style={{ display: 'flex', alignItems: 'center', width: 65 }}>
                            <Icon icon="bi:send" style={{ color: "white" }} />
                            <label style={{ paddingLeft: 10, cursor: "pointer" }}>ส่งรีวิว</label>
                        </div>
                    }
                    cancelText={
                        <div style={{ display: 'flex', alignItems: 'center', width: 65 }}>
                            <Icon icon="mdi:cancel" />
                            <label style={{ paddingLeft: 10, cursor: "pointer" }}>ยกเลิก</label>
                        </div>
                    }
                    onCancel={() => {
                        setWriteReview(false)
                        setReviewScore(0)
                    }}
                    onOk={() => {
                        formReview.submit()
                    }}
                >
                    <Form
                        form={formReview}
                        layout="vertical"
                        onFinish={onReviewFinish}
                    >
                        <Row>
                            <Col span={24}>
                                <Form.Item
                                    label="คะเเนน"
                                    name="score"
                                >
                                    <Rate
                                        allowClear={true}
                                        value={reviewScore}
                                        onChange={(value) => {
                                            setReviewScore(value)
                                        }}
                                        style={{
                                            fontSize: 30,
                                            color: '#23B14D'
                                        }}
                                    />
                                </Form.Item>
                            </Col>

                            <Col span={24}>
                                <Form.Item
                                    label="ข้อความรีวิว"
                                    name="message"
                                    rules={[{ required: true, message: "กรุณากรอกคะเเนน" }]}
                                >
                                    <TextArea rows={4} maxLength={300} showCount />
                                </Form.Item>
                            </Col>

                            <Col span={24}>
                                {myReview ?
                                    <label>
                                        {myReview.updatedAt ?
                                            <>เเก้ไขล่าสุดเมื่อวันที่ {myReview.updatedAt ? myReview.updatedAt : "-"}</>
                                            :
                                            <>สร้างเมื่อวันที่ {myReview.createdAt ? myReview.createdAt : "-"}</>
                                        }
                                    </label>
                                    : []
                                }
                            </Col>
                        </Row>
                    </Form>
                </Modal>
            </div>
        )
    }

    return (
        <div style={{ fontSize: 16 }}>
            {type === "basic" ?
                genReview()
                :
                <Layout className="layout">
                    <Header
                        firebase={firebase}
                        user={firebase.auth().currentUser}
                    />

                    <article>
                        {!loading ?
                            <div style={width >= 1500 ? { paddingLeft: '5%', paddingRight: '5%', paddingTop: '1%', paddingBottom: '1%' } : ((width > 912 && width < 1500) ? { paddingLeft: '5%', paddingRight: '5%', paddingTop: '1%', paddingBottom: '1%' } : { paddingLeft: '0%', paddingRight: '0%', paddingTop: '1%', paddingBottom: '1%' })}>
                                {genReview()}
                            </div>
                            :
                            <LoadingPage height={280} bottom={280} />
                        }
                    </article>

                    <Footer />
                </Layout>
            }
        </div>
    )
}
