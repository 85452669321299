/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */

import { useState, useEffect } from 'react'
import {
    Button,
    Layout,
    Menu
} from 'antd'
import { PageHeader } from '@ant-design/pro-layout'
import { useNavigate, useLocation } from 'react-router-dom'
import InspectRequestOpenCourse from './inspect/requestOpenCourse'
import InspectRequestUoloadCourse from './inspect/requestUploadCourse'
import InspectRequestEditCourse from './inspect/requestEditCourse'
import InspectFeedback from './inspect/feedback'
import AnnounceManage from './manage/announce'
import PermissionManage from './manage/permission'
import BannerManage from './manage/banner'
import InspectPaymentOrder from './inspect/payment/paymentOrderTransfer'
import EarnedSalesRevenueManage from './manage/payment/earnedSalesRevenue'
import PaymentOrderManage from './manage/payment/paymentOrder'
import ReservedWordManage from './manage/reservedWord'
import ComplaintManage from './manage/complaint'
import UserManage from './manage/user'
import { getAdminByIdFetch, getTokenByIdFetch, updateTokenByIdFetch } from './API'
import VerifyIdentity from './inspect/verifyIdentity'
import { Notification } from '../../common/components/notification'
import CouponManage from '../erp/manage/coupon'

const { Sider } = Layout
const { SubMenu } = Menu

export default function ERP(props) {
    const navigate = useNavigate()
    const location = useLocation()

    const [loading, setLoading] = useState(true)

    let username = location.state ? location.state.username : null
    let password = location.state ? location.state.password : null
    let token = location.state ? location.state.token : null

    const [selectedMenuItem, setSelectedMenuItem] = useState("menu0")

    const componentsSwitch = (type) => {
        switch (type) {
            case "menu0":
                return <>A</>
            case "menu1":
                return <InspectRequestOpenCourse adminLogin={location.state} />
            case "menu2":
                return <InspectRequestUoloadCourse adminLogin={location.state} />
            case "menu3":
                return <InspectRequestEditCourse adminLogin={location.state} />
            case "menu4":
                return <InspectFeedback />
            case "menu5":
                return <AnnounceManage />
            case "menu6":
                return <PermissionManage adminMasterCreateId={location.state.adminListId} />
            case "menu7":
                return <BannerManage />
            case "menu8":
                return <VerifyIdentity />
            case "menu9":
                return <InspectPaymentOrder adminLogin={location.state} />
            case "menu10":
                return <EarnedSalesRevenueManage adminLogin={location.state} />
            case "menu11":
                return <ReservedWordManage />
            case "menu12":
                return <ComplaintManage />
            case "menu13":
                return <PaymentOrderManage adminLogin={location.state} />
            case "menu14":
                return <UserManage adminLogin={location.state} />
            case "menu15":
                return <CouponManage adminLogin={location.state} />
            default:
                return null
        }
    }

    const detectLogin = async () => {
        // console.log(username, password)
        return await getAdminByIdFetch(username, password) // & where isActive = 1
    }

    const detectLoginAndToken = async () => {
        setLoading(true)

        let resultAdmin = await getAdminByIdFetch(username, password)
        let resultToken = await getTokenByIdFetch(username, token)

        if (resultAdmin.isAdmin === "correct" && resultToken === "correct") {
            // update token
            await updateTokenByIdFetch(token)
        }

        setLoading(false)
    }

    useEffect(() => {
        detectLoginAndToken()

        setInterval(async () => {
            let result = await detectLogin()
            // console.log("detectLogin 1 : ", result)
            if (result) {
                if (result.isAdmin === "incorrect") {
                    // navigate("/erp-login")
                }
            } else {
                Notification("warning", "เเจ้งเตือน", "เกิดข้อผิดพลาดในการ Detect login !")
            }
        }, 10000)
    }, [])

    return (
        <>
            {!loading ?
                <div style={{ backgroundColor: '#ECECEC' }}>
                    <div style={{ paddingLeft: 0, paddingRight: 0 }}>
                        <PageHeader
                            title={
                                <>
                                    <label style={{ paddingBottom: 0, marginBottom: 0, color: "white" }}>Administrator</label><br />
                                    <label style={{ paddingBottom: 0, marginBottom: 0, color: "white", fontSize: 16, fontWeight: "initial" }}>ผู้ใช้งาน : {location.state ? location.state.username : "-"}</label>
                                </>
                            }
                            extra={[
                                <Button
                                    type='default'
                                    onClick={() => {
                                        navigate(
                                            "/erp-manage",
                                            { state: null }
                                        )

                                        navigate("/erp-login")
                                    }}
                                    style={{ position: 'absolute', marginTop: 5, marginLeft: -105 }}
                                >ออกจากระบบ</Button>
                            ]}
                            style={{ backgroundColor: 'black' }}
                        >
                        </PageHeader>
                        <Layout>
                            <Sider
                                width={300}
                                className="site-layout-background"
                            >
                                <Menu
                                    defaultSelectedKeys={['1']}
                                    defaultOpenKeys={['menu0']}
                                    mode="inline"
                                    theme="dark"
                                    selectedKeys={selectedMenuItem}
                                    onClick={async (e) => {

                                        let resultDetectLogin = await detectLogin(); // & where isActive = 1
                                        // console.log("detectLogin 2 : ", resultDetectLogin)

                                        // ฝ่ายตรวจสอบ
                                        if (resultDetectLogin.adminTypeId === 1) { // location.state.adminTypeId
                                            if (e.key === "menu0" || e.key === "menu1" || e.key === "menu2" || e.key === "menu3" || e.key === "menu4") {
                                                setSelectedMenuItem(e.key)
                                            } else {
                                                Notification("warning", "ระบบ", "คุณไม่มีสิทธิเข้าถึง")
                                            }
                                        }

                                        // ฝ่ายข่าวสาร
                                        if (resultDetectLogin.adminTypeId === 2) {
                                            if (e.key === "menu0" || e.key === "menu5") {
                                                setSelectedMenuItem(e.key)
                                            } else {
                                                Notification("warning", "ระบบ", "คุณไม่มีสิทธิเข้าถึง")
                                            }
                                        }

                                        // ธานอส
                                        if (resultDetectLogin.adminTypeId === 3) {
                                            setSelectedMenuItem(e.key)
                                        }
                                    }}
                                    style={{ height: '100%' }}
                                >
                                    <Menu.Item key="menu0">
                                        <label style={{ paddingLeft: 10, marginBottom: 0, cursor: 'pointer' }}>Dashboard</label>
                                    </Menu.Item>

                                    <Menu.Item key="menu5">
                                        <label style={{ paddingLeft: 10, marginBottom: 0, cursor: 'pointer' }}>จัดการประกาศข่าวสาร</label>
                                    </Menu.Item>

                                    <Menu.Item key="menu6">
                                        <label style={{ paddingLeft: 10, marginBottom: 0, cursor: 'pointer' }}>จัดการรายชื่อ Admin</label>
                                    </Menu.Item>

                                    <Menu.Item key="menu7">
                                        <label style={{ paddingLeft: 10, marginBottom: 0, cursor: 'pointer' }}>จัดการ Banner</label>
                                    </Menu.Item>

                                    <Menu.Item key="menu11">
                                        <label style={{ paddingLeft: 10, marginBottom: 0, cursor: 'pointer' }}>จัดการคำสงวน</label>
                                    </Menu.Item>

                                    <Menu.Item key="menu14">
                                        <label style={{ paddingLeft: 10, marginBottom: 0, cursor: 'pointer' }}>จัดการสมาชิก</label>
                                    </Menu.Item>

                                    <Menu.Item key="menu15">
                                        <label style={{ paddingLeft: 10, marginBottom: 0, cursor: 'pointer' }}>จัดการคูปอง</label>
                                    </Menu.Item>

                                    <SubMenu key="sub1" title="จัดการตรวจสอบ">
                                        <Menu.Item key="menu1">
                                            <label style={{ paddingLeft: 10, marginBottom: 0, cursor: 'pointer' }}>ตรวจสอบคำขอลงทะเบียนสอน</label>
                                        </Menu.Item>
                                        <Menu.Item key="menu2">
                                            <label style={{ paddingLeft: 10, marginBottom: 0, cursor: 'pointer' }}>ตรวจสอบคำขอเปิดคอร์ส</label>
                                        </Menu.Item>
                                        <Menu.Item key="menu3">
                                            <label style={{ paddingLeft: 10, marginBottom: 0, cursor: 'pointer' }}>ตรวจสอบคำขอเเก้ไขคอร์ส</label>
                                        </Menu.Item>
                                        <Menu.Item key="menu4">
                                            <label style={{ paddingLeft: 10, marginBottom: 0, cursor: 'pointer' }}>ตรวจสอบข้อความ feedback</label>
                                        </Menu.Item>
                                        <Menu.Item key="menu8">
                                            <label style={{ paddingLeft: 10, marginBottom: 0, cursor: 'pointer' }}>ตรวจสอบยืนยันตัวตนข้อมูลส่วนตัว</label>
                                        </Menu.Item>
                                        <Menu.Item key="menu9">
                                            <label style={{ paddingLeft: 10, marginBottom: 0, cursor: 'pointer' }}>ตรวจสอบรายการโอนเงิน</label>
                                        </Menu.Item>
                                    </SubMenu>

                                    <SubMenu key="sub2" title="จัดการชำระเงิน">
                                        <Menu.Item key="menu10">
                                            <label style={{ paddingLeft: 10, marginBottom: 0, cursor: 'pointer' }}>รายได้จากการขายของผู้สอน</label>
                                        </Menu.Item>
                                        <Menu.Item key="menu13">
                                            <label style={{ paddingLeft: 10, marginBottom: 0, cursor: 'pointer' }}>คำสั่งชำระเงิน</label>
                                        </Menu.Item>
                                    </SubMenu>

                                    <SubMenu key="sub4" title="จัดการความช่วยเหลือ และทรัพยากร">
                                        <Menu.Item key="menu12">
                                            <label style={{ paddingLeft: 10, marginBottom: 0, cursor: 'pointer' }}>เรื่องร้องเรียน</label>
                                        </Menu.Item>
                                    </SubMenu>
                                </Menu>
                            </Sider>
                            <article style={{ backgroundColor: 'white', width: '100%', height: '100%' }}>
                                <div style={{ padding: 20 }}>
                                    {componentsSwitch(selectedMenuItem)}
                                </div>
                            </article>
                        </Layout>
                    </div>
                </div>
                : []}
        </>
    )
}
