import { 
    HTTP_COURSE_MANAGE_CHAPTER_FETCHING, 
    HTTP_COURSE_MANAGE_CHAPTER_SUCCESS, 
    HTTP_COURSE_MANAGE_CHAPTER_FAILED, 
} from "../constants"

export const setCourseManageChapterStateToFetching = () => ({
    type: HTTP_COURSE_MANAGE_CHAPTER_FETCHING,
})

export const setCourseManageChapterStateToSuccess = (payload) => ({
    type: HTTP_COURSE_MANAGE_CHAPTER_SUCCESS,
    payload
})

export const setCourseManageChapterStateToFailed = () => ({
    type: HTTP_COURSE_MANAGE_CHAPTER_FAILED,
})
