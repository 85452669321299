import { server } from "../../../constants"
import { httpClient } from "../../../utils/HttpClient"

const getCourseReviewListByCourseIdFetch = async (value) => { // [courseId] Done
    try {
        const result = await httpClient.post(server.GET_COURSE_REVIEW_LIST_BY_COURSE_ID_URL, { value })
        return result.data.formData
    } catch (err) { // status 404
        return null
    }
}

const getCourseReviewOverviewByCourseIdFetch = async (value) => { // [courseId] Done
    try {
        const result = await httpClient.post(server.GET_COURSE_REVIEW_OVERVIEW_BY_COURSE_ID_URL, { value })
        return result.data.formData
    } catch (err) { // status 404
        return null
    }
}

const getCourseReviewByUserIdFetch = async (value) => { // [uid, courseId] Done
    try {
        const result = await httpClient.post(server.GET_COURSE_REVIEW_BY_USER_ID_URL, { value })
        return result.data.formData
    } catch (err) { // status 404
        return null
    }
}

const insertCourseReviewByUserIdFetch = async (value) => { // Done
    try {
        const result = await httpClient.post(server.INSERT_COURSE_REVIEW_BY_USER_ID_URL, { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const insertCourseReviewOverviewFetch = async (value) => { // Done
    try {
        const result = await httpClient.post(server.INSERT_COURSE_REVIEW_OVERVIEW_URL, { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const updateCourseReviewListByUserIdFetch = async (value) => { // 
    try {
        const result = await httpClient.put(server.UPDATE_COURSE_REVIEW_LIST_BY_USER_ID_URL, { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const updateCourseReviewOverviewByCourseIdFetch = async (value, courseId) => { // 
    try {
        const result = await httpClient.put(server.UPDATE_COURSE_REVIEW_OVERVIEW_BY_COURSE_ID_URL, { value, courseId })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

export {
    // get
    getCourseReviewListByCourseIdFetch,
    getCourseReviewOverviewByCourseIdFetch,
    getCourseReviewByUserIdFetch,

    // insert
    insertCourseReviewByUserIdFetch,
    insertCourseReviewOverviewFetch,

    // update
    updateCourseReviewListByUserIdFetch,
    updateCourseReviewOverviewByCourseIdFetch

    // delete

}