import {
    HTTP_REGISTER_CAREER_AND_EDUCATION_FETCHING,
    HTTP_REGISTER_CAREER_AND_EDUCATION_SUCCESS,
    HTTP_REGISTER_CAREER_AND_EDUCATION_FAILED
} from "../constants"

const initialState = {
    resultCareerAndEducation: {
        currentOccupation: undefined, 
        highestEducation: undefined,
        businessCategory: undefined
    },
    isFetching: false,
    isError: false
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, { type, payload }) => {
    switch (type) {

        case HTTP_REGISTER_CAREER_AND_EDUCATION_FETCHING:
            return { ...state, resultCareerAndEducation: null, isFetching: true, isError: false }
        case HTTP_REGISTER_CAREER_AND_EDUCATION_SUCCESS:
            return { ...state, resultCareerAndEducation: payload, isFetching: false, isError: false }
        case HTTP_REGISTER_CAREER_AND_EDUCATION_FAILED:
            return { ...state, resultCareerAndEducation: null, isFetching: false, isError: true }
        default:
            return state
    }
}
