/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */

import { useState, useEffect, useMemo } from 'react'
import * as moment from 'moment'
import { isEmptyObject } from 'jquery'
import { useNavigate } from 'react-router-dom'
import {
    Row,
    Col,
    Button
} from 'antd'
import { Icon } from '@iconify/react'
import Avatar from 'antd/lib/avatar/avatar'
import { useDispatch } from 'react-redux'
import { useDimensions } from './../../../../common/mamager/ScreenManager'
import { imgDefaultUrl } from './../../../../constants'
import { Checkout } from './../../../../modules/payment/components/checkout'
import { filterPaymentVideo } from './../../../../modules/coursShop/mamager/filterPaymentVideo'
import { setOrderPaymentChapterAndVideoCourseSuccess } from '../../../../actions/orderPaymentChapterAndVideoCourse.action'
import { getPaymentOrderDetectReBuyByUserId } from './../../../../modules/payment/API'
import { calculateDiscountTimeLeft } from './../../../../common/mamager/TimeManager'
import { detectSignIn } from './../../../../common/mamager/DetectManager'
import { Notification } from './../../../../common/components/notification'
import './../../css/overview.css'

export default function CourseOverview(props) {
    const navigate = useNavigate()
    const { width } = useDimensions()
    const [timeLeft, setTimeLeft] = useState(null)
    const courseVideoAmount = props.video?.length // In progress

    const dispatch = useDispatch()

    const [paymentOrderDetectReBuy, setPaymentOrderDetectReBuy] = useState()
    const [freeLoading, setFreeLoading] = useState(false)
    const [loading, setLoading] = useState(true)

    const getBaseApi = async () => {

        let result = await getPaymentOrderDetectReBuyByUserId(props?.user?.uid, props.course.courseId)
        setPaymentOrderDetectReBuy(result)
    }

    useMemo(() => {
        setLoading(true)

        getBaseApi()
        setTimeLeft(calculateDiscountTimeLeft(props.course?.discountDateEnd))

        setLoading(false)
    }, [])

    useEffect(() => {
        setTimeout(() => {
            setTimeLeft(calculateDiscountTimeLeft(props.course?.discountDateEnd))
        }, 1000)
    })

    useEffect(() => {

    }, [props.orderPaymentAmount])

    return (
        <div style={{ fontSize: 16 }}>
            {!loading ?
                <Row className={props.pageName === "course-shop" ? "course-overview-course-shop-container" : "course-overview-classroom-container"}>
                    {props.pageName === "course-shop" || props.pageName === "classroom-course-study" ?
                        <>
                            <Col
                                // xs={3} sm={3} md={3} lg={4}
                                span={24}
                                style={{
                                    borderBottom: "1px solid #505050",
                                    cursor: "pointer",
                                    paddingLeft: 24,
                                    paddingRight: 24,
                                    paddingTop: 12,
                                    paddingBottom: 12
                                }}
                                onClick={() => {
                                    navigate(
                                        `/school?teacher=${props.course.uid}`, // teacher
                                        {
                                            state: {
                                                authorized: props.authorized,
                                                firebase: props.firebase,
                                                user: props.user ? props.user.uid : null // learner
                                            }
                                        }
                                    )
                                }}
                            >
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Avatar
                                        src={
                                            props.courseOwner.imageProfile === "-" ?
                                                imgDefaultUrl
                                                :
                                                props.courseOwner.imageProfile
                                        }
                                        style={{
                                            height: 40,
                                            width: 40,
                                            padding: 0,
                                            margin: 0
                                        }}
                                    />

                                    <div style={{ paddingLeft: 14 }}>
                                        ผู้สอน <span style={{ cursor: "pointer" }}>{props.courseOwner ? props.courseOwner.userName : null}</span>
                                    </div>
                                </div>
                            </Col>

                            <Col
                                span={24}
                                style={{
                                    paddingLeft: props.pageName === "course-shop" ? 24 : 24,
                                    paddingBottom: props.pageName === "course-shop" ? 12 : 18,
                                    paddingTop: props.pageName === "course-shop" ? 18 : 18,
                                    paddingRight: props.pageName === "course-shop" ? 24 : 24
                                }}
                            >
                                <span>{props.course.courseTitleName}</span>
                            </Col>
                        </>
                        : []}

                    {props.pageName === "course-shop" ?
                        <>
                            <Col span={(width >= 669 && width <= 992) ? 18 : 15} style={{ paddingBottom: 12, paddingLeft: 24, paddingRight: 24 }}>
                                <label>จำนวนวิดีโอทั้งหมด</label>
                            </Col>
                            <Col span={(width >= 669 && width <= 992) ? 6 : 9}>
                                <label>{courseVideoAmount}</label>
                            </Col>

                            <Col span={(width >= 669 && width <= 992) ? 18 : 15} style={{ paddingBottom: 12, paddingLeft: 24, paddingRight: 24 }}>
                                <label>จำนวนเวลาทั้งหมด</label>
                            </Col>
                            <Col span={(width >= 669 && width <= 992) ? 6 : 9}>
                                <label>{props.totalVideoTime} น.</label>
                            </Col>

                            <Col span={(width >= 669 && width <= 992) ? 18 : 15} style={{ paddingBottom: 12, paddingLeft: 24, paddingRight: 24 }}>
                                <label>รูปเเบบคอร์ส</label>
                            </Col>
                            <Col span={(width >= 669 && width <= 992) ? 6 : 9}>
                                <label>{props.course.courseFormatName}</label>
                            </Col>

                            <Col span={(width >= 669 && width <= 992) ? 18 : 15} style={{ paddingBottom: 12, paddingLeft: 24, paddingRight: 24 }}>
                                <label>ใบรับรองหลักสูตร</label>
                            </Col>
                            <Col span={(width >= 669 && width <= 992) ? 6 : 9}>
                                <label>{props.course.courseCertificateName}</label>
                            </Col>

                            {props.course.discountStatus && !isEmptyObject(timeLeft) ?
                                <>
                                    <Col span={(width >= 669 && width <= 992) ? 18 : 15} style={{ paddingBottom: 12, paddingLeft: 24, paddingRight: 24 }}>
                                        <label>ราคาปกติ</label>
                                    </Col>
                                    <Col span={(width >= 669 && width <= 992) ? 4 : 6}>
                                        <label style={{ textDecorationLine: 'line-through' }}>{Intl.NumberFormat("en", { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format((props.course.price))} บาท</label>
                                    </Col>
                                    <Col span={(width >= 669 && width <= 992) ? 2 : 3} style={{ fontStyle: 'italic', color: 'red' }}>
                                        <label style={{ fontSize: 11 }}>{Intl.NumberFormat("en", { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(((Number(props.course.discount) * 100) / Number(props.course.price)).toFixed(2))}%</label>
                                    </Col>

                                    <Col span={(width >= 669 && width <= 992) ? 18 : 15} style={{ paddingLeft: 24, paddingRight: 24 }}>
                                        <label>เฉพาะใน {timeLeft.days !== '0' ? <label> {timeLeft.days} วันนี้ </label> : <label style={{ fontWeight: 'bold' }}> {timeLeft.hours}:{timeLeft.minutes}:{timeLeft.seconds} </label>} ลดเหลือ</label>
                                    </Col>
                                    <Col span={6}><label>{Intl.NumberFormat("en", { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(Number(props.course.price) - Number(props.course.discount))} บาท</label></Col>
                                </>
                                :
                                <>
                                    <Col span={(width >= 669 && width <= 992) ? 18 : 15} style={{ paddingLeft: 24, paddingRight: 24 }}><label>ราคา</label></Col>
                                    <Col span={6}>
                                        <label>
                                            {Number(props.course.price) === 0 ?
                                                <>ฟรี</>
                                                :
                                                <>{Intl.NumberFormat("en", { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(Number(props.course.price))} บาท</>
                                            }

                                        </label>
                                    </Col>
                                </>
                            }
                            <Col span={24} style={{ padding: 24 }}>
                                <center>
                                    {props.isCourseHasBeenPurchased && paymentOrderDetectReBuy?.isPaid ?
                                        <Button
                                            type="primary"
                                            size='large'
                                            style={{ width: (!freeLoading ? (width > 576 ? 200 : "100%") : 230) }}
                                            onClick={() => {
                                                navigate("/classroom-course-study?course-id=" + props.course.courseId)
                                            }}
                                        >
                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: "center" }}>
                                                <Icon icon="material-symbols:school-outline" style={{ color: "white", width: 20, height: 20 }} />
                                                <label style={{ paddingLeft: 5, cursor: "pointer" }}>เข้าห้องเรียน</label>
                                            </div>
                                        </Button>
                                        :
                                        <>
                                            {props.course?.price === 0 ?
                                                <Button
                                                    type="primary"
                                                    size='large'
                                                    style={{ width: (!freeLoading ? (width > 576 ? 200 : "100%") : 230) }}
                                                    loading={freeLoading}
                                                    onClick={async () => {
                                                        const isLogin = await detectSignIn()
                                                        // console.log("isLogin : ", isLogin);
                                                        if (isLogin) {
                                                            if (paymentOrderDetectReBuy?.isPaid) {
                                                                Notification("warning", "ระบบ", 'ไม่สามารถซื้อคอร์สนี้ได้ เนื่องจากท่านได้ซื้อคอร์สนี้แล้ว หรืออยู่ในช่วงตรวจสอบชำระเงินอยู่')
                                                            } else {
                                                                // ------------------------------------------------------------------------------------
                                                                setFreeLoading(true)

                                                                await Checkout(
                                                                    null,                                                       // ไอดีของคูปอง
                                                                    1,                                                          // (1: คอร์สเรียน, ...)
                                                                    null,                                                       // (วิธีการชำระเงิน -> [1: โอนเงิน, 2: บัตรเดบิต/เครดิต, 3: Paypal, 4: พร้อมเพย์])
                                                                    "successful",                                               // (สถานะการชำระเงิน -> [1,successful: ชำระเงินแล้ว, 2,pending: รอการชำระเงิน, 3,failed: ยกเลิกการชำระเงิน, 4,free: ฟรี (ไม่ชำระเงิน)])
                                                                    true,                                                       // (true คือ การชำระเงินสำเร็จเเละเสร็จสิ้นเเล้ว พร้อมเพิ่มคอร์สเรียนลงในรายการคอร์สของผู้เรียน เเละพร้อมเรียนได้ทันที)
                                                                    {
                                                                        courseId: props.course.courseId,
                                                                        uid: props.course.uid,
                                                                        courseFormatId: props.course.courseFormatId,
                                                                        courseTitleName: props.course.courseTitleName
                                                                    },                                                          // ข้อมูลของผู้สอน
                                                                    {
                                                                        uid: props.user.uid,
                                                                        amount: props.course.discountStatus ?
                                                                            (Number(props.course.price) - Number(props.course.discount))
                                                                            :
                                                                            Number(props.course.price)
                                                                    },                                                          // ข้อมูลของผู้เรียน
                                                                    null,                                                       // charge token
                                                                    null,                                                       // charge status
                                                                    moment(),                                                   // วันที่โอนดูจากสลิปธนาคาร หรือวันที่ชำระเงิน (today -> moment())
                                                                    false,                                                      // เปิดหน้า ธุรกรรมการชำระเงิน
                                                                    navigate,                                                   // redirect page
                                                                    null,                                                       // ภาพหลักฐานการโอนเงิน เฉพาะการโอนเงิน
                                                                    null,                                                       // การต่อสัญญาจากหมดอายุเรียนคอร์ส -> [1: ต่อสัญญาเเล้ว, 2: รอต่อสัญญา, null: ซื้อคอร์สครั้งเเรก หรือเป็นคอร์สที่ไม่มีหมดอายุ]
                                                                )

                                                                setFreeLoading(false)
                                                                // ------------------------------------------------------------------------------------
                                                            }
                                                        } else {
                                                            navigate("/login")
                                                        }
                                                    }}
                                                >
                                                    {!freeLoading ?
                                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                                            <Icon icon="entypo:download" style={{ color: "white", width: 20, height: 20 }} />
                                                            <label style={{ paddingLeft: 5, cursor: "pointer" }}>เก็บคอร์สเรียนนี้ (ฟรี)</label>
                                                        </div>
                                                        : []
                                                    }
                                                </Button>
                                                :
                                                <>
                                                    {props.orderPaymentAmount?.totalAmount > 0 ?
                                                        <Row>
                                                            <Col span={24}>
                                                                <div style={{ paddingBottom: 16, borderTop: "4px dashed white" }}></div>
                                                            </Col>
                                                            <Col span={(width >= 669 && width <= 992) ? 18 : 15}>
                                                                <label style={{ float: 'left' }}>วิดีโอที่เลือก</label>
                                                            </Col>
                                                            <Col span={(width >= 669 && width <= 992) ? 6 : 9}>
                                                                <label style={{ float: 'left' }}>{props.orderPaymentAmount?.totalAmount} วิดีโอ</label>
                                                            </Col>

                                                            <Col span={(width >= 669 && width <= 992) ? 18 : 15} style={{ paddingBottom: 12 }}>
                                                                <label style={{ float: 'left' }}>ราคาคอร์สที่จ่าย</label>
                                                            </Col>
                                                            <Col span={(width >= 669 && width <= 992) ? 6 : 9}>
                                                                <label style={{ float: 'left' }}>{Intl.NumberFormat("en", { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format((props.orderPaymentAmount?.totalPrice))} บาท</label>
                                                            </Col>

                                                            <Col span={24}>
                                                                <Button
                                                                    type="primary"
                                                                    size='large'
                                                                    style={{ width: (width > 576 ? 200 : "100%") }}
                                                                    onClick={async () => {
                                                                        const isLogin = await detectSignIn()
                                                                        // console.log("isLogin : ", isLogin)
                                                                        if (isLogin) {
                                                                            navigate("/payment?course-id=" + props.course.courseId)
                                                                        } else {
                                                                            navigate("/login")
                                                                        }
                                                                    }}
                                                                >
                                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                        <Icon icon="icons8:buy" style={{ color: "white", width: 20, height: 20 }} />
                                                                        <label style={{ paddingLeft: 5, cursor: "pointer" }}>ซื้อวิดีโอคอร์สเหล่านี้</label>
                                                                    </div>
                                                                </Button>
                                                            </Col>
                                                        </Row>
                                                        :
                                                        <Button
                                                            type="primary"
                                                            size='large'
                                                            style={{ width: (width > 576 ? 200 : "100%") }}
                                                            onClick={async () => {
                                                                const isLogin = await detectSignIn()
                                                                // console.log("isLogin : ", isLogin)
                                                                if (isLogin) {
                                                                    if (paymentOrderDetectReBuy?.isPaid) {
                                                                        Notification("warning", "ระบบ", 'ไม่สามารถซื้อคอร์สนี้ได้ เนื่องจากท่านได้ซื้อคอร์สนี้แล้ว หรืออยู่ในช่วงตรวจสอบชำระเงินอยู่')
                                                                    } else {
                                                                        if (props?.isPaymentByVideo) {
                                                                            let fillOrderVideo = filterPaymentVideo(
                                                                                props.chapter,
                                                                                props.video,
                                                                                props.courseId
                                                                            )
                                                                            dispatch(setOrderPaymentChapterAndVideoCourseSuccess(fillOrderVideo))
                                                                            props.reloadOderPayment(fillOrderVideo.video)
                                                                        }

                                                                        navigate("/payment?course-id=" + props.course.courseId)
                                                                    }
                                                                } else {
                                                                    navigate("/login")
                                                                }
                                                            }}
                                                        >
                                                            <div style={{ display: 'flex', alignItems: 'center', width: "100%", justifyContent: 'center' }}>
                                                                <Icon icon="icons8:buy" style={{ color: "white", width: 20, height: 20 }} />
                                                                <label style={{ paddingLeft: 5, cursor: "pointer" }}>ซื้อคอร์สนี้</label>
                                                            </div>
                                                        </Button>
                                                    }
                                                </>
                                            }
                                        </>

                                    }
                                </center>
                            </Col>
                        </>
                        : []}
                </Row>
                : []}
        </div>
    )
}
