import { 
    HTTP_REGISTER_INFORMATION_FETCHING, 
    HTTP_REGISTER_INFORMATION_SUCCESS, 
    HTTP_REGISTER_INFORMATION_FAILED, 
} from "../constants"

export const setRegisterInformationFetching = () => ({
    type: HTTP_REGISTER_INFORMATION_FETCHING,
})

export const setRegisterInformationSuccess = (payload) => ({
    type: HTTP_REGISTER_INFORMATION_SUCCESS,
    payload
})

export const setRegisterInformationFailed = () => ({
    type: HTTP_REGISTER_INFORMATION_FAILED,
})
