/* eslint-disable array-callback-return */
import { useState, useEffect } from "react"
import { Row, Col, Skeleton } from 'antd'
import "../pointView/css/PointViewItem.css"
import { Icon } from '@iconify/react'
import { getPointViewFetch } from "../../../modules/home/API"

export default function PointViewItem(props) {

    const [pointView, setPointView] = useState([])

    const getPointView = async () => { // Done
        const result = await getPointViewFetch()
        setPointView(result)
    }

    useEffect(() => {
        getPointView()
    }, [])

    return (
        <Row style={{ paddingBottom: 10 }}>
            <Col span={24} style={{ textAlign: 'center', paddingBottom: 12 }}>
                <label style={{ fontWeight: 600 }}>ความตั้งใจของเรา</label>
            </Col>

            <Col span={24} style={{ backgroundColor: "#F0F0F0", borderRadius: "10px 10px 0px 0px" }}>
                <div style={{ display: 'flex', alignItems: 'center', paddingTop: 12, paddingLeft: 12, paddingRight: 12 }}>
                    <Icon icon="pixelarticons:teach" style={{ color: "#23b14d", width: 25, height: 25 }} />
                    <label style={{ paddingLeft: 10, fontWeight: 600 }}>มุมผู้สอน</label>
                </div>
            </Col>
            <Col span={24} style={{ backgroundColor: "#F0F0F0", borderRadius: "0px 0px 10px 10px" }}>
                {pointView ?
                    <Row>
                        {pointView?.map((val, index) => {
                            if (val.pointviewUserTypeId === 2) {
                                return (
                                    <Col
                                        key={index}
                                        md={12} lg={12} xl={12} xxl={8}
                                        style={{ paddingRight: 5, paddingLeft: 5, paddingBottom: 10 }}
                                    >
                                        <Row>
                                            <Col span={24} style={{ paddingBottom: 24, paddingTop: 16 }}>
                                                <center>
                                                    <div className="zoom">
                                                        <img
                                                            style={{ height: "auto", width: "70%", borderRadius: 10 }}
                                                            className="course-img"
                                                            src={`./assets/images/pointview/img-pointview-${index + 1}.png`}
                                                            alt="pointvew img teacher"
                                                        />
                                                    </div>
                                                </center>
                                            </Col>
                                            <Col span={24} style={{ paddingLeft: 10, paddingRight: 10, fontWeight: 600 }}>
                                                <label className="card-title">{val.pointviewTitle}</label>
                                            </Col>
                                            <Col span={24} style={{ paddingLeft: 10, paddingRight: 10 }}>
                                                <label className="card-text">{val.pointviewDescription}</label>
                                            </Col>
                                        </Row>
                                    </Col>
                                )
                            }
                        })}
                    </Row>
                    :
                    <Row>
                        <Col md={24} lg={12} xl={12} xxl={8} style={{ paddingRight: 5, paddingLeft: 5, paddingBottom: 10 }}>
                            <div style={{ backgroundColor: "#EEEEEE", height: "auto", width: "100%", borderRadius: "10px 10px 0px 0px" }}></div>
                            <Skeleton active={true} paragraph={{ rows: 1 }} />
                        </Col>
                        <Col md={24} lg={12} xl={12} xxl={8} style={{ paddingRight: 5, paddingLeft: 5, paddingBottom: 10 }}>
                            <div style={{ backgroundColor: "#EEEEEE", height: "auto", width: "100%", borderRadius: "10px 10px 0px 0px" }}></div>
                            <Skeleton active={true} paragraph={{ rows: 1 }} />
                        </Col>
                        <Col md={24} lg={12} xl={12} xxl={8} style={{ paddingRight: 5, paddingLeft: 5, paddingBottom: 10 }}>
                            <div style={{ backgroundColor: "#EEEEEE", height: "auto", width: "100%", borderRadius: "10px 10px 0px 0px" }}></div>
                            <Skeleton active={true} paragraph={{ rows: 1 }} />
                        </Col>
                    </Row>
                }
            </Col>

            <Col span={24} style={{ backgroundColor: "#F0F0F0", borderRadius: "10px 10px 0px 0px", marginTop: 24 }}>
                <div style={{ display: 'flex', alignItems: 'center', paddingTop: 12, paddingLeft: 12, paddingRight: 12 }}>
                    <Icon icon="dashicons:welcome-learn-more" style={{ color: "#23b14d", width: 25, height: 25 }} />
                    <span style={{ paddingLeft: 10, fontWeight: 600 }}>มุมผู้เรียน</span>
                </div>
            </Col>
            <Col span={24} style={{ backgroundColor: "#F0F0F0", borderRadius: "0px 0px 10px 10px" }}>
                {pointView ?
                    <Row>
                        {pointView?.map((val, index) => {
                            if (val.pointviewUserTypeId === 1) {
                                return (
                                    <Col
                                        key={index}
                                        md={12} lg={12} xl={12} xxl={8}
                                        style={{ paddingRight: 5, paddingLeft: 5, paddingBottom: 10 }}
                                    >
                                        <Row>
                                            <Col span={24} style={{ paddingBottom: 24, paddingTop: 16 }}>
                                                <center>
                                                    <div className="zoom">
                                                        <img
                                                            style={{ height: "auto", width: "70%", borderRadius: 10 }}
                                                            className="course-img"
                                                            src={`./assets/images/pointview/img-pointview-${index + 1}.png`}
                                                            alt="pointvew img learner"
                                                        />
                                                    </div>
                                                </center>
                                            </Col>
                                            <Col span={24} style={{ paddingLeft: 10, paddingRight: 10, fontWeight: 600 }}>
                                                <label className="card-title">{val.pointviewTitle}</label>
                                            </Col>
                                            <Col span={24} style={{ paddingLeft: 10, paddingRight: 10 }}>
                                                <label className="card-text">{val.pointviewDescription}</label>
                                            </Col>
                                        </Row>
                                    </Col>
                                )
                            }
                        })}
                    </Row>
                    :
                    <Row>
                        <Col md={24} lg={12} xl={12} xxl={8} style={{ paddingRight: 5, paddingLeft: 5, paddingBottom: 10 }}>
                            <div style={{ backgroundColor: "#EEEEEE", height: "auto", width: "100%", borderRadius: "10px 10px 0px 0px" }}></div>
                            <Skeleton active={true} paragraph={{ rows: 1 }} />
                        </Col>
                        <Col md={24} lg={12} xl={12} xxl={8} style={{ paddingRight: 5, paddingLeft: 5, paddingBottom: 10 }}>
                            <div style={{ backgroundColor: "#EEEEEE", height: "auto", width: "100%", borderRadius: "10px 10px 0px 0px" }}></div>
                            <Skeleton active={true} paragraph={{ rows: 1 }} />
                        </Col>
                        <Col md={24} lg={12} xl={12} xxl={8} style={{ paddingRight: 5, paddingLeft: 5, paddingBottom: 10 }}>
                            <div style={{ backgroundColor: "#EEEEEE", height: "auto", width: "100%", borderRadius: "10px 10px 0px 0px" }}></div>
                            <Skeleton active={true} paragraph={{ rows: 1 }} />
                        </Col>
                    </Row>
                }
            </Col>
        </Row>
    )
}
