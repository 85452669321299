import "../feedback/css/feedbackItem.css"
import { Avatar, Empty } from "antd"
import { Comment } from '@ant-design/compatible'
import ScrollableFeed from 'react-scrollable-feed'
import { useDimensions } from "../../mamager/ScreenManager"

export default function FeedbackItem(props) {
    const { width } = useDimensions()

    return (
        <div style={{ height: 443 }}>
            <ScrollableFeed>
                <div style={width >= 1500 ? { paddingLeft: '5%', paddingRight: '5%', paddingTop: 15 } : ((width > 912 && width < 1500) ? { paddingLeft: '5%', paddingRight: '5%', paddingTop: 15 } : { paddingLeft: '0%', paddingRight: '0%', paddingTop: 15 })}>
                    {props.feedback?.map((data, index) => (
                        <center key={index}>
                            <div key={index} style={{ paddingBottom: 15 }}>
                                <Comment
                                    key={index}
                                    style={{ backgroundColor: 'white', width: (width >= 1500 ? "70%" : ((width > 912 && width < 1500) ? "80%" : "90%")), paddingLeft: 15, paddingRight: 15, borderRadius: 10 }}
                                    author={data.user.userName ? <label style={{ fontSize: 16, color: 'black' }}>{data.user.userName}</label> : null}
                                    avatar={
                                        <Avatar
                                            src={data.user.imageProfile}
                                            alt={data.user.userName ? data.user.userName : null}
                                        />
                                    }
                                    content={<p style={{ color: 'black', float: 'left' }}>{data.feedbackName}</p>}
                                />
                            </div>
                        </center>
                    ))}

                    {props.feedback?.length <= 0 ?
                        <>
                            <div style={{ paddingBottom: 50, width: '100%' }}>
                                <Empty
                                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                                    imageStyle={{
                                        height: 80
                                    }}
                                    style={{ width: '100%' }}
                                    description={
                                        <span style={{ color: 'gray' }}>ไม่มีความคิดเห็น</span>
                                    }
                                >
                                </Empty>
                            </div>
                        </>
                        : []}
                </div>
            </ScrollableFeed>
        </div>
    )
}
