/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import Layout from "antd/lib/layout/layout"
import { Button, Col, Row, DatePicker, Select, Form, Divider } from "antd"
import moment from 'moment'
import { Icon } from '@iconify/react'
import { useDimensions } from "../../common/mamager/ScreenManager"
import Header from "../../common/components/header"
import MonitorOverview from "./components/overview"
import EarnedSalesRevenue from "./components/earnedSalesRevenue"
import Footer from "../../common/components/footer"
import { detectSignIn } from "../../common/mamager/DetectManager"

const { RangePicker } = DatePicker
const { Option } = Select

const formatDateDB = "YYYY-MM-DD"

export default function CourseMonitor(props) {

    const navigate = useNavigate()

    const { width } = useDimensions()

    const [formInDepthFilter] = Form.useForm()

    const [inDepthActive, setInDepthActive] = useState(false)

    const [filter, setFilter] = useState({
        rangBasic: "2",
        rangAdvance: {
            startedDate: null,
            endDate: null
        },
        isInDepth: false
    })

    const onInDepthFilterFinish = async (values) => {
        let startedDate = moment(values.dateRange[0]).format(formatDateDB)
        let endDate = moment(values.dateRange[1]).format(formatDateDB)

        setFilter({
            rangBasic: undefined,
            rangAdvance: {
                startedDate,
                endDate
            },
            isInDepth: true
        })
    }

    const detectLogin = async () => {
        // detect login
        const isLogin = await detectSignIn()
        if (!isLogin) {
            navigate("/login")
        }
    }

    useEffect(() => {
        detectLogin()
    }, [])

    return (
        <Layout className="layout">
            <Header firebase={props.firebase} user={props.user} />

            <article>
                <>
                    <div style={{ padding: 15, backgroundColor: "#E5E5E5" }}>
                        <Row style={width >= 1500 ? { paddingLeft: '5%', paddingRight: '5%' } : ((width > 912 && width < 1500) ? { paddingLeft: '5%', paddingRight: '5%' } : { paddingLeft: '0%', paddingRight: '0%' })}>
                            <Col xs={24} sm={6} md={6} lg={6}>
                                <label style={{ marginTop: 2, fontSize: 20, marginBottom: 0, paddingBottom: 0 }}>วิเคราะห์เชิงลึก</label>
                            </Col>

                            <Col xs={24} sm={18} md={18} lg={18}>
                                <div style={width > 480 ? { paddingTop: 0 } : { paddingTop: 10 }}>
                                    <Button
                                        size="large"
                                        style={width > 480 ? { float: "right", width: 170 } : { width: '100%' }}
                                        type="primary"
                                        onClick={() => {
                                            if (inDepthActive) {
                                                setFilter({
                                                    rangBasic: "4",
                                                    rangAdvance: {
                                                        startedDate: null,
                                                        endDate: null
                                                    },
                                                    isInDepth: false
                                                })
                                            } else {
                                                formInDepthFilter.resetFields()
                                            }
                                            setInDepthActive(!inDepthActive)
                                        }}
                                    >
                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                            <Icon icon="akar-icons:calendar" style={{ color: "white", width: 23, height: 23 }} />
                                            <label style={{ marginLeft: 10, cursor: "pointer" }}>{inDepthActive ? "ปิด" : "เปิด"}กรองเชิงลึก</label>
                                        </div>
                                    </Button>
                                </div>

                                {!inDepthActive ?
                                    <Select
                                        size="large"
                                        value={filter.rangBasic}
                                        style={width > 480 ? { width: 180, float: "right", marginRight: 15 } : { width: '100%', paddingTop: 15 }}
                                        onChange={async (e) => {
                                            setFilter({
                                                rangBasic: e,
                                                rangAdvance: {
                                                    startedDate: null,
                                                    endDate: null
                                                },
                                                isInDepth: false
                                            });
                                        }}
                                    >
                                        <Option value="1">วันนี้</Option>
                                        <Option value="2">7 วันล่าสุด</Option>
                                        <Option value="3">30 วันล่าสุด</Option>
                                        <Option value="4">ตลอดอายุใช้งาน</Option>
                                    </Select>
                                    : []}
                            </Col>
                        </Row>
                    </div>

                    <Row>
                        {inDepthActive ?
                            <Col
                                span={24}
                                style={
                                    width >= 1500 ?
                                        { paddingLeft: '5%', paddingRight: '5%', padding: 15, backgroundColor: "white" }
                                        :
                                        (
                                            (width > 912 && width < 1500) ?
                                                { paddingLeft: '5%', paddingRight: '5%', padding: 15, backgroundColor: "white" }
                                                :
                                                { paddingLeft: 15, paddingRight: 15, padding: 15, backgroundColor: "white" }
                                        )
                                }
                            >
                                <Form
                                    layout="vertical"
                                    form={formInDepthFilter}
                                    onFinish={onInDepthFilterFinish}
                                >
                                    <Form.Item
                                        name="dateRange"
                                        label="ช่วงวันที่"
                                        rules={[{ required: true, message: 'กรุณากรอกช่วงวันที่' }]}
                                    >
                                        <RangePicker
                                            ranges={{
                                                "วันนี้": [moment(), moment()],
                                                'เดือนนี้': [moment().startOf('month'), moment().endOf('month')],
                                            }}
                                        />
                                    </Form.Item>
                                    <Divider style={{ marginTop: 12, marginBottom: 15 }} />
                                    <Button
                                        type="primary"
                                        style={{ float: "right" }}
                                        onClick={() => {
                                            formInDepthFilter.submit()
                                        }}
                                    >
                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                            <Icon icon="charm:search" style={{ color: "white", width: 18, height: 18 }} />
                                            <label style={{ paddingLeft: 10, cursor: "pointer" }}>ค้นหา</label>
                                        </div>
                                    </Button>
                                </Form>
                            </Col>
                            : []}
                    </Row>

                    <Row style={width >= 1500 ? { paddingLeft: '5%', paddingRight: '5%' } : ((width > 912 && width < 1500) ? { paddingLeft: '5%', paddingRight: '5%' } : { paddingLeft: 15, paddingRight: 15 })}>
                        <Col span={24} style={{ paddingTop: 15 }}>
                            <MonitorOverview
                                teacherId={props.user ? props.user.uid : null}
                                type={"advance"}
                                filter={filter}
                            />
                        </Col>

                        <Col span={24} style={{ paddingBottom: 15 }}>
                            <EarnedSalesRevenue
                                teacherId={props.user ? props.user.uid : null}
                                type={"advance"}
                                filter={filter}
                            />
                        </Col>
                    </Row>
                </>
            </article>

            <Footer />
        </Layout>
    )
}
