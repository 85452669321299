import { useState } from "react"
import FeedbackItem from "./FeedbackItem"
import { 
    Modal, 
    Form, 
    Input, 
    Button,
    Divider,
} from "antd"
import "../feedback/css/index.css"
import { Notification } from "../notification"
import { useDimensions } from "../../mamager/ScreenManager"
import { insertFeedbackFetch } from "./API"

const { TextArea } = Input

export default function Feedback(props) {
    const [form] = Form.useForm()
    const { width } = useDimensions()
    const [isModalVisible, setIsModalVisible] = useState(false)

    const showModal = () => {
        if (props.auth.currentUser !== null) {
            setIsModalVisible(true)
        } else {
            Notification("warning", "ระบบ", "กรุณาเข้าสู่ระบบก่อน !")
        }
    }

    const handleCancel = () => {
        setIsModalVisible(false)
    }

    const onFinish = async (values) => {
        props.onAddFeedback(values)

        // insert feedback
        await insertFeedbackFetch(values.feedbackName, props.auth.currentUser.uid)

        setIsModalVisible(false)

        form.setFieldsValue({ feedbackName: '' })
    }

    return (
        <>
            <Divider />
            <center><h4 className="feedback-title">ความคิดเห็นการใช้งาน</h4></center>
            <div style={{ width: width >= 1500 ? '70%' : ((width > 912 && width < 1500) ? '90%' : '100%'), backgroundColor: '#eeeeee', display: 'table', margin: '0 auto' }}>
                <FeedbackItem feedback={props.feedback} />
            </div>
            <center>
                <Button
                    type="primary"
                    onClick={showModal}
                    className="btn btn-open-modal-feedback"
                >
                    + เพิ่มความคิดเห็น
                </Button>
            </center>
            <div className="feedback-footer"></div>

            <Modal
                title="เเสดงความคิดเห็น"
                visible={isModalVisible}
                onCancel={handleCancel}
                width={1000}
                footer={
                    <Button
                        type="primary"
                        onClick={() => form.submit()}
                    >
                        ส่ง
                    </Button>
                }
            >
                <Form
                    form={form}
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                >
                    <Form.Item
                        name="feedbackName"
                        rules={[{ required: true, message: "กรุณากรอกความคิดเห็นของคุณ" }]}
                    >
                        <TextArea
                            placeholder="พิมพ์ข้อความ"
                            autoSize={{ minRows: 5, maxRows: 5 }}
                        />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
}
