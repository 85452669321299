/* eslint-disable react-hooks/exhaustive-deps */

import { useState, useEffect } from "react"
import {
    Page,
    Text,
    Image,
    Document,
    StyleSheet,
    View,
    Font
} from "@react-pdf/renderer"
import moment from 'moment'
import QRCode from "qrcode"
import { inspectCertificateUrl } from "./../../../../../constants"
import { getCourseOwnerFetch } from "./../../../../../modules/course/API"
import { getAccountVerifyIdentifyByIdFetch } from "./../../../../../modules/account/API"
import { getCourseCertificateByIdFetch, getThemeCertificateByIdFetch, updateCourseCertificateDownloadFirstTimeFetch } from "./../../../../../common/components/certificate/API"
import { formatCodeUnit } from "./../../../../../common/mamager/IdManager"
import { modifyDate } from "./../../../../../common/mamager/DateMamager"

import RobotoBold from "./../../../../../fonts/Roboto/Roboto-Bold.ttf"
import RobotoRegular from "./../../../../../fonts/Roboto/Roboto-Regular.ttf"
import RobotoThaiLaoBold from "./../../../../../fonts/Roboto/RobotoThaiLao-Bold.ttf"
import RobotoThaiLaoRegular from "./../../../../../fonts/Roboto/RobotoThaiLao-Regular.ttf"

Font.register({
    family: 'Roboto-Bold',
    src: RobotoBold
})

Font.register({
    family: 'Roboto-Regular',
    src: RobotoRegular
})

Font.register({
    family: 'RobotoThaiLao-Bold',
    src: RobotoThaiLaoBold
})

Font.register({
    family: 'RobotoThaiLao-Regular',
    src: RobotoThaiLaoRegular
})

const formatDate = "DD/MM/YYYY"

const styles = StyleSheet.create({
    body: {
        maxHeight: 440,
        maxWidth: 730
    },
    frame: {
        paddingTop: 20,
        paddingBottom: 20,
        paddingLeft: 40,
        paddingRight: 40
    },
    image_qr_code: {
        height: 130,
        width: 130,
        paddingLeft: 20
    },
    image_logo: {
        height: 95,
        width: '60%',
        marginLeft: 30
    },
    table: {
        width: '100%'
    },

    row_th: {
        display: 'flex',
        flexDirection: 'row',
        paddingTop: 0,
        paddingBottom: 15
    },
    row_tr: {
        display: 'flex',
        flexDirection: 'row',
        paddingTop: 15,
        paddingBottom: 15
    },
    row_tf: {
        display: 'flex',
        flexDirection: 'row',
        paddingTop: 15,
        paddingBottom: 0
    },
    //   -----------------------------------------------------------------
    id_cer: {
        width: '50%',
        fontFamily: 'Roboto-Regular',
        paddingTop: 3
    },
    logo_name: {
        width: '50%',
        textAlign: 'right',
        fontFamily: 'Roboto-Regular',
        paddingTop: 3
    },
    qr_code_img: {
        width: '23%',
        textAlign: 'center',
        marginTop: 100
    },
    row4: {
        width: '54%'
    },
    cer_ach: {
        width: '100%',
        textAlign: 'center',
        fontSize: 24,
        fontFamily: "Roboto-Bold"
    },
    learner_name: {
        width: '100%',
        textAlign: 'center',
        fontSize: 20,
        fontFamily: "RobotoThaiLao-Regular"
    },
    row7: {
        width: '23%',
        textAlign: 'center',
        marginTop: 110
    },
    by_course: {
        width: '100%',
        textAlign: 'center',
        fontSize: 20,
        fontFamily: "RobotoThaiLao-Bold"
    },
    course: {
        width: '100%',
        textAlign: 'center',
        fontSize: 20,
        fontFamily: "RobotoThaiLao-Regular"
    },
    date: {
        width: '100%',
        textAlign: 'center',
        fontSize: 20,
        fontFamily: "RobotoThaiLao-Regular"
    },
    code_course: {
        width: '50%',
        textAlign: 'center',
        fontFamily: "RobotoThaiLao-Regular"
    },
    teacher_name: {
        width: '50%',
        textAlign: 'center',
        fontFamily: "RobotoThaiLao-Regular"
    },
    teacher_name_right: {
        width: '20%',
        textAlign: 'center'
    },
    code_course_left: {
        width: '20%',
        textAlign: 'center'
    },
    verified: {
        width: '100%',
        textAlign: 'center',
        fontFamily: "RobotoThaiLao-Regular"
    },
    pageBackground: {
        position: 'absolute',
        minWidth: '100%',
        minHeight: '100%',
        display: 'block',
        height: '100%',
        width: '100%'
    }
})

export default function Template(props) {
    const [loading, setLoading] = useState(true)

    const [certificate, setCertificate] = useState([])

    const getRequestApi = async () => {
        setLoading(true)

        let certificateDownloadFirstTime = null

        let objCourseCertificate = {
            uid: props.uid,                         // uid of teacher
            courseId: props.courseId,               // courseId of teacher
            isActive: 1,
        }
        const resultCourseCertificate = await getCourseCertificateByIdFetch(objCourseCertificate)
        const resultCourseTeacher = await getCourseOwnerFetch(props.course.uid)                              // uid of teacher
        // console.log("getCourseOwnerFetch", resultCourseTeacher)
        const resultCourseLearner = await getAccountVerifyIdentifyByIdFetch(props.uid)                           // uid of learner
        // console.log("getAccountVerifyIdentifyByIdFetch", resultCourseLearner)
        const resultThemeCertificate = await getThemeCertificateByIdFetch(resultCourseCertificate.themeCertificateId)

        if (resultCourseCertificate.courseCertificateDownloadFirstTime) {
            certificateDownloadFirstTime = resultCourseCertificate.courseCertificateDownloadFirstTime
        } else {
            certificateDownloadFirstTime = moment()
            let objCourseCertificateDownloadFirstTime = {
                courseCertificateDownloadFirstTime: certificateDownloadFirstTime,
                uid: props.uid,                 // uid of teacher
                courseId: props.courseId,       // courseId of teacher
                isActive: 1,
            }
            await updateCourseCertificateDownloadFirstTimeFetch(objCourseCertificateDownloadFirstTime)
        }

        let learnerName = "-"
        if (resultCourseLearner) {
            if (resultCourseLearner.businessOperationId === 1) {
                learnerName = resultCourseLearner.idCardFirstName + ' ' + resultCourseLearner.idCardLastName
            } else if (resultCourseLearner.businessOperationId === 2) {
                learnerName = resultCourseLearner.companyNameTH
            }
        }

        let objDisplay = {
            learnerName,
            certificateCode: resultCourseCertificate ? resultCourseCertificate.courseCertificateCode : '-',
            certificateQRCode: resultCourseCertificate.courseCertificateCode ? await QRCode.toDataURL(inspectCertificateUrl + "?id=" + resultCourseCertificate.courseCertificateCode).then((data) => { return data }) : null,
            certificateDownloadFirstTime: modifyDate(moment(certificateDownloadFirstTime).format(formatDate)),
            courseName: props.course ? props.course.courseTitleName : '-',
            teacherName: resultCourseTeacher ? resultCourseTeacher.userName : '-',
            courseId: props.courseId ? props.courseId : '-',
            themeCertificate: resultThemeCertificate.themeCertificateUrl ? resultThemeCertificate.themeCertificateUrl : '-'
        }
        setCertificate(objDisplay)

        setLoading(false)
    }

    useEffect(() => {
        getRequestApi()
    }, [props])

    return (
        <>
            {!loading ?
                <Document height={100}>
                    <Page style={styles.body} size="C3">
                        <View style={styles.table}>
                            <Image
                                style={[styles.pageBackground]}
                                src={window.location.origin + `/assets/images/course-certificate/theme/${certificate.themeCertificate}`}
                            />

                            <View 
                                style={styles.frame}
                            >
                                <View style={styles.row_th}>
                                    <Text style={styles.id_cer}>
                                        ID : {certificate.certificateCode}
                                    </Text>
                                    <Text style={styles.logo_name}>
                                        TOFLYN
                                    </Text>
                                </View>

                                <View style={styles.row_th}>
                                    <Image
                                        style={[styles.image_qr_code, styles.qr_code_img]}
                                        src={certificate.certificateQRCode}
                                    />

                                    <View style={styles.row4}>
                                        <View style={styles.table}>
                                            <View style={styles.row_tr}>
                                                <Text style={styles.cer_ach}>CERTIFICATE OF ACHIEVEMENT</Text>
                                            </View>

                                            <View style={styles.row_tr}>
                                                <Text style={styles.learner_name}>{certificate.learnerName}</Text>
                                            </View>

                                            <View style={styles.row_tr}>
                                                <Text style={styles.by_course}>ได้สำเร็จหลักสูตร </Text>
                                            </View>

                                            <View style={styles.row_tr}>
                                                <Text style={styles.course}>{certificate.courseName}</Text>
                                            </View>

                                            <View style={styles.row_tr}>
                                                <Text style={styles.date}>ณ วันที่ {certificate.certificateDownloadFirstTime}</Text>
                                            </View>
                                        </View>
                                    </View>

                                    <View style={styles.row7}>
                                        <View style={styles.table}>
                                            <View style={styles.verified}>
                                                <Image
                                                    style={styles.image_logo}
                                                    src="./assets/images/logo/toflynUnBG.png"
                                                />
                                            </View>

                                            <View style={styles.row_tr}>
                                                <Text style={styles.verified}>Toflyn Verified</Text>
                                            </View>
                                        </View>
                                    </View>
                                </View>

                                <View style={styles.row_tr}>
                                    <Text style={styles.code_course_left}></Text>

                                    <View style={styles.row4}>
                                        <View style={styles.table}>
                                            <View style={styles.row_tr}>
                                                <Text style={styles.code_course}>รหัสหลักสูตร</Text>

                                                <Text style={styles.teacher_name}>ผู้สอน</Text>
                                            </View>
                                            <View style={styles.row_tr}>
                                                <Text style={styles.code_course}>{formatCodeUnit(certificate.courseId)}</Text>

                                                <Text style={styles.teacher_name}>{certificate.teacherName}</Text>
                                            </View>
                                        </View>
                                    </View>

                                    <Text style={styles.teacher_name_right}></Text>
                                </View>
                            </View>
                        </View>
                    </Page>
                </Document>
                : []}
        </>
    )
}

