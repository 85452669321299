/* eslint-disable react-hooks/exhaustive-deps */
import { Spin } from 'antd'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { calculateRemainingPeriodStudyCourse } from '../../../../common/mamager/TimeManager'
import { getCourseByIdFetch } from '../../../course/API'
import { getDetectCourseHasBeenPurchasedFetch } from '../../../coursShop/API'
import { getPaymentCreditDebitTransactionFetch } from '../../API'
import { Checkout } from '../checkout'

export default function OrderReceived() {

    const location = useLocation()
    const params = new URLSearchParams(location.search)
    const token = params.get("token")
    const couponId = params.get("couponId")

    const navigate = useNavigate()

    const [loading, setLoading] = useState(true)

    let language = "th"

    const amountFormat = (amount) => {
        const integer = amount.substring(0, (amount.toString().length - 2))
        const decimal = amount.substring((amount.toString().length - 2), amount.length)
        return integer + "." + decimal
    }

    const getCourseById = async (value) => {
        const result = await getCourseByIdFetch(value.courseId, language)
        // console.log("getCourseByIdFetch : ", result)
        return result
    }

    const getPaymentCreditDebitTransaction = async () => {
        let obj = { token }
        const resultPayment = await getPaymentCreditDebitTransactionFetch(obj)

        if (resultPayment) {
            let amount = resultPayment.amount ? amountFormat(resultPayment.amount.toString()) : null
            let isExpire = false

            const resultCourse = await getCourseById(resultPayment)

            // get detect expire 
            let obj = {
                learnerId: resultPayment.uid,
                courseId: resultPayment.courseId,
                isActive: 1,
            }
            const resultCourseHasBeenPurchased = await getDetectCourseHasBeenPurchasedFetch(obj)
            if (resultCourseHasBeenPurchased) {
                if (resultCourseHasBeenPurchased.endLearnDate) {
                    let remainingPeriod = calculateRemainingPeriodStudyCourse(new Date(), resultCourseHasBeenPurchased.endLearnDate)
                    if (remainingPeriod?.isExpire) {
                        isExpire = true;
                    }
                }
            }

            await Checkout(
                couponId ?? null,                   // ไอดีของคูปอง
                1,                                  // (1: คอร์สเรียน, ...)
                2,                                  // (วิธีการชำระเงิน -> [1: โอนเงิน, 2: บัตรเดบิต/เครดิต, 3: Paypal, 4: พร้อมเพย์])
                resultPayment.status,               // (สถานะการชำระเงิน -> [1,successful: ชำระเงินแล้ว, 2,pending: รอการชำระเงิน, 3,failed: ยกเลิกการชำระเงิน, 4,free: ฟรี (ไม่ชำระเงิน)])
                true,                               // (true คือ การชำระเงินสำเร็จเเละเสร็จสิ้นเเล้ว พร้อมเพิ่มคอร์สเรียนลงในรายการคอร์สของผู้เรียน เเละพร้อมเรียนได้ทันที)
                resultCourse,                       // ข้อมูลของผู้สอน
                { uid: resultPayment.uid, amount }, // ข้อมูลของผู้เรียน
                token,                              // charge token
                resultPayment.status,               // charge status
                moment(),                           // วันที่โอนดูจากสลิปธนาคาร หรือวันที่ชำระเงิน (today -> moment())
                false,                              // เปิดหน้า ธุรกรรมการชำระเงิน
                navigate,                           // redirect page
                null,                               // ภาพหลักฐานการโอนเงิน เฉพาะการโอนเงิน
                isExpire ? 1 : null,                // การต่อสัญญาจากหมดอายุเรียนคอร์ส -> [1: ต่อสัญญาเเล้ว, 2: รอต่อสัญญา, null: ซื้อคอร์สครั้งเเรก หรือเป็นคอร์สที่ไม่มีหมดอายุ]
            )
        } else {
            navigate(
                "/result",
                {
                    state: {
                        authorized: null,// props.authorized,
                        type: "warning",
                        title: "ไม่พบข้อมูลการชำระเงิน",
                        subTitle: "การชำระเงินนี้อาจมีการยกเลิก หรือดำเนินการชำระเงินเสร็จสิ้นเเล้ว",
                        btnWidth: 170,

                        btnName1: "ไปหน้าหลัก",
                        btnPathName1: "home",
                        btnIsActive1: true
                    }
                }
            )
        }
    }

    const getBaseApi = async () => {
        setLoading(true)

        await getPaymentCreditDebitTransaction()

        setLoading(false)
    }

    useEffect(() => {
        getBaseApi()
    }, [])

    return (
        <div style={{ paddingTop: "10%" }}>
            {!loading ? []
                :
                <>
                    <center>
                        <Spin size="large" style={{ paddingBottom: 10 }} />
                        <br />
                        <label>กำลังดำเนินการ โปรดรอสักครู่ ...</label>
                    </center>
                </>
            }
        </div>
    )
}
