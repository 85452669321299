/* eslint-disable react-hooks/exhaustive-deps */

import {
    Button,
    Card,
    Col,
    Divider,
    Image,
    Modal,
    Popconfirm,
    Row,
    Spin,
    Table,
    Collapse,
    Tabs
} from 'antd'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { FaCog } from "react-icons/fa"
import { Notification } from './../../../../../common/components/notification'
import { storageUrl } from './../../../../../constants'

import { 
    getEarnedSalesRevenueManageAllFetch,
    updateEarnedSalesRevenueManageByIdFetch
} from '../API'

const { Panel } = Collapse
const { TabPane } = Tabs
const dateFormat = 'DD/MM/YYYY'

export default function EarnedSalesRevenueManage() {
    const columns = [
        {
            title: 'รหัสการสั่งซื้อ',
            dataIndex: 'paymentOderId',
            width: "6%",
        },
        {
            title: 'รายการที่ซื้อ',
            dataIndex: 'orderName',
            width: "10%",
        },
        {
            title: 'ราคาคอร์ส (บาท)',
            dataIndex: 'price',
            width: "7%",
            render: (text, record) => {
                return Intl.NumberFormat('en').format(Number(text))
            }
        },
        {
            title: 'เงินของผู้สอน (บาท)',
            dataIndex: 'balanceAfterPayFee',
            width: "7%",
            render: (text, record) => {
                return Intl.NumberFormat('en').format(Number(text))
            }
        },
        {
            title: 'ค่าธรรมเนียม',
            dataIndex: 'fee',
            width: "7%",
            render: (text, record) => {
                return text + " %"
            }
        },
        {
            title: 'วันที่ซื้อผ่านตรวจสอบ',
            dataIndex: 'paymentDate',
            width: "7%",
            render: (text, record) => {
                return moment(text).format(dateFormat)
            }
        },
        {
            title: 'วันที่ผู้ขายได้รับเงิน',
            dataIndex: 'receivedDate',
            width: "7%",
            render: (text, record) => {
                return moment(text).add(0, "hour").format(dateFormat)
            }
        },
        {
            title: 'สถานะการรับเงิน',
            dataIndex: 'isReceivedName',
            width: "7%",
        },
        {
            title: <FaCog />,
            dataIndex: '',
            align: "center",
            width: "7%",
            render: (text, record) => {
                return (
                    <>
                        {!record.isReceived ?
                            <Button
                                type="primary"
                                style={{ width: 100 }}
                                onClick={() => {
                                    // console.log("record : ", record);
                                    setTranferModal({
                                        data: record,
                                        visible: true,
                                    })
                                }}
                            >โอนเงิน</Button>
                            :
                            <Popconfirm
                                title="คุณยืนยันบันทึกยกเลิกการโอนหรือไม่ ?"
                                okText={<span style={{ width: 50 }}>ใช่</span>}
                                onConfirm={async () => {
                                    setLoadingSend(true)

                                    const result = await updateEarnedSalesRevenueManageByIdFetch({
                                        isReceived: false,
                                        earnedSalesRevenueId: record.earnedSalesRevenueId,
                                    })
                                    if (result.isSuccess) {
                                        Notification("success", "ระบบ", "บันทึกการโอนเงินไปให้ผู้ขายสำเร็จ")
                                        getBaseApi()
                                    } else {
                                        Notification("error", "ระบบ", "บันทึกการโอนเงินไปให้ผู้ขายไม่สำเร็จ โปรดลองใหม่อีกครั้ง")
                                    }

                                    setLoadingSend(false)
                                }}
                                cancelText={<span style={{ width: 50 }}>ไม่ใช่</span>}
                            >
                                <Button
                                    type="default"
                                    loading={loadingSend}
                                    style={{ width: 110 }}
                                >
                                    {!loadingSend ? "ยกเลิกโอนเงิน" : []}
                                </Button>
                            </Popconfirm>
                        }
                    </>
                )
            }
        },
    ]

    const [tranferModal, setTranferModal] = useState({
        visible: false,
        data: null,
    })

    const [listResidualTransfer, setListResidualTransfer] = useState({
        data: [],
        sumPriceReceive: 0
    })
    const [listTodayTransfer, setListTodayTransfer] = useState({
        data: [],
        sumPriceReceive: 0
    })
    const [listTomorrowTransfer, setListTomorrowTransfer] = useState({
        data: [],
        sumPriceReceive: 0
    })
    const [listDayAfterTransfer, setListDayAfterTransfer] = useState({
        data: [],
        sumPriceReceive: 0
    })

    const [loading, setLoading] = useState(true)
    const [loadingSend, setLoadingSend] = useState(false)

    const [list, setList] = useState({
        detail: []
    })

    const getEarnedSalesRevenueManageAll = async () => {
        try {
            let obj = { language: "th" }
            const result = await getEarnedSalesRevenueManageAllFetch(obj)
            // console.log("getEarnedSalesRevenueManageAllFetch : ", result)

            let tmp = result.detail
            let sumPriceReceive = 0
            
            // รายการโอนตกค้าง
            sumPriceReceive = 0;
            tmp = result.detail.filter(val => (moment(new Date(), "YYYY-MM-DD").diff(moment(val.paymentDate, "YYYY-MM-DD"), "day") > 7 && !val.isReceived))
            tmp.map(val => sumPriceReceive += Number(val.balanceAfterPayFee))
            setListResidualTransfer({
                data: tmp ? tmp : [],
                sumPriceReceive
            })
            // console.log("tmp1 : ", tmp)

            // รายการโอนวันนี้
            sumPriceReceive = 0
            tmp = result.detail.filter(val => ((moment(new Date(), "YYYY-MM-DD").diff(moment(val.paymentDate, "YYYY-MM-DD"), "day") === 7)))
            tmp.map(val => sumPriceReceive += Number(val.balanceAfterPayFee))
            setListTodayTransfer({
                data: tmp ? tmp : [],
                sumPriceReceive
            })
            // console.log("tmp2 : ", tmp)

            // รายการโอนพรุ่งนี้
            sumPriceReceive = 0
            tmp = result.detail.filter(val => (moment(new Date(), "YYYY-MM-DD").diff(moment(val.paymentDate, "YYYY-MM-DD"), "day") === 6))
            tmp.map(val => sumPriceReceive += Number(val.balanceAfterPayFee))
            setListTomorrowTransfer({
                data: tmp ? tmp : [],
                sumPriceReceive
            })
            // console.log("tmp3 : ", tmp)

            // รายการโอนมะรืน
            sumPriceReceive = 0
            tmp = result.detail.filter(val => (moment(new Date(), "YYYY-MM-DD").diff(moment(val.paymentDate, "YYYY-MM-DD"), "day") === 5))
            tmp.map(val => sumPriceReceive += Number(val.balanceAfterPayFee))
            setListDayAfterTransfer({
                data: tmp ? tmp : [],
                sumPriceReceive
            })
            // console.log("tmp4 : ", tmp)

            setList(result)
        } catch (err) {
            // ...
        }
    }

    const getBaseApi = async () => {
        setLoading(true)

        await getEarnedSalesRevenueManageAll()

        setLoading(false)
    }

    useEffect(() => {
        getBaseApi()
    }, [])

    return (
        <>
            {!loading ?
                <>
                    <Row>
                        <Col span={24} style={{ paddingBottom: 5 }}>
                            <label>จัดการ รายได้ที่ได้รับจากการขาย</label>
                        </Col>

                        <Col span={24} style={{ paddingBottom: 5 }}>
                            <Card>
                                <Row style={{ paddingLeft: 5, paddingRight: 5, fontSize: 19 }}>
                                    <Col span={14}><label style={{ float: "left" }}>รายได้จากการขายคอร์สผู้สอนทั้งหมด  </label></Col>
                                    <Col span={6}><label style={{ float: "right", fontWeight: "bold" }}>{list ? Intl.NumberFormat("en", { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(list.sumPrice) + " บาท" : 0}</label></Col>
                                    <Col span={4}><label style={{ float: "right", fontWeight: "bold" }}>{list ? Intl.NumberFormat("en", { maximumFractionDigits: 0, minimumFractionDigits: 0 }).format(list.countPrice) : []} รายการ</label></Col>

                                    <Col span={14}><label style={{ float: "left" }}>รายได้จากการขายคอร์สผู้สอนทั้งหมด (หักค่าธรรมเนียมเเล้ว)  </label></Col>
                                    <Col span={6}><label style={{ float: "right", fontWeight: "bold" }}>{list ? Intl.NumberFormat("en", { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(list.sumBalanceAfterPayFee) + " บาท" : 0}</label></Col>
                                    <Col span={4}><label style={{ float: "right", fontWeight: "bold" }}></label></Col>

                                    <Col span={14}><label style={{ float: "left" }}>ราคาคอร์สทั้งหมดที่ยังไม่ได้โอนให้ผู้สอน (หักค่าธรรมเนียมเเล้ว)  </label></Col>
                                    <Col span={6}><label style={{ float: "right", fontWeight: "bold" }}>{list ? Intl.NumberFormat("en", { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(list.sumNotReceived) + " บาท" : 0}</label></Col>
                                    <Col span={4}><label style={{ float: "right", fontWeight: "bold" }}>{list ? Intl.NumberFormat("en", { maximumFractionDigits: 0, minimumFractionDigits: 0 }).format(list.countNotReceived) : []} รายการ</label></Col>

                                    <Col span={14}><label style={{ float: "left" }}>ราคาคอร์สทั้งหมดที่โอนให้ผู้สอนเเล้ว (หักค่าธรรมเนียมเเล้ว)  </label></Col>
                                    <Col span={6}><label style={{ float: "right", fontWeight: "bold" }}>{list ? Intl.NumberFormat("en", { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(list.sumReceived) + " (" + Intl.NumberFormat("en", { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format((list.sumReceived * 100) / list.sumBalanceAfterPayFee) + "%) บาท" : 0}</label></Col>
                                    <Col span={4}><label style={{ float: "right", fontWeight: "bold" }}>{list ? Intl.NumberFormat("en", { maximumFractionDigits: 0, minimumFractionDigits: 0 }).format(list.countReceived) : []} รายการ</label></Col>

                                    <Col span={14}><label style={{ float: "left", paddingBottom: 0, marginBottom: 0 }}>รายได้เข้าบริษัทที่มาจากค่าธรรมเนียมทั้งหมด (หักค่าธรรมเนียมของ Omise 3.9% ออกเเล้ว) </label></Col>
                                    <Col span={6}><label style={{ float: "right", fontWeight: "bold", paddingBottom: 0, marginBottom: 0 }}>{list ? Intl.NumberFormat("en", { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(list.sumChargeFee) + " (" + Intl.NumberFormat("en", { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format((list.sumChargeFee * 100) / list.sumPrice) + "%) บาท" : 0}</label></Col>
                                    <Col span={4}><label style={{ float: "right", fontWeight: "bold", paddingBottom: 0, marginBottom: 0 }}></label></Col>
                                </Row>
                            </Card>
                        </Col>
                        <Col span={24}>
                            <label>* โอนเงินค่าคอร์สให้ผู้ขายหลังจากผ่านไปเเล้ว 7 วัน หรืออย่างช้าที่สุด 10 วัน</label>
                        </Col>
                    </Row >

                    <Collapse ghost defaultActiveKey={['1']}>
                        <Panel header={<h6>รายการที่ต้องโอนเงินให้ผู้สอน ในเเต่ละช่วง</h6>} key="1">
                            <Tabs defaultActiveKey="1">
                                <TabPane tab="รายการโอนตกค้าง" key="1">
                                    <label style={{ float: "left" }}>ราคารวมที่ต้องโอนของตกค้าง</label>
                                    <label style={{ float: "right" }}>{Intl.NumberFormat("en", { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(listResidualTransfer.sumPriceReceive)} บาท</label>
                                    <Table
                                        loading={loading}
                                        columns={columns}
                                        dataSource={listResidualTransfer.data}
                                        pagination={{
                                            pageSize: 5
                                        }}
                                    ></Table>
                                </TabPane>
                                <TabPane tab={<span style={{ fontWeight: "bold" }}>รายการโอนวันนี้</span>} key="2">
                                    <label style={{ float: "left" }}>ราคารวมที่ต้องโอนวันนี้</label>
                                    <label style={{ float: "right" }}>{Intl.NumberFormat("en", { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(listTodayTransfer.sumPriceReceive)} บาท</label>
                                    <Table
                                        loading={loading}
                                        columns={columns}
                                        dataSource={listTodayTransfer.data}
                                        pagination={{
                                            pageSize: 5
                                        }}
                                    ></Table>
                                </TabPane>
                                <TabPane tab="รายการโอนพรุ่งนี้" key="3">
                                    <label style={{ float: "left" }}>ราคารวมที่ต้องโอนพรุ่งนี้</label>
                                    <label style={{ float: "right" }}>{Intl.NumberFormat("en", { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(listTomorrowTransfer.sumPriceReceive)} บาท</label>
                                    <Table
                                        loading={loading}
                                        columns={columns}
                                        dataSource={listTomorrowTransfer.data}
                                        pagination={{
                                            pageSize: 5
                                        }}
                                    ></Table>
                                </TabPane>
                                <TabPane tab="รายการโอนมะรืน" key="4">
                                    <label style={{ float: "left" }}>ราคารวมที่ต้องโอนมะรืน</label>
                                    <label style={{ float: "right" }}>{Intl.NumberFormat("en", { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(listDayAfterTransfer.sumPriceReceive)} บาท</label>
                                    <Table
                                        loading={loading}
                                        columns={columns}
                                        dataSource={listDayAfterTransfer.data}
                                        pagination={{
                                            pageSize: 5
                                        }}
                                    ></Table>
                                </TabPane>
                            </Tabs>
                        </Panel>
                    </Collapse>

                    <Collapse ghost defaultActiveKey={['1']}>
                        <Panel header={<h6>รายการทั้งหมด</h6>} key="1">
                            <Table
                                loading={loading}
                                columns={columns}
                                dataSource={list.detail}
                                pagination={{
                                    pageSize: 10
                                }}
                            ></Table>
                        </Panel>
                    </Collapse>
                </>
                :
                <div style={{ padding: 200, width: "100%" }}>
                    <center>
                        <Spin size="large" />
                    </center>
                </div>
            }

            <Modal
                maskClosable={false}
                title="โอนเงิน"
                visible={tranferModal.visible}
                width={800}
                // loading={tranferLoading}
                onCancel={() => {
                    // close modal
                    setTranferModal({
                        visible: false,
                        data: null,
                    });
                }}
                footer={[

                    <Popconfirm
                        title="คุณยืนยันบันทึกการโอนหรือไม่ ?"
                        okText={<span style={{ width: 50 }}>ใช่</span>}
                        onConfirm={async () => {
                            setLoadingSend(true);

                            let obj = {
                                isReceived: true,
                                earnedSalesRevenueId: tranferModal.data.earnedSalesRevenueId,
                            }
                            // console.log("obj : ", obj);
                            const result = await updateEarnedSalesRevenueManageByIdFetch(obj);
                            // console.log("updateEarnedSalesRevenueManageByIdFetch : ", result);

                            if (result?.isSuccess) {
                                Notification("success", "ระบบ", "บันทึกการโอนเงินไปให้ผู้ขายสำเร็จ");

                                // reload data
                                getBaseApi();

                                // close modal
                                setTranferModal({
                                    data: null,
                                    visible: false,
                                });
                            } else {
                                Notification("error", "ระบบ", "บันทึกการโอนเงินไปให้ผู้ขายไม่สำเร็จ โปรดลองใหม่อีกครั้ง");
                            }

                            setLoadingSend(false);
                        }}
                        cancelText={<span style={{ width: 50 }}>ไม่ใช่</span>}
                    >
                        <Button
                            type="primary"
                            loading={loadingSend}
                        >
                            {!loadingSend ? "โอนเงินไปยังผู้ขาย" : []}
                        </Button>
                    </Popconfirm>
                ]}
            >
                <Row>
                    <Col span={13}>
                        <Row>
                            <Col span={24}><label>ข้อมูลธุรกรรม</label></Col>

                            <Col span={9}><label style={{ float: "right" }}>ผู้ขาย : </label></Col>
                            <Col span={15}><label style={{ paddingLeft: 10 }}>{tranferModal.data?.sellerName}</label></Col>

                            <Col span={9}><label style={{ float: "right" }}>ผู้ซื้อ : </label></Col>
                            <Col span={15}><label style={{ paddingLeft: 10 }}>{tranferModal.data?.buyerName}</label></Col>

                            <Col span={9}><label style={{ float: "right" }}>ซื้อด้วย : </label></Col>
                            <Col span={15}><label style={{ paddingLeft: 10 }}>{tranferModal.data?.paymentMethodTypeNameTH}</label></Col>

                            <Col span={9}><label style={{ float: "right" }}>ประเภทซื้อ : </label></Col>
                            <Col span={15}><label style={{ paddingLeft: 10 }}>{tranferModal.data?.paymentTypeNameTH}</label></Col>

                            <Col span={9}><label style={{ float: "right" }}>สถานะการซื้อ : </label></Col>
                            <Col span={15}><label style={{ paddingLeft: 10 }}>{tranferModal.data?.paymentStatusNameTH}</label></Col>

                            <Divider style={{ marginTop: 12, marginBottom: 5 }} />

                            <Col span={9}><label style={{ float: "right", marginTop: 11 }}>หักค่าธรรมเนียม : </label></Col>
                            <Col span={15}><label style={{ paddingLeft: 10, fontSize: 22, color: "green" }}>{tranferModal.data?.fee}</label> % คิดเป็น {Intl.NumberFormat('en').format(tranferModal.data?.price - tranferModal.data?.balanceAfterPayFee)} บาท</Col>

                            <Col span={9}><label style={{ float: "right", marginTop: 9 }}>จำนวนเงินโอนให้ผู้ขาย : </label></Col>
                            <Col span={15}><label style={{ paddingLeft: 10, paddingRight: 10, fontSize: 22, color: "green" }}>{Intl.NumberFormat('en').format(tranferModal.data?.balanceAfterPayFee)}</label> บาท</Col>
                        </Row>
                    </Col>

                    <Col span={11}>
                        <Row>
                            <Col span={24}><label>บัญชีธนาคารของผู้รับเงิน</label></Col>

                            <Col span={7}><label style={{ float: "right" }}>ธนาคาร : </label></Col>
                            <Col span={17}><label style={{ paddingLeft: 10 }}>{tranferModal.data?.bankCategoryId}</label></Col>

                            <Col span={7}><label style={{ float: "right" }}>ชื่อบัญชี : </label></Col>
                            <Col span={17}><label style={{ paddingLeft: 10 }}>{tranferModal.data?.bankAccountName}</label></Col>

                            <Col span={7}><label style={{ float: "right" }}>เลขที่บัญชี : </label></Col>
                            <Col span={17}><label style={{ paddingLeft: 10 }}>{tranferModal.data?.bankAccountId}</label></Col>

                            <Col span={7}><label style={{ float: "right" }}>ภาพบัญชี : </label></Col>
                            <Col span={17}>
                                <label style={{ paddingLeft: 10 }}>
                                    <Image
                                        src={`${tranferModal.data?.fileBankAccountLocation}`}
                                        style={{ width: "100%" }}
                                    />
                                </label>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Modal>
        </>
    )
}
