/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import { Col, Row, Image, Button } from 'antd'
import { useEffect, useState } from 'react'
import Footer from '../../common/components/footer'
import { getSalepageByIdFetch } from './API'
import { useLocation } from "react-router-dom"
import Video from '../../common/components/video'
import { PathVideo } from '../../common/components/video/components/convert/pathVideo'
import { Icon } from '@iconify/react'
import parse from 'html-react-parser'
import { storageUrl } from '../../constants'
import { useNavigate } from 'react-router-dom'
import { useDimensions } from '../../common/mamager/ScreenManager'

export default function Salepage(props) {

    const location = useLocation()
    const params = new URLSearchParams(location.search)
    const courseId = params.get("course-id")

    const navigate = useNavigate()
    const { width } = useDimensions()

    const [loading, setLoading] = useState(true)
    const [course, setCourse] = useState(null)
    const [salepageDetails, setSalepageDetails] = useState(null)
    const [salepageImages, setSalepageImages] = useState([])
    const [salepageVideos, setSalepageVideos] = useState([])

    const handleTimeVideo = (timeVideo, displayTimeVideo) => { }
    const handlePercentWatchVideoCourse = (timeVideoCourse, timerWatchVideoCourse, percent, isFirstLoadApi, VideoCourseList, timerWatchVideoCourseList) => { }
    const handlePlayer = (player) => { }
    const handleControls = (controls) => { }

    const getSalepageById = async () => {
        setLoading(true)
        const result = await getSalepageByIdFetch(courseId)

        setCourse(result.course)
        setSalepageDetails(result.salepage_details)
        setSalepageImages(result.salepage_images)
        setSalepageVideos(result.salepage_videos)

        setLoading(false)
    }

    const getBaseApi = async () => {
        await getSalepageById()
    }
    
    const genCourseInfo = (type) => {
        return (
            <Row 
                style={{ 
                    backgroundColor: "#23b14d", 
                    color: "white", 
                    padding: 12,
                    borderTopLeftRadius: (type === "top" ? 0 : 8),
                    borderTopRightRadius: (type === "top" ? 0 : 8),
                    borderBottomLeftRadius: (type === "top" ? 8 : 0),
                    borderBottomRightRadius: (type === "top" ? 8 : 0)
                }}
            >
                {type === "top" ? 
                    <>
                        <Col span={24} style={{ textAlign: "left" }}>
                            <span style={{ fontWeight: "bold" }}>{course.courseTitleName}</span>
                        </Col>

                        <Col span={24} style={{ textAlign: "left" }}>
                            <span style={{}}>{course.courseSubDescriptionName}</span>
                        </Col>
                    </>
                :
                    <>
                        <Col xs={24} sm={8} md={8} lg={6} style={{ textAlign: "left" }}>
                            <img src={`${course.courseImageLocation}`} style={{ width: '100%' }} />
                        </Col>

                        <Col xs={24} sm={16} md={16} lg={18} style={{ textAlign: "left", paddingLeft: 16 }}>
                            <Row>
                                <Col span={24} style={{ textAlign: "left" }}>
                                    <span style={{ fontWeight: "bold" }}>{course.courseTitleName}</span>
                                </Col>

                                <Col span={24} style={{ textAlign: "left" }}>
                                    <span style={{}}>{course.courseSubDescriptionName}</span>
                                </Col>

                                <Col span={24} style={{ textAlign: "left", paddingTop: 12 }}>
                                    <Row style={{ backgroundColor: "#009116", padding: 12, borderRadius: 8 }}>
                                        <Col xs={24} sm={24} md={14} lg={18} style={{ textAlign: "left" }}>
                                            <Row>
                                                <Col span={24} style={{ textAlign: "left" }}>
                                                    <label style={{ color: "#EEEEEE", fontSize: 18 }}>ราคาปกติ <span style={{ color: "white", fontWeight: 'bold' }}>{Intl.NumberFormat("en", { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format((course.coursePrice))}</span> บาท</label>
                                                </Col>
                                                <Col span={24} style={{ textAlign: "left" }}>
                                                    <label style={{ color: "#EEEEEE", fontWeight: "bold", fontSize: 20 }}>ลดราคาเหลือ <span style={{ color: "white", fontWeight: 'bold' }}>{Intl.NumberFormat("en", { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format((course.coursePriceDiscount))}</span> บาท</label>
                                                </Col>
                                            </Row>
                                        </Col>

                                        <Col xs={24} sm={24} md={10} lg={6} style={{ textAlign: "left" }}>
                                            <Button
                                                type="primary"
                                                size="large"
                                                style={{ width: 170, height: 55, marginTop: 8 }}
                                                onClick={() => {
                                                    navigate("/payment?course-id=" + course.courseId)
                                                }}
                                            >
                                                <Icon icon="icons8:buy" style={{ color: "white", width: 24, height: 24 }} />
                                                <label style={{ paddingLeft: 5, cursor: "pointer", fontSize: 24 }}>ซื้อคอร์สนี้</label>
                                            </Button>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </>
                }
            </Row>
        )
    }

    const genSalepageDetails = (item, index) => {
        return (
            <div style={{ paddingTop: 6, paddingBottom: 6 }}>
                <Row style={{ backgroundColor: "white", borderRadius: 10, border: "2px solid #23b14d", padding: 6 }} >
                    <Col span={24} style={{ textAlign: "left" }}>
                        <span style={{}}>{parse(item.detail)}</span>
                    </Col>
                </Row>
            </div>
        )
    }

    const genSalepageImages = (item, index) => {
        return (
            <div style={{ paddingTop: 6, paddingBottom: 6 }}>
                <Row style={{ backgroundColor: "white", borderRadius: 10, border: "2px solid #23b14d" }}>
                    <Col span={24} style={{ textAlign: "left", padding: 6, backgroundColor: "#23b14d", color: "white", borderTopLeftRadius: 6, borderTopRightRadius: 8 }}>
                        <span style={{}}>{item.imageGroupTitle}</span>
                    </Col>
                    {item?.salepage_image_items?.map(img => {
                        return (
                            <Col xs={12} sm={12} md={6} lg={6} style={{ textAlign: "center", padding: 6 }}>
                                <Image
                                    width={'100%'}
                                    src={img.url}
                                    // fallback={`${course.courseImageUrl}`}
                                    // fallback={img.url}
                                />
                            </Col>
                        )
                    })}
                </Row>
            </div>
        )
    }

    const genSalepageVideos = (item, index) => {
        return (
            <div style={{ paddingTop: 6, paddingBottom: 6 }}>
                <Row style={{ backgroundColor: "white", borderRadius: 10, border: "2px solid #23b14d" }}>
                    <Col span={24} style={{ textAlign: "left", padding: 6, backgroundColor: "#23b14d", color: "white", borderTopLeftRadius: 6, borderTopRightRadius: 8 }}>
                        <span style={{}}>{item.title}</span>
                    </Col>
                    {item?.salepage_video_items?.map(vdo => {
                        return (
                            <Col span={24} style={{ textAlign: "center", padding: 6 }}>
                                <Video
                                    courseId={null}
                                    learnerId={null}
                                    videoTitle=""
                                    videoLocation={PathVideo(vdo.url)}
                                    component={'course-shop'}
                                    width="100%"
                                    isPlaying={true}
                                    isBreak={false}
                                    timerRunning={false}
                                    handleTimeVideo={handleTimeVideo}
                                    handlePercentWatchVideoCourse={handlePercentWatchVideoCourse}
                                    handlePlayer={handlePlayer}
                                    handleControls={handleControls}
                                />
                            </Col>
                        )
                    })}
                </Row>
            </div>
        )
    }

    useEffect(() => {
        getBaseApi()
    }, [])

    return (
        <>
            <header>
                <nav
                    style={{
                        paddingLeft: 0,
                        paddingRight: 0,
                        paddingTop: 0,
                        paddingBottom: 0,
                        height: 50,
                        position: 'fixed',
                        zIndex: 1000,
                        width: '100%',
                        backgroundColor: '#fff',
                        overflow: 'auto',
                        boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.2)',
                    }}
                >
                    <Row>
                        <Col xs={4} sm={8} md={8} lg={12} >
                            <img
                                src="./assets/images/logo/toflynAddName.png"
                                style={{ width: 150, height: 47, marginLeft: 15, padding: 3, cursor: 'pointer' }}
                                onClick={() => {
                                    navigate("/home")
                                }}
                            />
                        </Col>

                        <Col xs={18} sm={16} md={16} lg={12}>
                            <Row style={{ float: "right", marginTop: 2 }}>
                                <Col span={24}>
                                    <div style={{ float: "right", paddingRight: 16 }}>
                                        <Button
                                            type="primary"
                                            size='middle'
                                            style={{ width: 150, marginTop: 7 }}
                                            onClick={() => {
                                                navigate("/payment?course-id=" + course.courseId)
                                            }}
                                        >
                                            <Icon icon="icons8:buy" style={{ color: "white", width: 20, height: 20 }} />
                                            <label style={{ paddingLeft: 5, cursor: "pointer" }}>ซื้อคอร์สนี้</label>
                                        </Button>
                                    </div>
                                    {/* <label style={{ color: "#a9a9a9", fontSize: 13 }}>ราคาปกติ {Intl.NumberFormat("en", { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format((course.coursePrice))} บาท</label> */}
                                    {/* <label s tyle={{ color: "#23b14d", fontWeight: "bold", fontSize: 16 }}>ลดราคาเหลือ {Intl.NumberFormat("en", { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format((course.coursePriceDiscount))} บาท</label> */}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </nav>
            </header>
            <article>
                {!loading ?
                    <div 
                        style={{ 
                            paddingLeft: (width > 992 ? '5%' : 12), 
                            paddingRight: (width > 992 ? '5%' : 12), 
                            backgroundColor: "#EEEEEE" 
                        }}
                    >
                        <div style={{ paddingBottom: 6 }}>
                            {genCourseInfo("top")}
                        </div>

                        <div style={{}}>
                            {salepageDetails?.map(((item, index) => { return genSalepageDetails(item, index) }))}
                        </div>

                        <div style={{}}>
                            {salepageImages?.map(((item, index) => { return genSalepageImages(item, index) }))}
                        </div>

                        <div style={{}}>
                            {salepageVideos?.map(((item, index) => { return genSalepageVideos(item, index) }))}
                        </div>

                        <div style={{ paddingTop: 6 }}>
                            {genCourseInfo(null)}
                        </div>
                    </div>
                    : []}
            </article>
            <footer>
                <Footer />
            </footer>
        </>
    )
}
