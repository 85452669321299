import { Notification } from "./../../../common/components/notification"
import { server, apiStorageUrl } from "./../../../constants"
import { httpClient } from "./../../../utils/HttpClient"
import Axios from 'axios'

const getAdminByIdFetch = async (username, password) => { // Done
    try {
        const result = await httpClient.post(server.GET_ADMIN_BY_ID_URL, { username, password })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getAdminAllFetch = async (language) => { // Done
    try {
        const result = await httpClient.get(server.GET_ADMIN_ALL_URL + `/${language}`)
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getTokenByIdFetch = async (username, token) => { // Done
    try {
        const result = await httpClient.post(server.GET_TOKEN_BY_ID_FETCH_URL, { username, token })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getDepartmentAllFetch = async (language) => { // Done
    try {
        const result = await httpClient.get(server.GET_DEPARTMENT_ALL_FETCH_URL + `/${language}`)
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getAdminTypeAllFetch = async (language) => { // Done
    try {
        const result = await httpClient.get(server.GET_ADMIN_TYPE_ALL_FETCH_URL + `/${language}`)
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getAnnouncementAllFetch = async () => { // Done
    try {
        const result = await httpClient.get(server.GET_ANNCUNCEMENT_ALL_URL)
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getInspectionRequestOpenCourseFetch = async (value, language) => { // Done
    try {
        const result = await httpClient.post(server.GET_INSPECTION_REQUEST_OPEN_COURSE_ALL_URL, { value, language })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getInspectionCourseCategoryFetch = async (language) => { // Done
    try {
        const result = await httpClient.get(server.GET_INSPECTION_COURSE_CATEGORY_URL + `/${language}`)
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getInspectionCourseApprovalCategoryFetch = async (language) => { // Done
    try {
        const result = await httpClient.get(server.GET_INSPECTION_COURSE_APPROVAL_CATEGORY_URL + `/${language}`)
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getAdminInspectOpenCourseByIdFetch = async (value, language) => { // 
    try {
        const result = await httpClient.post(server.GET_ADMIN_INSPECTION_OPEN_COURSE_BY_ID_URL, { value, language })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getCourseInspectFetch = async (value, userType) => { // value[uid, courseId, isActive, language] : 
    try {
        const result = await httpClient.post(server.GET_COURSE_INSPECT_BY_ID_URL, { value, userType })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getCourseInspectMediaIntroFetch = async (value, userType) => { // value[uid, courseId, isActive, language] : 
    try {
        const result = await httpClient.post(server.GET_COURSE_INSPECT_MEDIA_INTRO_BY_ID_URL, { value, userType })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getCourseInspectDocumentApiFetch = async (value, userType) => { // value[uid, courseId, isActive, language] : 
    try {
        const result = await httpClient.post(server.GET_COURSE_INSPECT_DOCUMENT_BY_ID_URL, { value, userType })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getCourseInspectVideoListFetch = async (value, userType) => { // value[uid, courseId, isActive, language] : 
    try {
        const result = await httpClient.post(server.GET_COURSE_INSPECT_VIDEO_BY_ID_URL, { value, userType })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getInspectFileRegistrationCertificateFetch = async (value) => {
    try {
        const result = await httpClient.post(server.GET_INSPECT_FILE_REGISTRATION_CERTIFICATE_URL, { value })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getCourseInspectChapterFetch = async (value, userType) => { // value[courseId, isActive, language] : 
    try {
        const result = await httpClient.post(server.GET_COURSE_INSPECT_CHAPTER_BY_ID_URL, { value, userType })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getInspectRequestEditCourseAllFetch = async (type, value) => {
    try {
        const result = await httpClient.post(server.GET_INSPECTION_REQUEST_EDIT_COURSE_ALL_URL, { type, value })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getInspectRequestEditCourseByIdFetch = async (value, language) => {
    try {
        const result = await httpClient.post(server.GET_INSPECTION_REQUEST_EDIT_COURSE_BY_ID_URL, { value, language })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getStatusInspectRequestEditCourseByIdFetch = async (value) => {
    try {
        const result = await httpClient.post(server.GET_STATUS_INSPECTION_REQUEST_EDIT_COURSE_BY_ID_URL, { value })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getInspectFormRequestOpenCourseAllFetch = async (value, language) => {
    try {
        const result = await httpClient.post(server.GET_INSPECTION_FORM_REQUEST_OPEN_COURSE_ALL_URL, { value, language })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getInspectFormRequestOpenCourseByIdFetch = async (value, language) => {
    try {
        const result = await httpClient.post(server.GET_INSPECTION_FORM_REQUEST_OPEN_COURSE_BY_ID_URL, { value, language })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getInspectAccountVerifyFetch = async (type, value) => {
    try {
        const result = await httpClient.post(server.GET_INSPECT_ACCOUNT_VERIFY_URL, { type, value })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getOpenCourseTokenByIdFetch = async (id) => {
    try {
        const result = await httpClient.post(server.GET_OPEN_COURSE_TOKEN_BY_ID_URL, { id })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getInspectFeedbackFetch = async (userType, value) => {
    try {
        const result = await httpClient.post(server.GET_INSPECT_FEEDBACK_ALL, { value, userType })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getInspectPaymentOrderTransferAllFetch = async (value, language) => {
    try {
        const result = await httpClient.post(server.GET_INSPECT_PAYMENT_ORDER_TRANSFER_ALL_URL, { value, language })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getManagePaymentOrderAllFetch = async (value, language) => {
    try {
        const result = await httpClient.post(server.GET_MANAGE_PAYMENT_ORDER_ALL_URL, { value, language })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getPaymentStatusFetch = async (language) => {
    try {
        const result = await httpClient.post(server.GET_PAYMENT_STATUS_URL, { language })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
} 

const getFeePercentageCategoryFetch = async (language) => {
    try {
        const result = await httpClient.post(server.GET_FEE_PERCENTAGE_CATEGORY_URL, { language })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getManageReservedWordAllFetch = async (value) => {
    try {
        const result = await httpClient.post(server.GET_MANAGE_RESERVED_WORD_ALL_URL, { value })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const updateAdminByIdFetch = async (value) => {
    try {
        const result = await httpClient.put(server.UPDATE_ADMIN_BY_ID_URL, { value })
        if (result.data.isSuccess) {
            Notification("success", "ระบบ", "เเก้ไข Admin สำเร็จ")
        } else {
            Notification("error", "ระบบ", "ไม่สามารถเเก้ไข Admin ได้ กรุณาตรวจสอบข้อมูล")
        }
    } catch (err) { // status 404
        return null
    }
}

const updateTokenByIdFetch = async (token) => {
    try {
        const result = await httpClient.put(server.UPDATE_TOKEN_BY_ID_URL, { token })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const updateTokenByUsernameFetch = async (username) => {
    try {
        const result = await httpClient.put(server.UPDATE_TOKEN_BY_USERNAME_URL, { username })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const updateAnnouncementFetch = async (value) => {
    try {
        const result = await httpClient.put(server.UPDATE_ANNCUNCEMENT_BY_ID_URL, { value })
        if (result.data.isSuccess) {
            Notification("success", "ระบบ", "เเก้ไขข่าวประกาศ สำเร็จ")
        } else {
            Notification("error", "ระบบ", "ไม่สามารถเเก้ไขข่าวประกาศได้ กรุณาตรวจสอบข้อมูล")
        }
    } catch (err) { // status 404
        return null
    }
}

const updateInspectRequestOpenCourseCardByIdFetch = async (value) => { 
    try {
        const result = await httpClient.put(server.UPDATE_COURSE_INSPECT_CARD_BY_ID_URL, { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const updateInspectRequestOpenCoursePriceByIdFetch = async (value) => { 
    try {
        const result = await httpClient.put(server.UPDATE_COURSE_INSPECT_PRICE_BY_ID_URL, { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const updateInspectRequestOpenCourseDescriptionByIdFetch = async (value) => { 
    try {
        const result = await httpClient.put(server.UPDATE_COURSE_INSPECT_DESCRIPTION_BY_ID_URL, { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const updateInspectRequestOpenCourseMediaIntroByIdFetch = async (value) => { 
    try {
        const result = await httpClient.put(server.UPDATE_COURSE_INSPECT_MEDIA_INTRO_BY_ID_URL, { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const updateInspectRequestOpenCourseDocumentByIdFetch = async (value) => { 
    try {
        const result = await httpClient.put(server.UPDATE_COURSE_INSPECT_DOCUMENT_BY_ID_URL, { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const updateInspectRequestOpenCourseVideoByIdFetch = async (value) => { 
    try {
        const result = await httpClient.put(server.UPDATE_COURSE_INSPECT_VIDEO_BY_ID_URL, { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const updateInspectRequestOpenCourseChapterByIdFetch = async (value) => { 
    try {
        const result = await httpClient.put(server.UPDATE_COURSE_INSPECT_CHAPTER_BY_ID_URL, { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const updateCourseInspectFetch = async (value) => { 
    try {
        const result = await httpClient.put(server.UPDATE_COURSE_INSPECT_BY_ID_URL, { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const updateCourseOverdueFetch = async (value) => { 
    try {
        const result = await httpClient.put(server.UPDATE_COURSE_OVERDUE_BY_ID_URL, { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const updateCourseInspectRequestEditByIdFetch = async (value) => {
    try {
        const result = await httpClient.post(server.UPDATE_COURSE_INSPECT_REQUEST_EDIT_BY_ID_URL, { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const updateInspectRequestEditCourseCardByIdFetch = async (value) => {
    try {
        const result = await httpClient.put(server.UPDATE_INSPECT_REUEST_EDIT_COURSE_CARD_BY_ID_URL, {value})
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const updateInspectRequestEditCourseDescriptionByIdFetch = async (value) => {
    try {
        const result = await httpClient.put(server.UPDATE_INSPECT_REUEST_EDIT_COURSE_DESCRIPTION_BY_ID_URL, {value})
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const updateInspectRequestEditCoursePriceByIdFetch = async (value) => {
    try {
        const result = await httpClient.put(server.UPDATE_INSPECT_REUEST_EDIT_COURSE_PRICE_BY_ID_URL, {value})
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const updateInspectRequestEditCourseMediaIntroByIdFetch = async (value) => {
    try {
        const result = await httpClient.put(server.UPDATE_INSPECT_REUEST_EDIT_COURSE_MEDIA_INTRO_BY_ID_URL, {value})
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const updateInspectRequestEditCourseDocumentByIdFetch = async (value) => {
    try {
        const result = await httpClient.put(server.UPDATE_INSPECT_REUEST_EDIT_COURSE_DOCUMENT_BY_ID_URL, {value})
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const updateInspectRequestEditCourseVideoByIdFetch = async (value) => {
    try {
        const result = await httpClient.put(server.UPDATE_INSPECT_REUEST_EDIT_COURSE_VIDEO_BY_ID_URL, {value})
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const updateInspectRequestEditCourseChapterByIdFetch = async (value) => {
    try {
        const result = await httpClient.put(server.UPDATE_INSPECT_REUEST_EDIT_COURSE_CHAPTER_BY_ID_URL, {value})
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const updateInspectAccountVerifyFetch = async (value) => {
    try {
        const result = await httpClient.put(server.UPDATE_INSPECT_ACCOUNT_VERIFY_URL, {value})
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const updateInspectAccountVerifyIdentifyFetch = async (value) => {
    try {
        const result = await httpClient.put(server.UPDATE_INSPECT_ACCOUNT_VERIFY_IDENTIFY_URL, {value})
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const updateInspectFileRegistrationCertificateFetch = async (value) => {
    try {
        const result = await httpClient.put(server.UPDATE_INSPECT_FILE_REGISTRATION_CERTIFICATE_URL, {value})
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const updateInspectAccountVerifyBankFetch = async (value) => {
    try {
        const result = await httpClient.put(server.UPDATE_INSPECT_ACCOUNT_VERIFY_BANK_URL, {value})
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const updateIsHasBeenAccountVerifiedBankById = async (value) => {
    try {
        const result = await httpClient.put(server.UPDATE_IS_HAS_BEEN_ACCOUNT_VERIFIED_BANK_BY_ID_URL, {value})
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const updateInspectFormRequestOpenCourseFetch = async (value) => {
    try {
        const result = await httpClient.put(server.UPDATE_INSPECTION_FORM_REQUEST_OPEN_COURSE_URL, { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const updateInspectFormRequestOpenCourseIsOverdueFetch = async (value) => {
    try {
        const result = await httpClient.put(server.UPDATE_INSPECTION_FORM_REQUEST_OPEN_COURSE_IS_OVERDUE_URL, { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const updateOpenCourseTokenByIdFetch = async (id) => { // id is formRegOpenCourseId
    try {
        const result = await httpClient.put(server.UPDATE_OPEN_COURSE_TOKEN_BY_ID_URL, { id })
        if (result.data.isSuccess) {
            return result
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const updateInspectFeedbackFetch = async (value) => {
    try {
        const result = await httpClient.post(server.UPDATE_INSPECT_FEEDBACK_BY_ID_URL, { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const updateInspectPaymentOrderFetch = async (value) => {
    try {
        const result = await httpClient.put(server.UPDATE_INSPECT_PAYMENT_ORDER_URL, { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const updateManageReservedWordByIdFetch = async (value) => {
    try {
        const result = await httpClient.put(server.UPDATE_MANAGE_RESERVED_WORD_BY_Id_URL, { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

const insertTokenByIdFetch = async (value) => {
    try {
        const result = await httpClient.post(server.INSERT_TOKEN_BY_ID_URL, { value })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const insertAdminByIdFetch = async (value) => {
    try {
        const result = await httpClient.post(server.INSERT_ADMIN_BY_ID_URL, { value })
        if (result.data.isSuccess) {
            Notification("success", "ระบบ", "เพิ่ม Admin สำเร็จ")
        } else {
            Notification("error", "ระบบ", "ไม่สามารถเพิ่ม Admin ได้ กรุณาตรวจสอบข้อมูล")
        }
    } catch (err) { // status 404
        return null
    }
}

const insertAnnouncementFetch = async (value) => {
    try {
        const result = await httpClient.post(server.INSERT_ANNCUNCEMENT_BY_ID_URL, { value })
        if (result.data.isSuccess) {
            Notification("success", "ระบบ", "เพิ่มข่าวประกาศ สำเร็จ")
        } else {
            Notification("error", "ระบบ", "ไม่สามารถเพิ่มข่าวประกาศได้ กรุณาตรวจสอบข้อมูล")
        }
    } catch (err) { // status 404
        return null
    }
}

const insertOpenCourseTokenByIdFetch = async (value) => {
    try {
        const result = await httpClient.post(server.INSERT_OPEN_COURSE_TOKEN_BY_ID_URL, { value })
        if (result.data.isSuccess) {
            return result
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const insertPayFeeFetch = async (value) => {
    try {
        const result = await httpClient.post(server.INSERT_PAY_FEE_URL, { value })
        if (result.data.isSuccess) {
            return result.data
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const insertManageReservedWordFetch = async (value) => {
    try {
        const result = await httpClient.post(server.INSERT_MANAGE_RESERVED_WORD_URL, { value })
        if (result.data.isSuccess) {
            return result.data
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const deleteAdminByIdFetch = async (id) => {
    try {
        const result = await httpClient.delete(server.DELETE_ADMIN_BY_ID_URL + `/${id}`)
        if (result.data.isSuccess) {
            Notification("success", "ระบบ", "ลบ Admin สำเร็จ")
        } else {
            Notification("error", "ระบบ", "ไม่สามารถลบ Admin ได้ กรุณาตรวจสอบข้อมูล")
        }
    } catch (err) { // status 404
        return null
    }
}

const deleteTokenByIdFetch = async () => {
    try {
        const result = await httpClient.delete(server.DELETE_TOKEN_BY_ID_URL)
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const detectRepeatTokenFetch = async (token) => { // Done
    try {
        const result = await httpClient.post(server.DETECT_REPEAT_TOKEN_URL, { token })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const deleteAnnouncementFetch = async (id) => {
    try {
        const result = await httpClient.delete(server.DELETE_ANNCUNCEMENT_BY_ID_URL + `/${id}`)
        if (result.data.isSuccess) {
            Notification("success", "ระบบ", "ลบข่าวประกาศ สำเร็จ")
        } else {
            Notification("error", "ระบบ", "ไม่สามารถลบข่าวประกาศได้ กรุณาตรวจสอบข้อมูล")
        }
    } catch (err) { // status 404
        return null
    }
}

const detectRepeatOpenCourseTokenFetch = async (token, uid) => { // Done
    try {
        const result = await httpClient.post(server.DETECT_REPEAT_OPEN_COURSE_TOKEN_URL, { token, uid })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const deleteFeedbackFetch = async (id) => {
    try {
        const result = await httpClient.delete(server.DELETE_FEEDBACK_BY_ID_URL + `/${id}`)
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const deletePaymentOrderFetch = async (id) => {
    try {
        const result = await httpClient.delete(server.DELETE_PAYMENT_ORDER_BY_ID_URL + `/${id}`)
        if (result.data.isSuccess) {
            return result.data
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const deleteManageReservedWordByIdFetch = async (id) => {
    try {
        const result = await httpClient.delete(server.DELETE_MANAGE_RESERVED_WORD_BY_Id_URL + `/${id}`)
        if (result.data.isSuccess) {
            return result.data
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const removeFileByIdFetch = async (path, sliceMin, sliceMax) => {
    try {
        const pathRoot = `uploaded/${path}`
        const pathSplit = pathRoot.split("/")
        const pathMap = pathSplit.slice(sliceMin, sliceMax).join('/')

        const formDataREM = new FormData()
        formDataREM.append('pathFolder', pathMap)
        formDataREM.append('pathFile', pathRoot)
        const result = await Axios.post(`${apiStorageUrl}/${server.DELETE_FILE_BY_Id_URL}`, formDataREM, {
            headers: { 'Content-Type': 'multipart/form-data' }
        })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const pauseAdminByIdFetch = async (value) => {
    let type = value.isActive ? "เปิดการใช้" : "ปิดการใช้"
    try {
        const result = await httpClient.put(server.PAUSE_ADMIN_BY_ID_URL, { value })
        if (result.data.isSuccess) {
            Notification("success", "ระบบ", type + " Admin สำเร็จ")
        } else {
            Notification("error", "ระบบ", "ไม่สามารถ" + type + " Admin ได้ กรุณาตรวจสอบข้อมูล")
        }
    } catch (err) { // status 404
        return null
    }
}

const sendLineNotifyToGroup = async (username, token) => {
    try {
        const result = await httpClient.post(server.SEND_LINE_NOTIFY_TO_GROUP, { username, token })
        if (result.data.isSuccess) {
            return result.data.formData;
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const lineNotifyPaymentConsiderationResult = async (value) => {
    try {
        const result = await httpClient.post(server.LINE_NOTIFY_PAYMENT_CONSIDERATION_RESULT_URL, { value })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const lineNotifyPaymentOrderManageResult = async (value) => {
    try {
        const result = await httpClient.post(server.LINE_NOTIFY_PAYMENT_ORDER_MANAGE_RESULT_URL, { value })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

export {
    // get
    getAdminByIdFetch,
    getAdminAllFetch,
    getTokenByIdFetch,
    getDepartmentAllFetch,
    getAdminTypeAllFetch,
    getAnnouncementAllFetch,
    getInspectionRequestOpenCourseFetch,
    getInspectionCourseCategoryFetch,
    getInspectionCourseApprovalCategoryFetch,
    getAdminInspectOpenCourseByIdFetch,
    getCourseInspectFetch,
    getCourseInspectMediaIntroFetch,
    getCourseInspectDocumentApiFetch,
    getCourseInspectVideoListFetch,
    getInspectFileRegistrationCertificateFetch,
    getCourseInspectChapterFetch,
    getInspectRequestEditCourseAllFetch,
    getInspectRequestEditCourseByIdFetch,
    getStatusInspectRequestEditCourseByIdFetch,
    getInspectFormRequestOpenCourseAllFetch,
    getInspectFormRequestOpenCourseByIdFetch,
    getInspectAccountVerifyFetch,
    getOpenCourseTokenByIdFetch,
    getInspectFeedbackFetch,
    getInspectPaymentOrderTransferAllFetch,
    getPaymentStatusFetch,
    getFeePercentageCategoryFetch,
    getManageReservedWordAllFetch,
    getManagePaymentOrderAllFetch,

    // insert
    insertTokenByIdFetch,
    insertAdminByIdFetch,
    insertAnnouncementFetch,
    insertOpenCourseTokenByIdFetch,
    insertPayFeeFetch,
    insertManageReservedWordFetch,

    // update
    updateAdminByIdFetch,
    updateTokenByIdFetch,
    updateTokenByUsernameFetch,
    updateAnnouncementFetch,
    updateInspectRequestOpenCourseCardByIdFetch,
    updateInspectRequestOpenCoursePriceByIdFetch,
    updateInspectRequestOpenCourseDescriptionByIdFetch,
    updateInspectRequestOpenCourseMediaIntroByIdFetch,
    updateInspectRequestOpenCourseDocumentByIdFetch,
    updateInspectRequestOpenCourseVideoByIdFetch,
    updateInspectRequestOpenCourseChapterByIdFetch,
    updateCourseInspectFetch,
    updateCourseOverdueFetch,
    updateCourseInspectRequestEditByIdFetch,
    updateInspectRequestEditCourseCardByIdFetch,
    updateInspectRequestEditCourseDescriptionByIdFetch,
    updateInspectRequestEditCoursePriceByIdFetch,
    updateInspectRequestEditCourseMediaIntroByIdFetch,
    updateInspectRequestEditCourseDocumentByIdFetch,
    updateInspectRequestEditCourseVideoByIdFetch,
    updateInspectRequestEditCourseChapterByIdFetch,
    updateInspectAccountVerifyFetch,
    updateInspectAccountVerifyIdentifyFetch,
    updateInspectFileRegistrationCertificateFetch,
    updateInspectAccountVerifyBankFetch,
    updateIsHasBeenAccountVerifiedBankById,
    updateInspectFormRequestOpenCourseFetch,
    updateInspectFormRequestOpenCourseIsOverdueFetch,
    updateOpenCourseTokenByIdFetch,
    updateInspectFeedbackFetch,
    updateInspectPaymentOrderFetch,
    updateManageReservedWordByIdFetch,

    // delete
    deleteAdminByIdFetch,
    deleteTokenByIdFetch,
    detectRepeatTokenFetch,
    deleteAnnouncementFetch,
    detectRepeatOpenCourseTokenFetch,
    deleteFeedbackFetch,
    deletePaymentOrderFetch,
    deleteManageReservedWordByIdFetch,

    // remove
    removeFileByIdFetch,

    pauseAdminByIdFetch,
    sendLineNotifyToGroup,
    lineNotifyPaymentConsiderationResult,
    lineNotifyPaymentOrderManageResult
}