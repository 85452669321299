/* eslint-disable jsx-a11y/anchor-is-valid */
import { Breadcrumb } from 'antd'

export default function TutorialPlatformBreadcrumb(props) {
    return (
        <>
            <Breadcrumb>
                <Breadcrumb.Item>
                    <a href="">การใช้งานเเพลตฟอร์ม Toflyn</a>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    {props.pathName}
                </Breadcrumb.Item>
            </Breadcrumb>
        </>
    )
}
