/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */

import "../../css/manageMediaIntro.css"
import { useState, useEffect, useRef } from 'react'
import { AiOutlineQuestionCircle } from "react-icons/ai"
import { LoadingOutlined, UploadOutlined } from '@ant-design/icons'
import { useDispatch, useSelector } from "react-redux"
import {
    Row,
    Col,
    Form,
    Button,
    Input,
    Upload,
    Popconfirm,
    Spin,
    Popover,
    Divider,
} from 'antd'
import { Icon } from '@iconify/react'
import { setCourseManageAddCurrentStepStateToSuccess } from "../../../../actions/courseManageAddCurrentStep.action"
import { setCourseManageIntroStateToSuccess } from "../../../../actions/courseManageIntro.action"
import { Notification } from "../../../../common/components/notification"
import Video from "../../../../common/components/video"
import { PathVideo } from "../../../../common/components/video/components/convert/pathVideo"
import { useDimensions } from "../../../../common/mamager/ScreenManager"
import { apiStorageUrl, bucketName } from "../../../../constants"
import { getCourseManageMediaIntroFetch, updateCourseMediaIntroFetch, updateRequestOpenCourseMediaIntroAdjustFetch } from "../../API"

export default function ManageMediaIntro(props) {
    const dispatch = useDispatch()

    const { width } = useDimensions()

    const [courseMediaIntro, setcourseMediaIntro] = useState(null)
    const [user, setUser] = useState({ uid: null })
    const [isStepActive, setIsStepActive] = useState(null)
    const [courseInspect, setcourseInspect] = useState(null)
    const [switchType, setSwitchType] = useState(null)

    const [loading, setLoading] = useState(true)

    const [form] = Form.useForm()

    const [duration, setDuration] = useState({
        timeVideo: null,
        displayTimeVideo: null
    })

    const { resultCourseManageAddCurrentStep } = useSelector(({ courseManageAddCurrentStepReducer }) => courseManageAddCurrentStepReducer)
    const { resultIntro } = useSelector(({ courseManageIntroReducer }) => courseManageIntroReducer)

    const initialAdd = {
        title: resultIntro ? resultIntro.title : undefined,
    }

    const initialEdit = {
        title: courseMediaIntro ? courseMediaIntro.courseMediaIntroTitleName : undefined,
    }

    const submitTypeRef = useRef(null);

    const [mediaCourseIntro, setMediaCourseIntro] = useState({
        loading: false,
        videoUrl: null
    })

    const nextStepRef = useRef(false)

    const option = {
        name: 'file',
        action: `${apiStorageUrl}/upload/file`,
        data: {
            // uploaded/video/course-video-intro
            bucket: bucketName,
            folder: "video/course/intro"
        },
        headers: {
            authorization: 'authorization-text',
        },
        onChange(info) {
            if (info.file.status === 'done') {
                let result = info.file.response;
                console.log("info : ", info.file.response)
                if (result?.isSuccess) {
                    console.log("info.file.response.filePath : ", result.url)
                    setMediaCourseIntro({
                        videoUrl: result.url,
                        loading: false,
                    })
                }
            } else {
                setMediaCourseIntro({
                    loading: true,
                    videoUrl: mediaCourseIntro.videoUrl
                })
            }

            if (info.file.status === 'done') {
                Notification("success", "ระบบ", "อัพโหลดสื่อนำเสนอคอร์สสำเร็จ")
            } else if (info.file.status === 'error') {
                Notification("error", "ระบบ", "อัพโหลดสื่อนำเสนอคอร์สไม่สำเร็จ กรุณาลองใหม่อีกครั้ง")
            }
        },
        progress: {
            strokeColor: {
                '0%': '#108ee9',
                '100%': '#87d068',
            },
            strokeWidth: 3,
            width: '10%',
            format: percent => `${parseFloat(percent.toFixed(0))}%`,
        },
    }

    const onInputChangeCourseIntroTitle = (value) => {
        setReducer(value, mediaCourseIntro.videoUrl, duration)
    }

    const onFinish = async (values) => {
        if (submitTypeRef.current === 'update') {
            // get data latest
            const courseMediaIntroLatest = await getCourseManageMediaIntroFetch(user.uid, courseMediaIntro.courseId);

            let obj = {
                // new
                courseMediaIntroTitleName: values.title,
                courseMediaIntroLocation: mediaCourseIntro.videoUrl,

                // old
                courseMediaIntroId: courseMediaIntroLatest.courseMediaIntroId,
                approvalStatusId: courseMediaIntroLatest.approvalStatusId,
                courseMediaIntroCategoryId: courseMediaIntroLatest.courseMediaIntroCategoryId,
                courseMediaIntroRank: courseMediaIntroLatest.courseMediaIntroRank,
                courseMediaIntroDuration: duration.timeVideo,
                created_at: new Date(courseMediaIntroLatest.created_at),
                updated_at: new Date(courseMediaIntroLatest.updated_at),
                view: courseMediaIntroLatest.view, // ***
            }

            if (switchType === "adjust-course") {
                const resultUpdate = await updateRequestOpenCourseMediaIntroAdjustFetch(
                    user.uid,                           // uid
                    [obj],                              // course media intro
                    1,                                  // inspectionCourseAdjustCategoryId
                    1,                                  // isActive
                    courseMediaIntro.courseId,          // courseId
                )

                if (resultUpdate.data.isSuccess) {
                    Notification("success", "ระบบ", "บันทึกการปรับเเก้สำเร็จ")
                } else {
                    Notification("error", "ระบบ", "บันทึกการปรับเเก้ไม่สำเร็จ")
                }
            } else {
                const resultUpdate = await updateCourseMediaIntroFetch(
                    user.uid,                           // uid
                    obj,                                // course media intro
                    1,                                  // isActive
                    courseMediaIntro.courseId,          // courseId
                    "courseMediaIntro",                 // courseComponent
                )

                if (resultUpdate.data.isSuccess) {
                    Notification("success", "ระบบ", "บันทึกการปรับเเต่งสำเร็จ")
                } else {
                    Notification("error", "ระบบ", "บันทึกการปรับเเต่งไม่สำเร็จ")
                }
            }
        }

        // set reducer
        setReducer(values.title, mediaCourseIntro.videoUrl, duration)

        if (nextStepRef.current) {
            // set session
            localStorage.setItem("stepsIntroClickable", 1)

            dispatch(setCourseManageAddCurrentStepStateToSuccess(Number(resultCourseManageAddCurrentStep) + 1))
        } else {
            dispatch(setCourseManageAddCurrentStepStateToSuccess(Number(resultCourseManageAddCurrentStep) - 1))
        }

        if (!isStepActive) {
            // reload
            props.handleCourseMediaIntroReload()
        }
    }

    const setReducer = (title, location, duration) => {
        // reducer
        dispatch(setCourseManageIntroStateToSuccess({ title, location, duration }))
    }

    const detectMediaCourseIntro = () => {
        if (nextStepRef.current) { // next tab
            if (mediaCourseIntro.videoUrl) {
                return Promise.resolve()
            } else {
                return Promise.reject(new Error('กรุณาอัพโหลดวิดีโอ'))
            }
        } else { // back tab
            return Promise.resolve()
        }
    };

    const detectNameCourseIntro = (rules, value) => {
        if (nextStepRef.current) {
            if (value) { // next tab
                return Promise.resolve()
            } else {
                return Promise.reject(new Error('กรุณากรอกชื่อวิดีโอ'))
            }
        } else { // back tab
            return Promise.resolve()
        }
    }

    const handleTimeVideo = (duration) => {
        setDuration(duration)
    }

    const handlePercentWatchVideoCourse = (timeVideoCourse, timerWatchVideoCourse, percent, isFirstLoadApi, VideoCourseList, timerWatchVideoCourseList) => { }
    const handlePlayer = (player) => { }
    const handleControls = (controls) => { }

    const genBtnUpload = () => {
        return (
            <Upload
                {...option}
                accept='.mp4'
                maxCount={1}
                showUploadList={{ showRemoveIcon: false }}
            >
                <Button
                    type="primary"
                    style={{ width: "100%" }}
                    icon={mediaCourseIntro.loading ? <LoadingOutlined /> : <UploadOutlined />}
                    disabled={
                        switchType === "adjust-course" ?
                            (props.courseInspect ?
                                (props.courseInspect.courseMediaIntro.inspectionCourseApprovalIdSummary === 1 ? true : false) : false)
                            :
                            (switchType === "edit-course" ?
                                (props.courseInspect ?
                                    (props.courseInspect.inspectionCourseId === 2 && props.courseInspect.inspectionCourseApprovalId === 2 ? true : false) : false) : false)
                    }
                >อัพโหลด</Button>
            </Upload>
        )
    }

    useEffect(() => {
        setLoading(true)

        setTimeout(function () {
            setcourseMediaIntro(props.courseMediaIntro)
            setcourseInspect(props.courseInspect)
            setUser(props.user)
            setIsStepActive(props.isStepActive)
            setSwitchType(props.switchType)

            if (props.isStepActive) {
                if (resultIntro) {
                    setMediaCourseIntro({
                        videoUrl: resultIntro ?
                            (resultIntro.location ? resultIntro.location : null)
                            : null,
                        loading: false,
                    })
                }
            } else {
                setMediaCourseIntro({
                    videoUrl: props.courseMediaIntro ?
                        (props.courseMediaIntro.courseMediaIntroLocation ? props.courseMediaIntro.courseMediaIntroLocation : null)
                        : null,
                    loading: false,
                })
            }

            setLoading(false)
        }, 500)
    }, [])

    const genMediaIntro = () => {
        return (
            <>
                <Form
                    layout="vertical"
                    form={form}
                    initialValues={isStepActive ? initialAdd : initialEdit}
                    onFinish={onFinish}
                >
                    <Row style={{ textAlign: 'left' }}>
                        <Col xs={24} lg={12}>
                            <Row>
                                <Col span={24}>
                                    <label style={{ color: "red", paddingRight: 4 }}>*</label>
                                    <label>วิดีโอนำเสนอ</label>
                                </Col>
                                <Col span={24}>
                                    {mediaCourseIntro.videoUrl ?
                                        <Video
                                            courseId={null}
                                            learnerId={null}

                                            videoTitle=""
                                            videoLocation={PathVideo(mediaCourseIntro.videoUrl)}
                                            component={"upload-video"}
                                            handleTimeVideo={handleTimeVideo}
                                            handlePercentWatchVideoCourse={handlePercentWatchVideoCourse}
                                            handlePlayer={handlePlayer}
                                            handleControls={handleControls}
                                        />
                                        :
                                        <img
                                            style={{ width: "100%" }}
                                            src={`./assets/images/default/df-vdo.png`}
                                        />
                                    }
                                </Col>

                                <Col span={24}>
                                    <div style={{ paddingTop: 12 }}>
                                        <Form.Item
                                            name="mediaCourseIntro"
                                            rules={[{ required: true, validator: detectMediaCourseIntro }]}
                                        >
                                            {courseInspect ?
                                                (courseInspect.inspectionCourseId !== 2 && courseInspect.inspectionCourseApprovalId !== 2 ?
                                                    genBtnUpload()
                                                    :
                                                    ((courseMediaIntro.approvalStatusId === 1 || courseMediaIntro.approvalStatusId === 2) && courseInspect.inspectionCourseId === 3 ?
                                                        genBtnUpload()
                                                        :
                                                        !props.inspectCourseApprovalId && !courseInspect.courseMediaIntro.inspectionCourseAdjustIdSummary && courseInspect.inspectionCourseId === 2 ?
                                                            genBtnUpload()
                                                            :
                                                            <Button style={{ width: 200 }} icon={<UploadOutlined />} type="primary" disabled={true}>อัพโหลด</Button>
                                                    )
                                                )
                                                :
                                                genBtnUpload()
                                            }
                                        </Form.Item>
                                    </div>
                                </Col>

                                <Col span={24}>
                                    <Form.Item
                                        name="title"
                                        label={
                                            <>
                                                <div>ชื่อวิดีโอ </div>
                                                <div style={{ paddingLeft: 10 }}> (จำกัดตัวอักษร 100 ตัว)</div>
                                            </>
                                        }
                                        rules={[{ required: true, validator: detectNameCourseIntro }]}
                                    >
                                        <Input
                                            maxLength={100}
                                            placeholder="กรอกชื่อวิดีโอ"
                                            onBlur={(e) => {
                                                form.setFieldsValue({ title: e.target.value })
                                                onInputChangeCourseIntroTitle(e.target.value)
                                            }}
                                            disabled={
                                                (switchType === "adjust-course" ?
                                                    (props.courseInspect ?
                                                        (props.courseInspect.courseMediaIntro.inspectionCourseApprovalIdSummary === 1 || props.courseInspect.inspectionCourseId === 2 ? true : false) : false)
                                                    :
                                                    (switchType === "edit-course" ?
                                                        courseInspect ?
                                                            (courseInspect.inspectionCourseId !== 2 && courseInspect.inspectionCourseApprovalId !== 2 ?
                                                                false
                                                                :
                                                                ((courseMediaIntro.approvalStatusId === 1 || courseMediaIntro.approvalStatusId === 2) && courseInspect.inspectionCourseId === 3 ?
                                                                    false
                                                                    :
                                                                    (!props.inspectCourseApprovalId && !courseInspect.courseMediaIntro.inspectionCourseAdjustIdSummary && courseInspect.inspectionCourseId === 2 ?
                                                                        false
                                                                        :
                                                                        true
                                                                    )
                                                                )
                                                            )
                                                            :
                                                            false
                                                        :
                                                        false
                                                    )
                                                )
                                            }
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Form>

                <Divider style={{ marginTop: 1, marginBottom: 15 }} />
                <div style={{ fontSize: 14 }}>
                    <label>หมายเหตุ !</label><br />
                    <ul>
                        <li>ส่วนของ "สื่อนำเสนอ" ใช้บอกกล่าว เเละโน้มน้าวผู้เรียนให้ทราบถึงเรื่องที่จะสอนเเบบภาพรวม เเละสิ่งที่ผู้เรียนจะได้รับ</li>
                        <li>ปัจจุบันการนำเสนอคอร์ส ต้องเป็นประเภทวิดีโอเท่านั้น</li>
                        <li>เมื่อ "สื่อนำเสนอ" ผ่านการตรวจสอบเเล้ว จะเผยแพร่สู่สาธารณะให้ผู้คนทั่วไปเห็นได้ทันที เเม้ว่าผู้เรียนจะยังไม่ได้ซื้อคอร์สนี้ก็ตาม</li>
                    </ul>
                </div>
            </>
        )
    }

    return (
        <>
            {!loading ?
                <>
                    {isStepActive ?
                        <Row>
                            <Col span={24}>
                                {genMediaIntro()}
                            </Col>
                            <Divider style={{ marginTop: 1, marginBottom: 20 }} />
                            <Col span={24}>
                                <Row gutter={[24, 24]}>
                                    <Col xs={24} sm={12} md={12} lg={12}>
                                        <Button
                                            style={width > 992 ? { float: 'right', width: 130 } : ((width < 992 && width > 576) ? { float: 'right', width: 130 } : { width: '100%' })}
                                            type="default"
                                            onClick={() => {
                                                nextStepRef.current = false
                                                form.submit()
                                            }}
                                        >
                                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                <Icon icon="fluent:arrow-step-back-20-filled" />
                                                <label style={{ paddingLeft: 10, cursor: "pointer" }}>ย้อนกลับ</label>
                                            </div>
                                        </Button>
                                    </Col>
                                    <Col xs={24} sm={12} md={12} lg={12}>
                                        <Button
                                            style={width > 992 ? { float: 'left', width: 130 } : ((width < 992 && width > 576) ? { float: 'left', width: 130 } : { width: '100%' })}
                                            type="primary"
                                            onClick={() => {
                                                submitTypeRef.current = 'next'
                                                nextStepRef.current = true
                                                form.submit()
                                            }}
                                        >
                                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                <Icon icon="fluent:arrow-next-20-filled" style={{ color: "white" }} />
                                                <label style={{ paddingLeft: 10, cursor: "pointer" }}>ถัดไป</label>
                                            </div>
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        :
                        <>
                            <Row
                                style={{
                                    paddingTop: 10,
                                    paddingBottom: 0,
                                    paddingLeft: 10,
                                    paddingRight: 10
                                }}
                            >
                                <Col span={24} style={{ padding: 10 }}>
                                    {genMediaIntro()}
                                </Col>
                                <Divider style={{ marginTop: 1, marginBottom: 1 }} />
                                <Col span={24} style={{ padding: 20 }}>
                                    <center>
                                        {(courseInspect ? courseInspect.courseMediaIntro.inspectionCourseApprovalIdSummary === 2 : false) ?
                                            <Popconfirm
                                                title="คุณยืนยันบันทึกการปรับเเก้หรือไม่ ?"
                                                okText={<span style={{ width: 50 }}>ใช่</span>}
                                                onConfirm={() => {
                                                    submitTypeRef.current = 'update';
                                                    form.submit();
                                                }}
                                                cancelText={<span style={{ width: 50 }}>ไม่ใช่</span>}
                                                disabled={courseInspect.inspectionCourseId === 3 ? false : true}
                                            >
                                                <Row>
                                                    <Col span={24}>
                                                        <Button
                                                            type="primary"
                                                            disabled={courseInspect.inspectionCourseId === 3 ? false : true}
                                                        >
                                                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                                <Icon icon="bx:save" style={{ color: "white" }} />
                                                                <label style={{ paddingLeft: 10, cursor: "pointer" }}>บันทึกการปรับเเก้</label>
                                                            </div>
                                                        </Button>
                                                    </Col>
                                                    <Col span={24}>
                                                        {switchType === "edit-course" && courseInspect.inspectionCourseId === 2 && courseInspect.inspectionCourseMediaIntroId === 1 ?
                                                            <label style={{ paddingTop: 10 }}>กำลังอยู่ระหว่างการตรวจสอบ ทำให้ไม่สามารถกดปุ่ม "บันทึกการเเก้ไข" ได้ในขณะนี้! จนกว่าจะตรวจสอบเสร็จ เเละเเจ้งผลให้ทราบ</label>
                                                            : []
                                                        }
                                                    </Col>
                                                </Row>
                                            </Popconfirm>
                                            :
                                            <>
                                                {switchType === "edit-course" ?
                                                    <Popconfirm
                                                        title="คุณยืนยันการบันทึกการปรับเเต่งหรือไม่ ?"
                                                        okText={<span style={{ width: 50 }}>ใช่</span>}
                                                        onConfirm={() => {
                                                            submitTypeRef.current = 'update';
                                                            form.submit();
                                                        }}
                                                        cancelText={<span style={{ width: 50 }}>ไม่ใช่</span>}
                                                    >
                                                        <Button type="primary">
                                                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                                <Icon icon="bx:save" style={{ color: "white" }} />
                                                                <label style={{ paddingLeft: 10, cursor: "pointer" }}>บันทึกการปรับเเต่ง</label>
                                                            </div>
                                                        </Button>
                                                    </Popconfirm>
                                                    : []}
                                            </>
                                        }
                                    </center>
                                </Col>
                            </Row>

                            {(switchType === "adjust-course" ?
                                (courseInspect ? courseInspect.courseMediaIntro.inspectionCourseApprovalIdSummary === 2 : false)
                                :
                                (switchType === "edit-course" ?
                                    (
                                        (courseInspect ? (
                                            (courseInspect.courseMediaIntro.inspectionCourseApprovalIdSummary === 2 && courseInspect.inspectionCourseMediaIntroId === 1 && courseInspect.inspectionCourseId === 3) ||
                                            (courseInspect.amountEdit > 0 && courseInspect.courseMediaIntro.inspectionCourseApprovalIdSummary === 2)
                                        ) : false)
                                    ) : false
                                )
                            ) ?
                                <div style={{ paddingLeft: 20, paddingRight: 20, paddingBottom: 20, backgroundColor: 'white', height: 495 }}>
                                    <Row
                                        style={{
                                            padding: 10,
                                            border: '1px solid #EEEEEE',
                                            fontSize: 14
                                        }}
                                    >
                                        <Col span={24} style={{ paddingLeft: 10 }}>
                                            เหตุผลไม่ผ่าน
                                            <Popover content={<div style={{ width: 250 }}>ผลตรวจ ที่ทาง Toflyn เเจ้งให้ผู้สอนปรับเเก้ เเละส่งกลับมาใหม่อีกครั้งในเวลาที่กำหนด</div>}>
                                                <AiOutlineQuestionCircle style={{ marginLeft: 5, cursor: 'pointer' }} />
                                            </Popover>
                                        </Col>
                                        <Col span={24} style={{ paddingLeft: 10, paddingRight: 10, paddingTop: 5 }}>
                                            <p style={{ color: "red", backgroundColor: "#EEEEEE", padding: 8 }}>
                                                {courseInspect.courseMediaIntro.formData[0].reasonCourseNotApproval}
                                            </p>
                                        </Col>
                                        <Col span={24} style={{ padding: 10, borderTop: "1px solid #EEEEEE" }}>
                                            ตัวอย่าง เกร็ดความรู้ เเละสิ่งที่ควรหลีกเลี่ยง
                                        </Col>
                                        <Col span={24}>
                                            <label style={{ paddingLeft: 20 }}>1. เป็นคลิปที่พูดถึงเนื้อหาที่ต้องการสอนเเบบภาพรวม กระชับ ไม่ยาวจนเกินไป ประมาณ 2 ถึง 5 นาที เเละควรพูดโปรโมทรายละเอียดเน้นสิ่งที่ต้องการสอนให้ครบถ่วน หรือเลือกจุดเด่นของคอร์ส</label>
                                        </Col>
                                        <Col span={24}>
                                            <label style={{ paddingLeft: 20 }}>2. ชื่อคลิปต้องสอดคล้องกับเนื้อหาที่สอน</label>
                                        </Col>
                                        <Col span={24}>
                                            <label style={{ paddingLeft: 20 }}>2. วีดีโอต้องชัดเจนไม่สะดุด</label>
                                        </Col>
                                        <Col span={24}>
                                            <label style={{ paddingLeft: 20 }}>2. เสียงต้องดังฟังชัดไม่ซ่าขาดหาย</label>
                                        </Col>
                                    </Row>
                                </div>
                                : []}
                        </>
                    }
                </>
                :
                <center><Spin size="large" style={{ marginTop: 200, marginBottom: 200 }} /></center>
            }
        </>
    )
}
