/* eslint-disable react-hooks/exhaustive-deps */

import { Button, Card, Col, Form, Input, Row, Spin } from 'antd'
import { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { detectFormatPassword } from '../../../../common/mamager/DetectManager'
import { useDimensions } from '../../../../common/mamager/ScreenManager'

export default function PasswordReset(props) {
    const [form] = Form.useForm()
    const { width } = useDimensions()

    const navigate = useNavigate()
    const location = useLocation()
    const params = new URLSearchParams(location.search)
    const oobCode = params.get("oobCode")

    const [switchMenu, setSwitchMenu] = useState(0) // 1 = reset password, 2 = reset password success

    const [reminderPassword, setReminderPassword] = useState("")

    const [loadingResetPassword, setLoadingResetPassword] = useState(false)

    const onFinish = async (value) => {
        setLoadingResetPassword(true)

        // console.log(value);
        if (value.new_password !== value.confirm_password) {
            setReminderPassword("รหัสผ่านไม่ตรงกัน")
        } else {
            if (reminderPassword !== "") {
                setReminderPassword("")
            }

            // reset password
            await props.firebase.auth().confirmPasswordReset(oobCode, value.new_password)
                .then(function (res) {
                    // Success
                    setSwitchMenu(2)
                })
                .catch(function (err) {
                    // Invalid code
                    if (err.code === "auth/invalid-action-code") {
                        setReminderPassword("รหัสผ่านหมดอายุ หรือมีการใช้งานแล้ว")
                    } else if (err.code === "auth/network-request-failed") {
                        setReminderPassword("เกิดข้อผิดพลาดของเครือข่าย")
                    }
                })
        }

        setLoadingResetPassword(false)
    }

    useEffect(() => {
        props.firebase.auth().verifyPasswordResetCode(oobCode)
            .then(function (email) {
                // Display a "new password" form with the user's email address
                setSwitchMenu(1)
            })
            .catch(function (err) {
                // Invalid code
                setSwitchMenu(3)
            })
    }, [])

    const resetPassword = () => {
        return (
            <>
                {!loadingResetPassword ?
                    <Row gutter={[10, 10]} style={{ paddingTop: 24, paddingBottom: 24 }}>
                        <Col span={width >= 1500 ? 3 : ((width > 912 && width < 1500) ? 2 : 1)}></Col>
                        <Col span={width >= 1500 ? 18 : ((width > 912 && width < 1500) ? 20 : 22)}>
                            <Form form={form} layout="vertical" onFinish={onFinish} >
                                <Row gutter={[10, 10]}>
                                    <Col span={24} style={{}}>
                                        <label style={{ fontSize: '1.2rem', float: "left" }}>รีเซตรหัสผ่าน</label>
                                    </Col>
                                    <Col span={24} style={{ height: 60, textAlign: "left" }}>
                                        <Form.Item
                                            name="new_password"
                                            style={{ width: '100%' }}
                                            rules={[{ required: true, validator: detectFormatPassword }]}
                                        >
                                            <Input.Password placeholder="รหัสผ่านใหม่" size="large" />
                                        </Form.Item>

                                    </Col>
                                    <Col span={24} style={{ height: 50, textAlign: "left" }}>
                                        <Form.Item
                                            name="confirm_password"
                                            style={{ width: '100%' }}
                                            rules={[{ required: true, validator: detectFormatPassword }]}
                                        >
                                            <Input.Password placeholder="ยืนยันรหัสผ่าน" size="large" />
                                        </Form.Item>

                                    </Col>
                                    <Col span={24} style={{}}>
                                        <span style={{ color: "red", fontSize: '1.2rem', float: "left" }}>{reminderPassword}</span>
                                    </Col>
                                    <Col span={24} style={{}}>
                                        <Button
                                            size="large"
                                            type="primary"
                                            style={{ width: "100%" }}
                                            onClick={() => {
                                                form.submit()
                                            }}
                                        >รีเซ็ตรหัสผ่าน</Button>
                                    </Col>
                                </Row>
                            </Form>
                        </Col>
                        <Col span={width >= 1500 ? 3 : ((width > 912 && width < 1500) ? 2 : 1)}></Col>
                    </Row>
                    :
                    <center>
                        <div style={{ paddingTop: 145, paddingBottom: 145 }}><Spin size="large" /></div>
                    </center>
                }
            </>
        )
    }

    const resetPasswordSuccess = () => {
        return (
            <Row gutter={[10, 10]}>
                <Col span={24} style={{ float: "left" }}>
                    <label style={{ fontSize: '1.2rem' }}>เปลี่ยนรหัสผ่านใหม่ สำเร็จ</label>
                </Col>
                <Col span={24} style={{ textAlign: "center" }}>
                    <label
                        style={{ color: '#23B14D', fontSize: '1.2rem', cursor: "pointer" }}
                        onClick={() => {
                            navigate("/login")
                        }}
                    >เข้าสู่ระบบ</label>
                </Col>
            </Row>
        )
    }

    const expiredLink = () => {
        return (
            <Row gutter={[10, 10]}>
                <Col span={24} style={{ float: "left" }}>
                    <label style={{ fontSize: '1.2rem' }}>ลิงค์หมดอายุ หรือถูกใช้งานเเล้ว</label>
                </Col>
            </Row>
        )
    }

    return (
        <div style={{ padding: "5%", display: "flex", alignItems: "center", justifyContent: "center" }}>
            <Card
                style={{
                    width: width >= 1500 ? "450px" : ((width > 712 && width < 1500) ? "450px" : "100%"),
                    borderRadius: 20,
                    boxShadow: "2px 2px 16px 5px rgba(208, 216, 243, 1)",
                }}
            >
                {switchMenu === 0 ?
                    <center>
                        <div><Spin size="large" /></div>
                    </center>
                    : []
                }
                {switchMenu === 1 ? resetPassword() : []}
                {switchMenu === 2 ? resetPasswordSuccess() : []}
                {switchMenu === 3 ? expiredLink() : []}
            </Card>
        </div>
    )
}
