import { server } from "../../../constants"
import { httpClient } from "../../../utils/HttpClient"

const getCourseChannelSortBySearchFetch = async (values) => {
    try {
        const result = await httpClient.post(server.GET_COURSE_SORT_BY_SEARCH_LOGIN_URL, { values })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) {}
}

export {
    // get
    getCourseChannelSortBySearchFetch

    // insert

    // update

    // delete

}