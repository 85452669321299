/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */

import { useState } from 'react'
import { useNavigate } from "react-router-dom"
import {
    Row,
    Col,
    Button,
    Modal,
    List
} from "antd"
import { Icon } from '@iconify/react'
import moment from 'moment'
import { AiFillCheckCircle, AiFillCloseCircle } from "react-icons/ai"
import "../courseMamage/css/manageItem.css"
import { useDimensions } from '../../common/mamager/ScreenManager'
import { imgDefaltCardCourse, storageUrl } from '../../constants'
import { deleteCourseOverdueByIdFetch } from './API'
import { modifyDate } from '../../common/mamager/DateMamager'
import { MdChangeCircle } from '../../common/mamager/IconMamager'
import { Notification } from '../../common/components/notification'

const formatDate = "YYYY-MM-DD"
const formatDateTime = "DD/MM/YYYY HH:mm"

export default function ManageItem(props) {

    const navigate = useNavigate()

    const [isModalVisible, setIsModalVisible] = useState(false)

    const showModal = () => {
        setIsModalVisible(true)
    }

    const handleCancel = () => {
        setIsModalVisible(false)
    }

    const data = [""]

    const { width } = useDimensions()

    const handleDeleteCourseOverdue = async () => {
        const result = await deleteCourseOverdueByIdFetch(props.course.courseId) // courseId
        if (result.isSuccess) {
            props.reloadManageCourse()
            Notification("success", "ระบบ", "ลบคอร์สสำเร็จ")
        } else {
            Notification("error", "ระบบ", "ลบคอร์สไม่สำเร็จ กรุณาลองใหม่อีกครั้ง")
        }
    }

    return (
        <Col xs={24} lg={12} style={width > 991 ? (props.index % 2 !== 0 ? { paddingBottom: 14, paddingRight: 7.5 } : { paddingBottom: 14, paddingLeft: 7.5 }) : { paddingBottom: 14 }} >
            <List
                className='list-course-manahe-item'
                header={
                    <div style={{ display: "flex", alignItems: "center", paddingTop: 8, paddingBottom: 8 }}>
                        {props.course.inspectionRequestOpenCourse.inspectionCourseCategoryId === 1 ?
                            // request edit course 
                            <>
                                {(props.course.inspectionRequestEditCourse ?
                                    (props.course.inspectionRequestEditCourse.inspectionCourseCategoryId === 2 && !props.course.inspectionRequestEditCourse.inspectionCourseApprovalCategoryId ?
                                        <MdChangeCircle type={2} />
                                        :
                                        (props.course.inspectionRequestEditCourse ?
                                            (props.course.inspectionRequestEditCourse.inspectionCourseApprovalCategoryId === 2 ?
                                                <AiFillCloseCircle style={{ height: 23, width: 23, color: 'red' }} />
                                                :
                                                <AiFillCheckCircle style={{ height: 23, width: 23, color: 'green' }} />
                                            )
                                            : []
                                        )
                                    )
                                    : []
                                )}
                            </>
                            :
                            // request open course
                            (!props.course.inspectionRequestOpenCourse.isOverdue && props.course.inspectionRequestOpenCourse.inspectionCourseCategoryId === 1 && props.course.inspectionRequestOpenCourse.inspectionCourseApprovalCategoryId ?
                                <>
                                    {!props.course.isCourseEdit ? <MdChangeCircle type={2} /> : <AiFillCheckCircle style={{ height: 23, width: 23, color: 'green' }} />}
                                </>
                                :
                                <>
                                    {props.course.inspectionRequestOpenCourse.inspectionCourseCategoryId === 2 ?
                                        <MdChangeCircle type={2} /> :
                                        (props.course.inspectionRequestOpenCourse.inspectionCourseCategoryId === 1 ?
                                            <AiFillCheckCircle style={{ height: 23, width: 23, color: 'green' }} /> :
                                            <AiFillCloseCircle style={{ height: 23, width: 23, color: 'red' }} />
                                        )
                                    }
                                </>
                            )
                        }
                        <label style={{ paddingLeft: 10 }}>{props.course.courseTitleName}</label>
                    </div>
                }
                footer={
                    <div style={{ textAlign: "right", padding: 0, margin: 0 }}>
                        <Row gutter={[12, 12]}>
                            {!props.course.inspectionRequestOpenCourse.isOverdue ?
                                <Col xs={24} sm={12} md={12} lg={8}>
                                    <Button
                                        onClick={showModal}
                                        type="primary"
                                        style={{ width: '100%' }}
                                    >
                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                            <Icon icon="bx:detail" style={{ color: "white" }} />
                                            <label style={{ paddingLeft: 10, cursor: "pointer" }}>รายละเอียด</label>
                                        </div>
                                    </Button>
                                </Col>
                                : []
                            }

                            {!props.course.inspectionRequestOpenCourse.isOverdue && props.course.inspectionRequestOpenCourse.inspectionCourseCategoryId === 1 && props.course.inspectionRequestOpenCourse.inspectionCourseApprovalCategoryId ?
                                <>
                                    <Col xs={24} sm={12} md={12} lg={8}>
                                        <Button
                                            type="primary"
                                            onClick={(e) => {
                                                e.preventDefault()

                                                navigate("/course-manage-edit?course-id=" + props.course.courseId)
                                            }}
                                            style={{ width: '100%' }}
                                            disabled={(props.course.approvalStatusId === 6 || props.course.approvalStatusId === 2) ? true : false}
                                        >
                                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                <Icon icon="dashicons:admin-customizer" style={{ color: "white" }} />
                                                <label style={{ paddingLeft: 10, cursor: "pointer" }}>ปรับเเต่ง</label>
                                            </div>
                                        </Button>
                                    </Col>

                                    <Col xs={24} sm={12} md={12} lg={8}>
                                        <Button
                                            type="primary"
                                            onClick={() => {
                                                let courseId = props.course ? props.course.courseId : ""
                                                let courseOwner = props.course ? props.course.courseOwnerId : ""
                                                let toMember = props.user ? props.user.uid : ""

                                                navigate("/chat-teacher" +
                                                    '?course-id=' + courseId +
                                                    '&course-owner=' + courseOwner +
                                                    '&to-member=' + toMember
                                                )
                                            }}
                                            style={{ width: '100%' }}
                                            disabled={(props.course.approvalStatusId === 6 || props.course.approvalStatusId === 2) ? true : false}
                                        >
                                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                <Icon icon="bi:chat-left-dots" style={{ color: "white" }} />
                                                <label style={{ paddingLeft: 10, cursor: "pointer" }}>เเชท</label>
                                            </div>
                                        </Button>
                                    </Col>
                                </>
                                :
                                <Col xs={24} sm={12} md={12} lg={8}>

                                    {!props.course.inspectionRequestOpenCourse.isOverdue ?
                                        <>
                                            {props.course.isActive && props.course.approvalStatusId === 2 ?
                                                <Button
                                                    type="primary"
                                                    style={{ width: '100%' }}
                                                    onClick={(e) => {
                                                        e.preventDefault()

                                                        navigate("/course-manage-adjust?course-id=" + props.course.courseId)
                                                    }}
                                                >
                                                    <div style={{ display: "flex", alignItems: "center" }} >
                                                        <Icon icon="dashicons:admin-customizer" style={{ color: "white" }} />
                                                        <label style={{ paddingLeft: 10, cursor: "pointer" }}>ปรับเเก้</label>
                                                    </div>
                                                </Button>
                                                : []
                                            }
                                        </>
                                        :
                                        <Button
                                            type="primary"
                                            style={{ width: 100 }}
                                            onClick={() => handleDeleteCourseOverdue()}
                                        >ลบคอร์ส</Button>
                                    }
                                </Col>
                            }
                        </Row>
                    </div>
                }
                dataSource={data}
                style={props.course.approvalStatusId === 1 ?
                    { backgroundColor: 'white', paddingLeft: 15, paddingRight: 15, borderRadius: 15 } :
                    { backgroundColor: '#E5E5E5', paddingLeft: 15, paddingRight: 15, borderRadius: 15 }
                }
                renderItem={(item) => (
                    <List.Item style={{ padding: 0, margin: 0 }}>
                        <List.Item.Meta
                            style={{ height: width < 540 ? 'auto' : 212 }}
                            description={
                                <Row>
                                    <Col xs={width < 540 ? 24 : 16} lg={16}>
                                        <Row style={{ paddingRight: 8 }} gutter={[0, 8]}>
                                            {props.course.approvalStatusId !== 1 ?
                                                <Col span={24} style={{ paddingBottom: 10, paddingTop: 10 }}>
                                                    {/* inspection request open course : อยู่ระหว่างการตรวจสอบ */}
                                                    {(!props.course.inspectionRequestOpenCourse.isOverdue && props.course.isActive && props.course.inspectionRequestOpenCourse.inspectionCourseCategoryId === 2) ?
                                                        <div style={{ backgroundColor: "orange", borderRadius: 10, padding: 4, display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                            <label style={{ color: "white" }}>คำขอเปิดคอร์สนี้ อยู่ระหว่างการตรวจสอบ</label>
                                                        </div>
                                                        : []}

                                                    {/* inspection request open course : ไม่ผ่านการตรวจสอบ โปรดเเก้ไข */}
                                                    {(!props.course.inspectionRequestOpenCourse.isOverdue && props.course.isActive && props.course.inspectionRequestOpenCourse.inspectionCourseCategoryId === 3) ?
                                                        <div style={{ backgroundColor: "red", borderRadius: 10, padding: 4, display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                            <label style={{ color: "white" }}>คำขอเปิดคอร์สนี้ ไม่ผ่านการตรวจสอบ</label>
                                                        </div>
                                                        : []}

                                                    {/* inspection request open course : คอร์สเกินกำหนดการปรับเเก้ ไม่สามารถลงสอนได้ */}
                                                    {props.course.inspectionRequestOpenCourse.isOverdue ?
                                                        <div style={{ backgroundColor: "red", borderRadius: 10, padding: 4, display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                            <label style={{ color: "white" }}>คอร์สเกินกำหนดการปรับเเก้ ไม่สามารถลงสอนได้</label>
                                                        </div>
                                                        : []}
                                                </Col>
                                                :
                                                <Col span={24} style={{ paddingBottom: 10, paddingTop: 10 }}>
                                                    {/* inspection request edit course : อยู่ระหว่างการตรวจสอบ */}
                                                    {(props.course.inspectionRequestEditCourse ?
                                                        (props.course.inspectionRequestEditCourse.inspectionCourseCategoryId === 2 && !props.course.inspectionRequestEditCourse.inspectionCourseApprovalCategoryId ?
                                                            <div style={{ backgroundColor: "orange", borderRadius: 10, padding: 4, display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                                <label style={{ color: "white" }}>คำขอเเก้ไขคอร์สนี้ อยู่ระหว่างการตรวจสอบ</label>
                                                            </div>
                                                            : [])
                                                        : [])}

                                                    {/* inspection request edit course : ไม่ผ่านการตรวจสอบ โปรดเเก้ไข */}
                                                    {(props.course.inspectionRequestEditCourse ?
                                                        (props.course.inspectionRequestEditCourse.inspectionCourseApprovalCategoryId === 2 ?
                                                            <div style={{ backgroundColor: "red", borderRadius: 10, padding: 4, display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                                <label style={{ color: "white" }}>คำขอเเก้ไขคอร์สนี้ ไม่ผ่านการตรวจสอบ</label>
                                                            </div>
                                                            : [])
                                                        : [])}

                                                    {/* inspection request edit course : คอร์สเกินกำหนดการปรับเเก้ ไม่สามารถลงสอนได้ */}
                                                </Col>

                                            }

                                            {props.course.inspectionRequestOpenCourse.inspectionCourseCategoryId === 1 ?
                                                <>
                                                    <Col span={24}>
                                                        <label style={{ color: 'black' }}>การเข้าชม {props.course.courseView ? Intl.NumberFormat('en').format(Number(props.course.courseView)) : "-"} ครั้ง</label>
                                                    </Col>

                                                    {props.course.courseDiscount === true || props.course.courseDiscount === 1 ?
                                                        <>
                                                            <Col span={24}>
                                                                <label style={{ color: 'black' }}>ราคาปกติ {props.course.coursePrice ?
                                                                    Intl.NumberFormat("en", { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(Number(props.course.coursePrice)) : "-"} บาท</label>
                                                            </Col>
                                                            <Col span={24}>
                                                                <label style={{ color: 'black' }}>ลดราคาเหลือ {(props.course.coursePrice && props.course.coursePriceDiscount) ? Intl.NumberFormat("en", { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(Number(props.course.coursePrice) - Number(props.course.coursePriceDiscount)) : "-"} บาท</label>
                                                            </Col>
                                                            <Col span={24}>
                                                                <label style={{ color: 'black' }}>ลดถึงวันที่ {props.course.discountDateEnd ? modifyDate(props.course.discountDateEnd) : "-"}</label>
                                                            </Col>
                                                        </>
                                                        :
                                                        <Col span={24}>
                                                            <label style={{ color: 'black' }}>
                                                                {Number(props.course.coursePrice) === 0 ?
                                                                    "ฟรี"
                                                                    :
                                                                    Intl.NumberFormat('en').format(Number(props.course.coursePrice)) + " บาท"
                                                                }
                                                            </label>
                                                        </Col>
                                                    }
                                                </>
                                                : []}

                                            {props.course.inspectionRequestOpenCourse.inspectionCourseCategoryId === 2 && props.course.inspectionRequestOpenCourse.amountAdjust.length > 0 ?
                                                <>
                                                    <Col span={24}>
                                                        <label style={{ color: 'black' }}>จำนวนปรับเเก้ {props.course.inspectionRequestOpenCourse.amountAdjust ? Intl.NumberFormat('en').format(Number(props.course.inspectionRequestOpenCourse.amountAdjust)) : "-"} ครั้ง</label>
                                                    </Col>
                                                </>
                                                : []}

                                            {props.course.inspectionRequestOpenCourse.inspectionCourseCategoryId === 3 ?
                                                <>
                                                    <Col span={24}>
                                                        <label style={{ color: 'black' }}>วันที่เหลือปรับเเก้
                                                            {props.course.inspectionRequestOpenCourse.dateEndAdjust ?
                                                                <> {moment(props.course.inspectionRequestOpenCourse.dateEndAdjust, formatDate).diff(new Date(), 'days')} วัน, {moment(props.course.inspectionRequestOpenCourse.dateEndAdjust).format(formatDateTime)}</>
                                                                : " -"}
                                                        </label>
                                                    </Col>
                                                </>
                                                : []}

                                        </Row>
                                    </Col>

                                    <Col xs={width < 540 ? 24 : 8} lg={8}>
                                        <div style={{ paddingRight: 0, paddingTop: 10, float: width < 540 ? 'left' : 'right' }}>
                                            {props.course.courseImageLocation ?
                                                <img
                                                    style={{
                                                        width: width > 1168 ? '97.5%' : ((width <= 1168 && width >= 540) ? 189 : '100%'),
                                                        float: "right"
                                                    }}
                                                    src={`${props.course.courseImageLocation}`}
                                                />
                                                :
                                                <img
                                                    style={{ maxHeight: 150 }}
                                                    src={`${imgDefaltCardCourse}`}
                                                />
                                            }
                                        </div>
                                    </Col>
                                </Row>
                            }
                        />
                    </List.Item>
                )}
            />

            <Modal
                title={<label style={{ height: 15 }}> ข้อมูลเเพิ่มเติม</label >}
                visible={isModalVisible}
                // onOk={handleAddFeedback}
                footer={null}
                onCancel={handleCancel}
                cancelButtonProps={{ hidden: true }}
                okButtonProps={{ hidden: true }}
                width={600}
            >
                <Row>
                    <Col span={24} style={{ paddingBottom: 5 }}>
                        <label>ชื่อคอร์ส : {props.course.courseTitleName ? props.course.courseTitleName : "-"}</label>
                    </Col>
                    <Col span={12}>
                        <Col span={24} style={{ paddingBottom: 5 }}><label>จำนวนวิดีโอ {props.course.courseVideo ? Intl.NumberFormat('en').format(Number(props.course.courseVideo.length)) : "0"} วิดีโอ</label></Col>
                        <Col span={24} style={{ paddingBottom: 5 }}><label>จำนวนการเข้าชม {props.course.courseView ? Intl.NumberFormat('en').format(Number(props.course.courseView)) : "0"} ครั้ง</label></Col>
                        <Col span={24} style={{ paddingBottom: 5 }}><label>จำนวนผู้เรียน 0 คน</label></Col>
                        <Col span={24} style={{ paddingBottom: 5 }}><label>สร้าง {props.course.created_at ? props.course.created_at : "-"}</label></Col>
                        <Col span={24} style={{ paddingBottom: 5 }}><label>เเก้ไขล่าสุด {props.course.updated_at ? props.course.updated_at : "-"}</label></Col>
                    </Col>
                    <Col span={12}>
                        <Col span={24} style={{ paddingBottom: 5 }}><div>สถานะคอร์ส <label style={{ color: props.course.approvalStatusId === 1 ? "green" : (props.course.approvalStatusId === 6 ? "orange" : "black") }}>{props.course.courseApprovalStatus ? props.course.courseApprovalStatus : "-"}</label></div></Col>
                        {props.course.courseDiscount === true ?
                            <>
                                <Col span={24}><label>ราคาปกติ {props.course.coursePrice ? Intl.NumberFormat("en", { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(Number(props.course.coursePrice)) : "-"} บาท</label></Col>
                                <Col span={24}><label>ลดราคาเหลือ {props.course.coursePriceDiscount ? Intl.NumberFormat("en", { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(Number(props.course.coursePriceDiscount)) : "-"} บาท</label></Col>
                                <Col span={24}><label>ลดถึงวันที่ {props.course.discountDateEnd ? modifyDate(props.course.discountDateEnd) : "-"}</label></Col>
                            </>
                            :
                            <Col span={24}><label>ราคา {props.course.coursePrice ? Intl.NumberFormat("en", { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(Number(props.course.coursePrice)) : "-"} บาท</label></Col>}
                    </Col>
                </Row>
            </Modal >
        </Col >
    );
}
