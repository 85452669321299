import { createTheme } from '@mui/material/styles'
import { green } from '@mui/material/colors'

const themeMUI = createTheme({
    palette: {
        primary: {
            main: green[500],
            // contrastText: green[0]
        }
    }
})

export default themeMUI