import { detectCertificateCodeByIdFetch } from "../../API"

export async function GenerateCode(priceOnDatePurchase) {
    let token = await detectRepeatCode()
    token = "TFY#" + findLevelCertificate(priceOnDatePurchase) + token
    // console.log("GEN TOKEN : ", token)
    return token
}

const detectRepeatCode = async () => {
    let loopActive = true
    while (loopActive) {
        let code = genCode()
        const resultDetect = await detectCertificateCodeByIdFetch(code)
        if (resultDetect === "unrepeat") {
            // console.log(resultDetect, code)
            loopActive = false
            return code
        } else {
            // console.log(resultDetect, code)
        }
    }
}

const findLevelCertificate = (courseNetPrice) => {
    if (courseNetPrice >= 1000000) {
        return "L000"
    } else if (courseNetPrice >= 100000 && courseNetPrice <= 999999) {
        return "SR00"
    } else if (courseNetPrice >= 10000 && courseNetPrice <= 99999) {
        return "R000"
    } else if (courseNetPrice >= 0 && courseNetPrice <= 9999) {
        return "CM00"
    } else {
        return "ERR0"
    }
}

const genCode = () => {
    let tempToken = "";
    for (let i = 0; i < 15; i++) {
        let characters = '0123456789'
        let charactersLength = characters.length
        tempToken += characters.charAt(Math.floor(Math.random() * charactersLength))
    }
    return tempToken
}
