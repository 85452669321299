
import { Icon } from '@iconify/react'

export default function Rating({value}) {

    const column = (isActive) => {
        if (isActive) {
            return (
                <Icon
                    icon="game-icons:round-star"
                    style={{
                        width: 19,
                        height: 19,
                        paddingRight: 6,
                        color: "#23B14D"
                    }}
                />
            )
        } else {
            return (
                <Icon
                    icon="game-icons:round-star"
                    style={{
                        width: 19,
                        height: 19,
                        paddingRight: 6,
                        color: 'white'
                    }}
                />
            )
        }

    }

    const detectRateSize = (location) => {
        if (location === 1) {
            if (value >= 1) {
                return true
            } else {
                return false
            }
        } else if (location === 2) {
            if (value >= 2) {
                return true
            } else {
                return false
            }
        }  else if (location === 3) {
            if (value >= 3) {
                return true
            } else {
                return false
            }
        }  else if (location === 4) {
            if (value >= 4) {
                return true
            } else {
                return false
            }
        }  else if (location === 5) {
            if (value >= 5) {
                return true
            } else {
                return false
            }
        } 
    }

    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            {column(detectRateSize(1))}
            {column(detectRateSize(2))}
            {column(detectRateSize(3))}
            {column(detectRateSize(4))}
            {column(detectRateSize(5))}
        </div>
    )
}
