/* eslint-disable react-hooks/exhaustive-deps */

import {
    Col,
    Form,
    Row,
    Select,
    DatePicker,
    Input,
    Button,
    Table,
    Modal,
    Spin,
    Image,
    Divider,
    Popconfirm,
    Radio
} from 'antd'
import { useEffect, useState } from 'react'
import moment from 'moment'
import TextArea from 'antd/lib/input/TextArea'
import { Icon } from '@iconify/react'
import { updateCourseLearnerExpire } from '../../../../payment/components/checkout'
import { GenerateCode } from '../../../../../common/components/certificate/components/GenerateCode'
import { Notification } from '../../../../../common/components/notification'
import {
    emailSubject,
    emailSupport,
    storageUrl
} from '../../../../../constants'
import {
    deletePaymentOrderFetch,
    getInspectPaymentOrderTransferAllFetch,
    getPaymentStatusFetch,
    lineNotifyPaymentConsiderationResult,
    updateInspectPaymentOrderFetch
} from '../../../API'
import { insertCourseCertificateFetch } from '../../../../../common/components/certificate/API'
import {
    insertEarnedSalesRevenueFetch,
    insertLearnerCourseFetch,
    lineNotifyCoupon,
    updatePaymentOrderExpireByIdFetch
} from '../../../../payment/API'
import { formatCodeUnit } from '../../../../../common/mamager/IdManager'
import {
    paymentOrderTransferCancel,
    paymentOrderTransferNotPass,
    paymentOrderTransferPass
} from '../../../../../common/mamager/EmailMamager'
import { calculateEndDateLearnCourse } from '../../../../../common/mamager/DateMamager'
import { insertCouponLogFetch, updatePermissionUsedCouponByIdFetch } from '../../../../../common/API/coupon'

const { Option } = Select

const { RangePicker } = DatePicker

const formatTime = "HH:mm"
const formatDateTime = "YYYY-MM-DD HH:mm"

const newDate = moment()

export default function InspectPaymentOrderTransfer(props) {
    const columnsฺBill = [
        {
            title: 'No',
            dataIndex: 'index',
            width: "2%",
        },
        {
            title: 'เลขที่ชำระ',
            dataIndex: 'paymentOderId',
            width: "5%",
            render: (text, record) => {
                return (
                    <div>{formatCodeUnit(record.paymentOderId)}</div>
                )
            }
        },
        {
            title: 'รายการ',
            dataIndex: 'orderName',
            width: "25%",
        },
        {
            title: 'ชื่อผู้ใช้',
            dataIndex: 'ownerName',
            width: "6%",
        },
        // {
        //     title: 'ประเภทชำระเงิน',
        //     dataIndex: 'paymentTypeName',
        //     width: "8%",
        // },
        // {
        //     title: 'ชำระผ่านทาง',
        //     dataIndex: 'paymentMethodTypeName',
        //     width: "7%",
        // },
        {
            title: 'การต่อสัญญา',
            dataIndex: 'expireStatusName',
            width: "7%",
            render: (text, record) => {
                return (
                    <div>{record.expireStatusId ? record.expireStatusName : "-"}</div>
                )
            }
        },
        {
            title: 'ราคาสุทธิ',
            dataIndex: 'netPrice',
            width: "10%",
            render: (text, record) => {
                return (
                    <div>{Intl.NumberFormat('en').format(Number(record.netPrice))} บาท</div>
                )
            }
        },

        {
            title: 'คูปอง',
            dataIndex: 'couponCode',
            width: "10%",
        },

        {
            title: 'วันที่ชำระ',
            dataIndex: 'dateTimeTransfer',
            align: "center",
            width: "3%",
            render: (text, record) => {
                return (
                    <div>
                        {moment(record.dateTimeTransfer).format('DD/MM/YYYY HH:mm:ss')}
                    </div>
                )
            }
        },
        {
            title: 'ตรวจสอบ',
            fixed: 'right',
            align: "center",
            width: "12%",
            render: (text, record) => {
                if (record.paymentStatusId === 1) {
                    return <>
                        <span style={{ color: "green" }}>{record.paymentStatusName}</span>
                        <Icon
                            icon="charm:search"
                            style={{
                                color: "#23b14d",
                                paddingLeft: 3,
                                cursor: "pointer",
                                marginTop: -8
                            }}
                            width="25"
                            height="25"
                            onClick={() => {
                                setModal({
                                    isOpen: true,
                                    data: record
                                })
                            }}
                        />
                    </>
                } else if (record.paymentStatusId === 2 || record.paymentStatusId === 3) {
                    return <>
                        <Button
                            type="primary"
                            onClick={() => {
                                setModal({
                                    isOpen: true,
                                    data: record
                                })

                                setPaymentStatus(record.paymentStatusId)

                                setReasonNotPass(record.reasonNotPass ? record.reasonNotPass : undefined)
                            }}
                        >ตรวจสอบชำระเงิน</Button>

                        {record.paymentStatusId === 2 ? <div style={{ color: "orange" }}>{record.paymentStatusName}</div> : []}
                        {record.paymentStatusId === 3 ? <div style={{ color: "red" }}>{record.paymentStatusName}</div> : []}
                    </>
                } else if (record.paymentStatusId === 4) {
                    return <div>{record.paymentStatusName}</div>
                }
            }
        },
    ]

    let language = "th"

    const [form] = Form.useForm()

    const [list, setList] = useState([])
    const [paymentStatusCategory, setPaymentStatusCategory] = useState([])
    const [loading, setLoading] = useState(false)

    const [paymentStatus, setPaymentStatus] = useState(undefined)
    const [reasonNotPass, setReasonNotPass] = useState(undefined)

    const [modal, setModal] = useState({
        isOpen: false,
        data: null
    })

    const [pageCurrent, setPageCurrent] = useState(1)

    const onPagine = (n) => {
        setPageCurrent(n.current)
    }

    const onFinish = async (value) => {
        let ownerName = value.ownerName ? value.ownerName : null
        let startedDate = value.dateRange ? moment(value.dateRange[0]).format(formatDateTime) : null
        let endDate = value.dateRange ? moment(value.dateRange[1]).format(formatDateTime) : null
        let paymentStatusCategory = value.paymentStatusCategory ? value.paymentStatusCategory : null

        let request = {
            ownerName, startedDate, endDate, paymentStatusCategory
        }
        await getInspectPaymentOrderTransferAll(request)
    }

    const getInspectPaymentOrderTransferAll = async (value) => {
        const result = await getInspectPaymentOrderTransferAllFetch(value, language)
        // console.log("getInspectPaymentAllFetch : ", result)
        let tempResult = result ? result.filter(val => val.paymentMethodTypeId === 1) : []
        setList(tempResult)
    }

    const getPaymentStatus = async () => {
        const result = await getPaymentStatusFetch(language)
        // console.log("getPaymentStatusFetch : ", result)
        setPaymentStatusCategory(result)
    }

    const getBaseApi = async () => {
        setLoading(true)

        await getInspectPaymentOrderTransferAll({
            ownerName: null,
            startedDate: null,
            endDate: null,
            paymentStatus: null
        });
        await getPaymentStatus()

        setLoading(false)
    }

    const onAddCourseToLearner = async (value) => {
        let endLearnDate = calculateEndDateLearnCourse(value.courseFormatId, newDate)

        // create learner -------------------------------------------------------------------------------------------------------------
        let obj = {
            courseId: value.orderId,
            teacherId: value.teacherId,                             // teacherId
            learnerId: value.uid,                                   // learnerId
            isActive: 1,
            courseMonthId: null,                                    // รายเดือน
            courseFormatId: value.courseFormatId,
            is25PercentWatchVideoCourse: false,
            is50PercentWatchVideoCourse: false,
            is80PercentWatchVideoCourse: false,
            startLearnDate: newDate,
            endLearnDate,
        }
        // console.log("obj : ", obj)
        const resultInsertLearnerCourse = await insertLearnerCourseFetch(obj)
        // console.log("insertLearnerCourseFetch : ", resultInsertLearnerCourse)

        // create certificate ---------------------------------------------------------------------------------------------------------
        let priceOnDatePurchase = (Number(value.coursePrice) - Number(value.coursePriceDiscount))
        let certificateCode = await GenerateCode(priceOnDatePurchase)
        let objCertificate = {
            courseId: value.orderId,
            uid: value.uid,
            themeCertificateId: 1,
            courseCertificateCode: certificateCode,
            courseCertificateQRCode: null,
            certificateApprovalCategoryId: 2, // study
            priceOnDatePurchase,
            isActive: 1
        }
        const resultInspectCourseCertificate = await insertCourseCertificateFetch(objCertificate)
        // console.log("insertCourseCertificateFetch : ", resultInspectCourseCertificate)

        if (resultInsertLearnerCourse.isSuccess && resultInspectCourseCertificate.isSuccess) {
            // reminder success
            // Notification("success", "ระบบ", "เพิ่มคอร์สเรียนที่ซื้อไปยังหน้า 'คอร์สของฉัน' ให้ผู้เรียนสำเร็จ")
        }
    }

    const handleUpdatePaymentStatus = async (value) => {
        setLoading(true)

        let obj = {
            paymentOderId: value.paymentOderId,
            paymentStatus,
            adminId: props.adminLogin ? props.adminLogin.adminListId : null,
            reasonNotPass // สาเหตุที่ไม่ผ่านการตรวจสอบ
        }
        const resultInspectPaymentOrder = await updateInspectPaymentOrderFetch(obj) // update payment status
        // console.log("updateInspectPaymentOrderFetch : ", resultInspectPaymentOrder)

        if (resultInspectPaymentOrder.isSuccess) {
            let objEmail = {
                ownerName: value.ownerName,
                orderName: value.orderName,
                orderPrice: Intl.NumberFormat('en').format(Number(value.netPrice)) + " บาท",
                reasonNotPass,
            }

            if (paymentStatus === 1) { // is pass 
                let resultUpdateCourseLearnerExpire = { isSuccess: true }
                let resultUpdatePaymentOrderExpire = { isSuccess: true }
                if (!value.expireStatusId) {
                    // เพิ่มคอร์สเรียนในรายการคอร์สของผู้เรียน เเละพร้อมเรียนได้ทันที (สำหรับ ฟรี (ไม่ชำระเงิน), บัตรเดบิต/เครดิต, หรือตรวจสอบการโอนผ่านเเล้ว)
                    await onAddCourseToLearner(value);
                } else {
                    // update course learner expire by id
                    resultUpdateCourseLearnerExpire = await updateCourseLearnerExpire(
                        {
                            courseId: value.orderId,                                            // courseId
                            uid: value.teacherId,                                               // uid of teacher
                            courseFormatId: value.courseFormatId                                // course format
                        },
                        {
                            uid: value.uid                                                      // uid of learner
                        },
                        value.expireStatusId === 2 ? 1 : value.expireStatusId,                  // expire status
                        newDate                                                                 // startDate
                    )

                    resultUpdatePaymentOrderExpire = await updatePaymentOrderExpireByIdFetch({
                        paymentOderId: value.paymentOderId,                                     // payment order id
                        orderOwnerId: value.uid,                                                // uid of learner
                        courseId: value.orderId,                                                // courseId
                        courseOwnerId: value.teacherId,                                         // uid of teacher
                        courseFormat: value.courseFormatId,                                     // course format
                        expireStatusId: value.expireStatusId === 2 ? 1 : value.expireStatusId,  // expire status
                    })

                    // console.log("newDate : ", newDate)
                }

                // create earned sales revenue (จะเกิด record ได้ก็ต่อเมื่อการชำระเงินต้องสำเร็จ เเละเสร็จสิ้น)
                let objEarnedSalesRevenue = {
                    paymentOderId: value.paymentOderId,
                    uid: value.teacherId,                       // TeacherId : เจ้าของเงินที่ Toflyn ต้องส่งให้  
                    receivedDate: moment().add(7, 'd'),         // วันที่ได้รับเงิน หลังผ่านไป 7 วัน
                    paymentDate: moment(),                      // วันที่เกิดชำระเงิน นับจากรายการชำระเงินผ่านตรวจสอบเเล้ว
                    isReceived: false,                          // สถานะการรับเงินของผู้สอน
                }
                const resultInsertEarnedSalesRevenue = await insertEarnedSalesRevenueFetch(objEarnedSalesRevenue)
                // console.log("insertEarnedSalesRevenueFetch : ", resultInsertEarnedSalesRevenue)

                // update coupon information
                let resultCouponLog = { isSuccess: true }
                let resultUpdatePermissionUsedCoupon = { isSuccess: true }
                if (value.couponId) {
                    let objCouponLog = {
                        couponId: value.couponId,
                        courseId: value.orderId,
                        uid: value.uid
                    }
                    resultCouponLog = await insertCouponLogFetch(objCouponLog)

                    let objPermissionUsedCoupon = {
                        couponId: value.couponId
                    }
                    resultUpdatePermissionUsedCoupon = await updatePermissionUsedCouponByIdFetch(objPermissionUsedCoupon)
                
                    await lineNotifyCoupon({
                        title: "มีการใช้คูปอง",
                        couponId: value.couponId,
                        courseId: value.orderId,
                        uid: value.uid,
                        dateNotify: moment().format("DD/MM/YYYY HH:mm")
                    })
                }

                if (
                    resultInsertEarnedSalesRevenue.isSuccess ||
                    resultUpdateCourseLearnerExpire.isSuccess ||
                    resultUpdatePaymentOrderExpire.isSuccess ||
                    resultCouponLog.isSuccess ||
                    resultUpdatePermissionUsedCoupon.isSuccess
                ) {
                    genLineNotifyInspect(
                        "ผ่านการตรวจสอบ",
                        value.paymentOderId,
                        value.ownerName,
                        value.orderName,
                        value.netPrice
                    )

                    // reminder
                    Notification("success", "ระบบ", "อัพเดทสถานะการชำระเงินนี้เป็น ผ่านการตรวจสอบ สำเร็จ")
                } else {
                    Notification("error", "ระบบ", "การบันทึกข้อมูลไม่สมบูรณ์ พบข้อผิดพลาดเกิดขึ้น")
                }

                // send email
                paymentOrderTransferPass(
                    emailSupport, // support@toflyn.com
                    value.email,
                    emailSubject + "หลักฐานชำระเงินของผู้เรียนเป็นข้อมูลถูกต้อง เเละซื้อคอร์สเรียนสำเร็จ",
                    objEmail,
                )
            } else if (paymentStatus === 3) { // is not pass
                genLineNotifyInspect(
                    "ไม่ผ่านการตรวจสอบ",
                    value.paymentOderId,
                    value.ownerName,
                    value.orderName,
                    value.netPrice
                )

                // reminder
                Notification("success", "ระบบ", "อัพเดทสถานะการชำระเงินนี้เป็น ไม่ผ่านการตรวจสอบ สำเร็จ")

                // send email
                paymentOrderTransferNotPass(
                    emailSupport, // support@toflyn.com
                    value.email,
                    emailSubject + "หลักฐานชำระเงินของผู้เรียนเป็นข้อมูลไม่ถูกต้อง เเละซื้อคอร์สเรียนไม่สำเร็จ",
                    objEmail,
                )
            }

            // reload data
            getBaseApi()

            // set default
            setModal({
                isOpen: false,
                data: null
            })
        } else {
            Notification("error", "ระบบ", "การบันทึกข้อมูลไม่สมบูรณ์ พบข้อผิดพลาดเกิดขึ้น")
        }

        setLoading(false)
    }

    const genLineNotifyInspect = async (orderStatus, paymentOderId, ownerName, orderName, netPrice) => {
        // line
        let objLineNotify = {
            orderStatus: orderStatus,
            orderPaymentMethodTypeName: "โอนเงิน",
            orderId: paymentOderId,
            adminName: props.adminLogin?.username,
            ownerName: ownerName,
            orderName: orderName,
            orderNetPrice: Intl.NumberFormat('en').format(Number(netPrice)) + " บาท",
            orderDateTime: moment().format('DD/MM/YYYY HH:mm')
        }
        // console.log("objLineNotify : ", objLineNotify)
        await lineNotifyPaymentConsiderationResult(objLineNotify)
    }

    const handleDeletePaymentOrder = async (value) => {
        setLoading(true)

        const result = await deletePaymentOrderFetch(value.paymentOderId)
        // console.log("deletePaymentOrderFetch : ", result)
        if (result.isSuccess) {
            // reminder
            Notification("success", "ระบบ", "ลบรายการชำระเงินสำเร็จ")

            // send email
            let objEmail = {
                ownerName: value.ownerName,
                orderName: value.orderName,
                orderPrice: Intl.NumberFormat('en').format(Number(value.netPrice)) + " บาท",
            }
            paymentOrderTransferCancel(
                emailSupport, // support@toflyn.com
                value.email,
                emailSubject + "ยกเลิกรายการชำระเงินสำเร็จ",
                objEmail,
            )

            // reload data
            getBaseApi()

            // set default
            setModal({
                isOpen: false,
                data: null
            })
        } else {
            Notification("error", "ระบบ", "ลบรายการชำระเงินไม่สำเร็จ")
        }

        setLoading(false)
    }

    useEffect(() => {
        // console.log("adminLogin : ", props.adminLogin)
        getBaseApi()
    }, [])

    return (
        <>

            <>
                <Row>
                    <Col span={5}>
                        <label>ชำระเงินผ่านการโอนเงิน</label>
                    </Col>
                    <Col span={19} style={{ paddingBottom: 20 }}>
                        <Form
                            layout="vertical"
                            form={form}
                            onFinish={onFinish}
                        >
                            <Row>
                                <Col xs={12} lg={5}>
                                    <Form.Item name="paymentStatusCategory" >
                                        <Select
                                            showSearch
                                            style={{ width: '90%' }}
                                            placeholder="สถานะชำระเงิน"
                                            optionFilterProp="children"
                                            allowClear
                                        >
                                            {paymentStatusCategory?.map((item, index) => (
                                                <Option key={index} value={item.value}>{item.label}</Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col xs={12} lg={7}>
                                    <Form.Item name="dateRange" >
                                        <RangePicker
                                            style={{ width: '90%' }}
                                            showTime={{ format: formatTime }}
                                            format={formatDateTime}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={12} lg={10}>
                                    <Form.Item name="ownerName" >
                                        <Input
                                            style={{ width: '100%' }}
                                            placeholder="ชื่อผู้ใช้"
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={12} lg={2}>
                                    <Button
                                        type="primary"
                                        onClick={() => form.submit()}
                                    >
                                        ค้นหา
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                    <Col span={24}>
                        {!loading ?
                            <Table
                                loading={loading}
                                columns={columnsฺBill}
                                dataSource={list}
                                pagination={{
                                    current: pageCurrent,
                                    pageSize: 10,
                                    total: list.length
                                }}
                                onChange={(n) => onPagine(n)}
                            >
                            </Table>
                            :
                            <center><Spin size="large" style={{ paddingTop: 200 }} /></center>
                        }
                    </Col>
                </Row>

                <Modal
                    title={"ตรวจสอบชำระเงิน"}
                    visible={modal.isOpen}
                    onCancel={() => {
                        setModal({
                            isOpen: false,
                            data: null
                        });

                        setPaymentStatus(undefined);

                        setReasonNotPass(undefined);
                    }}
                    width={550}
                    footer={
                        (modal.data?.paymentStatusId !== 1 ?
                            <>
                                <Popconfirm
                                    title="ยืนยันส่งผลตรวจ ใช่หรือไม่ ?"
                                    okText={<span style={{ width: 50 }}>ใช่</span>}
                                    onConfirm={async () => {
                                        if (modal.data?.paymentStatusId === 3) {
                                            Notification("warning", "ระบบ", "รายการนี้ เคยถูกส่งผลตรวจสอบว่าไม่ผ่าน ไปให้ผู้ใช่เเล้ว");
                                        } else {
                                            await handleUpdatePaymentStatus(modal.data);
                                        }
                                    }}
                                    cancelText={<span style={{ width: 50 }}>ไม่ใช่</span>}
                                >
                                    <Button
                                        loading={loading}
                                        style={{ width: 150, marginRight: 10 }}
                                        type="primary"
                                    >
                                        {!loading ? "ส่งผลการตรวจสอบ" : []}
                                    </Button>
                                </Popconfirm>

                                <Popconfirm
                                    title="การยกเลิกรายการชำระเงิน จะต้องเเจ้งให้ผู้ใช้ทราบก่อน ท่านดำเนินการเเล้ว เเละผู้ใช้งานทราบเเล้ว ใช่หรือไม่ ?"
                                    okText={<span style={{ width: 50 }}>ใช่</span>}
                                    onConfirm={() => {
                                        handleDeletePaymentOrder(modal.data);
                                    }}
                                    cancelText={<span style={{ width: 50 }}>ไม่ใช่</span>}
                                >
                                    <Button
                                        loading={loading}
                                        style={{ width: 150, marginRight: 10 }}
                                        danger
                                        type="primary"
                                    >
                                        {!loading ? "ยกเลิกการชำระเงิน" : []}
                                    </Button>
                                </Popconfirm>
                            </>
                            :
                            null
                        )
                    }
                >
                    <Row>
                        <Col span={8}><label>ชื่อผู้ใช้</label></Col>
                        <Col span={16}>: <label>{modal.data?.ownerName ?? "-"}</label></Col>

                        <Col span={8}><label>เบอร์โทร</label></Col>
                        <Col span={16}>: <label>{modal.data?.phone ?? "-"}</label></Col>

                        <Col span={8}><label>อีเมล</label></Col>
                        <Col span={16}>: <label>{modal.data?.email ?? "-"}</label></Col>

                        <Col span={8}><label>สถานะ</label></Col>
                        <Col span={16}>: <label>{modal.data?.paymentStatusName}</label></Col>

                        <Divider />

                        <Col span={8}><label>การต่อสัญญา</label></Col>
                        <Col span={16}>: <label>{modal.data?.expireStatusId ? modal.data?.expireStatusName : "-"}</label></Col>

                        <Col span={8}><label>วันเวลาโอน</label></Col>
                        <Col span={16}>: <label>{moment(modal.data?.dateTimeTransfer).format(formatDateTime)}</label></Col>

                        {/* <Col span={8}><label>เลขท้าย 3 ตัว บัญชี</label></Col>
                                <Col span={16}>: <label>{modal.data?.last3DigitsBankAccountTransferor}</label></Col> */}

                        <Col span={8}><label>รูปเเบบการซื้อวิดีโอคอร์ส</label></Col>
                        <Col span={16}>: <label>{modal.data?.isPaymentByVideo ? (
                            modal.data?.isPaymentByVideo ? "เป็นการชำระเงินเเบบรายวิดีโอ" : "เป็นการชำระเงินเเบบซื้อวิดีโอทั้งหมด เท่านั้น!"
                        ) : "-"}</label></Col>

                        <Col span={8}><label>จำนวนวิดีโอ</label></Col>
                        <Col span={16}>: <label>{modal.data?.courseVideoAmount ? modal.data?.courseVideoAmount : "-"}</label> วิดีโอ</Col>

                        <Col span={8}><label>การต่อสัญญา</label></Col>
                        <Col span={16}>: <label>{modal.data?.expireStatusId ? modal.data?.expireStatusName : "-"}</label></Col>

                        <Col span={8}><label>ราคาสุทธิ</label></Col>
                        <Col span={16}>: <label>{Intl.NumberFormat('en').format(Number(modal.data?.netPrice))} บาท</label></Col>

                        <Col span={8}><label>คูปอง</label></Col>
                        <Col span={16}>: <label>{modal.data?.couponCode}</label></Col>

                        <Col span={8}><label>หลักฐานโอนเงิน</label></Col>
                        <Col span={16}>
                            <Image src={`${modal.data?.transferSlipImage}`} />
                        </Col>

                        {modal.data?.paymentStatusId !== 1 ?
                            <>
                                <Divider />
                                <Col span={24}>
                                    <label>ผลตรวจสอบ</label>
                                </Col>
                                <Col span={24} style={{ paddingBottom: 10 }}>
                                    <Radio.Group onChange={(e) => {
                                        setPaymentStatus(e.target.value);
                                    }}
                                        value={paymentStatus}
                                    >
                                        <Radio value={1}>ผ่าน</Radio>
                                        <Radio value={3}>ไม่ผ่าน</Radio>
                                    </Radio.Group>
                                </Col>
                                {paymentStatus === 3 ?
                                    <Col span={24}>
                                        <TextArea
                                            rows={4}
                                            placeholder="สาเหตุที่ไม่ผ่านการตรวจสอบ"
                                            value={reasonNotPass}
                                            onChange={(e) => setReasonNotPass(e.target.value)}
                                            rules={[{ required: true, message: 'กรุณาระบุสาเหตุที่ไม่ผ่านการตรวจสอบ' }]}
                                        />
                                    </Col>
                                    : []}

                                <Divider />

                                <Col span={24}>
                                    <label>หากข้อมูลไม่ตรง หรือไม่ถูกต้อง คลิกที่ "ไม่ผ่านการตรวจสอบ" เเละติดต่อผู้ใช้งานให้ทราบด่วน !</label>
                                </Col>
                            </>
                            : []}
                    </Row>
                </Modal>
            </>

        </>
    )
}
