/* eslint-disable jsx-a11y/alt-text */

import { useState, useEffect } from "react"
import { Row, Col, Pagination, Empty } from 'antd'
import { useDimensions } from "../../common/mamager/ScreenManager"
import ClassroomItem from "./ClassroomItem"

export default function ClassroomBoard(props) {

    const { width } = useDimensions()
    const [courseGroup, setCourseGroup] = useState({
        data: [],
        pageNumber: 0,
    })

    const [page, setPage] = useState({
        minValue: 0,
        maxValue: 4
    })

    const handleChange = value => {
        if (value <= 1) {
            setPage({
                minValue: 0,
                maxValue: 4
            })
        } else {
            setPage({
                minValue: page.maxValue,
                maxValue: value * 4
            })
        }
        window.scrollTo(0, 0);
    }

    function getCourseFound() {
        return (
            <Empty
                imageStyle={{
                    height: 80,
                    marginTop: 110
                }}
                style={{ width: '100%' }}
                description={
                    <span style={{ color: 'gray' }}>ไม่พบรายการ</span>
                }
            >
            </Empty>
        )
    }

    useEffect(() => {
        setCourseGroup({
            data: props.courseClassroom,
            pageNumber: 0,
        })
    }, [props.courseClassroom])

    return (
        <>
            <div style={{ backgroundColor: 'white' }}>
                <Row style={width >= 1500 ? { paddingLeft: '5%', paddingRight: '5%' } : ((width > 912 && width < 1500) ? { paddingLeft: '5%', paddingRight: '5%' } : { paddingLeft: '0%', paddingRight: '0%' })}>
                    <Col xs={24} lg={24} style={{ paddingTop: 15, paddingRight: 15, paddingBottom: 15 }}>
                        <span className="course-manage-course-amount-all">
                            จำนวนคอร์สที่พบ {courseGroup.data.length}
                        </span>
                    </Col>
                </Row>
            </div>

            <div style={{ paddingTop: 15 }}>
                <Row style={width >= 1500 ? { paddingLeft: '5%', paddingRight: '5%' } : ((width > 912 && width < 1500) ? { paddingLeft: '5%', paddingRight: '5%' } : { paddingLeft: 15, paddingRight: 15 })}>
                    {courseGroup.data.length > 0 ?
                        <>
                            <Col span={24}>
                                <Row>
                                    {courseGroup.data && courseGroup.data.length > 0 &&
                                        courseGroup.data.slice(page.minValue, page.maxValue).map((val, index) => (
                                            <ClassroomItem
                                                key={index}
                                                item={val}
                                                index={(index + 1)}
                                                user={props.user}
                                            />
                                        ))
                                    }
                                </Row>
                            </Col>
                            <Col span={24} style={{ paddingBottom: 15 }}>
                                <center>
                                    <Pagination
                                        defaultCurrent={1}
                                        defaultPageSize={4}
                                        onChange={handleChange}
                                        total={courseGroup.data.length}
                                    />
                                </center>
                            </Col>
                        </>
                        :
                        <Col span={24}>
                            {getCourseFound()}
                        </Col>
                    }
                </Row>
            </div>
        </>
    )
}
