import "../../css/manageSearch.css"
import { useState, useRef } from "react"
import { useNavigate } from "react-router-dom"
import { Button, Col, Row, AutoComplete } from "antd"
import { Icon } from '@iconify/react'
import { useDimensions } from "../../../../common/mamager/ScreenManager"
import { getCourseManageSearchFetch } from "../../API"

export default function ManageSearch(props) {
    const navigate = useNavigate()
    const { width } = useDimensions()

    const searchRef = useRef('')

    const [options, setOptions] = useState([])

    const getSearch = async (value) => {
        const resultSearch = await getCourseManageSearchFetch(props.user.uid, value, 10)

        let data = []

        resultSearch.map((val) => data.push(val.courseTitleName))
        let searchResult = await data.map((data) => {
            return {
                value: data,
                label: (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                    >
                        <span>{data}</span>
                    </div>
                ),
            }
        })
        
        setOptions(value ? searchResult : [])
    }

    const handleSearch = async (value) => {
        await getSearch(value)
    }

    const onclick = () => {
        navigate("/course-manage?course-manage-text=" + searchRef.current)
    }
    
    return (
        <Row
            style={width >= 1500 ?
                { paddingLeft: '5%', paddingRight: '5%', backgroundColor: '#E5E5E5', paddingBottom: 0 }
                : (
                    (width > 912 && width < 1500) ?
                        { paddingLeft: '5%', paddingRight: '5%', paddingTop: 0, backgroundColor: '#E5E5E5', paddingBottom: 0 }
                        : { paddingLeft: '0%', paddingRight: '0%', paddingTop: 0, backgroundColor: '#E5E5E5', paddingBottom: 0 }
                )}
        >
            <Col xs={6} lg={12} style={{ paddingTop: 15, backgroundColor: '#E5E5E5', paddingBottom: 0, paddingLeft: 15 }}>
                <label style={{ fontSize: 18, paddingTop: 6, paddingLeft: 4 }}>จัดการคอร์ส</label>
            </Col>

            <Col xs={18} lg={12} style={{ paddingLeft: 8, paddingRight: 15, paddingBottom: 15, paddingTop: 15 }}>
                <Button
                    type="primary"
                    style={{ float: 'right', border: '1px solid #dedede' }}
                    size="large"
                    onClick={() => onclick()}
                >
                    <Icon icon="bi:search" style={{ color: "white" }} />
                </Button>
                <AutoComplete
                    placeholder="ค้นหาคอร์สเรียน"
                    onChange={(e) => searchRef.current = e}
                    dropdownMatchSelectWidth={252}
                    style={{ width: '50%', float: 'right' }}
                    options={options}
                    size="large"
                    onSearch={handleSearch}
                ></AutoComplete>
            </Col>
        </Row>
    )
}