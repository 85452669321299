/* eslint-disable jsx-a11y/anchor-is-valid */

import GuidePlatformBreadcrumb from "./GuidePlatformBreadcrumb"
import { Icon } from '@iconify/react'
import { fontSize } from "../../../common/mamager/FontManager"

export default function GuideRegister(props) {
    return (
        <>
            <GuidePlatformBreadcrumb pathName={"การสมัครสมาชิก"} />
            <div style={{ fontSize: fontSize.medium }}>
                <p style={{ textAlign: "center" }}><strong>การสมัครสมาชิก</strong></p>
                <p><span style={{}}>การที่ผู้ใช้งานจะสามารถเรียนคอร์สในเเพลตฟอร์ม Toflyn ได้ ผู้ใช้งานจะต้องทำการสมัครสมาชิกก่อน โดยมีเพียง 5 ขั้นตอนง่าย ๆ ดังนี้</span></p>

                <span style={{ fontWeight: "bold" }}>ขั้นตอนที่  1. คลิก "เข้าสู่ระบบ" ที่มุมขวาบนสุดในเว็บไซต์ หรือคลิกที่นี่ (เข้าสู่ระบบ) </span>
                <p><span style={{}}></span></p>
                <p style={{ paddingBottom: 0, marginBottom: 0 }}><span></span></p>
                <p style={{}}><span></span></p>

                <span style={{ fontWeight: "bold" }}>ขั้นตอนที่  2. เลือกช่องทางที่ใช้สมัครสมาชิก ได้เเก่ </span><br/>
                <Icon icon="radix-icons:dot" style={{ marginTop: -2, color: "#23b14d", width: 25, height: 25 }}/>
                <label style={{ paddingBottom: 0, marginBottom: 0 }}><span>สมัครสมาชิกด้วยบัญชี Facebook</span></label><br/>
                <Icon icon="radix-icons:dot" style={{ marginTop: -2, color: "#23b14d", width: 25, height: 25 }}/>
                <label style={{ paddingBottom: 0, marginBottom: 0 }}><span>สมัครสมาชิกด้วยบัญชี Google</span></label><br/>
                <Icon icon="radix-icons:dot" style={{ marginTop: -2, color: "#23b14d", width: 25, height: 25 }}/>
                <label style={{ paddingBottom: 12, marginBottom: 12 }}><span>สมัครสมาชิกด้วย Email/Password</span></label><br/>

                <span style={{ fontWeight: "bold" }}>ขั้นตอนที่  3. เลือกประเภทของผู้ใช้งาน มี 2 ประเภท ได้เเก่ </span><br/>
                <Icon icon="radix-icons:dot" style={{ marginTop: -2, color: "#23b14d", width: 25, height: 25 }}/>
                <label style={{ paddingBottom: 0, marginBottom: 0 }}><span>บุคคลธรรมดา</span></label><br/>
                <Icon icon="radix-icons:dot" style={{ marginTop: -2, color: "#23b14d", width: 25, height: 25 }}/>
                <label style={{}}><span>นิติบุคคล</span></label><br/>

                <span style={{ fontWeight: "bold" }}>ขั้นตอนที่  4. กรอกข้อมูลดังต่อไปนี้</span>
                <label><span style={{}}>หากสมัครสมาชิกเเบบ บุคคลธรรมดา กรอกข้อมูล 2 หัวข้อ ได้เเก่</span></label>
                <label><span style={{}}>หัวข้อที่ 1 "ข้อมูลผู้ใช้" ประกอบไปด้วย</span></label><br/>
                <Icon icon="radix-icons:dot" style={{ marginTop: -2, color: "#23b14d", width: 25, height: 25 }}/>
                <label style={{ paddingBottom: 12, marginBottom: 12 }}><span>ชื่อผู้ใช้งาน</span></label><br/>
                <label><span style={{}}>กรณีที่ผู้ใช้งานเลือกการสมัครสมาชิกด้วย Email/Password ต้องกรอกข้อมูลเพิ่ม ดังนี้</span></label><br/>
                <Icon icon="radix-icons:dot" style={{ marginTop: -2, color: "#23b14d", width: 25, height: 25 }}/>
                <label style={{ paddingBottom: 0, marginBottom: 0 }}><span>รหัสผ่าน</span></label><br/>
                <Icon icon="radix-icons:dot" style={{ marginTop: -2, color: "#23b14d", width: 25, height: 25 }}/>
                <label style={{ paddingBottom: 0, marginBottom: 0 }}><span>รหัสผ่านอีกครั้ง</span></label><br/><br/>
                <Icon icon="radix-icons:dot" style={{ marginTop: -2, color: "#23b14d", width: 25, height: 25 }}/>
                <label style={{ paddingBottom: 12, marginBottom: 12 }}><span>อีเมล (ต้องเป็นอีเมลที่ใช้งาน เเละมีอยู่จริง)</span></label><br/>
                <label><span style={{}}>หัวข้อที่ 2 "หมวดหมู่คอร์สที่ชอบ" ประกอบไปด้วย</span></label><br/>
                <Icon icon="radix-icons:dot" style={{ marginTop: -2, color: "#23b14d", width: 25, height: 25 }}/>
                <label style={{ paddingBottom: 0, marginBottom: 0 }}><span>หมวดหมู่คอร์สที่ชื่นชอบ อันดับ 1</span></label><br/>
                <Icon icon="radix-icons:dot" style={{ marginTop: -2, color: "#23b14d", width: 25, height: 25 }}/>
                <label style={{ paddingBottom: 0, marginBottom: 0 }}><span>หมวดหมู่คอร์สที่ชื่นชอบ อันดับ 2</span></label><br/>
                <Icon icon="radix-icons:dot" style={{ marginTop: -2, color: "#23b14d", width: 25, height: 25 }}/>
                <label style={{ paddingBottom: 12, marginBottom: 12 }}><span>หมวดหมู่คอร์สที่ชื่นชอบ อันดับ 3</span></label><br/>

                <label><span style={{}}>หากสมัครสมาชิกเเบบ นิติบุคคล กรอกข้อมูล ดังต่อไปนี้</span></label>
                <label><span style={{}}>ชื่อผู้ใช้งาน</span></label>
                <label><span style={{}}>กรณีที่ผู้ใช้งานเลือกการสมัครสมาชิกด้วย Email/Password ต้องกรอกข้อมูลเพิ่ม ดังนี้</span></label><br/>
                <Icon icon="radix-icons:dot" style={{ marginTop: -2, color: "#23b14d", width: 25, height: 25 }}/>
                <label style={{ paddingBottom: 0, marginBottom: 0 }}><span>รหัสผ่าน</span></label><br/>
                <Icon icon="radix-icons:dot" style={{ marginTop: -2, color: "#23b14d", width: 25, height: 25 }}/>
                <label style={{ paddingBottom: 0, marginBottom: 0 }}><span>รหัสผ่านอีกครั้ง</span></label><br/>
                <Icon icon="radix-icons:dot" style={{ marginTop: -2, color: "#23b14d", width: 25, height: 25 }}/>
                <label style={{ paddingBottom: 12, marginBottom: 12 }}><span>อีเมลบริษัท (ต้องเป็นอีเมลที่ใช้งาน เเละมีอยู่จริง)</span></label><br/>

                <span style={{ fontWeight: "bold" }}>ขั้นตอนที่  5. ตรวจทานข้อมูล เเละกดยืนยันการสมัครสมาชิกที่ปุ่ม "สมัครสมาชิก" </span>
            </div>
        </>
    )
}
