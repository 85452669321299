/* eslint-disable array-callback-return */

export const filterPaymentVideo = (
    chapter, 
    video,
    courseId
) => {
    // fliter video
    let tmpVideoIsNotPayment = video.filter(val => val.paymentInfo?.length === 0)

    let chapterList = []
    tmpVideoIsNotPayment.map(val => {
        if (val.courseChapterId) {
            let findChapterNotPaymentDupicate = chapterList?.filter(valP => val.courseChapterId === valP.chapterId)[0]
            if (!findChapterNotPaymentDupicate) {
                chapterList.push({
                    chapterId: val.courseChapterId
                })
            }
        }
    })

    // filter chalter
    let tmpChapterNotPayment = [];
    if (chapter) {
        chapter?.map(val => {
            let findChapterNotPayment = chapterList?.filter(valNP => val.courseChapterId === valNP.chapterId)[0]
            if (findChapterNotPayment) {
                tmpChapterNotPayment.push(val)
            }
        })
    }

    return {
        video: tmpVideoIsNotPayment,
        chapter: tmpChapterNotPayment,
        courseId: courseId,
        videoMain: video
    }
}