/* eslint-disable react-hooks/exhaustive-deps */
import { Layout, Menu } from 'antd'
import { PageHeader } from '@ant-design/pro-layout'
import { useLocation } from "react-router-dom"
import { useEffect, useState } from 'react'
import Footer from '../../../common/components/footer'
import Header from '../../../common/components/header'
import GuideRegister from './GuideRegister'
import GuidePayment from './GuidePayment'

// import { useNavigate } from 'react-router-dom'
// import ScreenManager, { useDimensions } from '../../../common/mamager/ScreenManager'

const { Sider } = Layout
const { Content } = Layout

export default function GuidePlatform(props) {

    // const navigate = useNavigate()
    // const { width } = useDimensions()
    let urlPlatform = "http://localhost:3000"

    const componentsSwitch = (type) => {
        switch (type) {
            case "guide-toflyn":
                return <>Toflyn</>
            case "guide-register":
                return <GuideRegister />
            case "guide-payment":
                return <GuidePayment />
            default:
                return <>Not found</>
        }
    };

    const location = useLocation()
    const params = new URLSearchParams(location.search)
    const tab = params.get("tab")

    const [selectedMenuItem, setSelectedMenuItem] = useState(null)

    useEffect(() => {
        setSelectedMenuItem(tab ? tab : "toflyn")
    }, [])

    return (
        <>
            <Header firebase={props.firebase} user={props?.user?.uid} />

            <article>
                <Content style={{ backgroundColor: "#F5F5F5" }}>
                    <PageHeader
                        title={<label style={{ paddingBottom: 0, marginBottom: 0 }}>การใช้งานเเพลตฟอร์ม Toflyn</label>}
                        style={{ backgroundColor: "white" }}
                    >
                    </PageHeader>

                    <Layout>
                        <Sider width={300} className="site-layout-background" >
                            <Menu
                                defaultSelectedKeys={['0']}
                                defaultOpenKeys={['toflyn']}
                                mode="inline"
                                selectedKeys={selectedMenuItem}
                                onClick={async (e) => window.location.href = `${urlPlatform}/guide-platform?tab=${e.key}`}
                                style={{ height: '100%' }}
                            >
                                <Menu.Item key="guide-toflyn">
                                    <label style={{ paddingLeft: 10, marginBottom: 0, cursor: 'pointer' }}>เเพลตฟอร์ม Toflyn</label>
                                </Menu.Item>
                                <Menu.Item key="guide-register">
                                    <label style={{ paddingLeft: 10, marginBottom: 0, cursor: 'pointer' }}>การสมัครสมาชิก</label>
                                </Menu.Item>
                                <Menu.Item key="guide-payment">
                                    <label style={{ paddingLeft: 10, marginBottom: 0, cursor: 'pointer' }}>การซื้อคอร์สเรียน</label>
                                </Menu.Item>
                            </Menu>
                        </Sider>
                        <div style={{ backgroundColor: 'white', width: '100%', height: '100%' }}>
                            <div style={{ padding: 20 }}>
                                {componentsSwitch(selectedMenuItem)}
                            </div>
                        </div>
                    </Layout>
                </Content>
            </article>

            <Footer />
        </>
    )
}
