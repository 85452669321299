/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */

import { useState, useEffect, useRef } from 'react'
import {
    Button,
    Card,
    Checkbox,
    Col,
    Modal,
    Row,
    Spin,
    Steps
} from 'antd'
import { Icon } from '@iconify/react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from "react-redux"
import Layout, { Content } from 'antd/lib/layout/layout'
import { BsInfoCircle } from "react-icons/bs"
import firebase, { auth } from "../../config/firebase-config"
import { setRegisterCurrentStepStateToSuccess } from '../../actions/registerCurrentStep.action'
import { setRegisterInformationSuccess } from '../../actions/registerInformation.action'
import { setRegisterCareerAndEducationSuccess } from '../../actions/registerCareerAndEducation.action'
import { setRegisterCourseCategoryInterestSuccess } from '../../actions/registerCourseCategoryInterest.action'
import { setRegisterAddressSuccess } from '../../actions/registerAddress.action'
import { setRegisterBusinessDetailsSuccess } from '../../actions/registerBusinessDetails.action'
import "../register/css/index.css"
import Footer from '../../common/components/footer'
import Header from '../../common/components/header'
import { Notification } from '../../common/components/notification'
import { useDimensions } from '../../common/mamager/ScreenManager'
import CourseCategoryInterest from './components/courseCategoryInterest'
import Inforation from './components/inforation'
import PrivacyPolicyItem from '../policy/components/privacyPolicy/PrivacyPolicyItem'
import { insertJuristicPersonFetch, insertNaturalPersonFetch, insertUserFetch } from './API'
import { insertAccountVerifyBankFetch, insertAccountVerifyFetch, insertAccountVerifyIdentifyFetch } from '../account/API'
import { getBusinessCategoryFetch, getCareersFetch, getEducationFetch } from '../../common/components/userInfo/API'
import { getCourseCategoryFetch } from '../course/API'
import { lineNotifyUserManageResult } from '../erp/manage/user/API'
import moment from 'moment'
import LoadingPage from '../../common/components/loading/page'

// import CareerAndEducation from './components/careerAndEducation'
// import BusinessDetails from './components/businessDetails'
// import Address from './components/address'

const { Step } = Steps

export default function Register(props) {
    const { width } = useDimensions()

    const navigate = useNavigate()
    const location = useLocation()

    const language = "th"
    const providerId = location.state.providerId

    const dispatch = useDispatch()

    const [agree, setAgree] = useState(false)
    const [modalActive, setModalActive] = useState(true)
    const [modalBusinessOperation, setModalBusinessOperation] = useState(false)

    const careersRef = useRef([])
    const educationRef = useRef([])
    const businessCategoryRef = useRef([])
    const courseCategoryRef = useRef([])
    const [loading, setLoading] = useState(false)
    const [loadingSend, setLoadingSend] = useState(false)

    const [selectBusinessOperation, setSelectBusinessOperation] = useState(null)

    const [steps, setSteps] = useState(null)

    const { resultRegisterCurrentStep } = useSelector(({ registerCurrentStepReducer }) => registerCurrentStepReducer)
    const { resultRegisterInformation } = useSelector(({ registerInformationReducer }) => registerInformationReducer)                              // บุคคลธรรมดา
    const { resultCareerAndEducation } = useSelector(({ registerCareerAndEducationReducer }) => registerCareerAndEducationReducer)                 // บุคคลธรรมดา
    const { resultCourseCategoryInterest } = useSelector(({ registerCourseCategoryInterestReducer }) => registerCourseCategoryInterestReducer)     // บุคคลธรรมดา
    const { resultRegisterAddress } = useSelector(({ registerAddressReducer }) => registerAddressReducer)                                          // นิติบุคคล
    const { resultBusinessDetails } = useSelector(({ registerBusinessDetailsReducer }) => registerBusinessDetailsReducer)                          // นิติบุคคล

    const handleInsertRegister = async () => {
        setLoadingSend(true)

        let uid = null
        let isAccountHasBeenUsed = false
        let userName = resultRegisterInformation.userName
        let passWord = resultRegisterInformation.passWord // only register by email/pass
        let email = resultRegisterInformation.email
        let phone = resultRegisterInformation.phone
        let businessOperation = selectBusinessOperation ? selectBusinessOperation : null
        let careerId = resultCareerAndEducation.currentOccupation
        let educationId = resultCareerAndEducation.highestEducation
        let businessCategory = resultBusinessDetails.businessCategory
        let courseCategoryLike1 = resultCourseCategoryInterest.courseCategoryInterestedRank1
        let courseCategoryLike2 = resultCourseCategoryInterest.courseCategoryInterestedRank2
        let courseCategoryLike3 = resultCourseCategoryInterest.courseCategoryInterestedRank3
        let birthday = resultRegisterInformation.birthday?.split('-')
        let genderId = resultRegisterInformation.gender
        let firstName = resultRegisterInformation.firstName
        let lastName = resultRegisterInformation.lastName
        let companyNameTH = resultRegisterInformation.companyNameTH
        let companyNameENG = resultRegisterInformation.companyNameENG
        let companyAddress = resultRegisterAddress.companyAddress
        // let taxIdNumber = resultRegisterInformation.taxIdNumber
        let cityId = resultRegisterAddress.cityId
        let stateId = resultRegisterAddress.stateId
        let zipCode = resultRegisterAddress.zipCode
        let countryId = resultRegisterAddress.countryId
        let providerId = location.state.providerId
        let socialMediaId = location.state.socialMediaId

        if (location.state.providerId === "email/password") {
            // let objAuth = {
            //     email,
            //     passWord
            // }
            // console.log("objAuth : ", objAuth)
            const result = await auth.createUserWithEmailAndPassword(
                email,
                passWord
            ).then((user) => {
                return user
            }).catch((err) => {
                isAccountHasBeenUsed = true
                return null
            });
            uid = isAccountHasBeenUsed ? null : result.user.uid
        } else {
            uid = location.state.uid
        }

        if (!isAccountHasBeenUsed) {
            // insert data to db [user]
            let objUser = {
                businessOperationId: businessOperation ? businessOperation : null,
                userName: userName ? userName : null,
                email: email ? email : null,
                phone: phone ? phone : null,
                providerId: providerId ? providerId : null,
                socialMediaId: socialMediaId ? socialMediaId : null,
                uid: uid ? uid : null,
            }
            // console.log("objUser : ", objUser)
            const resultUser = await insertUserFetch(objUser)

            // insert data to db [user information]
            let userInfoIsSuccess = false
            if (businessOperation === 1) { // บุคคลธรรมดา
                let objNaturalPerson = {
                    careerId: careerId ? careerId : null,
                    educationId: educationId ? educationId : null,
                    genderId: genderId ? genderId : null,
                    courseCategoryLike1: courseCategoryLike1 ? courseCategoryLike1 : null,
                    courseCategoryLike2: courseCategoryLike2 ? courseCategoryLike2 : null,
                    courseCategoryLike3: courseCategoryLike3 ? courseCategoryLike3 : null,
                    birthday: birthday ? birthday[2] : null,
                    birthMonth: birthday ? birthday[1] : null,
                    birthYear: birthday ? birthday[0] : null,
                    firstName: firstName ? firstName : null,
                    lastName: lastName ? lastName : null,
                    uid: uid ? uid : null,
                }
                // console.log("objNaturalPerson : ", objNaturalPerson)
                const resultNaturalPerson = await insertNaturalPersonFetch(objNaturalPerson)
                if (resultNaturalPerson.isSuccess) {
                    userInfoIsSuccess = true
                } else {
                    userInfoIsSuccess = false
                }
            } else if (businessOperation === 2) { // นิติบุคคล
                let objJuristicPerson = {
                    companyNameTH: companyNameTH ? companyNameTH : null,
                    companyNameENG: companyNameENG ? companyNameENG : null,
                    companyAddress: companyAddress ? companyAddress : null,
                    businessCategory: businessCategory ? businessCategory : null,
                    // taxIdNumber: taxIdNumber ? taxIdNumber : null,
                    cityId: cityId ? cityId : null,
                    stateId: stateId ? stateId : null,
                    zipCode: zipCode ? zipCode : null,
                    countryId: countryId ? countryId : null,
                    uid: uid ? uid : null,
                }
                // console.log("objJuristicPerson : ", objJuristicPerson)
                const resultJuristicPerson = await insertJuristicPersonFetch(objJuristicPerson)
                if (resultJuristicPerson.isSuccess) {
                    userInfoIsSuccess = true
                } else {
                    userInfoIsSuccess = false
                }
            }

            // insert data to db [account_verified]
            let objAccountVerify = {
                uid: uid ? uid : null,
                businessOperationId: businessOperation ? businessOperation : null
            }
            // console.log("objAccountVerify : ", objAccountVerify);
            const resultAccountVerify = await insertAccountVerifyFetch(objAccountVerify)

            // insert data to db [account_verified_bank]
            let objAccountVerifyBank = {
                uid: uid ? uid : null,
                businessOperationId: businessOperation ? businessOperation : null
            }
            // console.log("objAccountVerifyBank : ", objAccountVerifyBank)
            const resultAccountVerifyBank = await insertAccountVerifyBankFetch(objAccountVerifyBank)

            // insert data to db [account_verified_identify]
            let objAccountVerifyIdCard = {
                uid: uid ? uid : null,

                firstName: firstName ? firstName : null,
                lastName: lastName ? lastName : null,

                companyNameTH: companyNameTH ? companyNameTH : null,
                companyNameENG: companyNameENG ? companyNameENG : null,

                businessOperationId: businessOperation ? businessOperation : null
            }
            // console.log("objAccountVerifyIdCard : ", objAccountVerifyIdCard)
            const resultAccountVerifyIdCard = await insertAccountVerifyIdentifyFetch(objAccountVerifyIdCard)

            if (
                resultUser.isSuccess &&
                userInfoIsSuccess &&
                resultAccountVerify.isSuccess &&
                resultAccountVerifyIdCard.isSuccess &&
                resultAccountVerifyBank.isSuccess
            ) {
                navigate('/home')
                Notification('success', 'สมัครสมาชิกสำเร็จ')

                genLineNotifyUserManage(
                    userName,
                    uid,
                    providerId
                )
            } else {
                Notification('error', 'ไม่สามารถสมัครสมาชิกได้ กรุณาลองใหม่อีกครั้ง')
                setLoadingSend(false)
            }
        } else {
            Notification('error', 'ไม่สามารถสมัครสมาชิกได้ เนื่องจากอีเมลที่ใช้สมัคร ถูกใช้งานเเล้ว')
            setLoadingSend(false)
        }
    }

    const genLineNotifyUserManage = async (userName, uid, providerId) => {
        // line
        let objLineNotify = {
            title: 'ผู้ใช้งานใหม่ \n',
            status: 'สมัครสมาชิกสำเร็จ',
            userName,
            providerId,
            // userId,
            uid,
            actionDateTime: moment().format('DD/MM/YYYY HH:mm')
        }
        // console.log("objLineNotify : ", objLineNotify)
        await lineNotifyUserManageResult(objLineNotify)
    }

    const getBaseApi = async () => {
        setLoading(true)

        let resultCareers = []
        if (careersRef.current.length === 0) {
            resultCareers = await getCareersFetch(language)
            careersRef.current = resultCareers
        }

        let resultEducation = []
        if (educationRef.current.length === 0) {
            resultEducation = await getEducationFetch(language)
            educationRef.current = resultEducation
        }

        let resultBusinessCategory = []
        resultBusinessCategory = await getBusinessCategoryFetch(language)
        businessCategoryRef.current = resultBusinessCategory

        let resultCourseCategory = []
        if (courseCategoryRef.current.length === 0) {
            resultCourseCategory = await getCourseCategoryFetch(language)
            courseCategoryRef.current = resultCourseCategory
        }

        if (!steps) {
            // ser default reducer
            dispatch(setRegisterInformationSuccess({
                birthday: undefined,
                firstName: undefined,
                lastName: undefined,
                gender: undefined,
                userName: undefined,
                passWord: undefined, // only register by email/pass
                email: undefined,
                phone: undefined,
                businessOperation: undefined,
                companyNameTH: undefined,
                companyNameENG: undefined
            }))

            dispatch(setRegisterAddressSuccess({
                companyAddress: undefined,
                cityId: undefined,
                stateId: undefined,
                zipCode: undefined,
                countryId: undefined
            }))

            dispatch(setRegisterCareerAndEducationSuccess({
                currentOccupation: undefined,
                highestEducation: undefined
            }))

            dispatch(setRegisterBusinessDetailsSuccess({
                businessCategory: undefined
            }))

            dispatch(setRegisterCourseCategoryInterestSuccess({
                courseCategoryInterestedRank1: undefined,
                courseCategoryInterestedRank2: undefined,
                courseCategoryInterestedRank3: undefined
            }))

            dispatch(setRegisterCurrentStepStateToSuccess(0))

            // gen step
            if (selectBusinessOperation === 1) {
                setSteps([
                    {
                        title: <label style={{ fontWeight: 100 }}>ข้อมูลผู้ใช้</label>,
                        content: <Inforation auth={location.state} businessOperation={1} />,
                    },
                    // {
                    //     title: 'อาชีพเเละการศึกษา',
                    //     content: <CareerAndEducation businessOperation={1} />,
                    // },
                    {
                        title: <label style={{ fontWeight: 100 }}>หมวดหมู่คอร์สที่ชอบ</label>,
                        content: <CourseCategoryInterest courseCategory={resultCourseCategory} />,
                    }
                ])
            } else if (selectBusinessOperation === 2) {
                setSteps([
                    {
                        title: <label style={{ fontWeight: 100 }}>ข้อมูลผู้ใช้</label>,
                        content: <Inforation auth={location.state} businessOperation={2} />,
                    },
                    // {
                    //     title: 'ทีอยู่',
                    // content: <Address businessOperation={2} />,
                    // },
                    // {
                    //     title: 'ประกอบธุรกิจ',
                    //     content: <BusinessDetails businessOperation={2} />,
                    // }
                ])
            }
        }
        setLoading(false)
    }

    useEffect(() => {
        if (location.state === undefined) {
            navigate("/login")
        } else {
            getBaseApi()
        }
    }, [selectBusinessOperation])

    return (
        <>
            <Layout className="layout">
                <Header btnAutnActive={false} />

                <article>
                    {!loading && steps ?
                        <>
                            {resultRegisterCurrentStep >= (selectBusinessOperation === 1 ? 2 : 1) ?
                                <Content>
                                    <div style={width >= 1500 ? { paddingLeft: '15%', paddingRight: '15%' } : ((width > 912 && width < 1500) ? { paddingLeft: '5%', paddingRight: '5%' } : { paddingLeft: 0, paddingRight: 0 })}>
                                        <div style={{ backgroundColor: 'white', padding: 30 }}>
                                            <div style={{ paddingBottom: 20 }}>
                                                <label>สรุปข้อมูล การกรอกสมัครสมาชิก</label>
                                            </div>
                                            <Row>
                                                <Col span={24}>
                                                    <Card title={<label style={{ fontWeight: 100 }}>ข้อมูลผู้ใช้</label>} style={{ marginBottom: 20 }}>
                                                        <Row gutter={[12, 12]}>
                                                            <Col xs={12} xl={6}>
                                                                <label style={{ float: "right" }}>ชื่อผู้ใช้งาน : </label>
                                                            </Col>
                                                            <Col xs={12} xl={18}>
                                                                <label>{resultRegisterInformation.userName}</label>
                                                            </Col>

                                                            {providerId === "email/password" ?
                                                                <>
                                                                    <Col xs={12} xl={6}>
                                                                        <label style={{ float: "right" }}>{selectBusinessOperation === 1 ? "อีเมล" : "อีเมลบริษัท"} : </label>
                                                                    </Col>
                                                                    <Col xs={12} xl={18}>
                                                                        <label>{resultRegisterInformation.email}</label>
                                                                    </Col>
                                                                </>
                                                                : []
                                                            }
                                                        </Row>
                                                    </Card>

                                                    {selectBusinessOperation === 1 ?
                                                        <>
                                                            <Card title={<label style={{ fontWeight: 100 }}>หมวดหมู่คอร์สที่ชอบ</label>}>
                                                                <Row gutter={[12, 12]}>
                                                                    <Col xs={12} xl={6}>
                                                                        <label style={{ float: "right" }}>หมวดหมู่คอร์สที่ชื่นชอบ อันดับ 1 : </label>
                                                                    </Col>
                                                                    <Col xs={12} xl={18}>
                                                                        <label>{courseCategoryRef.current?.find(e => resultCourseCategoryInterest.courseCategoryInterestedRank1 === e.value)?.label}</label>
                                                                    </Col>

                                                                    <Col xs={12} xl={6}>
                                                                        <label style={{ float: "right" }}>หมวดหมู่คอร์สที่ชื่นชอบ อันดับ 2 : </label>
                                                                    </Col>
                                                                    <Col xs={12} xl={18}>
                                                                        <label>{courseCategoryRef.current?.find(e => resultCourseCategoryInterest.courseCategoryInterestedRank2 === e.value)?.label}</label>
                                                                    </Col>

                                                                    <Col xs={12} xl={6}>
                                                                        <label style={{ float: "right" }}>หมวดหมู่คอร์สที่ชื่นชอบ อันดับ 3 : </label>
                                                                    </Col>
                                                                    <Col xs={12} xl={18}>
                                                                        <label>{courseCategoryRef.current?.find(e => resultCourseCategoryInterest.courseCategoryInterestedRank3 === e.value)?.label}</label>
                                                                    </Col>
                                                                </Row>
                                                            </Card>
                                                        </>
                                                        : []
                                                    }
                                                </Col>

                                                <Col span={24} style={{ paddingTop: 15 }}>
                                                    <Button
                                                        style={{ float: 'left', width: 130 }}
                                                        type="default"
                                                        onClick={() => dispatch(setRegisterCurrentStepStateToSuccess(Number(resultRegisterCurrentStep) - 1))}
                                                    >
                                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                                            <Icon icon="fluent:arrow-step-back-20-filled" />
                                                            <label style={{ paddingLeft: 10, cursor: "pointer" }}>ย้อนกลับ</label>
                                                        </div>
                                                    </Button>

                                                    <Button
                                                        loading={loadingSend}
                                                        style={{ float: 'right', width: 130 }}
                                                        type="primary"
                                                        onClick={() => handleInsertRegister()}
                                                    >
                                                        {!loadingSend ?
                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                <Icon icon="ic:baseline-app-registration" style={{ color: "white" }} />
                                                                <label style={{ paddingLeft: 10, cursor: "pointer" }}>ยืนยัน</label>
                                                            </div>
                                                            : []
                                                        }
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                </Content>
                                :
                                <Content>
                                    <div style={width >= 1500 ? { paddingLeft: '15%', paddingRight: '15%' } : ((width > 912 && width < 1500) ? { paddingLeft: '5%', paddingRight: '5%' } : { paddingLeft: 0, paddingRight: 0 })}>
                                        <div style={{ backgroundColor: 'white', padding: 30 }}>
                                            <div style={{ paddingBottom: selectBusinessOperation === 1 ? 20 : 0 }}>
                                                <label>สมัครสมาชิก ({selectBusinessOperation === 1 ? "บุคคลธรรมดา" : "นิติบุคคล"})</label>
                                            </div>

                                            {selectBusinessOperation === 1 ?
                                                <Steps
                                                    current={resultRegisterCurrentStep}
                                                    direction={width >= 1500 ? "horizontal" : ((width > 712 && width < 1500) ? "horizontal" : "vertical")}
                                                >
                                                    {steps.map(item => (
                                                        <Step key={item.title} title={item.title} />
                                                    ))}
                                                </Steps>
                                                : []
                                            }

                                            <div className="steps-content">{steps[resultRegisterCurrentStep]?.content}</div>
                                        </div>
                                    </div>
                                </Content>
                            }
                        </>
                        :
                        <LoadingPage loading={loading} isTitleActive={true} />
                    }
                </article>

                <Footer />
            </Layout>

            <Modal
                maskClosable={false}
                title={<label style={{ fontWeight: 100 }}>คุณเป็นผู้ใช้งานในรูปเเบบใด ? </label>}
                visible={modalBusinessOperation}
                onCancel={() => {
                    setModalActive(false)
                }}
                footer={null}
                width={300}
            >
                <center>
                    <Button
                        type="primary"
                        size='large'
                        onClick={() => {
                            setSelectBusinessOperation(1)
                            setModalBusinessOperation(false)
                        }}
                        style={{ width: '100%', marginBottom: 15 }}
                    >
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Icon icon="fluent:person-12-filled" style={{ color: "white", width: 20, height: 20 }} />
                            <label style={{ paddingLeft: 10, cursor: 'pointer' }}>บุคคลธรรมดา</label>
                        </div>
                    </Button>
                    <Button
                        type="primary"
                        size='large'
                        style={{ width: '100%' }}
                        onClick={() => {
                            setSelectBusinessOperation(2)
                            setModalBusinessOperation(false)
                        }}
                    >
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Icon icon="clarity:building-solid" style={{ color: "white", width: 20, height: 20 }} />
                            <label style={{ paddingLeft: 10, cursor: 'pointer' }}>นิติบุคคล</label>
                        </div>
                    </Button>
                </center >
            </Modal >

            <Modal
                maskClosable={false}
                closable={false}
                title={
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <BsInfoCircle style={{ marginRight: 10, width: 22, height: 22, color: '#23B14D' }} />
                        <label style={{ fontWeight: 100 }}>นโยบายความเป็นส่วนตัว ภายใต้ชื่อ Toflyn</label>
                    </div>
                }
                style={{ top: 50 }}
                visible={modalActive}
                onOk={() => {
                    setModalActive(false)
                    setModalBusinessOperation(true)
                }}
                onCancel={() => {
                    firebase.auth().signOut()
                    navigate("/")
                }}
                okText={
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Icon icon="line-md:confirm" style={{ color: !agree ? "gray" : "white" }} />
                        <label style={{ paddingLeft: 10, cursor: 'pointer' }}>ตกลง</label>
                    </div>
                }
                cancelText={
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Icon icon="ant-design:home-outlined" style={{ color: "black" }} />
                        <label style={{ paddingLeft: 10, cursor: 'pointer' }}>กลับไปหน้าเเรก</label>
                    </div>
                }
                width={800}
                okButtonProps={{ disabled: !agree }}
            >
                <Row>
                    <Col span={24} style={{ paddingBottom: 15 }}>
                        โปรดอ่านโดยละเอียด และยอมรับเพื่อดำเนินการใช้งานระบบ
                    </Col>
                    <Col span={24} style={{ paddingBottom: 15 }}>
                        <div
                            style={{
                                overflow: 'auto',
                                height: 330,
                                width: 'auto',
                                overflowX: 'hidden',
                                border: '1px solid #E0E0E0',
                                padding: 15
                            }}
                        >
                            <PrivacyPolicyItem />
                        </div>
                    </Col>
                    <Col span={24}>
                        <Checkbox
                            className='checkbox-custom'
                            onChange={() => setAgree(!agree)}
                        >ข้าพเจ้ายอมรับนโยบายความเป็นส่วนตัวข้างต้น เเละ <a href="https://toflyn.com/privacy-policy"><label style={{ cursor: "pointer", textDecoration: "underline", color: "#23B14D" }}>เงื่อนไขการใช้งาน</label></a></Checkbox>
                    </Col>
                </Row>
            </Modal>
        </>
    )
}