import { useNavigate } from 'react-router-dom'
import { Icon } from '@iconify/react'
import { Card, Col, Divider, Pagination, Row } from 'antd'
import { useState } from 'react'
import { useDimensions } from '../../../../common/mamager/ScreenManager'
import { iconCourseCategory } from '../../../../constants'

export default function CourseCategory(props) {

    const navigate = useNavigate()
    const { width } = useDimensions()

    const [page, setPage] = useState({
        minValue: 0,
        maxValue: 12
    })

    const handleChange = value => {
        if (value <= 1) {
            setPage({
                minValue: 0,
                maxValue: 12
            })
        } else {
            setPage({
                minValue: (value - 1) * 12,
                maxValue: value * 12
            })
        }
    }

    return (
        <>
            <Row style={width >= 1500 ? { paddingLeft: 0, paddingRight: 0 } : ((width > 912 && width < 1500) ? { paddingLeft: 0, paddingRight: 0 } : { paddingLeft: 12, paddingRight: 12 })}>
                <Col span={24} style={{ paddingBottom: 15 }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div><Icon icon="ic:baseline-category" style={{ color: "#23b14d", width: 25, height: 25 }} /></div>
                        <label style={{ fontSize: 18, paddingLeft: 10, marginTop: -4 }}>หมวดหมู่คอร์ส ( {props?.courseCategory ? props?.courseCategory?.length : 0} รายการ)</label>
                    </div>
                </Col>
                <Col span={24}>
                    <div>
                        <Row>
                            {props.courseCategory
                                .slice(page.minValue, page.maxValue)
                                .map((val, index) =>
                                    <Col
                                        xs={24} sm={12} md={8} lg={8} xl={6} xxl={4}
                                        style={{
                                            paddingRight: 15,
                                            paddingTop: 7.5,
                                            paddingBottom: 7.5,
                                            borderRadius: 20
                                        }}
                                    >
                                        <Card
                                            hoverable
                                            bodyStyle={{ padding: "10px" }}
                                            style={{
                                                width: '100%',
                                                borderRadius: 10,
                                                minHeight: 60
                                                // maxHeight: 108
                                            }}
                                            onClick={() => {
                                                navigate("/course-list" +
                                                    "?course-category=" + val.value
                                                    + "&course-text="
                                                    + "&course-rating=" + 1
                                                    + "&is-search=" + false

                                                )
                                            }}
                                        >
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <Icon
                                                    icon={iconCourseCategory[page.minValue + index]}
                                                    style={{
                                                        color: "#23b14d",
                                                        width: 40,
                                                        height: 40,
                                                        // marginTop: 2
                                                    }}
                                                />
                                                <span style={{ fontSize: 16, paddingLeft: 8 }}>{val.label}</span>
                                            </div>
                                        </Card>
                                    </Col>
                                )}
                        </Row>
                    </div>
                </Col>
                <Col span={24} style={{ paddingTop: 8 }}>
                    <center>
                        <Pagination
                            defaultCurrent={1}
                            defaultPageSize={12}
                            onChange={handleChange}
                            total={props.courseCategory.length}
                        />
                    </center>
                </Col>
            </Row>
            <Divider />
        </>
    )
}
