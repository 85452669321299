/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */

import { useEffect, useState } from 'react'
import {
    Col,
    Divider,
    Image,
    Radio,
    Row,
    Input,
    Button,
    Popconfirm,
    Table,
    Modal,
    Spin,
} from 'antd'
import Column from 'antd/lib/table/Column'
import { BsSearch } from "react-icons/bs"
import { Notification } from '../../../../../common/components/notification'
import Video from '../../../../../common/components/video'
import { PathVideo } from '../../../../../common/components/video/components/convert/pathVideo'
import { imgDefaultUrl, storageUrl, videoDefaultUrl } from '../../../../../constants'
import { updateInspectRequestEditCourseChapterByIdFetch, updateInspectRequestEditCourseVideoByIdFetch, updateInspectRequestOpenCourseChapterByIdFetch, updateInspectRequestOpenCourseVideoByIdFetch } from '../../../API'
import LoadingPage from '../../../../../common/components/loading/page'

const { TextArea } = Input

export default function InspectVideo(props) {
    const [videoOriginal, setVideoOriginal] = useState([])
    const [videoReqtEdit, setVideoReqtEdit] = useState([])
    const [chapterOriginal, setChapterOriginal] = useState([])
    const [chapterReqtEdit, setChapterReqtEdit] = useState([])

    const [loading, setLoading] = useState(true)

    const [courseId, setCourseId] = useState(null)
    const [uid, setUid] = useState(null)
    const [isCloseInspect, setIsCloseInspect] = useState({
        status: null,
        message: null
    })
    const [userType, setUserType] = useState(null)

    const [pageCurrent, setPageCurrent] = useState(1)

    const [modal, setModal] = useState({
        isOpen: false,
        url: null,
        title: null,
    })

    const [isSendResults, setIsSendResults] = useState(true)

    const onPagine = (n) => {
        setPageCurrent(n.current);
    }

    const handleInspectCourseVideoApprovalUpdate = (event, record, row) => {
        let reasonNotApploval = record.reasonNotApploval
        let inspectCourseAdjustId = null
        if (event === 1) { // detect reason
            reasonNotApploval = null
        }

        if (event === 2) { // detect adjust
            inspectCourseAdjustId = 2
        }

        if (userType === "admin-inspect-open-course") {
            setVideoOriginal(videoOriginal.map(val => val.index !== row.index ? val : {
                index: val.index,
                name: val.name,
                chapterName: val.chapterName,
                description: val.description,
                inspect: {
                    inspectId: record.inspectId,
                    inspectCourseApprovalId: event,
                    inspectCourseAdjustId,
                    reasonNotApploval,
                },
                courseVideoId: val.courseVideoId,
            }))
        } else if (userType === "admin-inspect-edit-course") {
            setVideoReqtEdit(videoReqtEdit.map(val => val.index !== row.index ? val : {
                index: val.index,
                name: val.name,
                chapterName: val.chapterName,
                description: val.description,
                request: val.request,
                inspect: {
                    inspectId: record.inspectId,
                    inspectCourseApprovalId: event,
                    inspectCourseAdjustId,
                    reasonNotApploval,
                },
                courseVideoId: val.courseVideoId,
            }))
        }
    }

    const handleInspectCourseChapterApprovalUpdate = (event, record, row) => {
        let reasonNotApploval = record.reasonNotApploval;
        let inspectCourseAdjustId = null;
        if (event === 1) { // detect reason
            reasonNotApploval = null;
        }

        if (event === 2) { // detect adjust
            inspectCourseAdjustId = 2;
        }

        if (userType === "admin-inspect-open-course") {
            setChapterOriginal(chapterOriginal.map(val => val.index !== row.index ? val : {
                index: val.index,
                name: val.name,
                description: val.description,
                inspect: {
                    inspectId: record.inspectId,
                    inspectCourseApprovalId: event,
                    inspectCourseAdjustId,
                    reasonNotApploval,
                },
                courseChapterId: val.courseChapterId,
            }))
        } else if (userType === "admin-inspect-edit-course") {
            setChapterReqtEdit(chapterReqtEdit.map(val => val.index !== row.index ? val : {
                index: val.index,
                name: val.name,
                description: val.description,
                request: val.request,
                inspect: {
                    inspectId: record.inspectId,
                    inspectCourseApprovalId: event,
                    inspectCourseAdjustId,
                    reasonNotApploval,
                },
                courseChapterId: val.courseChapterId,
            }))
        }
    }

    const handleReasonCourseVideoNotApplovalUpdate = (event, record, row) => {
        if (userType === "admin-inspect-open-course") {
            setVideoOriginal(videoOriginal.map(val => val.index !== row.index ? val : {
                index: val.index,
                name: val.name,
                chapterName: val.chapterName,
                description: val.description,
                inspect: {
                    inspectId: record.inspectId,
                    inspectCourseApprovalId: record.inspectCourseApprovalId,
                    inspectCourseAdjustId: record.inspectCourseAdjustId,
                    reasonNotApploval: event,
                },
                courseVideoId: val.courseVideoId,
            }))
        } else if (userType === "admin-inspect-edit-course") {
            setVideoReqtEdit(videoReqtEdit.map(val => val.index !== row.index ? val : {
                index: val.index,
                name: val.name,
                chapterName: val.chapterName,
                description: val.description,
                request: val.request,
                inspect: {
                    inspectId: record.inspectId,
                    inspectCourseApprovalId: record.inspectCourseApprovalId,
                    inspectCourseAdjustId: record.inspectCourseAdjustId,
                    reasonNotApploval: event,
                },
                courseVideoId: val.courseVideoId,
            }))
        }
    }

    const handleReasonCourseChapterNotApplovalUpdate = (event, record, row) => {
        if (userType === "admin-inspect-open-course") {
            setChapterOriginal(chapterOriginal.map(val => val.index !== row.index ? val : {
                index: val.index,
                name: val.name,
                description: val.description,
                inspect: {
                    inspectId: record.inspectId,
                    inspectCourseApprovalId: record.inspectCourseApprovalId,
                    inspectCourseAdjustId: record.inspectCourseAdjustId,
                    reasonNotApploval: event
                },
                courseChapterId: val.courseChapterId
            }))
        } else if (userType === "admin-inspect-edit-course") {
            setChapterReqtEdit(chapterReqtEdit.map(val => val.index !== row.index ? val : {
                index: val.index,
                name: val.name,
                description: val.description,
                request: val.request,
                inspect: {
                    inspectId: record.inspectId,
                    inspectCourseApprovalId: record.inspectCourseApprovalId,
                    inspectCourseAdjustId: record.inspectCourseAdjustId,
                    reasonNotApploval: event
                },
                courseChapterId: val.courseChapterId
            }))
        }
    }

    const detectVideoAndChapterInspect = async () => {
        let detectVideoNeatness = true // if false is select not pass but reason is empty
        let detectChapterNeatness = true // if false is select not pass but reason is empty
        let resultVideo = { isSuccess: false }
        let resultChapter = { isSuccess: false }

        if (userType === "admin-inspect-open-course") {
            // video
            videoOriginal.map(val => {
                if (val.inspect.inspectCourseApprovalId) {
                    if (val.inspect.inspectCourseApprovalId === 2 &&
                        (
                            val.inspect.reasonNotApploval === null ||
                            val.inspect.reasonNotApploval === "" ||
                            val.inspect.reasonNotApploval === undefined)
                    ) {
                        detectVideoNeatness = false;
                    }
                } else {
                    detectVideoNeatness = false;
                }
            })

            // chapter
            chapterOriginal.map(val => {
                if (val.inspect.inspectCourseApprovalId) {
                    if (val.inspect.inspectCourseApprovalId === 2 &&
                        (
                            val.inspect.reasonNotApploval === null ||
                            val.inspect.reasonNotApploval === "" ||
                            val.inspect.reasonNotApploval === undefined)
                    ) {
                        detectChapterNeatness = false
                    }
                } else {
                    detectChapterNeatness = false
                }
            })
        } else if (userType === "admin-inspect-edit-course") {
            // video
            videoReqtEdit.map(val => {
                if (val.inspect.inspectCourseApprovalId) {
                    if (val.inspect.inspectCourseApprovalId === 2 &&
                        (
                            val.inspect.reasonNotApploval === null ||
                            val.inspect.reasonNotApploval === "" ||
                            val.inspect.reasonNotApploval === undefined)
                    ) {
                        detectVideoNeatness = false
                    }
                } else {
                    detectVideoNeatness = false
                }
            })

            // chapter
            chapterReqtEdit.map(val => {
                if (val.inspect.inspectCourseApprovalId) {
                    if (val.inspect.inspectCourseApprovalId === 2 &&
                        (
                            val.inspect.reasonNotApploval === null ||
                            val.inspect.reasonNotApploval === "" ||
                            val.inspect.reasonNotApploval === undefined)
                    ) {
                        detectVideoNeatness = false
                    }
                } else {
                    detectVideoNeatness = false
                }
            })
        }

        if (detectVideoNeatness && detectChapterNeatness) { // complete information
            // video -----------------------------------------------------------------------------------
            if (userType === "admin-inspect-open-course") {
                let obj = {
                    inspectList: videoOriginal,
                    isInspect: 1,
                    courseId,
                    uid
                }
                resultVideo = await updateInspectRequestOpenCourseVideoByIdFetch(obj)
            } else if (userType === "admin-inspect-edit-course") {
                let obj = {
                    inspectList: videoReqtEdit,
                    isInspect: 1,
                    courseId,
                    uid
                }
                resultVideo = await updateInspectRequestEditCourseVideoByIdFetch(obj)
            }

            // chapter ---------------------------------------------------------------------------------
            if (chapterOriginal?.length > 0 || chapterReqtEdit?.length > 0) { // Have Chapter
                if (userType === "admin-inspect-open-course") {
                    let obj = {
                        inspectList: chapterOriginal,
                        isInspect: 1,
                        courseId
                    }
                    resultChapter = await updateInspectRequestOpenCourseChapterByIdFetch(obj)
                } else if (userType === "admin-inspect-edit-course") {
                    let obj = {
                        inspectList: chapterReqtEdit,
                        isInspect: 1,
                        courseId
                    }
                    resultChapter = await updateInspectRequestEditCourseChapterByIdFetch(obj)
                }
            } else { // Do not have Chapter
                resultChapter = { isSuccess: true }
            }
            
            // notify send api
            if (resultVideo.isSuccess && resultChapter.isSuccess) {
                props.reloadInspectCourse()
                Notification("success", "เเจ้งเตือน", "บันทึกผลตรวจสอบสำเร็จ")
            } else {
                Notification("error", "เเจ้งเตือน", "บันทึกผลตรวจสอบไม่สำเร็จ กรุณาลองใหม่อีกครั้ง")
            }
        } else {
            // notify error
            Notification("warning", "เเจ้งเตือน", "ไม่สามารถบันทึกผลตรวจสอบได้ กรุณาตรวจสอบให้ครับ")
        }
    }
    
    const genVideoColumn = (value) => {
        let tempCourseVideo = []
        value.map((val, index) => {
            let inspect = props.inspect.courseVideo.formData.filter(val2 => val2.id === val.courseVideoId)[0]
            if (inspect) {
                tempCourseVideo.push({
                    index: (index + 1),
                    name:
                        <>
                            {val.courseVideoTitleName}
                            <BsSearch
                                style={{ float: "right", cursor: "pointer", marginTop: 4 }}
                                onClick={() => {
                                    let original = props.courseVideoOriginal.find(val2 => val2.courseVideoId === val.courseVideoId);
                                    
                                    setModal({
                                        isOpen: true,

                                        originalImgURL: original.courseVideoImgLocation,
                                        originalVideoURL: original.courseVideoLocation,
                                        originalDescription: original.courseVideoDescriptionName,
                                        originalVideoPrice: original.courseVideoPrice,

                                        reqtEditImgURL: val.courseVideoImgLocation,
                                        reqtEditVideoURL: val.courseVideoLocation,
                                        reqtEditTitle: val.courseVideoTitleName,
                                        reqtEditDescription: val.courseVideoDescriptionName,
                                        reqtEditVideoPrice: val.courseVideoPrice
                                    })
                                }}
                            />
                        </>
                    ,
                    description: val.courseVideoDescriptionName,
                    chapterName: val.courseChapterTitleName ? val.courseChapterTitleName : "ไม่มี",
                    request: val.approvalStatusName,
                    inspect: {
                        inspectId: inspect.id,
                        inspectCourseApprovalId: inspect.inspectionCourseApprovalId,
                        inspectCourseAdjustId: inspect.inspectionCourseAdjustId,
                        reasonNotApploval: inspect.reasonCourseNotApproval,
                    },
                    courseVideoId: val.courseVideoId,
                })
            }
        })
        return tempCourseVideo
    }

    const genChapterColumn = (value) => {
        let tempCourseChapter = []
        value?.map((val, index) => {
            let inspect = props.inspect.courseChapter.formData.filter(val2 => val2.id === val.courseChapterId)[0]
            if (inspect) {
                tempCourseChapter.push({
                    index: (index + 1),
                    name: val.courseChapterTitleName,
                    description: val.courseChapterDescriptionName,
                    inspect: {
                        inspectId: inspect.id,
                        inspectCourseApprovalId: inspect.inspectionCourseApprovalId,
                        inspectCourseAdjustId: inspect.inspectionCourseAdjustId,
                        reasonNotApploval: inspect.reasonCourseNotApproval,
                    },
                    courseChapterId: val.courseChapterId,
                })
            }
        })
        return tempCourseChapter;
    }

    const handleTimeVideo = (duration) => { }
    const handlePercentWatchVideoCourse = (timeVideoCourse, timerWatchVideoCourse, percent, isFirstLoadApi, VideoCourseList, timerWatchVideoCourseList) => { }
    const handlePlayer = (player) => { }
    const handleControls = (controls) => { }

    useEffect(() => {
        setLoading(true)

        setUserType(props.userType)
        setCourseId(props.courseId)
        setUid(props.uid)

        if ( // open button send result to teacher
            props.inspect.inspectionCourseCardId !== 2 &&               // ต้องไม่รอตรวจสอบ
            props.inspect.inspectionCourseDescriptionId !== 2 &&        // ต้องไม่รอตรวจสอบ
            props.inspect.inspectionCoursePriceId !== 2 &&              // ต้องไม่รอตรวจสอบ

            props.inspect.inspectionCourseMediaIntroId !== 2 &&         // ต้องไม่รอตรวจสอบ
            props.inspect.inspectionCourseDocumentId !== 2 &&           // ต้องไม่รอตรวจสอบ
            props.inspect.inspectionCourseVideoId !== 2 &&              // ต้องไม่รอตรวจสอบ

            props.inspect.inspectionCourseId === 3                      // สถานะโดยรวม คือ รอตรวจสอบ [2]
        ) {
            setIsSendResults(true)
        } else {
            setIsSendResults(false)
        }

        setVideoOriginal(genVideoColumn(props.courseVideoOriginal))
        setChapterOriginal(genChapterColumn(props.courseChapterOriginal))

        setTimeout(function () {
            if (props.userType === "admin-inspect-open-course") {
                setIsCloseInspect(props.isCloseInspect)
            } else if (props.userType === "admin-inspect-edit-course") {
                setVideoReqtEdit(genVideoColumn(props.courseVideoReqtEdit))
            }
            setLoading(false)
        }, 500)
    }, [])

    const genAdminInspectOpenCourse = (isSaveInspect) => {
        return (
            <Row style={{ fontSize: 14 }}>
                <Col span={24} style={{ paddingLeft: 15 }}>
                    <label>รายการกลุ่มวิดีโอ</label>
                    <Table
                        loading={loading}
                        dataSource={chapterOriginal}
                        pagination={{
                            current: pageCurrent,
                            pageSize: 10,
                            total: chapterOriginal.length
                        }}
                        onChange={(n) => onPagine(n)}
                    >
                        <Column title="No" dataIndex="index" width="5%" align="center" />
                        <Column title="ชื่อกลุ่มวิดีโอ" dataIndex="name" width="35%" />
                        <Column title="รายละเอียดกลุ่มวิดีโอ" dataIndex="description" width="40%" />
                        {isSaveInspect ?
                            <Column title="สถานะตรวจสอบ" dataIndex="inspect" width="20%" align="center"
                                render={(record, row) => {
                                    return (
                                        <Row>
                                            <Col span={24} style={{ textAlign: "center", paddingBottom: 6 }}>
                                                <Radio.Group
                                                    disabled={isSendResults}
                                                    onChange={(e) => handleInspectCourseChapterApprovalUpdate(e.target.value, record, row)}
                                                    value={record.inspectCourseApprovalId}
                                                >
                                                    <Radio value={1}>ผ่าน</Radio>
                                                    <Radio value={2}>ไม่ผ่าน</Radio>
                                                </Radio.Group>
                                            </Col>
                                            {record.inspectCourseApprovalId === 2 ?
                                                <Col span={24}>
                                                    <TextArea
                                                        disabled={isSendResults}
                                                        onBlur={(e) => handleReasonCourseChapterNotApplovalUpdate(e.target.defaultValue, record, row)}
                                                        defaultValue={record.reasonNotApploval}
                                                        placeholder="เหตุผลที่ไม่ผ่าน"
                                                        autoSize={{ minRows: 2, maxRows: 2 }}
                                                    />
                                                </Col>
                                                : []}
                                        </Row>
                                    )
                                }}
                            />
                            : []
                        }
                    </Table>
                </Col>

                <Col span={24} style={{ paddingLeft: 15, borderTop: "1px solid #EEEEEE" }}>
                    <label>รายการวิดีโอ</label>
                    <Table
                        loading={loading}
                        dataSource={videoOriginal}
                        pagination={{
                            current: pageCurrent,
                            pageSize: 10,
                            total: videoOriginal.length
                        }}
                        onChange={(n) => onPagine(n)}
                    >
                        <Column title="No" dataIndex="index" width="5%" align="center" />
                        <Column title="ชื่อวิดีโอ" dataIndex="name" width="25%" />
                        <Column title="ชื่อกลุ่มวิดีโอ" dataIndex="chapterName" width="20%" />
                        <Column title="รายละเอียดวิดีโอ" dataIndex="description" width="30%" />

                        {isSaveInspect ?
                            <Column title="สถานะตรวจสอบ" dataIndex="inspect" width="20%" align="center"
                                render={(record, row) => {
                                    return (
                                        <Row>
                                            <Col span={24} style={{ textAlign: "center", paddingBottom: 6 }}>
                                                <Radio.Group
                                                    disabled={isSendResults}
                                                    onChange={(e) => handleInspectCourseVideoApprovalUpdate(e.target.value, record, row)}
                                                    value={record.inspectCourseApprovalId}
                                                >
                                                    <Radio value={1}>ผ่าน</Radio>
                                                    <Radio value={2}>ไม่ผ่าน</Radio>
                                                </Radio.Group>
                                            </Col>
                                            {record.inspectCourseApprovalId === 2 ?
                                                <Col span={24}>
                                                    <TextArea
                                                        disabled={isSendResults}
                                                        onBlur={(e) => handleReasonCourseVideoNotApplovalUpdate(e.target.defaultValue, record, row)}
                                                        defaultValue={record.reasonNotApploval}
                                                        placeholder="เหตุผลที่ไม่ผ่าน"
                                                        autoSize={{ minRows: 2, maxRows: 2 }}
                                                    />
                                                </Col>
                                                : []}
                                        </Row>
                                    )
                                }}
                            />
                            : []
                        }
                    </Table>
                </Col>

                {isSaveInspect ?
                    <>
                        <Divider style={{ marginBottom: 10, marginTop: 10 }} />
                        {!isCloseInspect.status ?
                            <Col span={24} style={{ textAlign: "center" }}>
                                <Popconfirm
                                    disabled={isSendResults}
                                    title="คุณยืนยันบันทึกผลตรวจสอบหรือไม่ ?"
                                    okText={<span style={{ width: 50 }}>ใช่</span>}
                                    onConfirm={() => detectVideoAndChapterInspect()}
                                    cancelText={<span style={{ width: 50 }}>ไม่ใช่</span>}
                                >
                                    <Button
                                        type="primary"
                                        style={{ width: 150 }}
                                        disabled={isSendResults}

                                    >บันทึกผลตรวจสอบ</Button>
                                </Popconfirm>
                            </Col>
                            :
                            <Col span={24} style={{ textAlign: "center" }}>
                                <center><label style={{ color: "red" }}>{isCloseInspect.message}</label></center>
                            </Col>
                        }
                    </>
                    : []
                }
            </Row>
        )
    }

    const genAdminInspectEditCourse = () => {
        return (
            <Row>
                <Col
                    span={24}
                >
                    <Table
                        loading={loading}
                        dataSource={videoReqtEdit}
                        pagination={{
                            current: pageCurrent,
                            pageSize: 15,
                            total: videoReqtEdit.length
                        }}
                        onChange={(n) => onPagine(n)}
                    >
                        <Column title="No" dataIndex="index" width="5%" align="center" />
                        <Column title="ชื่อวิดีโอ" dataIndex="name" width="20%" />
                        <Column title="ชื่อกลุ่มวิดีโอ" dataIndex="chapterName" width="15%" />
                        <Column title="รายละเอียดการสอน" dataIndex="description" width="20%" />
                        <Column title="คำขอ" dataIndex="request" width="10%" align="center" />
                        <Column title="สถานะตรวจสอบ" dataIndex="inspect" width="20%" align="center"
                            render={(record, row) => {
                                return (
                                    <Row>
                                        <Col span={24} style={{ textAlign: "center", paddingBottom: 6 }}>
                                            <Radio.Group
                                                disabled={isSendResults}
                                                onChange={(e) => handleInspectCourseVideoApprovalUpdate(e.target.value, record, row)}
                                                value={record.inspectCourseApprovalId}
                                            >
                                                <Radio value={1}>ผ่าน</Radio>
                                                <Radio value={2}>ไม่ผ่าน</Radio>
                                            </Radio.Group>
                                        </Col>
                                        {record.inspectCourseApprovalId === 2 ?
                                            <Col span={24}>
                                                <TextArea
                                                    disabled={isSendResults}
                                                    onBlur={(e) => handleReasonCourseVideoNotApplovalUpdate(e.target.defaultValue, record, row)}
                                                    defaultValue={record.reasonNotApploval}
                                                    placeholder="เหตุผลที่ไม่ผ่าน"
                                                    autoSize={{ minRows: 2, maxRows: 2 }}
                                                />
                                            </Col>
                                            : []}
                                    </Row>
                                )
                            }}
                        />
                    </Table>
                </Col>

                <Divider style={{ marginBottom: 10, marginTop: 10 }} />
                {props.inspect.inspectionCourseVideoId ?
                    <Col span={24}>
                        <center>
                            {!isCloseInspect.status ?
                                <Col span={24} style={{ textAlign: "center" }}>
                                    <Popconfirm
                                        disabled={isSendResults}
                                        title="คุณยืนยันบันทึกผลตรวจสอบหรือไม่ ?"
                                        okText={<span style={{ width: 50 }}>ใช่</span>}
                                        onConfirm={() => detectVideoAndChapterInspect()}
                                        cancelText={<span style={{ width: 50 }}>ไม่ใช่</span>}
                                    >
                                        <Button
                                            type="primary"
                                            style={{ width: 150 }}
                                            disabled={isSendResults}

                                        >บันทึกผลตรวจสอบ</Button>
                                    </Popconfirm>
                                </Col>
                                :
                                <Col span={24} style={{ textAlign: "center" }}>
                                    <center><label style={{ color: "red" }}>{isCloseInspect.message}</label></center>
                                </Col>
                            }
                        </center>
                    </Col>
                    : []}
            </Row>
        )
    }

    return (
        <>
            {!loading ?
                <>
                    {userType === "admin-inspect-open-course" ? genAdminInspectOpenCourse(true) : []}
                    {userType === "admin-inspect-edit-course" ? genAdminInspectEditCourse() : []}

                    <Modal
                        title={modal.reqtEditTitle ? modal.reqtEditTitle : ""}
                        visible={modal.isOpen}
                        onCancel={() => {
                            setModal({
                                isOpen: false,
                                url: null,
                                title: null,
                            });
                        }}
                        width={userType === "admin-inspect-edit-course" ? 1200 : 450}
                        footer={false}
                    >
                        <Row>
                            {userType === "admin-inspect-edit-course" ?
                                <>
                                    <Col
                                        span={12}
                                        style={{
                                            height: 30,
                                            borderRight: "1px solid #DEDEDE",
                                            borderBottom: "1px solid #DEDEDE"
                                        }}
                                    ><center>เเบบเก่า</center></Col>
                                    <Col
                                        span={12}
                                        style={{
                                            height: 30,
                                            borderBottom: "1px solid #DEDEDE"
                                        }}
                                    ><center>เเบบที่ขอเเก้ไข</center></Col>

                                    <Col
                                        span={12}
                                        style={{
                                            paddingTop: 10,
                                            borderRight: "1px solid #DEDEDE",
                                        }}
                                    >
                                        <Row>
                                            <Col span={24} style={{ paddingBottom: 6, borderBottom: "1px solid #EEEEEE" }}>
                                                <label style={{}}>ภาพปกวิดีโอ</label><br />
                                                {modal.originalImgURL ?
                                                    <Image
                                                        style={{ width: '100%' }}
                                                        alt="avatar"
                                                        src={modal.originalImgURL}
                                                        preview={{
                                                            src: modal.originalImgURL,
                                                        }}
                                                    />
                                                    :
                                                    <Image
                                                        style={{ width: '100%' }}
                                                        src="error"
                                                        fallback={`${imgDefaultUrl}`}
                                                    />
                                                }
                                            </Col>

                                            <Col span={24} style={{ paddingBottom: 6, paddingTop: 6, borderBottom: "1px solid #EEEEEE" }}>
                                                <label style={{}}>วิดีโอ</label>
                                                <div style={{ paddingRight: 20 }}>
                                                    {modal.originalVideoURL ?
                                                        <Video
                                                            courseId={null}
                                                            learnerId={null}
                                
                                                            videoTitle=""
                                                            videoLocation={PathVideo(modal.originalVideoURL)}
                                                            component={"upload-video"}
                                                            handleTimeVideo={handleTimeVideo}
                                                            handlePercentWatchVideoCourse={handlePercentWatchVideoCourse}
                                                            handlePlayer={handlePlayer}
                                                            handleControls={handleControls}
                                                        />
                                                        :
                                                        <Image
                                                            style={{ width: '100%' }}
                                                            src="error"
                                                            fallback={`${videoDefaultUrl}`}
                                                        />
                                                    }
                                                </div>
                                            </Col>

                                            <Col span={24} style={{ paddingBottom: 6, paddingTop: 6, borderBottom: "1px solid #EEEEEE" }}>
                                                <label>รายละเอียดวิดีโอ</label>
                                                <p style={{ marginBottom: 0 }}>{modal.originalDescription}</p>
                                            </Col>

                                            <Col span={24} style={{ paddingTop: 6 }}>
                                                <label>ราคาวิดีโอ {Intl.NumberFormat('en', { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(Number(modal.originalVideoPrice))} บาท</label>
                                            </Col>
                                        </Row>
                                    </Col>
                                </>
                                : []
                            }

                            <Col
                                span={userType === "admin-inspect-edit-course" ? 12 : 24}
                                style={{
                                    paddingTop: userType === "admin-inspect-edit-course" ? 10 : 0,
                                    paddingLeft: userType === "admin-inspect-edit-course" ? 20 : 0
                                }}
                            >
                                <Row>
                                    <Col span={24} style={{ paddingBottom: 6, borderBottom: "1px solid #EEEEEE" }}>
                                        <label style={{}}>ภาพปกวิดีโอ</label><br />
                                        {modal.reqtEditImgURL ?
                                            <Image
                                                style={{ width: '100%' }}
                                                alt="avatar"
                                                src={`${modal.reqtEditImgURL}`}
                                                preview={{
                                                    src: `${modal.reqtEditImgURL}`,
                                                }}
                                            />
                                            :
                                            <Image
                                                style={{ width: '100%' }}
                                                src="error"
                                                fallback={`${imgDefaultUrl}`}
                                            />
                                        }
                                    </Col>

                                    <Col span={24} style={{ paddingBottom: 6, paddingTop: 6, borderBottom: "1px solid #EEEEEE" }}>
                                        <label style={{ paddingLeft: userType === "admin-inspect-edit-course" ? 10 : 0 }}>วิดีโอ</label>
                                        {modal.reqtEditVideoURL ?
                                            <Video
                                                courseId={null}
                                                learnerId={null}
                                                
                                                videoTitle=""
                                                videoLocation={PathVideo(modal.reqtEditVideoURL)}
                                                component={"upload-video"}
                                                handleTimeVideo={handleTimeVideo}
                                                handlePercentWatchVideoCourse={handlePercentWatchVideoCourse}
                                                handlePlayer={handlePlayer}
                                                handleControls={handleControls}
                                            />
                                            :
                                            <Image
                                                style={{ width: '100%' }}
                                                src="error"
                                                fallback={`${videoDefaultUrl}`}
                                            />
                                        }
                                    </Col>

                                    <Col span={24} style={{ paddingBottom: 6, paddingTop: 6, borderBottom: "1px solid #EEEEEE" }}>
                                        <label>รายละเอียดวิดีโอ</label>
                                        <p style={{ marginBottom: 0 }}>{modal.reqtEditDescription ? modal.reqtEditDescription : ""}</p>
                                    </Col>

                                    <Col span={24} style={{ paddingTop: 6 }}>
                                        <label>ราคาวิดีโอ {Intl.NumberFormat('en', { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(Number(modal.reqtEditVideoPrice))} บาท</label>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Modal>
                </>
                :
                <LoadingPage loading={loading} isTitleActive={true} />
            }
        </>
    )
}
