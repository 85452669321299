/* eslint-disable react-hooks/exhaustive-deps */

import { 
    Col, 
    Row, 
    Table,
    Modal
} from 'antd'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { BsSearch } from "react-icons/bs"
import { formatCodeUnit } from '../../../../common/mamager/IdManager'
import { getPaymentOrderByUserIdFetch } from '../../API'

const dateTime = 'DD/MM/YYYY HH:mm'

export default function Transaction(props) {

    const [modal, setModal] = useState({
        isOpen: false,
        data: null
    });

    const columns = [
        {
            title: 'No',
            dataIndex: 'index',
            width: "2%",
        },
        {
            title: 'เลขที่ชำระเงิน',
            dataIndex: 'paymentOderId',
            width: "4%",
            render: (text, record) => {
                return (
                    <div>{formatCodeUnit(record.paymentOderId)}</div>
                )
            }
        },
        {
            title: 'รายการ',
            dataIndex: 'orderName',
            width: "10%",
        },
        // {
        //     title: 'ประเภทชำระเงิน',
        //     dataIndex: 'paymentTypeName',
        //     width: "7%",
        // },
        {
            title: 'ชำระผ่านทาง',
            dataIndex: 'paymentMethodTypeName',
            width: "7%",
        },
        {
            title: 'ราคาสุทธิ',
            dataIndex: 'netPrice',
            width: "5%",
            render: (text, record) => {
                return (
                    <>
                        <label>{Intl.NumberFormat('en').format(Number(record.netPrice))} บาท</label>
                        {record.paymentCourseVideo?.length > 0 ? 
                            <BsSearch
                                style={{ float: "right", cursor: "pointer", marginTop: 4 }}
                                onClick={() => {
                                    setModal({
                                        isOpen: true,
                                        data: record
                                    });
                                }}
                            />
                        : []}
                    </>
                )
            }
        },
        {
            title: 'คูปอง',
            dataIndex: 'couponCode',
            width: "7%",
        },
        {
            title: 'จำนวนวิดีโอ',
            dataIndex: 'courseVideoAmount',
            
            width: "5%",
            render: (text, record) => {
                return (
                    <>
                        {record.courseVideoAmount ? <label>{record.courseVideoAmount} วิดีโอ</label> : "-"}
                    </>
                )
            }
        },
        {
            title: 'การต่อสัญญา',
            dataIndex: 'expireStatusName',
            width: "7%",
            render: (text, record) => {
                return (
                    <div>{record.expireStatusId ? record.expireStatusName : "-"}</div>
                )
            }
        },
        {
            title: 'สถานะ',
            // dataIndex: 'paymentStatusName',
            width: "8%",
            render: (text, record) => {
                // console.log(record.paymentStatusId);
                if (record.paymentStatusId === 1) {
                    return <div style={{ color: "green" }}>{record.paymentStatusName}</div>
                } else if (record.paymentStatusId === 2) {
                    return <div style={{ color: "orange" }}>{record.paymentStatusName}</div>
                } else if (record.paymentStatusId === 3) {
                    return <div style={{ color: "red" }}>{record.paymentStatusName}</div>
                } else if (record.paymentStatusId === 4) {
                    return <div>{record.paymentStatusName}</div>
                }
            }
        },
        {
            title: 'สาเหตุที่ไม่ผ่าน',
            dataIndex: 'reasonNotPass',
            width: "6%",
            render: (text, record) => {
                return text ? <div>{text}</div> : <div>-</div>
            }
        },
        {
            title: 'วันที่ชำระ',
            dataIndex: 'dateTimeTransfer',
            width: "4%",
            render: (text, record) => {
                return (
                    <div>{moment(record.dateTimeTransfer).format(dateTime)}</div>
                )
            }
        },
    ];

    const columnsPaymentOrderVideo = [
        {
            title: 'No',
            dataIndex: 'index',
            width: "2%",
        },
        {
            title: 'ชื่อวิดีโอ',
            // dataIndex: 'paymentOderId',
            width: "7%",
            render: (text, record) => {
                return (
                    <label>{record.course_video.courseVideoTitleName}</label>
                )
            }
        },
        {
            title: 'ราคา ณ วันที่ซื้อ',
            // dataIndex: 'paymentOderId',
            width: "7%",
            render: (text, record) => {
                return (
                    <label>{Intl.NumberFormat("en", { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(Number(record.videoPaidPrice))} บาท</label>
                )
            }
        },
    ]

    const language = "th"

    const [order, setOrder] = useState([])
    const [loading, setLoading] = useState(false) // true

    const [pagePaymentOrderVideoCurrent, setPagePaymentOrderVideoCurrent] = useState(1)
    const [pageOrderCurrent, setPageOrderCurrent] = useState(1)

    const onOrderPagine = (n) => {
        setPageOrderCurrent(n.current)
    }
    
    const onPaymentOrderVideoPagine = (n) => {
        setPagePaymentOrderVideoCurrent(n.current)
    }

    const getPaymentOrderByUserId = async () => {
        setLoading(true)

        const result = await getPaymentOrderByUserIdFetch(language, props.user.uid)
        // console.log("getPaymentOrderByUserIdFetch : ", result)
        setOrder(result)

        setLoading(false)
    }

    useEffect(() => {
        getPaymentOrderByUserId()
    }, [])

    return (
        <>
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <label>ธุรกรรมการชำระเงิน</label>
                </Col>
                <Col span={24}>
                    <Table
                        scroll={{ x: 1500 }}
                        loading={loading}
                        columns={columns}
                        dataSource={order}
                        pagination={{
                            current: pageOrderCurrent,
                            pageSize: 10,
                            total: order.length
                        }}
                        onChange={(n) => onOrderPagine(n)}
                    >
                    </Table>
                </Col>
            </Row>

            <Modal
                title={"รายละเอียดราคาคอร์ส เเยกตามวิดีโอ"}
                visible={modal.isOpen}
                onCancel={() => {
                    setModal({
                        isOpen: false,
                        data: null
                    })
                }}
                width={600}
                footer={false}
            >
                <Table
                    columns={columnsPaymentOrderVideo}
                    dataSource={modal.data?.paymentCourseVideo}
                    pagination={{
                        current: pagePaymentOrderVideoCurrent,
                        pageSize: 10,
                        total: order.length
                    }}
                    onChange={(n) => onPaymentOrderVideoPagine(n)}
                ></Table>
            </Modal>
        </>
    )
}
