import moment from 'moment'
import { insertCourseCertificateFetch } from '../../../../common/components/certificate/API'
import { GenerateCode } from '../../../../common/components/certificate/components/GenerateCode'
import { calculateEndDateLearnCourse } from '../../../../common/mamager/DateMamager'
import { insertLearnerCourseFetch } from '../../API'

const newDate = moment()

export const CourseCreateLearner = async (
    teacherInfo,
    learnerInfo,
    navigate,
    netPrice
) => {
    if (teacherInfo) {
        let endLearnDate = calculateEndDateLearnCourse(teacherInfo.courseFormatId, newDate)

        // create learner -------------------------------------------------------------------------------------------------------------
        let objLearnerCourse = {
            courseId: teacherInfo.courseId,
            teacherId: teacherInfo.uid,                                 // teacherId
            learnerId: learnerInfo.uid,                                 // learnerId
            isActive: 1,
            courseMonthId: null,                                        // รายเดือน
            courseFormatId: teacherInfo.courseFormatId,
            is25PercentWatchVideoCourse: false,
            is50PercentWatchVideoCourse: false,
            is80PercentWatchVideoCourse: false,
            startLearnDate: newDate,
            endLearnDate,
            expireStatusId: null
        }
        // console.log("objLearnerCourse : ", objLearnerCourse)
        const resultInsertLearnerCourse = await insertLearnerCourseFetch(objLearnerCourse)
        // console.log("insertLearnerCourseFetch : ", resultInsertLearnerCourse)

        // create certificate ---------------------------------------------------------------------------------------------------------
        let priceOnDatePurchase = Number(learnerInfo.amount)
        let certificateCode = await GenerateCode(priceOnDatePurchase)
        let objCertificate = {
            courseId: teacherInfo.courseId,
            uid: learnerInfo.uid,
            themeCertificateId: 1,
            courseCertificateCode: certificateCode,
            courseCertificateQRCode: null,
            certificateApprovalCategoryId: 2, // study
            priceOnDatePurchase,
            isActive: 1
        }
        // console.log("objCertificate : ", objCertificate);
        const resultInspectCourseCertificate = await insertCourseCertificateFetch(objCertificate)
        // console.log("insertCourseCertificateFetch : ", resultInspectCourseCertificate)

        if (resultInsertLearnerCourse.isSuccess && resultInspectCourseCertificate.isSuccess) {
            navigate(
                "/result",
                {
                    state: {
                        authorized: null,// props.authorized,

                        type: "success",
                        title: netPrice === 0 ? "เก็บคอร์สฟรีสำเร็จ" : "ชำระเงินสำเร็จ",
                        subTitle: "คอร์สที่ท่านชำระเงินจะถูกส่งไปอยู่ที่ 'คอร์สของฉัน'",
                        btnWidth: 170,

                        btnName1: "ไปหน้าคอร์สของฉัน",
                        btnPathName1: "classroom-course",
                        btnIsActive1: true
                    }
                }
            )
        }
    }
}