/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */

import { useEffect, useState } from 'react'
import {
    Col,
    Divider,
    Image,
    Radio,
    Row,
    Input,
    Button,
    Popconfirm,
    Table,
    Modal,
    Spin,
} from 'antd'
import Column from 'antd/lib/table/Column'
import { BsSearch } from "react-icons/bs"
import { Notification } from '../../../../../common/components/notification'
import { imgDefaltCardCourse, storageUrl } from '../../../../../constants'
import { updateInspectRequestEditCourseDocumentByIdFetch, updateInspectRequestOpenCourseDocumentByIdFetch } from '../../../API'
import LoadingPage from '../../../../../common/components/loading/page'

const { TextArea } = Input

export default function InspectDocument(props) {

    const [listOriginal, setListOriginal] = useState([])
    const [listReqtEdit, setListReqtEdit] = useState([])

    const [loading, setLoading] = useState(true)

    const [courseId, setCourseId] = useState(null)
    const [uid, setUid] = useState(null)
    const [isCloseInspect, setIsCloseInspect] = useState({
        status: null,
        message: null,
    })
    const [userType, setUserType] = useState(null)

    const [pageCurrent, setPageCurrent] = useState(1)

    const [modal, setModal] = useState({
        isOpen: false,
        url: null,
        title: null,
    })

    const [isSendResults, setIsSendResults] = useState(true)

    const handleInspectCourseDocumentApprovalUpdate = (event, record, row) => {
        let reasonNotApploval = record.reasonNotApploval
        let inspectCourseAdjustId = null
        if (event === 1) { // detect reason
            reasonNotApploval = null
        }

        if (event === 2) { // detect adjust
            inspectCourseAdjustId = 2
        }

        if (userType === "admin-inspect-open-course") {
            setListOriginal(listOriginal.map(val => val.index !== row.index ? val : {
                index: val.index,
                name: val.name,
                type: val.type,
                inspect: {
                    inspectId: record.inspectId,
                    inspectCourseApprovalId: event,
                    inspectCourseAdjustId,
                    reasonNotApploval,
                },
                coureDocumentId: val.coureDocumentId,
            }))
        } else if (userType === "admin-inspect-edit-course") {
            setListReqtEdit(listReqtEdit.map(val => val.index !== row.index ? val : {
                index: val.index,
                name: val.name,
                type: val.type,
                request: val.request,
                inspect: {
                    inspectId: record.inspectId,
                    inspectCourseApprovalId: event,
                    inspectCourseAdjustId,
                    reasonNotApploval,
                },
                coureDocumentId: val.coureDocumentId,
            }))
        }
    }

    const handleReasonCourseDocumentNotApplovalUpdate = (event, record, row) => {
        if (userType === "admin-inspect-open-course") {
            setListOriginal(listOriginal.map(val => val.index !== row.index ? val : {
                index: val.index,
                name: val.name,
                type: val.type,
                inspect: {
                    inspectId: record.inspectId,
                    inspectCourseApprovalId: record.inspectCourseApprovalId,
                    inspectCourseAdjustId: record.inspectCourseAdjustId,
                    reasonNotApploval: event,
                },
                coureDocumentId: val.coureDocumentId,
            }))
        } else if (userType === "admin-inspect-edit-course") {
            setListReqtEdit(listReqtEdit.map(val => val.index !== row.index ? val : {
                index: val.index,
                name: val.name,
                type: val.type,
                request: val.request,
                inspect: {
                    inspectId: record.inspectId,
                    inspectCourseApprovalId: record.inspectCourseApprovalId,
                    inspectCourseAdjustId: record.inspectCourseAdjustId,
                    reasonNotApploval: event,
                },
                coureDocumentId: val.coureDocumentId,
            }))
        }

    }

    const onPagine = (n) => {
        setPageCurrent(n.current);
    }

    const detectDocumentInspect = async () => {
        let detectNeatness = true; // if false is select not pass but reason is empty
        if (userType === "admin-inspect-open-course") {
            listOriginal.map(val => {
                if (val.inspect.inspectCourseApprovalId) {
                    if (val.inspect.inspectCourseApprovalId === 2 &&
                        (
                            val.inspect.reasonNotApploval === null ||
                            val.inspect.reasonNotApploval === "" ||
                            val.inspect.reasonNotApploval === undefined)
                    ) {
                        detectNeatness = false;
                    }
                } else {
                    detectNeatness = false;
                }
            })
        } else if (userType === "admin-inspect-edit-course") {
            listReqtEdit.map(val => {
                if (val.inspect.inspectCourseApprovalId) {
                    if (val.inspect.inspectCourseApprovalId === 2 &&
                        (
                            val.inspect.reasonNotApploval === null ||
                            val.inspect.reasonNotApploval === "" ||
                            val.inspect.reasonNotApploval === undefined)
                    ) {
                        detectNeatness = false;
                    }
                } else {
                    detectNeatness = false;
                }
            })
        }

        if (detectNeatness) { // complete information
            let result = { isSuccess: true };
            if (userType === "admin-inspect-open-course") {
                // send API
                let obj = {
                    inspectList: listOriginal,
                    isInspect: 1,
                    courseId,
                    uid,
                };
                // console.log(obj)
                result = await updateInspectRequestOpenCourseDocumentByIdFetch(obj)
            } else if (userType === "admin-inspect-edit-course") {
                // send API
                let obj = {
                    inspectList: listReqtEdit,
                    isInspect: 1,
                    courseId,
                    uid,
                }
                // console.log("course document : ", obj)
                result = await updateInspectRequestEditCourseDocumentByIdFetch(obj)
            }

            // notify send api
            if (result.isSuccess) {
                props.reloadInspectCourse()
                Notification("success", "ระบบ", "บันทึกผลตรวจสอบสำเร็จ")
            } else {
                Notification("error", "ระบบ", "บันทึกผลตรวจสอบไม่สำเร็จ กรุณาลองใหม่อีกครั้ง")
            }
        } else {
            // notify error
            Notification("error", "ระบบ", "ไม่สามารถบันทึกผลตรวจสอบได้ กรุณากรอกข้อมูลให้ครับ")
        }
    }

    const genColumn = (courseDocument) => {
        let tempCourseDocument = []
        courseDocument.map((val, index) => {
            // console.log(val)
            let inspect = props.inspect.courseDocument.formData.filter(val2 => val2.id === val.coureDocumentationId)[0]
            if (inspect) {
                tempCourseDocument.push({
                    index: (index + 1),
                    name:
                        <>
                            {val.coureDocumentationName}
                            <BsSearch
                                style={{ float: "right", cursor: "pointer" }}
                                onClick={() => {
                                    if (val.fileCategoryId === 1) {
                                        // console.log("val.coureDocumentationLocation : ", val.coureDocumentationLocation)
                                        window.open(`${val.coureDocumentationLocation}`)
                                    } else if (val.fileCategoryId === 2) {
                                        setModal({
                                            isOpen: true,
                                            url: `${val.coureDocumentationLocation}`,
                                            title: val.coureDocumentationName,
                                        });
                                    }
                                }}
                            />
                        </>,
                    type: val.fileCategoryName,
                    request: val.approvalStatusName,
                    inspect: {
                        inspectId: inspect.id,
                        inspectCourseApprovalId: inspect.inspectionCourseApprovalId,
                        inspectCourseAdjustId: inspect.inspectionCourseAdjustId,
                        reasonNotApploval: inspect.reasonCourseNotApproval,
                    },
                    coureDocumentId: val.coureDocumentationId,
                })
            }
        })
        return tempCourseDocument
    }

    useEffect(() => {
        setLoading(true)

        setUserType(props.userType)
        setCourseId(props.courseId)
        setUid(props.uid)

        if ( // open button send result to teacher
            props.inspect.inspectionCourseCardId !== 2 &&               // ต้องไม่รอตรวจสอบ
            props.inspect.inspectionCourseDescriptionId !== 2 &&        // ต้องไม่รอตรวจสอบ
            props.inspect.inspectionCoursePriceId !== 2 &&              // ต้องไม่รอตรวจสอบ

            props.inspect.inspectionCourseMediaIntroId !== 2 &&         // ต้องไม่รอตรวจสอบ
            props.inspect.inspectionCourseDocumentId !== 2 &&           // ต้องไม่รอตรวจสอบ
            props.inspect.inspectionCourseVideoId !== 2 &&              // ต้องไม่รอตรวจสอบ

            props.inspect.inspectionCourseId === 3                      // สถานะโดยรวม คือ รอตรวจสอบ [2]
        ) {
            setIsSendResults(true)
        } else {
            setIsSendResults(false)
        }

        setListOriginal(genColumn(props.courseDocumentOriginal))

        // console.log("props.userType : ", props.userType)
        // console.log("props.courseDocumentReqtEdit : ", props.courseDocumentReqtEdit)
        setTimeout(function () {
            if (props.userType === "admin-inspect-open-course") {
                setIsCloseInspect(props.isCloseInspect)
            } else if (props.userType === "admin-inspect-edit-course") {
                setListReqtEdit(genColumn(props.courseDocumentReqtEdit))
            }
            setLoading(false)
        }, 500)
    }, [])

    const genAdminInspectOpenCourse = (isSaveInspect) => {
        return (
            <Row>
                <Col span={24}>
                    <Table
                        loading={loading}
                        dataSource={listOriginal}
                        pagination={{
                            current: pageCurrent,
                            pageSize: 15,
                            total: listOriginal.length
                        }}
                        onChange={(n) => onPagine(n)}
                    >
                        <Column title="No" dataIndex="index" width="5%" align="center" />
                        <Column title="ชื่อเอกสาร" dataIndex="name" width="25%" />
                        <Column title="ประเภท" dataIndex="type" width="10%" align="center" />

                        {isSaveInspect ?
                            <Column title="สถานะตรวจสอบ" dataIndex="inspect" width="25%" align="center"
                                render={(record, row) => {
                                    return (
                                        <Row>
                                            <Col span={24} style={{ textAlign: "center", paddingBottom: 6 }}>
                                                <Radio.Group
                                                    disabled={isSendResults}
                                                    onChange={(e) => handleInspectCourseDocumentApprovalUpdate(e.target.value, record, row)}
                                                    value={record.inspectCourseApprovalId}
                                                >
                                                    <Radio value={1}>ผ่าน</Radio>
                                                    <Radio value={2}>ไม่ผ่าน</Radio>
                                                </Radio.Group>
                                            </Col>
                                            {record.inspectCourseApprovalId === 2 ?
                                                <Col span={24}>
                                                    <TextArea
                                                        disabled={isSendResults}
                                                        onBlur={(e) => handleReasonCourseDocumentNotApplovalUpdate(e.target.defaultValue, record, row)}
                                                        defaultValue={record.reasonNotApploval}
                                                        placeholder="เหตุผลที่ไม่ผ่าน"
                                                        autoSize={{ minRows: 2, maxRows: 2 }}
                                                    />
                                                </Col>
                                                : []}
                                        </Row>
                                    )
                                }}
                            />
                            : []}
                    </Table>
                </Col>

                {isSaveInspect ?
                    <>
                        <Divider style={{ marginBottom: 10, marginTop: 10 }} />
                        {!isCloseInspect.status ?
                            <>
                                <Col span={24} style={{ textAlign: "center" }}>
                                    <Popconfirm
                                        disabled={isSendResults}
                                        title="คุณยืนยันบันทึกผลตรวจสอบหรือไม่ ?"
                                        okText={<span style={{ width: 50 }}>ใช่</span>}
                                        onConfirm={() => {
                                            detectDocumentInspect();
                                        }}
                                        cancelText={<span style={{ width: 50 }}>ไม่ใช่</span>}
                                    >
                                        <Button
                                            type="primary"
                                            style={{ width: 150 }}
                                            disabled={isSendResults}

                                        >บันทึกผลตรวจสอบ</Button>
                                    </Popconfirm>
                                </Col>
                            </>
                            :
                            <Col span={24} style={{ textAlign: "center" }}>
                                <center><label style={{ color: "red" }}>{isCloseInspect.message}</label></center>
                            </Col>
                        }
                    </>
                    : []}
            </Row>
        )
    }

    const genAdminInspectEditCourse = () => {
        return (
            <Row>
                <Col
                    span={24}
                >
                    <Table
                        loading={loading}
                        dataSource={listReqtEdit}
                        pagination={{
                            current: pageCurrent,
                            pageSize: 15,
                            total: listReqtEdit.length
                        }}
                        onChange={(n) => onPagine(n)}
                    >
                        <Column title="No" dataIndex="index" width="5%" align="center" />
                        <Column title="ชื่อเอกสาร" dataIndex="name" width="25%" />
                        <Column title="ประเภท" dataIndex="type" width="10%" align="center" />
                        <Column title="คำขอ" dataIndex="request" width="10%" align="center" />
                        <Column title="สถานะตรวจสอบ" dataIndex="inspect" width="15%" align="center"
                            render={(record, row) => {
                                return (
                                    <Row>
                                        <Col span={24} style={{ textAlign: "center", paddingBottom: 6 }}>
                                            <Radio.Group
                                                disabled={isSendResults}
                                                onChange={(e) => handleInspectCourseDocumentApprovalUpdate(e.target.value, record, row)}
                                                value={record.inspectCourseApprovalId}
                                            >
                                                <Radio value={1}>ผ่าน</Radio>
                                                <Radio value={2}>ไม่ผ่าน</Radio>
                                            </Radio.Group>
                                        </Col>
                                        {record.inspectCourseApprovalId === 2 ?
                                            <Col span={24}>
                                                <TextArea
                                                    disabled={isSendResults}
                                                    onBlur={(e) => handleReasonCourseDocumentNotApplovalUpdate(e.target.defaultValue, record, row)}
                                                    defaultValue={record.reasonNotApploval}
                                                    placeholder="เหตุผลที่ไม่ผ่าน"
                                                    autoSize={{ minRows: 2, maxRows: 2 }}
                                                />
                                            </Col>
                                            : []}
                                    </Row>
                                )
                            }}
                        />
                    </Table>
                </Col>

                <Divider style={{ marginBottom: 10, marginTop: 10 }} />
                {props.inspect.inspectionCourseDocumentId ?
                    <Col span={24}>
                        <center>
                            {!isCloseInspect.status ?
                                <>
                                    <Col span={24} style={{ textAlign: "center" }}>
                                        <Popconfirm
                                            disabled={isSendResults}
                                            title="คุณยืนยันบันทึกผลตรวจสอบหรือไม่ ?"
                                            okText={<span style={{ width: 50 }}>ใช่</span>}
                                            onConfirm={() => {
                                                detectDocumentInspect();
                                            }}
                                            cancelText={<span style={{ width: 50 }}>ไม่ใช่</span>}
                                        >
                                            <Button
                                                type="primary"
                                                style={{ width: 150 }}
                                                disabled={isSendResults}

                                            >บันทึกผลตรวจสอบ</Button>
                                        </Popconfirm>
                                    </Col>
                                </>
                                :
                                <Col span={24} style={{ textAlign: "center" }}>
                                    <center><label style={{ color: "red" }}>{isCloseInspect.message}</label></center>
                                </Col>
                            }
                        </center>
                    </Col>
                    : []}
            </Row>
        )
    }

    return (
        <>
            {!loading ?
                <>
                    {userType === "admin-inspect-open-course" ? genAdminInspectOpenCourse(true) : []}
                    {userType === "admin-inspect-edit-course" ? genAdminInspectEditCourse() : []}

                    <Modal
                        title={modal.title}
                        visible={modal.isOpen}
                        onCancel={() => {
                            setModal({
                                isOpen: false,
                                url: null,
                                title: null,
                            });
                        }}
                        width={600}
                        footer={false}
                    >
                        {modal.url ?
                            <Image
                                style={{ width: '100%' }}
                                alt="avatar"
                                src={modal.url}
                                preview={{
                                    src: modal.url,
                                }}
                            />
                            :
                            <Image
                                style={{ maxHeight: 300 }}
                                src="error"
                                fallback={imgDefaltCardCourse}
                            />
                        }
                    </Modal>
                </>
                :
                <LoadingPage loading={loading} isTitleActive={true} />
            }
        </>
    )
}
