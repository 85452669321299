import { 
    HTTP_REGISTER_BUSINESS_DETAILS_FETCHING, 
    HTTP_REGISTER_BUSINESS_DETAILS_SUCCESS, 
    HTTP_REGISTER_BUSINESS_DETAILS_FAILED, 
} from "../constants"

export const setRegisterBusinessDetailsFetching = () => ({
    type: HTTP_REGISTER_BUSINESS_DETAILS_FETCHING,
})

export const setRegisterBusinessDetailsSuccess = (payload) => ({
    type: HTTP_REGISTER_BUSINESS_DETAILS_SUCCESS,
    payload
})

export const setRegisterBusinessDetailsFailed = () => ({
    type: HTTP_REGISTER_BUSINESS_DETAILS_FAILED,
})
