/* eslint-disable array-callback-return */

import { useState, useEffect } from 'react'
import thaiIdCard from 'thai-id-card'
import { FaCog } from "react-icons/fa"
import { FiEdit } from "react-icons/fi"
import { RiDeleteBin6Line } from "react-icons/ri"
import { TiLockOpen, TiLockClosed } from "react-icons/ti"
import {
    Table,
    Row,
    Col,
    Button,
    Form,
    Modal,
    Input,
    Select,
    Popconfirm
} from 'antd'
import moment from 'moment'
import { deleteAdminByIdFetch, getAdminAllFetch, getAdminTypeAllFetch, getDepartmentAllFetch, insertAdminByIdFetch, pauseAdminByIdFetch, updateAdminByIdFetch } from '../../API'

const { Option } = Select
const formatDate = "DD/MM/YYYY"

const columns = [
    {
        title: 'No',
        dataIndex: 'index',
        width: "5%",
    },
    {
        title: 'Username',
        dataIndex: 'adminListUsername',
        width: "12%",
    },
    {
        title: 'Password',
        dataIndex: 'adminListPassword',
        width: "12%",
    },
    {
        title: 'เเผนก',
        dataIndex: 'departmentName',
        width: "15%",
    },
    {
        title: 'ประเภท Admin',
        dataIndex: 'adminType',
        width: "12%",
    },
    {
        title: 'เลขบัตรประชาชน',
        dataIndex: 'adminListIdCard',
        width: "10%",
    },
    {
        title: 'วันที่สร้าง',
        dataIndex: 'createdAt',
        width: "10%",
    },
    {
        title: <FaCog />,
        dataIndex: 'operator',
        align: "center",
    },
]

export default function PermissionManage(props) {
    const language = "th"

    const [searchUser, setSearchUser] = useState('')
    const [list, setList] = useState([])
    const [loading, setLoading] = useState(true)

    const [department, setDepartment] = useState([])
    const [adminType, setAdminType] = useState([])

    const [form] = Form.useForm()

    const [pageCurrent, setPageCurrent] = useState(1)

    const [modal, setModal] = useState({
        isShow: false,
        title: null,
    })

    const onPagine = (n) => {
        setPageCurrent(n.current)
    }

    const getAdminAll = async () => {
        setLoading(true)

        const result = await getAdminAllFetch(language)

        let tempList = []
        result.map((val, index) => {
            let adminListIdCard = "*********" + val.adminListIdCard.substring(val.adminListIdCard.length - 4, val.adminListIdCard.length)
            let departmentName = language === "th" ? val.department.departmentNameTH : val.department.departmentNameENG
            let adminType = language === "th" ? val.admin_type.adminTypeNameTH : val.admin_type.adminTypeNameENG

            tempList.push({
                index: (index + 1),
                adminListUsername: val.adminListUsername,
                adminListPassword: val.adminListPassword,
                adminListIdCard,
                departmentName,
                adminType,
                createdAt: moment(val.createdAt).format(formatDate),
                operator:
                    <>
                        <Button
                            icon={<FiEdit style={{ marginTop: -3, color: "white" }} />}
                            style={{
                                width: 35,
                                backgroundColor: "orange",
                                border: "1px solid orange",
                                color: 'white',
                                borderRadius: 50
                            }}
                            onClick={() => {

                                form.setFieldsValue({
                                    adminListId: val.adminListId,
                                    adminListUsername: val.adminListUsername,
                                    adminListPassword: val.adminListPassword,
                                    adminListIdCard,
                                    departmentName: val.department.departmentId,
                                    adminType: val.admin_type.adminTypeId,
                                })

                                // api
                                getDepartment()
                                getAdminType()

                                setModal({
                                    isShow: true,
                                    title: "edit",
                                })
                            }}
                        ></Button>{" "}

                        <Popconfirm
                            title="คุณยืนยันลบหรือไม่ ?"
                            okText={<span style={{ width: 50 }}>ใช่</span>}
                            onConfirm={async () => {
                                await deleteAdminByIdFetch(val.adminListId)

                                // reload
                                await getAdminAll()
                            }}
                            cancelText={<span style={{ width: 50 }}>ไม่ใช่</span>}
                        >
                            <Button
                                icon={<RiDeleteBin6Line style={{ marginTop: -3, color: "white" }} />}
                                danger
                                type="primary"
                                style={{
                                    width: 35,
                                    borderRadius: 50
                                }}
                            ></Button>{" "}
                        </Popconfirm>

                        <Popconfirm
                            title="คุณยืนยันปิดการใช้งานหรือไม่ ?"
                            okText={<span style={{ width: 50 }}>ใช่</span>}
                            onConfirm={async () => {

                                let resuest = {
                                    isActive: !val.isActive,
                                    adminListId: val.adminListId
                                }
                                // console.log("resuest : ", val.isActive, resuest)

                                await pauseAdminByIdFetch(resuest)

                                // reload
                                await getAdminAll()
                            }}
                            cancelText={<span style={{ width: 50 }}>ไม่ใช่</span>}
                        >
                            <Button
                                icon={val.isActive ?
                                    <TiLockOpen style={{ marginTop: -5, color: "white", height: 21, width: 21 }} /> :
                                    <TiLockClosed style={{ marginTop: -5, color: "white", height: 21, width: 21 }} />
                                }
                                style={{
                                    width: 35,
                                    backgroundColor: val.isActive ? "gray" : "green",
                                    border: val.isActive ? "1px solid green" : "1px solid gray",
                                    color: 'white',
                                    borderRadius: 50
                                }}
                            ></Button>
                        </Popconfirm>
                    </>
            })
        })
        setList(tempList)

        setLoading(false)
    }

    const onFinish = async (values) => {
        let request = {
            adminListId: values.adminListId,
            adminListUsername: values.adminListUsername,
            adminListPassword: values.adminListPassword,
            adminListIdCard: values.adminListIdCard,
            adminMasterCreateId: props.adminMasterCreateId,
            adminTypeId: values.adminType,
            departmentId: values.departmentName,
        }

        if (modal.title === "add") {
            await insertAdminByIdFetch(request)
        } else if (modal.title === "edit") {
            await updateAdminByIdFetch(request)
        }

        // default
        setFormDefault()

        // reload
        await getAdminAll()
    }

    const getDepartment = async () => {
        const result = await getDepartmentAllFetch(language)
        setDepartment(result)
    }

    const getAdminType = async () => {
        const result = await getAdminTypeAllFetch(language)
        setAdminType(result)
    }

    const setFormDefault = () => {
        form.setFieldsValue({
            adminListId: undefined,
            adminListUsername: undefined,
            adminListPassword: undefined,
            adminListIdCard: undefined,
            departmentName: undefined,
            adminType: undefined,
        })

        setModal({
            isShow: false,
            title: null,
        })
    }

    const detectIdCard = (rule, value) => {
        const result = thaiIdCard.verify(value)
        if (result) {
            return Promise.resolve()
        } else {
            return Promise.reject('เลขบัตรประชาชนมี Format ไม่ถูกต้อง')
        }
    }

    useEffect(() => {

        getAdminAll()
    }, [])

    useEffect(() => {

    }, [list])

    return (
        <Row>
            <Col span={12}>
                <label>จัดการรายชื่อ Admin</label>
            </Col>
            <Col span={12} style={{ paddingBottom: 20 }}>
                <Button
                    type="primary"
                    style={{ float: 'right' }}
                    onClick={() => {
                        setModal({
                            isShow: true,
                            title: "add"
                        })
                    }}
                >เพิ่ม Admin</Button>
                <label style={{ float: 'right', width: 20 }} ></label>
                <Input
                    placeholder="ค้นหา Username"
                    value={searchUser}
                    onChange={e => {
                        const currValue = e.target.value
                        setSearchUser(currValue)
                        if (currValue === "") {
                            getAdminAll()
                        } else {
                            const filteredData = list.filter(entry => entry.adminListUsername.includes(currValue))
                            setList(filteredData)
                        }
                    }}
                    style={{ float: 'right', width: 250 }}
                />
            </Col>
            <Col span={24}>
                <Table
                    loading={loading}
                    columns={columns}
                    dataSource={list}
                    pagination={{
                        current: pageCurrent,
                        pageSize: 5,
                        total: list.length
                    }}
                    onChange={(n) => onPagine(n)}
                >
                </Table>
            </Col>

            <Modal
                title={<strong><label className="topic-color-bold">{modal.title === "add" ? "เพิ่ม Admin" : "เเก้ไข Admin"}</label></strong>}
                visible={modal.isShow}
                onCancel={() => {

                    // default
                    setFormDefault()
                }}
                width={400}
                onOk={() => {

                    form.submit()
                }}
                okText={<label style={{ width: 50, cursor: 'pointer' }}>บันทึก</label>}
                cancelText={<label style={{ width: 50, cursor: 'pointer' }}>ยกเลิก</label>}
            >
                <Form
                    layout="vertical"
                    form={form}
                    onFinish={onFinish}
                >
                    <Form.Item
                        name="adminListId"
                        label=""
                        style={{ display: "none" }}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        name="adminListUsername"
                        label="Username"
                        rules={[{ required: true, message: 'กรุณากรอก Username' }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        name="adminListPassword"
                        label="Password"
                        rules={[{ required: true, message: 'กรุณากรอก Password' }]}
                    >
                        <Input.Password />
                    </Form.Item>

                    <Form.Item
                        name="departmentName"
                        label="Department"
                        rules={[{ required: true, message: 'กรุณากรอก Department' }]}
                    >
                        <Select
                            showSearch
                            placeholder="เลือก Department"
                            optionFilterProp="children"
                            allowClear
                            onClick={() => getDepartment()}
                        >
                            {department.map((val, index) => <Option key={index} value={val.departmentId}>{val.departmentName}</Option>)}
                        </Select>
                    </Form.Item>

                    <Form.Item
                        name="adminType"
                        label="Admin Type"
                        rules={[{ required: true, message: 'กรุณากรอก Admin Type' }]}
                    >
                        <Select
                            showSearch
                            placeholder="เลือก Admin Type"
                            optionFilterProp="children"
                            allowClear
                            onClick={() => getAdminType()}
                        >
                            {adminType.map((val, index) => <Option key={index} value={val.adminTypeId}>{val.adminTypeName}</Option>)}
                        </Select>
                    </Form.Item>

                    {modal.title === "add" ?
                        <Form.Item
                            name="adminListIdCard"
                            label="Id card"
                            // rules={[{ required: true, message: 'กรุณากรอก Id card' }]}
                            rules={[{ required: true, validator: detectIdCard }]}
                        >
                            <Input />
                        </Form.Item>
                        : []}
                </Form>
            </Modal>
        </Row>
    )
}
