/* eslint-disable react-hooks/exhaustive-deps */
import {
    Col,
    Form,
    Row,
    DatePicker,
    Input,
    Button,
    Table,
    Spin,
    Popconfirm,
    Image,
} from 'antd'
import { useEffect, useState } from 'react'
import moment from 'moment'
import { Notification } from '../../../../common/components/notification'
import { 
    getUserManageAllFetch,
    getCourseOwnerByIdFetch,
    deleteUserManageByIdFetch,
    lineNotifyUserManageResult,
} from './API'

const { RangePicker } = DatePicker

const formatTime = "HH:mm"
const formatDateTime = "YYYY-MM-DD HH:mm"

export default function UserManage(props) {
    const columnsฺ = [
        {
            title: 'No',
            dataIndex: 'index',
            width: "2%",
        },
        {
            title: 'ภาพโปรไฟล์',
            dataIndex: 'imageProfile',
            align: "center",
            width: "7%",
            render: (text, record) => {
                return (
                    <>
                        {record.imageProfile ?
                            <Image
                                style={{ 
                                    height: "60px", 
                                    width: "60px", 
                                    borderRadius: "5%" 
                                }}
                                src={record.imageProfile}
                            />
                            :
                            <Image
                                style={{ 
                                    height: "60px", 
                                    width: "60px", 
                                    borderRadius: "5%" 
                                }}
                                src={`./assets/images/profile/personDefault.jpg`}
                            />
                        }
                    </>
                )
            }
        },
        {
            title: 'ชื่อผู้ใช้งาน',
            dataIndex: 'userName',
            width: "6%",
        },
        {
            title: 'provider',
            dataIndex: 'providerId',
            width: "9%",
        },
        {
            title: 'UID',
            dataIndex: 'uid',
            width: "9%",
        },
        {
            title: 'ประเภทบัญชี',
            dataIndex: 'businessOperation',
            width: "8%",
        },
        {
            title: 'วันที่สร้าง',
            dataIndex: 'createdAt',
            align: "center",
            width: "3%",
            render: (text, record) => {
                return (
                    <div>
                        {moment(record.createdAt).format('DD/MM/YYYY HH:mm:ss')}
                    </div>
                )
            }
        },
        {
            title: 'จัดการ',
            fixed: 'right',
            align: "center",
            width: "8%",
            render: (text, record) => {
                return (
                    <Popconfirm
                        title="ยืนยันที่จะลบผู้ใช้งาน ใช่หรือไม่ ?"
                        okText={<span style={{ width: 50 }}>ใช่</span>}
                        onConfirm={ async () => {
                            try {
                                const result = await getCourseOwnerByIdFetch({uid: record.uid})
                                if (result?.length > 0) {
                                    Notification("error", "ระบบ", "ไม่สามารถลบผู้ใช้ได้ เนื่องจากมีคอร์สเรียนที่เป็นเจ้าของอยู่ ต้องลบคอร์สเหล่านั้นก่อน!")
                                } else {
                                    Notification("success", "ระบบ", "สามารถลบผู้ใช้ได้")
                                    await handleDeleteUser(record)
                                }
                            } catch (err) {
                                // ...
                            }
                        }}
                        cancelText={<span style={{ width: 50 }}>ไม่ใช่</span>}
                    >
                        <Button danger type="primary">ลบผู้ใช้งาน</Button>
                    </Popconfirm>
                )
            }
        },
        // {
        //     title: 'ีรหัสผู้ใช้งาน',
        //     dataIndex: 'userId',
        //     width: "5%",
        // },
        // {
        //     title: 'เบอร์โทร',
        //     dataIndex: 'phone',
        //     width: "6%",
        // },
        // {
        //     title: 'อีเมล',
        //     dataIndex: 'email',
        //     width: "6%",
        // },
    ]
    
    let language = "th"

    const [form] = Form.useForm()

    const [list, setList] = useState([])
    const [loading, setLoading] = useState(false)

    const [pageCurrent, setPageCurrent] = useState(1)

    const onPagine = (n) => {
        setPageCurrent(n.current)
    }

    const onFinish = async (value) => {
        setLoading(true)

        let userName = value.userName ?? null
        let startedDate = value.dateRange ? moment(value.dateRange[0]).format(formatDateTime) : null
        let endDate = value.dateRange ? moment(value.dateRange[1]).format(formatDateTime) : null

        let request = {
            userName, 
            startedDate, 
            endDate, 
            // paymentStatusCategory
        }

        await getUserManageAll(request)
        setLoading(false)
    }

    const getUserManageAll = async (value) => {
        try {
        const result = await getUserManageAllFetch(value, language)
        // console.log("getUserManageAllFetch : ", result)
        setList(result)
        } catch (err) {
            // ...
        }
    }

    const genLineNotifyUserManage = async (userName, uid) => {
        // line
        let objLineNotify = {
            title: 'จัดการผู้ใช้งาน \n',
            status: 'ลบผู้ใช้งานสำเร็จ',
            userName,
            // userId,
            uid,
            actionDateTime: moment().format('DD/MM/YYYY HH:mm')
        }
        // console.log("objLineNotify : ", objLineNotify)
        await lineNotifyUserManageResult(objLineNotify)
    }

    const handleDeleteUser = async (data) => {
        setLoading(true)

        const result = await deleteUserManageByIdFetch(data.uid)
        // console.log("deleteUserManageByIdFetch : ", result)

        if (result.isSuccess) {
            Notification("success", "ระบบ", "ลบผู้ใช้งานสำเร็จ")

            // Line
            await genLineNotifyUserManage(
                data.userName,
                // data.userId,
                data.uid,
            )

            // reload data
            getBaseApi()

        } else {
            Notification("error", "ระบบ", "ลบผู้ใช้งานไม่สำเร็จ")

            await genLineNotifyUserManage(
                data.userName,
                // data.userId,
                data.uid,
            )
        }

        setLoading(false)
    }

    const getBaseApi = async () => {
        setLoading(true)

        try {
            await getUserManageAll({
                userName: null,
                startedDate: null,
                endDate: null,
            })
        } catch (err) {
            // ...
        }

        setLoading(false)
    }

    useEffect(() => {
        getBaseApi()
    }, [])

    return (
        <Row>
            <Col span={5}>
                <label>จัดการคำสั่งชำระเงิน</label>
            </Col>

            <Col span={19} style={{ paddingBottom: 20 }}>
                <Form layout="vertical" form={form} onFinish={onFinish}>
                    <Row>
                        <Col xs={12} lg={7}>
                            <Form.Item name="dateRange" >
                                <RangePicker
                                    style={{ width: '90%' }}
                                    showTime={{ format: formatTime }}
                                    format={formatDateTime}
                                />
                            </Form.Item>
                        </Col>

                        <Col xs={12} lg={15}>
                            <Form.Item name="userName" >
                                <Input
                                    style={{ width: '100%' }}
                                    placeholder="ชื่อผู้ใช้งาน"
                                />
                            </Form.Item>
                        </Col>

                        <Col xs={12} lg={2}>
                            <Button type="primary" onClick={() => form.submit()}>
                                ค้นหา
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Col>

            <Col span={24}>
                {!loading ?
                    <Table
                        loading={loading}
                        columns={columnsฺ}
                        dataSource={list}
                        pagination={{
                            current: pageCurrent,
                            pageSize: 10,
                            total: list.length
                        }}
                        onChange={(n) => onPagine(n)}
                    >
                    </Table>
                    :
                    <center><Spin size="large" style={{ paddingTop: 200 }} /></center>
                }
            </Col>
        </Row>
    )
}
