import { server } from "../../../../constants"
import { httpClient } from "../../../../utils/HttpClient"

const getComplaintAllFetch = async (type, value) => { // Done
    try {
        const result = await httpClient.post(server.GET_COMPLAINT_ALL_URL, { type, value })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getUserTypeFetch = async (language) => { // language -> th, eng
    try {
        const result = await httpClient.get(server.GET_USER_TYPE_URL + `/${language}`)
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getComplaintActionStatusFetch = async (language) => { // language -> th, eng
    try {
        const result = await httpClient.get(server.GET_COMPLAINT_ACTION_STATUS_URL + `/${language}`)
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const insertComplaintFetch = async (value) => { // Done
    try {
        const result = await httpClient.post(server.INSERT_COMPLAINT_URL, { value })
        if (result.data.isSuccess) {
            return result.data
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const updateComplaintByIdFetch = async (value) => { // Done
    try {
        const result = await httpClient.put(server.UPDATE_COMPLAINT_BY_ID_URL, { value })
        return result.data
    } catch (err) { // status 404
        return null
    }
}

export {
    // get
    getComplaintAllFetch,
    getUserTypeFetch,
    getComplaintActionStatusFetch,

    // update
    updateComplaintByIdFetch,

    // insert
    insertComplaintFetch,

    // delete
}