/* eslint-disable react-hooks/exhaustive-deps */

import { useRef, useState, useEffect } from 'react'
import {
    Row,
    Col,
    Form,
    Button,
    Checkbox,
    InputNumber,
    Divider,
    Popconfirm,
    Spin,
    Popover,
    Select,
    TimePicker,
} from 'antd'
import locale from "antd/es/date-picker/locale/de_DE"
import "../../css/managePrice.css"
import { useDispatch, useSelector } from "react-redux"
import moment from 'moment'
import { Icon } from '@iconify/react'
import { AiOutlineQuestionCircle } from "react-icons/ai"
import { setCourseManageAddCurrentStepStateToSuccess } from '../../../../actions/courseManageAddCurrentStep.action'
import { setCourseManagePriceStateToSuccess } from '../../../../actions/courseManagePrice.action'
import { Notification } from '../../../../common/components/notification'
import { useDimensions } from '../../../../common/mamager/ScreenManager'
import { getCourseManageFetch, updateCourseFetch, updateCourseIsUpdateFetch, updateRequestOpenCoursePriceAdjustFetch } from '../../API'
import { secondsToMdhms } from '../../../../common/mamager/TimeManager'
import { genDay, genMonth, genYear } from '../../../../common/mamager/DateMamager'

const dateTimeFormat = 'YYYY-MM-DD HH:mm:ss'
const timeFormat = 'HH:mm'
const { Option } = Select

export default function ManagePrice(props) {
    const dispatch = useDispatch()

    const minimumPrice = 1000

    const { width } = useDimensions()

    const [day, setDay] = useState([])
    const [month, setMonth] = useState([])
    const [year, setYear] = useState([])

    const [dayDiscount, setDayDiscount] = useState(undefined)
    const [monthDiscount, setMonthDiscount] = useState(undefined)
    const [yearDiscount, setYearDiscount] = useState(undefined)
    const [timeDiscount, setTimeDiscount] = useState(undefined)

    const [course, setCourse] = useState(null)
    const [user, setUser] = useState({ uid: null })
    const [isStepActive, setIsStepActive] = useState(null)
    const [courseInspect, setcourseInspect] = useState(null)
    const [switchType, setSwitchType] = useState(null)
    const [isFormDisable, setisFormDisable] = useState(false)

    const [loading, setLoading] = useState(true)

    const [form] = Form.useForm()

    const { resultCourseManageAddCurrentStep } = useSelector(({ courseManageAddCurrentStepReducer }) => courseManageAddCurrentStepReducer)
    const { resultPrice } = useSelector(({ courseManagePriceReducer }) => courseManagePriceReducer)
    const { resultCard } = useSelector(({ courseManageCardReducer }) => courseManageCardReducer)

    const submitTypeRef = useRef(null)

    const [coursePrice, setCoursePrice] = useState(null)
    const [coursePriceDiscount, setCoursePriceDiscount] = useState(null)
    const [checkedDiscount, setCheckedDiscount] = useState(null)
    const [isPaymentByVideo, setIsPaymentByVideo] = useState(null)
    const nextStepRef = useRef(false)

    const [price, setPrice] = useState({})

    const onDiscountChange = () => {
        // set default
        form.setFieldsValue({
            priceDiscount: undefined,
            time: undefined,
            day: undefined,
            month: undefined,
            year: undefined
        })
        setCoursePriceDiscount(0)

        setCheckedDiscount(!checkedDiscount)
    }

    const onInputChangeCoursePriceDiscount = (coursePrice, coursePriceDiscount) => {

        const priceLeft = coursePrice - coursePriceDiscount
        const percent = (coursePriceDiscount * 100) / coursePrice

        setPrice({
            priceLeft, percent
        })
    }

    const setReducer = (price, priceDiscount, dateEndDiscount) => {
        let tempDateTimeEndDiscount = dateEndDiscount === '' ? undefined : dateEndDiscount
        // reducer
        dispatch(setCourseManagePriceStateToSuccess({
            price,
            priceDiscount,
            dateTimeEndDiscount: tempDateTimeEndDiscount,
            isDiscount: checkedDiscount,
        }))
    }

    const detectDateEndDiscount = (dateEndDiscount) => {
        if (checkedDiscount) {
            if (moment(dateEndDiscount).diff(moment()) >= 0) {
                return true
            } else {
                return false
            }
        } else {
            return true
        }
    }

    const onFinish = async (values) => {
        let tempCoursePrice = values.price ? values.price : null
        let tempCoursePriceDiscount = values.priceDiscount ? values.priceDiscount : null
        let tempDateEndDiscount = (values.day && values.month && values.year && values.time) ? moment(
            values.month + "/" + values.day + "/" + (Number(values.year) - 543) + " " + moment(values.time).format(timeFormat)).format(dateTimeFormat)
            :
            null

        if (detectDateEndDiscount(tempDateEndDiscount)) { // detect date discount
            // calculate price discount
            calculateDiscount(tempCoursePrice, tempCoursePriceDiscount)

            // state
            setCoursePrice(tempCoursePrice)
            setCoursePriceDiscount(tempCoursePriceDiscount)

            // reducer data
            setReducer(tempCoursePrice, tempCoursePriceDiscount, tempDateEndDiscount)

            // reducer step
            if (nextStepRef.current) {
                // set session
                localStorage.setItem("stepsPriceClickable", 1)

                dispatch(setCourseManageAddCurrentStepStateToSuccess(Number(resultCourseManageAddCurrentStep) + 1))
                nextStepRef.current = false
            }

            if (submitTypeRef.current === 'update') {
                // get data latest
                const resultCourseLatest = await getCourseManageFetch(user.uid, course.courseId)

                let obj = {
                    // new
                    coursePrice: values.price ? values.price : 0,
                    courseDiscount: checkedDiscount,
                    coursePriceDiscount: values.priceDiscount ? values.priceDiscount : 0,
                    discountDateEnd: tempDateEndDiscount,

                    // old
                    courseId: resultCourseLatest.courseId,
                    courseDescription: resultCourseLatest.courseDescription,
                    approvalStatusId: resultCourseLatest.approvalStatusId,
                    ownerCheckCourseId: resultCourseLatest.ownerCheckCourseId,
                    courseCategoryId: resultCourseLatest.courseCategoryId,
                    courseCertificateCategoryId: resultCourseLatest.courseCertificateCategoryId,
                    courseFormatId: resultCourseLatest.courseFormatId,
                    courseSubDescriptionName: resultCourseLatest.courseSubDescriptionName,
                    courseTitleName: resultCourseLatest.courseTitleName,
                    courseImageLocation: resultCourseLatest.courseImageLocation,
                    courseView: resultCourseLatest.courseView,
                    created_at: new Date(resultCourseLatest.created_at),
                    updated_at: new Date(resultCourseLatest.updated_at),
                }

                if (switchType === "adjust-course") { // course adjust
                    const resultUpdate = await updateRequestOpenCoursePriceAdjustFetch(
                        user.uid,               // uid
                        obj,                    // course
                        1,                      // inspectionCourseAdjustCategoryId
                        1,                      // isActive
                        course.courseId,        // courseId
                    )
                    if (resultUpdate.data.isSuccess) {
                        Notification("success", "ระบบ", "บันทึกการปรับเเก้สำเร็จ")
                    } else {
                        Notification("error", "ระบบ", "บันทึกการปรับเเก้ไม่สำเร็จ")
                    }
                } else { // course edit
                    // =======================================================================
                    // future add check diff between resultCard whit courseLatest
                    // =======================================================================

                    const resultUpdateCourse = await updateCourseFetch(
                        user.uid,
                        obj,
                        1,
                        course.courseId,
                        "coursePrice",          // courseComponent
                    )

                    // update isUpdateCoursePrice true
                    const resultIsUpdate = await updateCourseIsUpdateFetch(
                        user.uid,               // uid
                        1,                      // isActive
                        course.courseId,        // courseId
                        'coursePrice',          // use update field isUpdateCoursePrice
                    )

                    if (resultUpdateCourse.data.isSuccess && resultIsUpdate.data.isSuccess) {
                        Notification("success", "ระบบ", "บันทึกการปรับเเต่งสำเร็จ")
                    } else {
                        Notification("error", "ระบบ", "บันทึกการปรับเเต่งไม่สำเร็จ")
                    }
                }

                if (!isStepActive) {
                    // reload
                    props.handleCourseReload()
                }
            }
        } else {
            Notification("warning", "เเจ้งเตือน", "วันเเละเวลาที่ลดราคาถึงวันที่นั้น ๆ ต้องมากกว่าวันเเละเวลาปัจจุบัน")
        }
    };

    const calculateDiscount = (coursePrice, coursePriceDiscount) => {
        const priceLeft = coursePrice - coursePriceDiscount;
        const percent = (coursePriceDiscount * 100) / coursePrice;

        setPrice({
            priceLeft, percent
        })
    }

    const detectPriceCourse = (rules, value) => {
        const reg = /^-?\d*(\.\d*)?$/;
        if (reg.test(value)) {
            if (Number(value) >= 300 || Number(value) === 0) {
                setCoursePrice(value)

                // calculate price discount
                calculateDiscount(value, coursePriceDiscount);

                let tempMonthlyPayment = isStepActive ?
                    (resultCard ? resultCard.monthlyPayment : null)                                         // add
                    :
                    course.monthlyPayment                                                                   // edit, adjust

                if (tempMonthlyPayment) {
                    if (Number(value) >= minimumPrice) {
                        return Promise.resolve()
                    } else {
                        return Promise.reject(new Error('ราคาต้องมากกว่า 1,000 บาท เมื่อใช้โหมดเช่ารายเดือน'))
                    }
                } else {
                    return Promise.resolve()
                }
            } else {
                return Promise.reject(new Error('ราคาต้อง 300 บาทขึ้นไป หรือเท่ากับ 0'))
            }
        } else {
            return Promise.reject(new Error('กรุณากรอกราคา'))
        }
    }

    const detectPriceDiscountCourse = (rules, value) => {
        let price = form.getFieldValue("price")
        let priceDiscount = form.getFieldValue("priceDiscount")

        if (checkedDiscount) {

            // calculate price discount
            calculateDiscount(coursePrice, value)

            if (value) {
                if (!isNaN(+value)) {
                    setCoursePriceDiscount(value)

                    if (Number(priceDiscount) < Number(price)) {
                        if (Number(price) - Number(priceDiscount) >= 20) {
                            return Promise.resolve()
                        } else {
                            return Promise.reject(new Error('ลดราคาต้องไม่ต่ำกว่า 20 บาท'))
                        }
                    } else {
                        return Promise.reject(new Error('ราคาที่กรอกต้องน้อยกว่าราคาปกติ'))
                    }
                } else {
                    form.setFieldsValue({ priceDiscount })
                }
            } else {
                return Promise.reject(new Error('กรุณากรอกราคาที่ลด'))
            }
        }
    }

    const calculateAmountDateEndDiscount = () => {
        let today = moment(new Date(), dateTimeFormat)
        let endDate = moment(monthDiscount + "/" + dayDiscount + "/" + (Number(yearDiscount) - 543) + " " + timeDiscount).format(dateTimeFormat)
        let amountDay = moment(endDate, dateTimeFormat).diff(today, "second")

        if (amountDay && amountDay > 0) {
            return (secondsToMdhms(amountDay))
        } else {
            return null
        }
    }

    const onFormChange = () => {
        let formValue = form.getFieldValue();
        let tempDateEndDiscount = (formValue.day && formValue.month && formValue.year && formValue.time) ? moment(
            formValue.month + "/" + formValue.day + "/" + (Number(formValue.year) - 543) + " " + moment(formValue.time).format(timeFormat)).format(dateTimeFormat)
            :
            null

        setReducer(
            formValue.price,
            formValue.priceDiscount,
            tempDateEndDiscount
        )
    }

    useEffect(() => {
        setLoading(true)

        setTimeout(function () {

            setDay(genDay())
            setMonth(genMonth())
            setYear(genYear("go-ahead"))

            setUser(props.user)
            setIsStepActive(props.isStepActive)
            setSwitchType(props.switchType)

            if (props.isStepActive) {

                setIsPaymentByVideo(resultCard?.isPaymentByVideo ? resultCard?.isPaymentByVideo : null)

                if (resultPrice) {
                    let coursePrice = Number(resultPrice?.price ? resultPrice.price : 0)
                    let coursePriceDiscount = Number(resultPrice?.priceDiscount ? resultPrice.priceDiscount : 0)

                    setCoursePrice(coursePrice)
                    setCoursePriceDiscount(coursePriceDiscount)
                    onInputChangeCoursePriceDiscount(coursePrice, coursePriceDiscount)

                    setCheckedDiscount(resultPrice?.isDiscount ? resultPrice.isDiscount : false)

                    if (resultPrice?.dateTimeEndDiscount) {
                        let tmpDate = resultPrice.dateTimeEndDiscount?.split(" ")[0]
                        let tmpTime = resultPrice.dateTimeEndDiscount?.split(" ")[1]

                        let tmpDay = undefined
                        let tmpMonth = undefined
                        let tmpYear = undefined

                        if (tmpDate) {
                            tmpDay = tmpDate.split("-")[2]
                            tmpMonth = tmpDate.split("-")[1]
                            tmpYear = tmpDate.split("-")[0]
                        }

                        setDayDiscount(Number(tmpDay))
                        setMonthDiscount(Number(tmpMonth))
                        setYearDiscount(Number(tmpYear) + 543)
                        setTimeDiscount(tmpTime ? tmpTime : undefined)

                        form.setFieldsValue({
                            day: Number(tmpDay),
                            month: Number(tmpMonth),
                            year: Number(tmpYear) + 543,
                            time: (tmpTime ? moment(tmpTime, timeFormat) : undefined)
                        });
                    }

                    form.setFieldsValue({
                        price: Number(resultPrice.price ? resultPrice.price : 0),
                        priceDiscount: Number(resultPrice.priceDiscount ? resultPrice.priceDiscount : undefined)
                    })
                }
            } else {
                setCourse(props.course)
                setcourseInspect(props.courseInspect)

                // adjust
                if (switchType === "adjust-course" && (props.courseInspect ? props.courseInspect.inspectionCourseId !== 3 : false)) {
                    setisFormDisable(true)
                }

                setIsPaymentByVideo(props.course?.isPaymentByVideo ? props.course?.isPaymentByVideo : null)

                if (props.course) {
                    let coursePrice = Number(props.course.coursePrice ? props.course.coursePrice : 0);
                    let coursePriceDiscount = Number(props.course.coursePriceDiscount ? props.course.coursePriceDiscount : 0);

                    setCoursePrice(coursePrice)
                    setCoursePriceDiscount(coursePriceDiscount)
                    onInputChangeCoursePriceDiscount(coursePrice, coursePriceDiscount)

                    setCheckedDiscount(props.course?.courseDiscount ? props.course.courseDiscount : null)

                    let discountDateEnd = props?.course?.discountDateEnd ? moment(props?.course?.discountDateEnd).format(dateTimeFormat) : null
                    if (discountDateEnd) {
                        let tmpDate = discountDateEnd?.split(" ")[0]
                        let tmpTime = discountDateEnd?.split(" ")[1]

                        let tmpDay = undefined
                        let tmpMonth = undefined
                        let tmpYear = undefined

                        if (tmpDate) {
                            tmpDay = tmpDate.split("-")[2]
                            tmpMonth = tmpDate.split("-")[1]
                            tmpYear = tmpDate.split("-")[0]
                        }

                        setDayDiscount(Number(tmpDay))
                        setMonthDiscount(Number(tmpMonth))
                        setYearDiscount(Number(tmpYear) + 543)
                        setTimeDiscount(tmpTime ? tmpTime : undefined)

                        form.setFieldsValue({
                            day: Number(tmpDay),
                            month: Number(tmpMonth),
                            year: (Number(tmpYear) + 543),
                            time: (tmpTime ? moment(tmpTime, timeFormat) : undefined)
                        })
                    }

                    form.setFieldsValue({
                        price: Number(props.course.coursePrice ? props.course.coursePrice : 0),
                        priceDiscount: Number(props.course.coursePriceDiscount ? props.course.coursePriceDiscount : undefined)
                    })
                }
            }

            setLoading(false)
        }, 500)
    }, [])

    useEffect(() => {

    }, [
        coursePrice,
        coursePriceDiscount,
        dayDiscount,
        monthDiscount,
        yearDiscount,
        timeDiscount,
        price.priceLeft,
        price.percent
    ])

    useEffect(() => {

    }, [checkedDiscount])

    const genDateTimeDiscount = () => { // dateEndDiscount
        return (
            <Row gutter={[12, 0]}>
                <Col span={24}>
                    <label style={{ color: "red", fontSize: 16 }}>*</label>
                    <label style={{ paddingLeft: 5, }}>ลดถึงวันที่</label>
                </Col>

                <Col xs={24} sm={12} md={8} lg={6}>
                    <Form form={form} onFinish={onFinish} layout="vertical">
                        <Form.Item
                            name="day"
                            label="วัน"
                            style={{ width: '100%' }}
                            rules={[{ required: true, message: 'กรุณาเลือกวัน' }]}
                        >
                            <Select
                                showSearch
                                style={{ width: '100%' }}
                                onChange={(e) => {
                                    setDayDiscount(e)
                                    onFormChange()
                                }}
                                disabled={
                                    (props.courseInspect ?
                                        ((props.courseInspect.course.inspectionCoursePriceApprovalId === 1 || props.courseInspect.inspectionCourseId === 2) && props.courseInspect.course.inspectionCoursePriceApprovalId !== null ? true
                                            :
                                            isFormDisable ? isFormDisable : (Number(coursePrice) > 0 && Number(coursePriceDiscount) > 0 ? false : true) // adjust true is disable
                                        )
                                        :
                                        false
                                    )
                                }
                                optionFilterProp="children"
                                allowClear
                            >
                                {day.map((val, index) =>
                                    <Option key={index} value={val.value}>{val.label}</Option>
                                )}
                            </Select>
                        </Form.Item>
                    </Form>
                </Col>
                <Col xs={24} sm={12} md={8} lg={6}>
                    <Form form={form} onFinish={onFinish} layout="vertical">
                        <Form.Item
                            name="month"
                            label="เดือน"
                            style={{ width: '100%' }}
                            rules={[{ required: true, message: 'กรุณาเลือกเดือน' }]}
                        >
                            <Select
                                showSearch
                                style={{ width: '100%' }}
                                onChange={(e) => {
                                    setMonthDiscount(e);
                                    onFormChange();
                                }}
                                disabled={
                                    (props.courseInspect ?
                                        ((props.courseInspect.course.inspectionCoursePriceApprovalId === 1 || props.courseInspect.inspectionCourseId === 2) && props.courseInspect.course.inspectionCoursePriceApprovalId !== null ? true
                                            :
                                            isFormDisable ? isFormDisable : (Number(coursePrice) > 0 && Number(coursePriceDiscount) > 0 ? false : true) // adjust true is disable
                                        )
                                        :
                                        false
                                    )
                                }
                                optionFilterProp="children"
                                allowClear
                            >
                                {month.map((val, index) =>
                                    <Option key={index} value={val.value}>{val.label}</Option>
                                )}
                            </Select>
                        </Form.Item>
                    </Form>
                </Col>
                <Col xs={24} sm={12} md={8} lg={6}>
                    <Form form={form} onFinish={onFinish} layout="vertical">
                        <Form.Item
                            name="year"
                            label="ปี"
                            style={{ width: '100%' }}
                            rules={[{ required: true, message: 'กรุณาเลือกปี' }]}
                        >
                            <Select
                                showSearch
                                style={{ width: '100%' }}
                                onChange={(e) => {
                                    setYearDiscount(e);
                                    onFormChange();
                                }}
                                disabled={
                                    (props.courseInspect ?
                                        ((props.courseInspect.course.inspectionCoursePriceApprovalId === 1 || props.courseInspect.inspectionCourseId === 2) && props.courseInspect.course.inspectionCoursePriceApprovalId !== null ? true
                                            :
                                            isFormDisable ? isFormDisable : (Number(coursePrice) > 0 && Number(coursePriceDiscount) > 0 ? false : true) // adjust true is disable
                                        )
                                        :
                                        false
                                    )
                                }
                                optionFilterProp="children"
                                allowClear
                            >
                                {year.map((val, index) =>
                                    <Option key={index} value={val.value}>{val.label}</Option>
                                )}
                            </Select>
                        </Form.Item>
                    </Form>
                </Col>
                <Col xs={24} sm={12} md={8} lg={6} style={width > 992 ? { paddingRight: 14 } : {}}>
                    <Form form={form} onFinish={onFinish} layout="vertical">
                        <Form.Item
                            name="time"
                            label="เวลา"
                            disabled={
                                (props.courseInspect ?
                                    ((props.courseInspect.course.inspectionCoursePriceApprovalId === 1 || props.courseInspect.inspectionCourseId === 2) && props.courseInspect.course.inspectionCoursePriceApprovalId !== null ? true
                                        :
                                        isFormDisable ? isFormDisable : (Number(coursePrice) > 0 && Number(coursePriceDiscount) > 0 ? false : true) // adjust true is disable
                                    )
                                    :
                                    false
                                )
                            }
                            style={{ width: '100%' }}
                            rules={[{ required: true, message: 'กรุณาเลือกเวลา' }]}
                        >
                            <TimePicker
                                format={timeFormat}
                                placeholder={"00:00"}
                                locale={{
                                    ...locale,
                                    lang: {
                                        ...locale.lang,
                                        now: "ตอนนี้",
                                        ok: <div style={{ fontSize: 11, marginTop: -1 }}>ตกลง</div>,
                                    }
                                }}
                                onChange={(e) => {
                                    setTimeDiscount(moment(e).format(timeFormat))
                                    onFormChange()
                                }}
                            />
                        </Form.Item>
                    </Form>
                </Col>

                <Col span={24}>
                    {calculateAmountDateEndDiscount() ?
                        <label style={(width > 576 && width < 992) ? { paddingBottom: 10 } : { marginTop: 35 }}>จำนวน {calculateAmountDateEndDiscount()}</label>
                        :
                        <label style={(width > 576 && width < 992) ? { paddingBottom: 10 } : { marginTop: 35 }}>จำนวน 0 วัน</label>
                    }
                </Col>
            </Row>
        )
    }

    const genCoursePrice = () => {
        return (
            <>
                <Row style={{ textAlign: 'left' }}>
                    <Col xs={24} lg={12}>
                        <Form form={form} onFinish={onFinish} layout="vertical">
                            <Row>
                                <Col span={16}>
                                    <Form.Item
                                        label="ราคาปกติ"
                                        name="price"
                                        rules={[{ required: true, validator: detectPriceCourse }]}
                                    >
                                        <InputNumber
                                            parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                            precision={2}
                                            min={0}
                                            max={100000}
                                            onBlur={() => onFormChange()}
                                            step={0.1}
                                            style={{ width: '95%' }}
                                            disabled={
                                                switchType === "adjust-course" ?
                                                    // adjust true is disable
                                                    (props.courseInspect ?
                                                        ((props.courseInspect.course.inspectionCoursePriceApprovalId === 1 || props.courseInspect.inspectionCourseId === 2) && props.courseInspect.course.inspectionCoursePriceApprovalId !== null ? true : false) : false)
                                                    :
                                                    (switchType === "edit-course" ?
                                                        (courseInspect ?
                                                            (courseInspect.inspectionCourseId !== 2 && courseInspect.inspectionCourseApprovalId !== 2 ?
                                                                false
                                                                :
                                                                ((course.approvalStatusId === 1 || course.approvalStatusId === 2) && courseInspect.inspectionCourseId === 3 ?
                                                                    false
                                                                    :
                                                                    (!props.inspectCourseApprovalId && !courseInspect.course.inspectionCoursePriceAdjustId && courseInspect.inspectionCourseId === 2 ?
                                                                        false
                                                                        :
                                                                        true
                                                                    )
                                                                )
                                                            )
                                                            :
                                                            false
                                                        )
                                                        :
                                                        false
                                                    )
                                            }
                                        />
                                    </Form.Item>
                                </Col>

                                <Col span={8}>
                                    <Form.Item
                                        name="price"
                                        style={{ marginTop: 30 }}
                                    >
                                        <Checkbox
                                            checked={checkedDiscount}
                                            onChange={onDiscountChange}
                                            disabled={
                                                isPaymentByVideo ? true :
                                                    (switchType === "adjust-course" ?
                                                        // adjust true is disable
                                                        (props.courseInspect ?
                                                            ((props.courseInspect.course.inspectionCoursePriceApprovalId === 1 || props.courseInspect.inspectionCourseId === 2) && props.courseInspect.course.inspectionCoursePriceApprovalId !== null ? true : false) : false)
                                                        :
                                                        (switchType === "edit-course" ?
                                                            (courseInspect ?
                                                                (courseInspect.inspectionCourseId !== 2 && courseInspect.inspectionCourseApprovalId !== 2 ?
                                                                    false
                                                                    :
                                                                    ((course.approvalStatusId === 1 || course.approvalStatusId === 2) && courseInspect.inspectionCourseId === 3 ?
                                                                        false
                                                                        :
                                                                        (!props.inspectCourseApprovalId && !courseInspect.course.inspectionCoursePriceAdjustId && courseInspect.inspectionCourseId === 2 ?
                                                                            false
                                                                            :
                                                                            true
                                                                        )
                                                                    )
                                                                )
                                                                :
                                                                false
                                                            )
                                                            :
                                                            false
                                                        )
                                                    )
                                            }
                                        >ลดราคา</Checkbox>
                                    </Form.Item>
                                </Col>

                                {checkedDiscount ?
                                    <>
                                        <Col span={24}>
                                            <label style={{ color: "red", paddingRight: 4 }}>*</label>
                                            <label>ลดราคา</label>
                                        </Col>

                                        <Col span={4}>
                                            <label style={{ paddingRight: 5, float: "right", marginTop: 5 }}>{Intl.NumberFormat('en').format(Number(coursePrice))} - </label>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item
                                                name="priceDiscount"
                                                rules={[{ required: true, validator: detectPriceDiscountCourse }]}
                                            >
                                                <InputNumber
                                                    disabled={
                                                        (props.courseInspect ?
                                                            ((props.courseInspect.course.inspectionCoursePriceApprovalId === 1 || props.courseInspect.inspectionCourseId === 2) && props.courseInspect.course.inspectionCoursePriceApprovalId !== null ? true
                                                                :
                                                                isFormDisable ? isFormDisable : (Number(coursePrice) > 0 ? false : true) // adjust true is disable
                                                            )
                                                            :
                                                            false
                                                        )
                                                    }
                                                    precision={2}
                                                    min={0}
                                                    max={100000}
                                                    step={0.1}
                                                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                                    style={{ width: '94%' }}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={8}>
                                            <label style={{ marginTop: 5 }}> เหลือ {Intl.NumberFormat("en", { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(Number(price.priceLeft ? price.priceLeft : 0))} บาท</label>
                                            <br />
                                            <label style={{ color: "red" }}>ลดลง {parseFloat(price.percent ? price.percent : 0).toFixed(2)} %</label>
                                        </Col>

                                        <Col xs={24} lg={24}>
                                            {genDateTimeDiscount()}
                                        </Col>
                                    </>
                                    : []}
                            </Row>
                        </Form>
                    </Col>
                </Row>

                <Divider style={{ marginTop: 1, marginBottom: 15 }} />
                <div style={{ fontSize: 14 }}>
                    <label>หมายเหตุ !</label><br />
                    <ul>
                        <li>หากใส่ราคาคอร์สเป็น 0 (ศูนย์) คอร์สเรียนนี้จะเป็น ฟรี</li>
                        <li>ราคาคอร์ส ต้องไม่ต่ำกว่า 300 บาท</li>
                        <li>ลดราคาต้องไม่ต่ำกว่า 20 บาท</li>
                        <li>หากมีการลดราคา ระบบจะเเสดงราคาที่ลดคงเหลือ เเละราคาจากปกติ</li>
                        <li>เมื่อ "ราคาคอร์ส" ผ่านการตรวจสอบเเล้ว จะเผยแพร่สู่สาธารณะให้ผู้คนทั่วไปเห็นได้ทันที เเม้ว่าผู้เรียนจะยังไม่ได้ซื้อคอร์สนี้ก็ตาม</li>
                        <li>หากยินยอมชำระเงินเเบบรายวิดีโอ จะไม่สามารถลดราคาได้ ซึ่งระบบจะเเสดงข้อความบอกผู้เรียนว่า "ชำระเเบบรายวิดีโอได้</li>
                    </ul>
                </div>
            </>
        )
    }

    return (
        <>
            {!loading ?
                <>
                    {
                        isStepActive ?
                            <Row gutter={24}>
                                <Col span={24}>
                                    {genCoursePrice()}
                                </Col>
                                <Divider style={{ marginTop: 1, marginBottom: 20 }} />
                                <Col span={24}>
                                    <Row gutter={[24, 24]}>
                                        <Col xs={24} sm={12} md={12} lg={12}>
                                            <Button
                                                style={width > 992 ? { float: 'right', width: 130 } : ((width < 992 && width > 576) ? { float: 'right', width: 130 } : { width: '100%' })}
                                                type="default"
                                                onClick={() => dispatch(setCourseManageAddCurrentStepStateToSuccess(Number(resultCourseManageAddCurrentStep) - 1))}
                                            >
                                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                    <Icon icon="fluent:arrow-step-back-20-filled" />
                                                    <label style={{ paddingLeft: 10, cursor: "pointer" }}>ย้อนกลับ</label>
                                                </div>
                                            </Button>
                                        </Col>
                                        <Col xs={24} sm={12} md={12} lg={12}>
                                            <Button
                                                style={width > 992 ? { float: 'left', width: 130 } : ((width < 992 && width > 576) ? { float: 'left', width: 130 } : { width: '100%' })}
                                                type="primary"
                                                onClick={() => {
                                                    submitTypeRef.current = 'next'
                                                    nextStepRef.current = true
                                                    form.submit()
                                                }}
                                            >
                                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                    <Icon icon="fluent:arrow-next-20-filled" style={{ color: "white" }} />
                                                    <label style={{ paddingLeft: 10, cursor: "pointer" }}>ถัดไป</label>
                                                </div>
                                            </Button>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            :
                            <>
                                <Row style={{ padding: 20 }}>
                                    <Col span={24}>
                                        {genCoursePrice()}
                                    </Col>
                                    <Divider style={{ marginTop: 1, marginBottom: 1 }} />
                                    <Col span={24} style={{ paddingTop: 20 }}>
                                        <center>
                                            {(courseInspect ? courseInspect.course.inspectionCoursePriceApprovalId === 2 : false) ?
                                                <Popconfirm
                                                    title="คุณยืนยันบันทึกการปรับเเก้หรือไม่ ?"
                                                    okText={<span style={{ width: 50 }}>ใช่</span>}
                                                    onConfirm={() => {
                                                        submitTypeRef.current = 'update'
                                                        form.submit()
                                                    }}
                                                    cancelText={<span style={{ width: 50 }}>ไม่ใช่</span>}
                                                    disabled={courseInspect.inspectionCourseId === 3 ? false : true}
                                                >
                                                    <Row>
                                                        <Col span={24}>
                                                            <Button
                                                                type="primary"
                                                                disabled={courseInspect.inspectionCourseId === 3 ? false : true}
                                                            >
                                                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                                    <Icon icon="bx:save" style={{ color: "white" }} />
                                                                    <label style={{ paddingLeft: 10, cursor: "pointer" }}>บันทึกการปรับเเก้</label>
                                                                </div>
                                                            </Button>
                                                        </Col>
                                                        <Col span={24}>
                                                            {switchType === "edit-course" && courseInspect.inspectionCourseId === 2 && courseInspect.inspectionCoursePriceId === 1 ?
                                                                <label style={{ paddingTop: 10 }}>กำลังอยู่ระหว่างการตรวจสอบ ทำให้ไม่สามารถกดปุ่ม "บันทึกการเเก้ไข" ได้ในขณะนี้! จนกว่าจะตรวจสอบเสร็จ เเละเเจ้งผลให้ทราบ</label>
                                                                : []}
                                                        </Col>
                                                    </Row>
                                                </Popconfirm>
                                                :
                                                <>
                                                    {switchType === "edit-course" ?
                                                        <Popconfirm
                                                            title="คุณยืนยันการบันทึกการปรับเเต่งหรือไม่ ?"
                                                            okText={<span style={{ width: 50 }}>ใช่</span>}
                                                            onConfirm={() => {
                                                                submitTypeRef.current = 'update'
                                                                form.submit()
                                                            }}
                                                            cancelText={<span style={{ width: 50 }}>ไม่ใช่</span>}
                                                        >
                                                            <Button type="primary">
                                                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                                    <Icon icon="bx:save" style={{ color: "white" }} />
                                                                    <label style={{ paddingLeft: 10, cursor: "pointer" }}>บันทึกการปรับเเต่ง</label>
                                                                </div>
                                                            </Button>
                                                        </Popconfirm>
                                                        : []
                                                    }
                                                </>
                                            }
                                        </center>
                                    </Col>
                                </Row>
                                {/* </div> */}

                                {(switchType === "adjust-course" ?
                                    (courseInspect ? courseInspect.course.inspectionCoursePriceApprovalId === 2 : false)
                                    :
                                    (switchType === "edit-course" ?
                                        (
                                            (courseInspect ? (
                                                (courseInspect.course.inspectionCoursePriceApprovalId === 2 && courseInspect.inspectionCoursePriceId === 1 && courseInspect.inspectionCourseId === 3) ||
                                                (courseInspect.amountEdit > 0 && courseInspect.course.inspectionCoursePriceApprovalId === 2)
                                            ) : false)
                                        ) : false
                                    )
                                ) ?
                                    <div style={{ paddingLeft: 20, paddingRight: 20, paddingBottom: 20, backgroundColor: 'white' }}>
                                        <Row
                                            style={{
                                                padding: 10,
                                                border: '1px solid #EEEEEE',
                                                fontSize: 14
                                            }}
                                        >
                                            <Col span={24} style={{ paddingLeft: 10 }}>
                                                เหตุผลไม่ผ่าน
                                                <Popover content={<div style={{ width: 250 }}>ผลตรวจ ที่ทาง Toflyn เเจ้งให้ผู้สอนปรับเเก้ เเละส่งกลับมาใหม่อีกครั้งในเวลาที่กำหนด</div>}>
                                                    <AiOutlineQuestionCircle style={{ marginLeft: 5, cursor: 'pointer' }} />
                                                </Popover>
                                            </Col>
                                            <Col span={24} style={{ paddingLeft: 10, paddingRight: 10, paddingTop: 5 }}>
                                                <p style={{ color: "red", backgroundColor: "#EEEEEE", padding: 8 }}>
                                                    {courseInspect.course.reasonCoursePriceNotApproval}
                                                </p>
                                            </Col>
                                            <Col span={24} style={{ padding: 10, borderTop: "1px solid #EEEEEE" }}>
                                                ตัวอย่าง เกร็ดความรู้ เเละสิ่งที่ควรหลีกเลี่ยง
                                            </Col>
                                            <Col span={24}>
                                                <label style={{ paddingLeft: 20 }}>ราคาคอร์สควรเหมาะสมกับความเป็นจริง และตรวจสอบราคาให้ถูกต้อง</label>
                                            </Col>
                                        </Row>
                                    </div>
                                    : []}
                            </>
                    }
                </>
                :
                <center><Spin size="large" style={{ marginTop: 200, marginBottom: 200 }} /></center>
            }
        </>
    )
}
