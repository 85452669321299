/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useRef } from "react"
import "../home/css/welcome.css"
import { Layout } from 'antd'
import { Element } from 'react-scroll'
import Banner from "../../common/components/banner"
import Feedback from "../../common/components/feedback"
import Marquees from "../../common/components/announce/Marquees"
import Footer from "../../common/components/footer"
import Header from "../../common/components/header"
import LoadingPage from "../../common/components/loading/page"
import { useDimensions } from "../../common/mamager/ScreenManager"
import CourseSearch from "../../modules/course/components/search"
import Course from "../../modules/course"
import CourseCategory from "../../modules/course/components/category"
import PointViewItem from "../../common/components/pointView/PointViewItem"
import OpenCourse from "./components/openCourse"
import { getAttendLatestCourseByUserIdFetch, getCourseCategoryFetch, getCourseRatingFetch, getCourseSearchByIdFetch } from "../course/API"
import { getBannerFetch, getCheckUserIdOnDBFetch, getCourseAlreadyTakenPlaceFetch } from "./API"
import { getFeedbackFetch } from "../../common/components/feedback/API"
import { getUserByUidFetch } from "../../common/components/header/API"

const { Content } = Layout

export default function Welcome({ authorized, firebase, user }) {
    const language = "th"
    const { width } = useDimensions()

    const [courseGroupLatest, setCourseGroupLatest] = useState(null)

    const [courseGroupRandom1, setCourseRandom1] = useState()
    const [courseGroupRandom2, setCourseRandom2] = useState()
    const [courseGroupRandom3, setCourseRandom3] = useState()

    const [bannerData, setBannerData] = useState()
    const [courseCategory, setCourseCategory] = useState()
    const [courseRating, setCourseRating] = useState()

    const [feedback, setFeedback] = useState([])

    const userIdActiveOnDbRef = useRef(null)

    const [loading, setLoading] = useState(true)

    const [userInfo, setUserInfo] = useState(null)
    const [uid, setUid] = useState()

    const [attendLatestCourse, setAttendLatestCourse] = useState(null)

    const checkUserIdOnDB = async () => { // In progress [select data]
        try {
            const result = await getCheckUserIdOnDBFetch(firebase.auth().currentUser.uid)
            userIdActiveOnDbRef.current = result ? true : false; // true = have uid in database
        } catch (err) {
            userIdActiveOnDbRef.current = false
        }
    }

    const getFeedback = async () => { // In Done
        const result = await getFeedbackFetch()
        setFeedback(result)
    }

    const getCourseGroupLatest = async () => {
        setCourseGroupLatest(
            await getCourseById(
                null,       // category
                1,          // rating
                null,       // text
                true,       // loadMoreActive
                12,         // paginate
            )
        )
    }

    const randomCourseGroup = (max) => {
        return Math.floor(Math.random() * max)
    }

    const getCourseGroupRandom = async () => {
        // ------------- Random Course -------------
        const result = await getCourseAlreadyTakenPlaceFetch()
        let tmpArrRan = [1, 2, 3]
        if (result) {
            tmpArrRan = [];
            for (var i = 0; i < 3; i++) {
                let loopCheckRan = true
                while (loopCheckRan) {
                    let tmpRan = randomCourseGroup(result ? result.length : [])
                    let tmpResultCheck = tmpArrRan.find(ran => ran === result[tmpRan].courseCategoryId)
                    if (tmpResultCheck === undefined) {
                        tmpArrRan = [
                            ...tmpArrRan,
                            ...[result[tmpRan].courseCategoryId],
                        ];
                        loopCheckRan = false
                    }
                }
            }
        }

        setCourseRandom1(
            await getCourseById(
                tmpArrRan[0],       // category
                1,                  // rating
                null,               // text
                true,               // loadMoreActive
                6,                  // paginate
            )
        )

        setCourseRandom2(
            await getCourseById(
                tmpArrRan[1],       // category
                1,                  // rating
                null,               // text
                true,               // loadMoreActive
                6,                  // paginate
            )
        )

        setCourseRandom3(
            await getCourseById(
                tmpArrRan[2],       // category
                1,                  // rating
                null,               // text
                true,               // loadMoreActive
                6,                  // paginate
            )
        )
    }

    const getCourseById = async (category, rating, text, loadMoreActive, paginate) => { // category is courseCategoryId
        let obj = {
            category,
            text,
            rating,
            search: null,
            uid: firebase.auth().currentUser ? firebase.auth().currentUser.uid : null,
            loadMoreActive,
            paginate,
            language
        }

        const result = await getCourseSearchByIdFetch(obj)
        return result
    }

    const getCourseCategory = async () => {
        const result = await getCourseCategoryFetch(language)
        setCourseCategory(result)
    }

    const getCourseRating = async () => {
        const result = await getCourseRatingFetch(authorized, language)
        setCourseRating(result)
    }

    const getBanner = async () => {
        const result = await getBannerFetch()
        setBannerData(result)
    }

    const getUser = async () => {
        let uid = firebase.auth()?.currentUser?.uid
        if (uid) {
            const result = await getUserByUidFetch(uid)
            // console.log("getUserByUidFetch : ", result)
            setUserInfo(result)
        }
    }

    const getAttendLatestCourseByUserId = async () => {
        let uid = firebase.auth()?.currentUser?.uid
        if (uid) {
            const result = await getAttendLatestCourseByUserIdFetch({
                uid: firebase.auth().currentUser.uid,
                loadMoreActive: true,
                paginate: 6,
                language
            })
            setAttendLatestCourse(result)
            // console.log("getAttendLatestCourseByUserIdFetch : ", result)
        }
    }

    const onAddFeedback = async (values) => {
        let tempFeedback = feedback
        tempFeedback.push({
            id: null,
            feedbackName: values.feedbackName,
            user: {
                userName: userInfo.userName,
                imageProfile: userInfo.imageProfile,
            }
        })

        setFeedback(tempFeedback)
    }

    const requestApi = async () => {
        setLoading(true)

        // authen
        try {
            setUid(firebase.auth().currentUser.uid)
        } catch (err) {
            setUid(null)
        }

        await checkUserIdOnDB()

        await getAttendLatestCourseByUserId()
        await getCourseGroupLatest()
        await getCourseGroupRandom()
        await getCourseCategory()
        await getCourseRating()
        await getBanner()
        await getFeedback()
        await getUser()

        setLoading(false)
    }

    const handleCourseSearch = async (values) => { }

    useEffect(() => {
        requestApi()
    }, [])

    return (
        <>
            <Header firebase={firebase} user={user} />

            <Content style={{ backgroundColor: "#F5F5F5" }}>
                {!loading ?
                    <>
                        <div style={{ paddingBottom: 6, paddingTop: 6 }}>
                            <Banner bannerData={bannerData} />
                        </div>

                        <Marquees />

                        <div style={{ paddingBottom: 12, paddingTop: 12 }}>
                            <CourseSearch
                                uid={uid}
                                courseRating={courseRating}
                                courseCategory={courseCategory}
                                user={user}
                                isRedirectUrl={true}
                                handleCourseSearch={handleCourseSearch}
                                pageName="main"
                            />
                        </div>

                        <div
                            style={{
                                paddingLeft: (width >= 912 ? '5%' : 0),
                                paddingRight: (width >= 912 ? '5%' : 0)
                            }}
                        >
                            {attendLatestCourse?.courseItem?.length > 0 ?
                                <div style={{ paddingBottom: 5 }}>
                                    <Course
                                        uid={uid}
                                        courses={attendLatestCourse}
                                        isOpenCourseClassroom={true}
                                        isBoardLayoutHalve={true}
                                    />
                                </div>
                                : []
                            }
                        </div>

                        <OpenCourse uid={uid} attendLatestCourse={attendLatestCourse} />

                        <div
                            style={{
                                paddingLeft: (width >= 912 ? '5%' : 0),
                                paddingRight: (width >= 912 ? '5%' : 0),
                                paddingTop: 16
                            }}
                        >
                            <Course uid={uid} courses={courseGroupLatest} />

                            <CourseCategory uid={uid} courseCategory={courseCategory} />

                            <Course uid={uid} courses={courseGroupRandom1} />

                            <Course uid={uid} courses={courseGroupRandom2} />

                            <Course uid={uid} courses={courseGroupRandom3} />

                            <Element name="pointview">
                                <PointViewItem />
                            </Element>

                            <Feedback
                                feedback={feedback}
                                auth={firebase.auth()}
                                authorized={authorized}
                                onAddFeedback={onAddFeedback}
                            />
                        </div>

                    </>
                    :
                    <LoadingPage loading={loading} />
                }
            </Content>

            <Footer />
        </>
    )
}
