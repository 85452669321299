/* eslint-disable react-hooks/exhaustive-deps */
import {
    Col,
    Form,
    Row,
    Select,
    DatePicker,
    Input,
    Button,
    Table,
    Spin,
    Popconfirm,
} from 'antd'
import { useEffect, useState } from 'react'
import moment from 'moment'
import { Notification } from './../../../../../common/components/notification'
import { formatCodeUnit } from './../../../../../common/mamager/IdManager'
import {
    getPaymentStatusFetch,
    deletePaymentOrderFetch,
    lineNotifyPaymentOrderManageResult,
    getManagePaymentOrderAllFetch,
    removeFileByIdFetch
} from './../../../../../modules/erp/API'

const { Option } = Select

const { RangePicker } = DatePicker

const formatTime = "HH:mm"
const formatDateTime = "YYYY-MM-DD HH:mm"

export default function PaymentOrderManage(props) {
    const columnsฺ = [
        {
            title: 'No',
            dataIndex: 'index',
            width: "2%",
        },
        {
            title: 'เลขที่ชำระ',
            dataIndex: 'paymentOderId',
            width: "5%",
            render: (text, record) => {
                return (
                    <div>{formatCodeUnit(record.paymentOderId)}</div>
                )
            }
        },
        {
            title: 'รายการ',
            dataIndex: 'orderName',
            width: "9%",
        },
        {
            title: 'ชื่อผู้ใช้',
            dataIndex: 'ownerName',
            width: "6%",
        },
        {
            title: 'ประเภทชำระเงิน',
            dataIndex: 'paymentTypeName',
            width: "8%",
        },
        {
            title: 'ชำระผ่านทาง',
            dataIndex: 'paymentMethodTypeName',
            width: "7%",
        },
        {
            title: 'สถานะชำระเงิน',
            dataIndex: 'paymentStatusName',
            width: "7%",
        },
        {
            title: 'ราคาสุทธิ',
            dataIndex: 'netPrice',
            width: "6%",
            render: (text, record) => {
                return (
                    <div>{Intl.NumberFormat('en').format(Number(record.netPrice))} บาท</div>
                )
            }
        },
        {
            title: 'คูปอง',
            dataIndex: 'couponCode',
            width: "8%",
        },
        {
            title: 'วันที่ชำระ',
            dataIndex: 'dateTimeTransfer',
            align: "center",
            width: "3%",
            render: (text, record) => {
                return (
                    <div>
                        {moment(record.dateTimeTransfer).format('DD/MM/YYYY HH:mm:ss')}
                    </div>
                )
            }
        },
        {
            title: 'จัดการ',
            fixed: 'right',
            align: "center",
            width: "8%",
            render: (text, record) => {
                return (
                    <Popconfirm
                        title="ยืนยันที่จะลบคำสั่งซื้อ ใช่หรือไม่ ?"
                        okText={<span style={{ width: 50 }}>ใช่</span>}
                        onConfirm={async () => {
                            await handleDeletePaymentOrder(record)
                        }}
                        cancelText={<span style={{ width: 50 }}>ไม่ใช่</span>}
                    >
                        <Button
                            danger
                            type="primary"
                            onClick={() => {

                            }}
                        >ลบคำสั่งซื้อ</Button>
                    </Popconfirm>
                )
            }
        },
    ]

    let language = "th"

    const [form] = Form.useForm()

    const [list, setList] = useState([])
    const [loading, setLoading] = useState(false)

    const [pageCurrent, setPageCurrent] = useState(1)
    const [paymentStatusCategory, setPaymentStatusCategory] = useState([])

    const onPagine = (n) => {
        setPageCurrent(n.current)
    }

    const onFinish = async (value) => {
        setLoading(true)

        let ownerName = value.ownerName ?? null
        let startedDate = value.dateRange ? moment(value.dateRange[0]).format(formatDateTime) : null
        let endDate = value.dateRange ? moment(value.dateRange[1]).format(formatDateTime) : null
        let paymentStatusCategory = value.paymentStatusCategory ? value.paymentStatusCategory : null

        let request = {
            ownerName, startedDate, endDate, paymentStatusCategory
        }
        await getManagePaymentOrderAll(request)

        setLoading(false)
    }

    const getPaymentStatus = async () => {
        const result = await getPaymentStatusFetch(language)
        // console.log("getPaymentStatusFetch : ", result)
        setPaymentStatusCategory(result)
    }

    const getManagePaymentOrderAll = async (value) => {
        const result = await getManagePaymentOrderAllFetch(value, language)
        // console.log("getInspectPaymentAllFetch : ", result)
        setList(result)
    }

    const genLineNotifyManage = async (orderStatus, paymentOderId, ownerName, orderName, netPrice, paymentStatusName) => {
        // line
        let objLineNotify = {
            orderStatus: orderStatus,
            orderPaymentMethodTypeName: paymentStatusName,
            orderId: paymentOderId,
            adminName: props.adminLogin?.username,
            ownerName: ownerName,
            orderName: orderName,
            orderNetPrice: Intl.NumberFormat('en').format(Number(netPrice)) + " บาท",
            orderDateTime: moment().format('DD/MM/YYYY HH:mm')
        }
        // console.log("objLineNotify : ", objLineNotify)
        await lineNotifyPaymentOrderManageResult(objLineNotify)
    }

    const handleDeletePaymentOrder = async (data) => {
        setLoading(true)

        const resultDeletePaymentOrder = await deletePaymentOrderFetch(data.paymentOderId)
        // console.log("deletePaymentOrderFetch : ", resultDeletePaymentOrder)

        const resultRemoveFile = await removeFileByIdFetch(data.transferSlipImage, 0, 5) // (path, sliceMin, sliceMax)

        if (resultDeletePaymentOrder.isSuccess ?? resultRemoveFile.isSuccess) {
            // reminder
            Notification("success", "ระบบ", "ลบรายการคำสั่งซื้อสำเร็จ")

            // Line
            await genLineNotifyManage(
                "ลบคำสั่งชำระเงินสำเร็จ",
                data.paymentOderId,
                data.ownerName,
                data.orderName,
                data.netPrice,
                data.paymentStatusName
            )

            // reload data
            getBaseApi()

        } else {
            Notification("error", "ระบบ", "ลบรายการคำสั่งซื้อไม่สำเร็จ")

            await genLineNotifyManage(
                "ลบคำสั่งชำระเงินไม่สำเร็จ!, โปรดลองอีกครั้ง",
                data.paymentOderId,
                data.ownerName,
                data.orderName,
                data.netPrice,
                data.paymentStatusName
            )
        }

        setLoading(false)
    }


    const getBaseApi = async () => {
        setLoading(true)

        try {
            await getPaymentStatus()
            await getManagePaymentOrderAll({
                ownerName: null,
                startedDate: null,
                endDate: null,
                paymentStatus: null
            })
        } catch (err) {
            // ...
        }

        setLoading(false)
    }

    useEffect(() => {
        getBaseApi()
    }, [])

    return (
        <>

            <Row>
                <Col span={5}>
                    <label>จัดการคำสั่งชำระเงิน</label>
                </Col>
                <Col span={19} style={{ paddingBottom: 20 }}>
                    <Form layout="vertical" form={form} onFinish={onFinish}>
                        <Row>
                            <Col xs={12} lg={5}>
                                <Form.Item name="paymentStatusCategory" >
                                    <Select
                                        showSearch
                                        style={{ width: '90%' }}
                                        placeholder="สถานะชำระเงิน"
                                        optionFilterProp="children"
                                        allowClear
                                    >
                                        {paymentStatusCategory?.map((item, index) => (
                                            <Option key={index} value={item.value}>{item.label}</Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={12} lg={7}>
                                <Form.Item name="dateRange" >
                                    <RangePicker
                                        style={{ width: '90%' }}
                                        showTime={{ format: formatTime }}
                                        format={formatDateTime}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={12} lg={10}>
                                <Form.Item name="ownerName" >
                                    <Input
                                        style={{ width: '100%' }}
                                        placeholder="ชื่อผู้ใช้"
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={12} lg={2}>
                                <Button
                                    type="primary"
                                    onClick={() => form.submit()}
                                >
                                    ค้นหา
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Col>
                <Col span={24}>
                    {!loading ?
                        <Table
                            loading={loading}
                            columns={columnsฺ}
                            dataSource={list}
                            pagination={{
                                current: pageCurrent,
                                pageSize: 10,
                                total: list.length
                            }}
                            onChange={(n) => onPagine(n)}
                        >
                        </Table>
                        :
                        <center><Spin size="large" style={{ paddingTop: 200 }} /></center>
                    }
                </Col>
            </Row>
        </>
    )
}
