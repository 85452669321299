/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */

import '../../modules/course/css/course.css'
import { useState, useEffect } from 'react'
import {
    useNavigate,
    // withRouter 
} from 'react-router-dom'
import { isEmptyObject } from 'jquery'
import { Row, Col, Button, Divider, Pagination, Skeleton, Empty, Space } from 'antd'
import { Icon } from '@iconify/react'
import { useDimensions } from '../../common/mamager/ScreenManager'
import CourseItem from './CourseItem'

function Course(props) {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(true)
    const { width } = useDimensions()
    const [courseGroup, setCourseGroup] = useState([])
    const [page, setPage] = useState({
        minValue: 0,
        maxValue: 12
    })

    const getCourseFound = () => {
        return (
            <div style={{ paddingBottom: 50, width: '100%' }}>
                <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    imageStyle={{
                        height: 80
                    }}
                    style={{ width: '100%' }}
                    description={
                        <span style={{ color: 'gray' }}>ไม่พบคอร์สเรียน</span>
                    }
                >
                </Empty>
            </div>
        )
    }

    const handleChange = value => {
        if (value <= 1) {
            setPage({
                minValue: 0,
                maxValue: 12
            });
        } else {
            setPage({
                minValue: (value - 1) * 12,
                maxValue: value * 12
            })
        }
    }

    const reloadCourse = (courseId, type) => {
        setLoading(true)

        if (type === 'fetch-api') {
            let tempCourseItem = courseGroup.courseItem.filter(val => val.courseId !== courseId)
            setCourseGroup({
                courseGroupImg: courseGroup.courseGroupImg,
                courseGroupName: courseGroup.courseGroupName,
                courseGroupId: courseGroup.courseGroupId,
                courseLoadMoreActive: courseGroup.courseLoadMoreActive,
                courseItem: tempCourseItem,
            })
        } else if (type === 'not-fetch-api') {
            let tempCourseItem = courseGroup.courseItem.map(val => val.courseId === courseId ? {
                courseDescription: val.courseDescription,
                courseIsDiscount: val.courseIsDiscount,
                courseId: val.courseId,
                courseImageCard: val.courseImageCard,
                courseInterested: val.courseInterested === 1 ? 0 : 1,
                coursePrice: val.coursePrice,
                coursePriceDiscount: val.coursePriceDiscount,
                courseSubDescriptionName: val.courseSubDescriptionName,
                courseTitleName: val.courseTitleName,
                courseView: val.courseView,
            } : val)
            setCourseGroup({
                courseGroupImg: courseGroup.courseGroupImg,
                courseGroupName: courseGroup.courseGroupName,
                courseGroupId: courseGroup.courseGroupId,
                courseLoadMoreActive: courseGroup.courseLoadMoreActive,
                courseItem: tempCourseItem,
            })
        }

        setLoading(false)
    }

    useEffect(() => {
        setLoading(true)

        setCourseGroup(props.courses)

        setLoading(false)
    }, [])

    useEffect(() => {

    }, [courseGroup])

    return (
        <>
            {!loading && courseGroup ?
                <div>
                    {courseGroup.courseLoadMoreActive === true ?
                        <Row>
                            <Col span={24} style={{ paddingBottom: 10, paddingLeft: 10, paddingRight: 10 }}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <div>
                                        <Icon
                                            icon={courseGroup.courseGroupImg}
                                            style={{
                                                float: "left",
                                                color: "#23b14d",
                                                width: 25,
                                                height: 25,
                                                // marginTop: 2
                                            }}
                                        />
                                    </div>
                                    <label style={{ fontSize: 18, paddingLeft: 15 }}>{courseGroup.courseGroupName}</label>
                                </div>
                            </Col>

                            <Col span={24} style={width >= 1500 ? { paddingLeft: 0, paddingRight: 0 } : ((width > 912 && width < 1500) ? { paddingLeft: 0, paddingRight: 0 } : { paddingLeft: 8, paddingRight: 8 })}>
                                <Row>
                                    {!isEmptyObject(courseGroup.courseItem) ?
                                        <>
                                            {courseGroup.courseItem.map((courses, index) =>
                                                <CourseItem
                                                    key={index}
                                                    uid={props.uid}
                                                    courses={courses}
                                                    isOpenCourseClassroom={props.isOpenCourseClassroom}
                                                    reloadCourse={reloadCourse}
                                                    isRating={props.courses ? (props.courses.courseGroupName === "เเสดงตามคอร์สที่เก็บสนใจ" ? true : false) : false}
                                                    isBoardLayoutHalve={props.isBoardLayoutHalve}
                                                />
                                            )}
                                        </>
                                        : getCourseFound()
                                    }
                                </Row>
                            </Col>

                            {!props.isOpenCourseClassroom ?
                                <Col span={24} style={{ paddingTop: 10 }}>
                                    <center>
                                        <Button
                                            type="primary"
                                            onClick={() => {
                                                navigate("/course-list" +
                                                    "?course-category=" + props.courses.courseGroupId
                                                    + "&course-text="
                                                    + "&course-rating=" + 1
                                                    + "&is-search=" + false
                                                )
                                            }}
                                        >
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <Icon icon="gg:more-o" style={{ cursor: 'pointer', color: 'white', width: 18, height: 18 }} />
                                                <div style={{ paddingLeft: 10, paddingTop: 1, cursor: 'pointer' }}>
                                                    <label style={{ cursor: "pointer" }}>เพิ่มเติม</label>
                                                </div>
                                            </div>
                                        </Button>
                                    </center>
                                </Col>
                                : []
                            }

                            {!props.isBoardLayoutHalve ? <Divider plain></Divider> : []}
                        </Row>
                        :
                        <Row>
                            <Col span={24} style={{ paddingBottom: 10 }}>
                                <Icon
                                        icon={courseGroup.courseGroupImg}
                                        style={{
                                            float: "left",
                                            color: "#23b14d",
                                            width: 25,
                                            height: 25,
                                            marginTop: 2
                                        }}
                                    />
                                <label style={{ fontSize: 18, paddingLeft: 15, paddingTop: 0, margin: 0 }}>{courseGroup.courseGroupName}</label>
                            </Col>
                            {!isEmptyObject(courseGroup.courseItem) ?
                                <>
                                    <Col span={24}>
                                        <Row>
                                            {courseGroup.courseItem && courseGroup.courseItem.length > 0 &&
                                                courseGroup.courseItem
                                                    .slice(page.minValue, page.maxValue)
                                                    .map((courses, index) => (
                                                        <CourseItem
                                                            key={index}
                                                            uid={props.uid}
                                                            courses={courses}
                                                            reloadCourse={reloadCourse}
                                                            isOpenCourseClassroom={props.isOpenCourseClassroom}
                                                            isRating={props.courses ? (props.courses.courseGroupName === "เเสดงตามคอร์สที่เก็บสนใจ" ? true : false) : false}
                                                        />

                                                    ))
                                            }
                                        </Row>
                                    </Col>

                                    <Col span={24} style={{ paddingBottom: 10 }}>
                                        <center>
                                            <Pagination
                                                defaultCurrent={1}
                                                defaultPageSize={12}
                                                onChange={handleChange}
                                                total={courseGroup.courseItem.length}
                                            />
                                        </center>
                                    </Col>
                                </>
                                :
                                <Col span={24}>
                                    {getCourseFound()}
                                </Col>
                            }
                        </Row>
                        // <></>
                    }
                </div>
                :
                // <Row>
                //     <Col span={24} style={{ paddingBottom: 8 }}>
                //         <Space>
                //             <div style={{ height: 25, width: 25, backgroundColor: "#EEEEEE", borderRadius: 20 }}></div>
                //             <label style={{ marginTop: 5 }}>กำลังโหลดคอร์ส ...</label>
                //         </Space>
                //     </Col>
                //     <Col xs={24} sm={12} md={12} lg={6} xl={6} xxl={4} style={{ paddingRight: 5, paddingLeft: 5, paddingBottom: 10 }}>
                //         <div style={{ backgroundColor: "#EEEEEE", width: "100%", height: 300, borderRadius: "10px 10px 0px 0px" }}></div>
                //         <Skeleton active={true} paragraph={{ rows: 3 }} />
                //         <Row>
                //             <Col span={12}>
                //                 <Skeleton.Button active={true} size={'default'} shape={'square'} block={false} />
                //             </Col>
                //             <Col span={12}>
                //                 <Skeleton.Button active={true} size={'small'} shape={'square'} block={true} />
                //             </Col>
                //         </Row>
                //     </Col>
                //     <Col xs={24} sm={12} md={12} lg={6} xl={6} xxl={4} style={{ paddingRight: 5, paddingLeft: 5, paddingBottom: 10 }}>
                //         <div style={{ backgroundColor: "#EEEEEE", width: "100%", height: 300, borderRadius: "10px 10px 0px 0px" }}></div>
                //         <Skeleton active={true} paragraph={{ rows: 3 }} />
                //         <Row>
                //             <Col span={12}>
                //                 <Skeleton.Button active={true} size={'default'} shape={'square'} block={false} />
                //             </Col>
                //             <Col span={12}>
                //                 <Skeleton.Button active={true} size={'small'} shape={'square'} block={true} />
                //             </Col>
                //         </Row>
                //     </Col>
                // </Row>
                <></>
            }
        </>
    )
}

export default Course
// export default withRouter(Course)