/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable default-case */
/* eslint-disable no-fallthrough */
/* eslint-disable react-hooks/exhaustive-deps */

import { useState, useEffect } from 'react'
import {
    Table,
    Row,
    Col,
    Button,
    Form,
    Input,
    Select,
    DatePicker,
    Modal
} from 'antd'
import moment from 'moment'
import { BsSearch } from "react-icons/bs"
import { Notification } from '../../../../common/components/notification'
import { storageUrl } from '../../../../constants'
import { getComplaintActionStatusFetch, getComplaintAllFetch, updateComplaintByIdFetch } from '../../../helpAndResources/complaint/API'

const { Option } = Select
const { RangePicker } = DatePicker

const formatTime = "HH:mm"
const formatDateTime = "YYYY-MM-DD HH:mm"

export default function ComplaintManage() {
    const language = "th"

    const [form] = Form.useForm()
    const [formExecute] = Form.useForm()

    const [list, setList] = useState([])
    const [complaintActionStatus, setComplaintActionStatus] = useState([])
    const [loading, setLoading] = useState(true) // true

    const columns = [
        {
            title: 'No.',
            dataIndex: 'index',
            width: "5%",
        },
        {
            title: 'ผู้ร้องเรียน',
            dataIndex: 'ownerName',
            width: "12%",
        },
        {
            title: 'หัวข้อร้องเรียน',
            // dataIndex: 'complaintTopic',
            width: "25%",
            render: (record) => {
                return (
                    <>
                        <span style={{ float: "left" }}>{record.complaintTopic}</span>
                        <div
                            style={{ float: "right", cursor: "pointer" }}
                            onClick={() => {
                                setModalDetail({
                                    isOpen: true,
                                    data: record
                                });
                            }}
                        ><BsSearch /></div>
                    </>
                )
            }
        },
        {
            title: 'วันที่สร้าง',
            dataIndex: 'createdAt',
            width: "14%",
        },
        {
            title: 'ผลดำเนินการ',
            dataIndex: 'complaintActionStatusName',
            width: "10%",
        },
        {
            title: 'ดำเนินการ',
            dataIndex: 'operation',
            align: "center",
            width: "19%",
        },
    ]

    const [modalExecute, setModalExecute] = useState({
        isOpen: false,
        data: null
    })

    const [modalDetail, setModalDetail] = useState({
        isOpen: false,
        data: null
    })

    const [pageCurrent, setPageCurrent] = useState(1)

    const onFinish = async (value) => {
        let ownerName = value.ownerName ? value.ownerName : null
        let startedDate = value.dateRange ? moment(value.dateRange[0]).format(formatDateTime) : null
        let endDate = value.dateRange ? moment(value.dateRange[1]).format(formatDateTime) : null
        let complaintActionStatusId = value.complaintActionStatusId ? value.complaintActionStatusId : null

        let request = {
            ownerName, startedDate, endDate, complaintActionStatusId, language
        }
        // console.log("request : ", request);

        if (!ownerName && !startedDate && !endDate && !complaintActionStatusId) {
            getComplaintAllApi("allData", {
                ownerName: null,
                startedDate: null,
                endDate: null,
                complaintActionStatusId: null,
                language
            })
        } else {

            getComplaintAllApi("bySearch", request)
        }
    }

    const getComplaintAllApi = async (type, value) => {
        setLoading(true)

        const result = await getComplaintAllFetch(type, value)
        // console.log("result : ", result)

        let genData = []
        if (result) {
            for (let i = 0; i < result.length; i++) {
                genData.push({
                    index: (i + 1),
                    ownerName: result[i].ownerName,
                    complaintTopic: result[i].complaintTopic,
                    complaintDetails: result[i].complaintDetails,
                    attachAdditionalComplaintDocumentUrl: result[i].attachAdditionalComplaintDocumentUrl,
                    userTypeName: result[i].userTypeName,
                    createdAt: moment(result[i].createdAt).format(formatDateTime),
                    complaintActionStatusId: result[i].complaintActionStatusId,
                    complaintActionStatusName: genComplaintStatus(result[i]),
                    operation: genButtonComplaintAction(result[i])
                })
            }
            // console.log("genData : ", genData)
            setList(genData)

            setLoading(false)
        }
    }

    const onExecuteFinish = async (value) => {
        let obj = {
            uid: modalExecute.data ? modalExecute.data?.uid : null,
            complaintId: modalExecute.data ? modalExecute.data?.complaintId : null,
            complaintActionStatusId: value.complaintActionStatusId ? value.complaintActionStatusId : null
        }
        const result = await updateComplaintByIdFetch(obj)
        if (result.isSuccess) {
            setModalExecute({
                isOpen: false,
                data: null
            })
            Notification("success", "ระบบ", "Update สถานะสำเร็จ")
        } else {
            Notification("error", "ระบบ", "Update สถานะไม่สำเร็จ กรุณาลองใหม่อีกครั้ง")
        }

        // reload
        getBaseApi()
    }

    const genComplaintStatus = (value) => {
        if (value.complaintActionStatusId === 1) {
            return <div style={{ color: "#23B14D", fontWeight: "bold" }}>{value.complaintActionStatusName}</div>
        } else if (value.complaintActionStatusId === 2) {
            return <div style={{ color: "#FFD600", fontWeight: "bold" }}>{value.complaintActionStatusName}</div>
        } else if (value.complaintActionStatusId === 3) {
            return <div style={{ color: "#FF8A00", fontWeight: "bold" }}>{value.complaintActionStatusName}</div>
        } else if (value.complaintActionStatusId === 4) {
            return <div style={{ color: "#FF0000", fontWeight: "bold" }}>{value.complaintActionStatusName}</div>
        } else if (value.complaintActionStatusId === 5) {
            return <div style={{ color: "#6E6E6E", fontWeight: "bold" }}>{value.complaintActionStatusName}</div>
        }
    }

    const onPagine = (n) => {
        setPageCurrent(n.current)
    };

    const getBaseApi = async () => {
        await getComplaintAllApi("allData", {
            ownerName: null,
            startedDate: null,
            endDate: null,
            complaintActionStatusId: null,
            language
        })
    }

    const getComplaintActionStatus = async () => {
        const result = await getComplaintActionStatusFetch(language)
        setComplaintActionStatus(result)
    }

    useEffect(() => {
        setLoading(true)

        getBaseApi()
        getComplaintActionStatus()

        setLoading(false)
    }, [])

    const genButtonComplaintAction = (value) => {
        return (
            <>
                <Button
                    type="primary"
                    style={{
                        width: 90,
                        borderRadius: 50
                    }}
                    onClick={() => {
                        formExecute.setFieldsValue({
                            complaintActionStatusId: value.complaintActionStatusId ? value.complaintActionStatusId : undefined
                        })

                        setModalExecute({
                            isOpen: true,
                            data: value
                        })
                    }}
                >ปรับสถานะ</Button>
            </>
        )
    }

    return (
        <>
            {!loading ?
                <Row>
                    <Col span={5}>
                        <label>จัดการเรื่องร้องเรียน</label>
                    </Col>
                    <Col span={19} style={{ paddingBottom: 20 }}>
                        <Form
                            layout="vertical"
                            form={form}
                            onFinish={onFinish}
                        >
                            <Row>
                                <Col xs={12} lg={5}>
                                    <Form.Item name="complaintActionStatusId" >
                                        <Select
                                            showSearch
                                            style={{ width: '90%' }}
                                            placeholder="ผลดำเนินการ"
                                            optionFilterProp="children"
                                            allowClear
                                        >
                                            {complaintActionStatus.map(val => <Option value={val.value}>{val.label}</Option>)}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col xs={12} lg={7}>
                                    <Form.Item name="dateRange" >
                                        <RangePicker
                                            style={{ width: '90%' }}
                                            showTime={{ format: formatTime }}
                                            format={formatDateTime}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={12} lg={10}>
                                    <Form.Item name="ownerName" >
                                        <Input
                                            style={{ width: '100%' }}
                                            placeholder="ชื่อผู้ใช้"
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={12} lg={2}>
                                    <Button
                                        type="primary"
                                        onClick={() => form.submit()}
                                    >
                                        ค้นหา
                                    </Button>
                                </Col>
                            </Row>
                        </Form>

                    </Col>
                    <Col span={24}>
                        <Table
                            loading={loading}
                            columns={columns}
                            dataSource={list}
                            pagination={{
                                current: pageCurrent,
                                pageSize: 5,
                                total: list.length
                            }}
                            onChange={(n) => onPagine(n)}
                        >
                        </Table>
                    </Col>
                </Row>
                : []}

            {/* modalDetail */}

            <Modal
                title={"ข้อมูลเรื่องร้องเรียน"}
                visible={modalDetail.isOpen}
                onCancel={() => {
                    setModalDetail({
                        isOpen: false,
                        data: null
                    });
                }}
                width={500}
                footer={null}
            >
                <Row>
                    <Col span={8}>
                        <label style={{ float: "right" }}>หัวข้อร้องเรียน :</label>
                    </Col>
                    <Col span={16} style={{ paddingLeft: 8 }}>
                        <label>{modalDetail.data ? modalDetail.data.complaintTopic : null}</label>
                    </Col>

                    <Col span={8}>
                        <label style={{ float: "right" }}>ประเภทผู้ร้องเรียน :</label>
                    </Col>
                    <Col span={16} style={{ paddingLeft: 8 }}>
                        <label>{modalDetail.data ? modalDetail.data.userTypeName : null}</label>
                    </Col>

                    <Col span={8}>
                        <label style={{ float: "right" }}>รายละเอียดร้องเรียน :</label>
                    </Col>
                    <Col span={16} style={{ paddingLeft: 8 }}>
                        <label>{modalDetail.data ? modalDetail.data.complaintDetails : null}</label>
                    </Col>

                    {modalDetail.data?.attachAdditionalComplaintDocumentUrl ?
                        <>
                            <Col span={8}>
                                <label style={{ float: "right" }}>เอกสารเพิ่มเติม :</label>
                            </Col>
                            <Col span={16} style={{ paddingLeft: 8 }}>
                                <a
                                    target="_blank"
                                    href={`${modalDetail.data ? modalDetail.data.attachAdditionalComplaintDocumentUrl : null}`}
                                >คลิกดู</a>
                            </Col>
                        </>
                        : []}
                </Row>
            </Modal>

            <Modal
                title={"ปรับสถานะ"}
                visible={modalExecute.isOpen}
                onCancel={() => {
                    setModalExecute({
                        isOpen: false,
                        data: null
                    });
                }}
                width={300}
                footer={[
                    <Button
                        style={{ width: 100 }}
                        type="primary"
                        onClick={() => formExecute.submit()}
                    >ตกลง</Button>
                ]}
            >
                <Form
                    layout="vertical"
                    form={formExecute}
                    onFinish={onExecuteFinish}
                >
                    <Form.Item
                        name="complaintActionStatusId"
                        rules={[{ required: true, message: 'กรุณาเลือกสถานะ' }]}
                    >
                        <Select
                            showSearch
                            style={{ width: '100%' }}
                            optionFilterProp="children"
                            allowClear
                        >
                            {complaintActionStatus.map(val => <Option value={val.value}>{val.label}</Option>)}
                        </Select>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}
